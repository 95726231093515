import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxPicaErrorInterface, NgxPicaService } from '@digitalascetic/ngx-pica';

// libraries
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { Observable, Observer, Subscription } from 'rxjs';

// services
import { TrackService } from 'src/app/services/track.service';

// models
import { ITrack } from 'src/app/models/track.model';


@Component({
  selector: 'sl-update-data',
  templateUrl: './update-data.component.html',
  styleUrls: ['./update-data.component.less'],
})
export class UpdateDataComponent implements OnInit {
  @Output() updateTrackData = new EventEmitter();
  @Input() dataInput!: ITrack;
  targetData: ITrack = {} as ITrack;
  action!: (data: ITrack) => void;
  formType!: number;
  isVisible = false;
  generating = false;

  constructor(private trackService: TrackService, private message: NzMessageService, private ngxPicaService: NgxPicaService) { }

  ngOnInit(): void {
    this.targetData = { ...this.dataInput };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isSupportedType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
      if (!isSupportedType) {
        this.message.error('You can only upload JPG, PNG or SVG files.');
        observer.complete();
        return;
      }
      observer.next(isSupportedType);
      observer.complete();
    });

  uploadImage = (item: NzUploadXHRArgs): Subscription => {
    const fileToUpload: File = item.file as unknown as File;
    this.resizeAndSetImage(fileToUpload);
    return new Subscription();
  };

  resizeAndSetImage(fileToUpload: File) {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e: unknown) => {
      img.src = (e as ProgressEvent<FileReader>).target?.result as string;

      img.onload = () => {
        const originalWidth = img.width;
        const originalHeight = img.height;
        const maxImageWidth = 230;

        const { newWidth, newHeight } = this.calculateAspectRatio(originalWidth, originalHeight, maxImageWidth);

        if (fileToUpload.size > 250 * 1024) {
          this.ngxPicaService.resizeImages([fileToUpload], newWidth, newHeight).subscribe({
            next: (imageResized: File) => {
              if (imageResized.size > 250 * 1024) {
                this.message.error('Image must be smaller than 250KB');
                return;
              }
              const reader = new FileReader();
              reader.onload = () => {
                this.targetData.imageUrl = reader.result?.toString();
              };
              reader.readAsDataURL(imageResized);
            },
            error: (error: NgxPicaErrorInterface) => {
              this.message.error('Error resizing image: ' + error.err);
            }
          });
        } else {
          const event = e as ProgressEvent<FileReader>;
          this.targetData.imageUrl = event.target?.result as string;
        }
      };
    };

    reader.readAsDataURL(fileToUpload);
  }

  calculateAspectRatio(originalWidth: number, originalHeight: number, maxImageWidth: number) {
    let newWidth = 0;
    let newHeight = 0;

    if (originalWidth > originalHeight) {
      newWidth = maxImageWidth;
      newHeight = (originalHeight / originalWidth) * newWidth;
    } else {
      newHeight = maxImageWidth;
      newWidth = (originalWidth / originalHeight) * newHeight;
    }

    return { newWidth, newHeight };
  }

  showModal(): void {
    this.isVisible = true;
    // setTimeout(() => {
    //   const element = this.renderer.selectRootElement('#focus-here');
    //   element.focus();
    // }, 500);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  autogenerate(): void {
    this.generating = true;
    this.trackService.generateTrackDescription(this.targetData).subscribe({
      next: (description) => {
        this.message.success('New Description Generated!');
        this.targetData.description = description;
        this.generating = false;
      },
      error: (err: HttpErrorResponse) => {
        this.message.error('Failed to auto generate the track description from ChatGPT. It may be that the number of requests has been exceeded. Try again tomorrow.');
        console.error(`*** UpdateDataComponent: trackService.GenerateTrackDescription returned with an error: ${JSON.stringify(err)}`);
        this.generating = false;
      }
    });
  }

  submit(): void {
    this.trackService.updateTrackDetails(this.targetData).subscribe({
      next: () => {
        this.message.success('Success, thank you for helping us keep our tracks up to date.');
        // console.log('*** UpdateDataComponent: trackService.updateTrackDetails modified track with id=' + ret.id);
        this.updateTrackData.emit();
        this.handleCancel();
      },
      error: (err: HttpErrorResponse) => {
        this.message.error('Looks like something went wrong, you can try again if you like.');
        console.error(`*** UpdateDataComponent: trackService.updateTrackDetails returned with an error: ${JSON.stringify(err)}`);
      }
    });
  }
}
