<div>
  <nz-card class="card-style" nzHoverable [nzTitle]="guildCardName">
    <div class="guild-card-container">
      <div *ngIf="guild.imageUrl" class="img-container">
        <img class="guild-image" [src]="getGuildImage()" alt="Image of the Guild" />
      </div>

      <div *ngIf="!guild.imageUrl && guild.imageIcon" class="img-container">
        <div class="{{ guild.imageIcon }} image-icon"></div>
      </div>

      <div *ngIf="!guild.imageUrl && !guild.imageUrl && !guild.imageIcon" class="img-container">
        <img class="guild-image" [src]="getGuildPlaceholderImage()" alt="Image of the Guild" />
      </div>

      <div class="guild-information">
        <div *ngIf="guild.profession === 'all'" id="guild-industry">
          <p>Generic {{ guild.industry }}</p>
          <p>ALL Professions</p>
        </div>
        <p id="guild-profession" *ngIf="guild.profession !== 'all'">{{ guild.profession }}</p>
        <p id="guild-specialisation" *ngIf="guild.specialisation !== 'all'"><i class="icon fa-solid fa-microscope"></i> {{ guild.specialisation }}</p>
        <p id="guild-levels"><i class="icon fa-solid fa-stairs" aria-hidden="true"></i> Levels {{ guild.levelMin }} - {{ guild.levelMax }}</p>
        <p *ngIf="hasLocation()" id="guild-location"><i class="icon fa fa-map-marker" aria-hidden="true"></i> {{ guildLocation() }}</p>
        <p *ngIf="!hasLocation()" id="guild-globe"><i class="icon fa fa-globe" aria-hidden="true"></i> Global</p>
        <span *ngIf="!isGuildPublic()" nz-tooltip nzTooltipTitle="This is a private guild" nz-tooltip>
          <p id="guild-accessibility"><i class="icon fa fa-lock"></i> Private</p>
        </span>
        <span *ngIf="isGuildPublic()" nz-tooltip nzTooltipTitle="This is a public guild" nz-tooltip>
          <p id="guild-accessibility"><i class="icon fa fa-unlock" aria-hidden="true"></i> Public</p>
        </span>
        <p id="guild-members" *ngIf="guild.guildMemberCount !== 1"><i class="icon fa fa-users" aria-hidden="true"></i> {{ guild.guildMemberCount}} members</p>
        <p id="guild-members" *ngIf="guild.guildMemberCount === 1"><i class="icon fa fa-users" aria-hidden="true"></i> {{ guild.guildMemberCount}} member</p>
      </div>
    </div>
  </nz-card>
  <ng-template #guildCardName>
    <span class="guild-card-name">{{ guild.name }}</span>
  </ng-template>
</div>