import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringValidation'
})
export class StringValidationPipe implements PipeTransform {
  transform(value: string): string {
    if (value.length > 64) {
      return 'Value exceeds 64 characters.';
    }

    const specialChars = ['<', '>', '(', ')', '{', '}', '*', '='];
    const specialCharRegex = new RegExp(`[${specialChars.join('\\')}]`, 'g');
    if (specialCharRegex.test(value)) {
      return `Value contains invalid characters: ${specialChars.join(' ')}`;
    }

    return ''; // Valid value
  }
}