import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-localstorage';

@Injectable()
export class AuthGuard  {

  constructor(private router: Router, private authService: AuthService, private localStorageService: LocalStorageService) {
    console.log(`*** AuthGuard: Constructing`);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log(`*** AuthGuard: canActivate check. route ${route}. state: ${state}`);
    return this.authService.isAuthenticated().then(
      (res: boolean) => {
        if (!res) {
          console.log(`*** AuthGuard: cannot activate page`);
          this.localStorageService.set('previousRoute', state.url);
          this.authService.login();
          return false;
        }
        console.log(`*** AuthGuard: can activate page`);
        return true;
      }
    );
  }
}
