<nz-skeleton *ngIf="isLoading"></nz-skeleton>
<div *ngIf="stripeEvents && !isLoading">
  <div>
    <nz-table nzTemplateMode>
      <thead>
        <tr>
          <th>Event Id</th>
          <th>Event Type</th>
          <th>Subscription Status</th>
          <th>Customer Name</th>
          <th>Customer Email</th>
          <th>Customer Id</th>
          <th>Created Date</th>
          <th>Accept / Reject?</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let stripeEvent of stripeEvents; let i = index">
          <td>{{stripeEvent.eventId}}</td>
          <td>{{stripeEvent.eventType}}</td>
          <td>{{stripeEvent.subscriptionStatus}}</td>
          <td>{{stripeEvent.customerName}}</td>
          <td>{{stripeEvent.customerEmail}}</td>
          <td>{{stripeEvent.customerId}}</td>
          <td>{{stripeEvent.createdDate}}</td>
          <td>
            <button id="run-event-{{stripeEvent.id}}" class="action-button" nz-button nzType="danger" nz-popconfirm nzPopconfirmTitle="Are you sure?" nzOkText="OK" nzCancelText="Cancel" (nzOnConfirm)="runFailedStripeEvent(stripeEvent)">
              <span nz-icon nzType="check" nzTheme="outline"></span>
            </button>
            <button id="ignore-event-{{stripeEvent.id}}" class="action-button" nz-button nzType="danger" nz-popconfirm nzPopconfirmTitle="Are you sure?" nzOkText="OK" nzCancelText="Cancel" (nzOnConfirm)="ignoreFailedStripeEvent(stripeEvent)">
              <span nz-icon nzType="close" nzTheme="outline"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<!-- <div *ngIf="skillDuplicates?.length === 0">
  <h2 class="text-center" id="noDuplicatesMessage">There are currently no track skill duplicates.</h2>
</div> -->