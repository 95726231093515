<div *ngIf="playlistCreated" id="playlistcreation-page">
  <div class="grid-playlist-form" *ngIf="playlistCreated">
    <nz-steps id="playlist-steps" class="step" [nzCurrent]="stepIndex" nzDirection="vertical" (nzIndexChange)="onStepIndexChange($event)">
      <nz-step id="playlist-name-step" nzTitle="Enter the name of the playlist" [nzStatus]="isPlaylistNameValid && playlistCreated.name.trim() ? 'finish' : 'process'" [nzSubtitle]="requiredIcon" [nzDescription]="playlistNameInput">
        <ng-template #playlistNameInput>
          <div [ngClass]="isAdminMode === false ? 'form-item-name-container' : 'form-item-name-container-admin'">
            <div id="playlist-name">
              <nz-input-group nzPrefixIcon="bars">
                <input #playlistNameFocus tabindex="0" id="form-name" [(ngModel)]="playlistCreated.name" (ngModelChange)="onPlaylistNameChange()" (blur)="nameBlur()" (focus)="nameFocus()" nz-input placeholder="Playlist Name" />
              </nz-input-group>
              <div class="playlist-name-validator-container">
                <div class="error-container">
                  <p class="error-no-name" *ngIf="nameShowError">A valid playlist name is required</p>
                  <p class="playlist-name-error" *ngIf="playlistNameError">{{ playlistNameError }}</p>
                </div>
                <p class="character-counter">Characters remaining {{ remainingCharacters }}</p>
              </div>
            </div>
            <div *ngIf="isAdminMode">
              <button id="playlist-edit" nz-button [nzType]="'primary'" (click)="showPlaylistPickerModal()">
                <span><i nz-icon nzType="edit" nzTheme="outline"></i> Edit Playlist</span>
              </button>
            </div>
          </div>
        </ng-template>
      </nz-step>
      <nz-step id="playlist-industry-step" class="step" nzTitle="Select an Industry" [nzStatus]="isStepValid.get('nameEntered')  ? (isStepValid.get('industrySelected') ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('nameEntered')  ? industryOptions : null" [nzDisabled]="!isStepValid.get('nameEntered') ">
        <ng-template #industryOptions>
          <sl-industry-selector id="select-industry" (selectIndustryEvent)="initSelectedIndustry($event)" [currentMode]="mode" [previouslySelectedIndustry]="incomingPlaylist.industry"></sl-industry-selector>
        </ng-template>
      </nz-step>
      <nz-step id="playlist-profession-step" class="step" nzTitle="Select a Profession" [nzStatus]="isStepValid.get('industrySelected')? (isStepValid.get('professionSelected') ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('industrySelected') ? professionOptions : null" [nzDisabled]="!isStepValid.get('industrySelected')">
        <ng-template #professionOptions>
          <sl-profession-selector id="select-profession" [selectedIndustry]="playlistCreated.industry" [previouslySelectedIndustry]="incomingPlaylist.industry" [previouslySelectedProfession]="incomingPlaylist.profession" [currentMode]="mode"
            (selectProfessionEvent)="initSelectedProfession($event)" [allowAllProfessions]="true">
          </sl-profession-selector>
        </ng-template>
      </nz-step>
      <nz-step id="playlist-specialisation-step" class="step" nzTitle="Select a Specialisation" [nzStatus]="isStepValid.get('professionSelected') ? (isStepValid.get('specialisationSelected')  ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('professionSelected') ? specialisationOptions : null" [nzDisabled]="!isStepValid.get('professionSelected') ">
        <ng-template #specialisationOptions>
          <sl-specialisation-selector id="select-specialisation" [selectedIndustry]="playlistCreated.industry" [selectedProfession]="playlistCreated.profession" [previouslySelectedSpecialisation]="incomingPlaylist.specialisation"
            [previouslySelectedIndustry]="incomingPlaylist.industry" [previouslySelectedProfession]="incomingPlaylist.profession" [currentMode]="mode" (selectSpecialisationEvent)="initSelectedSpecialisation($event)"></sl-specialisation-selector>
        </ng-template>
      </nz-step>
      <nz-step id="playlist-level-step" class="step" nzTitle="Select Level Range(s)" [nzStatus]="isStepValid.get('specialisationSelected') ? (isStepValid.get('levelFromSelected') && isStepValid.get('levelToSelected') ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('specialisationSelected') ? levelOptions : null" [nzDisabled]="!isStepValid.get('specialisationSelected') ">
        <ng-template #levelOptions>
          <sl-level-selector id="select-level" (selectLevelFromEvent)="initSelectedLevelFrom($event)" (selectLevelToEvent)="initSelectedLevelTo($event)" [currentMode]="mode" [previouslySelectedLevelFrom]="incomingPlaylist.levelFrom"
            [previouslySelectedLevelTo]="incomingPlaylist.levelTo">
          </sl-level-selector>
        </ng-template>
      </nz-step>
      <nz-step id="playlist-track-step" class="step" nzTitle="Select a Track" [nzStatus]="isStepValid.get('levelFromSelected') && isStepValid.get('levelToSelected')? (isStepValid.get('trackSelected') ? 'finish' : 'process') : 'wait'"
        [nzSubtitle]="isStepValid.get('levelFromSelected') && isStepValid.get('levelToSelected') ? trackOptions : null" [nzDisabled]="!isStepValid.get('levelFromSelected') || !isStepValid.get('levelToSelected')">
        <ng-template #trackOptions>
          <sl-track-selector id="select-track" [selectedIndustry]="playlistCreated.industry" [selectedProfession]="playlistCreated.profession" [selectedSpecialisation]="playlistCreated.specialisation" [selectedLevelFrom]="playlistCreated.levelFrom"
            [selectedLevelTo]="playlistCreated.levelTo" [previouslySelectedIndustry]="incomingPlaylist.industry" [previouslySelectedProfession]="incomingPlaylist.profession" [previouslySelectedSpecialisation]="incomingPlaylist.specialisation"
            [previouslySelectedLevelFrom]="incomingPlaylist.levelFrom" [previouslySelectedLevelTo]="incomingPlaylist.levelTo" [previouslySelectedTrackName]="incomingPlaylist.relatedTrackName" [currentMode]="mode" (selectTrackEvent)="initSelectedTrack($event)">
          </sl-track-selector>
        </ng-template>
      </nz-step>
      <nz-step id="playlist-image-upload-step" class="step" nzTitle="Upload a profile image" [nzStatus]="isStepValid.get('trackSelected') ? (validateImageBox() ? 'finish' : 'process') : 'wait'" [nzDescription]="isStepValid.get('trackSelected')? imageUploader : null"
        [nzDisabled]="!isStepValid.get('trackSelected')">
        <ng-template #imageUploader>
          <sl-image-uploader id="image-uploader" (uploadImageUrlEvent)="initUploadedImageUrl($event)" [imageSrc]="playlistCreated.playlistImageUrl" [currentMode]="mode"></sl-image-uploader>
        </ng-template>
      </nz-step>
      <nz-step id="playlist-description-step" class="step" nzTitle="Write a Description" [nzStatus]="isStepValid.get('trackSelected') ? (validateDescriptionBox() ? 'finish' : 'process') : 'wait'" [nzDescription]="isStepValid.get('trackSelected') ? playlistDescription : null"
        [nzDisabled]="!isStepValid.get('trackSelected')">
        <ng-template #playlistDescription>
          <div class="form-description-container">
            <textarea id="form-description" nz-input [(ngModel)]="playlistCreated.description" placeholder="Enter a helpful description to explain what the playlist is for" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
          </div>
        </ng-template>
      </nz-step>
    </nz-steps>
    <ng-template #requiredIcon><sup><i class="fa-solid fa-asterisk required-icon"></i></sup></ng-template>
  </div>
  <div class="grid-file-options">
    <div class="grid-export-template">
      <button id="export-button-newmode" class="grid-export-template-button" *ngIf="mode === modeType.new" nz-button nzType="primary" nzShape="circle" (click)="exportPlaylistTemplate()" [nzLoading]="isExporting"
        nzTooltipTitle="Download a playlist template to your 'Downloads' directory" nzTooltipPlacement="topCenter" nz-tooltip>
        <i nz-icon nzType="download"></i>
      </button>
      <button id="export-button-editmode" class="grid-export-template-button" *ngIf="mode === modeType.edit" nz-button nzType="primary" nzShape="round" (click)="exportPlaylist()" [nzLoading]="isExporting" nzTooltipTitle="Download this playlist to your 'Downloads' directory"
        nzTooltipPlacement="topCenter" nz-tooltip>
        <i nz-icon nzType="download"></i> Download
      </button>
    </div>
    <div class="grid-select-file">
      <nz-upload id="form-playlistActivitiesVm" nzListType="picture" [(nzFileList)]="fileList" [nzBeforeUpload]="uploadCsv" [nzRemove]="removeCsv">
        <button *ngIf="mode === modeType.new" class="select-file" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Add activities to this playlist from a CSV file"><i nz-icon nzType="upload"></i>Select Activities CSV</button>
        <button *ngIf="mode === modeType.edit" class="select-file" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Upload new activities for this playlist from a CSV file"><i nz-icon nzType="upload"></i>Select Activities CSV</button>
      </nz-upload>
    </div>
  </div>

  <!-- submit row -->
  <nz-alert *ngIf="showNoticeAboutUpdatingPlaylist" class="grid-submit-download-info" nzType="info" nzShowIcon nzMessage="Tips for Updating..."
    nzDescription="You can find the latest playlist in your downloads directory. Just edit the CSV, click 'Select Activities CSV', and hit Update.">
  </nz-alert>
  <div class="grid-submit">
    <button id="submit-playlist" *ngIf="mode === modeType.new" nz-button nzType="primary" [disabled]="!validateAllStepInputs() || !this.activitiesCsv" (click)="submitPlaylist()" [nzLoading]="isSubmittingPlaylist">
      <i nz-icon nzType="send" nzTheme="outline"></i>Submit
    </button>
    <button id="submit-playlist" *ngIf="mode === modeType.edit" nz-button nzType="primary" [disabled]="!validateAllStepInputs()" (click)="submitPlaylistUpdates()" [nzLoading]="isSubmittingPlaylist || isSubmittingActivities">
      <i nz-icon nzType="send" nzTheme="outline"></i>Update
    </button>
  </div>

  <!-- Don't put the crappy dialog up if we're showin this component in the product. This is for Admin mode only -->
  <div *ngIf="isAdminMode">
    <nz-modal id="edit-modal" [(nzVisible)]="isVisiblePlaylistPicker" nzTitle="Select a Playlist to Edit" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzOkDisabled]="playlistExistingSelectedId === undefined">
      <ng-container *nzModalContent>
        <nz-spin *ngIf="loading.existing" nzSimple [nzSize]="'large'"></nz-spin>
        <!-- TODO: Perhaps we can consider a card view instead of a radio group. But... SIMPLE for now -->
        <nz-radio-group *ngIf="!loading.existing" [(ngModel)]="playlistExistingSelectedId">
          <label id="edit-playlist-radio-button" *ngFor="let playlistsExisting of playlistsExisting" nz-radio [nzValue]="playlistsExisting.id">{{ playlistsExisting.name }} V:{{ playlistsExisting.version }} #{{ playlistsExisting.playlistActivitiesVm.length }} - {{
            playlistsExisting.dateCreated | date : 'shortDate' }}</label>
        </nz-radio-group>
      </ng-container>
    </nz-modal>
  </div>
</div>