import { Component, OnInit, Input } from '@angular/core';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';

// services
import { UserActivitiesService } from '../../../services/user-activities.service';

// models
import { IUserActivity } from '../../../models/user-activity.model';

interface ColumnItem {
  name: string;
  sortOrder?: NzTableSortOrder | null;
  sortFn?: NzTableSortFn<IUserActivity>;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn<IUserActivity> | null;
  filterMultiple?: boolean;
  sortDirections?: NzTableSortOrder[];
}

@Component({
  selector: 'sl-user-activity',
  templateUrl: './user-activities.component.html',
  styleUrls: ['./user-activities.component.less'],
})
export class UserActivitiesComponent implements OnInit {
  @Input()
  userActivities!: IUserActivity[];
  loading = false;
  hasError = false;
  pagination = true;

  listOfColumns: ColumnItem[] = [
    {
      name: 'ID',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => (a.id as number) - (b.id as number),
      sortDirections: [],
    },
    {
      name: 'User ID',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => (!a.userId ? -1 : a.userId.localeCompare(b.userId ? b.userId : '')),
      sortDirections: ['descend', null],
    },
    {
      name: 'Title',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => a.title.localeCompare(b.title),
      sortDirections: ['descend', null],
    },
    {
      name: 'Provider ID',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => (!a.providerName ? -1 : a.providerName?.localeCompare(b.providerName ? b.providerName : '')),
      sortDirections: ['descend', null],
    },
    {
      name: 'Link',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => a.link.localeCompare(b.link),
      sortDirections: ['descend', null],
    },
    {
      name: 'Specialisation',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => (!a.specialisation ? -1 : a.specialisation?.localeCompare(b.specialisation ? b.specialisation : '')),
      sortDirections: ['descend', null],
      listOfFilter: [
        { text: 'Backend Developer', value: 'Backend Developer' },
        { text: 'Frontend Developer', value: 'Frontend Developer' },
        { text: 'Mobile Developer', value: 'Mobile Developer' },
        { text: 'Games Developer', value: 'Games Developer' },
        { text: 'DevOps', value: 'DevOps' },
        { text: 'Data Analyst', value: 'Data Analyst' },
        { text: 'Data Engineer', value: 'Data Engineer' },
      ],
      filterFn: (list: string[], item: IUserActivity) => list.some((specialisation) => item.specialisation?.indexOf(specialisation) !== -1),
      filterMultiple: true,
    },
    {
      name: 'Authors',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => (!a.authors ? -1 : a.authors?.localeCompare(b.authors ? b.authors : '')),
      sortDirections: ['descend', null],
    },
    {
      name: 'Status',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => a.status.localeCompare(b.status),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [
        { text: 'notstarted', value: 'notstarted' },
        { text: 'started', value: 'started' },
        { text: 'completed', value: 'completed' },
        { text: 'archived', value: 'archived' },
      ],
      filterFn: (list: string[], item: IUserActivity) => list.some((status) => item.status.indexOf(status) !== -1),
    },
    {
      name: 'Date Completed',
    },
    {
      name: 'Date Start',
    },
    {
      name: 'Date Added',
    },
    {
      name: 'Date Planned Start',
    },
    {
      name: 'Comments',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => (!a.comments ? -1 : a.comments.localeCompare(b.comments ? b.comments : '')),
      sortDirections: ['descend', null],
    },
    {
      name: 'Hidden',
    },
    {
      name: 'Skills',
    },
    {
      name: 'Flagged',
      sortOrder: null,
      sortFn: (a: IUserActivity, b: IUserActivity) => Number(a.flagged) - Number(b.flagged),
      sortDirections: ['ascend', 'descend', null],
    },
  ];

  constructor(private userActivitiesService: UserActivitiesService) {}

  ngOnInit() {
    this.loadActivityList();
  }

  loadActivityList() {
    this.hasError = false;
    this.loading = true;
    this.userActivitiesService.getActivitiesListAll().subscribe({
      next: (ret) => {
        this.userActivities = ret;
        this.loading = false;
      },
      error: () => {
        this.hasError = true;
        // console.error(`*** UserActivitiesComponent: Failed to get activities updating event. Error: ${JSON.stringify(err)}`);
        // this.message.error('We failed to get all of the activities for you.');

        this.userActivities = [];
        this.loading = false;
      }
    });
  }

  getSkillsString(activity: IUserActivity): string {
    let ret = '';

    if (!activity.skills) {
      return '';
    }

    for (const skill of activity.skills) {
      ret += skill.name + ', ';
    }

    return ret.slice(0, ret.length - 2);
  }
}
