<div>
  <form class="email-form-container" nz-form [formGroup]="singleUserMode ? emailSingleUserGroup : emailTemplateGroup">
    <div class="email-form-element">
      <nz-form-item id="template-address" *ngIf="singleUserMode">
        <nz-form-control nzErrorTip="Please enter the email address of someone you want to send this email to">
          <nz-input-group nzPrefixIcon="user">
            <input nz-input id="email-address" formControlName="address" placeholder="Address" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item id="template-title">
        <nz-form-control>
          <nz-input-group nzPrefixIcon="mail">
            <input nz-input id="email-title" formControlName="title" placeholder="Subject" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <div class="{{ singleUserMode ? 'edit-mode-single' : 'edit-mode-template' }}">
        <button *ngIf="!editMode" nz-button nzType="default" nzShape="circle" (click)="clickSwitch()">
          <span nz-icon nzType="edit" nz-tooltip nzTooltipTitle="Edit the current template"></span>
        </button>
        <button *ngIf="editMode" nz-button nzType="primary" nzShape="circle" (click)="clickSwitch()" [nzLoading]="isSavingTemplate">
          <span nz-icon nzType="save" nz-tooltip nzTooltipTitle="Save the current template"></span>
        </button>
        <button *ngIf="editMode" nz-button nzType="default" nzShape="circle" (click)="clickCancelEdit()" class="button-preview">
          <span nz-icon nzType="close-circle" nz-tooltip nzTooltipTitle="Resume preview without saving template"></span>
        </button>
      </div>

      <nz-form-item id="template-switch">
        <nz-form-control>
          <!-- Show the rendered html so the user can get some feedback -->
          <div id="template-text" [innerHTML]="getTemplateText()"></div>
          <textarea *ngIf="editMode" formControlName="message" nz-input rows="20" placeholder="Message..." white-space: pre-wrap></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item *ngIf="!singleUserMode" class="email-form-recipients">
      <nz-form-control>
        <div>
          <p *ngIf="!editMode">
            <nz-collapse id="recipient-collapse" nzGhost>
              <nz-collapse-panel id="recipient-collapse-panel" nzHeader="If sent now, this would reach {{ getCanSendEmailCount() }} people" nzDisabled="{{ recipients.length === 0 }}">
                <nz-table id="recipient-table" #nzTable [nzData]="recipients" [nzHideOnSinglePage]="true">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Last Sent Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let recipient of nzTable.data" [ngStyle]="{'color': recipient.canSendEmail? '' : 'lightgray'}">
                      <td>{{ recipient.name }}</td>
                      <td>{{ recipient.surname }}</td>
                      <td>{{ recipient.email }}</td>
                      <td>{{ recipient.dateCreated }}</td>
                    </tr>
                  </tbody>
                </nz-table>
              </nz-collapse-panel>
            </nz-collapse>
          </p>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="email-send send-button">
      <nz-form-control>
        <button id="send-button" nz-button nzType="primary" (click)="sendEmailsFromForm()" [disabled]="isLoading || (singleUserMode && !emailTemplateGroup.valid)">Send</button>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="email-stats-container">
    <div class="email-stats-switch" *ngIf="messageSent">
      <nz-switch [ngModel]="statsShown" nzCheckedChildren="Hide message stats" nzUnCheckedChildren="Show message stats" (click)="showStats()" [nzLoading]="isLoading" [ngModelOptions]="{ standalone: true }" name="statsModal" ngDefaultControl></nz-switch>
    </div>
    <div *ngIf="isLoading">
      <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
    </div>
    <div *ngIf="!isLoading && statsShown" class="email-stats-table">
      <nz-table id="statsTable" #statsTable [nzData]="recipients" class="table table-hover table-bordered table-fixed" [nzShowPagination]="false">
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Last Sent</th>
            <th>Total Sent</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr *ngFor="let recipient of statsTable.data">
            <td>{{ recipient.id }}</td>
            <td>{{ recipient.name }}</td>
            <td>{{ recipient.surname }}</td>
            <td>{{ recipient.lastSent | date : 'dd/MM/yyyy' }}</td>
            <td>{{ recipient.totalSent }}</td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>
