/* eslint-disable no-console */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
// models
import { IGoal } from 'src/app/models/user-goal.model';
import { IPlaylist } from 'src/app/models/playlist.model';
import { IRecommendation } from 'src/app/models/recommendation.model';
import { IRecommendationGoalPanelState } from 'src/app/models/recommendation-goal-panel-state.model';
import { ISuggestedAction } from 'src/app/models/suggested-action.model';
// services
import { GoalService } from 'src/app/services/goal.service';
import { LocalStorageService } from 'ngx-localstorage';
import { RecommendationService } from '../../services/recommendation.service';
import { NzMessageService } from 'ng-zorro-antd/message';

export interface CheckOption {
  label: string;
  value: string;
  checked?: boolean;
}

export interface ActivePanel {
  goalId: number;
  goalSignature?: string;
  expanded: boolean;
}

@Component({
  selector: 'sl-recommendations',
  templateUrl: './browse-recommendations.component.html',
  styleUrls: ['./browse-recommendations.component.less'],
})
export class BrowseRecommendationsComponent implements OnInit, OnDestroy {
  public query = '';
  panels: IRecommendationGoalPanelState[] = [];
  onChangeGoal!: Subscription;
  onChangeActivities!: Subscription;
  onChangePlaylist!: Subscription;
  onChangeAction!: Subscription;

  processing = false; // set to true when we're processing anything while toggling the panels, whether it's in a subscribe or not.
  advancedFilter = false;
  advancedFilterCheckOptions: CheckOption[] = [];
  noActiveGoals = false;
  DEFAULT_SHOW_COUNT = 12;

  constructor(private route: ActivatedRoute, private recommendationService: RecommendationService, private goalService: GoalService, private localStorageService: LocalStorageService, private message: NzMessageService) { }

  ngOnInit() {
    this.learningStyleOptions();
    this.onChangeGoal = this.goalService.getGoalsUpdatedEvent().subscribe(() => {
      this.loadPanels(this.goalService.getGoals(true));
    });

    if (this.goalService.isInitialised()) {
      this.loadPanels(this.goalService.getGoals(true));
    }
  }

  ngOnDestroy() {
    if (this.onChangeGoal) {
      this.onChangeGoal.unsubscribe();
    }

    if (this.onChangeActivities) {
      this.onChangeActivities.unsubscribe();
    }

    if (this.onChangePlaylist) {
      this.onChangePlaylist.unsubscribe();
    }

    if (this.onChangeAction) {
      this.onChangeAction.unsubscribe();
    }
  }

  public toggleExpanded(isExpended: boolean, panel: IRecommendationGoalPanelState) {
    this.processing = true;
    const panelForGoal = this.panels.find((p) => p.goal.id === panel.goal.id);
    if (!panelForGoal) {
      this.processing = false;
      throw new Error('We assume that we can find the panel for a given goal id');
    }

    panelForGoal.expanded = isExpended;

    this.storeActivePanels();
    if (panel.expanded && panel.recommendedActivitiesAll.length === 0) {
      this.loadPanelActivities(panel);
      this.loadPanelPlaylists(panel);
      this.loadPanelActions(panel);
    } else if (panel.expanded) {
      this.processActivities(panel);
    }
    this.processing = false;
  }

  public updateAdvancedFilter(): void {
    this.panels.forEach((p) => {
      this.updateAdvancedFilterShowCount(p);
    });
  }

  public updateAdvancedFilterShowCount(panel: IRecommendationGoalPanelState) {
    panel.recommendedActivitiesFiltered = [];
    panel.recommendedActionsFiltered = [];
    panel.recommendedPlaylistsFiltered = [];

    if (this.advancedFilterCheckOptions.filter((co) => co.checked === true).length === this.advancedFilterCheckOptions.length) {
      panel.recommendedActivitiesFiltered = panel.recommendedActivitiesAll.slice((panel.currentPageIndex - 1) * panel.itemsPerPage, panel.currentPageIndex * panel.itemsPerPage);
      panel.recommendedActionsFiltered = panel.recommendedActionsAll.slice((panel.currentPageIndex - 1) * panel.itemsPerPage, panel.currentPageIndex * panel.itemsPerPage);
      panel.recommendedPlaylistsFiltered = panel.recommendedPlaylistsAll.slice((panel.currentPageIndex - 1) * panel.itemsPerPage, panel.currentPageIndex * panel.itemsPerPage);
    } else {
      for (const option of this.advancedFilterCheckOptions) {
        if (option.checked) {
          panel.recommendedActivitiesFiltered = panel.recommendedActivitiesFiltered.concat(panel.recommendedActivitiesAll.filter((r) => r.learningStyle === option.value));
        }
      }
      panel.recommendedActivitiesFiltered = panel.recommendedActivitiesFiltered.slice((panel.currentPageIndex - 1) * panel.itemsPerPage, panel.currentPageIndex * panel.itemsPerPage);
      panel.recommendedActionsFiltered = panel.recommendedActionsFiltered.slice((panel.currentPageIndex - 1) * panel.itemsPerPage, panel.currentPageIndex * panel.itemsPerPage);
      panel.recommendedPlaylistsFiltered = panel.recommendedPlaylistsFiltered.slice((panel.currentPageIndex - 1) * panel.itemsPerPage, panel.currentPageIndex * panel.itemsPerPage);
    }
  }

  public loading(panel: IRecommendationGoalPanelState): boolean {
    return this.processing || panel.loadingActivities || panel.loadingPlaylists;
  }

  public handlePlaylistAdded(goalAndPlaylistIds: [goalId: number, playlistId: number]) {
    const goalId = goalAndPlaylistIds[0];
    const playlistId = goalAndPlaylistIds[1];

    this.recommendationService.setPlaylistAdded(goalId, playlistId, true);
  }

  public handleActivityAdded(goalAndActivityIds: [goalId: number, activityId: number]) {
    const goalId = goalAndActivityIds[0];
    const activityId = goalAndActivityIds[1];

    this.recommendationService.setActivityAdded(goalId, activityId, true);
  }

  public handleActionAdded(goalAndActionIds: [goalId: number, actionId: number]) {
    const goalId = goalAndActionIds[0];
    const actionId = goalAndActionIds[1];

    this.recommendationService.setActionAdded(goalId, actionId, true);
  }

  public updateItemsPerPage(panel: IRecommendationGoalPanelState, val: number) {
    panel.itemsPerPage = val;
    this.updateAdvancedFilterShowCount(panel);
  }

  private loadPanelActivities(panel: IRecommendationGoalPanelState) {
    if (!panel.goal.id) {
      throw new Error('Goal ID cannot be undefined at this point.');
    }

    if (this.recommendationService.isInitialisedActivities(panel.goal.id)) {
      this.processActivities(panel);
      return;
    }

    if (!this.onChangeActivities) {
      this.onChangeActivities = this.recommendationService.getRecommendedActivitiesUpdatedEvent(panel.goal.id).subscribe({
        next: () => {
          this.processActivities(panel);
          panel.loadingActivities = false;
        },
        error: (error) => {
          this.message.error('Error retrieving recommended activities');
          console.error(`Error retrieving activities for goal ${panel.goal.id}. Error: `, error);
          panel.loadingActivities = false;
        }
      });
    }

    panel.loadingActivities = true;
    this.recommendationService.initialiseRecommendedActivities(panel.goal.id).subscribe({
      next: () => {
        this.processActivities(panel);
        panel.loadingActivities = false;
      },
      error: (error) => {
        this.message.error('Oh dear, something has gone terribly wrong.');
        console.error(`*** Failed to initialise the recommended activities. Error: `, error);
        panel.loadingActivities = false;
      }
    });
  }

  private processActivities(panel: IRecommendationGoalPanelState) {
    const activities = this.recommendationService.getRecommendedActivities(panel.goal.id as number);
    if (activities === undefined) {
      console.log(`WTF, we got the event to tell us that there are new activities, and then when we go to get them, they aren't there?`);
      throw new Error(`Everything is broken`);
    }
    this.updatePanelWithActivities(panel, activities);
  }

  private loadPanelPlaylists(panel: IRecommendationGoalPanelState) {
    if (!panel.goal.id) {
      throw new Error('Goal ID cannot be undefined at this point.');
    }

    if (this.recommendationService.isInitialisedPlaylists(panel.goal.id)) {
      this.processPlaylists(panel);
      return;
    }

    if (!this.onChangePlaylist) {
      this.onChangePlaylist = this.recommendationService.getRecommendedPlaylistUpdatedEvent(panel.goal.id).subscribe({
        next: () => {
          this.processPlaylists(panel);
          panel.loadingPlaylists = false;
        },
        error: (error) => {
          this.message.error('Error retrieving recommended playlists');
          console.error(`Error retrieving playlists for goal ${panel.goal.id}. Error: `, error);
          panel.loadingPlaylists = false;
        }
      });
    }

    panel.loadingPlaylists = true;
    this.recommendationService.initialiseRecommendedPlaylists(panel.goal.id).subscribe({
      next: () => {
        this.processPlaylists(panel);
        panel.loadingPlaylists = false;
      },
      error: (error) => {
        this.message.error('Oh dear, something has gone terribly wrong.');
        console.error(`*** Failed to initialise the recommended playlists. Error: `, error);
        panel.loadingPlaylists = false;
      }
    });
  }

  private processPlaylists(panel: IRecommendationGoalPanelState) {
    const playlists = this.recommendationService.getRecommendedPlaylists(panel.goal.id as number);
    if (playlists === undefined) {
      console.log(`WTF, we got the event to tell us that there are new playlists, and then when we go to get them, they aren't there?`);
      throw new Error(`Everything is broken`);
    }

    this.updatePanelWithPlaylists(panel, playlists);
  }

  private loadPanelActions(panel: IRecommendationGoalPanelState) {
    if (!panel.goal.id) {
      throw new Error('Goal ID cannot be undefined at this point.');
    }

    if (this.recommendationService.isInitialisedActions(panel.goal.id)) {
      this.processActions(panel);
      return;
    }

    if (!this.onChangeAction) {
      this.onChangeAction = this.recommendationService.getRecommendedActionUpdatedEvent(panel.goal.id).subscribe({
        next: () => {
          this.processActions(panel);
          panel.loadingActions = false;
        },
        error: (error) => {
          this.message.error('Oh dear, something has gone terribly wrong.');
          console.error(`*** Failed to initialise the recommended actions. Error: `, error);
          panel.loadingActions = false;
        }
      });
    }

    panel.loadingActions = true;
    this.recommendationService.initialiseRecommendedActions(panel.goal.id).subscribe({
      next: () => {
        this.processActions(panel);
        panel.loadingActions = false;
      },
      error: (error) => {
        this.message.error('Oh dear, something has gone terribly wrong with the Actions.');
        console.error(`*** Failed to initialise the recommended actions. Error: `, error);
        panel.loadingActions = false;
      }
    });
  }

  private processActions(panel: IRecommendationGoalPanelState) {
    const actions = this.recommendationService.getRecommendedActions(panel.goal.id as number);
    if (actions === undefined) {
      console.log(`WTF, we got the event to tell us that there are new actions, and then when we go to get them, they aren't there?`);
      throw new Error(`Everything is broken`);
    }

    this.updatePanelWithActions(panel, actions);
  }

  private learningStyleOptions() {
    // const learningStyles = ['Exposure', 'Experience', 'Credibility', 'Unknown']; // TODO: Explore whether we can have an 'Unrecognised' category as a filter.
    const learningStyles = ['Exposure', 'Experience', 'Credibility'];
    for (const style of learningStyles) {
      const learningStyleOption: CheckOption = {
        label: style,
        value: style,
        checked: true,
      };
      this.advancedFilterCheckOptions.push(learningStyleOption);
    }
  }

  private loadPanels(goals: IGoal[]) {
    this.processing = true;
    this.noActiveGoals = goals.length === 0;

    const goalIdString = this.route.snapshot.paramMap.get('goalId');
    const goalId = goalIdString ? parseInt(goalIdString, 10) : undefined;
    let storedPanels = this.localStorageService.get<ActivePanel[]>('storedActivePanels');
    if (null === storedPanels || goalId) {
      storedPanels = [];
    }

    for (const goal of goals) {
      const storedPanel = storedPanels?.find((p) => p.goalId === goal.id);

      const goalIdValue = goal.id;

      const panel = {
        goal,
        goalSignature: this.goalSignatureFromGoal(goal),
        expanded: goalId === goalIdValue ? true : storedPanel ? storedPanel.expanded : false,
        recommendedActivitiesAll: [],
        recommendedActivitiesFiltered: [],
        recommendedPlaylistsAll: [],
        recommendedPlaylistsFiltered: [],
        recommendedActionsAll: [],
        recommendedActionsFiltered: [],
        hasRecommendations: false,
        loadingActivities: false,
        loadingPlaylists: false,
        loadingActions: false,
        currentPageIndex: 1,
        itemsPerPage: this.DEFAULT_SHOW_COUNT,
      } as IRecommendationGoalPanelState;

      this.panels.push(panel);

      if (panel.expanded) {
        this.loadPanelActivities(panel);
        this.loadPanelPlaylists(panel);
        this.loadPanelActions(panel);
      }
    }
    this.processing = false;
  }

  private goalSignatureFromGoal(goal: IGoal): string {
    return goal.profession + ' | ' + goal.specialisation + ' | ' + goal.level;
  }

  private storeActivePanels() {
    const activePanels: ActivePanel[] = [];
    for (const panel of this.panels) {
      const activePanel = {
        goalId: panel.goal.id,
        goalSignature: panel.goalSignature,
        expanded: panel.expanded,
      } as ActivePanel;
      activePanels.push(activePanel);
    }
    this.localStorageService.set('storedActivePanels', activePanels);
  }

  private updatePanelWithActivities(panel: IRecommendationGoalPanelState, recommendedActivities: IRecommendation[]) {
    const panelForGoal = this.panels.find((p) => p.goal.id === panel.goal.id);
    if (!panelForGoal) {
      throw new Error('We assume that we can find the panel for a given goal id');
    }

    if (recommendedActivities?.length > 0) {
      panelForGoal.recommendedActivitiesAll = recommendedActivities as IRecommendation[];
      panelForGoal.hasRecommendations = true;
    }

    this.updateAdvancedFilter();
  }

  private updatePanelWithPlaylists(panel: IRecommendationGoalPanelState, recommendedPlaylists: IPlaylist[]) {
    const panelForGoal = this.panels.find((p) => p.goal.id === panel.goal.id);
    if (!panelForGoal) {
      throw new Error('We assume that we can find the panel for a given goal id');
    }

    if (recommendedPlaylists?.length > 0) {
      panelForGoal.recommendedPlaylistsAll = recommendedPlaylists as IPlaylist[];
      panelForGoal.hasRecommendations = true;
    }

    this.updateAdvancedFilter();
  }

  private updatePanelWithActions(panel: IRecommendationGoalPanelState, recommendedActions: ISuggestedAction[]) {
    const panelForGoal = this.panels.find((p) => p.goal.id === panel.goal.id);
    if (!panelForGoal) {
      throw new Error('We assume that we can find the panel for a given goal id');
    }

    if (recommendedActions?.length > 0) {
      panelForGoal.recommendedActionsAll = recommendedActions as ISuggestedAction[];
      panelForGoal.hasRecommendations = true;
    }

    this.updateAdvancedFilter();
  }
}
