/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

// services
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { RecommendationService } from 'src/app/services/recommendation.service';

// models
import { IRecommendation } from 'src/app/models/recommendation.model';
import { ISkill } from 'src/app/models/skill.model';
import { IGuildMember } from 'src/app/modules-guilds/models/guild-member.model';
import { EGuildRole } from 'src/app/modules-guilds/types/guild-role.type';
import { LearningService } from 'src/app/services/learning.service';
import { ILearningType } from 'src/app/models/learning-type.model';

@Component({
  selector: 'sl-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.less'],
})

export class ActivityCardComponent implements OnInit {
  @Input() recommendation!: IRecommendation;
  @Input() goalSkills!: ISkill[];
  @Input() goalId!: number;
  @Input() isActivityListMode!: boolean;
  @Input() isInteractable = true;
  @Input() selectedGuildMembers!: IGuildMember[];
  @Input() learningTypes!: ILearningType[];

  @Output() activityAdded = new EventEmitter<number>();
  @Output() drawerVisible = new EventEmitter<boolean>(); // this component is used to show the activity in the panel, but it's also used in the draw for the playlists. This boolean is emitted if the draw is expanded.

  fileUrl!: string;
  learningTypeImage!: string | undefined;
  learningTypeIcon!: string | undefined;
  isLoading = false;
  activityDateScheduled!: Date;
  isHovered = false;
  recommendationPanel = false;
  shorterUrl = '';
  goalSkillsId: number[] = [];
  goalSkillsThatAreCoreSkillsIds: number[] = [];
  playlistPanel = false;
  defaultThumbnail = 'https://i.stack.imgur.com/y9DpT.jpg';
  guildRoleType = EGuildRole;
  missingImagePlaceholder!: string;

  constructor(private message: NzMessageService, private userActivitiesService: UserActivitiesService, private recommendationService: RecommendationService, private learningService: LearningService) { }

  public get addedToBacklog(): boolean {
    return this.recommendation.added;
  }

  public set addedToBacklog(added: boolean) {
    this.recommendation.added = added;
  }

  ngOnInit() {
    this.activityDateScheduled = this.defaultDateDue();
    this.setFileUrl();
    this.getShorterUrl();
    if (this.goalSkills) {
      this.goalSkillsThatAreCoreSkillsIds = this.goalSkills
        .filter(skill => skill.coreSkill)
        .map(skill => skill.id);
      this.goalSkillsId = this.goalSkills.map((s) => s.id);
      this.setCoreSkillsOnRecommendation();
    }
  }

  getShorterUrl() {
    const indexOfFirst = this.recommendation.link.indexOf('://') + 3;
    const indexOfSecond = this.recommendation.link.indexOf('/', indexOfFirst + 1);
    this.shorterUrl = this.recommendation.link.substring(indexOfFirst, indexOfSecond);
  }

  setFileUrl() {
    // priority; 1: normal url, 2: stored normal url, 3: larger url, 4: learning type image 5. learning type icon (handled using CSS class)
    this.getImageAndIconFromLearningType();
    this.fileUrl =
      this.recommendation.imageThumbnailUrl
        ? this.recommendation.imageThumbnailUrl
        : this.recommendation.imageThumbnail
          ? `data:image/jpeg;base64,${this.recommendation.imageThumbnail}`
          : this.recommendation.imageFullSizeUrl
            ? this.recommendation.imageFullSizeUrl
            : this.recommendation.imageBytes
              ? `data:image/jpeg;base64,${this.recommendation.imageBytes}`
              : this.learningTypeImage
                ? `data:image/jpeg;base64,${this.learningTypeImage}`
                : '';
  }

  getImageAndIconFromLearningType(): void {
    if (this.learningTypes) {
      this.learningTypes.find(lt => {
        if (lt.type === this.recommendation.typeName) {
          this.learningTypeImage = lt.image;
          this.learningTypeIcon = lt.icon;
        }
      });
    }
  }

  public handleMissingImage(event: Event) {
    // 1. If the learning type image is available, use that.
    // 2. Otherwise if both the learning type image doesn't exist, fall back to the default thumbnail
    this.missingImagePlaceholder = this.learningTypeImage ? `data:image/jpeg;base64,${this.learningTypeImage}` : this.defaultThumbnail;
    (event.target as HTMLImageElement).src = this.missingImagePlaceholder;
  }

  isShowingLearningTypeIcon(): boolean {
    if (!this.fileUrl && !this.learningTypeImage && this.learningTypeIcon) {
      return true;
    }
    return false;
  }

  defaultDateDue() {
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    return nextWeek;
  }

  public addToBacklog() {
    this.submitBacklogItemWithIds(this.recommendation.activityId, this.goalId);
  }

  openDrawer() {
    this.recommendationPanel = true;
    this.drawerVisible.emit(true);
  }

  closeDrawer() {
    this.recommendationPanel = false;
    this.drawerVisible.emit(false);
  }

  imageEnter() {
    this.isHovered = true;
  }

  imageLeave() {
    this.isHovered = false;
  }

  reportActivity(activityId: number) {
    this.recommendationService.flagRecommendedActivity(activityId).subscribe({
      next: (activity) => {
        this.isLoading = false;
        this.message.success(`Activity '${activity.title}' reported`);
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.message.error(`Damn, we can/'t report this activity right now`);
        console.error(error.message);
      }
    });
  }

  addToReportedList() {
    this.reportActivity(this.recommendation.activityId);
  }

  isActivityFlagged(): boolean | undefined {
    return this.recommendation.flagged;
  }

  private notifyNewActivityAdded(activityId: number) {
    // console.debug(`Recommendation for id: '${activityId}' has been added successfully. Notifying listeners...`);
    this.activityAdded.emit(activityId);
  }

  private submitBacklogItemWithIds(activityId: number, goalId: number) {
    this.isLoading = true;
    this.userActivitiesService.addBacklogItemToGoal(activityId, goalId).subscribe(
      (ua) => {
        this.addedToBacklog = true;
        this.isLoading = false;
        this.notifyNewActivityAdded(ua.activityTypeId as number);
        this.message.success(`Activity added: ${ua.title}`);
      },
      (err: HttpErrorResponse) => {
        if (err.status === 409) {
          this.addedToBacklog = true; // since we know they already have this activity, then let's mark it as accepted, but we have to wonder why it was here in the first place.
          this.message.info('Looks like you already have already added this activity to your profile before.');
        } else {
          this.message.error('Damm! We hit an iceberg when trying to add this activity');
        }

        this.isLoading = false;
      }
    );
  }

  private setCoreSkillsOnRecommendation() {
    this.recommendation.skills.forEach(skill => {
      if (this.goalSkillsThatAreCoreSkillsIds.includes(skill.id)) {
        skill.coreSkill = true;
      }
    })
  }
}
