<nz-badge [nzCount]="notificationCount" class="notification-badge" (click)="notificationModalVisible=true">
  <img src='/assets/img/sealadder/sealadder-logo-icon-white64.png' alt="logo" class="logo-img" />
</nz-badge>
<nz-modal [(nzVisible)]="notificationModalVisible" [nzTitle]="notificationTitle" [nzFooter]="notificationFooter" nzClosable="false" (nzOnCancel)="notificationModalVisible=false" nzWidth="65%" [nzBodyStyle]="{padding: '10px'}">
  <ng-container *nzModalContent>
    <nz-table class="notification-table" #notificationsTable [nzData]="notificationsHideAccepted" nzShowPagination="false">
      <thead>
        <tr>
          <th nzWidth="20%">Title</th>
          <th nzWidth="65%">Description</th>
          <th nzWidth="10%">Date</th>
          <th nzAlign="center" nzWidth="5%">Accept</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of notificationsTable.data; let i = index" [ngClass]="{ 'notification-unchecked': !data.checked }">
          <td>{{data.messageTitle}}</td>
          <td>{{data.messageBody}}</td>
          <td>{{data.dateCreated | date:"dd/MM/yyyy"}}</td>
          <td nzAlign="center"><label id="notification-checked-{{ i }}" nz-checkbox [ngModel]="data.checked" (ngModelChange)="updateSingleChecked(data.id, $event)" ngDefaultControl></label></td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
</nz-modal>
<ng-template #notificationTitle>
  <div class="notification-title">
    <span>Notifications</span>
    <div class="notification-checkall">
      <div>
        <label nz-checkbox id="accept-all" [(ngModel)]="allChecked" (ngModelChange)="allCheckedClicked()" [nzIndeterminate]="indeterminate" ngDefaultControl>Accept All</label>
      </div>
      <div>
        <label nz-checkbox id="hide-accepted" [(ngModel)]="hideAccepted" (ngModelChange)="hideAcceptedClicked()" ngDefaultControl>Hide Accepted</label>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #notificationFooter>
  <button nz-button nzType="primary" (click)="notificationModalVisible=false">Close</button>
</ng-template>