import Rollbar from 'rollbar';
import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';

///
/// See RollBar article on best practices for Angular 8+
/// https://rollbar.com/blog/error-handling-with-angular-8-tips-and-best-practices/
///
const rollbarConfig = {
  accessToken: 'b46bab76f83a4b17b5ce4182b6ff5006',
  captureUncaught: false,
  captureUnhandledRejections: false,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
