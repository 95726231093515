import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

// models
import { IInvitation } from 'src/app/models/invitation.model';

// services
import { InviteService } from '../../../../../services/invite.service';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'sl-individual-invite-code',
  templateUrl: './individual-invite-code.component.html',
  styleUrls: ['./individual-invite-code.component.less'],
})
export class IndividualInviteCodeComponent implements OnInit {
  invitationAll: IInvitation[] = new Array<IInvitation>();
  invitationsFiltered!: IInvitation[];
  invitationsWithoutExpired!: IInvitation[];
  showExpired = false;
  tablePageSize = [5, 10, 15, 20, 30];
  isLoading = true;
  isRequested = false;

  constructor(private message: NzMessageService, private inviteService: InviteService, private clipboard: Clipboard, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.getInvitations();
    this.checkRequested();
  }

  getInvitations() {
    this.isLoading = true;
    this.inviteService.getCurrentUserInvitations().subscribe({
      next: (res) => {
        this.invitationAll = res;
        this.invitationsWithoutExpired = this.invitationAll.filter((x) => x.dateIssued === undefined);
        this.invitationAll.sort((a, b) => b.amountAllocated - b.amountUsed - (a.amountAllocated - a.amountUsed));
      },
      error: (error) => {
        console.error(`*** Failed to Retrieve Invitations. Error: ${error}`);
      },
    });
  }

  disableRequestMore(invitation: IInvitation): boolean {
    return (!!invitation.reservedFor || !!invitation.userPlaylistId || !!invitation.playlistId);
  }

  getInvitationRequestToolTip(invitation: IInvitation): string {
    if (invitation.reservedFor) {
      return `This invitation is reserved for a specific user: ${invitation.reservedFor}`;
    }
    if (invitation.userPlaylistId || invitation.playlistIdForUserPlaylist) {
      return 'Requesting increases on invitations for user playlists is not supported just yet';
    }
    if (invitation.playlistId) {
      return 'Requesting increases on invitations for playlists is not supported just yet';
    }

    return 'Request the invitation limit for this code to be increased';
  }

  checkRequested() {
    this.inviteService.getCurrentUserIsRequestedInvitations().subscribe(
      (res) => {
        this.isRequested = res;
        this.isLoading = false;
      },
      (error) => {
        console.error(`*** IndividualInviteCodeComponent: checkRequested Error. Err = ${error}`);
      }
    );
  }

  requestGenerateInvitations() {
    this.inviteService.createInvitation().subscribe(
      (ret) => {
        this.isRequested = true;
        this.invitationAll.push(ret);
        this.invitationsWithoutExpired.push(ret);
        this.createMessage('success', 'Request successfully');
      },
      (error) => {
        console.error(`*** IndividualInviteCodeComponent: requestGenerateInvitations Error. Err = ${error}`);
      }
    );
  }

  copyUrl(invitation: IInvitation) {
    if (invitation.playlistId && invitation.inviteCode.startsWith('PL:')) {
      this.localStorageService.set('details-for-playlist-goal', JSON.stringify({ playlistId: invitation.playlistId }));
      this.localStorageService.set('invite-from-playlist-link', invitation.inviteCode);
    } else if (invitation.guildId && invitation.inviteCode.startsWith('G:')) {
      this.localStorageService.set('details-for-guild-goal', JSON.stringify({ guildId: invitation.guildId }));
      this.localStorageService.set('invite-from-guild-link', invitation.inviteCode);
    }

    this.clipboard.copy(invitation.inviteUrl);
    this.createMessage('success', 'Invitation URL copied to clipboard');
  }

  createMessage = (type: string, text: string) => {
    this.message.create(type, text);
  };

  toggleRequestStatus(invitation: IInvitation) {
    this.inviteService.toggleRequestStatus(invitation).subscribe({
      next: (res) => {
        if (res) {
          this.createMessage('success', 'Allocation request sent');
          console.log(`*** Allocation request for ${invitation.inviteCode} sent successfully`);
        } else {
          this.createMessage('success', 'Allocation request cancelled');
          console.log(`*** Allocation request for ${invitation.inviteCode} cancelled`);
        }
      },
      error: (error) => {
        this.createMessage('error', 'Invitation Invalid');
        console.error(`*** Failed to Update Invitation Request Status. Error: ${error}`);
      }
    });
  }
}
