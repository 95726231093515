import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// services
import { OnboardingService } from 'src/app/services/onboarding.service';
import { NzMessageService } from 'ng-zorro-antd/message';
// models
import { IOnboardingStatus } from 'src/app/models/onboarding-status.model';
// localstorage
import { LocalStorageService } from 'ngx-localstorage';
// confetti
import * as confetti from 'canvas-confetti';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sl-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.less'],
})
export class OnboardingComponent implements OnInit, OnDestroy {
  tasksToComplete: IOnboardingStatus[] = [];
  show!: boolean;
  private onboardingStatusSubscription!: Subscription;

  constructor(private onboardingService: OnboardingService, private localStorageService: LocalStorageService, private route: Router, private message: NzMessageService) { }

  ngOnInit(): void {
    this.onboardingStatusSubscription = this.onboardingService.onboardingComplete.subscribe(
      () => {
        this.show = !this.onboardingService.isComplete();
        if (this.show) {
          this.initOnboardingUpdatedEvent();
          this.listenToSealadderServiceEvents();
        }
      }
    );
  }

  ngOnDestroy() {
    this.onboardingStatusSubscription.unsubscribe();
  }

  public goToPage(taskName: string) {
    switch (taskName) {
      case 'Add Recommendation':
        this.route.navigate(['recommendations']);
        break;
      case 'Complete Activity':
        this.route.navigate(['profile', 'activities']);
        break;
      case 'Add Provider':
        this.route.navigate(['profile', 'providers']);
        break;
      case 'Fill Profile':
        this.route.navigate(['account']);
        break;
      case 'Join a Guild':
        this.route.navigate(['guilds']);
        break;
      default:
        break;
    }
  }

  public getClassNameFromTaskName(taskName: string): string {
    return taskName.replace(/ /g, '-');
  }

  private checkCelebration() {
    const onboardingCannonReady = this.localStorageService.get<boolean>('completed-onboarding-is') ?? false;
    const onboardingCannonWas = this.localStorageService.get<boolean>('completed-onboarding-was') ?? false;

    if (onboardingCannonReady !== this.onboardingService.isComplete() && this.onboardingService.isComplete() && !onboardingCannonWas) {
      this.localStorageService.set('completed-onboarding-is', true);
      this.confettiFire();
      this.message.success('Congratulations on completing your onboarding.', { nzDuration: 3000 });
    }
  }

  private confettiFire(duration = 3000) {
    const animationEnd = Date.now() + duration;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const interval: any = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }
      const myCanvas = document.createElement('canvas');
      document.appendChild(myCanvas);

      confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
      })({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
      });

      confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
      })({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
      });
    }, 250);
  }

  private initOnboardingUpdatedEvent(): void {
    this.onboardingService.getOnboardingStatus().subscribe(
      (ret) => {
        this.tasksToComplete = ret;
        this.show = !this.onboardingService.isComplete();

        // preset the variables in local storage for fire once.
        if (!this.localStorageService.get<boolean>('completed-onboarding-was')) {
          this.localStorageService.set<boolean>('completed-onboarding-was', this.onboardingService.isComplete());
        }

        if (!this.onboardingService.isComplete()) {
          this.localStorageService.set<boolean>('completed-onboarding-is', false);
        }

        this.checkCelebration();
      },
      (err) => console.error(`*** ERROR OnboardingService onboardingUpdatedEvent ${err}`)
    );
  }

  private listenToSealadderServiceEvents() {
    if (this.tasksToComplete.length) {
      if (this.tasksToComplete.findIndex((ttc) => ttc.taskName === 'Complete Activity' && ttc.taskValue === false) === -1) {
        this.message.info(`Adding your first recommended activity, check.`);
      }
    }
  }
}
