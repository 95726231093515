import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { TopSkillService } from 'src/app/services/top-skill.service';

import { IUserProfileSubscription } from 'src/app/models/user-profile.model';
import { ITopSkill } from 'src/app/models/top-skill.model';

@Component({
  selector: 'sl-all-user-top-skills',
  templateUrl: './all-user-top-skills.component.html',
  styleUrls: ['./all-user-top-skills.component.less'],
})
export class AllUserTopSkillsComponent implements OnInit {
  isLoadingTopSkills = false;
  users!: IUserProfileSubscription[];
  maxTopSkills = new Array<number>(6);
  private topSkills = new Map<string, ITopSkill[]>();

  constructor(private message: NzMessageService, private topSkillService: TopSkillService, private profileService: AccountDetailsService) { }

  ngOnInit(): void {
    this.profileService.getBasicUsers(false).subscribe((users: IUserProfileSubscription[]) => {
      this.users = users.filter((u) => u.id !== '0' && u.isTest !== true); // we don't wanna list out Phillip the First
      this.users.forEach((u) => {
        this.topSkillService.getTopSkillsForUser(u.id).subscribe((ts: ITopSkill[]) => {
          this.topSkills.set(u.id, ts);
        });
      });
    });
  }

  getTopSkillName(userId: string, topSkillIndex: number): string {
    const topSkillsForGoal = this.topSkills.get(userId);
    return topSkillsForGoal !== undefined && topSkillsForGoal.length > 0 && topSkillsForGoal[topSkillIndex] !== undefined ? topSkillsForGoal[topSkillIndex].name : '';
  }

  getTopSkillRank(userId: string, topSkillIndex: number): string {
    const topSkillsForGoal = this.topSkills.get(userId);
    return topSkillsForGoal !== undefined && topSkillsForGoal.length > 0 && topSkillsForGoal[topSkillIndex] !== undefined ? topSkillsForGoal[topSkillIndex].rankPercentile.toString() : '';
  }
}
