<div class="glyph-container">
  <div class="glyph-input-container">
    <p *ngIf="!glyphSuccessfullySubmitted" class="glyph-container-heading"><i class="help-icon" [nz-tooltip]="helpInfo" nz-icon nzType="info-circle" nzTheme="outline" (click)="redirectToGlyphWebsite()"></i></p>
    <ng-template #helpInfo>
      A glyph is a symbol that will be displayed on your guild. To see a range of glyphs that you can select, click on the icon. To ensure that we're able to register your glyph, we suggested selecting ones of type Font Awesome and Glyphicons.
    </ng-template>
    <nz-input-group *ngIf="!glyphSuccessfullySubmitted" class="glyph-input-group" nzAddOnBefore="<i class= &quot;" nzAddOnAfter="&quot; ></i>">
      <input id="glyph-input" type="text" spellcheck="false" nz-input [ngModel]="glyphClassName | glpyhSelectorPipe " (ngModelChange)="validateClassPrefix($event)" (change)="submitGlyphClassName()" autocomplete="off" (click)="$event.target.select()">
    </nz-input-group>
    <span *ngIf="!correctClassPrefix"><a id="help-text" href="https://glyphsearch.com/?copy=class&library=font-awesome" target="_blank"><i>Not sure of what to enter?</i></a></span>
  </div>
  <i *ngIf="!glyphSuccessfullySubmitted" id="glyph-icon-preview" class="{{glyphClassName | glpyhSelectorPipe}} fa-8x" [ngStyle]="{'display' : correctClassPrefix ? 'block' : 'none'}"></i>
  <div #submittedGlyphIconContainer [nz-tooltip]="glyphUnrecognised ? unrecognisedGlyphTooltip: undefined" [ngClass]="glyphUnrecognised ? 'glyph-icon-container-unrecognised':'glyph-icon-container'" *ngIf="glyphSuccessfullySubmitted && correctClassPrefix" (click)="removeIcon()">
    <i id="glyph-icon-submitted" class="{{glyphClassName | glpyhSelectorPipe}} fa-10x" nz-tooltip="Click to remove"></i>
  </div>
  <ng-template #unrecognisedGlyphTooltip>
    The glyph selected is not recognised. Click to remove.
  </ng-template>
</div>