<nz-card nzTitle="Create Playlist">
  <sl-create-playlist isAdminMode="true"></sl-create-playlist>
</nz-card>

<nz-card nzTitle="All Playlists">
  <div id="show-retired">
    <label nz-checkbox [(ngModel)]="showRetired" (ngModelChange)="filterPlaylists()">Show retired playlists</label>
  </div>
  <nz-table
    style="padding: 10px"
    id="playlists"
    #PlaylistsTable
    [nzData]="playlistsFiltered"
    [nzShowPagination]="true"
    [nzShowQuickJumper]="true"
    [nzPageSizeOptions]="[30, 50, 100, 200]"
    [nzPageSize]="50"
    [nzShowSizeChanger]="true"
    nzSize="small"
    nzPaginationPosition="both"
  >
    <thead>
      <tr>
        <th>Id</th>
        <th>Name</th>
        <th>Industry</th>
        <th>Profession</th>
        <th>Specialisation</th>
        <th>Level From</th>
        <th>Level To</th>
        <th>Track Name</th>
        <th>Activity Count</th>
        <th>Date Created</th>
        <th>Version</th>
        <th>Created By User</th>
        <th>Actions</th>
        <th>retired</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let playlist of PlaylistsTable.data">
        <td>{{ playlist.id }}</td>
        <td class="playlist-name">{{ playlist.name }}</td>
        <td>{{ playlist.industry }}</td>
        <td>{{ playlist.profession }}</td>
        <td>{{ playlist.specialisation }}</td>
        <td>{{ playlist.levelFrom }}</td>
        <td>{{ playlist.levelTo }}</td>
        <td>{{ playlist.relatedTrackName }}</td>
        <td class="playlist-activity-count">{{ playlist.playlistActivitiesVm.length }}</td>
        <td>{{ playlist.dateCreated }}</td>
        <td>{{ playlist.version }}</td>
        <td>{{ playlist.createdByFirstName }} {{ playlist.createdByLastName }}</td>
        <td>
          <button class="action-button export-playlist" id="export-playlist-{{ playlist.id }}" nz-button nzShape="circle" title="Export" (click)="exportPlaylist(playlist)" [nzLoading]="playlist.isExporting">
            <i nz-icon nzType="export" nzTheme="outline"></i>
          </button>
          <button *ngIf="!playlist.retired" class="action-button retire-playlist" id="retire-playlist-{{ playlist.id }}" nz-button nzShape="circle" title="Retire" (click)="retirePlaylist(playlist)" [nzLoading]="playlist.isRetiring">
            <i class="fa fa-archive"></i>
          </button>
          <button *ngIf="playlist.retired" class="action-button retire-playlist" id="unretire-playlist-{{ playlist.id }}" nz-button nzShape="circle" title="UnRetire" (click)="unRetirePlaylist(playlist)" [nzLoading]="playlist.isRetiring">
            <i class="fa fa-trash-restore"></i>
          </button>
          <button class="action-button delete-playlist" id="delete-playlist-{{ playlist.id }}" nz-button nzDanger nzShape="circle" title="Delete" (click)="deletePlaylistAdmin(playlist)" [nzLoading]="playlist.isDeleting">
            <i nz-icon nzType="delete" nzTheme="outline"></i>
          </button>
        </td>
        <td>{{ playlist.retired }}</td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>
<hr />

<sl-display-user-playlists></sl-display-user-playlists>
