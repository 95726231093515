/* eslint-disable quote-props */
import { Component, OnInit, Output, HostListener, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { NzMarks } from 'ng-zorro-antd/slider';
import { HttpErrorResponse } from '@angular/common/http';

// models
import { ActivityKind } from 'src/app/types/activity-kind.type';
import { IActivityMetadata, IUrlMetadata } from 'src/app/models/user-activity-metadata.model';
import { IGoal } from 'src/app/models/user-goal.model';
import { ILearningType } from 'src/app/models/learning-type.model';
import { ISkill } from 'src/app/models/skill.model';
import { IUserActivity } from 'src/app/models/user-activity.model';
import { IUserBacklog } from 'src/app/models/user-backlog.model';
import { IViewSkillOption } from 'src/app/models/view-skill-option.model';

// ngZorro services
import { LocalStorageService } from 'ngx-localstorage';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

// services
import { AuthService } from '../../../../services/auth.service';
import { ExternalApiService } from '../../../../services/external-api.service';
import { GoalService } from '../../../../services/goal.service';
import { LearningService } from '../../../../services/learning.service';
import { UserActivitiesService } from '../../../../services/user-activities.service';
import { SkillService } from 'src/app/services/skill.service';
import { UserPlaylistService } from 'src/app/services/user-playlist.service';
import { toTitleCase } from 'src/app/services/helper.service';

export interface GoalOption {
  label: string;
  value: IGoal;
  disabled?: boolean;
}
@Component({
  selector: 'sl-manual-user-activity',
  templateUrl: './manual-user-activity.component.html',
  styleUrls: ['./manual-user-activity.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ManualUserActivityComponent implements OnInit {
  @Input() activities!: IUserActivity[];
  @Input() selectedActivity: IUserActivity | undefined; // edit user activity
  @Input() recommendation: IUserActivity | undefined;
  @Input() activityKind!: ActivityKind;
  @Input() noMatchingGoals!: boolean;
  @Input() isActivityRecommendation!: boolean;
  @Input() isActivityRecommendationAdded!: boolean;
  @Input() matchingActivityGoals!: IGoal[];
  @Input() activityGoalId!: number;
  @Output() closeModalEventListener = new EventEmitter();
  @Output() updateTable = new EventEmitter();
  @Output() updateActivityType: EventEmitter<boolean> = new EventEmitter();

  ActivityKind = ActivityKind;
  learningTypeOptions: string[] = [];
  goalOptions: GoalOption[] = [];
  skillOptions: IViewSkillOption[] = [];

  isUrlDuplicateModalVisible = false;
  isUrlDuplicateConfirm: boolean | undefined = undefined;
  urlMetadata: IUrlMetadata | null = null;

  loadingMetadata = false;
  isLoadingOne = false;

  hasActivityDirty = false;
  hasActivityTitleDirty = false;
  hasActivityTypeDirty = false;

  selectedGoal: GoalOption | undefined;
  selectedActivityTitle: string | undefined;
  selectedActivityType: string | undefined;
  selectedActivityUrl: string | undefined;
  selectedActivitySkillIds: string[] = [];
  activityDateScheduled!: Date | undefined;
  dueDateMode = 'duration';
  activityDuration = 28;
  durationMarks: NzMarks = {
    5: '1 day',
    15: '3 days',
    28: '1 week',
    56: '2 weeks',
    84: '4 weeks',
    100: 'No Date',
  };

  newSelectedSkills: ISkill[] = [];
  isAddButtonDisabled = true;
  isAddButtonDisabledText = '';
  defaultImmediateStart = true;
  userOverrideImmediateStart: boolean | undefined = undefined;
  enableAllSkills = false;

  // edit user activity
  editActivityMode = false;
  selectedActivityGoal: IGoal | undefined;
  showAddToGoalPopConfirm = false;
  popConfirmSkill: ISkill | undefined = undefined;
  textModalText = '';
  saveTextDisabled = false;
  noteLength = 0;
  noteLimit = 500000; // Note: 1MB limit, 1 character = 2 bytes in javascript
  allowedToUpdate = false;
  newSkillName = '';

  // Froala Editor
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editor: any | undefined;

  isAYouTubePlaylist = false;
  isAddingExternallyManagedPlaylist = false;
  isUserActivityAdded = false;

  constructor(
    private authService: AuthService,
    private externalApiService: ExternalApiService,
    private learningService: LearningService,
    private goalService: GoalService,
    private userActivitiesService: UserActivitiesService,
    private skillService: SkillService,
    private localStorageService: LocalStorageService,
    private message: NzMessageService,
    private modal: NzModalService,
    private userPlaylistService: UserPlaylistService
  ) { }

  public get hasActivity(): boolean {
    return this.selectedActivityUrl !== undefined && this.selectedActivityUrl.length > 0 && !this.isBadUrl(this.selectedActivityUrl);
  }

  public get hasActivityType(): boolean {
    return this.selectedActivityType !== undefined;
  }

  public get hasActivityTitle(): boolean {
    return this.selectedActivityTitle !== undefined && this.selectedActivityTitle.length > 0;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const x = event.key;
    if (x === '27') {
      this.closeModal();
    }
  }

  ngOnInit() {
    this.initialise();
  }

  isNotSelected(skillOption: IViewSkillOption): boolean {
    return this.selectedActivitySkillIds.indexOf(skillOption.id) === -1;
  }

  public initialise() {
    this.editActivityMode = this.selectedActivity ? true : false;
    if (!this.editActivityMode) {
      // if we're not editing, then we are by definition adding a userActivity.
      this.activityKind = ActivityKind.UserActivity;
      this.activityDateScheduled = this.defaultDateDue();
      if (!this.isActivityRecommendation) {
        this.getGoals();
      } else {
        this.getMatchingGoals();
      }
      this.getLearningTypes();
      if (this.recommendation) {
        this.setupRecommendedUserActivity(this.recommendation);
      }
    } else {
      this.initialiseEditMode();
    }
  }

  setupRecommendedUserActivity(recommendedUserActivity: IUserActivity) {
    if (!this.selectedGoal && !this.isActivityRecommendation) {
      throw new Error('The goalSelected must be defined at this point');
    }

    if (this.selectedGoal) {
      this.selectedGoal.value.id = recommendedUserActivity.goalId;
    }

    this.selectedActivityUrl = recommendedUserActivity.link;
    this.selectedActivityTitle = recommendedUserActivity.title;
    this.selectedActivityType = recommendedUserActivity.typeName;

    this.initialiseSelectedSkillName([], recommendedUserActivity.skills);
    this.getMetaData();
    this.isAddButtonDisabled = false;
    this.isAddButtonDisabledText = '';
  }

  initialiseEditMode() {
    this.selectedActivityTitle = this.selectedActivity?.title;
    this.isAddButtonDisabled = false;
    this.isAddButtonDisabledText = '';
    this.getSelectedActivityGoal();
    this.getMetaData();
    this.getSelectedLearningType();

    if (this.activityKind === ActivityKind.UserActivity && undefined !== this.selectedActivityGoal && this.selectedActivity) {
      this.initialiseSelectedSkillName([], this.selectedActivity.skills);
      this.activityDateScheduled = this.selectedActivity?.datePlannedDue;
    }

    this.initialSelectedActivityDuration();
    this.setupNotes();
  }

  setupNotes() {
    if (!this.selectedActivity) {
      throw new Error('SelectedActivity cannot be null at this point');
    }

    if (this.selectedActivity.notes) {
      this.textModalText = this.selectedActivity.notes;
      this.noteLength = this.textModalText.length;
      if (this.noteLength > this.noteLimit) {
        this.saveTextDisabled = true;
      }
    }

    this.editor = {
      placeholderText: 'Type your notes here...',
      height: 940,
      minHeight: 100,
      maxHeight: 1000,
      imagePaste: true,
      imagePasteProcess: false,
      imageResize: true,
      fullPage: true,
      typingTimer: 2000,
      saveInterval: 5000,
      imageMove: true,
      imageUpload: true,
      saveParam: this.textModalText,
      charCounterMax: this.noteLimit,
      charCounterCount: true,
      events: {
        'save.before': () => this.onBlur(this.textModalText),
        blur: () => this.onBlur(this.textModalText),
        'charCounter.exceeded': () => this.onChange(this.textModalText),
        contentChanged: () => this.onChange(this.textModalText),
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur(e: any) {
    if (!this.selectedActivity) {
      throw new Error('SelectedActivity cannot be null at this point');
    }

    if (e.length > 0) {
      this.selectedActivity.notes = this.textModalText;

      this.userActivitiesService.updateMarkdownNotes(this.selectedActivity.id, this.selectedActivity.notes).subscribe(
        () => {
          console.log(`auto saved notes for ${this.selectedActivity?.title}`);
        },
        (err) => {
          console.error(`something went wrong with auto save. Error: ${err}`);
        }
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange(e: any) {
    if (e.length > this.noteLimit) {
      this.saveTextDisabled = true;
    } else {
      this.saveTextDisabled = false;
    }
    this.noteLength = e.getContent().length;
  }

  getSelectedActivityGoal() {
    if (!this.selectedActivity) {
      throw new Error('The SelectedActivity must be defined at this point');
    }

    this.selectedActivityGoal = this.goalService.getGoal(this.selectedActivity.goalId);
    const existingGoalOption = this.goalOptions.find((item) => item.value.id === this.selectedActivity!.goalId);

    if (!existingGoalOption) {
      this.goalOptions = this.buildGoalOptions(this.goalService.getGoals().filter((e) => e.dateCompleted == null));
      this.goalOptions = this.goalOptions.filter((v, i, s) => s.findIndex((t) => t.value.id === v.value.id) === i);
    }

    if (this.selectedActivityGoal) {
      this.selectedGoal = this.goalOptions.find((item) => item.value.id === this.selectedActivityGoal?.id);
    } else {
      this.goalService.getGoalFromServer(this.selectedActivity.goalId).subscribe({
        next: (res) => {
          if (res && res.deleted) {
            this.goalOptions.unshift(...this.buildGoalOptions([res]));
            // The deleted goal is now at the top of the array so it will always be the first option.
            this.selectedGoal = this.goalOptions[0];
          } else {
            this.message.warning("We couldn't get the goal associated with this activity. Try selecting another?");
          }
        },
        error: () => {
          this.message.warning("We couldn't get the goal associated with this activity. Try selecting another?");
        }
      })
    }
  }

  getSelectedLearningType() {
    if (!this.selectedActivity) {
      throw new Error('The SelectedActivity must be defined at this point');
    }

    this.learningTypeOptions = [];
    if ((this.selectedActivity as IUserActivity).typeName) {
      this.selectedActivityType = (this.selectedActivity as IUserActivity).typeName;
    }
  }

  initialiseSelectedSkillName(goalSkills: ISkill[], activitySkills: ISkill[]) {
    if (!activitySkills) {
      console.error('initialiseSelectedSkillName called without any skills');
      return;
    }

    const skillsFromGoal = goalSkills;

    for (const skill of activitySkills) {
      if (skill) {
        skillsFromGoal.push(skill);
        this.addSkillToSelected(skill);
      } else {
        console.error('Skill is null');
      }
    }

    const allSkills = skillsFromGoal.filter((v, i, s) => s.findIndex((t) => t.id === v.id) === i);
    this.addSkillOptions(allSkills, this.skillOptions, 'goal');
  }

  initialSelectedActivityDuration() {
    if (!this.selectedActivity) {
      throw new Error('The SelectedActivity must be defined at this point');
    }

    const oneDay = 1000 * 60 * 60 * 24;
    let durationDays: number | undefined = 100;

    if (this.selectedActivity?.datePlannedDue) {
      if (this.selectedActivity.datePlannedDue) {
        durationDays = (new Date(this.selectedActivity.datePlannedDue).getTime() - new Date().getTime()) / oneDay;
      }
    }

    this.setActivityDurationFromDays(durationDays);
  }

  setActivityDurationFromDays(durationDays: number) {
    if (durationDays < 4) {
      this.activityDuration = durationDays * 5;
    } else if (durationDays < 15) {
      this.activityDuration = durationDays * 4;
    } else if (durationDays < 22) {
      this.activityDuration = durationDays * 3.5;
    } else if (durationDays < 35) {
      this.activityDuration = durationDays * 3;
    } else {
      this.activityDuration = 100;
    }
  }

  disabledDates = (currentDate: Date): boolean => (currentDate.toDateString() === new Date().toDateString() ? false : currentDate < new Date());

  getLearningTypes() {
    this.learningService.getLearningTypes().subscribe({
      next: (data) => {
        this.learningTypeOptions = this.buildLearningTypesOptions(data);
      },
      error: () => this.message.error('Error downloading learning types'),
    });
  }

  defaultDateDue() {
    const week = new Date();
    week.setDate(week.getDate() + 7);
    return week;
  }

  updateActivityDuration() {
    let date: Date | undefined = new Date();
    const aDay = 24;
    const now = date.getHours();

    switch (this.activityDuration) {
      case 5:
        date.setHours(now + aDay * 1);
        break; // Note: 1 day
      case 15:
        date.setHours(now + aDay * 3);
        break; // Note: 3 day
      case 28:
        date.setHours(now + aDay * 7);
        break; // Note: 1 week
      case 56:
        date.setHours(now + aDay * 14);
        break; // Note: 2 weeks
      case 84:
        date.setHours(now + aDay * 28);
        break; // Note: 4 weeks
      default:
        date = undefined; // Unprioritised
    }

    this.activityDateScheduled = date;
  }

  changeTargetDate(date: Date) {
    this.activityDateScheduled = date;

    const oneDay = 1000 * 60 * 60 * 24;
    const durationDays = (new Date(date).getTime() - new Date().getTime()) / oneDay;
    this.setActivityDurationFromDays(durationDays);

    const input = date.getTime();
    const today = new Date().getTime();

    if (input < today) {
      this.message.warning(`It's ok to be ambitious, but you'll want to give yourself a reasonable amount of time to complete this.`);
    }
  }

  getMatchingGoals() {
    this.goalOptions.push(...this.buildGoalOptions(this.matchingActivityGoals));
    if (this.goalOptions.length === 1) {
      this.selectedGoal = this.goalOptions[0];
      this.getRelatedGoalSkills();
    }
  }

  getGoals() {
    this.goalOptions.push(...this.buildGoalOptions(this.goalService.getGoals().filter((e) => e.dateCompleted == null)));
    const previousSelectedGoalLabel = this.localStorageService.get<string>('selectedGoalLabel');
    if (previousSelectedGoalLabel) {
      for (const g of this.goalOptions) {
        if (g.label === previousSelectedGoalLabel) {
          this.selectedGoal = g;
          break;
        }
      }
    }

    if (!this.selectedGoal && this.goalOptions.length > 0) {
      this.selectedGoal = this.goalOptions[0];
    }

    this.getRelatedGoalSkills();
  }

  getRelatedGoalSkills() {
    if (!this.selectedGoal) {
      throw new Error('The GoalSelected should be defined by now.');
    }

    if (this.selectedGoal.value.skills) {
      const skillOptionsForGoal = this.selectedGoal.value.skills.filter((v, i, s) => s.findIndex((t) => t.id === v.id) === i);
      this.addSkillOptions(skillOptionsForGoal, this.skillOptions, 'goal');
    } else {
      this.skillOptions = [];
    }
    this.setDisabledAddButton();
  }

  saveGoal() {
    if (!this.selectedGoal) {
      throw new Error('The GoalSelected should be defined by now.');
    }

    this.localStorageService.set('selectedGoalLabel', this.selectedGoal.label);

    if (this.selectedActivity) {
      this.selectedActivity.goalId = this.selectedGoal.value.id;
    }

    if (this.enableAllSkills) {
      // need to also clear out the `All` skills
      this.skillOptions = this.skillOptions.filter((so) => so.group !== 'all');
      this.enableAllSkills = false;
    }
  }

  /// attempts to add the skill to the activity so it appears selected.
  // at the moment, this is ONLY called from the popConfirm after adding a skill to an activity.
  // But it should have already added it and this won't be needed.
  // addSkillToActivity(category: HTMLInputElement, skill: HTMLInputElement): void {
  //   this.addSkillToActivityWithValue(category.value, skill.value);
  // }

  // /// attempts to add the skill to the activity so it appears selected. Called after we popconfirm the addition of the skill to the user's goal skills.
  // addSkillToActivityWithValue(category: string, skillName: string): void {
  //   const newSkill = { category, name: skillName } as ISkill;

  //   if (newSkill.name) {
  //     const skillInSkillOption = this.skillOptions.find((so) => so.name.toLowerCase() === newSkill.name.toLowerCase());
  //     const skillInNewSelectedSkill = this.newSelectedSkills.find((s) => s.name.toLowerCase() === newSkill.name.toLowerCase());

  //     // if the skill that was just added is not already in the skillOptions or the newSelectedSkills (and it shouldn't be)
  //     if (!skillInSkillOption && !skillInNewSelectedSkill) {
  //       this.newSelectedSkills.push(newSkill);
  //       const groupName = 'new';
  //       this.addSkillOption(newSkill, this.skillOptions, groupName);
  //       this.addSelectedSkill(newSkill);
  //     } else {
  //       if (this.selectedActivitySkillIds.findIndex((ssn) => ssn === newSkill.id) === -1) {
  //         if (skillInSkillOption !== undefined) {
  //           this.addSelectedViewSkill(skillInSkillOption);
  //         } else {
  //           // TODO: Send a request to the backend to add this skill to the database
  //           this.addSelectedSkill(newSkill);
  //         }
  //       }
  //     }
  //   }
  // }

  findSkillByName(inputSkill: string, inputCategory: string = ''): IViewSkillOption | undefined {
    const skillsFound = this.skillOptions.filter((n) => n.name.toLowerCase() === inputSkill.toLowerCase());
    if (!skillsFound) {
      return undefined;
    }

    if (skillsFound.length === 1) {
      if (!inputCategory) {
        // this.message.info(`A matching skill with the name '${inputSkill}' was found with the category '${skillsFound[0].category}'.`);
        return skillsFound[0];
      } else {
        this.message.error(`A matching skill with the name '${inputSkill}' was found, but with a different category '${skillsFound[0].category}'.`);
        return undefined;
      }
    }

    if (skillsFound.length > 1) {
      if (inputCategory) {
        const found = skillsFound.find((n) => n.category.toLowerCase() === inputCategory.toLowerCase());
        if (found) {
          return found;
        }

        // the skill name matches multiple times, the category doesn't and the category is not blank. We just have to trust the user and let them add the skill
        this.message.warning(`A matching skill with the name '${inputSkill}' was found with a few different categories. We hope you know what you're doing!.`);
        return undefined;
      } else {
        this.message.warning(`Multiple skills found with the name '${inputSkill}' already. If you need that skill in a different category, you'll need to specify a new category.\nPlease try and use an existing skill where possible.`, {
          nzDuration: 5000,
          nzPauseOnHover: true,
        });
        return undefined;
      }
    } else {
      // skillsFound.length === 0
      return undefined;
    }
  }

  confirmAddSkillToGoalManual(): void {
    const title = `this.selectedGoal.label`;

    this.modal.confirm({
      nzTitle: `Add skill to Goal ${title}`,
      nzContent: 'This skill is not currently a part of your goal. Do you want to <b>also</b> add it to your selected goal?',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOnOk: () => this.confirmAddSkillToGoal(),
      nzCancelText: 'Nup',
      nzOnCancel: () => console.log('Cancel'),
    });
  }

  // intended to be called after adding the skill to the activity when it's not already in the user's goals.
  confirmAddSkillToGoal(): void {
    if (!this.selectedGoal) {
      throw new Error('The GoalSelected should be defined by now.');
    }

    if (!this.selectedGoal.value) {
      return; // don't go adding things unless there's actually a valid skill name here...
    }
    if (!this.popConfirmSkill) {
      throw new Error('popConfirmSkill must be defined by definition at this point');
    }
    this.selectedGoal.value.skills.push(this.popConfirmSkill);
    this.goalService.updateGoal(this.selectedGoal.value).subscribe({
      // TODO: Call AddSkillToGoal instead of updating the entire goal
      next: (res) => {
        if (!this.selectedGoal) {
          throw new Error('selectedGoal must be defined by definition at this point');
        }
        if (!this.popConfirmSkill) {
          throw new Error('popConfirmSkill must be defined by definition at this point');
        }
        this.selectedGoal.value = res;
        this.showAddToGoalPopConfirm = false;
        this.delSkillOptions(this.popConfirmSkill);
        this.addSkillOption(this.popConfirmSkill, this.skillOptions, 'goal');
        this.popConfirmSkill = undefined;
      },
      error: (error: HttpErrorResponse) => {
        this.message.error('Hmm, seems like something went wrong while trying to update your goal');
        console.error(`*** ManualUserActivityComponent GoalService updateGoal failed. Error: ${error.message}`);
      },
    });
  }

  cancelAddSkillToGoalManual(): void {
    this.showAddToGoalPopConfirm = false;
  }

  addNewSkillElement(categoryElement: HTMLInputElement, skillElement: HTMLInputElement) {
    this.addNewSkill(categoryElement.value, skillElement.value);
  }

  // Called when the user clicks the `+Add Skill` link.
  // All it should do is add it to seaLadder, confirming if the user wants to add it to their goal once that returns and respond appropriately.
  // The only time we should be asking is if they are adding a skill that is not in their goal. That can happen if they select something from the `All` list and not from the `Goal` list.
  // Will add the skill to seaLadder so we have an ID, then it'll add it to the options and the selected values
  addNewSkill(category: string, name: string) {
    const foundSkill = this.findSkillByName(name, category);

    if (!foundSkill) {
      // If we don't find the skill, then we assume that we want to add it to the goal
      const skillModel = { name, category } as ISkill;

      this.skillService.addSkill(skillModel).subscribe({
        next: (newSkill) => {
          this.addExistingSkill(newSkill, 'new');
          // If the skill is not in the user's goal, then we should ask if they want to add it to their goal
          const foundSkillInGoal = this.selectedGoal?.value.skills.find((s) => s.name.toLowerCase() === newSkill.name.toLowerCase());
          if (!foundSkillInGoal) {
            this.popConfirmSkill = newSkill;
            this.showAddToGoalPopConfirm = true;
          }
        },
        error: (error) => {
          this.message.error('Hmm, seems like something went wrong while trying to add your skill');
          console.error(`*** ManualUserActivityComponent UserActivitiesService addSkill failed. Error: ${error.message}`);
        },
      });
    } else {
      // Check if the user has already added this skill to their activity...
      const alreadyAdded = this.newSelectedSkills.find((nso) => nso.id.toString() === foundSkill.id);
      if (alreadyAdded) {
        this.message.info(`You have already added '${name}' to your activity. Let's not be greedy.`);
        return;
      }

      this.addExistingSkill(this.viewSkillOptionToSkill(foundSkill), 'existing');
    }
  }

  enableAddSkills() {
    // go and get ALL of the skills so the user can add something in cleanly.
    this.enableAllSkills = true;
    const relatedSkills = document.querySelector('#related-skills nz-select-top-control nz-select-search input') as HTMLInputElement;
    if (relatedSkills) {
      relatedSkills.focus();
    }

    const exceptIds = this.skillOptions.map((so) => parseInt(so.id, 10));
    this.skillService.getAllSkills(exceptIds).subscribe({
      next: (skills) => {
        this.addSkillOptions(skills, this.skillOptions, 'all');
      },
      error: (error) => {
        console.error('*** ManualUserActivityComponent getAllSkills failed. Error: ' + error.message);
      },
    });
  }

  buildLearningTypesOptions(learningTypes: ILearningType[]) {
    const specOptions: string[] = [];

    for (let i = 0, len = learningTypes.length; i < len; i++) {
      specOptions.push(learningTypes[i].type);
    }

    return specOptions;
  }

  buildGoalOptions(goals: IGoal[]): { label: string, value: IGoal }[] {
    const specOptions: { label: string, value: IGoal }[] = [];

    for (let i = 0, len = goals.length; i < len; i++) {
      const { specialisation, level } = goals[i];
      specOptions.push({
        label: `${specialisation}/${level}`,
        value: goals[i],
      });
    }

    return specOptions;
  }

  closeModal() {
    this.isAYouTubePlaylist = false;
    this.closeModalEventListener.emit();
  }

  addExternallyManagedPlaylist() {
    this.isLoadingOne = true;
    this.userPlaylistService.addUserPlaylistFromExternalPlaylistUrl(this.selectedGoal?.value.id, this.selectedActivityUrl).subscribe({
      next: () => {
        this.message.success('Nice! Playlist Added.');
        this.isLoadingOne = false;
        this.closeModal();
      },

      error: (err: HttpErrorResponse) => {
        console.log(`*** An error occurred while adding this playlist: ${JSON.stringify(err.message)}`);
        this.isLoadingOne = false;
        if (err.status === 409) {
          this.message.warning(`You're eager. Looks like you have already have this playlist. Try something new`);
          this.isUrlDuplicateConfirm = true;
          this.setDisabledAddButton();
        } else {
          this.message.error('Error adding this external playlist. Please try again.');
        }
      },
    });
  }

  addBacklogItem(): IUserBacklog | undefined {
    if (this.hasActivity && this.hasActivityType && this.hasActivityTitle && this.isUrlDuplicateConfirm === undefined) {
      const newUserBacklogItem = {
        userId: this.authService.userId,
        link: this.selectedActivityUrl,
        title: this.selectedActivityTitle,
        learningType: this.selectedActivityType,
        dateStarted: this.immediateStart() ? new Date() : undefined,
        dateDue: this.activityDateScheduled,
        dateAdded: new Date(),
        goalId: this.selectedGoal?.value.id,
        completed: false,
        skills: [], // TODO: Why are we deliberately adding a backlog item and NOT setting the skills?
        hidden: false,
        notes: '',
      } as IUserBacklog;

      this.submitBacklogItem(newUserBacklogItem);
      return newUserBacklogItem;
    } else {
      this.message.warning(`An attepmnt to add a manual backlog item was made but the essential requiremnents are not met. Please check the form and try again.`);
    }

    return undefined;
  }

  public immediateStart(): boolean {
    return this.userOverrideImmediateStart !== undefined ? this.userOverrideImmediateStart : this.defaultImmediateStart;
  }

  public setUserOverrideImmediateStart(immediateStartValue: boolean) {
    this.userOverrideImmediateStart = immediateStartValue;
  }

  public resetActivityValeus() {
    this.selectedActivityTitle = undefined;
    this.selectedActivityUrl = undefined;
    this.selectedActivityType = undefined;
  }

  public handleOkDuplicateConfirmation() {
    this.isUrlDuplicateConfirm = true;
    this.isUrlDuplicateModalVisible = false;
    this.setDisabledAddButton();
  }

  public handleCancelDuplicateConfirmation() {
    this.isUrlDuplicateModalVisible = false;
    this.isUrlDuplicateConfirm = false;

    if (this.selectedActivity) {
      this.resetActivityValeus();
    }
    this.setDisabledAddButton();
  }

  public handleActivityTypeChange() {
    this.setDisabledAddButton();
  }

  public handleActivityTitleChange() {
    this.setDisabledAddButton();
  }

  /* eslint-disable @typescript-eslint/no-explicit-any*/
  public extractVideoDataFromUrl(link: string) {
    this.externalApiService.extractVideoData(link).subscribe({
      next: (data) => {
        if (!this.editActivityMode) {
          this.selectedActivitySkillIds = [];
        }
        if (undefined !== (data as any).items[0]) {
          const tags = (data as any).items[0].snippet.tags;
          for (const tag of tags) {
            const index = this.skillOptions.findIndex((s) => s.name.toUpperCase() === tag.toUpperCase());
            if (index !== -1) {
              if (this.skillOptions[index].group === 'goal') {
                this.skillOptions[index].group = 'suggested';
              } else {
                this.skillOptions[index].group = 'activity';
              }

              if (!this.editActivityMode) {
                if (!this.selectedActivitySkillIds.includes(this.skillOptions[index].id)) {
                  this.selectedActivitySkillIds.push(this.skillOptions[index].id);
                }
              }
            }
          }
        }
      },
      error: (err) => {
        console.error(err);
        this.message.error('Error fetching video data.');
      },
    });
  }

  public extractPlaylistDataFromUrl(link: string) {
    this.loadingMetadata = true;
    this.externalApiService.extractPlaylistData(link).subscribe({
      next: (data) => {
        this.urlMetadata = {} as IUrlMetadata;
        setTimeout(() => {
          if (this.urlMetadata) {
            let title = data.items[0].snippet.title;
            title = title === title.toLowerCase() ? toTitleCase(title) : title;
            const channelTitle = data.items[0].snippet.channelTitle;
            this.urlMetadata.title = channelTitle ? `${title} [${channelTitle}]` : title;
            this.urlMetadata.description = data.items[0].snippet.description;

            if (data.items[0].snippet.thumbnails) {
              const imageToUse = data.items[0].snippet.thumbnails.standard?.url ||
                data.items[0].snippet.thumbnails.medium?.url ||
                data.items[0].snippet.thumbnails.default?.url ||
                data.items[0].snippet.thumbnails.high?.url;

              this.urlMetadata.imageUrl = imageToUse;
            }

            this.selectedActivityTitle = this.urlMetadata.title;
            this.selectedActivityType = 'Playlist';
            this.loadingMetadata = false;
            this.setDisabledAddButton();
          }

        });
      },

      error: (err) => {
        this.loadingMetadata = false;
        console.error(err);
        this.message.error('Error fetching playlist data.');
      }
    });
  }

  //--Commented out until we need to use it. This method extracts the tags from the videos inside the YouTube playlist. Normally, these tags appear in the Related Skills section of the 'Add Activities' modal
  // public getTagsFromPlaylistActivities(link: string) {
  //   this.externalApiService.getTagsFromPlaylistActivities(link).subscribe({
  //     next: (data) => {
  //       this.selectedActivitySkillIds = [];
  //       if (undefined !== data) {
  //         const tags = data;
  //         for (const tag of tags) {
  //           const index = this.skillOptions.findIndex((s) => s.name.toUpperCase() === tag.toUpperCase());
  //           if (index !== -1) {
  //             if (this.skillOptions[index].group === 'goal') {
  //               this.skillOptions[index.group = 'suggested';
  //             } else {
  //               this.skillOptions[index].group = 'activity';
  //             }

  //             if (!this.editActivityMode) {
  //               if (!this.selectedActivitySkillIds.includes(this.skillOptions[index].id)) {
  //                 this.selectedActivitySkillIds.push(this.skillOptions[index].id);
  //               }
  //             }
  //           }
  //         }
  //       }

  //     },

  //     error: () => {

  //     }
  //   });
  // }

  public addSelectedSkillsToActivity(item: IUserActivity | IUserBacklog) {
    item.skills.length = 0;
    this.selectedActivitySkillIds.forEach((skillId) => {
      const skillIdNum = parseInt(skillId, 10);
      const selectedSkillFromGoal = this.selectedGoal?.value.skills.find((goalSkill) => goalSkill.id === skillIdNum);

      if (selectedSkillFromGoal) {
        item.skills.push(selectedSkillFromGoal);
        return;
      }
      const selectedSkillFromNew = this.newSelectedSkills.find((newSkill) => newSkill.id.toString() === skillId);
      if (selectedSkillFromNew) {
        item.skills.push(selectedSkillFromNew);
        return;
      }

      const optionSkill = this.skillOptions.find((skillOption) => skillOption.id === skillId);
      if (optionSkill) {
        const newISkill: ISkill = {
          id: skillIdNum,
          name: optionSkill.name,
          category: optionSkill.category,
        };
        item.skills.push(newISkill);
        return;
      }

      this.message.error(`Something went wrong adding skillId ${skillId} to the activity.`);
      throw new Error('failed to add a selected skill that to the userActivity provided.');
    });
  }

  public submitBacklogItem(backlogItem: IUserBacklog) {
    this.addSelectedSkillsToActivity(backlogItem);
    if (backlogItem.title == null) {
      this.message.warning(`We're going to need a title for this activity first`);
      return;
    }

    this.isLoadingOne = true;
    this.userActivitiesService.addBacklogItem(backlogItem).subscribe({
      next: (res) => {
        this.recommendation = res;
        this.isActivityRecommendationAdded = true;
        this.message.success('Nice! Activity Added.');
        this.isLoadingOne = false;
        this.updateTable.emit();
        this.closeModal();
      },
      error: (err: HttpErrorResponse) => {
        console.log(`*** An error occurred while adding a backlogItem: ${JSON.stringify(err.message)}`);
        this.isLoadingOne = false;
        if (err.status === 409) {
          this.message.warning(`You're eager. Looks like you have already have this activity. Try something new`);
          this.isUrlDuplicateConfirm = true;
          this.setDisabledAddButton();
        } else {
          this.message.error('Error adding activity. Please try again.');
        }
      },
    });
  }

  public updateActivity() {
    if (!this.selectedActivityTitle || !this.selectedActivity) {
      throw new Error('ActivityTitle or SelectedActivity cannot be null at this stage');
    }

    if (!this.selectedGoal) {
      this.message.error("You need to select a goal to update this activity.");
      return;
    }

    if (this.selectedGoal.value.deleted) {
      this.message.error("The goal you selected is currently marked as deleted. You'll want to select an active goal");
      return;
    }

    this.selectedActivity.title = this.selectedActivityTitle;
    this.addSelectedSkillsToActivity(this.selectedActivity);
    this.selectedActivity.datePlannedDue = this.activityDateScheduled;
    if (this.textModalText) {
      this.selectedActivity.notes = this.textModalText;
    }

    this.isLoadingOne = true;
    this.userActivitiesService.updateUserActivity(this.selectedActivity as IUserActivity).subscribe({
      next: (result) => {
        this.message.success('Activity Updated.');
        this.isLoadingOne = false;
        this.selectedActivity = result;
        this.closeModal();
      },
      error: (error) => {
        console.error(`**** An error occurred while updating the activity: ${JSON.stringify(error)}`);
        this.isLoadingOne = false;
        this.message.error('Error updating activity. Please try again.');
      },
    });
  }

  public getMetaData() {
    this.isAYouTubePlaylist = false;
    this.isUrlDuplicateConfirm = undefined;

    const getMetaDataUrl = (): string | undefined => {
      if (this.editActivityMode) {
        if (!this.selectedActivity) {
          throw new Error('SelectedActivity cannot be null at this point');
        }
        if (this.selectedActivity.link) {
          return this.selectedActivity.link.trim();
        } else {
          return undefined;
        }
      } else {
        if (this.selectedActivityUrl) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return this.selectedActivityUrl!.trim();
        } else {
          return '';
        }
      }
    };

    const url = getMetaDataUrl();

    if (url === undefined) {
      return; // they probably navigated off the URL without setting
    }

    this.urlMetadata = null;
    if (this.isBadUrl(url)) {
      this.message.error(`Invalid url, we can't get MetaData for this: ${url}`);
      this.selectedActivityTitle = '';
    } else {
      if (url?.includes('youtube.com/playlist?list=PL')) {
        this.isAYouTubePlaylist = true;
        this.extractPlaylistDataFromUrl(url);
      } else if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
        this.extractVideoDataFromUrl(url);
      }

      // check if url is duplicated
      if (this.activities !== undefined && this.activities !== null) {
        this.isUrlDuplicateModalVisible = this.activities.map((item) => item.link).includes(url);

        if (this.isUrlDuplicateModalVisible) {
          this.message.warning(`You have this activity already.  Try adding something new?`);
          this.isUrlDuplicateConfirm = false;
        }
      }

      this.setDisabledAddButton();

      if (!this.isAYouTubePlaylist) {
        this.loadingMetadata = true;
        this.userActivitiesService.getActivityMetadata(url).subscribe({
          next: (result) => {
            this.urlMetadata = null;
            if (result.urlMetadata.title !== null) {
              this.urlMetadata = result.urlMetadata;
              this.selectedActivityTitle = this.editActivityMode && this.selectedActivity?.title ? this.selectedActivity.title : result.urlMetadata.title;
              this.selectedActivityType = result.learningType.value;
            }
            this.addFoundMetaDataSkills(result);
            this.setDisabledAddButton();
            this.loadingMetadata = false;
          },
          error: (error) => {
            console.error(error.message);
            this.loadingMetadata = false;
          },
        });
      }
      this.updateActivityType.emit(this.isAYouTubePlaylist);
    }
  }

  public hasSkills(): boolean {
    if (this.selectedActivitySkillIds.length === 0) {
      this.defaultImmediateStart = false;
      return false;
    } else {
      this.defaultImmediateStart = true;
      return true;
    }
  }

  private viewSkillOptionToSkill(option: IViewSkillOption): ISkill {
    return {
      id: parseInt(option.id, 10),
      name: option.name,
      category: option.category,
    };
  }

  private addSkillOptions(skills: ISkill[], viewSkillOptions: IViewSkillOption[], skillGroup: string) {
    for (let i = 0, len = skills.length; i < len; i++) {
      this.addSkillOption(skills[i], viewSkillOptions, skillGroup);
    }
    this.sortSkillOptions(viewSkillOptions);
  }

  private addSkillOption(skill: ISkill, viewSkillOptions: IViewSkillOption[], skillGroup: string) {
    const category = skill.category ? skill.category : 'Uncategorised'; // since this is the list of options, don't show an empty category as it looks rediculous
    viewSkillOptions.push({
      name: skill.name,
      id: skill.id?.toString(),
      category,
      group: skillGroup,
    });
  }

  private delSkillOptions(skill: ISkill, skillGroup: string = '') {
    const id = this.skillOptions.findIndex((so) => (so.id === skill.id.toString() && !skillGroup) || so.group === skillGroup);
    if (id > -1) {
      this.skillOptions.splice(id, 1);
    }
  }

  private sortSkillOptions(specOptions: IViewSkillOption[]) {
    specOptions.sort((a, b) => {
      // Note: sort by category in alphabetical order
      const textA = a.category.toUpperCase();
      const textB = b.category.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }

  private addExistingSkill(skill: ISkill, groupName: string = 'goal') {
    this.newSelectedSkills.push(skill);
    if (groupName !== 'existing') {
      this.addSkillOption(skill, this.skillOptions, groupName);
    } else {
      this.delSkillOptions(skill);
      this.addSkillOption(skill, this.skillOptions, 'new');
    }
    this.addSkillToSelected(skill);
    this.setDisabledAddButton();
  }

  private addSkillToSelected(skill: ISkill) {
    if (!skill.id) {
      console.error('Skill id is null');
      return;
    }
    if (!this.selectedActivitySkillIds.includes(skill.id.toString())) {
      this.selectedActivitySkillIds.push(skill.id.toString());
    }
  }

  private setDisabledAddButton() {
    if (!this.selectedGoal) {
      this.isAddButtonDisabled = true;
      this.isAddButtonDisabledText = `A goal must be selected.`;
      return;
    }

    if (this.isUrlDuplicateConfirm !== undefined) {
      this.isAddButtonDisabled = true;
      this.isAddButtonDisabledText = `Duplicate activities aren't permitted.`;
      return;
    }

    if (!this.selectedActivityType) {
      this.isAddButtonDisabled = true;
      this.isAddButtonDisabledText = 'Activity type needs to be specified';
      return;
    }

    if (!this.selectedActivityTitle || this.selectedActivityTitle.length === 0) {
      this.isAddButtonDisabled = true;
      this.isAddButtonDisabledText = `The activity needs to have a title`;
      return;
    }

    this.isAddButtonDisabled = false;
    this.isAddButtonDisabledText = '';
  }

  private isBadUrl(str: string): boolean {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+#@]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator

    return !pattern.test(str);
  }

  private addFoundMetaDataSkills(res: IActivityMetadata) {
    const newSkills: ISkill[] = [];
    res.activitySkills.forEach((s) => {
      if (this.skillOptions.filter((so) => so.name === s.name).length > 0) {
        if (!this.editActivityMode) {
          if (this.selectedActivitySkillIds.filter((sn) => sn.toLowerCase() === s.name.toLowerCase()).length === 0) {
            this.addSkillToSelected(s);
          }
        }
      } else {
        newSkills.push(s);
      }
    });
    if (newSkills.length > 0) {
      this.addSkillOptions(newSkills, this.skillOptions, 'activity');
    }
  }
}
