import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// models
import { ISuggestion } from './../models/suggestion.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class SuggestionsService {
  endpoint = `/api/Suggestions/`;
  constructor(private http: HttpClient) {}

  public getAllSuggestions(): Observable<ISuggestion[]> {
    return this.http.get<ISuggestion[]>(this.endpoint + `/Get`);
  }

  public addSuggestion(suggestion: ISuggestion): Observable<boolean> {
    const url = this.endpoint + 'AddSuggestion';
    return this.http.post<boolean>(url, suggestion, httpOptions);
  }
}
