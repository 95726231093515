import { Component, OnInit } from '@angular/core';
import { AppDetailsService } from 'src/app/services/app-details.service';

@Component({
  selector: 'sl-app-details',
  templateUrl: './app-details.component.html',
  styleUrls: ['./app-details.component.less']
})
export class AppDetailsComponent implements OnInit {
  version = 'unknown';
  dbConnected = false;

  constructor(private appDetailsService: AppDetailsService) { }

  ngOnInit(): void {
    this.appDetailsService.getAppDetailsData().subscribe(
      ret => {
        this.version = ret.version;
        this.dbConnected = ret.dbConnected;
      }
    );
  }
}
