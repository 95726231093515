<nz-drawer id="activity-contents-drawer" [nzClosable]="true" [nzWidth]="500" [nzVisible]="showDrawer" nzTitle="{{recommendation?.title}}" (nzOnClose)="closeDrawer()">
  <ng-container *nzDrawerContent>
    <div class="panel-container">
      <img *ngIf="!showLearningTypeIcon" class="panel-image" src="{{fileUrl}}" (error)="handleMissingImage($event)" />
      <i *ngIf="showLearningTypeIcon" class="learning-type-icon {{learningTypeIcon}}"></i>
      <div class="panel-grid">
        <a target="_blank" href="{{recommendation.link}}">
          <button class="panel-link" nz-button nzType="default" nzShape="round">
            <i class="fas fa-globe-asia"></i>
            <span class="shorter-url-text">{{shorterUrl}}</span>
          </button>
        </a>
        <div class="panel-type">
          <span>{{recommendation.typeName}}</span>
        </div>
        <div class="panel-learningStyle">
          <i class="fas fa-book-reader fa-2x card-learning-style" *ngIf="recommendation.learningStyle === 'Exposure'" nz-tooltip nzTooltipTitle="Exposure"></i>
          <i class="fas fa-award fa-2x card-learning-style" *ngIf="recommendation.learningStyle === 'Credibility'" nz-tooltip nzTooltipTitle="Credibility"></i>
          <i class="fas fa-tools fa-2x card-learning-style" *ngIf="recommendation.learningStyle === 'Experience'" nz-tooltip nzTooltipTitle="Experience"></i>
          <i class="fa fa-cog fa-2x card-learning-style" *ngIf="!recommendation.learningStyle" nz-tooltip nzTooltipTitle="Uncategorised"></i>
        </div>
      </div>
      <div class="panel-skills">
        <h5>Skills: </h5>
        <span *ngFor="let skill of recommendation.skills; let isLast=last">
          <sl-core-skill-icon *ngIf="skill.coreSkill" [isACoreSkill]="skill.coreSkill" [tooltipVisible]="true" [tooltip]="skill.name + ' has been marked as a core skill for your career'"></sl-core-skill-icon> {{skill.name}}{{isLast ? '' : ', '}}
        </span>
      </div>
      <div class="panel-description">
        <h5>Description</h5>
        <p>{{recommendation.description === null ? "There is no description for this recommendation." : recommendation.description}}</p>
      </div>
      <div class="panel-add-backlog">
        <div *ngIf="!addedToBacklog && !isActivityListMode">
          <button class="panel-add-backlog-button" nz-button nzType="primary" (click)="addToBacklog()">
            <i class="fas fa-plus"></i>
            <span class="add-button-text">
              Add activity
            </span>
          </button>
        </div>
        <div *ngIf="addedToBacklog">
          <i class="panel-add-backlog-button checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'"></i>
        </div>
      </div>
    </div>
  </ng-container>
</nz-drawer>