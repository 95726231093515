<!-- try this for a two-tone icon once ng-zorro sorts out issues with it not being loaded in testing module inclusion -->
<i *ngIf="showHelpIcon()" class="fas fa-info-circle help-info-icon" nz-popover [nzPopoverTitle]="title" [nzPopoverContent]="template !== undefined ? template : contentTemplate" nzPopoverPlacement="top" [(nzPopoverVisible)]="visible"
  (nzPopoverVisibleChange)="change($event)" nzPopoverTrigger="click">
</i>

<ng-template #contentTemplate>
  <div class="default-help-content">
    <img class="help-image" [src]="image">
    <i *ngIf="showContentIcon()" class="help-content-icon" nz-icon [nzType]="contentIconNgZorroDuo" nzTheme="twotone"></i>
    <div class="help-text" [innerHTML]="description"></div>
  </div>
</ng-template>