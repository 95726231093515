<div *ngIf="show" id="onboarding-component">
  <nz-divider nzText="Get Cracking"></nz-divider>
  <span *ngFor="let task of tasksToComplete">
    <nz-tag *ngIf="task.taskValue" nzColor="success" class="task"><i nz-icon nzType="check"></i> {{ task.taskName }}</nz-tag>

    <nz-tag *ngIf="!task.taskValue" nzColor="default" class="task">

      <sl-help-info *ngIf="task.taskName === 'Add Recommendation'" contentIconNgZorroDuo="thunderbolt" title="Follow a path others have set out" description="We have a list of activities that have helped other users reach the same goals that you have. 
        Head over to the recommendations page and try adding activities or even curated playlists of activities that will help you achieve your goal.">
      </sl-help-info>

      <sl-help-info *ngIf="task.taskName === 'Complete Activity'" contentIconNgZorroDuo="calendar" title="Take your first step" description="Completing activities helps build your skills and recording them on SeaLadder lets us highlight your top skills and 
        recommend new activities to help you on your journey.">
      </sl-help-info>

      <sl-help-info *ngIf="task.taskName === 'Add Provider'" contentIconNgZorroDuo="api" title="Help us, help you" description="Providers are external services where you have gained exposure, experience, and/or credibility 
        towards your goals. We connect to these providers and synchronise the data with your profile.">
      </sl-help-info>

      <sl-help-info *ngIf="task.taskName === 'Fill Profile'" contentIconNgZorroDuo="idcard" title="Tell us more about yourself" description="Personalise your profile by adding a picture and filling out some extra details.">
      </sl-help-info>

      <sl-help-info *ngIf="task.taskName === 'Join a Guild'" contentIconNgZorroDuo="like" title="Find your people" description="Guilds provide a way for you to connect and learn from other people. Find or start your community within the Guilds of SeaLadder">
      </sl-help-info>

      <a class="task-title incomplete" [ngClass]="'nav-' + getClassNameFromTaskName(task.taskName)" (click)="$event.stopPropagation(); goToPage(task.taskName)">{{ task.taskName }}</a>
    </nz-tag>
  </span>
</div>