import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// pipes
import { GlpyhSelectorPipe } from './components/glyph-selector/glpyh-selector.pipe';
import { LimitTextLengthPipe } from './components/link-selector/limit-text-length.pipe';
import { RemoveUrlProtocolPipe } from './components/link-selector/remove-url-protocol.pipe';
import { SingularPluralPipe } from '../generic-pipes/singular-plural.pipe';

// modules
import { LocalStorageDirective } from 'ngx-localstorage';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { RouterModule } from '@angular/router';

// components
import { ActivityCardComponent } from './../components/browse-recommendations/recommendation-goal/activity-card/activity-card.component';
import { ActivityContentsDrawerComponent } from './components/activity-contents-drawer/activity-contents-drawer.component';
import { CoreSkillIconComponent } from './core-skill-icon/core-skill-icon.component';
import { CreatePlaylistComponent } from '../components/admin/playlists/create-playlist/create-playlist.component';
import { GlyphSelectorComponent } from './components/glyph-selector/glyph-selector.component';
import { GuildEditorComponent } from './components/guild-editor/guild-editor.component';
import { GoalSelectorComponent } from './components/goal-selector/goal-selector.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { IndustrySelectorComponent } from './components/industry-selector/industry-selector.component';
import { LevelSelectorComponent } from './components/level-selector/level-selector.component';
import { LinkSelectorComponent } from './components/link-selector/link-selector.component';
import { MyPlaylistsComponent } from './../components/profile-v2/profile-playlists/my-playlists/my-playlists.component';
import { PlaylistSelectorCardsComponent } from './playlist-selector/playlist-selector-cards/playlist-selector-cards.component';
import { PlaylistSelectorComponent } from './playlist-selector/playlist-selector.component';
import { PlaylistViewerComponent } from 'src/app/components/playlist-viewer/playlist-viewer.component';
import { ProfessionSelectorComponent } from './components/profession-selector/profession-selector.component';
import { SpecialisationSelectorComponent } from './components/specialisation-selector/specialisation-selector.component';
import { StringValidationPipe } from '../generic-pipes/string-validation.pipe';
import { TrackSelectorComponent } from './components/track-selector/track-selector.component';
import { ProviderIconComponent } from './components/provider-icon/provider-icon.component';

@NgModule({
  declarations: [
    ActivityContentsDrawerComponent,
    ActivityCardComponent,
    CoreSkillIconComponent,
    CreatePlaylistComponent,
    GlpyhSelectorPipe,
    GlyphSelectorComponent,
    GuildEditorComponent,
    ImageUploaderComponent,
    IndustrySelectorComponent,
    LevelSelectorComponent,
    LimitTextLengthPipe,
    LinkSelectorComponent,
    MyPlaylistsComponent,
    PlaylistSelectorCardsComponent,
    PlaylistSelectorComponent,
    PlaylistViewerComponent,
    ProfessionSelectorComponent,
    ProviderIconComponent,
    RemoveUrlProtocolPipe,
    SpecialisationSelectorComponent,
    TrackSelectorComponent,
    SingularPluralPipe,
    StringValidationPipe,
    GoalSelectorComponent,
  ],
  imports: [
    CommonModule, //
    FormsModule,
    NzAlertModule,
    NzAvatarModule,
    NzBadgeModule,
    NzButtonModule,
    NzCardModule,
    NzDividerModule,
    NzDrawerModule,
    NzDropDownModule,
    NzFormModule,
    NzIconModule,
    NzImageModule,
    NzInputModule,
    NzInputNumberModule,
    NzMessageModule,
    NzMenuModule,
    NzModalModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzSelectModule,
    NzSkeletonModule,
    NzSpaceModule,
    NzStepsModule,
    NzSpinModule,
    NzTagModule,
    NzToolTipModule,
    NzUploadModule,
    LocalStorageDirective,
    NzTypographyModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    ActivityCardComponent,
    ActivityContentsDrawerComponent,
    CoreSkillIconComponent,
    CreatePlaylistComponent,
    GlpyhSelectorPipe,
    GlyphSelectorComponent,
    GuildEditorComponent,
    ImageUploaderComponent,
    IndustrySelectorComponent,
    LevelSelectorComponent,
    LimitTextLengthPipe,
    LinkSelectorComponent,
    MyPlaylistsComponent,
    PlaylistSelectorCardsComponent,
    PlaylistSelectorComponent,
    PlaylistViewerComponent,
    ProviderIconComponent,
    ProfessionSelectorComponent,
    RemoveUrlProtocolPipe,
    SpecialisationSelectorComponent,
    SingularPluralPipe,
    StringValidationPipe,
    TrackSelectorComponent,
    GoalSelectorComponent
  ],
})
export class GenericComponentsModule { }
