import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// services
import { RegisterService } from 'src/app/services/register.service';

@Component({
  selector: 'sl-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less'],
})
export class RegisterComponent implements OnInit {
  formValues!: UntypedFormGroup;
  specialisations!: string[];
  isVisible = false;

  constructor(private fb: UntypedFormBuilder, private registerService: RegisterService, private router: Router) {}

  ngOnInit(): void {
    this.initCurrentRoles();
    this.initFormFields();
  }

  initCurrentRoles(): void {
    this.specialisations = [
      // TODO: We REALLY should just get this from the backend, but this is fine for the landing page
      'Backend Developer',
      'Data Engineer',
      'DevOps',
      'Data Analyst',
      'Data Scientist',
      'Mobile Developer',
      'Frontend Developer',
      'Games Developer',
      'Other',
    ];
  }

  initFormFields(): void {
    this.formValues = this.fb.group({
      firstName: '',
      lastName: '',
      email: ['', [Validators.required]],
      city: '',
      profession: '',
    });
  }

  showModal(): void {
    this.isVisible = true;
    this.registerService.register(this.formValues).subscribe(
      () => {},
      (err) => {
        console.error(err);
      }
    );
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.isVisible = false;
    this.router.navigate(['landing']);
  }
}
