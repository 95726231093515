<div class="pricing-header">
  <h1>Pricing</h1>
  <br>
  <h3>Beta Launch Special</h3>
  <p class="description">
    June to August - join a cohort as a founding member and receive your first 3 billed months massively discounted<br>
    All new users during our Beta Launch will be have personalised onboarding through our cohort program.<br>
    <br>
    The program is a 4 week course whereby SeaLadder will conduct professional training, commencing with onboarding,<br>
    ending with a shared project. Each cohort will be themed on a chosen topic or one that is commonly requested. </p>
</div>
<div class="pricing-content">
  @for (plan of pricingPlans; track plan.title){
    <nz-card id="pricing-card">
      <div class="pricing-header">
        <h2>{{plan.title}}</h2>
         <div class="price">
           <span class="amount crossed">{{plan.price.original | currency}}</span>
           <span class="amount">{{plan.price.discounted | currency}}</span>
           <span class="per-month">per Month</span>
         </div>
         <p class="description">{{plan.description }}</p>
         <button  nz-button nzType="default" (click)="onJoinNow()">Join Now</button>
       </div>

       <hr/>
       
        <div class="features">
          @for (feature of plan.features; track feature){
            <div class="feature">
              <i class="fa-regular fa-circle-check fa-2x"></i>
              <h4>{{feature}}</h4>
            </div>
          }
       </div>
     </nz-card>
  }
</div>
<div class="pricing-info">
  <img class="info-logo" src='/assets/img/sealadder/SL_logo_Cropped_692x422.png' alt="logo" />
  <p class="description">
    Our #1 priority is to help people get ahead in their career, so if you would like to continue to use SeaLadder beyond your trial but are unable to afford it right now, we’ve still got ya.
    Just send us an email to <a href="mailto:support@sealadder.com">support&#64;sealadder.com</a> briefly outlining your situation and we will gladly extend your access until you’re on your feet again.
    Signup for your 1 month <b>free</b> trial - no credit card required. Your trial begins the first day of your cohort onboarding.
  </p>
</div>

