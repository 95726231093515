import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPlaylist } from 'src/app/models/playlist.model';
import { ISkill } from 'src/app/models/skill.model';
import { ISuggestedAction } from 'src/app/models/suggested-action.model';
import { IRecommendation } from '../../../models/recommendation.model';

export interface FilterOptions {
  link: string; // currently recommendation doesn't have specific id, therefore use link to make it unique
  category: string;
  skillName: string;
  typeName: string;
  checked?: boolean;
}

@Component({
  selector: 'sl-recommendation-goal',
  templateUrl: './recommendation-goal.component.html',
  styleUrls: ['./recommendation-goal.component.less'],
})
export class RecommendationGoalComponent implements OnInit, OnChanges {
  @Input() hasRecommendations!: boolean;
  @Input() goalSkills!: ISkill[];
  @Input() searchedRecommendations!: IRecommendation[];
  @Input() goalId!: number;
  @Input() activityLink!: string;
  @Input() playlists!: IPlaylist[];
  @Input() actions!: ISuggestedAction[];

  @Output() activityAdded = new EventEmitter<[number, number]>();
  @Output() playlistAdded = new EventEmitter<[number, number]>();
  @Output() actionAdded = new EventEmitter<[number, number]>();

  isFilter = false;
  title = 'Section header';
  recommendations!: IRecommendation[];
  filteredRecommendations!: IRecommendation[];
  skillsName!: string[];
  selectedSkillsName: string[] = [];
  categories!: string[];
  selectedCategories: string[] = [];
  typeNames!: string[];
  selectedTypesName: string[] = [];
  isViewable = false;

  filterOptions!: FilterOptions[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.initialiseFilterData();
  }

  ngOnChanges() {
    if (this.recommendations != null) {
      this.recommendations = this.searchedRecommendations;
      this.setFilterOptions(this.recommendations);
      this.filterRecommendations();
    }
  }

  initialiseFilterData(): void {
    this.recommendations = this.searchedRecommendations;
    this.filteredRecommendations = this.searchedRecommendations;
    const skillName = this.route.snapshot.paramMap.get('skillName');

    this.setFilterOptions(this.recommendations);

    this.skillsName = [...new Set(this.filterOptions.map((o) => o.skillName))];
    this.categories = [...new Set(this.filterOptions.map((o) => o.category))];
    this.typeNames = [...new Set(this.filterOptions.map((o) => o.typeName))];

    if (skillName) {
      this.filterOptions = this.filterOptions.map((o) => {
        o.checked = o.skillName === skillName ? true : false;
        return o;
      });
      this.isFilter = true;
    }
    this.filterRecommendations();
  }

  public handleChangeCategories(isChecked: boolean, tag: string): void {
    this.filterOptions = this.filterOptions.map((o) => {
      o.checked = o.category === tag ? isChecked : o.checked;
      return o;
    });
    this.filterRecommendations();
  }

  public handleChangeSkillNames(isChecked: boolean, tag: string): void {
    this.filterOptions = this.filterOptions.map((o) => {
      o.checked = o.skillName === tag ? isChecked : o.checked;
      return o;
    });
    this.filterRecommendations();
  }

  public handleChangeTypeNames(isChecked: boolean, tag: string): void {
    console.table(this.filterOptions);
    this.filterOptions = this.filterOptions.map((o) => {
      o.checked = o.typeName === tag ? isChecked : o.checked;
      return o;
    });
    this.filterRecommendations();
  }

  public resetSelectedCategories(): void {
    for (const category of this.categories) {
      this.filterOptions = this.filterOptions.map((o) => {
        o.checked = o.category === category ? true : o.checked;
        return o;
      });
    }
    this.filterRecommendations();
  }

  public resetSelectedSkills(): void {
    for (const skill of this.skillsName) {
      this.filterOptions = this.filterOptions.map((o) => {
        o.checked = o.skillName === skill ? true : o.checked;
        return o;
      });
    }
    this.filterRecommendations();
  }

  public resetSelectedTypes(): void {
    for (const type of this.typeNames) {
      this.filterOptions = this.filterOptions.map((o) => {
        o.checked = o.typeName === type ? true : o.checked;
        return o;
      });
    }
    this.filterRecommendations();
  }

  public handlePlaylistAdded(playlistId: number) {
    // this is called as a result of the the event that is fired when a playlist is added.
    this.playlistAdded.emit([this.goalId, playlistId]);
  }

  public handleActivityAdded(activityId: number) {
    // this is called as a result of the the event that is fired when an activity is added.
    this.activityAdded.emit([this.goalId, activityId]);
  }

  public handleActionAdded(actionId: number) {
    this.actionAdded.emit([this.goalId, actionId]);
  }

  private setFilterOptions(recommendations: IRecommendation[]) {
    this.filterOptions = [];

    for (const recommendation of recommendations) {
      const skills = recommendation.skills;
      for (const skill of skills) {
        if (!recommendation.typeName) {
          console.log(`Detected the typename isn't set. Title: ${recommendation.title}. Link: ${recommendation.link}`);
          recommendation.typeName = '';
        }
        const options: FilterOptions = {
          link: recommendation.link,
          category: skill.category,
          skillName: skill.name,
          typeName: recommendation.typeName,
          checked: true,
        };
        this.filterOptions.push(options);
      }
    }

    if (!this.filterOptions) {
      console.warn('we have recommendations and not one of them have skills. This seems somewhat wrong as we build up the filter options for skills based on the skills that are there.');
    }
  }

  private setSelectedValues() {
    this.selectedCategories = [];
    this.selectedSkillsName = [];
    this.selectedTypesName = [];

    for (const option of this.filterOptions) {
      if (option.checked) {
        this.selectedCategories.push(option.category);
        this.selectedSkillsName.push(option.skillName);
        this.selectedTypesName.push(option.typeName);
      }
    }
    this.selectedCategories = [...new Set(this.selectedCategories)];
    this.selectedSkillsName = [...new Set(this.selectedSkillsName)];
    this.selectedTypesName = [...new Set(this.selectedTypesName)];
  }

  private filterRecommendations(): void {
    this.setSelectedValues();

    const filteredOptions = this.filterOptions.filter((option) => option.checked?.valueOf() === true);
    let recommendations: IRecommendation[] = [];

    for (const option of filteredOptions) {
      recommendations = recommendations.concat(this.searchedRecommendations.filter((item) => item.link.includes(option.link)));
    }

    this.filteredRecommendations = recommendations.filter((recommendation, i, arr) => arr.findIndex((t) => t.link === recommendation.link) === i);
  }
}
