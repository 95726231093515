import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SingularPlural'
})
export class SingularPluralPipe implements PipeTransform {
  transform(count: number, singularText: string, pluralText: string | undefined = undefined): string {
    const pluralWord = pluralText ? pluralText : `${singularText}s`;
    return count > 1 || count === 0 ? `${count} ${pluralWord}` : `${count} ${singularText}`;
  }
}
