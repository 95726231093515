import { IPlaylist } from 'src/app/models/playlist.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sl-playlist-selector',
  templateUrl: './playlist-selector.component.html',
  styleUrls: ['./playlist-selector.component.less'],
})
export class PlaylistSelectorComponent {
  @Output() playlistReturned = new EventEmitter<IPlaylist>();
  @Input() playlistsReceived!: IPlaylist[];
  placeholderImage = '/assets/img/placeholder_image.png';

  constructor() {}

  handlePlaylistAdded(playlist: IPlaylist) {
    this.playlistReturned.emit(playlist);
  }

  getPlaceholderImage(): string {
    return this.placeholderImage;
  }
}
