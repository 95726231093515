import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// services
import { AccountDetailsService } from './../../../../services/account-details.service';

import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

// pipes
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FormatGoalDurationPipe } from 'src/app/components/profile-v2/format-goal-duration.pipe'; // used in HTML
// services
import { GoalService } from 'src/app/services/goal.service';
// models
import { IGoal } from 'src/app/models/user-goal.model';
import { IGoalSignatureBase } from 'src/app/models/goal-signature.model';
import { ESubscriptionStatus } from 'src/app/models/user-profile.model';
import { Location } from '@angular/common';

@Component({
  selector: 'sl-goal-table',
  templateUrl: './goal-table.component.html',
  styleUrls: ['./goal-table.component.less'],
})
export class GoalTableComponent implements OnInit, OnDestroy {
  @Input() goalsPublic?: IGoal[];

  goalToBeEdited: IGoal | undefined;
  addGoalModalIsOpen = false;
  goals!: IGoal[];
  hasActiveGoals = false;
  userSubscriptionStatus?: ESubscriptionStatus;
  isSubscribedUser = false;
  isSubscribedLoading = true;
  onChangeGoal!: Subscription;

  // param hints on the goal
  industry!: string;
  profession!: string;
  specialisation!: string;
  level!: number;
  goalId!: number;

  constructor(
    private goalService: GoalService,
    private message: NzMessageService,
    private modal: NzModalService,
    private router: Router,
    private accountDetailsService: AccountDetailsService,
    private location: Location,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.goalsPublic !== undefined) {
      this.goals = this.goalsPublic;
    } else {
      this.getGoalsInit();
      this.getSubscribedUser();

      if (this.route.queryParams) {
        this.route.queryParams.subscribe({
          next: (params) => {
            if (params.guild || params.playlistId || params.activityId) {
              this.addGoalModalIsOpen = true;
            }

            if (params.goalId) {
              this.goalToBeEdited = this.goalService.getGoal(parseInt(params.goalId, 10));
            }

            if (params.playlistId || params.activityId) {
              if (params.goalId) {
                this.goalService.getGoalSignature(parseInt(params.goalId, 10)).subscribe({
                  next: (res: IGoalSignatureBase) => {
                    // We try to match the industry, profession, specialisation and levels from the goal first
                    this.industry = res.industry ? res.industry : '';
                    this.profession = res.profession ? res.profession : '';
                    this.specialisation = res.specialisation ? res.specialisation : '';

                    const levelMatch = res.level ? res.level.match(/\d+/) : null;
                    this.level = levelMatch ? parseInt(levelMatch[0], 10) : 1;

                    // If we can fill these fields from the parameters, then do that instead
                    this.industry = params.industry ? params.industry : this.industry;
                    this.profession = params.profession ? params.profession : this.profession;
                    this.specialisation = params.specialisation ? params.specialisation : this.specialisation;
                    this.level = params.level ? params.level : this.level;
                  },
                  error: (error) => {
                    // Just console log an error because the lack of a goal should not stop the user flow
                    console.error(`*** Goal Table component: Failed to get goal. Error: ${error}`);
                  }
                })
              } else {
                this.industry = params.industry;
                this.profession = params.profession;
                this.specialisation = params.specialisation;
                this.level = params.level;
              }

            }
          }
        });
      } else {
        console.log(`QueryParams on the route should be initialised, but if it's not, it might be because we haven't setup the test case correctly`);
      }
    }
  }

  getSubscribedUser() {
    this.accountDetailsService.loadCurrentUser().subscribe({
      next: (userProfile) => {
        if (userProfile !== null) {
          this.userSubscriptionStatus = userProfile.subscriptionStatus;
          if (this.userSubscriptionStatus === ESubscriptionStatus.Trial ||
            this.userSubscriptionStatus === ESubscriptionStatus.Free ||
            this.userSubscriptionStatus === ESubscriptionStatus.Subscribed ||
            this.userSubscriptionStatus === ESubscriptionStatus.Cancelled) {
            this.isSubscribedUser = true;
          }
        }
        this.isSubscribedLoading = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.onChangeGoal) {
      this.onChangeGoal.unsubscribe();
    }
  }

  getGoalsInit(): void {
    this.initialiseWithLatestGoals();

    this.onChangeGoal = this.goalService.getGoalsUpdatedEvent().subscribe(
      () => {
        this.initialiseWithLatestGoals();
      },
      (error) => {
        console.error(`**** Error gettingGoalsUpdateEvent: ${error}`);
        this.message.error('Error loading goals');
      },
      () => console.log(`**** ProfileOverviewComponent - Cancel on the getGoalsUpdatedEvent`)
    );
  }

  showAddGoalModal(): void {
    if (this.isSubscribedUser) {
      this.addGoalModalIsOpen = true;
      return;
    }

    if (!this.isSubscribedUser && this.goals.length > 0) {
      this.modal.confirm({
        nzTitle: '<i>Would you like to subscribe</i>',
        nzContent: '<b>You\'re currently on a trial account. <br> Would you like to subscribe to get the ability to add multiple concurrent goals, and more?</b>',
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOnOk: () => this.router.navigate(['account/billing']),
        nzCancelText: 'Not right now',
      });
    }
  }

  cancel(): void {
    this.message.info('Cancelled');
  }

  closeEditGoalModal(): void {
    this.addGoalModalIsOpen = false;
    if (this.route.queryParams) {
      this.route.queryParams.subscribe({
        next: (params) => {
          if (params.guild && params.playlistId) {
            this.router.navigate([`/guilds/home/${params.guild}/playlists`], { queryParams: { goalId: params.goalId, playlistId: params.playlistId } });
          }

          if (params.guild && params.activityId) {
            this.router.navigate([`/guilds/home/${params.guild}/activities`], { queryParams: { goalId: params.goalId, activityId: params.activityId } });
          }
        }
      });
    } else {
      console.log(`QueryParams on the route should be initialised, but if it's not, it might be because we haven't setup the test case correctly`);
    }
  }

  handleCancel = (): void => {
    this.modal.confirm({
      nzTitle: '<i>Cancel Goal?</i>',
      nzContent: '<b>Are you sure you want to abandon creating this goal? <br> We wouldn\'t want you to lose any effort you put into curating it!</b>',
      nzOnOk: () => this.closeConfirmationModal(),
      nzOkText: 'Confirm',
    });
  };

  closeAddGoalModal() {
    this.addGoalModalIsOpen = false;
    if (this.route.queryParams) {
      this.route.queryParams.subscribe({
        next: (params) => {
          if (params.playlistId) {
            if (params.guild) {
              this.router.navigate([`/guilds/home/${params.guild}/playlists`], { queryParams: { goalId: this.goalId, playlistId: params.playlistId } });
            } else {
              this.location.back();
            }
          }

          if (params.activityId) {
            if (params.guild) {
              this.router.navigate([`/guilds/home/${params.guild}/activities`], { queryParams: { goalId: this.goalId, activityId: params.activityId } });
            } else {
              this.location.back();
            }
          }
        }
      });
    } else {
      this.router.navigate(['profile/overview']);
    }
  }

  closeConfirmationModal() {
    this.addGoalModalIsOpen = false;
    this.router.navigate(['profile/overview']);
  }

  getGoalId(goalId: number) {
    this.goalId = goalId;
  }

  formatTime(date: string): string {
    return new Date(date).toDateString();
  }

  isGoalOverdue(goal: IGoal): boolean {
    if (!goal.dateTarget) {
      throw new Error('The dateTarget should be defined by now.');
    }

    return new Date(goal.dateTarget).getTime() < new Date().getTime();
  }

  private initialiseWithLatestGoals() {
    if (this.goalService.isInitialised()) {
      // console.log(`*** ProfileOverviewComponent: initialiseWithLatestGoals`);
      this.goals = [...this.goalService.getGoals()];
      this.hasActiveGoals = this.goalService.hasActiveGoals();
    }
  }
}
