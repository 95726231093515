<p nz-tooltip [nzTooltipTitle]="titleTemplate" nzTooltipPlacement="rightTop">
  {{ displayType === 'paragraph'? summary : keyword }}
</p>
<ng-template #titleTemplate>
  <a target="_blank" rel="noopener noreferrer" href="{{ keywordUrl }}">
    <div class="long-tooltip">
      {{ summary }}
    </div>
  </a>
</ng-template>

