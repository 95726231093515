<h2 class="header">Edit Skills</h2>
<nz-tabset class="tabs">
  <nz-tab nzTitle="All">
    <nz-table id="all-skills" class="skills-table" #SkillsTable [nzData]="filteredSkills" [nzShowPagination]=true [nzShowQuickJumper]=true [nzPageSizeOptions]="[10,30,50]" [nzShowSizeChanger]="true" nzPaginationPosition="both" [nzScroll]="{ x: '1000px' }">
      <thead>
        <tr>
          <th [nzSortDirections]="sortDirections" [nzSortFn]="sortId">Skill Id</th>
          <th nzLeft nzCustomFilter [nzSortFn]="sortName" [nzSortDirections]="sortDirections">
            Skill Name
            <nz-filter-trigger [(nzVisible)]="filterNameVisible" [nzActive]="searchNameValue.length > 0" [nzDropdownMenu]="filterName">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter [nzSortFn]="sortCategory" [nzSortDirections]="sortDirections">
            Skill Category
            <nz-filter-trigger [(nzVisible)]="filterCategotyVisible" [nzActive]="searchCategoryValue.length > 0" [nzDropdownMenu]="filterCategory">
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
          </th>
          <th [nzSortDirections]="sortDirections" [nzSortFn]="sortRetired">Date Retired</th>
          <th [nzSortDirections]="sortDirections" [nzSortFn]="sortReplacedBy">Retired By (ID, Name)</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let skill of SkillsTable.data">
          <td>{{skill.id}}</td>
          <td>
            <div nz-typography nzEditable [(nzContent)]="skill.name">
              {{ skill.name }}
            </div>
          </td>
          <td>
            <input nz-input type="text" [(ngModel)]="skill.category" (ngModelChange)="onCategoryChange($event)" [nzAutocomplete]="auto" />
            <nz-autocomplete [nzDataSource]="filteredCategorySuggestions" #auto></nz-autocomplete>
          </td>
          <td>{{ skill.dateRetired | date:'long' }}</td>
          <td>
            <div *ngIf="skill.replacedBy">
              {{ skill.replacedBy.id }},
              {{ skill.replacedBy.name }}
            </div>
          </td>
          <td>
            <button id="update-skill-{{skill.id}}" class="action-button update-skill" nz-button nzType="{skillUpdated(skill)}" (click)="updateSkill(skill)"><span nz-icon nzType="check" nzTheme="outline"></span></button>
            <button class="action-button" nz-button nzType="danger" nz-popconfirm nzPopconfirmTitle="Are you sure?" nzOkText="OK" nzCancelText="Cancel" (nzOnConfirm)="retireSkill(skill)">
              <span nz-icon nzType="close" nzTheme="outline"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #filterName="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchNameValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="searchName()" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="resetName()">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #filterCategory="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchCategoryValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="searchCategory()" class="search-button">
            Search
          </button>
          <button nz-button nzSize="small" (click)="resetCategory()">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
  </nz-tab>
  <nz-tab nzTitle="Uncategorised Skills from User Activities">
    <nz-table id="uncategorised-skills" class="skills-table" #UserActivitiesSkill [nzData]="userActivitySkills" [nzShowPagination]=true>
      <thead>
        <tr>
          <th>User Activity ID</th>
          <th>Added by User ID</th>
          <th>Industry</th>
          <th>Profession</th>
          <th>Specialisation</th>
          <th>Skill Name</th>
          <th>Category</th>
          <th>Date Retired</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let userActivitySkill of UserActivitiesSkill.data">
          <td>{{ userActivitySkill.activity.id }}</td>
          <td>{{ userActivitySkill.activity.userId }}</td>
          <td>{{ userActivitySkill.activity.industry }}</td>
          <td>{{ userActivitySkill.activity.profession }}</td>
          <td>{{ userActivitySkill.activity.specialisation }}</td>
          <td>
            <div nz-typography nzEditable [(nzContent)]="userActivitySkill.skill.name">
              {{ userActivitySkill.skill.name }}
            </div>
          </td>
          <td>
            <nz-select class="category-input" nzShowSearch [nzPlaceHolder]="userActivitySkill.skill.category" [(ngModel)]="userActivitySkill.skill.category">
              <nz-option class="skill-select-option" *ngFor="let category of categories" [nzLabel]="category" [nzValue]="category"></nz-option>
            </nz-select>
          </td>
          <td>{{ userActivitySkill.skill.dateRetired | date:'long' }}</td>
          <td>
            <button class="action-button" nz-button nzType="primary" (click)="updateSkill(userActivitySkill.skill)">Update</button>
            <button class="action-button retire-button" nz-button nzType="danger" nz-popconfirm nzPopconfirmTitle="Are you sure?" nzOkText="OK" nzCancelText="Cancel" (nzOnConfirm)="retireSkill(userActivitySkill.skill)">Retire</button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Uncategorised Skills from Goals">
    <nz-table class="skills-table" #GoalSkill [nzData]="goalSkills" [nzShowPagination]=true>
      <thead>
        <tr>
          <th>Goal ID</th>
          <th>Industry</th>
          <th>Profession</th>
          <th>Specialisation</th>
          <th>Skill Name</th>
          <th>Category</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let goalSkill of GoalSkill.data">
          <td>{{ goalSkill.goal.id }}</td>
          <td>{{ goalSkill.goal.Industry }}</td>
          <td>{{ goalSkill.goal.Profession }}</td>
          <td>{{ goalSkill.goal.Specialisation }}</td>
          <td>{{ goalSkill.skill.name }}</td>
          <td>
            <nz-select style="width: 200px;" nzShowSearch [nzPlaceHolder]="Uncategorised" [(ngModel)]="selectedCategory" (ngModelChange)="updateGoalSkillCategory(goalSkill)">
              <nz-option class="skill-select-option" *ngFor="let category of categories" [nzLabel]="category" [nzValue]="category"></nz-option>
            </nz-select>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>
