<div class="component-title">
  <div class="title-logo"><img class="icon" src="assets/img/icons/dart-board.svg" /></div>
  <div class="title-text">GOALS</div>
</div>
<nz-table id="goalsTable" #nzTable [nzData]="goals" [nzShowPagination]="false" [nzFrontPagination]="false" [nzScroll]="{ x: '700px' }">
  <thead>
    <tr>
      <th nzWidth="100px" colspan="1"></th>
      <th colspan="1">Target</th>
      <th colspan="3">Goals</th>
      <th colspan="1" *ngIf="goalsPublic === undefined" id="th-add-goals" style="text-align: right">
        <span nz-tooltip nzTooltipTitle="Add Goal">
          <button id="addgoal-button" *ngIf="!isSubscribedLoading" [ngClass]="{ 'no-goals': !hasActiveGoals }" nz-button nzType="default" [nzShape]="'circle'" class="goal-button" (click)="showAddGoalModal()"><i class="fa fa-plus"></i></button>
        </span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let goal of nzTable.data" [ngClass]="{ boldGoal: !goal.dateCompleted }">
      <td>
        <span *ngIf="!isGoalOverdue(goal) || goal.dateCompleted; else overdueIcon" nz-tooltip nzTooltipTitle="{{ !goal.dateCompleted ? 'In progress' : 'Completed' }}">
          <div [ngClass]="!goal.dateCompleted ? 'status incomplete' : 'status complete'"></div>
        </span>
        <ng-template #overdueIcon>
          <span nz-tooltip nzTooltipTitle="Overdue">
            <div class="status"><i nz-icon nzType="warning" nzTheme="outline" class="overdue"></i></div>
          </span>
        </ng-template>
      </td>
      <td>
        <span *ngIf="!goal.dateCompleted; else notCompleted" [ngClass]="isGoalOverdue(goal) ? 'overdue' : null" [innerHTML]="goal.dateTarget | formatGoalDuration" nz-tooltip
          nzTooltipTitle="{{ isGoalOverdue(goal) ? 'Adjust your target date by editing this goal' : (goal.dateTarget | date : 'dd MMM yyyy') }}">
        </span>
        <ng-template #notCompleted>
          <span [innerHTML]="goal.dateCompleted | date : 'MMM yyyy'" nz-tooltip nzTooltipTitle="Date Completed"></span>
        </ng-template>
      </td>
      <td>{{ goal.profession }}</td>
      <td>{{ goal.specialisation }}</td>
      <td id="goal-level">{{ goal.level }}</td>
      <td *ngIf="goalsPublic === undefined">
        <sl-goal-actions [goal]="goal"></sl-goal-actions>
      </td>
    </tr>
  </tbody>
</nz-table>

<nz-modal id="goal-modal" [(nzVisible)]="addGoalModalIsOpen" [nzTitle]="addGoalTitle" [nzContent]="addGoalContent" [nzFooter]="addGoalFooter" (nzOnCancel)="handleCancel()" nzWidth="75%">
  <ng-template #addGoalTitle>
    <h2 class="sl--heading">Tell us what you want to achieve</h2>
  </ng-template>
  <ng-template #addGoalContent>
    <div class="goal-modal">
      <sl-goal [goal]="goalToBeEdited" [defaultProfession]="profession" [defaultSpecialisation]="specialisation" [level]="level" (sendGoalIdEmitter)="getGoalId($event)" (closeEditGoalModalEmitter)="closeEditGoalModal()" (closeAddGoalModalEmitter)="closeAddGoalModal()"></sl-goal>
    </div>
  </ng-template>
  <ng-template #addGoalFooter></ng-template>
</nz-modal>