import { IProvider } from './provider.model';
import { ISkill } from './skill.model';
import { IActivitySkill } from './activity-skill.model';
import { StatusKind } from '../types/status-kind.type';
import { IUserActivityBase } from './user-activity-base.model';


export const getUserActivityStatus = (userActivity: IUserActivity): StatusKind => {
  if (userActivity.status === 'notstarted') {
    return StatusKind.Scheduled;
  }

  if (userActivity.status === 'started') {
    return StatusKind.InProgres;
  }

  if (userActivity.status === 'completed') {
    return StatusKind.Completed;
  }

  if (userActivity.status === 'archived') {
    return StatusKind.Archived;
  }

  console.error(`We have a userActivityStatus that we don't recognise. Status: ${userActivity.status}`);
  throw new Error('invalid status');
};

export interface IUserActivity extends IUserActivityBase {
  activityTypeId?: number;
  typeName?: string;
  providerName?: string;
  provider?: IProvider;
  eventId?: string;
  industry?: string;
  profession?: string;
  specialisation?: string;
  authors?: string;
  cost?: string;
  status: string;
  comments?: string;
  skills: ISkill[];
  activitySkills?: IActivitySkill[];
  imageThumbnailUrl?: string;
  addedSource?: number;
  flagged?: boolean;
  flaggedActivityType?: string;
  hasCoreSkills?: boolean;
}
