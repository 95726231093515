import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'sl-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.less'],
})
export class InvoicesComponent implements OnInit {
  constructor(private paymentService: PaymentService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}
}
