import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class GtmService {
  constructor(private gtmService: GoogleTagManagerService) {}

  triggerEvent(event: string, data: any) {
    // push GTM data layer with a custom event
    const gtmTag = {
      event,
      data,
    };
    this.gtmService.pushTag(gtmTag);
  }
}
