import { Component, OnInit } from '@angular/core';
import { GuildService } from 'src/app/services/guild.service';
import { IGuild } from '../../../modules-guilds/models/guild.model';

@Component({
  selector: 'sl-guilds-admin',
  templateUrl: './guilds-admin.component.html',
  styleUrls: ['./guilds-admin.component.less'],
})
export class GuildsAdminComponent implements OnInit {
  guilds!: IGuild[];
  loading = false;

  constructor(private guildService: GuildService) { }

  ngOnInit(): void {
    this.initGuilds();
  }

  initGuilds() {
    this.loading = true;
    this.guildService.getGuilds().subscribe({
      next: (g) => {
        this.guilds = g;
        this.loading = false;
      },
      error: (err) => {
        console.error(`*** Guilds Admin component: Failed to get guilds. Error: ${JSON.stringify(err)}`);
        this.loading = false;
      }
    });
  }
}
