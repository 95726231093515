<nz-spin [nzSpinning]="isLoading">
  <div class="table-actions">
    <ul nz-menu>
      <li nz-submenu>
        <!-- Menu -->
        <div title *ngIf="!isCompleted">
          <button nz-button nzType="default" [nzShape]="'circle'" nz-tooltip nzTooltipTitle="Edit Goal" (click)="showEditGoalModal(goal.id)">
            <i class="fa fa-pencil"></i>
          </button>
        </div>
        <div title *ngIf="isCompleted">
          <button nz-button nzType="default" [nzShape]="'circle'" nz-tooltip nzTooltipTitle="Restart Goal" (click)="toggleGoalCompleted(goal.id)">
            <i class="fa fa-play"></i>
          </button>
        </div>
        <!-- SubMenu 1 -->
        <ul class="submenu-space">
          <li nz-menu-group>
            <ul>
              <li>
                <button *ngIf="!isCompleted" nz-button nzType="Primary" [nzShape]="'circle'" (click)="toggleGoalCompleted(goal.id)" nz-tooltip nzTooltipTitle="Complete Goal">
                  <i [ngClass]="!isCompleted?'fa fa-check':'fa fa-remove'"></i>
                </button>
                <button *ngIf="!isCompleted" nz-button nzShape="circle" (click)="showAssignActivityModal(goal.id)" nz-tooltip nzTooltipTitle="Assign Activities">
                  <i [ngClass]="'fa-solid fa-list-check'"></i>
                </button>
                <button *ngIf="!isCompleted" nz-popconfirm nzShape="circle" nzPopconfirmTitle="Are you sure you want to delete this goal?" (nzOnConfirm)="deleteGoal(goal.id)" (nzOnCancel)="cancel()" nzPopconfirmPlacement="topLeft" nz-tooltip nzTooltipTitle="Delete Goal" nzDanger
                  nz-button>
                  <i nz-icon nzType="delete" nzTheme="outline"></i>
                </button>
              </li>
            </ul>
          </li>
        </ul>
        <!-- SubMenu 2 -->
        <ul class="submenu-space" *ngIf="isCompleted">
          <li nz-menu-group>
            <ul>
              <li>
                <button id="completed-goal-delete-button" nz-popconfirm nzShape="circle" nzPopconfirmTitle="Are you sure you want to delete this goal?" (nzOnConfirm)="deleteGoal(goal.id)" (nzOnCancel)="cancel()" nzPopconfirmPlacement="topLeft" nz-tooltip
                  nzTooltipTitle="Delete Goal" nzDanger nz-button>
                  <i nz-icon nzType="delete" nzTheme="outline"></i>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nz-spin>

<div>
  <nz-modal [nzVisible]="editGoalModalIsOpen" [nzTitle]="editGoalTitle" [nzContent]="editGoalContent" [nzFooter]="editGoalFooter" (nzOnCancel)="handleCancel()" nzWidth="75%">
    <ng-template #editGoalTitle>
      <h2 class="sl--heading">Tell us what you want to achieve</h2>
    </ng-template>
    <ng-template #editGoalContent>
      <div class="goal-modal" *ngIf="editGoalModalIsOpen">
        <sl-goal [goal]="goalEdited" (closeEditGoalModalEmitter)="closeEditGoalModal()"></sl-goal>
      </div>
    </ng-template>
    <ng-template #editGoalFooter>
    </ng-template>
  </nz-modal>
</div>

<div>
  <nz-modal [nzVisible]="assignActivityModalIsOpen" [nzTitle]="assignActivityTitle" [nzContent]="assignActivityContent" [nzFooter]="assignActivityFooter" (nzOnCancel)="closeAssignActivitiesModal()" nzWidth="40%">
    <ng-template #assignActivityTitle>
      <h2 class="sl--heading">Assign Activities</h2>
      <p>You are assigning activities to your {{goal.specialisation}} goal.</p>
    </ng-template>
    <ng-template #assignActivityContent>
      <div class="goal-modal" *ngIf="assignActivityModalIsOpen">
        <sl-unassigned-activities (closeAssignActivitiesModalEmitter)="closeAssignActivitiesModal()" [goal]="goal"></sl-unassigned-activities>
      </div>
    </ng-template>
    <ng-template #assignActivityFooter></ng-template>
  </nz-modal>
</div>