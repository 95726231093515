export function getWindow(): Window {
  // eslint-disable-next-line no-undef
  return window;
}

export function getDocument(): Document {
  // eslint-disable-next-line no-undef
  return document;
}

export function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}
