<nz-card id="top-activities-card" class="guild-top-activities-container" [nzTitle]="cardHeader" [nzExtra]="extraTemplate">
  <div id="guild-top-acitivity" *ngIf="!isLoadingGuildTopActivities">
    <div class="top-activity-container-body ant-card ant-card-bordered ant-card-hoverable" [ngClass]="{ 'top-activity-added': activity.hasBeenAdded}" *ngFor="let activity of topActivities.activities" nzHoverable (click)="showDrawer(activity)">
      <span class="top-activity-image-container">
        <nz-badge *ngIf="isPercentage" class="activity-completed-count-badge" [nzStyle]="{ backgroundColor: '#f5a623'}" [nzCount]="activity.percentageCompleted" [nzOverflowCount]="100" nzShowZero
          nzTooltipTitle="{{activity.percentageCompleted}}% of people have completed this activity" nzTooltipPlacement="top" nz-tooltip>
          <div class="badge-contents">
            <img class="top-activity-image" [src]="getTopActivityImage(activity.activityType)" alt="Image of the activity" />
            <i *ngIf="activity.hasBeenCompleted" class="checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'" nz-tooltip nzTooltipTitle="You have completed this activity"></i>
          </div>
        </nz-badge>
        <nz-badge *ngIf="!isPercentage && activity.numberCompleted === 1" class="activity-completed-count-badge" [nzStyle]="{ backgroundColor: '#f5a623'}" [nzCount]="activity.numberCompleted" [nzOverflowCount]="100" nzShowZero
          nzTooltipTitle="{{activity.numberCompleted}} person has completed this activity" nzTooltipPlacement="top" nz-tooltip>
          <div class="badge-contents">
            <img class="top-activity-image" [src]="getTopActivityImage(activity.activityType)" alt="Image of the activity" />
            <i *ngIf="activity.hasBeenCompleted" class="checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'" nz-tooltip nzTooltipTitle="You have completed this activity"></i>
          </div>
        </nz-badge>
        <nz-badge *ngIf="!isPercentage && (activity.numberCompleted > 1 || activity.numberCompleted === 0)" class="activity-completed-count-badge" [nzStyle]="{ backgroundColor: '#f5a623'}" [nzCount]="activity.numberCompleted" [nzOverflowCount]="100" nzShowZero
          nzTooltipTitle="{{activity.numberCompleted}} people have completed this activity" nzTooltipPlacement="top" nz-tooltip>
          <div class="badge-contents">
            <img class="top-activity-image" [src]="getTopActivityImage(activity.activityType)" alt="Image of the activity" />
            <i *ngIf="activity.hasBeenCompleted" class="checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'" nz-tooltip nzTooltipTitle="You have completed this activity"></i>
          </div>
        </nz-badge>
      </span>
      <span class="title-body">
        {{activity.activityType.title}}
      </span>
      <span class="details-body">
        {{activity.activityType.description}}
      </span>
      <span class="actions-body">
        <p class="add-activity-icon" *ngIf="!activity.hasBeenAdded && isEditable"><span class="action-icon" nz-icon nzType="plus" nzTheme="outline" nz-tooltip nzTooltipTitle="Add Activity" nzTooltipPlacement="top" (click)="addTopActivity(activity, $event)"></span>
        </p>
      </span>
    </div>
  </div>

  <div class="goal-selection-container">
    <sl-goal-selector [goalSourceType]="goalSelectorComponentType.Activity" [guild]="guild" [goalSelectorVisible]="goalSelectorVisible" [goals]="returnedActivityGoals" [selectedGoal]="selectedGoal" [selectedActivity]="selectedActivity" (selectGoalFromGoals)="selectGoal($event)"
      (closeGoalSelector)="closeGoalSelectionModal()" (selectGoalFromGoalSelector)="selectGoalFromGoalSelectionModal()"> </sl-goal-selector>
  </div>

  <div class="activity-contents-drawer-container">
    <sl-activity-contents-drawer [showDrawer]="showRecommendationsDrawer" [recommendation]="topActivityRecommendation" [addedToBacklog]="topActivityRecommendation?.added ? true: false" [isActivityListMode]="topActivityRecommendation?.added ? true : false"
      [fileUrl]="topActivityRecommendationFile" [shorterUrl]="topActivityRecommendationShorterUrl" (drawerNotVisible)="closeDrawer()" (activityRecommendationAdded)="addTopActivityRecommendation($event)"></sl-activity-contents-drawer>
  </div>

  <div *ngIf="!topActivities.activities.length && selectMode && !isLoadingGuildTopActivities">
    <nz-alert id="alert-no-completed-activities" nzType="info" nzShowIcon [nzMessage]="heading" [nzDescription]="descriptionTpl">
      <ng-template #descriptionTpl>
        <br />
        <span class="alert-body">
          When activities have completed by the selected user(s), they will be displayed here.
        </span>
      </ng-template>
      <ng-template #heading>
        <span class="alert-heading">No activities have been completed by the selected user(s)</span>
      </ng-template>
    </nz-alert>
  </div>

  <div *ngIf="!topActivities.activities.length && !selectMode && !isLoadingGuildTopActivities">
    <nz-alert id="alert-no-available-activities" nzType="info" nzShowIcon [nzMessage]="heading" [nzDescription]="descriptionTpl">
      <ng-template #descriptionTpl>
        <br />
        <span class="alert-body">
          As soon as activities become available, they'll appear right here.
          <br />
          Keep an eye on this space for exciting opportunities to get involved!
          <br />
        </span>
      </ng-template>
      <ng-template #heading>
        <span class="alert-heading">No activities are currently available for members of this guild</span>
      </ng-template>
    </nz-alert>
  </div>

  <div *ngIf="isLoadingGuildTopActivities" class="loading-guild-top-activities-spinner-container">
    <nz-spin class="loading-guild-top-activities-spinner" nzTip="Just a sec, we're grabbing the activities..."></nz-spin>
  </div>
</nz-card>

<ng-template #cardHeader>
  <span class="top-activities-header-container">
    <span class="header-icon"><i class="fa-solid fa-user-shield"></i></span>
    <span class="top-activities-heading">Top Activities
      <span id="activities-completed-number" class="activities-completed" *ngIf="!isPercentage && isEditable" nz-tooltip
        nzTooltipTitle="You have completed {{numberOfActivitiesCompleted}}/{{topActivities.activities.length}} activities">({{numberOfActivitiesCompleted}}/{{topActivities.activities.length}})</span>
      <span id="activities-completed-percentage" class="activities-completed" *ngIf="isPercentage && isEditable" nz-tooltip nzTooltipTitle="You have completed {{percentageOfActivitiesCompleted}}% of activities">({{percentageOfActivitiesCompleted}}%)</span>
    </span>
  </span>
</ng-template>

<ng-template #extraTemplate>
  <nz-switch *ngIf="isEditable" [ngModel]="isPercentage" class="top-activities-switch" nzCheckedChildren="%" nzUnCheckedChildren="#" nzSize="small" (ngModelChange)="toggleSwitch()"></nz-switch>
</ng-template>