import { Component, OnInit } from '@angular/core';

// services
import { NzMessageService } from 'ng-zorro-antd/message';
import { GoalService } from 'src/app/services/goal.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { SkillService } from 'src/app/services/skill.service';

// Modules
import { ISkill } from 'src/app/models/skill.model';
import { IGoalSkill } from 'src/app/models/goal-skill.model';
import { IUserActivitySkill } from 'src/app/models/user-activity-skill.model';

@Component({
  selector: 'sl-uncategorised-skills',
  templateUrl: './uncategorised-skills.component.html',
  styleUrls: ['./uncategorised-skills.component.less'],
})
export class UncategorisedSkillsComponent implements OnInit {
  allSkills: ISkill[] = new Array<ISkill>();
  categories: string[] = new Array<string>();
  selectedCategory: string | null = null;
  userActivitySkills!: IUserActivitySkill[];
  goalSkills!: IGoalSkill[];
  searchNameValue = '';
  searchCategoryValue = '';
  filterNameVisible = false;
  filterCategotyVisible = false;
  filteredSkills!: ISkill[];
  filteredCategorySuggestions: string[] = [];
  skillClicked: Record<number, string | undefined> = {};
  sortDirections = ['ascend', 'descend', null];

  constructor(private message: NzMessageService, private goalsService: GoalService, private userActivitiesService: UserActivitiesService, private skillService: SkillService) {}

  ngOnInit() {
    this.getCategories();
    this.refreshUncategorisedSkills();
  }

  getCategories() {
    this.goalsService.getCategories().subscribe({
      next: (res) => {
        this.categories = res.sort((a, b) => (a.toUpperCase() > b.toUpperCase() ? 1 : -1));
      },
      error: (error) => {
        console.error(`*** Failed to Retrieve Categories. Error: ${error}`);
      }
    });
  }

  updateSkill(skill: ISkill) {
    this.skillService.updateSkill(skill).subscribe({
      next: (result: ISkill) => {
        this.skillClicked[result.id] = 'updated';
        this.message.success(`Success, updated ID:${result.id} to "${result.name}:${result.category}".`, { nzDuration: 10000 });
      },
      error: (error) => {
        this.message.error('Something went wrong while trying to update ' + skill.id);
        console.error(`*** updateSkill: Skill ${skill.id} was not able to update. Please try again. ${error.message}`);
      }
    });
  }

  skillUpdated(skill: ISkill): string {
    const skillClicked = this.skillClicked[skill.id];
    console.log(`skillClicked = ${skillClicked}`);
    return 'updated' === skillClicked ? 'primary' : 'success';
  }

  skillRetired(skill: ISkill): boolean {
    const skillClicked = this.skillClicked[skill.id];
    console.log(`skillClicked = ${skillClicked}`);
    return 'retired' === skillClicked ? true : false;
  }

  updateGoalSkillCategory(goalSkill: IGoalSkill) {
    this.goalsService.updateGoalSkill(goalSkill).subscribe({
      next: () => {
        this.message.success('Successfully updated ' + goalSkill.skill.name + ' to category : ' + this.selectedCategory);
        this.selectedCategory = null;
        this.refreshUncategorisedSkills();
      },
      error: () => {
        this.selectedCategory = null;
      }
    });
  }

  retireSkill(skill: ISkill) {
    this.skillService.retireSkill(skill).subscribe({
      next: (goalSkill: ISkill) => {
        this.skillClicked[goalSkill.id] = 'retired';
        // console.log(`*** retireSkill: Success, skill was removed from the GoalSkills and TrackSkills tables. Skill: ${goalSkill.name}`);
        this.retireSkillFromActivities(skill);
      },
      error: (err) => {
        this.message.error(`Failed to retire skill ID:${skill.id}.`, { nzDuration: 10000 });
        console.error(`*** retireSkill: Skill ID:${skill.id} was not retired. ${err}`);
      }
    });
  }

  refreshUncategorisedSkills() {
    this.getEditableSkills();
    this.getUncategorisedSkillsFromActivities();
    this.getUncategorisedSkillsFromGoals();
  }

  getEditableSkills() {
    this.goalsService.getEditableSkills().subscribe({
      next: (res) => {
        this.allSkills = res;
        this.filteredSkills = this.allSkills;
        if (this.searchNameValue) {
          this.searchName();
        } else {
          this.searchCategory();
        }
      },
      error: (error) => {
        console.error(`*** Failed to Uncategorised skills. Error: ${error}`);
      }
    });
  }

  getUncategorisedSkillsFromActivities() {
    this.userActivitiesService.getUncategorisedUserActivitySkills().subscribe({
      next: (res) => {
        this.userActivitySkills = res;
      },
      error: (error) => {
        console.error(`*** LoadUserActivities: Error getting user activities from service. Error: ${error}`);
        this.message.error('Failed to load the uncategorised UserActivitySkills.');
      }
    });
  }

  getUncategorisedSkillsFromGoals() {
    this.goalsService.getUncategorisedGoalSkills().subscribe({
      next: (res) => {
        this.goalSkills = res;
      },
      error: (error) => {
        console.error(`*** LoadUserActivities: Error getting user activities from service. Error: ${error}`);
      }
    });
  }

  createMessage = (type: string, text: string) => {
    this.message.create(type, text);
  };

  resetName(): void {
    this.searchNameValue = '';
    this.searchName();
  }

  searchName(): void {
    this.filterNameVisible = false;
    this.filteredSkills = this.allSkills.filter((item: ISkill) => item.name.toLocaleLowerCase().indexOf(this.searchNameValue.toLocaleLowerCase()) !== -1);
  }

  resetCategory(): void {
    this.searchCategoryValue = '';
    this.searchCategory();
  }

  searchCategory(): void {
    this.filterCategotyVisible = false;
    this.filteredSkills = this.allSkills.filter((item: ISkill) => item.category.toLocaleLowerCase().indexOf(this.searchCategoryValue.toLocaleLowerCase()) !== -1);
  }

  onCategoryChange(value: string): void {
    this.filteredCategorySuggestions = this.categories.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  sortRetired = (a: ISkill, b: ISkill, sortOrder?: string) => {
    if (!a.dateRetired) {
      return sortOrder === 'ascend' ? -1 : 1;
    }
    if (!b.dateRetired) {
      return sortOrder === 'ascend' ? -1 : 1;
    }
    return b.dateRetired > a.dateRetired ? 1 : -1;
  };

  sortReplacedBy = (a: ISkill, b: ISkill, sortOrder?: string) => {
    if (!a.replacedBy) {
      return sortOrder === 'ascend' ? -1 : 1;
    }
    if (!b.replacedBy) {
      return sortOrder === 'ascend' ? -1 : 1;
    }
    return b.replacedBy.id - a.replacedBy.id;
  };

  sortId = (a: ISkill, b: ISkill) => a.id - b.id;
  sortName = (a: ISkill, b: ISkill) => a.name.localeCompare(b.name);
  sortCategory = (a: ISkill, b: ISkill) => a.category.localeCompare(b.category);

  // TODO: Deleted because it's not being used: 2023.12.2
  // private updateUserActivitySkill(skill: ISkill) {
  //   this.skillService.updateSkill(skill).subscribe({
  //     next: (res: ISkill) => {
  //       this.message.success(`Success, updated ID:${res.id} to "${res.name}:${res.category}".`, { nzDuration: 10000 });
  //       // console.log(`*** updateUserActivitySkill: Skill ID:${skill.id} was updated successfully.`);
  //       this.refreshUncategorisedSkills();
  //     },
  //     error: (error) => {
  //       this.message.error(`Error, skill ID:${skill.id} was not updated in the ActivitySkills table. Please try again.`);
  //       console.error(`*** updateUserActivitySkill: Skill ID:${skill.id} was not updated in the ActivitySkills table. Please try to update it again. ${error.message}`);
  //       skill.category = 'Uncategorised';
  //     }
  //   });
  // }

  private retireSkillFromActivities(skill: ISkill) {
    this.skillService.retireSkill(skill).subscribe({
      next: () => {
        this.message.success(`Done, skill ID:${skill.id} has been retired.`, { nzDuration: 7000 });
        // console.log(`*** updateUserActivitySkill: Skill ID:${skill.id} was successfully retired.`);
        this.refreshUncategorisedSkills();
      },
      error: (error) => {
        this.message.error(`Oops, skill was not removed from the ActivitySkills table. Please try again.`, { nzDuration: 10000 });
        console.error(`*** retireSkillFromActivities: Skill ID:${skill.id} was not removed from the ActivitySkills table. Please try to retire it again. ${error.message}`);
        this.refreshUncategorisedSkills();
      }
    });
  }
}
