import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IInvitation } from '../models/invitation.model';
import { IWaitlist } from '../models/waitlist';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class InviteService {
  constructor(private http: HttpClient) { }

  generatePlaylistInvitationCode(inPlaylistId: number): Observable<IInvitation> {
    const endpoint = `/api/InviteCodes/GeneratePlaylistInvitationCode`;
    const playlistId = inPlaylistId.toString();
    return this.http.get<IInvitation>(endpoint, { params: { playlistId } });
  }

  generateGuildInvitationCode(guildId: number): Observable<IInvitation> {
    const endpoint = `/api/InviteCodes/GenerateGuildInvitationCode`;
    return this.http.get<IInvitation>(endpoint, { params: { guildId: guildId.toString() } });
  }

  generateUserPlaylistInvitationCode(inUserPlaylistId: number): Observable<IInvitation> {
    const endpoint = `/api/InviteCodes/GenerateUserPlaylistInvitationCode`;
    const userPlaylistId = inUserPlaylistId.toString();
    return this.http.get<IInvitation>(endpoint, { params: { userPlaylistId } });
  }

  generateInvitations(invitorEmailAddress: string): Observable<IInvitation[]> {
    const endpoint = `/api/InviteCodes/GenerateInvitationsByEmail?invitorEmailAddress=${encodeURIComponent(invitorEmailAddress)}`;
    return this.http.get<IInvitation[]>(endpoint, httpOptions);
  }

  generateInvitationForEmail(emailAddress: string): Observable<IInvitation> {
    const endpoint = `/api/InviteCodes/GenerateInvitationForEmail?emailAddress=${encodeURIComponent(emailAddress)}`;
    return this.http.get<IInvitation>(endpoint, httpOptions);
  }

  getInvitationDetails(invitationCode: string): Observable<IInvitation> {
    const endpoint = `/api/InviteCodes/GetInvitationDetails`;
    return this.http.get<IInvitation>(endpoint, { params: { invitationCode } });
  }

  getAllInvitations(): Observable<IInvitation[]> {
    const endpoint = `/api/InviteCodes/GetAllInvitations`;
    return this.http.get<IInvitation[]>(endpoint);
  }

  getWaitlist(): Observable<IWaitlist[]> {
    const endpoint = `/api/InviteCodes/GetWaitList`;
    return this.http.get<IWaitlist[]>(endpoint);
  }

  deleteWaitlistUser(waitlistPerson: IWaitlist): Observable<boolean> {
    const endpoint = `/api/InviteCodes/DeleteWaitlistUser`;
    return this.http.post<boolean>(endpoint, waitlistPerson, httpOptions);
  }

  getCurrentUserInvitations(): Observable<IInvitation[]> {
    const endpoint = `/api/InviteCodes/GetInvitations`;
    return this.http.get<IInvitation[]>(endpoint);
  }

  createInvitation(): Observable<IInvitation> {
    const endpoint = `/api/InviteCodes/CreateInvitation`;
    return this.http.put<IInvitation>(endpoint, '');
  }

  getCurrentUserIsRequestedInvitations(): Observable<boolean> {
    const endpoint = `/api/InviteCodes/UserHasRequestedInvitation`;
    return this.http.get<boolean>(endpoint);
  }

  updateInvitationNotes(invitation: IInvitation): Observable<boolean> {
    const endpoint = `/api/InviteCodes/UpdateInvitationNotes`;
    return this.http.put<boolean>(endpoint, invitation);
  }

  updateInvitationUses(invitation: IInvitation): Observable<boolean> {
    const endpoint = `/api/InviteCodes/UpdateInvitationUses`;
    return this.http.put<boolean>(endpoint, invitation);
  }

  getIsUserRequestingInvitation(email: string): Observable<boolean> {
    const endpoint = `/api/InviteCodes/UserHasRequestedInvitation/${email}`;
    return this.http.get<boolean>(endpoint);
  }

  toggleRequestStatus(invitation: IInvitation): Observable<boolean> {
    const endpoint = `/api/InviteCodes/Invitation/RequestStatus`;
    return this.http.put<boolean>(endpoint, invitation);
  }
}
