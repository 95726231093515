import { ILocation } from 'src/app/models/location.model';
import { ISkill } from 'src/app/models/skill.model';
import { IGuildExternalLink as IGuildExternalLink } from './guild-external-link.model';
import { EGuildVisibilityType } from 'src/app/modules-guilds/types/guild-visibility.type';

export const decodeUrlComponent = (urlParam: string) => {
  if (!urlParam) {
    console.error('urlParam is null or undefined');
    return '';
  }

  const underscores = urlParam.replace(/_/g, ' ');
  const hash = underscores.replace(/-sharp/g, '#');
  const decoded = decodeURI(hash);
  return decoded;
};

export const encodeUrlComponent = (urlParam: string) => {
  const underscores = urlParam.replace(/ /g, '_');
  const hash = underscores.replace(/#/g, '-sharp');
  const encoded = encodeURI(hash);
  return encoded;
};

export interface IGuild {
  id: number;
  name: string;
  imageThumbnail: string; // check to see if string is the right type for this. It's supposed to be a base64 encoded image
  imageUrl: string;
  imageIcon: string; // check to see if string is the right type for this. Remember, it's supposed to be something like a font-awesome name
  description: string;
  industry: string;
  profession: string;
  specialisation: string;
  relatedTrackId: number;
  relatedTrackName: string;
  levelMin: number;
  levelMax: number;
  skills: ISkill[];
  location: ILocation;
  visibility: EGuildVisibilityType;
  createdById: string;
  dateCreated: Date;
  approved: boolean;
  externalLinks: IGuildExternalLink[];
  ownerName: string;
  ownerProfileImage: string; // the base64 encoded image
  guildMemberCount: number;
}
