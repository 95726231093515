<nz-card nzTitle="Account Details" [nzExtra]="extraTemplate">
  <div *ngIf="isAccountDetailsLoading">
    <nz-skeleton></nz-skeleton>
  </div>

  <form *ngIf="!isAccountDetailsLoading" nz-form [formGroup]="formValues">
    <nz-form-item nz-row id="avatar-row">
      <nz-upload nz-col nzSpan="8" nzOffset="8" nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false" [nzCustomRequest]="uploadProfilePicture">
        <div *ngIf="isProfilePictureLoading">
          <nz-spin nzSimple></nz-spin>
        </div>
        <ng-container *ngIf="!avatarUrl">
          <i class="upload-icon" nz-icon [nzType]="'plus'"></i>
          <div class="ant-upload-text">Upload</div>
        </ng-container>
        <img *ngIf="avatarUrl" [src]="avatarUrl" class="avatar" nzJustify="center" />
      </nz-upload>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzRequired nzFor="name">First Name</nz-form-label>
      <nz-form-control nz-col nzSm="14" [nzErrorTip]="firstNameErrorTpl">
        <input nz-input formControlName="name" id="form-name" placeholder="First Name" />
        <ng-template #firstNameErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"> You must enter a first name! </ng-container>
          <ng-container *ngIf="control.hasError('invalidName')"> {{ control.errors.invalidName }} </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzRequired nzFor="surname">Surname</nz-form-label>
      <nz-form-control nz-col nzSm="14" [nzErrorTip]="surnameErrorTpl">
        <input nz-input formControlName="surname" id="form-surname" placeholder="Surname" />
        <ng-template #surnameErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"> You must enter a Surname! </ng-container>
          <ng-container *ngIf="control.hasError('invalidName')"> {{ control.errors.invalidName }} </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzRequired nzFor="username">Username</nz-form-label>
      <nz-form-control nz-col nzSm="14" [nzErrorTip]="usernameErrorTpl">
        <input nz-input formControlName="username" id="form-username" placeholder="Username" />
        <ng-template #usernameErrorTpl let-control>
          <ng-container *ngIf="control.hasError('usernameTooLong')"> You've exceeded the maximum length of {{ maxEmailCharLength }} characters. </ng-container>
          <ng-container *ngIf="control.hasError('usernameHasSpaces')"> Your username shouldn't include any spaces. </ng-container>
          <ng-container *ngIf="control.hasError('required')"> You must enter a Username! </ng-container>
          <ng-container *ngIf="control.hasError('invalidName')"> {{ control.errors.invalidName }} </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzRequired nzFor="email">Primary Email</nz-form-label>
      <nz-form-control nz-col nzSm="14" nzValidateStatus="success" nzHasFeedback>
        <nz-input-group [nzPrefix]="primaryEmailIcon">
          <input [readonly]="true" nz-input formControlName="email" id="form-email" />
        </nz-input-group>
      </nz-form-control>
      <ng-template #primaryEmailIcon>
        <i class="fa-solid fa-star"></i>
      </ng-template>
    </nz-form-item>
    <nz-form-item class="user-email-form-item" nz-row formArrayName="additionalEmails">
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzFor="email">Additional Emails</nz-form-label>
      <div class="user-email-container">
        <nz-form-control nz-col nzSm="30" *ngFor="let email of emailsFormArray.controls; index as i; first as isFirst; last as isLast"
          [nzValidateStatus]="isEmailVerified(i) ? 'success' : (!isEmailVerified(i) && doesEmailConatainValue(i) && !doesEmailHaveError(i)  ? 'warning' : (doesEmailHaveError(i) ? 'error' : ''))" [nzHasFeedback]="isEmailVerified(i) ? true : false">
          <div class="user-emails" [formGroupName]="i">
            <div class="user-email-icons">
              <i *ngIf="isEmailVerified(i)" class="fa-solid fa-star user-email-priority-icon" nz-tooltip="Mark as primary email address" (click)="allocateAsPrimaryEmailAddress(i)"></i>
              <i nz-icon nzType="close" nzTheme="outline" class="user-email-remove-icon" nz-tooltip="Remove" (click)="removeEmailAddress(i)"></i>
            </div>
            <nz-input-group [nzSuffix]="validationIcon">
              <input class="user-email-input" [readonly]="false" nz-input formControlName="email" [id]="'form-email-' + i" (input)="updateValidators(i)" />
            </nz-input-group>
          </div>
          <ng-template #validationIcon>
            <div *ngIf="!doesEmailHaveError(i) && doesEmailConatainValue(i)">
              <i *ngIf="!isEmailVerified(i)" class="warning-icon" nz-icon nzType="safety-certificate" nzTheme="outline" nz-tooltip="This email address has not been verified. Click to verify." nz-popover nzPopoverTrigger="click" [nzPopoverTitle]="titleTemplate"
                [nzPopoverContent]="contentTemplate"></i>
            </div>
            <ng-template #titleTemplate>
              <div>Verify Email</div>
            </ng-template>
            <ng-template #contentTemplate>
              <div class="verify-button-container">
                <button *ngIf="!isEmailBeingVerified(i)" class="verify-button" nz-button nzType="primary" (click)="verifyEmailAddress(i)">Click to verify</button>
                <div class="email-icon-container" *ngIf="isEmailBeingVerified(i)">
                  <i class="fa-solid fa-envelope email-icon"></i>
                  <span class="email-prompt">Please check your inbox</span>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </nz-form-control>
      </div>
      <div class="add-additional-user-email-container" *ngIf="emailsFormArray.controls.length < 5">
        <i class="add-additional-user-email-icon" nz-icon nzType="plus-circle" nzTheme="fill" nz-tooltip="Add an additional email address" (click)="addEmailAddress()"></i>
      </div>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzFor="phoneNumber">Phone Number</nz-form-label>
      <nz-form-control nz-col nzSm="14" [nzErrorTip]="phoneNumberErrorTpl">
        <input nz-input formControlName="phoneNumber" id="form-phone-number" />
        <ng-template #phoneNumberErrorTpl let-control>
          <ng-container *ngIf="control.hasError('invalidPhoneNumber')"> You must enter a valid phone number. </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzFor="gender">Gender</nz-form-label>
      <nz-form-control nz-col nzSm="14">
        <nz-select style="text-align: left !important" name="gender" ngDefaultControl formControlName="gender" id="form-gender">
          <nz-option nzValue="Female" nzLabel="Female"></nz-option>
          <nz-option nzValue="Male" nzLabel="Male"></nz-option>
          <nz-option nzValue="Other" nzLabel="Other"></nz-option>
        </nz-select>
      </nz-form-control>
      <div style="padding-top: 4px">
        <sl-help-info title="Help us, help you" description="Many employers have equal opportunity goals of achieving and maintaining a reasonable gender balance, and this is often achieved in the talent and succession planning phase. 
              By giving employers the opportunity to ensure that a selection pool contained a minimum (and maximum) selection criteria, 
              they can ensure their hiring process mains fair and unbiased towards finding high potential candidates from a balanced set of candidates">
        </sl-help-info>
      </div>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzFor="address">Address</nz-form-label>
      <nz-form-control nz-col nzSm="14">
        <input nz-input formControlName="address" id="form-address" placeholder="1 Some Street" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzFor="addressSuburb">Suburb</nz-form-label>
      <nz-form-control nz-col nzSm="14">
        <input nz-input formControlName="addressSuburb" id="form-addressSuburb" placeholder="Sydney" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzFor="addressState">State</nz-form-label>
      <nz-form-control nz-col nzSm="14">
        <input nz-input formControlName="addressState" id="form-addressState" placeholder="NSW" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzRequired nzFor="addressPostcode">Postcode</nz-form-label>
      <nz-form-control nz-col nzSm="14" [nzErrorTip]="postcodeErrorTpl">
        <input nz-input formControlName="addressPostcode" id="form-addressPostcode" placeholder="2000" />
        <ng-template #postcodeErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"> You must enter a Postcode! </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzFor="salary">Salary</nz-form-label>
      <nz-form-control nz-col nzSm="14" [nzErrorTip]="salaryNotValid">
        <input nz-input formControlName="salary" id="form-salary" placeholder="50000" />
        <ng-template #salaryNotValid let-control>
          <ng-container *ngIf="control.hasError('salaryNotValid')"> Invalid salary format </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzFor="tagline">Tagline</nz-form-label>
      <nz-form-control nz-col nzSm="14" [nzErrorTip]="taglineNotValid">
        <input nz-input formControlName="tagline" id="form-tagline" placeholder="Add a few interesting words about yourself!" />
        <ng-template #taglineNotValid let-control>
          <ng-container *ngIf="control.hasError('taglineNotValid')"> You've exceeded the maximum length of 280 characters </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-label class="form-label" nz-col nzSm="4" nzOffset="2" nzRequired nzFor="publicUrl">Public URL</nz-form-label>
      <nz-form-control nz-col nzSm="14" [nzErrorTip]="publicUrlErrorTpl">
        <nz-input-group [nzAddOnBefore]="hostName + '/profile/'" [nzAddOnAfter]="addOnAfterTemplate">
          <input (blur)="compareUrlValue($event.target.value)" [attr.disabled]="disablePublicUrlInput ? '' : null" nz-input formControlName="publicUrl" id="form-publicUrl" #urlinput placeholder="Firstname-Surname" />
        </nz-input-group>
        <ng-template #publicUrlErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"> You must enter a public URL with at least 1 character. </ng-container>
          <ng-container *ngIf="formValues.get('publicUrl')?.hasError('urlTooLong')"> You've exceeded the maximum length of 59 characters. </ng-container>
          <ng-container *ngIf="formValues.get('publicUrl')?.hasError('urlHasSpaces')"> Your URL should not contain any spaces. </ng-container>
          <ng-container *ngIf="formValues.get('publicUrl')?.hasError('urlInvalidCharacters')"> Only hyphens '-' and underscores '_' are allowed. </ng-container>
        </ng-template>
        <ng-template #addOnAfterTemplate>
          <span class="urlButton" *ngIf="!formValues.get('privateProfile').value && formValues.get('publicUrl').value?.length > 0">
            <a class nzTooltipTitle="Copy to clipboard" nz-tooltip><i nz-icon nzType="copy" nzTheme="outline" (click)="copyUrl(urlinput)"></i></a>
          </span>
          <span class="urlButton" *ngIf="formValues.get('publicUrl').value?.length > 0">
            <a nz-popconfirm nzPopconfirmTitle="Are you sure? Doing this will prevent others from being able to view your profile." nzOkText="OK" nzCancelText="Cancel" (nzOnConfirm)="updatePublicProfileStatus(true)" nzTooltipTitle="Hide profile" nz-tooltip>
              <i *ngIf="!formValues.get('privateProfile').value" nz-icon nzType="eye-invisible" nzTheme="outline"></i>
            </a>
            <a nz-popconfirm nzPopconfirmTitle="Your public profile will be available for anyone to view." nzOkText="OK" nzCancelText="Cancel" (nzOnConfirm)="updatePublicProfileStatus(false)" nzTooltipTitle="Show profile" nz-tooltip>
              <i *ngIf="formValues.get('privateProfile').value" nz-icon nzType="eye" nzTheme="outline"></i>
            </a>
          </span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row>
      <nz-form-control nz-col nzOffset="13">
        <button id="update-button" nz-button [disabled]="!formValues.valid" [nzLoading]="isLoading" (click)="update()" type="submit" [nzSize]="'medium'" nzType="primary" nzJustify="end">Update Account</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-card>
<ng-template #extraTemplate>
  <sl-suggestions id="feedback" area="Profile Field" message="Suggest a new field for your account if you think we should include something else:"></sl-suggestions>
</ng-template>
<nz-card nzTitle="Invite Codes">
  <sl-individual-invite-code></sl-individual-invite-code>
</nz-card>