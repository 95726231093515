import { Component, EventEmitter, Input, Output, OnChanges, ViewChild, ElementRef } from '@angular/core';

// pipes
import { GlpyhSelectorPipe } from './glpyh-selector.pipe';

// models
import { glyphCollection } from './glyph-collection';
import { EditMode } from '../../types/edit-mode.type';

@Component({
  selector: 'sl-glyph-selector',
  templateUrl: './glyph-selector.component.html',
  styleUrls: ['./glyph-selector.component.less'],
  providers: [GlpyhSelectorPipe],
})
export class GlyphSelectorComponent implements OnChanges {
  @ViewChild('submittedGlyphIconContainer') submittedIconContainer!: ElementRef;
  @Output() uploadGlyphEvent = new EventEmitter<string>();
  @Input() currentMode!: EditMode;
  @Input() glyphComponentTitle!: string;
  @Input() glyphIcon!: string;

  public glyphClassName = '';
  public correctClassPrefix = true;
  public glyphSuccessfullySubmitted = false;
  public glyphUnrecognised = false;

  constructor(private glyphSelectorPipe: GlpyhSelectorPipe) {}

  ngOnChanges(): void {
    this.glyphClassName = this.glyphIcon;
    if (!this.glyphClassName) {
      this.glyphSuccessfullySubmitted = false;
    } else {
      this.glyphSuccessfullySubmitted = true;
    }
  }

  validateClassPrefix(glyphClassNameInput: string): void {
    this.glyphClassName = this.glyphSelectorPipe.transform(glyphClassNameInput);
    if (this.validateGlyph(this.glyphClassName) || this.glyphClassName.match(/^(fa(-)?(brands)?(b)?(thin)?(solid)?(regular)?(light)?(duotone)?) (fa-[a-z0-9]{1,}(-[a-z0-9]{1,})?)$/)) {
      this.correctClassPrefix = true;
    } else {
      this.correctClassPrefix = false;
    }
  }

  validateGlyph(glyph: string): boolean {
    for (const icon of glyphCollection) {
      if (icon === glyph) {
        return true;
      }
    }
    return false;
  }

  submitGlyphClassName(): void {
    if (this.correctClassPrefix) {
      setTimeout(() => {
        if (this.submittedIconContainer.nativeElement.offsetHeight > 50) {
          this.glyphUnrecognised = false;
          this.uploadGlyphEvent.emit(this.glyphClassName);
        } else {
          this.glyphUnrecognised = true;
        }
      });
      this.glyphSuccessfullySubmitted = true;
    } else {
      this.glyphSuccessfullySubmitted = false;
    }
  }

  removeIcon() {
    this.glyphSuccessfullySubmitted = false;
    this.glyphUnrecognised = false;
    this.glyphClassName = '';
    this.uploadGlyphEvent.emit(this.glyphClassName);
  }

  redirectToGlyphWebsite() {
    window.open('https://glyphsearch.com/?copy=class&library=font-awesome', '_blank');
  }
}
