import { GoalService } from './goal.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, take } from 'rxjs';

@Injectable()
export class AccessHomePageGuard  {
  private goalsUpdated = new Subject<boolean>();
  private hasGoals = false;

  constructor(private goalService: GoalService, private router: Router) {
    console.log('*** AccessHomePageGuard Guard is constructing');
    this.goalService.getGoalsUpdatedEvent().pipe(take(1)).subscribe(() => {
      this.updateHasGoals();
    });
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    console.log('*** AccessHomePageGuard Guard is canActivate');
    if (!this.goalService.isInitialised()) {
      console.log('*** AccessHomePageGuard: canActivate called... returning observable.');
      return this.goalsUpdated;
    }

    this.updateHasGoals();

    return this.hasGoals;
  }

  private navigateOnChangeOfHasGoals() {
    if (this.hasGoals) {
      console.log(`*** AccessHomePageGuard: Navigating to profile/overview`);
      this.router.navigate(['profile/overview'], { replaceUrl: true });
    } else {
      console.log(`*** AccessHomePageGuard: Navigating to goals page`);
      this.router.navigate(['goal'], { replaceUrl: true });
    }
  }

  private updateHasGoals() {
    this.hasGoals = this.goalService.getGoals().length > 0;
    console.log(`*** AccessHomePageGuard: hasActiveGoals update to: ${this.hasGoals}`);
    this.goalsUpdated.next(this.hasGoals);
    this.navigateOnChangeOfHasGoals();
  }
}
