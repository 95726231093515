import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

// models
import { EditMode } from 'src/app/generic-components/types/edit-mode.type';
import { IInvitation } from 'src/app/models/invitation.model';
import { IPlaylist } from 'src/app/models/playlist.model';
import { IPlaylistActivity } from 'src/app/models/playlist-activity.model';
import { ISharedPlaylist } from 'src/app/models/shared-playlist.model';

// types
import { EPlaylistType } from '../../../../types/playlist.type';

// ngZorro Services
import { NzMessageService } from 'ng-zorro-antd/message';

// services
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { InviteService } from 'src/app/services/invite.service';
import { PlaylistService } from 'src/app/services/playlist.service';
import { RoutingStateService } from 'src/app/services/routing-state.service';

// other
import { Clipboard } from '@angular/cdk/clipboard';
import { convert } from 'html-to-text';
import { IGuildMember } from 'src/app/modules-guilds/models/guild-member.model';
import { ILearningType } from 'src/app/models/learning-type.model';

export interface PlaylistType {
  type: EPlaylistType;
}

export interface SharedPlaylistExpandedState {
  sharedPlaylistId: number;
  isExpanded: boolean;
}
@Component({
  selector: 'sl-my-playlists',
  templateUrl: './my-playlists.component.html',
  styleUrls: ['./my-playlists.component.less'],
})

export class MyPlaylistsComponent implements OnInit {
  @Input() playlist: IPlaylist = {} as IPlaylist;
  @Input() sharedPlaylist = { playlist: {} as IPlaylist } as ISharedPlaylist;
  @Input() playlistType: EPlaylistType = EPlaylistType.PersonalPlaylist;
  @Input() isInteractable = true;
  @Input() selectedGuildMembers!: IGuildMember[];
  @Input() isPlaylistCardExpanded = false;
  @Input() learningTypes!: ILearningType[];
  @Output() handleRetirePlaylist = new EventEmitter<IPlaylist>();
  @Output() removeUserPlaylistFromSharedPlaylist = new EventEmitter<number>();
  @Output() addSharedPlaylist = new EventEmitter<ISharedPlaylist>();
  @Output() handleEditPlaylist = new EventEmitter<boolean>();
  @Output() handleSharedPlaylistExpandedCardState = new EventEmitter<SharedPlaylistExpandedState>();
  @Output() handleUpdatingSharedPlaylistVersion = new EventEmitter<ISharedPlaylist>();

  public editMode = EditMode;
  playlistComponentType = EPlaylistType;
  editPlaylist = false;
  viewPlaylist = false;
  isRetiring = false;
  loadingPlaylistActivities = false;
  expandedDescription = false;
  plainTextDescription = '';
  availablePlaylistVersions!: number[];

  defaultCardImage = 'assets/img/playlists/default-playlist.jpg';

  constructor(
    private playlistService: PlaylistService,
    private accountDetailsService: AccountDetailsService,
    private routingStateService: RoutingStateService,
    private inviteService: InviteService,
    private clipboard: Clipboard,
    private message: NzMessageService,
  ) { }

  ngOnInit(): void {
    if (this.playlist.description) {
      const countLines = this.playlist.description.match(/\n/g)?.length || 0;
      this.expandedDescription = countLines > 8;
      if (this.expandedDescription) {
        const options = {
          wordwrap: 130,
          preserveNewlines: true,
          selectors: [
            { selector: 'a', options: { baseUrl: 'https://sealadder.com' } },
            { selector: 'a.button', format: 'skip' },
          ],
        };

        this.plainTextDescription = convert(this.playlist.description, options);
      }
    }

    if (this.playlistType === EPlaylistType.SharedPlaylist) {
      if (this.isPlaylistCardExpanded === true) {
        this.openPlaylistViewer();
      }
    }
  }

  public unsharePlaylistFromGuild(event: Event) {
    this.removeUserPlaylistFromSharedPlaylist.emit(this.sharedPlaylist.id);
    event.stopPropagation();
  }

  public addSharedPlaylistToUserPlaylist(event: Event) {
    this.addSharedPlaylist.emit(this.sharedPlaylist);
    event.stopPropagation();
  }

  public copyLink(event: Event) {
    event.stopPropagation();
    this.accountDetailsService.loadCurrentUser().subscribe({
      next: () => {
        if (this.accountDetailsService.isUserValid()) {
          const publicUrl = this.accountDetailsService.getPublicUrl();
          const hostName = this.routingStateService.getHostName();
          const playlistId = this.playlist.id;
          this.inviteService.generatePlaylistInvitationCode(playlistId).subscribe({
            next: (res: IInvitation) => {
              const clipboardContents = `${hostName}/recommended/playlist/${playlistId}/from/${publicUrl}/${res.inviteCode}`;
              this.clipboard.copy(clipboardContents);
              this.message.success(`An invitation to this playlist has been copied to clipboard.`);
            },
            error: (err) => {
              this.message.error(`Error: Failed to generate a Playlist invitation for you. Unable to connect to SeaLadder HQ. Check network connection? ${err}`);
            },
          });
        }
      },
      error: (err) => {
        this.message.error(`We were unable to copy the playlist link for you. ${err}`);
      }
    });
  }

  public openPlaylistEditor(event: Event) {
    this.editPlaylist = !this.editPlaylist;
    this.viewPlaylist = false;
    event.stopPropagation();
    this.handleEditPlaylist.emit(true);
  }

  public closePlaylistEditor() {
    this.editPlaylist = false;
    this.loadPlaylistActivities();
    this.handleEditPlaylist.emit(false);
  }

  public retirePlaylist() {
    this.isRetiring = true;
    this.playlistService.retirePlaylist(this.playlist.id).subscribe({
      next: () => {
        this.playlist.retired = true;
        this.isRetiring = false;
        this.handleRetirePlaylist.emit(this.playlist);
      },
      error: () => {
        this.isRetiring = false;
        this.message.error(`We've lost connection to SeaLadder HQ and can't get the activities for this playlist. Check network connection?`);
      },
    });
    this.loadPlaylistActivities();
  }

  public playlistCreated(playlist: IPlaylist) {
    this.viewPlaylist = false;
    this.playlist = playlist;
    this.closePlaylistEditor();
  }

  public playlistUpdated(playlist: IPlaylist) {
    // if the incoming playlist has an activitCount set, then just set the entire playlist
    if (playlist.activityCount !== 0) {
      this.playlist = playlist;
    }
    else {
      // Only update the metadata properties, not the activities
      this.playlist.name = playlist.name;
      this.playlist.description = playlist.description;
      this.playlist.industry = playlist.industry;
      this.playlist.profession = playlist.profession;
      this.playlist.specialisation = playlist.specialisation;
      this.playlist.levelFrom = playlist.levelFrom;
      this.playlist.levelTo = playlist.levelTo;
      this.playlist.relatedTrackName = playlist.relatedTrackName;
      this.playlist.playlistImageUrl = playlist.playlistImageUrl;
      this.playlist.trackImageUrl = playlist.trackImageUrl;
    }

    this.closePlaylistEditor();
  }

  public openPlaylistViewer() {
    if (this.editPlaylist) {
      this.viewPlaylist = false;
    } else {
      this.loadPlaylistActivities();
    }
  }

  public getCardImage(): string {
    if (this.playlist.playlistImageUrl) {
      return this.playlist.playlistImageUrl;
    }

    if (this.playlist.trackImageUrl) {
      return this.playlist.trackImageUrl;
    }

    if (this.playlist.playlistActivitiesVm) {
      let image = '';
      // gets the first activity that has a valid thumbnail URL
      this.playlist.playlistActivitiesVm.every((pav) => {
        if (pav.imageThumbnailUrl) {
          image = pav.imageThumbnailUrl;
          return false;
        }
        return true;
      });

      if (image) {
        return image;
      }
    }
    return this.defaultCardImage;
  }


  public isPlaylistVersionTheLatestSharedPlaylistVersion(): boolean {
    return this.playlist.version === this.sharedPlaylist.latestPlaylistVersion;
  }

  public getLatestVersionOfPlaylist(event: Event) {
    event.stopPropagation();
    this.getVersionSpecificPlaylist(this.playlist.id, this.sharedPlaylist.latestPlaylistVersion);
  }

  public getVersionSpecificPlaylist(playlistId: number, playlistVersionTo: number) {
    this.playlist.isUpdatingVersion = true;
    const playlistVersionFrom = this.playlist.version;
    if (playlistVersionFrom !== playlistVersionTo) {
      this.playlistService.getVersionSpecificPlaylist(playlistId, playlistVersionTo).subscribe({
        next: (res: IPlaylist) => {
          this.playlist = res;
          const updatedSharedPlaylist: ISharedPlaylist = {
            id: this.sharedPlaylist.id,
            guildId: this.sharedPlaylist.guildId,
            playlist: res,
            sharedByFirstName: this.sharedPlaylist.sharedByFirstName,
            sharedByLastName: this.sharedPlaylist.sharedByLastName,
            playlistDateShared: this.sharedPlaylist.playlistDateShared,
            userAlreadyAdded: this.sharedPlaylist.userAlreadyAdded,
            amountAddedByGuildMembers: this.sharedPlaylist.amountAddedByGuildMembers,
            userIdsOfGuildMembersWhoAddedPlaylist: this.sharedPlaylist.userIdsOfGuildMembersWhoAddedPlaylist,
            isPlaylistCardExpanded: this.sharedPlaylist.isPlaylistCardExpanded,
            currentPlaylistVersion: this.sharedPlaylist.currentPlaylistVersion,
            latestPlaylistVersion: this.sharedPlaylist.latestPlaylistVersion,
            hasUserAddedPlaylistVersion: this.sharedPlaylist.hasUserAddedPlaylistVersion
          }
          this.sharedPlaylist = updatedSharedPlaylist;
          this.handleUpdatingSharedPlaylistVersion.emit(this.sharedPlaylist);
          this.playlist.isUpdatingVersion = false;
        },
        error: () => {
          this.message.error(`We've lost connection to SeaLadder HQ and can't get the activities for this playlist. Check network connection?`);
          this.playlist.isUpdatingVersion = false;
        }
      });
    }
  }

  public getPlaylistVersions(event: Event) {
    event.stopPropagation();
    this.playlistService.getAvailableUserPlaylistVersions(this.sharedPlaylist.playlist.id).subscribe({
      next: (res) => {
        this.availablePlaylistVersions = res;
      },
      error: () => {
        this.message.error(`We've lost connection to SeaLadder HQ and can't get the available versions of this playlist. Check network connection?`);
      }
    })
  }

  private loadPlaylistActivities() {
    if (!this.playlist.playlistActivitiesVm) {
      this.loadingPlaylistActivities = true;
      this.playlistService.getPlaylistActivities(this.playlist.id).subscribe({
        next: (res: IPlaylistActivity[]) => {
          this.playlist.playlistActivitiesVm = res;
          this.loadingPlaylistActivities = false;
          this.viewPlaylist = !this.viewPlaylist;
          if (this.playlistType === EPlaylistType.SharedPlaylist) {
            this.handleSharedPlaylistExpandedCardState.emit({ sharedPlaylistId: this.sharedPlaylist.id, isExpanded: this.viewPlaylist });
          }
        },
        error: () => {
          this.message.error(`We've lost connection to SeaLadder HQ and can't get the activities for this playlist. Check network connection?`);
          this.loadingPlaylistActivities = false;
        },
      });
    } else {
      this.viewPlaylist = !this.viewPlaylist;
      if (this.playlistType === EPlaylistType.SharedPlaylist) {
        this.handleSharedPlaylistExpandedCardState.emit({ sharedPlaylistId: this.sharedPlaylist.id, isExpanded: this.viewPlaylist });
      }
    }
  }
}
