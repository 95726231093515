<h2 class="header">Generate Invite Code</h2>
<nz-tabset class="tabs">
  <nz-tab nzTitle="For Existing User">
    <div class="generate-code-fields">
      <nz-select class="generate-code-fields-selector" ngDefaultControl nzShowSearch nzAllowClear nzPlaceHolder="Select which user to generate an invite code for" [(ngModel)]="code.email">
        <nz-option *ngFor="let user of users" nzLabel="{{ user.email }}" nzValue="{{ user.email }}"></nz-option>
      </nz-select>
    </div>
    <div class="generate-code-button">
      <button nz-button nzType="primary" (click)="generateInvitations()" [nzLoading]="isGeneratingInvitations">Generate Invitation</button>
    </div>
  </nz-tab>
  <nz-tab [nzTitle]="getNumberWaitingString()">
    <div class="button-container">
      <div class="generate-code-button">
        <button nz-button nzType="primary" (click)="generateMultipleInvitations()" [nzLoading]="isGeneratingInvitations">Generate Invitation</button>
      </div>
    </div>
    <br>

    <nz-table class="waitlist-table" #WaitlistTable [nzData]="waitlist" [nzShowPagination]=true [nzPageSizeOptions]="[10, 20, 50, 100]" [nzPageSize]="20" [nzShowSizeChanger]="true">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Registration Date</th>
          <th>Invite Last Sent</th>
          <th>Invite Count</th>
          <th class="select-button-container">
            <div class="select-all-button">Generate Invitation
              <label nz-checkbox [(nzChecked)]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)">
              </label>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="waitlist-details" *ngFor="let person of WaitlistTable.data; let i = index" (mouseover)="hoverOverItem(i)" (mouseout)="unhoverOverItem(i)">
          <td>{{person.firstName}}</td>
          <td>{{person.lastName}}</td>
          <td>{{person.email}}</td>
          <td>{{person.registrationdate}}</td>
          <td>{{person.inviteLastSent}}</td>
          <td>{{person.inviteSentCount}}</td>
          <td style="padding-left: 80px;">
            <label nz-checkbox [nzChecked]="checkedItems.includes(person.id)" nzValue="{{ person.id }}" (nzCheckedChange)="onItemChecked(person, $event)">Select</label>
          </td>
          <td style="width: 15%">
            <div [ngStyle]="{'display':(personSelectedToBeDeleted[i].status === true)?'block':'none'}">
              <button class="delete-invitation-button" nz-button nzType="default" nzDanger (click)="showConfirm(person,i)" nz-tooltip nzTooltipTitle="Delete Waitlist Person">
                <i nz-icon nzType="delete" nzTheme="fill"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Direct to Email">
    <form nz-form>
      <nz-form-item>
        <nz-form-control class="generate-code-fields-selector" [nzSpan]="5" nzErrorTip="Email is not valid">
          <input nz-input name="email" type="email" id="email" [(ngModel)]="code.email" placeholder="Specify which email to generate a reserved invite code for" email>
        </nz-form-control>
      </nz-form-item>
      <div class="generate-code-button">
        <button nz-button nzType="primary" (click)="GenerateInvitationForEmail()" [nzLoading]="isGeneratingInvitations" nz-tooltip nzTooltipTitle="Create an invitation code for the email specified.">Generate Invitation and Send Email</button>
      </div>
    </form>
  </nz-tab>
</nz-tabset>

<nz-table class="invitations-table" #InvitationsTable [nzData]="invitationsFiltered" [nzShowPagination]=true>
  <thead>
    <tr>
      <th>Invite Code</th>
      <th>Invite URL</th>
      <th>Notes</th>
      <th>Created By</th>
      <th>Owned By</th>
      <th>Date Created</th>
      <th>Reserved For</th>
      <th>Used</th>
      <th>Allocated</th>
      <th>Requesting</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let invitation of InvitationsTable.data">
      <td>{{invitation.inviteCode}}</td>
      <td>{{invitation.inviteUrl}}</td>
      <td>
        <input type="text" placeholder="{{invitation.notes}}" nz-input [(ngModel)]="invitation.notes" (blur)="updateNotes(invitation)" maxlength="50" />
      </td>
      <td><span nz-tooltip nzTooltipTitle={{invitation.userCreatedByEmail}}>{{invitation.userCreatedByName}}</span></td>
      <td><span nz-tooltip nzTooltipTitle={{invitation.userOwnedByEmail}}>{{invitation.userOwnedByName}}</span></td>
      <td>{{invitation.dateCreated}}</td>
      <td>{{invitation.reservedFor}}</td>
      <td>{{invitation.amountUsed}}</td>
      <td>
        <input class="allocated-amount" type="number" placeholder="{{invitation.amountAllocated}}" nz-input [(ngModel)]="invitation.amountAllocated" (blur)="updateInvitationUses(invitation)" width="1" />
      </td>
      <label id="isRequesting">
        <input class="request-status" type="checkbox" [checked]="invitation.requestStatus" (change)="toggleRequestStatus(invitation)" />
      </label>
    </tr>
  </tbody>
</nz-table>
<div class="filter-codes-requesting-allocation">
  <label nz-checkbox [(ngModel)]="enabledInactiveInvitationCodes" (ngModelChange)="filterCodesRequestingAllocation()">Filter by Codes Requesting Allocation</label>
</div>
