<nz-card class="playlist-card" [nzHoverable]="true" (click)="openPlaylistViewer()">
  <div class="grid-container">
    <div id="playlist-image-container">
      <nz-badge
        *ngIf="playlistType === playlistComponentType.PersonalPlaylist"
        [nzStyle]="{ backgroundColor: '#f5a623' }"
        [nzCount]="playlist.totalUsed"
        nzShowZero
        nzTooltipTitle="{{ playlist.totalUsed | SingularPlural: 'user has' : 'users have' }} added this playlist"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <img id="playlist-image" nz-image [nzSrc]="getCardImage()" [nzFallback]="defaultCardImage" alt="" />
      </nz-badge>

      <nz-badge
        *ngIf="playlistType === playlistComponentType.SharedPlaylist"
        [nzStyle]="{ backgroundColor: '#f5a623' }"
        [nzCount]="sharedPlaylist.amountAddedByGuildMembers"
        nzShowZero
        nzTooltipTitle="{{ sharedPlaylist.amountAddedByGuildMembers | SingularPlural: 'user has' : 'users have' }} added this playlist"
        nzTooltipPlacement="top"
        nz-tooltip
      >
        <img id="playlist-image" nz-image [nzSrc]="getCardImage()" [nzFallback]="defaultCardImage" alt="" />
      </nz-badge>

      <nz-tag *ngIf="playlist.activityCount !== 1" id="playlist-activities-tag" nzColor="processing" nzTooltipTitle="{{ playlist.activityCount }} activities in this playlist" nzTooltipPlacement="top" nz-tooltip>
        <span>{{ playlist.activityCount }} activities</span>
      </nz-tag>
      <nz-tag *ngIf="playlist.activityCount === 1" id="playlist-activities-tag" nzColor="processing" nzTooltipTitle="{{ playlist.activityCount }} activity in this playlist" nzTooltipPlacement="top" nz-tooltip>
        <span>{{ playlist.activityCount }} activity</span>
      </nz-tag>
    </div>
    <div id="playlist-contents">
      <nz-card-meta class="playlist-info" [nzTitle]="cardMeta"></nz-card-meta>
      <ng-template #cardMeta>
        <div class="card-meta">
          <div class="playlist-title">{{ playlist.name }}</div>
          <nz-tag *ngIf="!playlist.retired && playlistType === playlistComponentType.PersonalPlaylist" class="playlist-version-tag">
            <span>V{{ playlist.version }}</span>
          </nz-tag>

          <nz-tag *ngIf="playlist.retired && playlistType === playlistComponentType.PersonalPlaylist" class="playlist-version-tag" nzColor="warning" nz-tooltip="This playlist has been retired">
            <span>V{{ playlist.version }}</span>
          </nz-tag>

          <nz-tag
            *ngIf="!isPlaylistVersionTheLatestSharedPlaylistVersion() && playlistType === playlistComponentType.SharedPlaylist"
            class="playlist-version-tag"
            [nzColor]="isPlaylistVersionTheLatestSharedPlaylistVersion() ? 'default' : 'processing'"
            [nz-tooltip]="!isPlaylistVersionTheLatestSharedPlaylistVersion() ? 'Update playlist to the latest version' : 'Click to view previous versions of this playlist'"
            (click)="isPlaylistVersionTheLatestSharedPlaylistVersion() ? getPlaylistVersions($event) : getLatestVersionOfPlaylist($event)"
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="isPlaylistVersionTheLatestSharedPlaylistVersion() ? menu : null"
          >
            <span *ngIf="playlist.updatingVersion" nz-icon nzType="sync" nzSpin></span>
            <span *ngIf="isPlaylistVersionTheLatestSharedPlaylistVersion()">V{{ playlist.version }}</span>
            <span *ngIf="!isPlaylistVersionTheLatestSharedPlaylistVersion()">V{{ playlist.version }} <span nz-icon nzType="caret-right" nzTheme="outline"></span> V{{ sharedPlaylist.latestPlaylistVersion }}</span>
          </nz-tag>

          <nz-tag
            *ngIf="isPlaylistVersionTheLatestSharedPlaylistVersion() && playlistType === playlistComponentType.SharedPlaylist"
            class="playlist-version-tag"
            [nzColor]="'default'"
            [nz-tooltip]="'Click to view other versions of this playlist'"
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="menu"
            (click)="getPlaylistVersions($event)"
          >
            <span *ngIf="playlist.updatingVersion" nz-icon nzType="sync" nzSpin></span>
            <span>V{{ playlist.version }}</span>
          </nz-tag>

          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <div *ngFor="let version of availablePlaylistVersions">
                <li id="playlist-version-menu-item" nz-menu-item [nzSelected]="version === playlist.version" (click)="getVersionSpecificPlaylist(playlist.id, version)">V{{ version }}</li>
              </div>
            </ul>
          </nz-dropdown-menu>
        </div>
      </ng-template>
      <nz-divider></nz-divider>
      <nz-skeleton *ngIf="loadingPlaylistActivities" [nzParagraph]="{ rows: 6 }"></nz-skeleton>
      <div *ngIf="!editPlaylist && !viewPlaylist && !loadingPlaylistActivities">
        <p *ngIf="expandedDescription" nz-typography nzEllipsis nzExpandable [nzEllipsisRows]="3">{{ plainTextDescription }}</p>
        <p *ngIf="!expandedDescription" [innerHTML]="playlist.description"></p>
      </div>
      <div *ngIf="editPlaylist">
        <sl-create-playlist [playlistEditing]="playlist" (handlePlaylistCreated)="playlistCreated($event)" (handlePlaylistUpdated)="playlistUpdated($event)"></sl-create-playlist>
      </div>
      <div *ngIf="viewPlaylist && !loadingPlaylistActivities">
        <sl-playlist-viewer [playlist]="playlist" [sharedPlaylist]="sharedPlaylist" [selectedGuildMembers]="selectedGuildMembers" [learningTypes]="learningTypes"></sl-playlist-viewer>
      </div>
    </div>
    <div id="playlist-actions">
      <span *ngIf="editPlaylist && !isRetiring" class="action-icon-parent">
        <i
          class="playlist-retire action-icon fas fa-trash-alt danger"
          nzTooltipTitle="Retire playlist"
          nzTooltipPlacement="top"
          nz-tooltip
          nzOkDanger="true,"
          (nzOnConfirm)="retirePlaylist($event)"
          [nzIcon]="iconTpl"
          nz-popconfirm
          nzPopconfirmTitle="Retiring playlists hide it as a recommendation for others?"
          nzAutoFocus="cancel"
        ></i>
      </span>
      <ng-template #iconTpl>
        <span id="playlist-actions-retire" nz-icon nzType="question-circle-o"></span>
      </ng-template>
      <span *ngIf="!sharedPlaylist.userAlreadyAdded && !sharedPlaylist.hasUserAddedPlaylistVersion && !editPlaylist && isInteractable"
        ><i id="add-playlist-icon" class="fa-solid fa-plus action-icon" nzTooltipTitle="Add playlist to your profile" (click)="addSharedPlaylistToUserPlaylist($event)"></i
      ></span>
      <span *ngIf="!editPlaylist && isInteractable" class="action-icon-parent"><i id="edit-playlist" class="playlist-edit action-icon fa fa-pencil" nzTooltipTitle="View / Edit playlist" nzTooltipPlacement="top" nz-tooltip (click)="openPlaylistEditor($event)"></i></span>
      <span *ngIf="editPlaylist" class="action-icon-parent"><i id="cancel-edit-playlist" class="playlist-edit action-icon fa fa-stop" nzTooltipTitle="Cancel Editing Playlist" nzTooltipPlacement="top" nz-tooltip (click)="closePlaylistEditor()"></i></span>
      <div *ngIf="isRetiring">
        <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
        <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
      </div>
      <i *ngIf="!sharedPlaylist.playlist.retired" class="playlist-share action-icon fas fa-share" nzTooltipTitle="Share Playlist With Others" nzTooltipPlacement="top" nz-tooltip (click)="copyLink($event)"></i>
    </div>
  </div>
</nz-card>
