import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IMetricLineChart } from 'src/app/models/metric-name-value.model';
import { IUserActivity } from 'src/app/models/user-activity.model';

// models
import { ESubscriptionStatus, IUserProfilePublic } from 'src/app/models/user-profile.model';

// services
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { MetricService } from 'src/app/services/metric.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { EMetricViewType } from 'src/app/types/metric-view-type';

@Component({
  selector: 'sl-nameplate',
  templateUrl: './nameplate.component.html',
  styleUrls: ['./nameplate.component.less'],
})
export class NameplateComponent implements OnInit, OnChanges, OnDestroy {
  @Output() inviteUserToGuildEmitter = new EventEmitter<string>();
  @Input() userProfilePublic?: IUserProfilePublic;  // Pass in the public profile if you want the component to be in public profile mode, otherwise, it will go and get the profile of the current user.
  @Input() isPublicProfileMode = false;
  @Input() isInvitingUserToGuild = false;
  @Input() hasPermissionToInviteUserToGuild = false;
  @Input() refreshUserActivityMetric = false;
  @Input() hasInvitedUserToGuild = false;
  fullName!: string;
  userLocation!: string;
  subscriptionStatus!: ESubscriptionStatus;
  subscriptionStatusMessage!: string;
  view: number[] = [200, 80];
  colourScheme = 'ocean';
  completedUserActivities!: IUserActivity[];
  completedUserActivitiesMetric!: IMetricLineChart;
  metricViewType!: EMetricViewType;
  viewType = EMetricViewType;
  animations = true;
  componentInitialised = false; // used to suppress the first ngOnChanges before the component is initialised.

  //sparkline data points
  monthlyBinAmount = 3;
  weeklyBinAmount = 12;
  yearlyBinAmount = 12;

  isFlipped = false
  isOnPause = false;
  // isUserAtRiskOfLosingStreak = false;
  showPrimaryHourglass = true;
  nameplateFlipIntervalId!: number | null;
  hourglassFlipIntervalId!: number | null;
  private readonly NAMEPLATE_FLIP_INTERVAL_TIME = 5000;

  constructor(private accountDetailsService: AccountDetailsService, private paymentService: PaymentService, public router: Router, private message: NzMessageService, private metricService: MetricService, private userActivitiesService: UserActivitiesService) { }

  ngOnInit(): void {
    this.getUserProfile();
    this.getSubscriptionStatus();
    this.componentInitialised = true;
    this.getActivityStreakDays();
    this.isUserAtRiskOfLosingStreak()
    this.startAutoFlip(this.toggleFlip.bind(this));

    this.userActivitiesService.getActivitiesUpdatedEvent().subscribe({
      next: () => {
        this.getActivityStreakDays();
        this.isUserAtRiskOfLosingStreak()
      },
      error: (error) => console.error(`*** ProfileActivitiesTableComponent: Failed to get activities updating event. Error: ${error}`),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.componentInitialised && changes.refreshUserActivityMetric) {
      this.refreshUserActivityMetricChart();
    }
  }

  ngOnDestroy(): void {
    this.stopAutoFlip();
  }

  toggleFlip() {
    this.isFlipped = !this.isFlipped;
    this.resetAutoFlipTimer();
  }

  togglePausePlay(event: Event) {
    event.stopPropagation();
    this.isOnPause = !this.isOnPause;
    if (this.isOnPause) {
      this.stopAutoFlip();
    } else {
      this.resetAutoFlipTimer();
    }
  }

  inviteUserToGuild() {
    this.inviteUserToGuildEmitter.emit(this.userProfilePublic?.publicUrl);
  }

  getSubscriptionStatus() {
    if (this.isPublicProfileMode) {
      return;
    }

    this.paymentService.getSubscriptionStatus().subscribe({
      next: (res) => {
        this.subscriptionStatus = res;
        this.setSubscriptionStatusMessage();
      },
      error: (err) => {
        this.message.error('Failed to get subscription status');
        console.error(`Failed to get subscription status: ${err.message}`);
      },
    });
  }

  editAccount() {
    const route = this.router.url;
    this.router.navigate(['/account', { from: route }]);
  }

  toggleMetricViewType(event: Event) {
    event.stopPropagation();
    if (this.metricViewType === EMetricViewType.Weekly) {
      // If currently Weekly, switch to Monthly
      this.getCompletedActivitiesMetric(EMetricViewType.Monthly, this.monthlyBinAmount);
    } else if (this.metricViewType === EMetricViewType.Monthly) {
      // If currently Monthly, switch to Yearly
      this.getCompletedActivitiesMetric(EMetricViewType.Yearly, this.yearlyBinAmount);
    } else {
      // If currently Yearly, switch to Weekly
      this.getCompletedActivitiesMetric(EMetricViewType.Weekly, this.weeklyBinAmount);
    }
  }

  refreshUserActivityMetricChart() {
    if (this.metricViewType === EMetricViewType.Monthly) {
      this.getCompletedActivitiesMetric(EMetricViewType.Monthly, this.monthlyBinAmount);
    } else if (this.metricViewType === EMetricViewType.Yearly) {
      this.getCompletedActivitiesMetric(EMetricViewType.Yearly, this.yearlyBinAmount);
    } else {
      this.getCompletedActivitiesMetric(EMetricViewType.Weekly, this.weeklyBinAmount);
    }
    this.refreshUserActivityMetric = false;
  }

  isUserAtRiskOfLosingStreak(): boolean {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userActivities = this.userActivitiesService.getUserActivities();
    const today = new Date().toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { timeZone });
    const todayDate = new Date(today);
    todayDate.setHours(0, 0, 0, 0);

    const activityStreakDays = this.getActivityStreakDays();

    if (activityStreakDays < 1) {
      return false; // User is not at risk of losing a streak if they had no streak to begin with
    }

    const endOfDay = new Date(todayDate)
    endOfDay.setDate(endOfDay.getDate() + 1);

    const now = new Date();
    const hoursRemaining = (endOfDay.getTime() - now.getTime()) / (1000 * 60 * 60); // Calculate the number of hours between and midnight

    if (hoursRemaining > 12) {
      return false; // The user is not at risk if more than 12 hours remain
    }

    const hasCompletedActivityToday = userActivities.some(activity => {
      const activityDate = new Date(new Date(activity.dateCompleted!).toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { timeZone }));
      activityDate.setHours(0, 0, 0, 0);
      return activityDate.getTime() === todayDate.getTime();
    })

    if (hasCompletedActivityToday) {
      return false; // User is not at risk if they have completed today's activity
    }

    return true;
  }

  getActivityStreakDays(): number {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userActivities = this.userActivitiesService.getUserActivities();
    const today = new Date().toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { timeZone });
    const todayDate = new Date(today);

    todayDate.setHours(0, 0, 0, 0); // Normalise the start of the day in the user's timezone
    const completedActivityDates = this.calculateCompletedAcivityDates(userActivities, todayDate, timeZone);

    if (!completedActivityDates.length) {
      return 0;
    }

    let streak = 0;
    const currentDate = new Date(todayDate);

    // Start with the day before since we should have 2 consecutive days of completed activities for a streak to occur
    currentDate.setDate(currentDate.getDate() - 1)

    for (const activityDate of completedActivityDates) {
      const normalizedCurrentDate = new Date(currentDate);
      normalizedCurrentDate.setHours(0, 0, 0, 0); // Normalize to start of the day

      const normalizedActivityDate = new Date(activityDate);
      normalizedActivityDate.setHours(0, 0, 0, 0);

      if (normalizedCurrentDate.getTime() === normalizedActivityDate.getTime()) {
        streak++;
        currentDate.setDate(currentDate.getDate() - 1);
      } else {
        break; //stop counting once we encounter non-consecutive days
      }
    }

    return streak;
  }

  private calculateCompletedAcivityDates(userActivities: IUserActivity[], todayDate: Date, timeZone: string) {
    return userActivities.filter(activity => {
      if (!activity.dateCompleted) {
        return false;
      }

      const activityDate = new Date(new Date(activity.dateCompleted).toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { timeZone }));
      return activityDate <= todayDate;
    })
      .map(activity => {
        const activityDate = new Date(new Date(activity.dateCompleted!).toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { timeZone }));
        activityDate.setHours(0, 0, 0, 0);
        return activityDate.getTime();
      })
      .sort((a, b) => b - a) // sort dates in descending order
      .filter((value, index, datesArray) => datesArray.indexOf(value) === index);
  }

  private startAutoFlip(callback?: () => void) {
    this.nameplateFlipIntervalId = window.setInterval(() => {
      if (callback) {
        callback();
      }
    }, this.NAMEPLATE_FLIP_INTERVAL_TIME);
  }

  private stopAutoFlip() {
    if (this.nameplateFlipIntervalId) {
      clearInterval(this.nameplateFlipIntervalId);
    }
  }

  private clearAutoFlipTimer() {
    if (this.nameplateFlipIntervalId) {
      clearInterval(this.nameplateFlipIntervalId);
      this.nameplateFlipIntervalId = null;
    }
  }

  private resetAutoFlipTimer() {
    this.clearAutoFlipTimer();
    if (!this.isOnPause) {
      this.startAutoFlip(this.toggleFlip.bind(this));
    }
  }

  private getUserProfile() {
    if (this.userProfilePublic) {
      this.setUserLocation(this.userProfilePublic);
      this.setUserName(this.userProfilePublic);
    } else {
      this.accountDetailsService.getUserProfileSubscription().subscribe({
        next: (profile: IUserProfilePublic) => {
          this.userProfilePublic = profile;
          this.setUserLocation(this.userProfilePublic);
          this.setUserName(this.userProfilePublic);
        },
        error: (err) => console.error(err)
      });
    }

    this.getCompletedActivitiesMetric(EMetricViewType.Weekly, this.weeklyBinAmount);
  }

  private setSubscriptionStatusMessage() {
    if (this.subscriptionStatus === ESubscriptionStatus.Subscribed) {
      this.subscriptionStatusMessage = 'SeaLadder Trailblazer Account';
    } else if (this.subscriptionStatus === ESubscriptionStatus.Free) {
      this.subscriptionStatusMessage = 'SeaLadder Freerider Account';
    } else if (this.subscriptionStatus === ESubscriptionStatus.Trial) {
      this.subscriptionStatusMessage = 'SeaLadder Testdrive Account';
    } else if (this.subscriptionStatus === ESubscriptionStatus.TrialExpired) {
      this.subscriptionStatusMessage = 'SeaLadder Expired Trial';
    } else if (this.subscriptionStatus === ESubscriptionStatus.Cancelled) {
      this.subscriptionStatusMessage = 'SeaLadder Defector Account';
    } else if (this.subscriptionStatus === ESubscriptionStatus.Unsubscribed) {
      this.subscriptionStatusMessage = 'SeaLadder Account Expired';
    } else {
      this.subscriptionStatusMessage = 'Account Status is in a Nebulus Condition';
      console.error('The sealadderSubscription status is not known when it should just be one of the enumerations. Currently set to: ' + this.subscriptionStatus + '.');
    }
  }

  private setUserName(userProfile: IUserProfilePublic | undefined) {
    const firstName = (userProfile && userProfile.name) ? userProfile.name : '';
    const surname = (userProfile && userProfile.surname) ? (firstName ? ' ' : '') + userProfile.surname : '';
    this.fullName = firstName + surname;
  }

  private setUserLocation(userProfile: IUserProfilePublic | undefined) {
    const suburb = !(userProfile?.addressSuburb) ? '' : userProfile.addressSuburb;
    const state = !(userProfile?.addressState) ? '' : userProfile.addressState;
    this.userLocation = suburb !== '' && state !== '' ? `${suburb} | ${state}` : `${suburb}${state}`;
  }

  private getCompletedActivitiesMetric(viewType: EMetricViewType, numberOfBins: number) {
    this.metricViewType = viewType;
    this.metricService.getCompletedUserActivitesMetric(viewType, numberOfBins).subscribe(cam => {
      this.completedUserActivitiesMetric = cam;
    });
  }
}
