import { IPlaylist } from './../../../../models/playlist.model';
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { EPlaylistType } from 'src/app/types/playlist.type';
import { ISharedPlaylist } from 'src/app/models/shared-playlist.model';
import { IGuildMember } from 'src/app/modules-guilds/models/guild-member.model';
import { SharedPlaylistExpandedState } from 'src/app/components/profile-v2/profile-playlists/my-playlists/my-playlists.component';
import { ILearningType } from 'src/app/models/learning-type.model';
import { UserPlaylistService } from 'src/app/services/user-playlist.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'sl-shared-playlist-card',
  templateUrl: './shared-playlist-card.component.html',
  styleUrls: ['./shared-playlist-card.component.less'],
})
export class SharedPlaylistCardComponent implements OnChanges {
  @Output() addSharedPlaylist = new EventEmitter<ISharedPlaylist>();
  @Output() handleRetirePlaylist = new EventEmitter<IPlaylist>();
  @Output() handleSharedPlaylistExpandedCardState = new EventEmitter<SharedPlaylistExpandedState>();
  @Output() handleUpdatingSharedPlaylistVersion = new EventEmitter<ISharedPlaylist>();
  @Input() sharedPlaylist: ISharedPlaylist = { playlist: {} as IPlaylist } as ISharedPlaylist;
  @Input() isInteractable = false;
  @Input() selectedGuildMembers!: IGuildMember[];
  @Input() learningTypes!: ILearningType[];
  playlistComponentType = EPlaylistType;
  isEditingPlaylist = false;

  constructor(private userPlaylistService: UserPlaylistService, private message: NzMessageService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedGuildMembers) {
      this.selectedGuildMembers = this.selectedGuildMembers.filter(gm => this.sharedPlaylist.userIdsOfGuildMembersWhoAddedPlaylist.includes(gm.userId));
    }
    if (changes.sharedPlaylist) {
      this.hasUserAddedPlaylistVersion(this.sharedPlaylist.playlist.id);
    }
  }

  getSharedPlaylistExpandedCardState(sharedPlaylistExpandedState: SharedPlaylistExpandedState) {
    this.handleSharedPlaylistExpandedCardState.emit(sharedPlaylistExpandedState);
  }

  addSharedPlaylistToUserPlaylist(sharedPlaylist: ISharedPlaylist) {
    this.addSharedPlaylist.emit(sharedPlaylist);
  }

  editPlaylist(status: boolean) {
    this.isEditingPlaylist = status;
  }

  retirePlaylist(playlist: IPlaylist) {
    this.handleRetirePlaylist.emit(playlist);
  }

  updatedSharedPlaylistVersion(sharedPlaylist: ISharedPlaylist) {
    this.handleUpdatingSharedPlaylistVersion.emit(sharedPlaylist);
  }

  private hasUserAddedPlaylistVersion(playlistId: number): void {
    this.userPlaylistService.hasUserAddedPlaylistVersion(playlistId).subscribe({
      next: (res) => {
        this.sharedPlaylist.hasUserAddedPlaylistVersion = res;
      },
      error: () => {
        this.message.error(`We've lost connection to SeaLadder HQ and can't get the shared playlists for this guild. Check network connection?`);
      }
    })
  }
}
