import { Component, OnInit, Input, TemplateRef } from '@angular/core';

/*
Description:
Shows an information icon that creates a ng zorro popover when clicked.
The contents of the popover depend on the inputs of the component.

Usage:
- The component must have a 'description' or a 'template' input, it will not render otherwise.
- To use a custom template, pass the anchor id of a <ng-template #inputThis> element to the
  'template' input. (see .spec.ts)
- The 'timeoutSec' specifies the number of seconds to wait till hiding the popover.
*/
@Component({
  selector: 'sl-help-info',
  templateUrl: './help-info.component.html',
  styleUrls: ['./help-info.component.less'],
})
export class HelpInfoComponent implements OnInit {
  @Input() title!: string;
  @Input() description?: string;
  @Input() image?: string;
  @Input() contentIconNgZorroDuo?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() template?: TemplateRef<any>;
  @Input() timeoutSec?: number;
  visible = false;
  timer!: string;
  private defaultImage = '/assets/img/sealadder/sealadder-logo-96x96.png';

  constructor() {}

  ngOnInit(): void {
    this.initImage();
  }

  showHelpIcon(): boolean {
    if (this.hasTitle()) {
      return this.hasTemplate() || this.hasDescription();
    } else {
      return false;
    }
  }

  hasTemplate(): boolean {
    return this.template ? true : false;
  }

  hasDescription(): boolean {
    return this.description ? true : false;
  }

  hasTitle(): boolean {
    return this.title ? true : false;
  }

  initImage() {
    if (!this.image && this.contentIconNgZorroDuo === undefined) {
      this.image = this.defaultImage;
    }
  }

  showContentIcon(): boolean {
    return undefined !== this.contentIconNgZorroDuo && undefined === this.image;
  }

  change(visible: boolean): void {
    const secToMs = 1000;

    if (visible && this.timeoutSec !== undefined) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.visible = false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, this.timeoutSec * secToMs) as any;
    }
  }
}
