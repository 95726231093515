<div class="image-container">
  <div class="upload-col">
    <div>
      <nz-upload id="image-uploader" class="company-image-container avatar-uploader" nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzCustomRequest]="uploadActionImage" (click)="selectImageContainer()">
        <ng-container *ngIf="!checkForExistingImage()">
          <i class="upload-icon" nz-icon [nzType]="isLoadingImage?'loading':'plus'"></i>
          <div class="ant-upload-text">Upload</div>
        </ng-container>
        <div *ngIf="imageContainerSelected===true; then imageContainerIsSelected else imageContainerIsNotSelected">
        </div>
        <ng-template #imageContainerIsSelected>
          <img class="image" *ngIf="getImageUrlFromImageContainer()" (error)="invalidImageUrl($event)" [src]=" getImageUrlFromImageContainer()" />
        </ng-template>
        <ng-template #imageContainerIsNotSelected>
          <img #imageFromInputBar class="image" [alt]="!imageFromInputBar.offsetHeight === 22 || !imageFromInputBar.offsetHeight ===0 ? '':'Invalid URL'" [ngClass]="!imageFromInputBar.offsetHeight ===22 || !imageFromInputBar.offsetHeight ===0 ?'image':'image-error'"
            *ngIf="getImageUrlFromInputBar()" [src]="getImageUrlFromInputBar()" />
        </ng-template>
      </nz-upload>
    </div>
    <input class="image-url-input" nz-input placeholder="Image URL" tabindex="-1" (click)="$event.target.select()" [(ngModel)]="imageUrlInputValue" (change)="uploadImageFromInputBar()" (ngModelChange)="selectImageInputBar()" (focus)="selectImageInputBar()">
  </div>
</div>
