import { Injectable } from '@angular/core';
import { IAppData } from '../models/app-data.model';

@Injectable({
  providedIn: 'root'
})
export class AppDetailsServiceData {
  private initialised = false;
  private version = 'unknown';
  private dbConnected = false;

  constructor() { }

  init(appDetails: IAppData): void {
    if (appDetails === undefined || appDetails === null) {
      throw new Error('*** AppDetailsServiceData: init - Cannot set to an undefined value');
    }
    this.initialised = true;
    this.version = appDetails.version;
    this.dbConnected = appDetails.dbConnected;
  }

  isInitialised(): boolean {
    return this.initialised;
  }

  getVersion(): string {
    return this.version;
  }

  isDbConnected(): boolean {
    return this.dbConnected;
  }
}
