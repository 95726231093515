import { Component, Input, OnInit } from '@angular/core';
import { ExternalApiService } from 'src/app/services/external-api.service';

@Component({
  selector: 'sl-keyword-info',
  templateUrl: './keyword-info.component.html',
  styleUrls: ['./keyword-info.component.less']
})
export class KeywordInfoComponent implements OnInit {
  @Input() keyword!: string;
  @Input() displayType ? = 'tooltip';
  summary!: string;
  keywordUrl!: string;

  constructor(private externalApiService: ExternalApiService) { }

  ngOnInit(): void {
    this.initialiseKeywordSummary();
  }

  private initialiseKeywordSummary() {
    this.externalApiService.getWikipediaExtract(this.keyword).subscribe(
      result => {
        this.keywordUrl = result.content_Urls.desktop.page;

        if (result.type === 'standard') {
          this.summary = result.extract;
          // console.log('*** ExternalApiService: getWikipediaExtract summary for keyword: ' + this.keyword);
          // console.log('*** ' + this.summary);
        } else {
          this.summary = `SeaLadder currently lacks a meaningful definition for ${this.keyword}. You could help us out by editing this and adding something more meaningful.`;
          console.log('*** ExternalApiService: getWikipediaExtract retrieved a bad result for keyword: ' + this.keyword);
        }
      },
      (error) => {
        this.summary = '';
        console.error(`*** ExternalApiService: getWikipediaExtract ${JSON.stringify(error)}`);
      }
    );
  }
}
