<a id="show-button" (click)="showModal()"><i nz-icon nzType="edit"></i></a>
<nz-modal [(nzVisible)]="isVisible" nzTitle="Update SeaLadder Track" (nzOnCancel)="handleCancel()" [nzContent]="modalContent" [nzFooter]="modalFooter">
  <ng-template #modalContent>
    <p>Thankyou so much for helping us keep SeaLadder's tracks up to date. This will improve the experience for everyone else, so this makes you a legend.</p>
    <div class="input-row" nz-row>
      <span class="property-title" nz-col [nzSpan]="6">Name:</span>
      <input class="property-input start-here" nz-col [nzSpan]="8" nz-input [(ngModel)]="targetData.name" ngDefaultControl>
    </div>
    <div class="input-row" nz-row>
      <span class="property-title" nz-col [nzSpan]="6">Description:</span>
      <textarea  nz-col [nzSpan]="14" nz-input rows="3" [(ngModel)]="targetData.description" ngDefaultControl></textarea>
      <button id="generate-button" nz-button nz-tooltip nzTooltipTitle="Get ChatGPT to generate a description!" [nzLoading]="generating"  (click)="autogenerate()" >
        <img src="assets/img/icons/chatgpt.svg" class="gpt-icon" [hidden]="generating"/>
      </button>
    </div>
    <div class="input-row" nz-row>
      <span class="property-title" nz-col [nzSpan]="6">Image:</span>
      <nz-upload nz-col [nzSpan]="6" class="image-container" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzCustomRequest]="uploadImage">
        <ng-container *ngIf="!targetData.imageUrl">
          <i class="upload-icon" nz-icon [nzType]="'plus'"></i>
          <div class="ant-upload-text">Upload</div>
        </ng-container>
        <img class="form-image" *ngIf="targetData.imageUrl" [src]="targetData.imageUrl" />
      </nz-upload>
      <input class="property-input" nz-col [nzSpan]="8" nz-input [(ngModel)]="targetData.imageUrl" placeholder="Or Enter Url" ngDefaultControl>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button id="cancel-button" nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <button id="submit-button" nz-button nzType="primary" (click)="submit()">Submit</button>
  </ng-template>
</nz-modal>