import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGuild } from '../../models/guild.model';
import { Router } from '@angular/router';
import { IUserProfilePublic } from 'src/app/models/user-profile.model';
import { GuildMemberStatus } from '../../types/guild-member-status.type';
import { GuildService } from 'src/app/services/guild.service';
import { EGuildVisibilityType } from '../../types/guild-visibility.type';
@Component({
  selector: 'sl-my-guilds',
  templateUrl: './my-guilds.component.html',
  styleUrls: ['./my-guilds.component.less'],
})
export class MyGuildsComponent {
  @Output() handleSelectedGuild = new EventEmitter<IGuild>();
  @Input() currentGuild!: IGuild;
  @Input() radioValue!: number;
  @Input() myGuilds: IGuild[] = [];
  @Input() currentUser: IUserProfilePublic = {} as IUserProfilePublic;
  defaultValue = 0;
  guildMemberStatus = GuildMemberStatus;
  guildVisibilityType = EGuildVisibilityType;

  constructor(private router: Router, private guildService: GuildService) { }
  selectGuild(guild: IGuild) {
    this.handleSelectedGuild.emit(guild);
  }

  isHomeGuildRoute() {
    return this.router.url === '/guilds';
  }
}
