<nz-modal id="goal-selection-modal" [(nzVisible)]="goalSelectorVisible" nzTitle="Select a goal" (nzOnCancel)="closeGoalSelectionModal()" [nzOkDisabled]="!hasGoalBeenSelected()" (nzOnOk)="selectGoalFromGoalSelectionModal()">
  <ng-container *nzModalContent>
    <nz-select class="goal-selector" [(ngModel)]="selectedGoal" name="value" [nzDisabled]="goals.length ? false : true"
      [nzPlaceHolder]="goals.length ? 'Select a goal' : (goalSourceType === goalSelectorComponentType.Playlist ? 'You have no goals suitable for this playlist' : 'You have no goals suitable for this activity')" (ngModelChange)="selectGoal($event)">
      <nz-option *ngFor="let goal of goals" [nzValue]="goal" nzLabel="{{ goal?.selectedTrack?.name }}, {{ goal?.level }} ({{ goal?.industry }}, {{ goal?.profession }}, {{ goal?.specialisation }})"></nz-option>
    </nz-select>
    <span>
      <a id="customise-goal-link" *ngIf="goals.length && hasGoalBeenSelected() && goalSourceType ===  goalSelectorComponentType.Playlist " class="goal-creator" nzType="link" [routerLink]="['/profile/overview']"
        [queryParams]="{ guild: encodeUrlComponent(guild.name), goalId: selectedGoal.id, playlistId: selectedSharedPlaylist.id }">
        Need a different goal for this playlist? Customise yours now...
      </a>

      <a id="customise-goal-link" *ngIf="goals.length && hasGoalBeenSelected() && goalSourceType ===  goalSelectorComponentType.Activity " class="goal-creator" nzType="link" [routerLink]="['/profile/overview']"
        [queryParams]="{ guild: encodeUrlComponent(guild.name), goalId: selectedGoal.id, activityId: selectedActivity.activityTypeId }">
        Need a different goal for this activity? Customise yours now...
      </a>
      <a id="create-goal-link" *ngIf="!goals.length && goalSourceType ===  goalSelectorComponentType.Playlist" class="goal-creator" nzType="link" [routerLink]="['/profile/overview']"
        [queryParams]="{ guild: encodeUrlComponent(guild.name), playlistId: selectedSharedPlaylist.id, industry: getSharedPlaylistIndustry(), profession: getSharedPlaylistProfession(), specialisation: getSharedPlaylistSpecialisation(), level: getSharedPlaylistLevel() }">
        Don't have a suitable goal? Why not create one for this playlist...
      </a>

      <a id="create-goal-link" *ngIf="!goals.length && goalSourceType ===  goalSelectorComponentType.Activity" class="goal-creator" nzType="link" [routerLink]="['/profile/overview']"
        [queryParams]="{ guild: encodeUrlComponent(guild.name), activityId: selectedActivity.activityTypeId, industry: getGuildIndustry(),  profession: getGuildProfession(), specialisation: getGuildSpecialisation(), level: getGuildLevel() }">
        Don't have a suitable goal? Why not create one for this activity...
      </a>
    </span>
  </ng-container>
</nz-modal>