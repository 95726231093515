import { Component } from '@angular/core';

@Component({
  selector: 'sl-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less'],
})
export class AdminComponent {
  constructor() {}
}
