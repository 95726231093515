import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// models
import { IGoal } from '../models/user-goal.model';

// types
import { EUserRowState } from '../types/user-activity-state.type';

@Injectable()
export class GoalServiceData {
  private goals: IGoal[] | undefined;
  private goalsUpdated = new Subject<boolean>();

  constructor() {
    // console.log('*** GoalServiceData: constructing');
  }

  public get initialised(): boolean {
    return undefined !== this.goals;
  }

  get hasActiveGoals(): boolean {
    if (undefined === this.goals) {
      throw new Error('Cannot get establish active goals when the service is not even initialised');
    }

    return this.goals.some((g) => null === g.dateCompleted && !g.deleted);
  }

  /* User Goal Methods */
  public initialiseGoals(goals: IGoal[]): void {
    if (undefined === goals || null === goals) {
      throw new Error('*** GoalService: initialiseGoals - Cannot set to an undefined value');
    }

    this.setGoals(goals);
  }

  public addGoal(goal: IGoal): boolean {
    // console.log(`*** AddGoal: Entering. Goal: ${JSON.stringify(goal)}`);
    if (!this.goals) {
      throw new Error('Cannot add a goal when the service is not initialised');
    }

    if (this.goals.find((g) => g.id === goal.id)) {
      console.error(`*** AddGoal: Why are we adding a goal that already exists? GoalId: ${goal.id}. Try UpdateGoal`);
      return false;
    }

    this.goals.push(goal);
    this.goals = this.getGoalsSortedByLevelThenStartDate(this.goals);
    this.notifyGoalsUpdated();
    return true;
  }

  public updateGoal(goal: IGoal): boolean {
    if (undefined === this.goals) {
      throw new Error('Cannot update a goal when the service is not initialised');
    }

    const goalIndex = this.goals.findIndex((g) => goal.id === g.id);
    if (-1 === goalIndex) {
      console.error(`*** UpdateGoal: Why are we updating a goal that does not exist`);
      return false;
    }

    this.goals[goalIndex] = goal;
    this.notifyGoalsUpdated();
    return true;
  }

  public getGoals(): IGoal[] {
    this.goals = this.getGoalsSortedByLevelThenStartDate(this.goals);
    return this.goals;
  }

  public getGoal(goalId: number): IGoal | undefined {
    if (undefined === this.goals) {
      throw new Error('Cannot get a goal when the service is not initialised');
    }

    return this.goals.find((g) => g.id === goalId);
  }

  public isGoalCompleted(goal: IGoal): boolean {
    return goal.status === EUserRowState.COMPLETED && goal.dateCompleted !== undefined;
  }

  public deleteGoal(goal: IGoal): boolean {
    if (undefined === this.goals) {
      throw new Error('Cannot delete a goal when the service is not initialised');
    }

    if (!goal || !goal.deleted) {
      // the goal that we are removing from the cache should be "marked" as deleted
      console.log(`*** DeleteGoal: Why are we deleting an already deleted goal? GoalId: ${goal.id}.`);
      return false;
    }

    const index = this.goals.findIndex((g) => g.id === goal.id);
    if (-1 === index) {
      console.log(`*** DeleteGoal: Tried to delete a goal that doesn't exist? GoalId: ${goal.id}.`);
      return false;
    }

    console.log(`*** DeleteGoal: Deleting goal from the local cache. GoalId: ${goal.id}.`);
    const deletedGoal = this.goals.splice(index, 1);
    this.notifyGoalsUpdated();
    return deletedGoal.length === 1;
  }

  public getActiveGoals(): IGoal[] {
    return this.goals !== undefined ? this.goals.filter((goal) => goal.dateCompleted === undefined || goal.dateCompleted === null) : ([] as IGoal[]);
  }

  public setGoals(goals: IGoal[]): void {
    if (goals === undefined) {
      throw new Error('*** GoalService: setGoals - Cannot set to an undefined value');
    }

    goals.forEach((g) => {
      if (!g.selectedTrackName) {
        g.selectedTrackName = 'Custom';
      }
    });

    this.goals = goals;
    this.notifyGoalsUpdated();
  }

  // PLEASE: Don't forget to unsubscribe or take(1)
  public getGoalsUpdatedEvent(): Subject<boolean> {
    return this.goalsUpdated;
  }

  public isGoalDuplicate(inputGoal: IGoal): boolean {
    if (undefined === this.goals) {
      throw new Error('Cannot get establish duplicate goals when the service is not even initialised');
    }

    for (const goal of this.goals) {
      if (goal.industry === inputGoal.industry && goal.profession === inputGoal.profession && goal.specialisation === inputGoal.specialisation && goal.level === inputGoal.level) {
        return true;
      }
    }
    return false;
  }

  public getGoalsSortedByLevelThenStartDate(goals: IGoal[] | undefined): IGoal[] {
    if (!goals) {
      console.log('*** getGoalsSortedByLevelThenStartDate: can\'t call with invalid goals');
      return [];
    }

    const sortedGoals = goals.sort((a: IGoal, b: IGoal) => {
      const aLevel = a.level ? parseInt(a.level.substring(6), 10) : '';
      const bLevel = b.level ? parseInt(b.level.substring(6), 10) : '';

      if (aLevel > bLevel) {
        return -1;
      }
      if (aLevel < bLevel) {
        return 1;
      }
      if (!a.dateStarted && !b.dateStarted) {
        return 0;
      }
      if (!a.dateStarted) {
        return 1;
      }
      if (!b.dateStarted) {
        return -1;
      }
      if (a.dateStarted > b.dateStarted) {
        return -1;
      }
      if (a.dateStarted < b.dateStarted) {
        return 1;
      }
      return 1;
    });

    return sortedGoals;
  }

  private notifyGoalsUpdated() {
    if (!this.goals) {
      throw new Error('goals should never be undefined when there are new goals to notify people about');
    }
    this.goalsUpdated.next(this.goals.length > 0);
  }
}
