<span nz-tooltip nzTooltipTitle="{{firstName}} overall gained more Exposure, Experience and Credibility than {{ topSkill.rankPercentile }}% of users">
  <nz-card [nzHoverable]=true [nzBordered]="true" class="skill-card" [nzCover]="coverTemplate">
    <div style="text-align:center;">
      <p>
        <span>
          <i *ngIf="topSkill.rankPercentile >75; else silverCrown" nz-icon nzType="crown" nzTheme="twotone" [nzTwotoneColor]="'#ffcc00'" nz-tooltip nzTooltipTitle="Gold Level Skill"></i>
          <ng-template #silverCrown>
            <i *ngIf="topSkill.rankPercentile >50; else bronzeCrown" nz-icon nzType="crown" nzTheme="twotone" [nzTwotoneColor]="'#C0C0C0'" nz-tooltip nzTooltipTitle="Silver Level Skill"></i>
          </ng-template>
          <ng-template #bronzeCrown>
            <i *ngIf="topSkill.rankPercentile >25" nz-icon nzType="crown" nzTheme="twotone" [nzTwotoneColor]="'#cd7f32'" nz-tooltip nzTooltipTitle="Bronze Level Skill"></i>
          </ng-template>
        </span>
        {{ topSkill.goalLevel }}
      </p>
      <nz-progress [nzPercent]="topSkill.rankPercentile" nzType="circle" [nzFormat]="labelText" [nzStrokeColor]="{ '0%' : '#1C7CAB', '100%' : '#29BA9C' }"></nz-progress>
    </div>
    <div class="learning-type" (click)="navigateToProfileActivities(topSkill)">
      <div nz-row class="details">
        <div nz-col [nzSpan]="12">Exposure</div>
        <div name="exposure" nz-col [nzSpan]="12" class="alignRight">{{ topSkill.exposure }}</div>
      </div>
      <div nz-row class="details1">
        <div nz-col [nzSpan]="12">Experience</div>
        <div name="experience" nz-col [nzSpan]="12" class="alignRight">{{ topSkill.experience }}</div>
      </div>
      <div nz-row class="details1">
        <div nz-col [nzSpan]="12">Credibility</div>
        <div name="credibility" nz-col [nzSpan]="12" class="alignRight">{{ topSkill.credibility }}</div>
      </div>
    </div>
  </nz-card>
</span>

<ng-template #coverTemplate>
  <div class="container" (click)="navigateToRecommendations(topSkill)">
    <span *ngIf='topSkill.imageFileName'>
      <img id="skill-image" src="{{ topSkill.imageFileName }}">
    </span>
    <span *ngIf='!topSkill.imageFileName && topSkill.iconFileName'>
      <i id="skill-icon" class="{{ topSkill.iconFileName }}"></i>
    </span>
    <span *ngIf='!topSkill.iconFileName && !topSkill.imageFileName'>
      <i id="skill-icon" class="{{ this.icon }}"></i>
    </span>
    <span name="name" class="skill-name">
      {{ topSkill.name }}
    </span>
    <sl-core-skill-icon *ngIf="topSkill.coreSkill" class="core-skill-icon" [tooltipVisible]="true" [tooltip]="topSkill.name + ' has been marked as a core skill for your career'"></sl-core-skill-icon>
  </div>
</ng-template>