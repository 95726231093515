import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sl-profile-overview',
  templateUrl: './profile-overview.component.html',
  styleUrls: ['./profile-overview.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileOverviewComponent {
  constructor() { }
}
