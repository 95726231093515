import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRecommendation } from '../models/recommendation.model';
import { RecommendationServiceData } from './recommendation.service.data';
import { IPlaylist } from '../models/playlist.model';
import { map } from 'rxjs/operators';
import { ISuggestedAction } from '../models/suggested-action.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class RecommendationService {
  endpoint = '/api/Recommendations';

  constructor(private http: HttpClient, private recommendationServiceData: RecommendationServiceData) { }

  public initialiseRecommendedActivities(goalId: number): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const params = new HttpParams().set('goalId', goalId.toString());
    const url = `${this.endpoint}/GetRecommendedActivities`;

    return this.http.get<IRecommendation[]>(url, { headers, params }).pipe(
      map((data: IRecommendation[]) => {
        this.recommendationServiceData.initialiseRecommendedActivities(goalId, data);
        return true;
      })
    );
  }

  public initialiseRecommendedPlaylists(goalId: number): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const params = new HttpParams().set('goalId', goalId.toString());
    const url = `${this.endpoint}/GetRecommendedPlaylists`;

    return this.http.get<IPlaylist[]>(url, { headers, params }).pipe(
      map((data: IPlaylist[]) => {
        this.recommendationServiceData.initialiseRecommendedPlaylists(goalId, data);
        return true;
      })
    );
  }

  public initialiseRecommendedActions(goalId: number): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const params = new HttpParams().set('goalId', goalId.toString());
    const url = `${this.endpoint}/GetRecommendedActions`;

    return this.http.get<ISuggestedAction[]>(url, { headers, params }).pipe(
      map((data: ISuggestedAction[]) => {
        this.recommendationServiceData.initialiseRecommendedActions(goalId, data);
        return true;
      })
    );
  }

  public seedSuggestedActions(): Observable<string> {
    const url = '/api/SuggestedAction' + '/SeedSuggestedActions';
    return this.http.post<string>(url, '', httpOptions);
  }

  // TODO: Adam: -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*s
  // TODO: Adam: -*   Move these playlist calls to the playlist service, and add the dataservice in there.
  // TODO: Adam: -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
  public getRecommendedActivitiesUpdatedEvent(goalId: number): Observable<[number, number]> {
    return this.recommendationServiceData.getRecommendedActivitiesUpdatedEvent(goalId);
  }

  public getRecommendedPlaylistUpdatedEvent(goalId: number): Observable<[number, number]> {
    return this.recommendationServiceData.getRecommendedPlaylistUpdatedEvent(goalId);
  }

  public getRecommendedActionUpdatedEvent(goalId: number): Observable<[number, number]> {
    return this.recommendationServiceData.getRecommendedActionUpdatedEvent(goalId);
  }

  public isInitialisedActivities(goalId: number): boolean {
    return this.recommendationServiceData.isInitialisedRecommendedActivities(goalId);
  }

  public isInitialisedPlaylists(goalId: number): boolean {
    return this.recommendationServiceData.isInitialisedRecommendedPlaylists(goalId);
  }

  public isInitialisedActions(goalId: number): boolean {
    return this.recommendationServiceData.isInitialisedRecommendedActions(goalId);
  }

  public getRecommendedActivities(goalId: number): IRecommendation[] | undefined {
    if (!this.recommendationServiceData.isInitialisedRecommendedActivities(goalId)) {
      console.log(`request to get activities for goal ${goalId} when we haven't initialised yet`);
      return [];
    }

    return this.recommendationServiceData.getRecommendedActivities(goalId);
  }

  public getRecommendedPlaylists(goalId: number): IPlaylist[] | undefined {
    if (!this.recommendationServiceData.isInitialisedRecommendedPlaylists(goalId)) {
      console.log(`request to get activities for goal ${goalId} when we haven't initialised yet`);
      return [];
    }

    return this.recommendationServiceData.getRecommendedPlaylists(goalId);
  }

  public getRecommendedActions(goalId: number): ISuggestedAction[] | undefined {
    if (!this.recommendationServiceData.isInitialisedRecommendedActions(goalId)) {
      console.log(`request to get activities for goal ${goalId} when we haven't initialised yet`);
      return [];
    }

    return this.recommendationServiceData.getRecommendedActions(goalId);
  }

  public getRecommendation(activityId: number): Observable<IRecommendation> {
    const url = this.endpoint + `/GetRecommendation/${activityId}`;
    return this.http.get<IRecommendation>(url, httpOptions);
  }

  public seedRecommendations(profession: string): Observable<string[]> {
    const url = this.endpoint + `/SeedRecommendations/${profession}`;
    return this.http.post<string[]>(url, '');
  }

  public seedRecommendationsFromFile(formData: FormData): Observable<string[]> {
    const url = this.endpoint + '/SeedRecommendationsFromFile';
    return this.http.post<string[]>(url, formData);
  }

  public setActivityAdded(goalId: number, activityId: number, added: boolean) {
    this.recommendationServiceData.setAddedActivity(goalId, activityId, added);
  }

  public setPlaylistAdded(goalId: number, playlistId: number, added: boolean) {
    this.recommendationServiceData.setAddedPlaylist(goalId, playlistId, added);
  }

  public setActionAdded(goalId: number, playlistId: number, added: boolean) {
    this.recommendationServiceData.setAddedAction(goalId, playlistId, added);
  }

  public flagRecommendedActivity(activityId: number): Observable<IRecommendation> {
    const id = `id=${activityId}`;
    const url = this.endpoint + `/ReportRecommendation?${id}`;
    return this.http.put<IRecommendation>(url, activityId, httpOptions).pipe(
      map((resultActivity: IRecommendation) => {
        this.recommendationServiceData.flagRecommendedActivity(resultActivity);
        return resultActivity;
      })
    );
  }
}
