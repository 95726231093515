import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPlaylist } from 'src/app/models/playlist.model';
import { ISharedPlaylist } from 'src/app/models/shared-playlist.model';
import { IGoal } from 'src/app/models/user-goal.model';
import { IGuildTopActivityTypeCompleted } from 'src/app/modules-guilds/models/guild-top-activity-type.model';
import { IGuild, encodeUrlComponent } from 'src/app/modules-guilds/models/guild.model';
import { EGoalSourceType } from 'src/app/types/goal-source-type.type';

export interface GoalSourceType {
  type: EGoalSourceType;
}
@Component({
  selector: 'sl-goal-selector',
  templateUrl: './goal-selector.component.html',
  styleUrls: ['./goal-selector.component.less']
})
export class GoalSelectorComponent {
  @Output() closeGoalSelector = new EventEmitter<boolean>();
  @Output() selectGoalFromGoalSelector = new EventEmitter();
  @Output() selectGoalFromGoals = new EventEmitter<IGoal>();
  @Input() guild!: IGuild;
  @Input() goalSelectorVisible = false;
  @Input() goals: IGoal[] = [];
  @Input() selectedGoal!: IGoal | null;
  @Input() goalSourceType: EGoalSourceType = EGoalSourceType.Playlist;

  // I know the practice of pre-initialising these field variables aren't good but I can't get the tests to pass otherwise.
  @Input() selectedSharedPlaylist: ISharedPlaylist = { playlist: {} as IPlaylist } as ISharedPlaylist;
  @Input() selectedActivity: IGuildTopActivityTypeCompleted = {} as IGuildTopActivityTypeCompleted;
  goalSelectorComponentType = EGoalSourceType;

  public closeGoalSelectionModal() {
    this.selectedGoal = null;
    this.closeGoalSelector.emit(false);
  }

  public hasGoalBeenSelected(): boolean {
    return !(!this.selectedGoal);
  }

  public selectGoalFromGoalSelectionModal() {
    this.selectGoalFromGoalSelector.emit();
  }

  public encodeUrlComponent(guildName: string) {
    return encodeUrlComponent(guildName);
  }

  public selectGoal(goal: IGoal) {
    this.selectedGoal = goal;
    this.selectGoalFromGoals.emit(goal);
  }

  getSharedPlaylistIndustry() {
    return this.selectedSharedPlaylist.playlist.industry;
  }

  getSharedPlaylistProfession() {
    return this.selectedSharedPlaylist.playlist.profession;
  }

  getSharedPlaylistSpecialisation() {
    return this.selectedSharedPlaylist.playlist.specialisation;
  }

  getSharedPlaylistLevel() {
    return this.selectedSharedPlaylist.playlist.levelFrom;
  }

  getGuildIndustry() {
    return this.guild.industry;
  }

  getGuildProfession() {
    return this.guild.profession;
  }

  getGuildSpecialisation() {
    return this.guild.specialisation;
  }

  getGuildLevel() {
    return this.guild.levelMin;
  }
}
