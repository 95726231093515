<div *ngIf="guildCurrent" id="guild-creation-page">
  <div class="guild-creation-form">
    <nz-steps id="guild-creation-steps" class="step" [nzCurrent]="stepIndex" nzDirection="vertical" (nzIndexChange)="onStepIndexChange($event)">
      <nz-step id="guild-name-step" nzTitle="Enter the name of the guild" [nzStatus]="isGuildNameValid && guildCurrent?.name.trim() ? 'finish' : 'process'" [nzSubtitle]="requiredIcon" [nzDescription]="guildNameInput">
        <ng-template #guildNameInput>
          <div class="guild-name-container">
            <div id="guild-name">
              <nz-input-group nzPrefixIcon="bars">
                <input #guildnamefocus tabindex="0" id="guild-name-input" nz-input placeholder="Guild Name - <Core Skill> or <Competence>" [(ngModel)]="guildCurrent.name" (ngModelChange)="onGuildNameChange()" (blur)="nameBlur()" (focus)="nameFocus()" />
              </nz-input-group>
              <div class="guild-name-validator-container">
                <div class="error-container">
                  <p class="error-no-name" *ngIf="nameShowError">A valid guild name is required</p>
                  <p class="guild-name-error" *ngIf="guildNameError">{{ guildNameError }}</p>
                </div>
                <p class="character-counter">Characters remaining {{ remainingCharacters }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </nz-step>
      <nz-step id="guild-industry-step" class="step" nzTitle="Select an Industry" [nzStatus]="isStepValid.get('nameEntered') ? (isStepValid.get('industrySelected') ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('nameEntered') ? industryOptions : null" [nzDisabled]="!isStepValid.get('nameEntered')">
        <ng-template #industryOptions>
          <sl-industry-selector id="select-industry" (selectIndustryEvent)="initSelectedIndustry($event)" [previouslySelectedIndustry]="incomingGuild.industry" [currentMode]="mode"></sl-industry-selector>
        </ng-template>
      </nz-step>
      <nz-step id="guild-profession-step" class="step" nzTitle="Select a Profession" [nzStatus]="isStepValid.get('industrySelected') ? (isStepValid.get('professionSelected') ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('industrySelected') ? professionOptions : null" [nzDisabled]="!isStepValid.get('industrySelected')">
        <ng-template #professionOptions>
          <sl-profession-selector id="select-profession" [selectedIndustry]="guildCurrent.industry" [previouslySelectedIndustry]="incomingGuild.industry" [previouslySelectedProfession]="incomingGuild.profession" [currentMode]="mode"
            (selectProfessionEvent)="initSelectedProfession($event)"></sl-profession-selector>
        </ng-template>
      </nz-step>
      <nz-step id="guild-specialisation-step" class="step" nzTitle="Select a Specialisation" [nzStatus]="isStepValid.get('professionSelected') ? (isStepValid.get('specialisationSelected') !== undefined ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('professionSelected') ? specialisationOptions : null" [nzDisabled]="!isStepValid.get('professionSelected') !== undefined">
        <ng-template #specialisationOptions>
          <sl-specialisation-selector id="select-specialisation" [selectedIndustry]="guildCurrent.industry" [selectedProfession]="guildCurrent.profession" [previouslySelectedSpecialisation]="incomingGuild.specialisation" [previouslySelectedIndustry]="incomingGuild.industry"
            [previouslySelectedProfession]="incomingGuild.profession" [currentMode]="mode" (selectSpecialisationEvent)="initSelectedSpecialisation($event)"></sl-specialisation-selector>
        </ng-template>
      </nz-step>
      <nz-step id="guild-level-step" class="step" nzTitle="Select Level Range(s)" [nzStatus]="isStepValid.get('specialisationSelected') ? (isStepValid.get('levelFromSelected') && isStepValid.get('levelToSelected') ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('specialisationSelected') ? levelOptions : null" [nzDisabled]="!isStepValid.get('specialisationSelected')">
        <ng-template #levelOptions>
          <sl-level-selector id="select-level" (selectLevelFromEvent)="initSelectedLevelFrom($event)" (selectLevelToEvent)="initSelectedLevelTo($event)" [currentMode]="mode" [previouslySelectedLevelFrom]="incomingGuild.levelMin"
            [previouslySelectedLevelTo]="incomingGuild.levelMax"></sl-level-selector>
        </ng-template>
      </nz-step>
      <nz-step id="guild-track-step" class="step" nzTitle="Select a Track" [nzStatus]="isStepValid.get('levelFromSelected') && isStepValid.get('levelToSelected') ? (isStepValid.get('trackSelected') ? 'finish' : 'process') : 'wait'" [nzSubtitle]="requiredIcon"
        [nzDescription]="isStepValid.get('levelFromSelected') && isStepValid.get('levelToSelected') ? trackOptions : null" [nzDisabled]="!isStepValid.get('levelFromSelected') && !isStepValid.get('levelToSelected')">
        <ng-template #trackOptions>
          <sl-track-selector id="select-track" [selectedIndustry]="guildCurrent.industry" [selectedProfession]="guildCurrent.profession" [selectedSpecialisation]="guildCurrent.specialisation" [selectedLevelFrom]="guildCurrent.levelMin" [selectedLevelTo]="guildCurrent.levelMax"
            [previouslySelectedIndustry]="incomingGuild.industry" [previouslySelectedProfession]="incomingGuild.profession" [previouslySelectedSpecialisation]="incomingGuild.specialisation" [previouslySelectedLevelFrom]="incomingGuild.levelMin"
            [previouslySelectedLevelTo]="incomingGuild.levelMax" [previouslySelectedTrackName]="incomingGuild.relatedTrackName" [currentMode]="mode" (selectTrackEvent)="selectedTrackChanged($event)"></sl-track-selector>
        </ng-template>
      </nz-step>
      <nz-step id="guild-description-step" class="step" nzTitle="Enter a description for the guild" [nzStatus]="isStepValid.get('trackSelected') ? 'finish' : 'wait'" [nzDescription]="isStepValid.get('trackSelected') ? guildDescription : null"
        [nzDisabled]="!isStepValid.get('trackSelected')">
        <ng-template #guildDescription>
          <div class="guild-description-container">
            <div id="guild-description">
              <nz-input-group>
                <textarea id="guild-description-input" nz-input placeholder="Enter a helpful description to explain what the guild is for" [(ngModel)]="guildCurrent.description"></textarea>
              </nz-input-group>
            </div>
          </div>
        </ng-template>
      </nz-step>
      <nz-step id="guild-upload-step" class="step" nzTitle="Upload" [nzStatus]="isStepValid.get('trackSelected') ? 'finish' : 'wait'" [nzDescription]="isStepValid.get('trackSelected') ? guildImageAndGlyph : null" [nzDisabled]="!isStepValid.get('trackSelected')">
        <ng-template #guildImageAndGlyph>
          <div class="guild-upload-details">
            <div>
              <div class="guild-image-and-glyph">
                <span class="upload-guide-icon-condensed" nz-icon nzType="info-circle" nzTheme="outline" (click)="openCondensedUploadGuide()"></span>
                <nz-radio-group class="guild-upload-options" [(ngModel)]="uploadOptions" nzButtonStyle="solid">
                  <label class="upload-image-radio-btn" nz-radio-button [nzValue]="uploadType.image">Image</label>
                  <label class="upload-glyph-radio-btn" nz-radio-button [nzValue]="uploadType.glyph">Glyph</label>
                </nz-radio-group>
              </div>
              <sl-image-uploader *ngIf="uploadOptions === uploadType.image" #imageUploader class="image-uploader" (uploadImageUrlEvent)="initUploadedImageUrl($event)" [imageSrc]="guildCurrent.imageUrl" [currentMode]="mode"></sl-image-uploader>
              <sl-glyph-selector *ngIf="uploadOptions === uploadType.glyph" #glyphUploader class="glyph-uploader" (uploadGlyphEvent)="initUploadedGlyphClassname($event)" [glyphIcon]="guildCurrent.imageIcon" [currentMode]="mode">
              </sl-glyph-selector>
            </div>
            <nz-alert class="upload-guide-alert" nzType="info" nzMessage="Image & Icon Guidelines" [nzDescription]="uploadGuide" nzShowIcon></nz-alert>
            <ng-template #uploadGuide class="upload-guide">
              <h6>The image or icon will help others identify the core purpose or identity of your guild.</h6>
              <h6>Some useful tips to consider:</h6>
              <ul>
                <li>Avoid images containing just text</li>
                <li>Ensure it has a solid background colour (useful in dark mode)</li>
                <li>Select and image that is square and a <u>minimum</u> size of 132px</li>
                <li>Make sure that your image has some space around it</li>
              </ul>
            </ng-template>
            <nz-modal [nzVisible]="showCondensedUploadGuide" [nzFooter]="modalFooter" [nzTitle]="'Upload Guide'" [nzContent]="modalContent" nzWidth="60%" (nzOnCancel)="closeCondensedUploadGuide()">
              <ng-template #modalContent>
                <nz-alert nzType="info" nzMessage="Guidelines for uploading an image or icon" [nzDescription]="uploadGuide" nzShowIcon></nz-alert>
              </ng-template>
              <ng-template #modalFooter>
                <button nz-button nzType="primary" (click)="closeCondensedUploadGuide()">Ok</button>
              </ng-template>
            </nz-modal>
          </div>
        </ng-template>
      </nz-step>
      <nz-step *ngIf="validateAllStepInputs()" nzTitle="All good to go!" [nzStatus]="validateAllStepInputs() ? 'finish' : null"></nz-step>
      <ng-template #requiredIcon><sup><i class="fa-solid fa-asterisk required-icon"></i></sup></ng-template>
    </nz-steps>
  </div>
  <div class="grid-submit">
    <button id="cancel-new-guild" nz-button nzType="secondary" (click)="cancel()">Cancel</button>
    <div *ngIf="mode === 1" class="creator-buttons-container">
      <button id="submit-guild" nz-button nzType="primary" [disabled]="!validateAllStepInputs()" (click)="submitGuild()">Submit</button>
    </div>
    <div *ngIf="mode === 2" class="editor-buttons-container">
      <button id="submit-guild" nz-button nzType="primary" [disabled]="!validateAllStepInputs()" (click)="submitGuild()">Edit</button>
      <!-- TODO: Sam - can you please make it so that the component does the submit inside the guildSubmittedFunction AND it therefore has a dependency on the isGuildCreating value
      <button id="submit-guild" nz-button nzType="primary" [disabled]="!validateAllStepInputs() || isCreatingGuild" (click)="submitGuild()">Edit</button>
       -->
    </div>
  </div>
</div>