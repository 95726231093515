<nz-card nzTitle="User Playlists">
  <nz-table id="userPlaylists" #UserPlaylistsTable [nzData]="userPlaylists" nzSize="middle" [nzShowPagination]="true">
    <thead>
      <tr>
        <th>Id</th>
        <th>Playlist Id</th>
        <th>Playlist Title</th>
        <th>Date Created</th>
        <th>Date Due</th>
        <th>Deleted</th>
        <th>User Id</th>
        <th>Goal Id</th>
        <th>User Activity Ids</th>
        <th>Playlist Version</th>
        <th>Latest Playlist Version</th>
        <th>All Completed</th>
        <th>Amount Completed</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let userPlaylist of UserPlaylistsTable.data">
        <td>{{ userPlaylist.id }}</td>
        <td>{{ userPlaylist.playlistId }}</td>
        <td class="playlist-title">{{ userPlaylist.playlistTitle }}</td>
        <td>{{ userPlaylist.dateCreated }}</td>
        <td>{{ userPlaylist.dateDue }}</td>
        <td>{{ userPlaylist.deleted }}</td>
        <td>{{ userPlaylist.userId }}</td>
        <td>{{ userPlaylist.goalId }}</td>
        <td class="user-playlists-activity-amount">{{ userPlaylist.userActivityIds.length }}</td>
        <td>{{ userPlaylist.playlistVersion }}</td>
        <td>{{ userPlaylist.latestPlaylistVersion }}</td>
        <td>{{ userPlaylist.allCompleted }}</td>
        <td>{{ userPlaylist.numCompleted }}</td>
        <td>{{ userPlaylist.numTotal }}</td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>