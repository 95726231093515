import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';

// services
import { PlaylistService } from 'src/app/services/playlist.service';

// models
import { IPlaylist } from 'src/app/models/playlist.model';

@Component({
  selector: 'sl-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.less'],
})
export class PlaylistsComponent implements OnInit {
  public playlistsFiltered: IPlaylist[] = [];
  public playlists: IPlaylist[] = [];
  public isExporting = false;
  public showRetired = false;

  constructor(private playlistService: PlaylistService, private notificationMessage: NzMessageService) {}

  ngOnInit(): void {
    this.initPlaylists();
  }

  public exportPlaylist(playlist: IPlaylist): void {
    playlist.isExporting = true;
    this.playlistService.exportPlaylist(playlist.id).subscribe(
      (ret: string) => {
        playlist.isExporting = false;
        if (ret) {
          this.notificationMessage.success('Boom, we have exported it for you');
        } else {
          this.notificationMessage.warning('Oh dear, something went wrong and it failed, but there is no reason why.');
        }
      },
      () => {
        playlist.isExporting = false;
        this.notificationMessage.error('Bugger!  Something went drastically wrong. Try again?');
      }
    );
  }

  public retirePlaylist(playlist: IPlaylist): void {
    if (playlist.retired) {
      this.notificationMessage.error('You can\'t retire a playlist that is already retired');
      return;
    }

    playlist.isRetiring = true;

    this.playlistService.retirePlaylist(playlist.id).subscribe({
      next: (retirePlaylistId: number) => {
        playlist.isRetiring = false;

        if (retirePlaylistId > 0) {
          playlist.retired = true;
          this.filterPlaylists();
          this.notificationMessage.success(`Boom, playlist "${playlist.name}" is retired. This won't appear in recommendations now.`);
        } else {
          this.notificationMessage.warning('Soemthing went wrong. It\'s likely this playlist doesn\'t exist, so did wasn\'t retired. We\'re still good right?.');
        }
      },
      error: (err: HttpErrorResponse) => {
        playlist.isRetiring = false;
        if (err.status === 409) {
          console.error(`There was an error with trying to retire playlist ${playlist.id}`);
        }
        this.notificationMessage.error('Bugger!  Something went drastically wrong when retiring the playlist. Try again?');
      },
    });
  }

  public unRetirePlaylist(playlist: IPlaylist): void {
    if (!playlist.retired) {
      this.notificationMessage.error('You can\'t unretire a playlist that is not retired');
      return;
    }

    playlist.isRetiring = true;

    this.playlistService.unRetirePlaylist(playlist.id).subscribe({
      next: (retirePlaylistId: number) => {
        playlist.isRetiring = false;

        if (retirePlaylistId > 0) {
          playlist.retired = false;
          this.filterPlaylists();
          this.notificationMessage.success(`Boom, playlist "${playlist.name}" is no longer retired. This will now appear in recommendations again.`);
        } else {
          this.notificationMessage.warning('Soemthing went wrong. It\'s likely this playlist doesn\'t exist, so did wasn\'t retired. We\'re still good right?.');
        }
      },
      error: (err: HttpErrorResponse) => {
        playlist.isRetiring = false;
        if (err.status === 409) {
          console.error(`There was an error with trying to retire playlist ${playlist.id}`);
        }
        this.notificationMessage.error('Bugger!  Something went drastically wrong when retiring the playlist. Try again?');
      },
    });
  }

  public deletePlaylistAdmin(playlist: IPlaylist): void {
    playlist.isRetiring = true;
    this.playlistService.deletePlaylistAdmin(playlist.id).subscribe(
      (deletedPlaylistId: number) => {
        playlist.isRetiring = false;

        if (deletedPlaylistId > 0) {
          this.playlistsFiltered = this.playlistsFiltered.filter((pl) => pl.id !== playlist.id);
          this.notificationMessage.success(`Snap, playlist "${playlist.name}" is deleted. This won't appear in recommendations now.`);
        } else {
          this.notificationMessage.warning('Soemthing went wrong. It\'s likely this playlist doesn\'t exist, so did wasn\'t deleted. We\'re still good right?.');
        }
      },
      (err: HttpErrorResponse) => {
        playlist.isRetiring = false;
        if (err.status === 409) {
          console.error(`There was an error with trying to delete playlist ${playlist.id}`);
        }
        this.notificationMessage.error('Bugger!  Something went drastically wrong when trying to delete the playlist. Try again?');
      }
    );
  }

  public filterPlaylists(): void {
    if (this.showRetired) {
      this.playlistsFiltered = this.playlists;
    } else {
      this.playlistsFiltered = this.playlists.filter((p) => p.retired === false);
    }
  }

  private initPlaylists(): void {
    this.playlistService.getPlaylistsAll().subscribe((ret: IPlaylist[]) => {
      this.playlists = ret;
      this.filterPlaylists();
    });
  }
}
