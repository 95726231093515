/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Observer, Subscription } from 'rxjs';

// libraries
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';

// serivces
import { LocalStorageService } from 'ngx-localstorage';
import { EmploymentExperienceService } from 'src/app/services/employment-experience.service';
import { GoalService } from 'src/app/services/goal.service';

// directives
// import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';

// models
import { ICompany } from 'src/app/models/company.model';
import { IEmployment, IEmploymentLoading } from 'src/app/models/employment.model';
import { ISkill } from 'src/app/models/skill.model';
import { IUrlMetadata } from 'src/app/models/user-activity-metadata.model';
import { IGoal } from 'src/app/models/user-goal.model';

export interface GoalCollapseState {
  id?: number;
  state: boolean;
}
export interface GoalNumberShown {
  id?: number;
  amount: number;
}
@Component({
  selector: 'sl-employment-experience',
  templateUrl: './employment-experience.component.html',
  styleUrls: ['./employment-experience.component.less'],
})
export class EmploymentExperienceComponent implements OnInit, OnDestroy {
  @Input() employmentsPublic?: IEmployment[];
  // @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  employmentExperienceList: IEmploymentLoading[] = [];
  showModal = false;
  selectedGoals: { goal: IGoal; selected: boolean }[] = [];
  editMode = false;
  selectedDisplayGoalSkills: { goal: IGoal; employmentExperience: IEmployment }[] = [];
  formValues!: UntypedFormGroup;
  showGoalsNumber = 2;
  industryOptions!: string[];
  isSkillsLoading = false;
  isInitialised = false;
  onChangeGoal: Subscription | undefined = undefined;
  showAllSkills = false;
  placesApiOptions = {
    types: ['establishment'],
    // componentRestrictions: {country: ['au','us','uk']} // NOTE: restrict the autocomplete search to specific countries
  };
  isLoadingImage = false;
  imageUrl?: string;
  imageContainerSelected = true;

  //Responsible for handling specific goals
  showSelectedGoalNumber = 0;
  numberOfGoalsShown: GoalNumberShown[] = [];
  collapsedGoalState: GoalCollapseState[] = [];

  constructor(private employmentExperienceService: EmploymentExperienceService, private goalService: GoalService, private fb: UntypedFormBuilder, private message: NzMessageService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    if (this.employmentsPublic !== undefined) {
      this.employmentExperienceList = this.employmentsPublic as IEmploymentLoading[];
      this.showGoalsNumber = 100;
      this.employmentsPublic.forEach((goal) => {
        this.collapsedGoalState.push({ id: goal.id, state: true });
        this.numberOfGoalsShown.push({ id: goal.id, amount: 2 });
      });
      this.getEmploymentGoalState();
      this.selectDefaultDisplayGoalSkills();
      this.initFormFields();
    } else {
      this.initFormFields();
      this.getEmploymentExperience();
      this.getUserGoals();
      this.onChangeGoal = this.goalService.getGoalsUpdatedEvent().subscribe(
        () => {
          this.collapsedGoalState.length = 0;
          this.numberOfGoalsShown.length = 0;
          this.selectedDisplayGoalSkills.length = 0;
          this.getEmploymentExperience();
          this.getUserGoals();
        },
        (err: HttpErrorResponse) => console.error(`*** ERROR: goalService getGoalsUpdatedEvent returned with ${err}`)
      );
    }
  }

  ngOnDestroy() {
    if (this.onChangeGoal) {
      this.onChangeGoal.unsubscribe();
    }
  }

  initFormFields() {
    this.formValues = this.fb.group({
      id: 0,
      userId: '',
      jobTitle: ['', [Validators.required]],
      description: '',
      company: this.fb.group({ name: ['', [Validators.required]], url: '', industry: '', imageUrl: '' }),
      companyLocation: this.fb.group({ city: '', state: '', postCode: '', country: '' }),
      dateStart: ['', [Validators.required]],
      dateEnd: '',
      associatedGoals: [],
    });
  }

  expandGoals(index: number): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.collapsedGoalState[index].state === true ? (this.collapsedGoalState[index].state = false) : (this.collapsedGoalState[index].state = true);
    this.saveEmploymentGoalState();
  }

  collapseGoals(index: number): void {
    if (this.collapsedGoalState[index].state === false) {
      this.collapsedGoalState[index].state = true;
      this.numberOfGoalsShown[index].amount = 2;
    } else {
      this.collapsedGoalState[index].state = false;
    }
    this.saveEmploymentGoalState();
  }

  goalToExpand(index: number): number {
    if (this.collapsedGoalState[index].state === false) {
      this.numberOfGoalsShown[index].amount = 100;
      return this.numberOfGoalsShown[index].amount;
    }
    return this.showGoalsNumber;
  }

  getCompanies(inUrl: string) {
    const target: IEmployment = this.formValues.value;
    this.employmentExperienceService.getCompaniesFromUrl(inUrl).subscribe(
      (company: ICompany) => {
        if (company !== null) {
          target.company = { name: '', url: inUrl, industry: '', imageUrl: '' };
          if (company.url !== null || company.url !== '' || company.url !== undefined) {
            this.formValues.controls.company.patchValue(company);
          }
        }
        if (target.company.imageUrl === '') {
          this.employmentExperienceService.getCompanyMetadata(inUrl).subscribe(
            (res: IUrlMetadata) => {
              if (res.imageUrl !== null && res.imageUrl !== undefined) {
                this.formValues.controls.company.patchValue(res);
              }
            },
            (err: HttpErrorResponse) => console.error(`*** ERROR: Could not get company metadata from URL. ${err}`)
          );
        }
      },
      (err: HttpErrorResponse) => {
        console.error(`*** ERROR: Could not get a list of companies from database. Returned ${err}`);
      }
    );
  }

  getEmploymentExperience() {
    this.employmentExperienceService.getEmploymentExperience().subscribe(
      (emp: IEmploymentLoading[]) => {
        // console.log(`*** EmploymentExperienceService responded with ${JSON.stringify(res)}`);
        this.employmentExperienceList = this.sortEmploymentExperience(emp);
        this.selectDefaultDisplayGoalSkills();
        emp.forEach((employment) => {
          employment.associatedGoals.forEach((goal) => {
            this.getEmploymentSkills(goal, employment);
          });
        });
        emp.forEach((goal) => {
          this.collapsedGoalState.push({ id: goal.id, state: true });
          this.numberOfGoalsShown.push({ id: goal.id, amount: 2 });
        });
        this.getEmploymentGoalState();
        this.refreshSkills();
      },
      () => {
        // err: HttpErrorResponse
        // console.error(`*** ERROR: EmploymentExperienceService returned with ${err}`);
        this.message.error('That\'s strange, we could not retrieve the Employment experience for you. Try again in a moment or two.');
        this.employmentExperienceList = [];
        this.selectDefaultDisplayGoalSkills();
      }
    );
  }

  refreshSkills() {
    this.employmentExperienceList.forEach((employment) => {
      employment.associatedGoals.forEach((goal) => {
        this.getEmploymentSkills(goal, employment);
      });
    });
  }

  getEmploymentSkills(goal: IGoal, employment: IEmploymentLoading) {
    if (goal !== null || employment !== null) {
      this.isSkillsLoading = true;

      this.employmentExperienceService.getEmploymentSkills(goal.id as number, employment.id as number, this.showAllSkills as boolean).subscribe(
        (skills: ISkill[]) => {
          const userGoal = employment.associatedGoals.findIndex((gl) => gl.id === goal.id)
          employment.associatedGoals[userGoal].skills = skills;
          if (!employment.associatedGoals[userGoal].coreSkills) {
            employment.associatedGoals[userGoal].coreSkills = [];
          }
          skills.forEach(skill => {
            if (this.isEmploymentSkillACoreSkill(employment.associatedGoals[userGoal].id!, skill)) {
              if (!employment.associatedGoals[userGoal].coreSkills?.some(s => s.id === skill.id)) {
                employment.associatedGoals[userGoal].coreSkills?.push(skill);
              }
            }
          })
          this.employmentExperienceList[this.employmentExperienceList.findIndex((e) => e === employment)] = employment;
          this.isSkillsLoading = false;
          this.isInitialised = true;
        },
        () => {
          // err: HttpErrorResponse
          // console.error(`*** ERROR: EmploymentExperienceService returned with ${JSON.stringify(err)}` );
          this.message.error('That\'s strange, we could not retrieve skills for this employment experience.');
          this.isSkillsLoading = false;
        }
      );
    }
  }

  saveEmploymentGoalState() {
    this.localStorageService.set('collapsedGoalState', this.collapsedGoalState);
    this.localStorageService.set('numberOfGoalsShown', this.numberOfGoalsShown);
  }

  getEmploymentGoalState() {
    const initialCollapsedGoalState = this.localStorageService.get<GoalCollapseState[]>('collapsedGoalState');
    const initialNumberOfGoalsShown = this.localStorageService.get<GoalNumberShown[]>('numberOfGoalsShown');

    if (initialCollapsedGoalState && initialNumberOfGoalsShown) {
      if (this.collapsedGoalState.length === initialCollapsedGoalState.length && this.numberOfGoalsShown.length === initialNumberOfGoalsShown.length) {
        this.collapsedGoalState = initialCollapsedGoalState;
        this.numberOfGoalsShown = initialNumberOfGoalsShown;
      } else {
        for (const goalState in initialCollapsedGoalState) {
          this.collapsedGoalState = this.collapsedGoalState.map((item) => (item.id === initialCollapsedGoalState[goalState].id ? Object.assign({}, item, { id: initialCollapsedGoalState[goalState].id, state: true }) : item));
        }
        for (const goalsShown in initialNumberOfGoalsShown) {
          this.numberOfGoalsShown = this.numberOfGoalsShown.map((item) => (item.id === initialNumberOfGoalsShown[goalsShown].id ? Object.assign({}, item, { id: initialNumberOfGoalsShown[goalsShown].id, amount: 2 }) : item));
        }
      }
    }
  }

  sortEmploymentExperience(experiences: IEmploymentLoading[]): IEmploymentLoading[] {
    const sorted = experiences.sort((a: IEmploymentLoading, b: IEmploymentLoading) => ((!a.dateEnd ? 0 : a.dateEnd) > (!b.dateEnd ? 0 : b.dateEnd) ? -1 : 1));
    return sorted;
  }

  getImageUrlOrDefault(imageUrl: string): string {
    if (!imageUrl) {
      return 'assets/img/default_employment_image.png';
    }
    return imageUrl;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      let isLt2M;
      if (file.size) {
        isLt2M = file.size / 1024 / 1024 < 0.2;
      }
      if (!isLt2M) {
        this.message.error('Image must be smaller than 200KB!');
        observer.complete();
        return;
      }
      observer.next(isLt2M);
      observer.complete();
    });

  uploadCompanyImage = (item: NzUploadXHRArgs) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileToUpload = item.file as any;
    const reader = new FileReader();
    reader.readAsDataURL(fileToUpload);
    reader.onload = () => {
      this.formValues.patchValue({ company: { imageUrl: reader.result?.toString() } });
      this.imageUrl = reader.result?.toString();
    };
  };

  openModal(entryToEdit: IEmployment) {
    if (!this.industryOptions) {
      this.employmentExperienceService.getIndustryOptions().subscribe({
        next: (ret: string[]) => (this.industryOptions = ret),
        error: () => {
          this.message.error('We couldn\'t find any industries for you to choose from.');
          console.log('Error retrieving industry options when adding employment.');
        },
      });
    }

    this.formValues.reset();

    if (entryToEdit) {
      this.startEditExperience(entryToEdit);
    } else {
      this.startAddNewExperience();
    }
  }

  startAddNewExperience() {
    this.editMode = false;
    this.showModal = true;
    this.resetSelectedGoals();
  }

  startEditExperience(experienceToEdit: IEmployment) {
    this.editMode = true;
    this.formValues.patchValue(experienceToEdit);
    this.showModal = true;
    experienceToEdit.associatedGoals.forEach((ag) => {
      const goalFound = this.selectedGoals.find((sg) => sg.goal.id === ag.id);
      if (goalFound) {
        goalFound.selected = true;
      }
    });
  }

  closeEmploymentExperienceModal() {
    this.showModal = false;
  }

  submitForm() {
    if (this.editMode) {
      const temp: IEmployment = this.formValues.value;
      temp.associatedGoals = this.updatedAssociatedGoals();
      this.updateEmploymentExperience(temp);
    } else {
      const temp: IEmployment = {
        jobTitle: this.formValues.controls.jobTitle.value,
        company: this.formValues.controls.company.value,
        companyLocation: this.formValues.controls.companyLocation.value,
        dateStart: this.formValues.controls.dateStart.value,
        dateEnd: this.formValues.controls.dateEnd.value,
        associatedGoals: this.updatedAssociatedGoals(),
      };
      this.addEmploymentExperience(temp);
    }
  }

  getUserGoals(): IGoal[] {
    if (this.goalService.isInitialised()) {
      const usersGoals = this.goalService.getGoals();
      this.selectedGoals = [];
      for (const g of usersGoals) {
        this.selectedGoals.push({ goal: g, selected: false });
      }
      return usersGoals;
    } else {
      this.selectedGoals = [];
      return [];
    }
  }

  updatedAssociatedGoals(): IGoal[] {
    const selectedGoalsFromModal: IGoal[] = [];
    for (const sg of this.selectedGoals) {
      if (sg.selected) {
        selectedGoalsFromModal.push(sg.goal);
        sg.selected = false;
      }
    }
    return selectedGoalsFromModal;
  }

  resetSelectedGoals() {
    this.selectedGoals.forEach((sg) => (sg.selected = false));
  }

  selectDefaultDisplayGoalSkills() {
    for (const employmentExperience of this.employmentExperienceList) {
      if (employmentExperience.associatedGoals.length > 0) {
        this.selectedDisplayGoalSkills.push({
          goal: employmentExperience.associatedGoals[0],
          employmentExperience,
        });
      }
    }
  }

  displayGoalSkills(experience: IEmployment, goal: IGoal) {
    for (const selectedDisplayGoalSkill of this.selectedDisplayGoalSkills) {
      if (selectedDisplayGoalSkill.employmentExperience === experience) {
        selectedDisplayGoalSkill.goal = goal;
      }
    }
  }

  getSelectedDisplayGoalCategorySkills(goal: IGoal) {
    const categorySkills: { [category: string]: { category: string; skill: string[] } } = {};
    goal.skills.forEach((s) => {
      if (!categorySkills[s.category]) {
        categorySkills[s.category] = { category: s.category, skill: [] };
      }
      if (categorySkills[s.category].skill.filter((ss) => ss === s.name).length === 0) {
        categorySkills[s.category].skill.push(s.name);
      }
    });
    return categorySkills;
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    const locationForm: { [key: string]: string } = {
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
    };

    if (address.name) {
      this.formValues.get('company.name')?.setValue(address.name);
    }

    if (address.website) {
      this.formValues.get('company.url')?.setValue(address.website);
    }

    if (!address.address_components) {
      throw new Error("Address components are not available.");
    }

    for (const component of address.address_components) {
      const addressType = component.types[0];

      if (locationForm[addressType]) {
        const val = component[locationForm[addressType]];
        const location = this.convertGoogleAddressPropertiesToCompanyLocationProperties(addressType);
        if (location) {
          this.formValues.get('companyLocation.' + location)?.setValue(val);
        }
      }
    }
  }

  addEmploymentExperience(experienceToAdd: IEmployment) {
    this.employmentExperienceService.addEmploymentExperience(experienceToAdd).subscribe(() => {
      this.message.success('Success, a new employment experience has been added to your profile.');
      this.closeEmploymentExperienceModal();
      this.getEmploymentExperience();
    });
  }

  updateEmploymentExperience(experinceToUpdate: IEmployment) {
    this.employmentExperienceService.updateEmploymentExperience(experinceToUpdate).subscribe(() => {
      this.message.success('Done, your employment experience was sucessfully updated.');
      this.closeEmploymentExperienceModal();
      this.getEmploymentExperience();
    });
  }

  deleteEmploymentExperience() {
    if (this.editMode) {
      const formEntry: IEmployment = this.formValues.value;
      this.employmentExperienceService.deleteEmploymentExperience(formEntry).subscribe(() => {
        this.message.success('Gone, your employment experience has been removed.');
        this.closeEmploymentExperienceModal();
        this.getEmploymentExperience();
      });
    }
  }

  checkForExistingImage(): boolean {
    while (this.formValues.get('company.imageUrl')?.value === '' || this.formValues.get('company.imageUrl')?.value === null) {
      return false;
    }
    return true;
  }

  selectImageInputBar(): void {
    this.imageContainerSelected = false;
    this.imageUrl = this.formValues.get('company.imageUrl')?.value;
  }

  selectImageContainer(): void {
    this.imageContainerSelected = true;
    if (this.formValues.get('company.imageUrl')?.value === '' || this.formValues.get('company.imageUrl')?.value === null) {
      this.formValues.get('company.imageUrl')?.reset();
    }
  }

  getImageUrlFromInputBar(): string {
    const selectedImageUrl = this.formValues.get('company.imageUrl')?.value.trim();
    return selectedImageUrl;
  }

  getImageUrlFromImageContainer(): string {
    const selectedImageUrl = this.imageUrl || '';
    return selectedImageUrl.trim();
  }

  invalidImageUrl(image: Event): void {
    (image.target as HTMLImageElement).style.color = 'red';
    (image.target as HTMLImageElement).alt = 'Invalid Url';
  }

  private convertGoogleAddressPropertiesToCompanyLocationProperties(googleType: string): string | null {
    switch (googleType) {
      case 'country':
        return 'country';
      case 'locality':
        return 'city';
      case 'administrative_area_level_1':
        return 'state';
      case 'postal_code':
        return 'postCode';
      default:
        return null;
    }
  }

  private isEmploymentSkillACoreSkill(goalId: number, skill: ISkill): boolean {
    const goal = this.goalService.getGoal(goalId);
    if (goal && goal.skills) {
      const skillMatch = goal.skills.find(goalSkill => goalSkill.id === skill.id);
      if (skillMatch && skillMatch.coreSkill) {
        return true;
      }
    }
    return false;
  }
}
