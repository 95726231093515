<div class="wrapper">
  <div class="section-title">
    <img class="title-logo" src="/assets/img/sealadder/SL_logo_Cropped_692x422.png" alt="logo" />
    <span class="title-text">Take Control of Your Career</span>
  </div>

  <div class="section-topbanner">
    <div class="topbanner-text">
      <p>The Lifelong Learning & Skills Platform for people Software Engineers, Data Science, Tech Ops</p>
      <div class="button-container">
        <button id="join-long-top" class="register-btn long-text" nz-button nzType="primary" nzSize="large" nzShape="round" (click)="navigateOnCallToAction(true)" nz-tooltip nzTitle="Join now and start using SeaLadder immediately">Join Now</button>
        <button id="join-short-top" class="register-btn short-text" nz-button nzType="primary" nzSize="large" nzShape="round" (click)="navigateOnCallToAction(true)" nz-tooltip nzTitle="Join now and start using SeaLadder immediately">Join</button>
        <button id="register-long-top" class="register-btn long-text" nz-button nzType="primary" nzSize="large" nzShape="round" (click)="navigateOnCallToAction(false)" nz-tooltip nzTitle="Take advantage of this exclusive and limited time offer of professional coaching by the Founder of SeaLadder. Don't miss out!">Register for Coaching</button>
        <button id="register-short-top" class="register-btn short-text" nz-button nzType="primary" nzSize="large" nzShape="round" (click)="navigateOnCallToAction(false)" nz-tooltip nzTitle="Take advantage of this exclusive and limited time offer of professional coaching by the Founder of SeaLadder. Don't miss out!">Register</button>
      </div>
    </div>
    <img class="topbanner-image" src="/assets/img/landing/computer_horizon.png" alt="SeaLadder Banner" />
  </div>

  <div class="section-lifelong-learning">
    <p class="lifelong-learning-title heading-margin">Lifelong Learning & Skills Development Platform</p>
    <div class="lifelong-learning-features">
      <div class="lifelong-learning-cards" *ngFor="let icon of lifeLongLearningIcons | slice: 0:3">
        <img class="card-icon" [src]="icon.iconPath" alt="icon" />
        <p class="card-name">{{ icon.name }}</p>
        <p class="card-description">{{ icon.description }}</p>
      </div>
    </div>
    <p class="lifelong-learning-subtitle heading-margin">Prepare for Success</p>
    <div class="lifelong-learning-features">
      <div class="lifelong-learning-cards" *ngFor="let icon of lifeLongLearningIcons | slice: 3:6">
        <img class="card-icon" [src]="icon.iconPath" alt="icon" />
        <p class="card-name">{{ icon.name }}</p>
        <p class="card-description">{{ icon.description }}</p>
      </div>
    </div>
    <div class="button-container bottom-buttons">
      <button class="register-btn long-text" nz-button nzType="primary" nzSize="large" nzShape="round" (click)="navigateOnCallToAction(true)" nz-tooltip nzTitle="Join now and start using SeaLadder immediately">Join Now</button>
      <button class="register-btn short-text" nz-button nzType="primary" nzSize="large" nzShape="round" (click)="navigateOnCallToAction(true)" nz-tooltip nzTitle="Join now and start using SeaLadder immediately">Join</button>
      <button class="register-btn long-text" nz-button nzType="primary" nzSize="large" nzShape="round" (click)="navigateOnCallToAction(false)" nz-tooltip nzTitle="Take advantage of this exclusive and limited time offer of professional coaching by the Founder of SeaLadder. Don't miss out!">Register for Coaching</button>
      <button class="register-btn short-text" nz-button nzType="primary" nzSize="large" nzShape="round" (click)="navigateOnCallToAction(false)" nz-tooltip nzTitle="Take advantage of this exclusive and limited time offer of professional coaching by the Founder of SeaLadder. Don't miss out!">Register</button>
    </div>
  </div>
</div>
<div class="section-integrated-learning">
  <p class="integrated-learning-title heading-margin">Integrated Learning</p>
  <img class="integrated-learning-logo" src="/assets/img/landing/logo_partners.png" alt="logo" />
  <p class="learning-type-title">Learning identification & planning, scheduling & management, publishing & showcasing:</p>
  <div class="list-learning">
    <ul>
      <li class="learning-type-text" *ngFor="let item of learningTypes">{{ item }}</li>
    </ul>
  </div>
</div>
