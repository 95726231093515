import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EButtonType } from 'src/app/types/button-type.type';
import { IPlaylistActivity } from 'src/app/models/playlist-activity.model';
import { IPlaylist } from 'src/app/models/playlist.model';
import { PlaylistService } from 'src/app/services/playlist.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { UserPlaylistService } from 'src/app/services/user-playlist.service';

@Component({
  selector: 'sl-playlist-selector-cards',
  templateUrl: './playlist-selector-cards.component.html',
  styleUrls: ['./playlist-selector-cards.component.less'],
})
export class PlaylistSelectorCardsComponent {
  @Input() playlist!: IPlaylist;
  @Input() goalId!: number;
  @Output() playlistSharedToGuild = new EventEmitter<IPlaylist>();
  buttonType = EButtonType;
  playlistActivites!: IPlaylistActivity[];
  isHovered = false;
  childrenVisible = false;
  isDrawerVisible = false;
  isAdding = false;
  isActivitiesModalVisible = false;
  isLoadingPlaylistActivities = false;
  MAX_ACTIVITIES = 8;
  activitiesShown = this.MAX_ACTIVITIES;
  readonly defaultCardImage = 'https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80';

  constructor(private userPlaylistService: UserPlaylistService, private playlistService: PlaylistService, private userActivitiesService: UserActivitiesService, private message: NzMessageService, private notification: NzNotificationService) {}

  public get playlistShared(): boolean {
    return this.playlist.added;
  }

  public set playlistShared(added: boolean) {
    this.playlist.added = added;
  }

  showDrawer(show: boolean) {
    this.isLoadingPlaylistActivities = true;
    this.playlistService.getPlaylistActivities(this.playlist.id).subscribe({
      next: (res: IPlaylistActivity[]) => {
        this.playlist.playlistActivitiesVm = res;
        this.isActivitiesModalVisible = show;
        this.isLoadingPlaylistActivities = false;
      },
      error: () => {
        this.message.error(`We've lost connection to SeaLadder HQ and can't get the activities for this playlist. Check network connection and try again?`);
      },
    });
  }

  closeActivityViewer() {
    this.isActivitiesModalVisible = false;
    this.isLoadingPlaylistActivities = false;
  }

  activityDrawerVisible(visible: boolean) {
    this.childrenVisible = visible;
  }

  increaseActivitiesShown() {
    this.activitiesShown += this.MAX_ACTIVITIES;
  }

  sharePlaylistToGuild(playlist: IPlaylist) {
    this.isAdding = true;
    this.playlistSharedToGuild.emit(playlist);
  }

  createNotification(type: string): void {
    this.notification.create(type, 'Notification Title', 'This is the content of the notification. This is the content of the notification. This is the content of the notification.');
  }

  imageEnter() {
    this.isHovered = true;
  }

  imageLeave() {
    this.isHovered = false;
  }

  getActivityCount(): number {
    if (!this.playlist) {
      return 0;
    }

    if (!this.playlist.playlistActivitiesVm) {
      return this.playlist.activityCount;
    }

    return this.playlist.playlistActivitiesVm.length;
  }

  getAuthor(): string {
    let author = '';
    if (this.playlist) {
      author = this.playlist.createdByFirstName + ' ' + this.playlist.createdByLastName;
      return author;
    }
    return author;
  }

  getPlayListDetails(): string {
    if (!this.playlist) {
      console.error('playlist is not defined when we\'re showing a playlist. That just doesn\'t make sense');
      return 'INVALID';
    }

    const levelFrom = this.playlist.levelFrom ? this.playlist.levelFrom.toLocaleString() : '*';
    const levelTo = this.playlist.levelTo ? ' - ' + this.playlist.levelTo.toLocaleString() : '';
    const specialisation = this.playlist.specialisation ? this.playlist.specialisation : '';

    return `${specialisation} [${levelFrom}${levelTo}], by ${this.getAuthor()}`;
  }

  getCardImage(): string {
    if (this.playlist.playlistImageUrl) {
      return this.playlist.playlistImageUrl;
    } else {
      return this.defaultCardImage;
    }
  }
}
