/* eslint-disable @typescript-eslint/member-ordering */
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import slugify from 'slugify';

// models
import { IGoal } from '../models/user-goal.model';
import { IGoalSkill } from '../models/goal-skill.model';
import { ISkill } from '../models/skill.model';
import { IGoalSignatureBase } from '../models/goal-signature.model';

// services
import { AuthService } from './auth.service';
import { GoalServiceData } from './goal.service.data';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class GoalService {
  endpoint = '/api/Goals/';
  initialising = false;
  private isLoggedIn = false;

  private readonly customTrackName = 'Custom';

  constructor(private http: HttpClient, private authService: AuthService, private goalServiceData: GoalServiceData) {
    this.isLoggedIn = authService.isLoggedIn() ? true : false;
    // console.log('*** GoalService: constructing while logggedIn= ' + this.isLoggedIn);
    this.initialiseGoals();

    this.authService.isLoggedInChange().subscribe(() => {
      this.isLoggedIn = authService.isLoggedIn();
      if (this.isLoggedIn) {
        this.initialiseGoals();
      }
    });
  }

  public getIsLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  public isInitialised(): boolean {
    return this.goalServiceData.initialised;
  }

  public getUncategorisedGoalSkills(): Observable<IGoalSkill[]> {
    const url = this.endpoint + 'GetUncategorisedGoalSkills';
    return this.http.get<IGoalSkill[]>(url, httpOptions);
  }

  getCategories(): Observable<string[]> {
    const url = this.endpoint + 'GetCategories';
    return this.http.get<string[]>(url, httpOptions);
  }

  getEditableSkills(): Observable<ISkill[]> {
    const url = this.endpoint + 'GetSkills';
    return this.http.get<ISkill[]>(url, httpOptions);
  }

  getCategoryIcon(category: string): Observable<string> {
    const sluggedCategory = slugify(category);
    const url = this.endpoint + `GetCategoryIcon/${sluggedCategory}`;
    return this.http.get<string>(url, httpOptions);
  }

  // call off to the API to get goals, and on return set the goalServiceData
  private initialiseGoals() {
    if (this.isLoggedIn) {
      this.intitialiseGoalsFromServer();
      return;
    }

    const initialised = this.isInitialised();
    console.log(`*** GoalService: cannot initialise the goals because isLoggedIn=${this.isLoggedIn} initialised=${initialised}`);
  }

  private intitialiseGoalsFromServer() {
    if (this.initialising) {
      return;
    }

    this.initialising = true;

    const url = this.endpoint + 'GetGoals';
    this.http.get<IGoal[]>(url, httpOptions).subscribe({
      next: (goals) => {
        this.goalServiceData.initialiseGoals(goals);
        this.initialising = false;
      },
      error: (error) => {
        console.error(`*** GoalService: initialiseGoals, error when getting the goals. Error: ${error.message}`);
        this.initialising = false;
      },
    });
  }

  /* User Goal Methods */

  /**
   * Get the cached goals. Will throw if not initialised
   *
   * @returns the collectioin of goals
   */
  public getGoals(filterActiveOnly: boolean = false): IGoal[] {
    if (filterActiveOnly) {
      return this.goalServiceData.getActiveGoals();
    }

    return this.goalServiceData.getGoals();
  }

  getGoal(goalId: number | undefined): IGoal | undefined {
    if (!goalId) {
      return undefined;
    }
    return this.goalServiceData.getGoal(goalId);
  }

  getGoalFromServer(goalId: number | undefined): Observable<IGoal> {
    if (!goalId) {
      throw new Error('Goal Id cannot be undefined at this point');
    }

    const url = `${this.endpoint}GetGoal/${goalId}`;
    return this.http.get<IGoal>(url);
  }

  addGoal(goal: IGoal): Observable<IGoal> {
    console.log('*** Entering add goal');
    const url = this.endpoint + 'AddGoal';

    return this.http.post<IGoal>(url, goal, httpOptions).pipe(
      map((resultGoal: IGoal) => {
        if (!resultGoal.selectedTrackName) {
          resultGoal.selectedTrackName = this.customTrackName;
        }
        console.log(`*** GoalService: addGoal succeeded to with GoalId: ${resultGoal.id}`);
        this.goalServiceData.addGoal(resultGoal);
        return resultGoal;
      })
    );
  }

  updateGoalAndServiceData(goal: IGoal): Observable<IGoal> {
    const url = this.endpoint + 'UpdateGoal';
    return this.http.put<IGoal>(url, goal, httpOptions).pipe(
      map((resultGoal: IGoal) => {
        if (!resultGoal.selectedTrackName) {
          resultGoal.selectedTrackName = this.customTrackName;
        }
        console.log(`*** GoalService: toggleGoalCompleted succeeded, setting to: ${resultGoal.dateCompleted === undefined ? 'Active' : 'Completed'}`);
        this.goalServiceData.updateGoal(resultGoal);
        return resultGoal;
      })
    );
  }

  updateGoal(goal: IGoal): Observable<IGoal> {
    const url = this.endpoint + 'UpdateGoal';
    return this.http.put<IGoal>(url, goal, httpOptions);
  }

  updateGoalSkill(goalSkill: IGoalSkill): Observable<IGoal> {
    const url = this.endpoint + 'UpdateGoalSkill';
    return this.http.post<IGoal>(url, goalSkill, httpOptions);
  }

  public toggleGoalCompleted(goal: IGoal): Observable<IGoal> {
    const url = this.endpoint + 'ToggleGoalComplete';
    return this.http.put<IGoal>(url, goal, httpOptions).pipe(
      map((resultGoal: IGoal) => {
        if (!resultGoal.selectedTrackName) {
          resultGoal.selectedTrackName = this.customTrackName;
        }
        console.log(`*** GoalService: toggleGoalCompleted succeeded, setting to: ${resultGoal.dateCompleted === undefined ? 'Active' : 'Completed'}`);
        this.goalServiceData.updateGoal(resultGoal);
        return resultGoal;
      })
    );
  }

  public deleteGoal(goal: IGoal): Observable<IGoal> {
    const url = this.endpoint + 'Delete';

    return this.http.put<IGoal>(url, goal, httpOptions).pipe(
      map((deletedGoal: IGoal) => {
        console.log(`*** GoalService: deleteGoal succeeded to with GoalId: ${deletedGoal.id}`);
        this.goalServiceData.deleteGoal(deletedGoal);
        return deletedGoal;
      })
    );
  }

  public seedDatabase(industry: string): Observable<boolean> {
    const url = this.endpoint + `SeedDatabase/${industry}`;
    return this.http.post<boolean>(url, '', httpOptions);
  }

  public seedCategoriesAndIcons(): Observable<boolean> {
    const url = this.endpoint + 'SeedCategoriesAndIcons';
    return this.http.post<boolean>(url, '', httpOptions);
  }

  public hasActiveGoals(): boolean {
    return this.goalServiceData.hasActiveGoals;
  }

  // PLEASE: Don't forget to unsubscribe or take(1)
  public getGoalsUpdatedEvent(): Subject<boolean> {
    return this.goalServiceData.getGoalsUpdatedEvent();
  }

  public isGoalDuplicate(inputGoal: IGoal): boolean {
    for (const goal of this.goalServiceData.getGoals()) {
      if (goal.industry === inputGoal.industry && goal.profession === inputGoal.profession && goal.specialisation === inputGoal.specialisation && goal.level === inputGoal.level) {
        return true;
      }
    }
    return false;
  }

  public getSuggestedCategorySkills(industry: string, profession: string, level: string): Observable<ISkill[]> {
    const url = this.endpoint + 'GetSuggestedCategorySkills';
    return this.http.get<ISkill[]>(url, { params: { industry, profession, level } });
  }

  // TODO: Go via skillService
  public updateSkill(skill: ISkill): Observable<ISkill> {
    const url = this.endpoint + 'UpdateSkill';
    return this.http.post<ISkill>(url, skill);
  }

  // TODO: Go via skillService
  public retireSkill(skill: ISkill): Observable<ISkill> {
    const url = this.endpoint + 'RetireSkill';
    return this.http.post<ISkill>(url, skill.id);
  }

  public getGoalsForPlaylist(playlistId: number): Observable<IGoal[]> {
    const url = this.endpoint + `GetGoalsForPlaylist/`;
    return this.http.get<IGoal[]>(url, { params: { playlistId: playlistId.toString() } });
  }

  public getGoalsForActivity(activityId: number): Observable<IGoal[]> {
    const url = this.endpoint + `GetGoalsForActivity/`;
    return this.http.get<IGoal[]>(url, { params: { activityId: activityId.toString() } });
  }

  public getGoalSignature(goalId: number): Observable<IGoalSignatureBase> {
    const url = this.endpoint + `GetGoalSignature/`;
    return this.http.get<IGoalSignatureBase>(url, { params: { goalId: goalId.toString() } });
  }

  public getBrokenGoalsCount(): Observable<number> {
    const url = this.endpoint + `GetBrokenGoalsCount/`;
    return this.http.get<number>(url, httpOptions);
  }
}
