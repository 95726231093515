/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, EventEmitter, Input, Output, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Observable, Observer } from 'rxjs';

// services
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';

// models
import { EditMode } from '../../types/edit-mode.type';

@Component({
  selector: 'sl-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.less'],
})
export class ImageUploaderComponent implements OnChanges {
  @ViewChild('imageFromInputBar') imageUrlField!: ElementRef;
  @Output() uploadImageUrlEvent = new EventEmitter<string>();
  @Input() currentMode!: EditMode;
  @Input() imageSrc!: string;

  public imageUrlInputValue = '';
  public isLoadingImage = false;
  public imageUrl = '';
  public imageContainerSelected = true;

  constructor(private message: NzMessageService) {}

  ngOnChanges(): void {
    this.imageUrlInputValue = this.imageSrc;
    this.imageUrl = this.imageSrc;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isSupportedType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
      if (!isSupportedType) {
        this.message.error('You can only upload JPG, PNG or SVG files.');
        observer.complete();
        return;
      }
      let isSupportedSize!: boolean;
      if (file.size) {
        isSupportedSize = file.size / 1024 / 1024 < 0.4;
      }
      if (!isSupportedSize) {
        this.message.error('Image must be smaller than 400KB!');
        observer.complete();
        return;
      }
      observer.next(isSupportedType && isSupportedSize);
      observer.complete();
    });

  uploadActionImage = (item: NzUploadXHRArgs) => {
    this.isLoadingImage = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileToUpload = item.file as any;
    const reader = new FileReader();
    reader.readAsDataURL(fileToUpload);
    reader.onload = () => {
      this.imageUrlInputValue = reader.result!.toString();
      this.imageUrl = reader.result!.toString();
      this.uploadImageUrlEvent.emit(this.imageUrl);
      this.isLoadingImage = false;
    };
  };

  public checkForExistingImage(): boolean {
    while (!this.imageUrlInputValue && !this.imageUrl) {
      return false;
    }
    return true;
  }

  public selectImageInputBar(): void {
    this.imageContainerSelected = false;
    this.imageUrl = this.imageUrlInputValue;
  }

  public uploadImageFromInputBar(): void {
    if (this.imageUrlInputValue.trim() === '') {
      this.uploadImageUrlEvent.emit('');
    } else if (this.imageUrlField.nativeElement.offsetHeight > 22) {
      this.uploadImageUrlEvent.emit(this.imageUrl);
    }
  }

  public selectImageContainer(): void {
    this.imageContainerSelected = true;
  }

  public getImageUrlFromInputBar(): string {
    this.isLoadingImage = false;
    const selectedImageUrl = this.imageUrlInputValue;
    return selectedImageUrl;
  }

  public getImageUrlFromImageContainer(): string {
    this.isLoadingImage = false;
    const selectedImageUrl = this.imageUrl;
    return selectedImageUrl!;
  }
}
