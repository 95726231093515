import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISuggestedAction } from '../models/suggested-action.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class SuggestedActionService {
  private controller = '/api/SuggestedAction';
  constructor(private http: HttpClient) {}

  addSuggestedAction(suggestedAction: ISuggestedAction): Observable<ISuggestedAction> {
    if (!suggestedAction) {
      throw new Error('Action cannot be undefined at this point');
    }

    const endpoint = this.controller + `/AddSuggestedAction`;

    return this.http.post<ISuggestedAction>(endpoint, suggestedAction, httpOptions);
  }

  getActionsCreated(): Observable<ISuggestedAction[]> {
    const endpoint = this.controller + '/GetActionsCreated';
    return this.http.get<ISuggestedAction[]>(endpoint);
  }

  updateActionMetaData(action: ISuggestedAction): Observable<ISuggestedAction> {
    if (!action) {
      throw new Error('Action cannot be undefined at this point.');
    }

    const endpoint = this.controller + `/UpdateSuggestedActionMetaData/`;
    return this.http.put<ISuggestedAction>(endpoint, action, httpOptions);
  }
}
