import { IMetricLineChart, IMetricSeriesNameValue } from './../../../models/metric-name-value.model';
import { MetricService } from './../../../services/metric.service';
import { Component, OnInit } from '@angular/core';
import { IMetricNameValue } from 'src/app/models/metric-name-value.model';
@Component({
  selector: 'sl-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.less'],
})
export class MetricsComponent implements OnInit {
  // Metrics
  activeUserMetric!: IMetricNameValue[];
  activeUserPerMonthMetric!: IMetricLineChart[];
  userTypesRatioMetric!: IMetricNameValue[];
  usersGoalLevelMetric!: IMetricNameValue[];
  userSpecialisationMetric!: IMetricNameValue[];
  completedActivitiesMetric!: IMetricLineChart;
  completedGoalsMetric!: IMetricLineChart;
  newSubscriptionsAndUnsubscriptionsMetric!: IMetricSeriesNameValue;

  // Graph Attributes
  view: number[] = [600, 400];
  viewLarge: number[] = [1200, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  animations = true;
  colourScheme = 'picnic';
  colourSchemeReduced = 'ocean';
  monthlyRange = 24;

  constructor(private metricService: MetricService) { }

  ngOnInit(): void {
    this.getActiveUserMetric();
    this.getActiveUserPerMonthMetric();
    this.getUserTypesRatioMetric();
    this.getUsersGoalLevelMetric();
    this.getUserSpecialisationsMetric();
    this.getCompletedActivitiesMetric();
    this.getCompletedGoalsMetric();
    this.getUserSubscriptionStatusMetric();
  }

  getActiveUserMetric() {
    this.metricService.getActiveUserMetric().subscribe((agm) => {
      this.activeUserMetric = agm;
    });
  }

  getActiveUserPerMonthMetric() {
    this.metricService.getActiveUserPerMonthMetric().subscribe((aupm) => {
      this.activeUserPerMonthMetric = aupm;
    });
  }

  getUserTypesRatioMetric() {
    this.metricService.getUserTypesRatioMetric().subscribe((agm) => {
      this.userTypesRatioMetric = agm;
    });
  }

  getUsersGoalLevelMetric() {
    this.metricService.getUsersGoalLevelMetric().subscribe((uglm) => {
      this.usersGoalLevelMetric = uglm;
    });
  }

  getUserSpecialisationsMetric() {
    this.metricService.getUserSpecialisationsMetric().subscribe((usm) => {
      this.userSpecialisationMetric = usm;
    });
  }

  getCompletedActivitiesMetric() {
    this.metricService.getCompletedActivitiesMetric().subscribe((cam) => {
      this.completedActivitiesMetric = cam;
    });
  }

  getCompletedGoalsMetric() {
    this.metricService.getCompletedGoalsMetric().subscribe((cgm) => {
      this.completedGoalsMetric = cgm;
    });
  }

  getUserSubscriptionStatusMetric() {
    this.metricService.getUserSubscriptionStatusMetric(this.monthlyRange).subscribe((ussm) => {
      this.newSubscriptionsAndUnsubscriptionsMetric = ussm;
    });
  }
}
