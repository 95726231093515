import { Component, OnInit } from '@angular/core';


import { NzMessageService } from 'ng-zorro-antd/message';

// Services
import { PaymentService } from 'src/app/services/payment.service';

// Models
import { IStripeEvent } from 'src/app/models/stripe-event.model';

@Component({
  selector: 'sl-stripe-reconciliation',
  templateUrl: './stripe-reconciliation.component.html',
  styleUrls: ['./stripe-reconciliation.component.less']
})
export class StripeReconciliationComponent implements OnInit {
  stripeEvents: IStripeEvent[] = [];
  isLoading = false;

  constructor(
    private paymentService: PaymentService,
    private messageService: NzMessageService) { }

  ngOnInit() {
    this.getFailedStripeEvents();
  }

  public getFailedStripeEvents() {
    this.isLoading = true;
    this.paymentService.getFailedStripeEvents().subscribe({
      next: (stripeEvents) => {
        this.stripeEvents = stripeEvents;
        this.isLoading = false;
      },
      error: (error) => {
        console.error(`Could not get failed stripe events: ${JSON.stringify(error)}`);
        this.isLoading = false;
      }
    });
  }

  public runFailedStripeEvent(stripeEvent: IStripeEvent) {
    stripeEvent.accepted = true;
    this.paymentService.runFailedStripeEvent(stripeEvent).subscribe({
      next: (stripeEventResult) => {
        this.messageService.create('success', `Stripe Event Successfully Ran. Subscription status for ${stripeEventResult.customerName} is ${stripeEventResult.subscriptionStatus}`);
        this.getFailedStripeEvents();
      },
      error: (error) => {
        console.error(`Could not run failed stripe event: ${JSON.stringify(error)}`);
      }
    });
  }

  public ignoreFailedStripeEvent(stripeEvent: IStripeEvent) {
    stripeEvent.accepted = false;
    this.paymentService.ignoreFailedStripeEvent(stripeEvent).subscribe({
      next: (stripeEventResult) => {
        this.messageService.create('success', `Stripe Event Has Been Ignored. Subscription status for ${stripeEventResult.customerName} is still ${stripeEventResult.subscriptionStatus}`);
        this.getFailedStripeEvents();
      },
      error: (error) => {
        console.error(`Could not ignore failed stripe event: ${JSON.stringify(error)}`);
      }
    });
  }

}
