<nz-card id="activity-card-style" [nzHoverable]="isInteractable" [nzCover]="coverTemplate" (click)="isInteractable ? openDrawer() : null">
  <div class="card-info-container">
    <a href={{recommendation.link}} target="_blank">
      <h5 class="card-title" id="activity-card-{{recommendation.activityId}}" nz-typography nzEllipsis nzEllipsisRows="1" nz-tooltip="{{recommendation.title}}">
        {{recommendation.title}}
      </h5>
    </a>
    <div class="card-type" nz-typography>{{recommendation.typeName}}</div>
    <i class="fas fa-book-reader fa-2x card-learning-style" *ngIf="recommendation.learningStyle === 'Exposure'" nz-tooltip nzTooltipTitle="Exposure"></i>
    <i class="fas fa-award fa-2x card-learning-style" *ngIf="recommendation.learningStyle === 'Credibility'" nz-tooltip nzTooltipTitle="Credibility"></i>
    <i class="fas fa-tools fa-2x card-learning-style" *ngIf="recommendation.learningStyle === 'Experience'" nz-tooltip nzTooltipTitle="Experience"></i>
    <i class="fa fa-cog fa-2x card-learning-style" *ngIf="!recommendation.learningStyle" nz-tooltip nzTooltipTitle="Uncategorised"></i>
    <div class="card-skills" nz-typography nzEllipsis nzEllipsisRows="1">
      <span class="skill-name" [ngClass]="{'skill-not-from-goal': !goalSkillsId.includes(skill.id)}" *ngFor="let skill of recommendation.skills; let isLast=last">
        <sl-core-skill-icon *ngIf="skill.coreSkill" class="core-skill-recommendation" [tooltipVisible]="true" [tooltip]="skill.name + ' has been marked as a core skill for your career'"></sl-core-skill-icon> {{skill.name}}{{isLast ? '' : ', '}}
      </span>
    </div>
    <button class="card-options" nz-button nzType="Primary" nz-tooltip nzTooltipTitle="Report Activity" (click)="addToReportedList()">
      <i id="report-flag" *ngIf="!isActivityFlagged()" class="fas fa-flag"></i>
      <i id="report-flag-flagged" *ngIf="isActivityFlagged()" class="fas fa-flag"></i>
    </button>
  </div>
  <div *ngIf="selectedGuildMembers" class="activity-added-by-members-container">
    <nz-avatar-group class="member-icons">
      <div *ngFor="let member of selectedGuildMembers">
        <nz-avatar id="member-avatar" nzIcon="user" nzSrc="{{ member.imageBase64 }}" nz-tooltip nzTooltipTitle="Added by: {{ member.firstName }} {{ member.surname }}" [ngClass]="{
            'member-avatar-newbie' : member.memberType === guildRoleType.Newbie,
            'member-avatar-member' : member.memberType === guildRoleType.Member,
            'member-avatar-leader' : member.memberType === guildRoleType.Leader,
            'member-avatar-owner' : member.memberType === guildRoleType.Owner
         }">
        </nz-avatar>
      </div>
    </nz-avatar-group>
  </div>
</nz-card>
<ng-template #coverTemplate>
  <div id="recommendation-image-{{recommendation.activityId}}" class="recommendation-image-container" (mouseleave)="imageLeave()" (mouseenter)="imageEnter()">
    <img *ngIf="!isShowingLearningTypeIcon()" class="recommandation-image" src="{{fileUrl}}" (error)="handleMissingImage($event)" [ngClass]="{'img-added-backlog': addedToBacklog}" />
    <i *ngIf="isShowingLearningTypeIcon()" class="learning-type-icon {{learningTypeIcon}}"></i>
    <div *ngIf="(!addedToBacklog) && isInteractable">
      <button *ngIf="goalId" id="add-activity-button-{{recommendation.activityId}}" class="add-backlog-button" [hidden]="!isHovered" [nzLoading]="isLoading" nz-button nzType="primary" (click)="addToBacklog($event)">
        <i class="fas fa-plus"></i>
        <span class="add-button-text"> Add Activity</span>
      </button>
    </div>
    <div *ngIf="addedToBacklog">
      <i class="add-backlog-button checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'"></i>
    </div>
  </div>
</ng-template>
<div class="activity-contents-drawer-container">
  <sl-activity-contents-drawer [showDrawer]="recommendationPanel" [addedToBacklog]="addedToBacklog" [recommendation]="recommendation" [isActivityListMode]="isActivityListMode" [fileUrl]="fileUrl" [shorterUrl]="shorterUrl" (drawerNotVisible)="closeDrawer()"
    (drawerVisible)="openDrawer()" (activityRecommendationAdded)="addToBacklog()" [showLearningTypeIcon]="isShowingLearningTypeIcon()" [learningTypeIcon]="learningTypeIcon" [missingImagePlaceholder]="missingImagePlaceholder"></sl-activity-contents-drawer>
</div>