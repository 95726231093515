/* eslint-disable no-useless-escape */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WikiExtract } from 'src/app/models/wikipedia-extract.model';
import { getWindow } from './helper.service';
import { YoutubeVideos, YoutubePlaylists as YoutubePlaylists } from '../models/google-youtube.models';

@Injectable({
  providedIn: 'root',
})
export class ExternalApiService {
  constructor(private http: HttpClient) { }

  extractVideoData(backlogLink: string): Observable<YoutubeVideos> {  // TODO: Use YoutubeVideos
    const videoId = this.extractVideoID(backlogLink);
    const baseUrl = `https://www.googleapis.com/youtube/v3`;
    const apiKey = `AIzaSyANL_kHolw53s_0PsaBxbKnRK6HA0A2Tkg`;
    const part = `snippet,contentDetails,statistics`;
    const apiUrl = baseUrl + `/videos?key=` + apiKey + `&id=` + videoId + `&part=` + part;

    // example: `https://www.googleapis.com/youtube/v3/videos?key=AIzaSyANL_kHolw53s_0PsaBxbKnRK6HA0A2Tkg&id=rNUxQ-9AZss&part=snippet,contentDetails,statistics`

    return this.http.get<YoutubeVideos>(apiUrl);
  }

  extractPlaylistData(backlogLink: string): Observable<YoutubePlaylists> {
    const playlistId = this.extractPlaylistID(backlogLink);
    const baseUrl = `https://www.googleapis.com/youtube/v3`;
    const apiKey = `AIzaSyANL_kHolw53s_0PsaBxbKnRK6HA0A2Tkg`;
    const part = `snippet,contentDetails`;
    const apiUrl = `${baseUrl}/playlists?key=${apiKey}&id=${playlistId}&part=${part}`;

    // example: `https://www.googleapis.com/youtube/v3/playlists?key=AIzaSyANL_kHolw53s_0PsaBxbKnRK6HA0A2Tkg&id=rNUxQ-9AZss&id=PLC3y8-rFHvwhBRAgFinJR8KHIrCdTkZcZ&part=snippet,contentDetails`

    return this.http.get<YoutubePlaylists>(apiUrl);
  }

  extractVideoID(url: string): string {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[7].length === 11) {
      return match[7];
    }

    console.warn(`extracId failed for ${url}`);
    return '';
  }

  extractPlaylistID(url: string): string {
    const regExp = /list=([a-zA-Z0-9_-]+)/;
    const match = url.match(regExp);
    if (match) {
      return match[1];
    }
    console.warn(`extractPlaylistID failed for ${url}`);
    return '';
  }

  isPlaylistLink(url: string): boolean {
    return url.includes('youtube.com/playlist');
  }

  //-- Commented out until we need to use it. This method extracts the tags from the videos inside the YouTube playlist. Normally, these tags appear in the Related Skills section of the 'Add Activities' modal
  // getTagsFromPlaylistActivities(backlogLink: string): Observable<any> {
  //   const baseUrl = `https://www.googleapis.com/youtube/v3`;
  //   const apiKey = `AIzaSyANL_kHolw53s_0PsaBxbKnRK6HA0A2Tkg`;

  //   return this.http.get<any>(`${baseUrl}/playlistItems`, {
  //     params: {
  //       key: apiKey,
  //       playlistId: this.extractPlaylistID(backlogLink),
  //       part: 'contentDetails',
  //       // maxResults: '50'  // Maximum allowed by the API
  //     }
  //   }).pipe(
  //     mergeMap(response => {
  //       const videoIds = response.items.map((item: { contentDetails: { videoId: string } }) => item.contentDetails.videoId);
  //       const fetchTagsObservables = videoIds.map((videoId: string) =>
  //         this.http.get<any>(`${baseUrl}/videos`, {
  //           params: {
  //             key: apiKey,
  //             id: videoId,
  //             part: 'snippet'
  //           }
  //         }).pipe(
  //           map((videoResponse: { items: { snippet: { tags: string[] } }[] }) => videoResponse.items[0].snippet.tags)

  //         )
  //       );
  //       return forkJoin(fetchTagsObservables) as any as Observable<string[][]>;

  //     }),
  //     map((tagsArrays: string[][]) => {
  //       const flattenedTagsArray = tagsArrays.flat();
  //       const filteredTags = [...new Set(flattenedTagsArray)];
  //       return filteredTags;
  //     })
  //   );
  // }

  extractGithub(url: string): string {
    const regExp = /.+\/(.+)$/;
    const match = url.match(regExp);
    if (match) {
      return match[1];
    }
    console.warn(`extractGithub failed for ${url}`);
    return '';
  }

  extractMeetup(url: string): string {
    const regExp = /.+\/(.+)\/events\/.*$/;
    const match = url.match(regExp);
    if (match) {
      return match[1];
    }
    console.warn(`extractMeetup failed for ${url}`);
    return '';
  }

  // source: https://stackoverflow.com/questions/28735459/how-to-validate-youtube-url-in-client-side-in-text-box
  validateYouTubeUrl(url: string): boolean {
    if (url !== undefined) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      const match = url.match(regExp);
      if (match) {
        return match[2].length === 11;
      }
    }
    return false;
  }

  validateGithubUrl(url: string): boolean {
    if (url !== undefined) {
      const regExp = /^.*github.com.*/;
      return regExp.test(url);
    }

    return false;
  }
  validateMeetupUrl(url: string): boolean {
    if (url !== undefined) {
      const regExp = /^.*meetup.com.*/;
      return regExp.test(url);
    }

    return false;
  }

  getWikipediaExtract(keyword: string): Observable<WikiExtract> {
    const controllerUrl = '/api/KeyInfo/GetArticleExtract/';
    return this.http.get<WikiExtract>(controllerUrl + keyword.replace(/ /g, '_'));
  }

  beginOAuth(href: string): void {
    const window = getWindow();
    window.location.href = href;
  }
}
