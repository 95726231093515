import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IOnboardingStatus } from '../models/onboarding-status.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class OnboardingService {
  onboardingStatus = new BehaviorSubject<boolean>(false);
  onboardingComplete = this.onboardingStatus.asObservable();
  private endpoint = '/api/Onboarding';
  private complete = false;

  constructor(private http: HttpClient) {
    this.getOnboardingStatus();
  }

  getOnboardingStatus(): Observable<IOnboardingStatus[]> {
    const endpoint = `${this.endpoint}/GetOnboardingStatus`;
    return this.http.get<IOnboardingStatus[]>(endpoint, httpOptions).pipe(
      tap((statuses: IOnboardingStatus[]) => {
        this.complete = statuses === null || statuses.every(status => status.taskValue === true);
      })
    );
  }

  completeAddActivityTask(isComplete: boolean): void {
    this.onboardingStatus.next(isComplete);
  }

  isComplete(): boolean {
    return this.complete;
  }
}
