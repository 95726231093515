import { Component, OnInit } from '@angular/core';

// Modules
import { NzMessageService } from 'ng-zorro-antd/message';

// Services
import { SkillDuplicatesService } from 'src/app/services/skill-duplicates.service';

// Models
import { ISkillDuplicate } from 'src/app/models/skill.duplicate.model';

@Component({
  selector: 'sl-skillduplicates',
  templateUrl: './skillduplicates.component.html',
  styleUrls: ['./skillduplicates.component.less']
})
export class SkillduplicatesComponent implements OnInit {
  skillDuplicates!: ISkillDuplicate[];
  skillDuplicate!: ISkillDuplicate;
  isLoading = false;
  isLoadingRemoveAll = false;

  constructor(private skillDuplicatesService: SkillDuplicatesService, private message: NzMessageService) { }

  ngOnInit() {
    this.getTrackSkillDuplicates();
  }

  public clearTrackSkillDuplicates() {
    this.isLoadingRemoveAll = true;
    this.skillDuplicatesService.clearTrackSkillDuplicates().subscribe({
      next: (result) => {
        this.skillDuplicates.splice(0, this.skillDuplicates.length);
        this.message.success(`Successfully removed ${result} duplicate skills.`);
        this.isLoadingRemoveAll = false;
      },
      error: (error) => {
        console.error(`Could not remove duplicate skills: ${JSON.stringify(error)}`);
        this.message.error('We failed to remove the duplicate skills for you. Please try again in a moment.');
        this.isLoadingRemoveAll = false;
      }
    });
  }

  public clearTrackSkillDuplicate(index: number) {
    this.skillDuplicate = this.skillDuplicates[index];
    this.skillDuplicatesService.clearTrackSkillDuplicate(this.skillDuplicate.trackId, this.skillDuplicate.skill.id).subscribe({
      next: (duplicate) => {
        if (duplicate) {
          this.skillDuplicates.splice(index, 1);
        }
        this.message.success(`Successfully removed duplicate skill`);
      },
      error: (error) => {
        console.error(`Could not remove duplicate skill: ${JSON.stringify(error)}`);
        this.message.error('We failed to remove the duplicate skill for you. Please try again in a moment.');
      }
    });
  }

  private getTrackSkillDuplicates() {
    this.isLoading = true;
    this.skillDuplicatesService.getTrackSkillDuplicates().subscribe({
      next: (duplicates) => {
        this.skillDuplicates = duplicates;
        this.isLoading = false;
      },
      error: (error) => {
        console.error(`Could not get duplicate skills: ${JSON.stringify(error)}`);
        this.message.error('We failed to get the duplicate skills for you.');
        this.isLoading = false;
      }
    });
  }
}
