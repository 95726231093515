<div class="wrapper">
  <div class="section-title">
    <img class="title-logo" src='/assets/img/sealadder/SL_logo_Cropped_692x422.png' alt="logo" />
    <p class="title-text">Join the waitlist to be amongst the brightest and the best</p>
    <p class="title-description">Clear goals and skills setting, and evidencing how you're developing your skills, helps change the way you're seen by employers, forever.</p>
  </div>
  <div class="section-form">
    <form nz-form [nzLayout]="'vertical'" [formGroup]="formValues" (ngSubmit)="showModal()">
        <nz-form-item id="name-input">
          <nz-form-label nzFor="firstName">First Name</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="firstName" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item id="name-input">
          <nz-form-label nzFor="lastName">Last Name</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="lastName" />
          </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="email">Email</nz-form-label>
        <nz-form-control>
          <input type="email" name="email" email="true" nz-input formControlName="email" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="city">City</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="city" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="profession">Current Role</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch formControlName="profession">
            <nz-option *ngFor="let role of specialisations" [nzLabel]="role" [nzValue]="role"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button id="regBtn" class="register-btn1" nz-button nzType="primary" nzSize="large" nzShape="round" [disabled]="formValues.invalid">Click here to join the waitlist</button>
        </nz-form-control>
      </nz-form-item>
     
    </form>
  </div>

  <nz-modal id="submitModal" nzWidth="850px"[(nzVisible)]="isVisible" nzTitle="<h1>Thanks so much for signing up</h1>" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <div nz-row>
      <img nz-col nzSpan="9" height="100%" width="100%" src="/assets/img/sealadder/sealadder-logo-gradient1844.png">
        <div nz-col nzSpan="15" styles="margin-top = 20%;">
        <div style="padding-top: 25%;"><h2 vertical-align="middle">We will send you an email to: {{this.formValues.value.email}} with your invitation code soon</h2></div>
      </div>
    </div>
  </nz-modal>
  
  <div class="section-icons">
    <i class="icon fab fa-facebook-f"></i>
    <i class="icon fab fa-linkedin-in"></i>
    <i class="icon fab fa-instagram"></i>
    <i class="icon fab fa-twitter"></i>
    <i class="icon fab fa-youtube"></i>
    <i class="icon icomoon icon-mail"></i>
  </div>
  <div class="section-footer">
    <p>Lifelong Learning & Skills Development Platform</p>
  </div>
</div>