/* eslint-disable @typescript-eslint/member-ordering */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

// libraries
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { EditMode } from 'src/app/generic-components/types/edit-mode.type';
import { StringValidationPipe } from 'src/app/generic-pipes/string-validation.pipe';

// models
import { IPlaylist } from 'src/app/models/playlist.model';
import { ITrack } from 'src/app/models/track.model';

// services
import { AuthService } from 'src/app/services/auth.service';
import { PlaylistService } from 'src/app/services/playlist.service';
import { SaveFileService } from 'src/app/services/save-file.service';

@Component({
  selector: 'sl-create-playlist',
  templateUrl: './create-playlist.component.html',
  styleUrls: ['./create-playlist.component.less'],
  providers: [StringValidationPipe]
})
export class CreatePlaylistComponent implements OnInit {
  @ViewChild('playlistNameFocus') playlistNameFocus!: ElementRef;
  @Input() playlistEditing!: IPlaylist;
  @Input() isAdminMode = false;
  @Output() handlePlaylistCreated = new EventEmitter<IPlaylist>();
  @Output() handlePlaylistUpdated = new EventEmitter<IPlaylist>();

  // TODO: Refactor playlistEditing to be playlistExisting, and follow the same pattern as in the guild editor and actions
  // Guild-Editor: incomingGuild, guildCurrent
  // ActionsComponent: actionCreated, actionsExisting[], actionExistingSelectedId

  public playlistsExisting: IPlaylist[] | undefined = undefined;
  public incomingPlaylist!: IPlaylist;
  public playlistCreated!: IPlaylist;
  public stepIndex = 0;
  public isVisiblePlaylistPicker = false;
  public mode: EditMode = EditMode.new;
  public modeType = EditMode; // hack to get around the limitations of JavaScript when it comes to enums; https://marco.dev/enums-angular
  public fileList: NzUploadFile[] = [];
  public activitiesCsv!: NzUploadFile | undefined;
  public playlistExistingSelectedId: number | undefined;
  public loading: { [index: string]: boolean } = {};
  public isSubmittingPlaylist = false;
  public isSubmittingActivities = false;
  public isExporting = false;
  public showNoticeAboutUpdatingPlaylist = false;
  public nameInputBarSelected = true;
  // Group for IPlaylistValues
  // See: https://netbasal.com/typed-reactive-forms-in-angular-no-longer-a-type-dream-bf6982b0af28 Bonus section

  public playlistNameError = '';

  public csvMimeTypes: string[] = [
    // see https://christianwood.net/posts/csv-file-upload-validation/
    'text/plain',
    'text/x-csv',
    'application/vnd.ms-excel',
    'application/csv',
    'application/x-csv',
    'text/csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
    'text/tab-separated-values',
  ];

  public isStepValid = new Map<string, boolean | undefined>([
    ['nameEntered', false],
    ['nameValid', false],
    ['industrySelected', false],
    ['professionSelected', false],
    ['specialisationSelected', undefined],
    ['levelFromSelected', false],
    ['levelToSelected', false],
    ['trackSelected', false],
  ]);

  private readonly MAX_CSV_FILE_SIZE = 51200; // 50Kb

  constructor(private authService: AuthService, private playlistService: PlaylistService, private saveFile: SaveFileService, private message: NzMessageService, private modal: NzModalService, private stringValidationPipe: StringValidationPipe) { }

  ngOnInit(): void {
    this.playlistCreated = { name: '', description: '' } as IPlaylist;
    this.incomingPlaylist = { name: '', description: '' } as IPlaylist;

    if (this.playlistEditing) {
      this.switchToEditMode(this.playlistEditing);
      this.mode = EditMode.edit;
    }

    this.nameFocus();
  }

  get isPlaylistNameValid(): boolean {
    return !this.playlistNameError && !this.nameShowError;
  }

  get remainingCharacters(): number {
    return Math.max(64 - this.playlistCreated.name.length, 0);
  }

  get nameShowError(): boolean {
    return (!this.playlistCreated.name.trim()) && !this.nameInputBarSelected;
  }

  onPlaylistNameChange() {
    this.playlistNameError = this.stringValidationPipe.transform(this.playlistCreated.name);
    this.validateName();
  }

  nameFocus() {
    this.nameInputBarSelected = true;
    this.isStepValid.set('nameEntered', true);
  }

  nameBlur() {
    this.nameInputBarSelected = false;
    this.validateName();
  }

  validateName(): boolean {
    if (this.playlistNameError) {
      this.isStepValid.set('nameValid', false);
      return false;
    }
    this.isStepValid.set('nameValid', true);
    return true;
  }

  onStepIndexChange(index: number): void {
    this.stepIndex = index;
  }

  validateDescriptionBox(): boolean {
    return true;
  }

  validateImageBox(): boolean {
    return true;
  }

  initSelectedIndustry(industry: string) {
    if (!industry) {
      this.isStepValid.set('industrySelected', false);
      this.isStepValid.set('professionSelected', false);
      this.resetMainSteps();
      throw new Error('CreatePlaylist: initSelectedIndustry: Not cool to pass an undefined industry into the initialisation of the industry');
    }
    this.isStepValid.set('industrySelected', true);
    this.playlistCreated.industry = industry;
  }

  initSelectedProfession(profession: string) {
    if (!profession) {
      this.isStepValid.set('professionSelected', false);
      this.resetMainSteps();
      throw new Error('Not cool to pass an undefined profession into the initialisation of the profession');
    }
    this.isStepValid.set('professionSelected', true);
    this.playlistCreated.profession = profession;
  }

  initSelectedSpecialisation(specialisation: string) {
    if (!specialisation) {
      this.playlistCreated.specialisation = specialisation
      return;
    }
    this.isStepValid.set('specialisationSelected', !(!specialisation));
    this.playlistCreated.specialisation = specialisation;
  }

  initSelectedLevelFrom(levelFrom: number) {
    if (levelFrom === -1) {
      this.isStepValid.set('levelFromSelected', false);
      this.isStepValid.set('levelToSelected', false);
      this.isStepValid.set('trackSelected', false);
      return;
    }
    this.isStepValid.set('levelFromSelected', true);
    this.playlistCreated.levelFrom = levelFrom;
  }

  initSelectedLevelTo(levelTo: number) {
    if (levelTo === -1) {
      this.isStepValid.set('levelFromSelected', false);
      this.isStepValid.set('levelToSelected', false);
      this.isStepValid.set('trackSelected', false);
      return;
    }
    this.isStepValid.set('levelToSelected', true);
    this.playlistCreated.levelTo = levelTo;
  }

  initSelectedTrack(track: ITrack) {
    this.initSelectedTrackName(track.name);
  }

  initSelectedTrackName(trackName: string) {
    if (!trackName) {
      this.isStepValid.set('trackSelected', false);
      throw new Error('Not cool to pass an undefined track into the initialisation of the track');
    }
    this.isStepValid.set('trackSelected', true);
    this.playlistCreated.relatedTrackName = trackName;
  }

  initUploadedImageUrl(imageUrl: string) {
    this.playlistCreated.playlistImageUrl = imageUrl;
  }

  validateAllStepInputs(): boolean | undefined {
    if (this.isPlaylistNameValid &&
      this.isStepValid.get('nameEntered') &&
      this.isStepValid.get('industrySelected') &&
      this.isStepValid.get('professionSelected') &&
      this.isStepValid.get('specialisationSelected') &&
      this.isStepValid.get('trackSelected') &&
      this.isStepValid.get('levelFromSelected') &&
      this.isStepValid.get('levelToSelected')
    ) {
      return true;
    } else {
      return false;
    }
  }

  public uploadCsv = (item: NzUploadFile): boolean => {
    const file = item;
    const isCsv = this.csvMimeTypes.findIndex((t) => t === file.type) !== -1;

    this.showNoticeAboutUpdatingPlaylist = false;

    if (!isCsv) {
      this.message.error('Nice try, but you can only upload a ".CSV" file!', { nzDuration: 5000 });
      return true;
    }

    if (!file?.size) {
      this.message.error('The file and the size were unable to be calculated. Something seems really wrong.', { nzDuration: 5000 });
      return true;
    }

    if (this.uploadedFileValidSize(file.size)) {
      this.fileList = [file];
      this.activitiesCsv = file;
      return false;
    } else {
      this.message.error(`Your file has to be less than ${this.MAX_CSV_FILE_SIZE} bytes.`);
      return true;
    }
  };

  public removeCsv = (item: NzUploadFile): boolean => {
    if (item.status === 'removed') {
      this.activitiesCsv = undefined;
      return true;
    }

    return false;
  };

  public submitPlaylist(): void {
    this.showNoticeAboutUpdatingPlaylist = false;

    if (!this.validateAllStepInputs()) {
      console.error('Playlist controls are not valid and somehow we got to the point where they are submitting the playlist', { nzDuration: 5000 });
      throw new Error('Playlist must be valid before submitting');
    }

    if (this.playlistCreated.relatedTrackName.toLowerCase() === 'all') {
      this.playlistCreated.relatedTrackName = '';
    }

    if (!this.playlistCreated.description) {
      this.showModalWithoutDescription(() => this.createPlaylist());
    } else {
      if (this.mode === EditMode.new) {
        this.createPlaylist();
        return;
      } else {
        throw new Error('We must be in new Mode in order to create a playlist');
      }
    }
  }

  private showModalWithoutDescription(onSuccessWithoutDescription: () => void) {
    // pass in the function that you want to be called if the user is ok with it.
    this.modal.confirm({
      nzTitle: 'Playlist without a description',
      nzContent: 'Are you sure you want to a playlist without a description?<br><br>' +
        '<i>The <b>description</b> is your opportunity to explain why someone might want to undertake this playlist as a part of their goal.</i><br><br>' +
        'We recommend you consider the following:<br>' +
        '<ul>' +
        '<li>Who is this playlist for?</li>' +
        '<li>What is the level of this playlist?</li>' +
        '<li>What does this playlist cover?</li>' +
        '<li>What are the benefits fo this playlist?</li>' +
        '<li>What is a reasonable time to complete?</li>' +
        '</ul><br>' +
        'You can format the playlist description as HTML for the best results.',
      nzCentered: true,
      nzOnOk: () => {
        onSuccessWithoutDescription();
      },
      nzOkText: 'Yes',
      nzCancelText: 'No',
    });
  }

  public submitPlaylistUpdates(): void {
    this.showNoticeAboutUpdatingPlaylist = false;

    if (this.mode !== EditMode.edit) {
      throw new Error('We must be in Edit mode in order to submit updates.');
    }
    if (!this.validateAllStepInputs()) {
      throw new Error('Playlist controls are not valid and somehow we got to the point where they are updating the playlist');
    }
    if (!this.playlistExistingSelectedId) {
      console.error('We must have a valid playlist id when in Edit mode.');
      throw new Error('We must have a valid playlist id when in Edit mode.');
    }

    if ('all' === this.playlistCreated.relatedTrackName.toLowerCase()) {
      this.playlistCreated.relatedTrackName = '';
    }

    const playlistFromForm = this.updateExistingPlaylistFromForm(this.playlistExistingSelectedId);

    if (!this.playlistCreated.description) {
      this.showModalWithoutDescription(() => this.updatePlaylistForm(playlistFromForm));
    } else {
      this.updatePlaylistForm(playlistFromForm);
    }
  }

  private resetMainSteps() {
    this.isStepValid.set('specialisationSelected', false);
    this.isStepValid.set('levelFromSelected', false);
    this.isStepValid.set('levelToSelected', false);
    this.isStepValid.set('trackSelected', false);
  }

  public exportPlaylist(): void {
    if (!this.playlistExistingSelectedId) {
      throw new Error('We really do need a playlist ID if we have any hope of downloading the right one.');
    }

    this.isExporting = true;
    this.playlistService.exportPlaylist(this.playlistExistingSelectedId).subscribe({
      next: (playlistCsv) => {
        const playlist = this.playlistsExisting?.find((pl) => pl.id === this.playlistExistingSelectedId);
        this.message.success(`Boom! We've got the playlist "${playlist?.name}" for you.`, { nzDuration: 5000 });
        this.message.success(`Check your downloads directory`, { nzDuration: 5000 });
        this.saveFile.saveString(`SeaLadder-Playlist-${playlist?.name}v${playlist?.version}.csv`, playlistCsv);
        this.showNoticeAboutUpdatingPlaylist = true;
        this.isExporting = false;
      },
      error: (error) => {
        this.message.error(`Hmmm, we weren't able to export this playlist for some reason. Perhaps, try again?`);
        console.error(`Could not export a playlist template. Error ${error.message}`, { nzDuration: 5000 });
        this.isExporting = false;
      },
    });
  }

  public exportPlaylistTemplate(): void {
    this.isExporting = true;
    this.playlistService.exportPlaylistTemplate().subscribe({
      next: (playlistCsv) => {
        this.message.success('Nice, we\'ve got the playlist template for you. Check your downloads directory', { nzDuration: 5000 });
        this.saveFile.saveString('SeaLadder-Playlist.csv', playlistCsv);
        this.isExporting = false;
      },
      error: (error) => {
        this.message.error(`Error, could not export a playlist template`);
        console.error(`Could not export a playlist template. Error ${error.message}`, { nzDuration: 5000 });
        this.isExporting = false;
      },
    });
  }

  public showPlaylistPickerModal(): void {
    this.showNoticeAboutUpdatingPlaylist = false;

    if (!this.playlistsExisting) {
      this.initExisting();
    }

    this.isVisiblePlaylistPicker = true;
  }

  public handleOk(): void {
    if (!this.playlistExistingSelectedId) {
      throw new Error('Playlist ID MUST be populated if you have aleady clicked the OK button. It shouldn\'t be possible to get here.');
    }

    this.updateFormWithValuesFromPlaylistId(this.playlistExistingSelectedId);
    this.isVisiblePlaylistPicker = false;
  }

  public handleCancel(): void {
    this.isVisiblePlaylistPicker = false;
  }

  private resetAllFields() {
    this.isStepValid.set('nameEntered', false);
    this.isStepValid.set('industrySelected', false);
    this.isStepValid.set('professionSelected', false);
    this.isStepValid.set('specialisationSelected', false);
    this.isStepValid.set('trackSelected', false);
    this.isStepValid.set('levelFromSelected', false);
    this.isStepValid.set('levelToSelected', false)
    this.fileList = [];
    this.showNoticeAboutUpdatingPlaylist = false;
  }

  private createPlaylist(): void {
    const playlist = this.playlistCreated;

    playlist.name = this.playlistCreated.name;
    playlist.description = this.playlistCreated.description;
    playlist.createdById = this.authService.userId;
    this.isSubmittingPlaylist = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.playlistService.createPlaylist(playlist, this.activitiesCsv as any).subscribe({
      next: (res: IPlaylist) => {
        this.message.success(`Nice work, the playlist '${res.name}' is available!`);
        this.playlistCreated = res;
        this.resetAllFields();
        this.handlePlaylistCreated.emit(res);
        this.isSubmittingPlaylist = false;
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 409) {
          const playlistResult = error.error as IPlaylist;
          this.modal.error({
            nzTitle: `Duplicate Playlist Detected.`,
            // eslint-disable-next-line max-len
            nzContent: `You have already created a Playlist called <strong>'${playlistResult.name}'</strong> before.  You can choose to update the previous playlist with this one but keep in mind, you will lose the current values.<br/><br/>
            Update playlist with values from the server?`,
            nzOkText: 'Update playlist',
            nzCancelText: 'Keep Existing Values',
            nzOkDanger: true,
            nzOnOk: () => {
              this.switchToEditMode(playlistResult);
            },
            nzOnCancel: () => console.log('Cancelling updating the form'),
          });
          console.warn(error.message);
        } else if (error.status === 422) {
          this.message.error(`Some errors were found in the file you provided.`, { nzDuration: 5000, nzPauseOnHover: true });
          let message = 'The errors were found in the following lines:';
          for (const errorMessage of error.error as string[]) {
            message = message + '<br><br>&nbsp - &nbsp' + errorMessage;
          }
          this.message.error(message, { nzDuration: 0 });
        } else {
          this.message.error(`Oh no,  something wen't wrong and we couldn't create playlist`, { nzDuration: 5000, nzPauseOnHover: true });
          console.error(`Could not create playlist ${error}`);
        }
        this.isSubmittingPlaylist = false;
      },
    });
  }

  private switchToEditMode(playlistResult: IPlaylist) {
    this.mode = EditMode.edit;
    this.playlistExistingSelectedId = playlistResult.id;
    if (!this.playlistsExisting) {
      // TODO: The incomming playlist is being assigned to the playlistsExisting array, as if it's all the playlists.
      this.playlistsExisting = [playlistResult];
    } else {
      this.playlistsExisting.push(playlistResult);
    }
    // With the following, we copy all of the properties from the playlistResult to the playlistCreated, and then we use that one.
    this.updateFormWithValuesFromPlaylist(playlistResult);
  }

  private updateExistingPlaylistFromForm(playlistId: number): IPlaylist {
    if (!this.playlistsExisting) {
      throw new Error('Playlist MUST be populated in order to update. It doen\'t even make sense if it\'s not.');
    }

    const playlist = this.playlistsExisting?.find((pl) => pl.id === playlistId);

    if (!playlist) {
      throw new Error('Playlist MUST be in the list in order to update. It doen\'t even make sense if it\'s not.');
    }

    playlist.name = this.playlistCreated.name;
    playlist.industry = this.playlistCreated.industry;
    playlist.profession = this.playlistCreated.profession;
    playlist.specialisation = this.playlistCreated.specialisation;
    playlist.levelFrom = this.playlistCreated.levelFrom;
    playlist.levelTo = this.playlistCreated.levelTo;
    playlist.playlistImageUrl = this.playlistCreated.playlistImageUrl;
    playlist.description = this.playlistCreated.description;
    playlist.relatedTrackName = this.playlistCreated.relatedTrackName;

    return playlist;
  }

  private updateFormWithValuesFromPlaylistId(existingPlaylistId: number) {
    const playlist = this.playlistsExisting?.find((pl) => pl.id === existingPlaylistId);

    if (!playlist) {
      throw new Error(`Playlist with ID ${existingPlaylistId} MUST be valid if we are going to update the form with values from a playlist.`);
    }

    if (playlist) {
      this.mode = EditMode.edit;

      // Go and set the industry, profession, specialisation, level range, and related track
      this.updateFormWithValuesFromPlaylist(playlist);
    }
  }

  private updateFormWithValuesFromPlaylist(playlist: IPlaylist) {
    this.incomingPlaylist = playlist;
    if (!this.incomingPlaylist.profession || this.incomingPlaylist.profession.toLocaleLowerCase().includes('all')) {
      this.incomingPlaylist.profession = 'All';
    }
    if (!this.incomingPlaylist.specialisation || this.incomingPlaylist.specialisation.toLocaleLowerCase().includes('all')) {
      this.incomingPlaylist.specialisation = 'All';
    }
    if (!this.incomingPlaylist.relatedTrackName || this.incomingPlaylist.relatedTrackName.toLocaleLowerCase().includes('all')) {
      this.incomingPlaylist.relatedTrackName = 'All';
    }
    this.playlistCreated = JSON.parse(JSON.stringify(this.incomingPlaylist));

    this.initSelectedIndustry(playlist.industry);
    this.initSelectedProfession(playlist.profession ? playlist.profession : 'All');
    this.initSelectedSpecialisation(playlist.specialisation ? playlist.specialisation : 'All');
    this.initSelectedLevelFrom(playlist.levelFrom);
    this.initSelectedLevelTo(playlist.levelTo);
    this.initSelectedTrackName(playlist.relatedTrackName ? playlist.relatedTrackName : 'All');
    this.initUploadedImageUrl(playlist.playlistImageUrl);
  }

  private updatePlaylistForm(playlistFromForm: IPlaylist) {
    this.message.info(`Updating may take a moment as we validate each link.`, { nzDuration: 4500 });
    const playlist = this.playlistsExisting?.find((pl) => pl.id === this.playlistExistingSelectedId);
    if (!playlist) {
      console.error('Playlist id doesn\'t exist in the list of known ones somehow.');
      throw new Error('There really has to be a valid playlist at this point or the world has gone to shit in a tea-cup.');
    }

    playlistFromForm.id = this.playlistExistingSelectedId as number;
    this.isSubmittingPlaylist = true;

    if (playlistFromForm.profession === 'All') {
      playlistFromForm.profession = '';
    }

    if (playlistFromForm.specialisation === 'All') {
      playlistFromForm.specialisation = '';
    }

    if (playlistFromForm.relatedTrackName === 'All') {
      playlistFromForm.relatedTrackName = '';
    }

    this.submitPlaylistUpdatesMetadata(playlistFromForm, playlist);
  }

  private submitPlaylistUpdatesMetadata(playlistFromForm: IPlaylist, playlist: IPlaylist) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const activityCsv = this.activitiesCsv as any;
    this.playlistService.updatePlaylistMetaData(playlistFromForm).subscribe({
      next: (res: IPlaylist) => {
        if (!this.isSubmittingActivities) {
          // if we're still submitting something, then don't tell them it's successful
          if (this.activitiesCsv) {
            this.submitPlaylistUpdatesActivitiesCsv(playlistFromForm, activityCsv);
          } else {
            this.message.success(`Nice work, the playlist '${res.name}' is updated and available!`);
            this.handlePlaylistUpdated.emit(res);
          }
          if (this.isAdminMode) {
            // don't reset the form if we're not in admin mode as it's really disjointing.
            this.resetAllFields();
          }
        }
        Object.assign(playlist, res);

        if (this.playlistsExisting) {
          const i = this.playlistsExisting?.findIndex((p) => p.id === res.id);
          this.playlistsExisting[i] = playlist;
          this.playlistExistingSelectedId = playlist.id;
        }
        this.isSubmittingPlaylist = false;
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 409) {
          const playlistResult = error.error as IPlaylist;
          this.modal.error({
            nzTitle: `Duplicate Playlist Detected.`,
            // eslint-disable-next-line max-len
            nzContent: `Ease up there champ! We can see that you've already got a playlist with the same name <strong>'${playlistResult.name}'</strong>.  We can switch you over to edit the other playlist if you like, but you will lose any changes you've made to this playlist.<br/><br/>
            <i>Note: You'll probably want to reload the page if make changes after switching.</i><br/><br/>
            Switch to edit other playlist?`,
            nzOkText: 'Switch',
            nzCancelText: 'Keep Editing',
            nzCentered: true,
            nzOkDanger: true,
            nzOnOk: () => {
              this.switchToEditMode(playlistResult);
            },
            nzOnCancel: () => console.log('Cancelling switching playlists'),
          });
          console.error(`Failed to update the playlist due to duplicate name. Error: ${error.message}`);
        } else {
          console.error(`Failed to update the playlist. Error: ${error.message}`);
          this.message.error('Updating the Playlist failed. Check you have permission to change it.', { nzDuration: 5000, nzPauseOnHover: true });
        }
        this.isSubmittingPlaylist = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private submitPlaylistUpdatesActivitiesCsv(playlistFromForm: IPlaylist, activityCsv: any) {
    this.isSubmittingActivities = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.playlistService.updatePlaylistActivities(playlistFromForm, activityCsv).subscribe({
      next: (res: IPlaylist) => {
        const length = res.playlistActivitiesVm ? res.playlistActivitiesVm.length : 0;
        this.message.success(`Nice work, the playlist has has been updated to version '${res.version}' with ${length} activities!`, { nzPauseOnHover: true });
        this.fileList = []; // reset the file list as we've just uploaded it and it won't be apparent to the user that if they change the CSV on thei rcomputer it won't be reflected here.
        this.handlePlaylistUpdated.emit(res);
        this.isSubmittingActivities = false;
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 422) {
          let message = 'Some activities failed to update. Errors include:';
          for (const errorMessage of error.error as string[]) {
            message = message + '<br>&nbsp - &nbsp' + errorMessage;
          }
          message = message + '<br><br>The rest of the activities were added though, but it might be worth checking the CSV file to see if you can address it and try again.';
          this.message.warning(message, { nzDuration: 15000 });
        } else {
          console.error(`Failed to update the playlist Activities. Error: ${error.message}`);
          this.message.error(`Failed to update the playlist activities. Check the attached file has the correct format by downloading a template.`, { nzDuration: 5000 });
        }
        this.isSubmittingActivities = false;
      },
    });
  }

  private initExisting() {
    this.loading.existing = true;
    this.playlistService.getPlaylistsCreated().subscribe({
      next: (ret: IPlaylist[]) => {
        this.playlistsExisting = ret;
        this.playlistExistingSelectedId = undefined;

        if (!this.playlistsExisting || this.playlistsExisting.length === 0) {
          this.message.info('It seems like there are no existing playlists');
        }

        this.loading.existing = false;
      },
      error: (error) => {
        console.error(`Failed to initialise the playlists. Error: ${error.message}`);
        this.message.error(`Oh dear, this is embarassing. We weren't able to get the industries. Check your network connection and try again in a few moments`, { nzDuration: 5000 });
        this.loading.existing = false;
      },
    });
  }

  private uploadedFileValidSize(fileSize: number) {
    return fileSize <= this.MAX_CSV_FILE_SIZE;
  }
}
