import { Component, Input } from '@angular/core';
import { IGuild } from '../../models/guild.model';

@Component({
  selector: 'sl-guild-details',
  templateUrl: './guild-details.component.html',
  styleUrls: ['./guild-details.component.less'],
})
export class GuildDetailsComponent {
  @Input() guild: IGuild = {} as IGuild;
  constructor() {}
}
