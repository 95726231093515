import { AuthService } from './../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { GoalService } from '../../../services/goal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { PlaylistService } from 'src/app/services/playlist.service';
import { IPlaylist } from 'src/app/models/playlist.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'sl-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.less'],
})
export class AuthCallbackComponent implements OnInit {
  error = false;

  constructor(private authService: AuthService, public router: Router, public route: ActivatedRoute, private goalService: GoalService, private playlistService: PlaylistService, private localStorageService: LocalStorageService) { }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async ngOnInit() {
    const fragment = this.route.snapshot.fragment;
    const err = fragment ? fragment.indexOf('error') : -1;
    if (undefined === this.route.snapshot || err >= 0) {
      // console.error('*** AuthCallbackComponent: initial state is in error. Navigating to landing.');
      this.error = true;
      this.router.navigate(['landing']);
      return;
    }

    await this.authService.completeAuthentication();

    // console.log(`*** AuthCallbackComponent: this.authService.isAuthenticated is: ${JSON.stringify(this.authService.isLoggedIn())}`);

    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['landing']);
    } else if (!this.goalService.isInitialised()) {
      this.redirectRouteBasedOnGoals();
    }

    this.updateOnGoalsChanged();
  }

  private updateOnGoalsChanged() {
    this.goalService.getGoalsUpdatedEvent().subscribe({
      next: () => {
        this.redirectRouteBasedOnGoals();
        // console.log('*** AuthCallbackComponent: Stopped loading goals');
      },
      error: (error: HttpErrorResponse) => {
        console.error(`*** AuthCallbackComponent: Failed to get goals updating event. Error: ${error}`);
      }
    });
  }

  private redirectRouteBasedOnGoals() {
    if (this.goalService.isInitialised()) {
      const goals = this.goalService.getGoals();

      if (goals.length > 0) {
        // console.log('*** AuthCallbackComponent: Has goals, re-directing to profile');
        const previousRoute = this.localStorageService.get<string>('previousRoute');

        if (previousRoute) {
          this.router.navigate([previousRoute]);
          this.localStorageService.remove('previousRoute');
        } else {
          this.router.navigate(['profile/overview']);
        }
      } else {
        // console.log('*** AuthCallbackComponent: Has NO goals, re-directing to goals');
        const storedPlaylistDetails = this.localStorageService.get<string>('details-for-playlist-goal');
        const invitationCode = this.localStorageService.get<string>('invite-from-playlist-link');

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion  
        if (storedPlaylistDetails && invitationCode) {
          const detailsForPlaylistGoal = JSON.parse(storedPlaylistDetails);

          if (detailsForPlaylistGoal.playlistId) {
            if (detailsForPlaylistGoal.profession && detailsForPlaylistGoal.specialisation && detailsForPlaylistGoal.level) {
              this.localStorageService.remove('details-for-playlist-goal');
              this.localStorageService.remove('invite-from-playlist-link');
              this.router.navigate(['goal'], {
                queryParams: {
                  playlistId: detailsForPlaylistGoal.playlistId,
                  industry: detailsForPlaylistGoal.industry,
                  profession: detailsForPlaylistGoal.profession,
                  specialisation: detailsForPlaylistGoal.specialisation,
                  level: detailsForPlaylistGoal.level,
                  invitation: invitationCode
                }
              });
            } else {
              this.playlistService.getPlaylist(detailsForPlaylistGoal.playlistId).subscribe({
                next: (res: IPlaylist) => {
                  this.localStorageService.remove('invite-from-playlist-link');
                  this.router.navigate(['goal'], {
                    queryParams: {
                      playlistId: detailsForPlaylistGoal.playlistId,
                      industry: res.industry,
                      profession: res.profession,
                      specialisation: res.specialisation,
                      level: res.levelFrom,
                      invitation: invitationCode
                    }
                  });
                },
                error: (error: HttpErrorResponse) => {
                  console.error(`*** AuthCallbackComponent: Failed to get playlist goal details updating event. Error: ${error}`);
                }
              });
            }
          }

        } else {
          this.router.navigate(['goal']);
        }
      }
    }
  }
}
