import { Component, OnInit } from '@angular/core';
import { InviteService } from 'src/app/services/invite.service';
import { IInvitation } from 'src/app/models/invitation.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IInviteCode } from 'src/app/models/invite-code.model';
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { IUserProfileSubscription } from 'src/app/models/user-profile.model';
import { IWaitlist } from 'src/app/models/waitlist';
import { HttpErrorResponse } from '@angular/common/http';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

interface PersonToDeleteStatus {
  id: number;
  status: boolean;
}
@Component({
  selector: 'sl-invitation-code',
  templateUrl: './invitation-code.component.html',
  styleUrls: ['./invitation-code.component.less'],
})
export class InvitationCodeComponent implements OnInit {
  code: IInviteCode = {
    url: '',
    email: '',
  };

  isGeneratingInvitations = false;
  invitationAll: IInvitation[] = new Array<IInvitation>();
  enabledInactiveInvitationCodes = true;
  invitationsFiltered: IInvitation[] = [] as IInvitation[];
  users: IUserProfileSubscription[] = [] as IUserProfileSubscription[];
  usersRequesting = [] as IUserProfileSubscription[];
  waitlist: IWaitlist[] = [] as IWaitlist[];
  enabledRequestStatus = false;
  checkedItems: number[] = [];
  indeterminate = false;
  checked = false;
  personSelectedToBeDeleted: PersonToDeleteStatus[] = [];
  confirmModal?: NzModalRef;

  constructor(private inviteService: InviteService, private accountDetailsService: AccountDetailsService, private modal: NzModalService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.getInvitations();
    this.getUsers();
    this.getWaitlistPeople();
  }

  getUsers() {
    this.accountDetailsService.getBasicUsers(false).subscribe({
      next: (res) => {
        this.users = res;
        this.usersRequesting = [];

        for (const i of this.users) {
          // for each entry of the users array, call a backend method that determines if the user is requesting invites.
          this.inviteService.getIsUserRequestingInvitation(i.id).subscribe({
            next: (resp) => {
              if (resp) {
                // responds with a boolean of true if the user has requested an invation
                this.usersRequesting.push(i); // as soon as you leave each subscribe, this becomes undefined, so it breaks
              }
            },
            error: (error) => {
              console.error(`*** Failed to Retrieve Invitation Requests. Error: ${error}`);
            }
          }
          );
        }
      },
      error: (error) => {
        console.error(`*** Failed to Retrieve Users. Error: ${error}`);
      },
    });
  }

  getInvitations() {
    this.inviteService.getAllInvitations().subscribe(
      (res) => {
        this.invitationAll = res;
        this.filterCodesRequestingAllocation();
      },
      (error) => {
        console.error(`*** Failed to Retrieve Invitations. Error: ${error}`);
      }
    );
  }

  hoverOverItem(index: number) {
    this.personSelectedToBeDeleted[index].status = true;
  }

  unhoverOverItem(index: number) {
    this.personSelectedToBeDeleted[index].status = false;
  }

  showConfirm(person: IWaitlist, index: number) {
    this.personSelectedToBeDeleted[index].status = true;
    this.onItemChecked(person, true);
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Are you sure you want to delete this user',
      nzOnOk: () => {
        this.deleteWaitlistUser(person);
        this.personSelectedToBeDeleted[index].status = false;
      },
      nzOnCancel: () => {
        this.personSelectedToBeDeleted[index].status = false;
        this.onItemChecked(person, false);
        this.modal.closeAll();
      },
      nzClosable: true,
    });
  }

  deleteWaitlistUser(person: IWaitlist) {
    this.inviteService.deleteWaitlistUser(person).subscribe({
      next: () => {
        const updatedWaitlist = this.waitlist.filter((userWaitlistPerson) => userWaitlistPerson.id !== person.id);
        this.message.success('Person taken off waitlist');
        this.waitlist = updatedWaitlist;
      },
      error: () => {
        this.message.error(`An error occurred. Couldn't take person off the waitlist`);
      }
    });
  }

  getWaitlistPeople() {
    this.inviteService.getWaitlist().subscribe((req) => {
      this.waitlist = req;
      req.forEach((user) => {
        this.personSelectedToBeDeleted.push({ id: user.id, status: false });
      });
    });
  }

  getNumberWaitingString() {
    return `Waitlist (${this.waitlist.length})`;
  }

  generateInvitations() {
    this.isGeneratingInvitations = true;
    this.inviteService.generateInvitations(this.code.email).subscribe(
      (res) => {
        this.message.success('Invitations added successfully!');
        this.isGeneratingInvitations = false;
        // console.log(`*** GenerateInvitationsByEmail returned with ${JSON.stringify(res)}`);
        this.invitationAll.push(...res);
      },
      (error) => {
        this.message.error('An error occurred');
        this.isGeneratingInvitations = false;
        console.error(`*** Failed to Generate Invite Codes. Error: ${error}`);
      }
    );
  }

  GenerateInvitationForEmail() {
    this.isGeneratingInvitations = true;
    this.inviteService.generateInvitationForEmail(this.code.email).subscribe({
      next: (res) => {
        this.message.success('Invitation added successfully!');
        this.invitationAll.push(res);
        for (const x in this.waitlist) {
          if (this.checkedItems.includes(this.waitlist[x].id)) {
            this.waitlist[x].email = this.code.email;
          }
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 409) {
          this.message.info('An account already exists for this Email');
          this.getInvitations();
        } else {
          this.message.error('An unknown error has occurred when adding an reserved invitation');
          console.error(`*** Failed to Generate Invite Codes. Error: ${err}`);
        }
      },
      complete: () => {
        this.isGeneratingInvitations = false;
      }
    });
  }

  generateMultipleInvitations() {
    for (const x in this.checkedItems) {
      if (x != null) {
        // this.code.email = this.checkedItems[x];

        const userId = this.checkedItems[x];
        const user = this.waitlist.find(u => u.id === userId);
        if (user) {
          this.code.email = user.email;
        }
        this.GenerateInvitationForEmail();
      }
    }
  }

  updateCheckedSet(item: IWaitlist, checked: boolean): void {
    if (checked && !this.checkedItems.includes(item.id)) {
      this.checkedItems.push(item.id);
    } else if (!checked && this.checkedItems.includes(item.id)) {
      const index = this.checkedItems.findIndex((element) => element === item.id);
      this.checkedItems.splice(index, 1);
    }
  }

  onItemChecked(user: IWaitlist, checked: boolean): void {
    this.updateCheckedSet(user, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean) {
    this.waitlist.forEach((item) => this.updateCheckedSet(item, checked));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.waitlist.every((item) => this.checkedItems.includes(item.id));
    this.indeterminate = this.waitlist.some((item) => this.checkedItems.includes(item.id)) && !this.checked;
  }

  setEnableInactive(enabled: boolean) {
    this.enabledInactiveInvitationCodes = enabled;
  }

  filterCodesRequestingAllocation() {
    this.invitationsFiltered = this.enabledInactiveInvitationCodes ? this.invitationAll.filter((x) => x.requestStatus === true) : this.invitationAll;
  }

  updateNotes(invitation: IInvitation) {
    if (invitation.notes !== null) {
      this.inviteService.updateInvitationNotes(invitation).subscribe(
        () => {
          // console.log(`*** Invitation Notes for invite ${invitation.inviteCode} Updated Successfully`);
        },
        (error) => {
          this.message.error('An error occurred');
          console.error(`*** Failed to Update Invitation Notes. Error: ${error}`);
        }
      );
    }
  }

  updateInvitationUses(invitation: IInvitation) {
    this.inviteService.updateInvitationUses(invitation).subscribe(
      () => {
        console.log(`*** Invitation Uses for invite ${invitation.inviteCode} Updated Successfully`);
      },
      (error) => {
        this.message.error('Error: Used > Allocated, or Allocated higher than maximum');
        console.error(`*** Failed to Update Invitation Uses. Error: ${error}`);
      }
    );
  }

  toggleRequestStatus(invitation: IInvitation) {
    this.inviteService.toggleRequestStatus(invitation).subscribe(
      (res) => {
        if (res) {
          this.message.success('Allocation request sent... but youre already on the admin page');
          console.log(`*** Allocation request for ${invitation.inviteCode} sent successfully`);
        } else {
          this.message.success('Allocation request cancelled');
          console.log(`*** Allocation request for ${invitation.inviteCode} cancelled`);
        }
      },
      (error) => {
        this.message.error('Invitation Invalid');
        console.error(`*** Failed to Update Invitation Request Status. Error: ${error}`);
      }
    );
  }
}
