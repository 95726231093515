import { Component, Input } from '@angular/core';
import { ProfilePageModel } from '../../../models/profile-page.model';

@Component({
  selector: 'sl-profile-activities',
  templateUrl: './profile-activities.component.html',
  styleUrls: ['./profile-activities.component.less']
})
export class ProfileActivitiesComponent {
  @Input() activityStatus!: ProfilePageModel[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colorMap: any = {
    Completed: 'completed',
    'In Progress': 'inprogress',
    Scheduled: 'scheduled'
  };

  constructor() { }
}
