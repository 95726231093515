import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

// models
import { IRecommendation } from '../models/recommendation.model';
import { IPlaylist } from '../models/playlist.model';
import { ISuggestedAction } from '../models/suggested-action.model';

@Injectable({
  providedIn: 'root',
})
export class RecommendationServiceData {
  private recommendedActivities = new Map();
  private recommendedActivitiesUpdated = new Map();

  private recommendedPlaylists = new Map();
  private recommendedPlaylistsUpdated = new Map();

  private recommendedActions = new Map();
  private recommendedActionsUpdated = new Map();

  constructor() {}

  public isInitialisedRecommendedActivities(goalId: number): boolean {
    if (this.recommendedActivities.size === 0) {
      return false;
    }

    const activities = this.recommendedActivities.get(goalId);
    return activities ? true : false;
  }

  public isInitialisedRecommendedPlaylists(goalId: number): boolean {
    if (this.recommendedPlaylists.size === 0) {
      return false;
    }

    return this.recommendedPlaylists.get(goalId) ? true : false;
  }

  public isInitialisedRecommendedActions(goalId: number): boolean {
    if (this.recommendedActions.size === 0) {
      return false;
    }

    const actions = this.recommendedActions.get(goalId);
    return actions ? true : false;
  }

  // initialises the dataset and notifies any listeners that there are changes.
  public initialiseRecommendedActivities(goalId: number, recommendedActivities: IRecommendation[]) {
    if (!recommendedActivities) {
      throw new Error('initialiseRecommendedActivities recommendedActivities cannot set to an undefined value');
    }

    if (!this.recommendedActivities) {
      throw new Error('initialiseRecommendedActivities recommendedActivities should be defined');
    }

    if (this.recommendedActivities.get(goalId)) {
      throw new Error(`initialiseRecommendedActivities recommendedActivities for goalId ${goalId} should not be trying to overwrite existing activities`);
    }

    this.recommendedActivities.set(goalId, recommendedActivities);
    this.initialiseRecommendedActivitiesUpdated(goalId);
  }

  public initialiseRecommendedPlaylists(goalId: number, recommendedPlaylists: IPlaylist[]) {
    if (!recommendedPlaylists) {
      throw new Error('initialiseRecommendedPlaylists recommendedPlaylists cannot set to an undefined value');
    }

    if (!this.recommendedPlaylists) {
      throw new Error('initialiseRecommendedPlaylists recommendedPlaylists should be defined');
    }

    if (this.recommendedPlaylists.get(goalId)) {
      throw new Error(`initialiseRecommendedActivities recommendedPlaylists for goalId ${goalId} should not be trying to overwrite existing playlists`);
    }

    this.recommendedPlaylists.set(goalId, recommendedPlaylists);
    this.initialiseRecommendedPlaylistsUpdated(goalId);
  }

  public initialiseRecommendedActions(goalId: number, recommendedActions: ISuggestedAction[]) {
    if (!recommendedActions) {
      throw new Error('initialiseRecommendedAction recommendedActions cannot set to an undefined value');
    }

    if (!this.recommendedActions) {
      throw new Error('initialiseRecommendedActions recommendedActions should be defined');
    }

    if (this.recommendedActions.get(goalId)) {
      throw new Error(`initialiseRecommendedActons recommendedActions for goalId ${goalId} should not be trying to overwrite existing actions`);
    }

    this.recommendedActions.set(goalId, recommendedActions);
    this.initialiseRecommendedActionsUpdated(goalId);
  }

  public getRecommendedActivities(goalId: number): IRecommendation[] {
    if (!this.isInitialisedRecommendedActivities(goalId)) {
      throw new Error(`A call to get activities, but it's not even initialised yet for goal: ${goalId}`);
    }

    if (this.recommendedActivities === undefined) {
      throw new Error('undefined recommendedActivities');
    }

    const recommendations = this.recommendedActivities.get(goalId);

    if (!recommendations) {
      throw new Error(`The activity isn't even defined for goalID: ${goalId}`);
    }

    return recommendations;
  }

  public getRecommendedPlaylists(goalId: number): IPlaylist[] {
    if (!this.isInitialisedRecommendedPlaylists(goalId)) {
      throw new Error(`A call to get playlists, but it's not even initialised yet for goal: ${goalId}`);
    }

    if (this.recommendedPlaylists === undefined) {
      throw new Error('undefined recommendedPlaylists');
    }

    const recommendations = this.recommendedPlaylists.get(goalId);

    if (!recommendations) {
      throw new Error(`The playlist isn't even defined for goalID: ${goalId}`);
    }

    return recommendations;
  }

  public getRecommendedActions(goalId: number): ISuggestedAction[] {
    if (!this.isInitialisedRecommendedActions(goalId)) {
      throw new Error(`A call to get Actions, but it's not even initialised yet for goal: ${goalId}`);
    }

    if (this.recommendedActions === undefined) {
      throw new Error('undefined recommendedActions');
    }

    const recommendations = this.recommendedActions.get(goalId);

    if (!recommendations) {
      throw new Error(`The Action isn't even defined for goalID: ${goalId}`);
    }

    return recommendations;
  }

  public getRecommendedActivitiesUpdatedEvent(goalId: number): Observable<[goalId: number, activityId: number]> {
    if (this.recommendedActivitiesUpdated === undefined) {
      throw new Error('recommendedActivities is expected to be defined');
    }

    let changed = this.recommendedActivitiesUpdated.get(goalId) as Subject<[goalId: number, activityId: number]>;

    if (!changed) {
      changed = this.initialiseRecommendedActivitiesUpdated(goalId);
    }

    return changed.asObservable();
  }

  public getRecommendedPlaylistUpdatedEvent(goalId: number): Observable<[goalId: number, playlistId: number]> {
    if (this.recommendedPlaylistsUpdated === undefined) {
      throw new Error('recommendedPlaylistsUpdated is expected to be defined');
    }

    let changed = this.recommendedPlaylistsUpdated.get(goalId) as Subject<[goalId: number, playlistId: number]>;

    if (!changed) {
      changed = this.initialiseRecommendedPlaylistsUpdated(goalId);
    }

    return changed.asObservable();
  }

  public getRecommendedActionUpdatedEvent(goalId: number): Observable<[goalId: number, actionId: number]> {
    if (this.recommendedActionsUpdated === undefined) {
      throw new Error('recommendedActionsUpdated is expected to be defined');
    }

    let changed = this.recommendedActionsUpdated.get(goalId) as Subject<[goalId: number, activityId: number]>;

    if (!changed) {
      changed = this.initialiseRecommendedActionsUpdated(goalId);
    }

    return changed.asObservable();
  }

  // Foreach of these goals, get all activities that match the given activityId and set it's addedValue
  public setAddedActivity(goalId: number, activityId: number, addedValue: boolean) {
    this.recommendedActivities.forEach((activities: IRecommendation[], keyGoalId: number) => {
      const activity = activities.find((a) => a.activityId === activityId);
      if (undefined !== activity && activity.added !== addedValue) {
        activity.added = addedValue;
        // console.log(`setAddedActivity is updated the added flag on all Activities for goal ${goalId}`, activities);
        this.notifyActivityChanged(keyGoalId, activityId);
      }
    });
  }

  public setAddedPlaylist(goalId: number, playlistId: number, addedValue: boolean) {
    // to start with, let's set all other playlists with the same ID (in other goals) as also added, so iterate through each of the playlists in each of the goals
    this.setAddedValueForAllPlaylistsWithId(goalId, playlistId, addedValue);

    // We've added a playlist, so we need to go through each activity in the playlist and look through all of the activities in all of the other goals and mark them as added too
    this.updateMatchingActivitiesForAllActivitiesInThisPlaylist(goalId, playlistId);
  }

  public setAddedAction(goalId: number, actionId: number, addedValue: boolean) {
    this.recommendedActions.forEach((actions: ISuggestedAction[], keyGoalId: number) => {
      const action = actions.find((a) => a.id === actionId);
      if (undefined !== action && action.added !== addedValue) {
        action.added = addedValue;
        // console.log(`setAddedAction is updated the added flag on all Actions for goal ${goalId}`, action);
        this.notifyActionChanged(keyGoalId, actionId);
      }
    });
  }

  public flagRecommendedActivity(recommendedActivity: IRecommendation) {
    this.recommendedActivities.forEach((activities: IRecommendation[]) => {
      const activity = activities.find((a) => a.activityId === recommendedActivity.activityId);
      if (activity !== undefined) {
        activity.flagged = !activity.flagged;
      }
    });
  }

  private initialiseRecommendedActivitiesUpdated(goalId: number): Subject<[goalId: number, activityId: number]> {
    const activities = this.recommendedActivitiesUpdated.get(goalId);
    if (!activities) {
      this.recommendedActivitiesUpdated.set(goalId, new Subject<[goalId: number, activityId: number]>());
    }

    return this.recommendedActivitiesUpdated.get(goalId);
  }

  private initialiseRecommendedPlaylistsUpdated(goalId: number): Subject<[goalId: number, playlistId: number]> {
    const playlists = this.recommendedPlaylistsUpdated.get(goalId);
    if (!playlists) {
      this.recommendedPlaylistsUpdated.set(goalId, new Subject<[goalId: number, playlistId: number]>());
    }

    return this.recommendedPlaylistsUpdated.get(goalId);
  }

  private initialiseRecommendedActionsUpdated(goalId: number): Subject<[goalId: number, actionId: number]> {
    const actions = this.recommendedActionsUpdated.get(goalId);
    if (!actions) {
      this.recommendedActionsUpdated.set(goalId, new Subject<[goalId: number, actionId: number]>());
    }

    return this.recommendedActionsUpdated.get(goalId);
  }

  private updateMatchingActivitiesForAllActivitiesInThisPlaylist(goalId: number, playlistId: number) {
    const playlists = this.recommendedPlaylists.get(goalId) as IPlaylist[];
    const playlistForId = playlists.find((a) => a.id === playlistId);

    // if there aren't any activityies, then don't bother. Not sure why we allow playlists with no activities, but it might be possible in a test scenario
    if (playlistForId && playlistForId.playlistActivitiesVm) {
      const goalIds = Array.from(this.recommendedActivities.keys()) as Array<number>;
      const playlistActivities = playlistForId.playlistActivitiesVm;

      for (const playlistActivity of playlistActivities) {
        for (const gId of goalIds) {
          this.setAddedActivity(gId, playlistActivity.id, true);
        }
      }
    }
  }

  private setAddedValueForAllPlaylistsWithId(goalId: number, playlistId: number, addedValue: boolean) {
    this.recommendedPlaylists.forEach((playlists: IPlaylist[], keyGoalId: number) => {
      const playlist = playlists.find((pl) => pl.id === playlistId);
      if (undefined !== playlist && playlist.added !== addedValue) {
        playlist.added = addedValue;
        // console.log(`Marking playlist ${playlistId} added for goal id:`, keyGoalId, playlists);
        this.notifyPlaylistChanged(keyGoalId, playlistId);
      }
    });
  }

  private notifyActivityChanged(goalId: number, activityId: number) {
    const updatedActivities = this.recommendedActivitiesUpdated.get(goalId) as Subject<[goalId: number, activityId: number]>;
    if (updatedActivities) {
      updatedActivities.next([goalId, activityId]);
    }
  }

  private notifyPlaylistChanged(goalId: number, playlistId: number) {
    const updatedPlaylists = this.recommendedPlaylistsUpdated.get(goalId) as Subject<[goalId: number, playlistId: number]>;
    if (updatedPlaylists) {
      updatedPlaylists.next([goalId, playlistId]);
    }
  }

  private notifyActionChanged(goalId: number, actionId: number) {
    const updatedActions = this.recommendedActionsUpdated.get(goalId) as Subject<[goalId: number, actionId: number]>;
    if (updatedActions) {
      updatedActions.next([goalId, actionId]);
    }
  }
}
