import { StatusKind } from '../types/status-kind.type';

export const getUserPlaylistStatus = (playlist: IUserPlaylist): StatusKind => {
  if (playlist.allCompleted) {
    return StatusKind.Completed;
  } else {
    // TODO: Put the REAL logic in here. Cover NotStarted, and Archived.
    return StatusKind.InProgres;
  }
};

export interface IUserPlaylist {
  id: number;
  playlistId: number;
  playlistTitle: string;
  dateCreated: Date;
  dateDue?: Date;
  isArchived?: boolean;
  userId: string;
  goalId: number;
  userActivityIds: number[];
  playlistImageUrl: string;
  playlistVersion: number;
  latestPlaylistVersion: number;
  availablePlaylistVersions: number[];
  allCompleted?: boolean;
  numCompleted?: number;
  numTotal?: number;
  totalUsed: number;
}

export interface IUserPlaylistState extends IUserPlaylist {
  expanded: boolean;
  updatingVersion: boolean;
  completedActivitiesBadgeView: boolean;
}
