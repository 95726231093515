import { Component, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// services
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { UserPlaylistService } from '../../../services/user-playlist.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PlaylistService } from 'src/app/services/playlist.service';
import { GoalService } from 'src/app/services/goal.service';

// models
import { IGoal } from 'src/app/models/user-goal.model';
import { IGuild, encodeUrlComponent } from '../../models/guild.model';
import { IPlaylist } from 'src/app/models/playlist.model';
import { ISharedPlaylist } from '../../../models/shared-playlist.model';
import { EGoalSourceType } from 'src/app/types/goal-source-type.type';
import { IGuildMember } from '../../models/guild-member.model';
import { SharedPlaylistExpandedState } from 'src/app/components/profile-v2/profile-playlists/my-playlists/my-playlists.component';
import { LearningService } from 'src/app/services/learning.service';
import { ILearningType } from 'src/app/models/learning-type.model';

@Component({
  selector: 'sl-shared-playlist',
  templateUrl: './shared-playlist.component.html',
  styleUrls: ['./shared-playlist.component.less'],
})

export class SharedPlaylistComponent implements OnChanges {
  @ViewChild('container') container!: ElementRef;
  @Input() guild!: IGuild;
  @Input() guildUrlName!: string;
  @Input() selectedGuildMembers: IGuildMember[] = [];
  @Input() selectMode!: boolean;
  @Input() isInteractable = false;
  learningTypes!: ILearningType[];
  sharedPlaylists: IPlaylist[] = [];
  sharedPlaylistsForGuild: ISharedPlaylist[] = [];
  selectedSharedPlaylist: ISharedPlaylist = {} as ISharedPlaylist;
  isLoadingUserSharedPlaylists = false;
  isLoadingGuildSharedPlaylists = false;

  returnedPlaylistGoals: IGoal[] = [];
  selectedGoal!: IGoal | null;

  isAddingPlaylist = false;
  goalSelectorVisible = false;
  goalSelectorComponentType = EGoalSourceType;
  readonly placeholderImage = 'https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80';

  sharedPlaylistCardExpandedStates = new Map<number, boolean>();

  constructor(
    private message: NzMessageService,
    private playlistService: PlaylistService,
    private userPlaylistService: UserPlaylistService,
    private userActivitiesService: UserActivitiesService,
    private goalService: GoalService,
    private learningService: LearningService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.guild && !this.selectMode) {
      if (this.guild.id) {
        this.getSharedPlaylistsforGuild();
        this.getLearningTypes();
      } else {
        this.sharedPlaylistsForGuild = [];
      }
      this.isAddingPlaylist = false;
    }

    if (changes.selectMode) {
      if (!this.selectMode) {
        this.getSharedPlaylistsforGuild();
      }
    }
  }

  encodeUrlComponent(guildName: string) {
    return encodeUrlComponent(guildName);
  }

  cancelAdd(): void {
    this.isAddingPlaylist = false;
  }

  refreshSharedPlaylists() {
    this.getSharedPlaylistsforGuild();
  }

  sharePlaylist(): void {
    this.isAddingPlaylist = true;
  }

  scrollBackToCard() {
    this.container.nativeElement.scrollIntoView();
  }

  handleReturnedPlaylist(playlist: IPlaylist) {
    this.sharePlaylistWithGuild(playlist.id);
    this.isAddingPlaylist = false;
    setTimeout(() => {
      this.scrollBackToCard();
      this.getSharedPlaylistsforGuild();
    });
  }

  getSharedPlaylistImage(sharedPlaylist: ISharedPlaylist) {
    if (sharedPlaylist.playlist.playlistImageUrl) {
      return sharedPlaylist.playlist.playlistImageUrl;
    } else {
      return this.placeholderImage;
    }
  }

  getPlaylistDetails(playlist: IPlaylist): string {
    if (!playlist) {
      return 'INVALID';
    }

    const levelFrom = playlist.levelFrom ? playlist.levelFrom.toLocaleString() : '*';
    const levelTo = playlist.levelTo ? ' - ' + playlist.levelTo.toLocaleString() : '';
    const specialisation = playlist.specialisation ? playlist.specialisation : '';
    return `${specialisation} [${levelFrom}${levelTo}], by ${this.getPlaylistAuthor(playlist)}`;
  }

  getPlaylistAuthor(playlist: IPlaylist): string {
    let author = '';
    if (playlist) {
      author = playlist.createdByFirstName + ' ' + playlist.createdByLastName;
      return author;
    }
    return author;
  }

  getSharedPlaylistsAdded() {
    const selectedGuildMemberIds = this.selectedGuildMembers.map(member => member.userId);
    this.userPlaylistService.getSharedPlaylistsAddedByGuildUsers(this.guild.id, selectedGuildMemberIds).subscribe({
      next: (res) => {
        res = res.map(sharedPlaylist => ({
          ...sharedPlaylist,
          isPlaylistCardExpanded: this.getSharedPlaylistExpandedCardState(sharedPlaylist.id)
        }));
        this.sharedPlaylistsForGuild = this.sortPlaylistsForGuild(res);
      },
      error: (error) => {
        console.error(`Failed to get the shared playlists added by users. Error: ${error.message}`);
      }
    });
  }

  handleRequestToAddSharedPlaylist(sharedPlaylist: ISharedPlaylist) {
    this.selectedSharedPlaylist = sharedPlaylist;
    const queryParams = this.route.snapshot.queryParams;
    this.goalService.getGoalsForPlaylist(this.selectedSharedPlaylist.playlist.id).subscribe((goals: IGoal[]) => {
      this.returnedPlaylistGoals = goals;
      if (queryParams.goalId) {
        const goalId = Number(queryParams.goalId);
        const goalIndex = goals.findIndex((g) => g.id === goalId);
        this.selectedGoal = goals[goalIndex];
      } else {
        this.selectedGoal = null;
      }

      if (queryParams.playlistId && queryParams.goalId) {
        this.closeGoalSelectionModal();
      }

      if (this.returnedPlaylistGoals.length !== 1) {
        this.openGoalSelectionModal();
      } else {
        this.selectedGoal = this.returnedPlaylistGoals[0];
        this.addUserPlaylistFromSharedPlaylist();
      }
    });
  }

  selectGoal(goal: IGoal) {
    this.selectedGoal = goal;
  }

  openGoalSelectionModal() {
    this.goalSelectorVisible = true;
  }

  closeGoalSelectionModal() {
    this.goalSelectorVisible = false;
    this.router.navigate([`guilds/home/${this.guildUrlName}/playlists`]);
  }

  selectGoalFromGoalSelectionModal() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.addUserPlaylistFromSharedPlaylist();
    this.closeGoalSelectionModal();
  }

  getSharedPlaylistProfession() {
    return this.selectedSharedPlaylist.playlist.profession;
  }

  getSharedPlaylistSpecialisation() {
    return this.selectedSharedPlaylist.playlist.specialisation;
  }

  getSharedPlaylistLevel() {
    return this.selectedSharedPlaylist.playlist.levelFrom;
  }

  userSuggestedPlaylistsAvailable(): boolean {
    return !(!this.sharedPlaylists.length);
  }

  handleSharedPlaylistExpandedCardState(sharedPlaylistExpandedState: SharedPlaylistExpandedState) {
    const isExpanded = this.sharedPlaylistCardExpandedStates.get(sharedPlaylistExpandedState.sharedPlaylistId);
    if (isExpanded !== sharedPlaylistExpandedState.isExpanded) {
      this.sharedPlaylistCardExpandedStates.set(sharedPlaylistExpandedState.sharedPlaylistId, sharedPlaylistExpandedState.isExpanded);
    }
  }

  getSharedPlaylistExpandedCardState(sharedPlaylistId: number): boolean {
    return this.sharedPlaylistCardExpandedStates.get(sharedPlaylistId) ?? false;
  }

  updatedSharedPlaylistVersion(sharedPlaylist: ISharedPlaylist): void {
    this.playlistService.updateSharedPlaylistVersion(sharedPlaylist.id, this.guild.id, sharedPlaylist.playlist.id).subscribe({
      next: (res: ISharedPlaylist) => {
        if (res.currentPlaylistVersion === res.latestPlaylistVersion) {
          this.message.success("This playlist has been successfully updated to the latest version!");
        } else {
          this.message.success(`This playlist has been successfully updated to version ${res.currentPlaylistVersion}!`);
        }
        this.getSharedPlaylistsforGuild();
      },
      error: () => {
        this.message.error(`We've lost connection to SeaLadder HQ and can't get the available versions of this playlist. Check network connection?`);
      }
    });
  }

  private getLearningTypes() {
    this.learningService.getLearningTypes().subscribe(lt => {
      this.learningTypes = lt;
    });
  }

  private addUserPlaylistFromSharedPlaylist() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.userPlaylistService.addUserPlaylistFromSharedPlaylist(this.selectedSharedPlaylist.id, this.selectedSharedPlaylist.playlist.id, this.guild.id, this.selectedGoal!.id!).subscribe({
      next: () => {
        this.userActivitiesService.updateActivitiesCache();
        this.getSharedPlaylistsforGuild();
        this.message.success('Nice, you\'ve added the playlist to your profile.');
      },
      error: (error) => {
        console.error(`Failed to add this playlist to your collection. Error: ${error.message}`);
      },
    });
  }

  private sortPlaylistsForGuild(sharedPlaylists: ISharedPlaylist[]) {
    return sharedPlaylists
      .sort((a, b) => {

        // Sort the playlists whether or not they have been added by the user
        const userAlreadyAddedComparison = Number(a.userAlreadyAdded) - Number(b.userAlreadyAdded);
        if (userAlreadyAddedComparison !== 0) {
          return userAlreadyAddedComparison;
        }

        // Sort by the date the playlist was shared last. If all else remains the same, the playlistDateShared will determine the sort order
        const dateComparison = new Date(b.playlistDateShared).getTime() - new Date(a.playlistDateShared).getTime();
        if (dateComparison !== 0) {
          return dateComparison;
        }

        return 0;
      });
  }

  private preventDuplicatePlaylistsForGuilds(sharedPlaylists: ISharedPlaylist[]) {
    const existingPlaylistIds = new Set();
    return sharedPlaylists.filter(sharedPlaylist => {
      if (!existingPlaylistIds.has(sharedPlaylist.playlist.id)) {
        existingPlaylistIds.add(sharedPlaylist.playlist.id);
        return true;
      }
      return false;
    });
  }

  private getSharedPlaylistsforGuild() {
    this.isLoadingGuildSharedPlaylists = true;
    const queryParams = this.route.snapshot.queryParams;
    this.playlistService.getSharedPlaylistsForGuild(this.guild.id).subscribe({
      next: (res: ISharedPlaylist[]) => {
        //We have prevented duplicates on the backend but this is just in case
        const nonDuplicatedPlaylists = this.preventDuplicatePlaylistsForGuilds(res);
        const sortedPlaylists = this.sortPlaylistsForGuild(nonDuplicatedPlaylists);
        this.sharedPlaylistsForGuild = sortedPlaylists;
        if (this.sharedPlaylistsForGuild.length > 0 && queryParams.playlistId && queryParams.goalId) {
          this.getlastSelectedSharedPlaylist();
        }
        this.getUserSuggestedPlaylists();
        this.setSharedPlaylistCardExpandedStates();
        this.isLoadingGuildSharedPlaylists = false;
      },
      error: (error) => {
        console.error(`Failed to get shared playlists for the guild. Error: ${error.message}`);
        this.isLoadingGuildSharedPlaylists = false;
      },
    });
  }

  private setSharedPlaylistCardExpandedStates(): void {
    this.sharedPlaylistsForGuild.forEach(sp => {
      this.sharedPlaylistCardExpandedStates.set(sp.id, false);
    });
  }

  private getlastSelectedSharedPlaylist() {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.playlistId && queryParams.goalId) {
      const playlistId = Number(queryParams.playlistId);
      const playlistIndex = this.sharedPlaylistsForGuild.findIndex((sp) => sp.id === playlistId);
      this.handleRequestToAddSharedPlaylist(this.sharedPlaylistsForGuild[playlistIndex]);
    }
  }

  private sharePlaylistWithGuild(playlistId: number) {
    this.playlistService.sharePlaylistWithGuild(this.guild.id, playlistId).subscribe({
      next: (res) => {
        if (!this.sharedPlaylistsForGuild.includes(res)) {
          this.sharedPlaylistsForGuild.push(res);
          this.message.success('Nice, you\'ve shared this playlist with your guild');
        } else {
          this.message.error('You already have this playlist in the guild');
        }
      },
      error: (error) => {
        console.warn(`Failed to share playlist with the guild. Error: ${error.message}`);
      },
    });
  }

  private getUserSuggestedPlaylists() {
    this.isLoadingUserSharedPlaylists = true;
    this.playlistService.getUserSuggestedPlaylists().subscribe({
      next: (res) => {
        const sharedPlaylistsForGuildArray: number[] = [];
        this.sharedPlaylistsForGuild.forEach((sharedPlaylist) => sharedPlaylistsForGuildArray.push(sharedPlaylist.playlist.id));
        const filteredResults = res.filter((playlist) => !sharedPlaylistsForGuildArray.includes(playlist.id));
        this.sharedPlaylists = filteredResults;
        this.isLoadingUserSharedPlaylists = false;
      },
      error: (error) => {
        console.warn(`Failed to retrieve playlists. Error: ${error.message}`);
        this.isLoadingUserSharedPlaylists = false;
      },
    });
  }
}
