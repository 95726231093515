import { ISubscription } from './../models/subscription.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ESubscriptionStatus, IUserProfileSubscription } from '../models/user-profile.model';
import { IStripeEvent } from '../models/stripe-event.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private endpoint = '/api/Payment/';

  constructor(private http: HttpClient) {}

  getUserSubscription(): Observable<ISubscription> {
    const url = this.endpoint + 'GetUserSubscription';
    return this.http.get<ISubscription>(url);
  }

  changeUserStatus(basicUser: IUserProfileSubscription): Observable<ISubscription> {
    const url = this.endpoint + 'ChangeUserStatus';
    const body = JSON.stringify(basicUser);

    return this.http.post<ISubscription>(url, body, httpOptions);
  }

  getSubscriptionStatus(): Observable<ESubscriptionStatus> {
    const url = this.endpoint + 'GetUserSubscriptionStatus';
    return this.http.get<ESubscriptionStatus>(url);
  }

  getFailedStripeEvents(): Observable<IStripeEvent[]> {
    const url = this.endpoint + 'GetFailedStripeEvents';
    return this.http.get<IStripeEvent[]>(url);
  }

  runFailedStripeEvent(stripeEvent: IStripeEvent): Observable<IStripeEvent> {
    const url = this.endpoint + 'RunFailedStripeEvent';
    const body = JSON.stringify(stripeEvent);
    return this.http.post<IStripeEvent>(url, body, httpOptions);
  }

  ignoreFailedStripeEvent(stripeEvent: IStripeEvent): Observable<IStripeEvent> {
    const url = this.endpoint + 'IgnoreFailedStripeEvent';
    const body = JSON.stringify(stripeEvent);
    return this.http.post<IStripeEvent>(url, body, httpOptions);
  }
}
