import { Component, Input, OnInit } from '@angular/core';
import { EIconSize } from '../types/icon-size';

@Component({
  selector: 'sl-core-skill-icon',
  templateUrl: './core-skill-icon.component.html',
  styleUrls: ['./core-skill-icon.component.less']
})
export class CoreSkillIconComponent implements OnInit {
  @Input() coreSkillIconSize!: EIconSize;
  @Input() tooltipVisible!: boolean;
  @Input() tooltip!: string;
  iconSizeClass!: string;

  coreSkillIconSizes: Map<EIconSize, string> = new Map([
    [EIconSize.Small, "fa-sm"],
    [EIconSize.Medium, "fa-lg"],
    [EIconSize.Large, "fa-xl"],
  ])

  ngOnInit(): void {
    if (this.coreSkillIconSizes.has(this.coreSkillIconSize)) {
      this.iconSizeClass = this.coreSkillIconSizes.get(this.coreSkillIconSize)!;
    }
  }
}