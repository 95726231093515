import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { INotification } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  getAllNotifications(): Observable<INotification[]> {
    const endpoint = '/api/Notifications/GetAllNotifications';
    return this.http.get<INotification[]>(endpoint);
  }

  getUserNotifications(): Observable<INotification[]> {
    const endpoint = '/api/Notifications/GetUserNotifications';
    return this.http.get<INotification[]>(endpoint);
  }

  addNotification(notification: INotification): Observable<INotification> {
    const endpoint = '/api/Notifications/AddNotification';
    return this.http.post<INotification>(endpoint, notification);
  }

  expireNotification(notification: INotification): Observable<boolean> {
    const endpoint = '/api/Notifications/ExpireNotification';
    return this.http.put<boolean>(endpoint, notification);
  }

  removeExpiredNotifications(): Observable<boolean> {
    const endpoint = '/api/Notifications/RemoveExpiredNotifications';
    return this.http.delete<boolean>(endpoint);
  }

  removeExpiredNotification(notification: INotification): Observable<boolean> {
    const endpoint = `/api/Notifications/RemoveExpiredNotification`;
    return this.http.post<boolean>(endpoint, notification);
  }
}
