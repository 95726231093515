// Components
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NzSelectComponent } from 'ng-zorro-antd/select';

// Models
import { ISkill } from '../../../../models/skill.model';
import { ITrack } from 'src/app/models/track.model';

export interface GoalSkills {
  category: string,
  skills: ISkill[]
}
@Component({
  selector: 'sl-goal-skills',
  templateUrl: './goal-skills.component.html',
  styleUrls: ['./goal-skills.component.less'],
})
export class GoalSkillsComponent implements OnInit {
  @ViewChild(NzSelectComponent, { static: false }) nzSelectComponent!: NzSelectComponent;
  @Input() public skillOptions!: ISkill[];
  @Input() allCategoryOptions: ISkill[] = [];
  @Input() public trackName!: string;
  @Input() isApprovedTrack = true;
  @Input() public skillCategoryName!: string;
  @Input() goalSkills!: ISkill[];
  @Input() track!: ITrack;
  @Input() category!: string;
  @Input() selectableCoreSkills!: { skill: ISkill; hidden: boolean }[];
  @Output() removeCoreSkill = new EventEmitter<string[]>();

  selectorSkillOptions: Array<{ label: string; value: string }> = [];
  selectorSelectedSkills: string[] = [];
  skillsCategory!: string;

  constructor() { }

  ngOnInit() {
    if (this.editingGoal()) {
      this.loadSelectedSkills();
    } else {
      this.initialiseTracks();
    }
  }

  isNotSelected(value: string): boolean {
    if (this.selectorSelectedSkills.indexOf(value as never) === -1) {
      this.selectableCoreSkills.filter((skill) => skill.skill.name === value).forEach(element => {
        element.hidden = true;
      });
      return true;
    }
    else {
      this.selectableCoreSkills.filter((skill) => skill.skill.name === value).forEach(element => {
        element.hidden = false;
      });
      return false;
    }
  }

  allOptionsNotSelected(): boolean | undefined {
    if (this.selectorSkillOptions.length === this.selectorSelectedSkills.length) {
      return false;
    }
    return undefined;   //  I'm really unconfortable with the implementation of this function. It just doesn't make sense.
  }

  initialiseTracks() {
    if (this.skillOptions !== undefined && this.skillOptions !== null) {
      // only pre-filling defined non-custom tracks
      const skillsList: Array<{ label: string; value: string }> = [];
      for (const skill of this.skillOptions) {
        skillsList.push({ label: skill.name, value: skill.name });
        this.selectableCoreSkills.push({ skill, hidden: true });
      }
      for (const skill of this.allCategoryOptions) {
        if (skillsList.filter(s => skill.name === s.value).length === 0) {
          skillsList.push({ label: skill.name, value: skill.name });
          this.selectableCoreSkills.push({ skill, hidden: true });
        }
      }
      this.selectorSkillOptions = skillsList;
      if (this.isApprovedTrack) {
        for (const skill of this.skillOptions) {
          this.selectorSelectedSkills.push(skill.name);
        }
      }
    }
  }

  loadSelectedSkills() {
    if (this.skillOptions.length !== 0) {
      this.skillsCategory = this.skillOptions[0].category;

      const selectedSkills = this.goalSkills
        .filter((skill) => skill.category === this.skillsCategory);

      for (const selectedSkill of selectedSkills) {
        // adding the skills that arent part of the track(have been added by the user) to the list of available options
        this.selectorSelectedSkills.push(selectedSkill.name);
      }

      for (const skillOption of this.skillOptions) {
        this.selectorSkillOptions.push({
          label: skillOption.name,
          value: skillOption.name,
        });
        this.selectableCoreSkills.push({ skill: skillOption, hidden: true });
      }
      for (const skillOption of this.allCategoryOptions) {
        if (this.selectorSkillOptions.filter(s => skillOption.name === s.value).length === 0) {
          this.selectorSkillOptions.push({
            label: skillOption.name,
            value: skillOption.name,
          });
        }
      }
    }
  }

  editingGoal(): boolean {
    return this.goalSkills.length !== 0;
  }

  getSelectedSkills(): ISkill[] {
    const selectedSkills = [] as ISkill[];

    for (const selectedSkill of this.selectorSelectedSkills) {
      // checking for new category
      if (this.skillOptions.length === 0) {
        const newSkill = {} as ISkill;
        newSkill.category = this.skillCategoryName;
        newSkill.name = selectedSkill;
        selectedSkills.push(newSkill);
      } else {
        for (let j = 0; j < this.skillOptions.length; j++) {
          // checking if selected skill exists in skill options list
          if (this.skillOptions[j].name === selectedSkill) {
            selectedSkills.push(this.skillOptions[j]);
            break;

            // checking if end of array has been reached, indicating it's a new skill
          } else if (j === this.skillOptions.length - 1) {
            const newSkill = {} as ISkill;
            newSkill.category = this.skillOptions[j].category;
            newSkill.name = selectedSkill;
            selectedSkills.push(newSkill);
          }
        }
      }
    }
    return selectedSkills;
  }

  onModelChange(value: string[]) {
    this.removeCoreSkill.emit(this.allCategoryOptions.filter(skill => !value.includes(skill.name)).map(skill => skill.name));
  }
}
