import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IGuild } from '../../models/guild.model';

@Component({
  selector: 'sl-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.less']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Output() handleSelectedGuildCard = new EventEmitter<IGuild>();
  @Input() searchResultGuilds!: IGuild[];
  @Input() isSearching = false;
  @Output() loadMoreGuilds = new EventEmitter<void>();
  @ViewChild('guildCards') guildCards!: ElementRef;
  previousWindowHeight = window.innerHeight;

  ngOnInit() {
    window.addEventListener('resize', this.resizeWindowHandler.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeWindowHandler.bind(this));
  }


  selectGuild(guild: IGuild) {
    this.handleSelectedGuildCard.emit(guild);
  }

  onScroll() {
    if (!this.isSearching) {
      this.loadMoreGuilds.emit();
    }
  }

  // When zooming out, load more results to fill up the increased viewport real estate we have
  resizeWindowHandler() {
    window.addEventListener('resize', () => {
      const currentWindowHeight = window.innerHeight;

      if (this.guildCards && this.guildCards.nativeElement) {
        const viewportHeight = currentWindowHeight;
        const contentHeight = this.guildCards.nativeElement.offsetHeight;

        // Check if window is resized smaller and content height is less than viewport height
        if (currentWindowHeight > this.previousWindowHeight && contentHeight < viewportHeight) {
          this.onScroll();
        }
      }
      // Update the previous window height for the next resize event
      this.previousWindowHeight = currentWindowHeight;
    });
  }
}
