import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { IProvider } from '../models/provider.model';
import { IProviderConfig } from '../models/provider-config.model';
import { ICertificate } from '../models/certificate.model';
import { IUserProfileSubscription } from '../models/user-profile.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class ProvidersService {
  private endpoint = `/api/Providers`;

  constructor(protected http: HttpClient) {}

  getProviders(): Observable<IProvider[]> {
    const url = this.endpoint;
    return this.http.get<IProvider[]>(url);
  }

  seedDatabase(): Observable<boolean> {
    const url = this.endpoint + `/SeedDatabase`;

    return this.http.post<boolean>(url, '', httpOptions);
  }

  getOAuthEndpointForProvider(provider: string, username: string): Observable<string> {
    const url = this.endpoint + `/GetOAuthEndpointForProvider`;
    const body = { provider, username };
    return this.http.post<string>(url, body, httpOptions);
  }

  connectProvider(provider: string, username: string): Observable<IProvider> {
    const url = this.endpoint + `/Connect`;
    const body = { provider, username };

    return this.http.post<IProvider>(url, body, httpOptions);
  }

  getLoginNamesForProviders(user: IUserProfileSubscription): Observable<Record<string, string>> {
    const url = this.endpoint + `/GetLoginNamesForProviders`;

    let params = new HttpParams();
    params = params.append('userId', user.id.toString());

    const options = { headers: httpOptions.headers, params };

    return this.http.get<Record<string, string>>(url, options);
  }

  refreshProvider(provider: string, username: string): Observable<boolean> {
    const url = this.endpoint + `/RefreshProvider`;
    const body = { provider, username };
    return this.http.post<boolean>(url, body, httpOptions);
  }

  // Todo : remove
  getProviderConfig(providerName: string): Observable<IProviderConfig> {
    const url = this.endpoint + '/ProviderConfig';

    let params = new HttpParams();
    params = params.append('providerName', providerName);
    const options = { headers: httpOptions.headers, params };

    return this.http.get<IProviderConfig>(url, options);
  }

  getOAuthProviderConfigs(): Observable<IProviderConfig[]> {
    const url = this.endpoint + '/GetOAuthProviderConfigs';
    return this.http.get<IProviderConfig[]>(url);
  }

  connectVerifier(provider: string, certificateId: string): Observable<ICertificate> {
    const url = this.endpoint + '/AddCertification';
    const body = { provider, certificateId };
    return this.http.post<ICertificate>(url, body);
  }

  getProviderUserAcitivitiesCount(provider: string): Observable<number> {
    const url = this.endpoint + `/GetProviderActivitiesCount`;

    let params = new HttpParams();
    params = params.append('providerName', provider);

    const options = { headers: httpOptions.headers, params };

    return this.http.get<number>(url, options);
  }
}
