import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class SkillsReportService {

  endpoint = '/api/SkillsReport';

  constructor(private http: HttpClient) { }

  updateSkillsCache(): Observable<boolean> {
    const url = this.endpoint + '/UpdateCache';
    return this.http.post<boolean>(url, httpOptions);
  }
}
