<nz-sider class="navbar-slider" [nzWidth]="width" [nzReverseArrow]="isReverseArrow">
  <div class="logo">
    <div *ngIf="isCollapsed; else elseBlock">
      <div *ngIf="isLoggedIn">
        <sl-notification-badge></sl-notification-badge>
      </div>
      <div *ngIf="!isLoggedIn">
        <img src="/assets/img/sealadder/sealadder-logo-icon-white64.png" alt="logo" class="logo-img" />
      </div>
    </div>
    <ng-template #elseBlock>
      <img src="/assets/img/sealadder/sealadder-logo-nameonly-banner-white179x32.png" alt="logo" class="logo-img-exp" a />
    </ng-template>
  </div>

  <ul nz-menu class="submenu" [nzTheme]="'light'" [nzMode]="'inline'" [nzInlineCollapsed]="isCollapsed">
    <li nz-menu-group [hidden]="!isLandingPage" (click)="selectedItem='pricing'">
      <ul>
        <span id="pricing" nz-tooltip nzTooltipTitle="Pricing" nzTooltipPlacement="right" routerLinkActive="active-link" [routerLink]="['/pricing']">
          <li nz-menu-item [nzSelected]="selectedItem==='pricing' && !isLandingPage">
            <span title><i nz-icon nzType="dollar" nzTheme="outline"></i> </span>
          </li>
        </span>
      </ul>
    </li>
    <li nz-menu-group [hidden]="!isLoggedIn">
      <ul>
        <span id="account" nz-tooltip nzTooltipTitle="Account" nzTooltipPlacement="right" routerLinkActive="active-link" [routerLink]="['/account']">
          <li [hidden]="!hasGoals" nz-menu-item>
            <span title><i nz-icon nzType="user" nzTheme="outline"></i> </span>
          </li>
        </span>
        <span id="goal" nz-tooltip nzTooltipTitle="Goal" nzTooltipPlacement="right" routerLinkActive="active-link" [routerLink]="['/goal']">
          <li [hidden]="hasGoals" nz-menu-item>
            <span title><i nz-icon nzType="apartment" nzTheme="outline"></i> </span>
          </li>
        </span>
        <span id="profile" nz-tooltip nzTooltipTitle="Profile" nzTooltipPlacement="right" routerLinkActive="active-link" [routerLink]="['/profile/overview']">
          <li [hidden]="!hasGoals" nz-menu-item>
            <span title><i nz-icon nzType="project" nzTheme="outline"></i> </span>
          </li>
        </span>
        <span id="recommendations" nz-tooltip nzTooltipTitle="Recommendations" nzTooltipPlacement="right" routerLinkActive="active-link" [routerLink]="['/recommendations']">
          <li [hidden]="!hasGoals" nz-menu-item>
            <span title><i nz-icon nzType="compass" nzTheme="outline"></i> </span>
          </li>
        </span>
        <span id="guilds" nz-tooltip nzTooltipTitle="Guilds" nzTooltipPlacement="right" routerLinkActive="active-link" [routerLink]="['/guilds']">
          <li nz-menu-item>
            <span title>
              <i nz-icon nzType="team" nzTheme="outline"></i>
            </span>
          </li>
        </span>
        <span id="admin" *ngIf="isAdmin" nz-tooltip nzTooltipTitle="Admin" nzTooltipPlacement="right" routerLinkActive="active-link" nzAlign="bottom" [routerLink]="['/admin']">
          <li nz-menu-item>
            <span title><i nz-icon nzType="lock" nzTheme="outline"></i> </span>
          </li>
        </span>
      </ul>
    </li>

    <li nz-menu-group>
      <ul>
        <span nz-tooltip nzTooltipTitle="Login" nzTooltipPlacement="right">
          <li id="login" [hidden]="isLoggedIn" nz-menu-item (click)="login()">
            <span title><i nz-icon nzType="login" nzTheme="outline"></i> </span>
          </li>
        </span>
        <span nz-tooltip nzTooltipTitle="Logout" nzTooltipPlacement="right">
          <li id="logout" [hidden]="!isLoggedIn" nz-menu-item (click)="logout()">
            <span title><i nz-icon nzType="logout" nzTheme="outline"></i> </span>
          </li>
        </span>
      </ul>
    </li>
  </ul>
</nz-sider>

<nz-header class="navbar-header">
  <ul id="header-logo" nz-menu class="submenu" nzTheme="light" nzMode="horizontal">
    <div class="logo logo-header">
      <div *ngIf="isCollapsed; else elseBlock">
        <div *ngIf="isLoggedIn">
          <sl-notification-badge></sl-notification-badge>
        </div>
        <div *ngIf="!isLoggedIn">
          <img src="/assets/img/sealadder/sealadder-logo-icon-white64.png" alt="logo" class="logo-img" />
        </div>
      </div>
      <ng-template #elseBlock>
        <img src="/assets/img/sealadder/sealadder-logo-icon-white64.png" alt="logo" class="logo-img" />
      </ng-template>
    </div>
    <li *ngIf="isLandingPage" nz-menu-item [routerLink]="['/pricing']"><i nz-icon nzType="dollar" nzTheme="outline"></i><span class="action-title">Pricing</span></li>
    <li *ngIf="hasGoals && isLoggedIn" nz-menu-item [routerLink]="['/account']"><i nz-icon nzType="user" nzTheme="outline"></i><span class="action-title">Account</span></li>
    <li *ngIf="!hasGoals && isLoggedIn" nz-menu-item [routerLink]="['/goal']"><i nz-icon nzType="apartment" nzTheme="outline"></i><span class="action-title">Goal</span></li>
    <li *ngIf="hasGoals && isLoggedIn" nz-menu-item [routerLink]="['/profile/overview']"><i nz-icon nzType="project" nzTheme="outline"></i><span class="action-title">Profile</span></li>
    <li *ngIf="hasGoals && isLoggedIn" nz-menu-item [routerLink]="['/recommendations']"><i nz-icon nzType="compass" nzTheme="outline"></i><span class="action-title">Recommendations</span></li>
    <li *ngIf="isAdmin" [hidden]="!isLoggedIn" nz-menu-item [routerLink]="['/guilds']"><i nz-icon nzType="team" nzTheme="outline"></i><span class="action-title">Guilds</span></li>
    <li *ngIf="isAdmin" [hidden]="!isLoggedIn" nz-menu-item [routerLink]="['/admin']"><i nz-icon nzType="lock" nzTheme="outline"></i><span class="action-title">Admin</span></li>
  </ul>
  <ul nz-menu id="header-signin" class="submenu" nzTheme="light" nzMode="horizontal">
    <li [hidden]="isLoggedIn" nz-menu-item (click)="login()"><i nz-icon nzType="login" nzTheme="outline"></i><span class="action-title">Log In</span></li>
    <li [hidden]="!isLoggedIn" nz-menu-item (click)="logout()"><i nz-icon nzType="logout" nzTheme="outline"></i><span class="action-title">Log Out</span></li>
  </ul>
</nz-header>