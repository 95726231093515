import { Pipe, PipeTransform } from '@angular/core';
import { IUserActivity } from '../../models/user-activity.model';
import { ISkill } from 'src/app/models/skill.model';

@Pipe({
  name: 'searchActivities',
})
export class SearchActivitiesPipe implements PipeTransform {
  transform(pipeData: IUserActivity[], pipeModifier: string): IUserActivity[] | null {
    const queryString = pipeModifier.toLowerCase();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions;
    let startDate: string;
    let completedDate: string;

    return pipeData
      ? pipeData.filter((activity) => {
          startDate = activity.dateStart ? new Date(activity.dateStart).toLocaleDateString('en-US', options) : '';
          completedDate = activity.dateCompleted ? new Date(activity.dateCompleted).toLocaleDateString('en-US', options) : '';
          return this.searchActivity(activity, queryString, startDate, completedDate);
        })
      : null;
  }

  public containsCaseInsensitive(skills: ISkill[], input: string): boolean {
    const result = skills.filter((skill) => skill.name.toLowerCase().includes(input.toLowerCase()));
    return result.length > 0;
  }

  private searchActivity(activity: IUserActivity, queryString: string, startDate: string, completedDate: string): unknown {
    return (
      (activity.providerName !== null && activity.providerName?.toLowerCase().includes(queryString)) ||
      (activity.typeName !== null && activity.typeName?.toLowerCase().includes(queryString)) ||
      (activity.title !== null && activity.title.toLowerCase().includes(queryString)) ||
      (activity.link !== null && activity.link.toLowerCase().includes(queryString)) ||
      (startDate !== null && startDate.toLowerCase().includes(queryString)) ||
      (completedDate !== null && completedDate.toLowerCase().includes(queryString)) ||
      (activity.status !== null && activity.status.toLowerCase().includes(queryString)) ||
      (activity.skills !== null && this.containsCaseInsensitive(activity.skills, queryString))
    );
  }
}
