<div class="links-container">
  <div class="links-header">
    <p class="panel-title">Links:</p>
    <i *ngIf="!isAddingLink && !isEditingLink" class="insert-link-icon" nz-icon nzType="plus-circle" nzTheme="outline" nzTooltipTitle="Add a new link" nzTooltipPlacement="top" nz-tooltip (click)="insertLink()"></i>
    <i *ngIf="isAddingLink" class="cancel-insert-link-icon" nz-icon nzType="close-circle" nzTheme="outline" nzTooltipTitle="Cancel" nzTooltipPlacement="top" nz-tooltip (click)="cancelLinkInsertion()"></i>
  </div>

  <div *ngIf="isAddingLink" [ngClass]="urlTypeSelected === false ? 'link-input-container-with-select-option' : 'link-input-container-without-select-option'">
    <div class="link-type-image-container" *ngIf="urlTypeSelected === true">
      <img id="link-type-image" [src]="linkTypeImage" />
    </div>
    <nz-select #linkTypeSelector *ngIf="urlTypeSelected === false" class="link-type" nzShowSearch nzAllowClear nzPlaceHolder="Link Type" [(ngModel)]="linkTypeValue" (ngModelChange)="selectUrlPrefix()" [disabled]="isGettingFavicon">
      <nz-option nzCustomContent nzLabel="LinkedIn" [nzValue]="linkType.LinkedIn">
        <i class="fa-brands fa-linkedin fa-lg"></i>
        {{ linkProperties.get(linkType.LinkedIn).name }}
      </nz-option>
      <nz-option nzCustomContent nzLabel="Slack" [nzValue]="linkType.Slack">
        <i class="fa-brands fa-slack fa-lg"></i>
        {{ linkProperties.get(linkType.Slack).name }}
      </nz-option>
      <nz-option nzCustomContent nzLabel="Discord" [nzValue]="linkType.Discord">
        <i class="fa-brands fa-discord fa-md"></i>
        {{ linkProperties.get(linkType.Discord).name }}
      </nz-option>
      <nz-option nzCustomContent nzLabel="WhatsApp" [nzValue]="linkType.WhatsApp">
        <i class="fa-brands fa-whatsapp fa-lg"></i>
        {{ linkProperties.get(linkType.WhatsApp).name }}
      </nz-option>
      <nz-option nzCustomContent nzLabel="Signal" [nzValue]="linkType.Signal">
        <i class="fa fa-comment-o fa-lg" aria-hidden="true"></i>
        {{ linkProperties.get(linkType.Signal).name }}
      </nz-option>
      <nz-option nzCustomContent nzLabel="Meetup" [nzValue]="linkType.Meetup">
        <i class="fa-brands fa-meetup fa-lg"></i>
        {{ linkProperties.get(linkType.Meetup).name }}
      </nz-option>
      <nz-option nzCustomContent nzLabel="Facebook" [nzValue]="linkType.Facebook">
        <i class="fa-brands fa-facebook-square fa-lg"></i>
        {{ linkProperties.get(linkType.Facebook).name }}
      </nz-option>
      <nz-option nzCustomContent nzLabel="Website" [nzValue]="linkType.Website">
        <i class="fa fa-laptop" aria-hidden="true"></i>
        {{ linkProperties.get(linkType.Website).name }}
      </nz-option>
      <nz-option nzCustomContent nzLabel="Other" [nzValue]="linkType.Other">
        <i nz-icon nzType="dash" nzTheme="outline"></i>
        {{ linkProperties.get(linkType.Other).name }}
      </nz-option>
    </nz-select>

    <nz-input-group class="input-bar" nzSearch [nzSuffix]="isGettingFavicon ? suffixTemplateInfo : null" [nzAddOnAfter]="suffixIconButton">
      <input *ngIf="!isEditingLink" #linkUrl id="guild-link-input" type="url" name="link" nz-input placeholder="Enter the link here" (keyup.enter)="addLink()" (keydown)="handleKeydown($event)" (click)="$event.target.select()" (blur)="handleBlurAddLink()"
        (ngModelChange)="selectLinkTypeBasedOnUrl($event)" [(ngModel)]="linkValue" [disabled]="isGettingFavicon" />
    </nz-input-group>
    <ng-template #suffixIconButton>
      <div class="add-link-btn-container">
        <button id="add-link-btn" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Add Link" nzSearch (click)="addLink()" [disabled]="isGettingFavicon">
          <i id="add-link-btn-icon" nz-icon nzType="plus"></i>
        </button>
      </div>
    </ng-template>
    <p class="error" *ngIf="linkTypeValue !== undefined && linkTypeValue !== null && hasAttemptedToAddIncompleteLink === true && (linkValue === '' || linkValue === null)">You need to enter a link</p>
    <p class="error" *ngIf="(linkTypeValue === undefined || linkTypeValue === null) && hasAttemptedToAddIncompleteLink === true && (linkValue !== '' || linkValue !== null)">You need to select a link type</p>
    <p class="error" *ngIf="!validateUrl() && hasAttemptedToAddInvalidLink === true && (linkValue !== '' || linkValue !== null)">The link you entered is invalid</p>
    <p class="error" *ngIf="linkExists() && hasAttemptedToAddExistingLink === true">You have already added this link</p>
  </div>

  <div *ngFor="let link of links | slice : 0 : linksState.amount; let i = index">
    <div class="link-container" #linksContainer>
      <div class="link-container-text">
        <span class="link-url-container" *ngIf="!link.editing">
          <a class="link-text" href="{{ link.url }}" target="_blank" nz-tooltip="{{ link.linkText }}"><img class="link-image" [src]="link.otherLinkTypeThumbnail" alt="Link Icon" (error)="setDefaultImage($event)">{{link.linkText | limitTextLengthPipe: dynamicLimit}}</a>
        </span>

        <div class="edit-links-container" *ngIf="link.editing && isEditingLink && !isAddingLink">
          <span class="edit-link-input-container">
            <img class="link-image" [src]="link.otherLinkTypeThumbnail" alt="Link Icon" (error)="setDefaultImage($event)">
            <nz-input-group>
              <input #editLinkInput id="guild-link-url-input" nzSize="small" type="url" name="link" nz-input placeholder="Link URL" (keyup.enter)="updateLink(link)" (keydown)="handleKeydown($event)" (blur)="handleBlurEditLink($event, link,  'guild-link-text-input')"
                (click)="$event.target.select()" (ngModelChange)="selectLinkTypeBasedOnUrl($event)" [(ngModel)]="linkValue" nz-tooltip="{{ linkValue }}" [disabled]="isGettingFavicon" />
            </nz-input-group>
            <nz-input-group [nzSuffix]="isGettingFavicon ? suffixTemplateInfo : blankTemplate">
              <input #editLinkTextInput id="guild-link-text-input" [(ngModel)]="linkTextValue" nzSize="small" type="text" name="link-text" nz-input placeholder="Link Text" (keyup.enter)="updateLink(link)" (click)=" $event.target.select()"
                (blur)="handleBlurEditLink($event, link, 'guild-link-url-input')" nz-tooltip="{{ linkTextValue }}" [disabled]="isGettingFavicon" />
            </nz-input-group>
          </span>
          <span class="edit-links-options">
            <i class="update-link-btn-icon" *ngIf="!isGettingFavicon" nz-icon nzType="check-circle" nzTheme="outline" (click)="updateLink(link)"></i>
            <i class="cancel-update-link-btn-icon" nz-icon nzType="close-circle" nzTheme="outline" (click)="cancelEdit(link)"></i>
          </span>
        </div>
      </div>
      <div class="link-options" *ngIf="!isEditingLink">
        <i class="edit-links-icon" nz-icon nzType="edit" nzTheme="outline" nz-tooltip nzTooltipTitle="Edit" (click)="editLink(link)"></i>
        <i class="delete-links-icon" nz-icon nzType="delete" nzTheme="outline" nz-popconfirm nzPopconfirmPlacement="topLeft" nzPopconfirmTitle="Are you sure you want to delete this link?" nz-tooltip nzTooltipTitle="Delete" (nzOnConfirm)="deleteLink(link)"></i>
      </div>
    </div>
  </div>

  <div class="link-state-controls-container">
    <div *ngIf="!linksState.expanded">
      <a id="expand-links-option" (click)="expandLinks()">Show More</a>
    </div>
    <div *ngIf="links.length > 3 && linksState.expanded">
      <a id="collapse-links-option" (click)="collapseLinks()">Show Less</a>
    </div>
  </div>
</div>

<div *ngIf="!isAddingLink" class="error-types">
  <p class="error" *ngIf="linkTypeValue !== undefined && linkTypeValue !== null && hasAttemptedToAddIncompleteLink === true && (linkValue === '' || linkValue === null)">You need to enter a link</p>
  <p class="error" *ngIf="(linkTypeValue === undefined || linkTypeValue === null) && hasAttemptedToAddIncompleteLink === true && (linkValue !== '' || linkValue !== null)">You need to select a link type</p>
  <p class="error" *ngIf="!validateUrl() && hasAttemptedToAddInvalidLink === true && (linkValue !== '' || linkValue !== null)">The link you entered is invalid</p>
  <p class="error" *ngIf="linkExists() && hasAttemptedToAddExistingLink === true">You have already added this link</p>
</div>

<div class="add-first-link-alert" *ngIf="!links.length && !isAddingLink">
  <nz-alert nzType="info" nzShowIcon [nzDescription]="descriptionTpl">
    <ng-template #descriptionTpl>
      <span class="alert-body">
        Add links to important sites & services.
      </span>
    </ng-template>
  </nz-alert>
</div>

<ng-template #suffixTemplateInfo>
  <nz-spin class="add-link-spinner" [nzSize]="'small'" nzSimple></nz-spin>
</ng-template>
<ng-template #blankTemplate></ng-template>