import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'glpyhSelectorPipe',
})
export class GlpyhSelectorPipe implements PipeTransform {
  transform(glyphClass: string): string {
    if (!glyphClass) {
      glyphClass = '';
    }

    const matchBeginning = /(^<i class=")/g;
    const matchEnd = /("><\/i>$)/g;
    const transformedGlyphClass = glyphClass.replace(matchBeginning, '').replace(matchEnd, '').trim();
    return transformedGlyphClass;
  }
}
