/* eslint-disable @typescript-eslint/member-ordering */
/*  @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

// models
import { IGoal } from '../models/user-goal.model';
import { ITrack } from '../models/track.model';
import { TrackServiceData, TrackSkillCategoryDescription, TrackSkillDescription } from './track.service.data';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class TrackService {
  endpoint = '/api/Tracks/';
  isLoggedIn = false;
  initialising = false;

  private readonly customTrackName = 'Custom';

  constructor(private http: HttpClient, private trackServiceData: TrackServiceData) { }

  public getTrack(trackId: number): Observable<ITrack> {
    const url = this.endpoint + 'GetTrack';
    return this.http.post<ITrack>(url, trackId, httpOptions);
  }

  public getTracks(goal: IGoal): Observable<ITrack[]> {
    const url = this.endpoint + 'GetTracks';
    return this.http.post<ITrack[]>(url, goal, httpOptions);
  }

  public getAllTracks(): Observable<ITrack[]> {
    const url = this.endpoint + 'GetAllTracks';
    return this.http.get<ITrack[]>(url);
  }

  public getAllTracksSummary(): Observable<ITrack[]> {
    const url = this.endpoint + 'GetAllTracksSummary';
    return this.http.get<ITrack[]>(url);
  }

  public getTracksApprovedBasedOnSignature(industry: string, profession: string, specialisation: string, levelFrom: number, levelTo: number): Observable<ITrack[]> {
    const url = this.endpoint + 'GetTracksApprovedBasedOnSignature';
    const params = new HttpParams()
      .set('industry', industry)
      .set('profession', profession)
      .set('specialisation', specialisation)
      .set('levelFrom', String(levelFrom))
      .set('levelTo', String(levelTo));

    const options = { headers: httpOptions.headers, params };

    return this.http.get<ITrack[]>(url, options);
  }

  public getTracksToBeApproved(): Observable<ITrack[]> {
    const url = this.endpoint + 'GetTracksToBeApproved';
    return this.http.get<ITrack[]>(url);
  }

  public approveTrack(track: ITrack): Observable<ITrack> {
    const url = this.endpoint + 'ApproveTrack';
    return this.http.post<ITrack>(url, track, httpOptions);
  }

  public rejectTrack(track: ITrack): Observable<ITrack> {
    const url = this.endpoint + 'RejectTrack';
    return this.http.post<ITrack>(url, track, httpOptions);
  }

  public getTrackHistory(goalSignatureWithTrackName: ITrack): Observable<ITrack[]> {
    const url = this.endpoint + 'GetTrackHistory';
    return this.http.post<ITrack[]>(url, goalSignatureWithTrackName);
  }

  public updateTracks(): Observable<ITrack[]> {
    const url = this.endpoint + 'UpdateTracks';
    return this.http.post<ITrack[]>(url, '', httpOptions);
  }

  public updateTrackDetails(track: ITrack): Observable<ITrack> {
    const url = this.endpoint + 'UpdateTrackDetails';
    return this.http.post<ITrack>(url, track, httpOptions);
  }

  public fixDuplicateTracks(): Observable<number> {
    const url = this.endpoint + 'FixDuplicateTracks';
    return this.http.post<number>(url, httpOptions);
  }

  public detectTrackProblems(): Observable<Record<string, string>> {
    const url = this.endpoint + 'DetectTrackProblems';
    return this.http.get<Record<string, string>>(url, httpOptions);
  }

  public cleanTrackProblems(cleanUpCode: string): Observable<string> {
    const url = this.endpoint + 'CleanTrackProblems';

    const params = new HttpParams()
      .set('cleanUpCode', cleanUpCode);

    return this.http.post(url, params, { responseType: 'text' });
  }

  public generateTrackDescription(track: ITrack): Observable<string> {
    const url = this.endpoint + 'GenerateTrackDescription';
    return this.http.post(url, track, { responseType: 'text' });
  }

  public generateTrackSkillCategoryDescriptionById(trackId: number, category: string): Observable<string> {
    const url = this.endpoint + 'GenerateTrackSkillCategoryDescriptionById';
    const headersText = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      headers: headersText,
      responseType: 'text',
      params: { trackId: trackId.toString(), category }
    };

    return this.http.get<string>(url, requestOptions).pipe(
      tap(description => {
        this.trackServiceData.initialiseTrackSkillCategoryDescriptionsById(trackId, category, description);
      })
    )
  }

  public getTrackSkillCategoryDescriptionById(trackId: number, category: string): TrackSkillCategoryDescription {
    return this.trackServiceData.getTrackSkillCategoryDescriptionById(trackId, category);
  }

  public generateTrackSkillCategoryDescriptionByName(trackName: string, category: string, industry: string, profession: string, specialisation: string, level: string): Observable<string> {
    const url = this.endpoint + 'GenerateTrackSkillCategoryDescriptionByName';
    const headersText = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      headers: headersText,
      responseType: 'text',
      params: { trackName, category, industry, profession, specialisation, level }
    };

    return this.http.get<string>(url, requestOptions).pipe(
      tap(description => {
        this.trackServiceData.initialiseTrackSkillCategoryDescriptionsByName(trackName, category, description);
      })
    )
  }

  public getTrackSkillCategoryDescriptionByName(trackName: string, category: string): TrackSkillCategoryDescription {
    return this.trackServiceData.getTrackSkillCategoryDescriptionByName(trackName, category);
  }

  public generateTrackSkillDescriptionById(trackId: number, skillName: string, category: string): Observable<string> {
    const url = this.endpoint + 'GenerateTrackSkillDescriptionById';
    const headersText = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      headers: headersText,
      responseType: 'text',
      params: { trackId: trackId.toString(), skillName, category }
    };

    return this.http.get<string>(url, requestOptions).pipe(
      tap(description => {
        this.trackServiceData.initialiseTrackSkillDescriptionsById(trackId, skillName, category, description);
      })
    )
  }

  public generateTrackSkillDescriptionByName(trackName: string, skillName: string, category: string, industry: string, profession: string, specialisation: string, level: string): Observable<string> {
    const url = this.endpoint + 'GenerateTrackSkillDescriptionByName';
    const headersText = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      headers: headersText,
      responseType: 'text',
      params: { trackName, skillName, category, industry, profession, specialisation, level }
    };

    return this.http.get<string>(url, requestOptions).pipe(
      tap(description => {
        this.trackServiceData.initialiseTrackSkillDescriptionsByName(trackName, skillName, category, description);
      })
    )
  }

  public getTrackSkillDescriptionById(trackId: number, skillName: string): TrackSkillDescription {
    return this.trackServiceData.getTrackSkillDescriptionById(trackId, skillName);
  }

  public getTrackSkillDescriptionByName(trackName: string, skillName: string): TrackSkillDescription {
    return this.trackServiceData.getTrackSkillDescriptionByName(trackName, skillName);
  }

  public regenerateTrackDescriptions(): Observable<string> {
    const url = this.endpoint + 'RegenerateTrackDescriptions';
    return this.http.post(url, '', { responseType: 'text' });
  }
}
