import { Component } from '@angular/core';

// Services
import { TrackService } from 'src/app/services/track.service';
// Models
import { ITrack } from 'src/app/models/track.model';
import { ITrackSkill } from 'src/app/models/track-skill.model';

@Component({
  selector: 'sl-track-history',
  templateUrl: './track-history.component.html',
  styleUrls: ['./track-history.component.less'],
})
export class TrackHistoryComponent {
  tracks: ITrack[] = new Array<ITrack>();
  isLoadingActive = false;
  trackIsMostRecent: ITrack | null = null;

  colourPool = [
    // light then dark colours to provide contrast between groups
    'magenta',
    'RebeccaPurple',
    'red',
    'Crimson',
    'volcano',
    'DarkRed',
    'orange',
    'OrangeRed',
    'gold',
    'Chocolate',
    'lime',
    'LightGreen',
    'green',
    'seagreen',
    'cyan',
    'PaleTurquoise',
    'blue',
    'navyblue',
    'geekblue',
    'mediumpurple',
    'purple',
    'Indigo',
  ];
  selectedTrack: ITrack | null = null;

  constructor(private trackService: TrackService) {}

  public setSelectedTrack(track: ITrack): void {
    if (!track) {
      console.error(`track-history: setSelectedTrack being set to null`);
    }

    this.selectedTrack = track;
    this.getTrackHistory();
  }

  public getColour(trackId: number, skillId: number): string {
    if (!this.tracks.length) {
      console.error('track-history request to get colour when there are no tracks');
      return 'black';
    }

    const track = this.tracks[trackId];
    const maxS = track.recommendedSkills.length;

    if (skillId === 0 || maxS <= 1) {
      return this.colourPool[0];
    }

    const categoriesAll = track.recommendedSkills.map((data) => data.skill.category);
    const categoriesUnique = categoriesAll.filter((x, i, a) => x && a.indexOf(x) === i);
    const categoryIndex = categoriesUnique.findIndex((c) => c === track.recommendedSkills[skillId].skill.category);
    const colourIndex = categoryIndex % this.colourPool.length;
    return this.colourPool[colourIndex];
  }

  public sortTracksByDate(tracks: ITrack[]): ITrack[] {
    tracks.sort((a, b) => ((!a.dateCreated ? 0 : a.dateCreated) < (!b.dateCreated ? 0 : b.dateCreated) ? 1 : -1));

    for (const track of tracks) {
      this.sortSkillsAlphabetically(track.recommendedSkills);
    }

    return tracks;
  }

  public isActive(track: ITrack) {
    return !track.dateExpired && track.approved;
  }

  public toggleActive(track: ITrack) {
    if (this.isActive(track)) {
      console.error('track-history: toggleActive should not allow toggling an active off directly');
    }
    if (this.tracks.length <= 1) {
      console.error('track-history: toggleActive how are we here if we are approving a track with just 1 in the list');
      return;
    }

    this.trackService.approveTrack(track).subscribe(() => {
      this.getTrackHistory();
    });
  }

  public activeIsRecent(): boolean {
    if (this.tracks.length <= 1) {
      return true;
    }

    if (!this.trackIsMostRecent || !this.trackIsMostRecent.approved) {
      return false;
    }

    return this.trackIsMostRecent.approved;
  }

  public activeHasMax(): boolean {
    return this.getSkillsCountActive() === this.getSkillsCountMax();
  }

  public getTotalHistory(): number {
    return this.tracks?.length;
  }

  public getSkillsCountActive(): number {
    if (!this.selectedTrack) {
      return 0;
    }

    return this.selectedTrack.recommendedSkills.length;
  }

  public getSkillsCountMax(): number {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return Math.max(...this.tracks?.map((t) => t.recommendedSkills.length));

  }
  public getSkillsCountMin(): number {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return Math.min(...this.tracks?.map((t) => t.recommendedSkills.length));
  }

  private sortSkillsAlphabetically(skills: ITrackSkill[]) {
    skills.sort((a, b) => (a.skill.category > b.skill.category ? 1 : -1));
  }

  private getTrackHistory(): void {
    this.trackIsMostRecent = null;
    if (!this.selectedTrack) {
      throw new Error('The SelectedTrack should be defined at this point');
    }

    this.trackService.getTrackHistory(this.selectedTrack).subscribe({
      next: (tracks) => {
        if (!tracks) {
          console.error('track-history:getTrackHistory has returned null instead of a collection of tracks.');
        }

        this.tracks = this.sortTracksByDate(tracks);

        // update the track that has the most recent date
        this.trackIsMostRecent = this.tracks.reduce((a, b) => ((!a.dateCreated ? -1 : a.dateCreated) > (!b.dateCreated ? 1 : b.dateCreated) ? a : b));
      },
      error: (error) => console.error(error)
    });
  }
}
