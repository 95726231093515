import { Pipe, PipeTransform } from '@angular/core';
import { IRecommendation } from 'src/app/models/recommendation.model';
import { ISkill } from 'src/app/models/skill.model';

@Pipe({
  name: 'searchRecommendation'
})
export class SearchRecommendationPipe implements PipeTransform {

  transform(pipeData: IRecommendation[], pipeModifier: string): IRecommendation[] | null {
    const queryString = pipeModifier.toLowerCase();

    return pipeData ? pipeData.filter(list => (
        (list.title !== null && list.title.toLowerCase().includes(queryString)) ||
        (list.skills !== null && this.containsCaseInsensitive(list.skills, queryString))
      )) : null;
  }

  containsCaseInsensitive(skills: ISkill[], input: string): boolean {
    const result = skills.filter(skill => skill.name.toLowerCase().includes(input.toLowerCase()));
    return result.length > 0;
  }

}
