import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { INotification } from 'src/app/models/notification.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { IUserProfileSubscription } from 'src/app/models/user-profile.model';
import { NzTableFilterFn, NzTableFilterList } from 'ng-zorro-antd/table';

interface ColumnItem {
  name: string;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<INotification> | null;
  filterMultiple: boolean;
}
@Component({
  selector: 'sl-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})

export class NotificationsComponent implements OnInit {
  isVisible = false;
  notifications!: INotification[];
  newNotification!: INotification;
  endOpen = false;
  users!: IUserProfileSubscription[];
  selectedUsers: string[] = [];
  loading = true;
  hasError = false;
  listOfColumns: ColumnItem[] = [
    {
      name: 'Status',
      filterMultiple: true,
      listOfFilter: [
        { text: 'Active', value: 'Active' },
        { text: 'Scheduled', value: 'Scheduled' },
        { text: 'Expired', value: 'Expired' }
      ],
      filterFn: (list: string[], item: INotification) => list.some(() => list.includes(this.getStatus(item))),
    }
  ];

  constructor(
    private notificationService: NotificationsService,
    private message: NzMessageService,
    private accountDetailsService: AccountDetailsService
  ) { }

  ngOnInit(): void {
    this.getAllNotifications();
    this.getUsers();
    this.newNotification = {
      messageTitle: '',
      messageBody: '',
      link: '',
    } as INotification;
    this.tempDefaultDate();
  }

  getUsers() {
    this.loading = true;
    this.accountDetailsService.getBasicUsers(false).subscribe({
      next: res => {
        this.users = res;
      },
      error: error => {
        this.hasError = true;
        console.error(`*** Failed to Retrieve Users. Error: ${error}`);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  tempDefaultDate() {
    const currentDate = new Date();
    this.newNotification.datePublish = currentDate;
    this.newNotification.dateExpiry = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000);
  }

  getAllNotifications() {
    this.notificationService.getAllNotifications().subscribe({
      next: res => {
        this.notifications = res;
      },
      error: err => {
        console.error(err);
      }
    });
  }

  showNewNotificationModal(): void {
    this.isVisible = true;
  }

  addNotification(): void {
    this.loading = true;
    if (this.selectedUsers.length !== 0) {
      for (const user of this.selectedUsers) {
        this.newNotification.recipientUserId = user;
        this.addNewNotification();
      }
    } else {
      this.addNewNotification();
    }
    this.isVisible = false;
  }

  removeNotification(notification: INotification): void {
    this.notificationService.expireNotification(notification).subscribe({
      next: () => {
        this.message.success('Notification deleted');
        this.getAllNotifications();
      },
      error: err => {
        console.error(err);
      }
    }
    );
  }

  isExpired(date: Date): boolean {
    return new Date(date).getTime() < new Date().getTime();
  }

  getStatus(notification: INotification): string {
    const now = new Date().getTime();
    if (new Date(notification.datePublish).getTime() > now) {
      return 'Scheduled';
    }
    if (new Date(notification.datePublish).getTime() < now && new Date(notification.dateExpiry).getTime() > now) {
      return 'Active';
    }
    if (new Date(notification.dateExpiry).getTime() < now) {
      return 'Expired';
    }
    return 'Error';
  }

  cancelNotification(): void {
    this.isVisible = false;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
    console.log('handleStartOpenChange', open, this.endOpen);
  }

  handleEndOpenChange(open: boolean): void {
    console.log(`*** handleEndOpenChange = ${open}`);
    this.endOpen = open;
  }

  removeExpiredNotifications() {
    this.notificationService.removeExpiredNotifications().subscribe(
      () => {
        this.message.success('All expired notifications were deleted');
        this.getAllNotifications();
      },
      err => {
        console.error(err);
      }
    );
  }

  removeExpiredNotification(notification: INotification) {
    this.notificationService.removeExpiredNotification(notification).subscribe(
      () => {
        this.message.success('Notification was deleted');
        this.getAllNotifications();
      },
      err => {
        console.error(err);
      }
    );
  }

  onChange(result: Date[]): void {
    this.newNotification.datePublish = result[0];
    this.newNotification.dateExpiry = result[1];
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.newNotification.dateExpiry) {
      return false;
    }
    return startValue.getTime() > this.newNotification.dateExpiry.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.newNotification.datePublish) {
      return false;
    }
    return endValue.getTime() <= this.newNotification.datePublish.getTime();
  };

  private addNewNotification() {
    this.notificationService.addNotification(this.newNotification).subscribe(
      () => {
        this.loading = false;
        this.getAllNotifications();
      },
      err => {
        console.error(err);
        this.loading = false;
      }
    );
  }
}
