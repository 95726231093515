import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ILearningType } from '../models/learning-type.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LearningService {
  endpoint = '/api/Learning/';

  constructor(private http: HttpClient) { }

  getLearningTypes(): Observable<ILearningType[]> {
    const url = this.endpoint + 'GetLearningTypes';
    return this.http.get<ILearningType[]>(url);
  }

  seedDatabase(): Observable<boolean> {
    const endpoint = `/api/Learning/SeedDatabase`;

    return this.http.post<boolean>(endpoint, '', httpOptions);
  }
}
