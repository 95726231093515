import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { IEmailMessage } from '../models/email-message.model';
import { IEmailMessageRecipient } from '../models/email-message-recipient.model';
import { IEmailType } from '../models/email-type.model';
import { IEmailBatch } from '../models/email-batch.model';
import { IEmailSingleUser, IEmailTemplate } from '../models/email-template.model';

@Injectable({
  providedIn: 'root',
})
export class EmailMessageService {
  private endpoint = '/api/EmailMessages/';
  constructor(private http: HttpClient) {}

  seedEmailMessages(): Observable<IEmailTemplate[]> {
    const url = this.endpoint + 'SeedDatabase';
    return this.http.get<IEmailTemplate[]>(url);
  }

  getEmailTypeNames(): Observable<IEmailType[]> {
    const url = this.endpoint + 'GetEmailTypes';
    return this.http.get<IEmailType[]>(url);
  }

  getEmailTemplate(id: number): Observable<IEmailMessage> {
    const url = this.endpoint + 'GetEmailTemplate/';
    return this.http.get<IEmailMessage>(url, { params: { id: id.toString() } });
  }

  getEmailMessageRecipients(emailTemplateId: number): Observable<IEmailMessageRecipient[]> {
    const url = this.endpoint + 'GetEmailMessageRecipients/';
    return this.http.get<IEmailMessageRecipient[]>(url, { params: { emailTemplateId: emailTemplateId.toString() } });
  }

  getEmailBatches(): Observable<IEmailBatch[]> {
    const url = this.endpoint + 'GetEmailBatches/';
    return this.http.get<IEmailBatch[]>(url);
  }

  sendEmailsBasedOnTemplate(emailTemplateId: number): Observable<IEmailBatch> {
    const url = this.endpoint + 'SendEmailsBasedOnTemplate/';
    return this.http.post<IEmailBatch>(url, emailTemplateId);
  }

  sendEmailsToSingleUser(emailTemplate: IEmailSingleUser): Observable<IEmailSingleUser> {
    const url = this.endpoint + 'SendEmailsToSingleUser/';
    return this.http.post<IEmailSingleUser>(url, emailTemplate);
  }

  updateEmailTemplate(template: IEmailTemplate): Observable<IEmailTemplate> {
    const url = this.endpoint + 'UpdateEmailTemplate/';
    return this.http.post<IEmailTemplate>(url, template);
  }
}
