<div class="component-title">
  <div class="title-logo"><i class="fas fa-briefcase"></i></div>
  <div class="title-text">EMPLOYMENT EXPERIENCE</div>
  <button id="add-experience-button" class="title-button" nz-button nzType="'Primary'" [nzShape]="'circle'" (click)="openModal()" *ngIf="employmentsPublic === undefined && employmentExperienceList.length">
    <span nz-tooltip nzTooltipTitle="Add Employment Experience">
      <i class="fa fa-plus"></i>
    </span>
  </button>
</div>

<nz-alert *ngIf="!employmentExperienceList.length" id="no-experience-found" nzType="info" nzMessage="No experience found" [nzDescription]="descriptionTpl" nzShowIcon>
  <ng-template #descriptionTpl>
    <div *ngIf="employmentsPublic === undefined">
      <span>Try adding one.</span>
      <button id="add-experience-button-alert" nz-button [nzShape]="'circle'" (click)="openModal()">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </ng-template>
</nz-alert>

<nz-card id="container-user-employment" *ngIf="employmentExperienceList.length" [nzLoading]="!isInitialised && isSkillsLoading">
  <nz-card id="container-user-employment" *ngIf="employmentExperienceList.length" [nzLoading]="!isInitialised && isSkillsLoading">
    <div id="switch-parent" *ngIf="employmentExperienceList.length">
      <div id="skill-switch">
        Show All
        <nz-switch [(ngModel)]="showAllSkills" (ngModelChange)="refreshSkills()" nz-tooltip nzTooltipTitle="Show / hide all skills that are in progress, even if there are no activities associated"></nz-switch>
      </div>
    </div>

    <div class="card-entry" *ngFor="let experience of employmentExperienceList; let i = index; let isLast = last">
      <div class="experience-entry">
        <img class="experience-entry-img" [src]="getImageUrlOrDefault(experience.company.imageUrl)" />

        <div class="experience-entry-details">
          <div class="experience-entry-info">
            <p class="experience-entry-title" nz-typography>{{ experience.jobTitle }}</p>
            <p class="experience-entry-company" nz-typography>{{ experience.company.name }}</p>
            <p *ngIf="null !== experience.dateEnd" class="experience-entry-date" nz-typography nzType="secondary">{{ experience.dateStart | date: 'MMM yyyy' }} - {{ experience.dateEnd | date: 'MMM yyyy' }}</p>
            <p *ngIf="null === experience.dateEnd" class="experience-entry-date" nz-typography nzType="secondary">{{ experience.dateStart | date: 'MMM yyyy' }} - Present</p>
          </div>

          <ng-container *ngIf="experience.associatedGoals.length === 1">
            <div class="experience-entry-goal-skills" *ngFor="let goal of experience.associatedGoals">
              <ng-template [ngTemplateOutlet]="goalSkillsTemplate" [ngTemplateOutletContext]="{ data: goal }"></ng-template>
            </div>
          </ng-container>
          <ng-container *ngIf="experience.associatedGoals.length !== 1">
            <nz-timeline [nzPending]="experience.associatedGoals.length > showGoalsNumber" [nzPendingDot]="pendingTemplate">
              <nz-timeline-item *ngFor="let goal of experience.associatedGoals | slice: 0:goalToExpand(i)" [nzDot]="!goal.dateCompleted ? progressBlock : completeBlock">
                <ng-template [ngTemplateOutlet]="goalSkillsTemplate" [ngTemplateOutletContext]="{ data: goal }"></ng-template>
              </nz-timeline-item>
            </nz-timeline>

            <div class="show-more-button-container" *ngIf="numberOfGoalsShown[i].amount < experience.associatedGoals.length">
              <a class="show-more-btn" *ngIf="numberOfGoalsShown[i].amount < 100 && collapsedGoalState[i].state === true" (click)="expandGoals(i)">Show More</a>
            </div>
            <div class="show-less-button-container">
              <a class="show-less-btn" *ngIf="collapsedGoalState[i].state === false" (click)="collapseGoals(i)">Show Less</a>
            </div>

            <ng-template #completeBlock id="completedDot">
              <i nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'lightgray'" nz-tooltip nzTooltipTitle="Goal completed"></i>
            </ng-template>
            <ng-template #progressBlock id="inProgressDot">
              <i nz-icon nzType="clock-circle" nzTheme="twotone" [nzTwotoneColor]="'#1abc9c'" nz-tooltip nzTooltipTitle="Goal in progress"></i>
            </ng-template>
          </ng-container>

          <ng-template #pendingTemplate></ng-template>

          <ng-template #goalSkillsTemplate let-goal="data">
            <label id="goal-name" [ngClass]="!goal.dateCompleted ? 'entry-goal' : 'entry-goal-complete'">{{ goal.specialisation }} {{ goal.profession }} - {{ goal.level }}</label>
            <div [ngClass]="!goal.dateCompleted ? 'entry-skills' : 'entry-skills-complete'">
              <span *ngIf="goal.coreSkills && goal.coreSkills.length > 0" class="employment-core-skills">
                <sl-core-skill-icon></sl-core-skill-icon><b> Core Skills - </b>
                <ng-container *ngFor="let skill of goal.coreSkills; let isLast = last">
                  <b>{{ skill.name }}<span *ngIf="!isLast">, </span></b>
                </ng-container>
              </span>
              <div *ngFor="let category of getSelectedDisplayGoalCategorySkills(goal) | keyvalue">
                {{ category.key ? category.key + ' - ' : '' }}<span *ngFor="let skill of category.value.skill; let isLast = last">{{ skill }}{{ isLast ? '' : ', ' }}</span>
              </div>
            </div>
          </ng-template>
        </div>

        <button class="edit-experience-button" nz-button nzType="'Primary'" [nzShape]="'circle'" (click)="openModal(experience)" *ngIf="employmentsPublic === undefined">
          <i class="fa fa-pencil"></i>
        </button>
      </div>

      <nz-divider *ngIf="!isLast"></nz-divider>
    </div>
  </nz-card>
</nz-card>

<nz-modal id="add-experience-modal" [(nzVisible)]="showModal" (nzOnCancel)="closeEmploymentExperienceModal()">
  <ng-container *nzModalContent>
    <form nz-form [nzLayout]="'vertical'" [formGroup]="formValues">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="jobTitle">Job Title</nz-form-label>
        <nz-form-control nzHasFeedback>
          <input nz-input formControlName="jobTitle" placeholder="Job Title" />
        </nz-form-control>
      </nz-form-item>
      <div formGroupName="company">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="name">Company Name</nz-form-label>
          <nz-form-control nzHasFeedback>
            <input #placesRef="ngx-places" ngx-gp-autocomplete [options]='placesApiOptions' nz-input id="companyName" formControlName="name" placeholder="Company Name" (onAddressChange)="handleAddressChange($event)" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="container">
          <nz-form-control class="upload-col">
            <div>
              <nz-upload class="avatar-uploader company-image-container" nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzCustomRequest]="uploadCompanyImage" tabindex="-1" (click)="selectImageContainer()">
                <ng-container class="upload-container" *ngIf="!checkForExistingImage()">
                  <i class="upload-icon" nz-icon [nzType]="'plus'"></i>
                  <div class="ant-upload-text">Upload</div>
                </ng-container>
                <div *ngIf="imageContainerSelected === true; then imageContainerIsSelected; else imageContainerIsNotSelected"></div>
                <ng-template #imageContainerIsSelected>
                  <img class="company-image" *ngIf="getImageUrlFromImageContainer()" [src]="getImageUrlFromImageContainer()" (error)="invalidImageUrl($event)" tabindex="-1" />
                </ng-template>
                <ng-template #imageContainerIsNotSelected>
                  <img class="company-image" *ngIf="getImageUrlFromInputBar()" [src]="getImageUrlFromInputBar()" (error)="invalidImageUrl($event, true)" tabindex="-1" />
                </ng-template>
              </nz-upload>
            </div>
            <input class="image-url-input" nz-input formControlName="imageUrl" placeholder="Image URL" tabindex="-1" (click)="$event.target.select()" (ngModelChange)="selectImageInputBar()" />
          </nz-form-control>
        </nz-form-item>
        <div id="left-align">
          <nz-form-item>
            <nz-form-label nzFor="url">Company Website</nz-form-label>
            <nz-form-control>
              <input nz-input id="companyUrl" formControlName="url" placeholder="URL" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>Company Details</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="industry" placeholder="Industry" [nzAutocomplete]="auto" />
              <nz-autocomplete #auto>
                <div *ngFor="let option of industryOptions" class="industry-list">
                  <nz-auto-option *ngIf="option" [nzLabel]="option" [nzValue]="option">
                    {{ option }}
                  </nz-auto-option>
                </div>
              </nz-autocomplete>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div id="left-align" formGroupName="companyLocation">
        <nz-form-item>
          <nz-form-control>
            <input nz-input id="country" formControlName="country" placeholder="Country" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <input nz-input id="administrative_area_level_1" formControlName="state" placeholder="State" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <input nz-input id="locality" formControlName="city" placeholder="City" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <input nz-input id="postal_code" formControlName="postCode" placeholder="Postcode" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <nz-form-item>
          <nz-form-label nzRequired>Duration</nz-form-label>
          <nz-form-control nzHasFeedback [nzErrorTip]="dateErrorTpl">
            <nz-date-picker formControlName="dateStart" nzPlaceHolder="Start"></nz-date-picker>
            <nz-date-picker formControlName="dateEnd" nzPlaceHolder="End"></nz-date-picker>
            <ng-template #dateErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"> You need to select a start date. </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
    <p class="modal-sub-title" nz-typography>Associate Goals</p>
    <div *ngFor="let sg of selectedGoals">
      <label class="goal-list-modal" nz-checkbox [(ngModel)]="sg.selected" ngDefaultControl> {{ sg.goal.specialisation }} {{ sg.goal.profession }} - {{ sg.goal.level }} </label>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button id="delete-experience-button" *ngIf="editMode" nz-button nzDanger nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this?" (nzOnConfirm)="deleteEmploymentExperience()">Delete</button>
    <button nz-button (click)="closeEmploymentExperienceModal()">Cancel</button>
    <button id="save-experience-button" nz-button nzType="primary" (click)="submitForm()" [disabled]="formValues.invalid">Save</button>
  </div>
</nz-modal>