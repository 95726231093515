import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPlaylistActivity } from '../models/playlist-activity.model';
import { IPlaylist } from '../models/playlist.model';
import { ISharedPlaylist } from '../models/shared-playlist.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  private controller = '/api/Playlist/';

  constructor(private http: HttpClient) { }

  createPlaylist(playlist: IPlaylist, activityCsvFile: File): Observable<IPlaylist> {
    if (!playlist) {
      throw new Error('Playlist cannot be undefined at this point.');
    }
    if (!activityCsvFile) {
      throw new Error('Activity CSV cannot be undefined at this point.');
    }

    const formData: FormData = new FormData();
    const blob = new Blob([JSON.stringify(playlist)], { type: 'application/json' });
    formData.append('playlist', blob, 'playlistJson');
    formData.append('fileKey', activityCsvFile, 'playlistActivities');

    const endpoint = this.controller + `CreatePlaylistWithCsv/`;
    return this.http.post<IPlaylist>(endpoint, formData);
  }

  updatePlaylistMetaData(playlist: IPlaylist): Observable<IPlaylist> {
    if (!playlist) {
      throw new Error('Playlist cannot be undefined at this point.');
    }

    const endpoint = this.controller + `UpdatePlaylistMetaData/`;
    return this.http.put<IPlaylist>(endpoint, playlist, httpOptions);
  }

  updatePlaylistActivities(playlist: IPlaylist, activityCsvFile: File): Observable<IPlaylist> {
    if (!playlist) {
      throw new Error('Playlist cannot be undefined at this point.');
    }

    const formData: FormData = new FormData();
    formData.append('fileKey', activityCsvFile, 'playlistActivities');

    const endpoint = this.controller + `UpdatePlaylistActivities?playlistId=${playlist.id}`;
    return this.http.put<IPlaylist>(endpoint, formData);
  }

  getVersionSpecificPlaylist(playlistId: number, playlistVersion: number): Observable<IPlaylist> {
    if (!playlistId) {
      throw new Error('Playlist id cannot be undefined at the point');
    }

    const endpoint = this.controller + `GetVersionSpecificPlaylist/`;
    return this.http.get<IPlaylist>(endpoint, { params: { playlistId: playlistId.toString(), playlistVersion: playlistVersion.toString() } });
  }

  getPlaylist(playlistId: number): Observable<IPlaylist> {
    if (!playlistId) {
      throw new Error('Playlist ID cannot be undefined at this point.');
    }

    const endpoint = this.controller + 'GetPlaylist/';
    return this.http.get<IPlaylist>(endpoint, { params: { playlistId: playlistId.toString() } });
  }

  getPlaylistsAll(): Observable<IPlaylist[]> {
    const endpoint = this.controller + 'GetPlaylists/';
    return this.http.get<IPlaylist[]>(endpoint);
  }

  getPlaylistsCreated(): Observable<IPlaylist[]> {
    const endpoint = this.controller + 'GetPlaylistsCreated/';
    return this.http.get<IPlaylist[]>(endpoint);
  }

  getAvailableUserPlaylistVersions(playlistId: number): Observable<number[]> {
    const endpoint = this.controller + `GetAvailableUserPlaylistVersions/`;
    return this.http.get<number[]>(endpoint, { params: { playlistId: playlistId.toString() } });
  }

  getPlaylistActivities(playlistId: number): Observable<IPlaylistActivity[]> {
    const endpoint = this.controller + 'GetPlaylistActivities/';
    return this.http.get<IPlaylistActivity[]>(endpoint, { params: { playlistId: playlistId.toString() } });
  }

  getPlaylistForUserPlaylist(userPlaylistId: number): Observable<IPlaylist> {
    const url = this.controller + 'GetPlaylistFromUserPlaylist/';
    return this.http.get<IPlaylist>(url, { params: { userPlaylistId: userPlaylistId.toString() } });
  }

  exportPlaylist(playlistId: number): Observable<string> {
    if (!playlistId) {
      throw new Error('Playlist ID cannot be undefined when trying to export a playlist.');
    }

    const headersText = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const endpoint = this.controller + `ExportPlaylist/`;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      headers: headersText,
      responseType: 'blob' as const,
      params: { playlistId: playlistId.toString() },
    };

    return this.http.get<string>(endpoint, requestOptions);
  }

  exportPlaylistTemplate(): Observable<string> {
    const endpoint = this.controller + `ExportPlaylistTemplate/`;
    const headersText = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    // eslint-disable-next-line @typescript-eslint/ban-types
    const requestOptions: Object = {
      headers: headersText,
      responseType: 'text' as const,
    };
    return this.http.get<string>(endpoint, requestOptions);
  }

  retirePlaylist(playlistId: number): Observable<number> {
    if (!playlistId) {
      throw new Error('Playlist ID cannot be undefined when trying to retire a playlist.');
    }

    const params = new HttpParams().set('id', String(playlistId));
    const options = { headers: httpOptions.headers, params };
    const endpoint = this.controller + `RetirePlaylist`;

    return this.http.delete<number>(endpoint, options);
  }

  unRetirePlaylist(playlistId: number): Observable<number> {
    if (!playlistId) {
      throw new Error('Playlist ID cannot be undefined when trying to unretire a playlist.');
    }

    const params = new HttpParams().set('id', String(playlistId));
    const options = { headers: httpOptions.headers, params };
    const endpoint = this.controller + `UnRetirePlaylist`;

    return this.http.delete<number>(endpoint, options);
  }

  deletePlaylistAdmin(playlistId: number): Observable<number> {
    if (!playlistId) {
      throw new Error('Playlist ID cannot be undefined when trying to delete a playlist.');
    }

    const params = new HttpParams().set('id', String(playlistId));
    const options = { headers: httpOptions.headers, params };
    const endpoint = this.controller + `DeletePlaylist`;

    return this.http.delete<number>(endpoint, options);
  }

  getSharedPlaylistsForGuild(guildId: number): Observable<ISharedPlaylist[]> {
    if (!guildId) {
      throw new Error(`Guild Id can't be underfined when trying to get the shared playlists`);
    }

    const endpoint = this.controller + 'GetSharedPlaylistsForGuild/';

    return this.http.get<ISharedPlaylist[]>(endpoint, { params: { guildId: guildId.toString() } });
  }

  getUserSuggestedPlaylists(): Observable<IPlaylist[]> {
    const endpoint = this.controller + 'GetUserSuggestedPlaylists/';
    return this.http.get<IPlaylist[]>(endpoint);
  }

  sharePlaylistWithGuild(guildId: number, playlistId: number): Observable<ISharedPlaylist> {
    const endpoint = this.controller + 'SharePlaylistWithGuild/';
    return this.http.post<ISharedPlaylist>(endpoint, { options: { observe: 'response', responseType: 'json' } }, { params: { guildId: guildId.toString(), playlistId: playlistId.toString() } });
  }

  unsharePlaylistFromGuild(sharedPlaylistId: number, guildId: number): Observable<ISharedPlaylist> {
    if (!sharedPlaylistId) {
      throw new Error(' SharedPlaylist ID cannot be undefined at this point.');
    }

    if (!guildId) {
      throw new Error('Guild ID cannot be undefined at this point.');
    }

    const params = new HttpParams().set('sharedPlaylistId', String(sharedPlaylistId)).set('guildId', String(guildId));
    const options = { headers: httpOptions.headers, params };
    const url = this.controller + `UnsharePlaylistFromGuild/`;
    return this.http.delete<ISharedPlaylist>(url, options);
  }

  updateSharedPlaylistVersion(sharedPlaylistId: number, guildId: number, updatedPlaylistVersionId: number): Observable<ISharedPlaylist> {
    if (!sharedPlaylistId) {
      throw new Error('SharedPlaylist ID cannot be undefined at this point.');
    }

    if (!guildId) {
      throw new Error('Guild ID cannot be undefined at this point.');
    }

    if (!updatedPlaylistVersionId) {
      throw new Error('We need to know the ID of the updated playlist at this point.');
    }

    const endpoint = this.controller + 'UpdateSharedPlaylistVersion/';

    return this.http.post<ISharedPlaylist>(endpoint, { options: { observe: 'response', responseType: 'json' } }, { params: { guildId: guildId.toString(), sharedPlaylistId: sharedPlaylistId.toString(), updatedPlaylistVersionId: updatedPlaylistVersionId.toString() } });
  }

  cleanEmptyPlaylistActivities(): Observable<IPlaylistActivity[]> {
    const url = this.controller + `CleanEmptyPlaylistActivities`;
    return this.http.post<IPlaylistActivity[]>(url, '', httpOptions);

  }
}
