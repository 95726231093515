<nz-spin nzTip="Loading..." [(nzSpinning)]="isLoading">
  <div *ngIf="!isLoading">
    <div class="invitation">
      <div class="invitation-header">
        <div *ngIf="!(invitationAll.length > 0)">
          <div *ngIf="isRequested; else elseBlock" nzTooltipTitle="Pending Admin Review" nz-tooltip><button disabled nz-button class="requestBtn">Invitation Requested</button></div>
          <ng-template #elseBlock><button nz-button class="requestBtn" (click)="requestGenerateInvitations()">Request Invitation Code</button></ng-template>
        </div>
      </div>
      <div *ngIf="invitationAll.length > 0">
        <nz-table class="invitations-table" #InvitationsTable [nzData]="invitationAll" [nzHideOnSinglePage]="true" [(nzPageSizeOptions)]="tablePageSize" nzShowSizeChanger [nzPageSize]="5">
          <tr>
            <th>Invitation Code</th>
            <th>Uses Remaining</th>
            <th>Request Increase</th>
          </tr>
          <tbody>
            <tr *ngFor="let invitation of InvitationsTable.data">
              <td>
                {{ invitation.inviteCode }}<a nz-tooltip nzTooltipTitle="Copy to clipboard"> <i nz-icon nzType="copy" nzTheme="outline" id="copy-icon" (click)="copyUrl(invitation)"></i></a>
              </td>
              <td>{{ invitation.amountAllocated - invitation.amountUsed }}</td>
              <td>
                <label id="isRequesting">
                  <span nz-tooltip [nzTooltipTitle]="getInvitationRequestToolTip(invitation)">
                    <input class="RequestStatus" type="checkbox" [checked]="invitation.requestStatus" (change)="toggleRequestStatus(invitation)" [disabled]="!invitation || disableRequestMore(invitation)" />
                  </span>
                </label>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</nz-spin>