<!-- Displays user created playlists -->
<div class="my-playlists">
  <div class="header">
    <div class="component-title">
      <div class="title-logo">
        <span nz-icon nzType="bars" nzTheme="outline"></span>
      </div>
      <div class="title-text">MY PLAYLISTS</div>
      <button id="create-playlist-button" class="title-button" nz-button nzType="'Primary'" [nzShape]="'circle'" (click)="showModal()">
        <span nz-tooltip nzTooltipTitle="Create a new Playlist">
          <i class="fa fa-plus"></i>
        </span>
      </button>
    </div>

    <div *ngFor="let playlist of playlists">
      <sl-my-playlists id="playlist-{{ playlist.id }}" class="playlist-view" *ngIf="playlist" [playlist]="playlist" [learningTypes]="learningTypes" (handleRetirePlaylist)="retirePlaylist($event)"></sl-my-playlists>
    </div>
    <nz-skeleton id="loading-playlists-skeleton" *ngIf="isLoadingPlaylists" [nzAvatar]="true" [nzParagraph]="{ rows: 5 }" [nzActive]="true"></nz-skeleton>
  </div>

  <div *ngIf="!isLoadingPlaylists" class="create-playlist-card-container">
    <nz-card *ngIf="!playlists.length" id="create-playlist-card" nzType="inner" nzTitle="Create New Playlist" nzHoverable (click)="showModal()">
      <i class="fas fa-plus-circle fa-4x add-track-button"></i>
      <p class="card-description">Create your own Playlists and share them with people you know.</p>
    </nz-card>
  </div>

  <!-- Create Playlist Modal -->
  <nz-modal id="create-playlist-modal" [(nzVisible)]="isVisible" [nzContent]="modalContent" [nzFooter]=null (nzOnCancel)="closeModal()" (nzOnOk)="closeModal()" nzWidth="60%">
    <ng-template #modalContent>
      <sl-create-playlist (handlePlaylistCreated)="playlistCreated($event)" (handlePlaylistUpdated)="playlistUpdated($event)"></sl-create-playlist>
    </ng-template>
  </nz-modal>
</div>