<div class="my-guild-icons-container">
  <nz-radio-group [(ngModel)]="isHomeGuildRoute() ? defaultValue : radioValue">
    <div id="my-guild" *ngFor="let guild of myGuilds">
      <div nz-radio-button [nzValue]="guild.id" class="icon-button card-style ant-card ant-card-bordered ant-card-hoverable" nz-tooltip nzTooltipPlacement="right" nzTooltipTitle="{{guild.name}}" (click)="selectGuild(guild)">
        <div *ngIf="!guild.imageUrl" class="my-guilds-icon {{guild.imageIcon}}"></div>
        <div *ngIf="!guild.imageUrl && !guild.imageIcon" class="my-guilds-letter">{{guild.name[0]}}</div>
        <img *ngIf="guild.imageUrl" class="my-guilds-image" src="{{guild.imageUrl}}">
        <i *ngIf="guild.visibility === guildVisibilityType.Private" class="private-guild-icon fa-solid fa-lock"></i>
      </div>
    </div>
  </nz-radio-group>
</div>