import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

import { GtmService } from './gtm.service';
import { getWindow } from './helper.service';
@Injectable()
export class RoutingStateService {
  private history: string[] = [];
  private location: Location;

  constructor(private router: Router, location: Location, private gtmService: GtmService) {
    this.location = location;
  }

  public loadRouting(): void {
    // TODO: ******************** FIX THIS *******************
    // as a result of the angular upgrade and type upgrade, this is no longer valid. Uncomment to find out why.

    // this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
    //     this.history = [...this.history, urlAfterRedirects];
    //   }
    // );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.gtmService.triggerEvent('page', { pageName: event.url });
      }
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }

  public getHostName(): string {
    const window = getWindow();
    const hostName = window.location.hostname;
    if (hostName.toLowerCase().includes('localhost')) {
      return `${hostName}:${window.location.port}`;
    }
    return hostName;
  }
}
