import { Component, OnInit } from '@angular/core';
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { AppDetailsService } from 'src/app/services/app-details.service';

@Component({
  selector: 'sl-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.less'],
})
export class UserDetailsComponent implements OnInit {
  version = '';
  isAdminOrTest = false;
  constructor(private appDetailsService: AppDetailsService, private accountDetailsService: AccountDetailsService) { }

  ngOnInit(): void {
    this.appDetailsService.getAppDetailsData().subscribe((res) => {
      //This code is to hide the Billing tab on the production site. To be removed when Billing functionality is complete.
      this.version = res.version;

      if (this.isProduction()) {
        // only call the following if we are on the production site
        this.accountDetailsService.getIsAdmin().subscribe(
          (isAdmin) => {
            if (isAdmin) {
              this.isAdminOrTest = true;
            }
          });

        this.accountDetailsService.getIsTest().subscribe(
          (isTest) => {
            if (isTest) {
              this.isAdminOrTest = true;
            }
          });
      }
    });
  }

  isProduction(): boolean {
    if (this.version.includes('P:')) {
      return true;
    }

    return false;
  }
}
