<nz-skeleton *ngIf="isLoading"></nz-skeleton>
<div *ngIf="skillDuplicates && !isLoading">
  <div *ngIf="skillDuplicates?.length > 0">
    <button id="removeAllDuplicatesButton" nz-button nzType="primary" (click)="clearTrackSkillDuplicates()" [nzLoading]="isLoadingRemoveAll">Remove All</button>
    <nz-table nzTemplateMode>
      <thead>
        <tr>
          <th>Track Name</th>
          <th>Number of Duplicates</th>
          <th>Skill Name</th>
          <th>Creator Email</th>
          <th>Creator Name</th>
          <th>Created Date</th>
          <th>Track Skill ID</th>
          <th>Track ID</th>
          <th>Skill ID</th>
          <th>Remove?</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let duplicate of skillDuplicates; let i = index">
          <td>{{duplicate.trackName}}</td>
          <td>{{duplicate.duplicateCount}}</td>
          <td>{{duplicate.skill.name}}</td>
          <td>{{duplicate.createdByEmail}}</td>
          <td>{{duplicate.createdByName}}</td>
          <td>{{duplicate.dateCreated}}</td>
          <td>{{duplicate.trackSkillId}}</td>
          <td>{{duplicate.trackId}}</td>
          <td>{{duplicate.skill.id}}</td>
          <td><button id="{{'removeDuplicateButton' + i}}" (click)="clearTrackSkillDuplicate(i)">Remove</button></td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<div *ngIf="skillDuplicates?.length === 0">
  <h2 class="text-center" id="noDuplicatesMessage">There are currently no track skill duplicates.</h2>
</div>