<div class="content">
  <div class="title">
    <div class="suggestions-div">
      <h2>User Activity</h2>
    </div>
  </div>

  <div *ngIf="loading">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <div *ngIf="!loading && userActivities" class="table-div">
    <div id="table-pagination-switch">
      <h4>Pagination</h4>
      <nz-switch [(ngModel)]="pagination">Pagination</nz-switch>
    </div>
    <nz-table #dynamicTable [nzData]="userActivities" class="table table-hover table-bordered table-fixed" [nzShowPagination]="pagination" [nzFrontPagination]="pagination">
      <thead>
        <tr>
          <th *ngFor="let column of listOfColumns" [nzSortOrder]="column.sortOrder" [nzSortFn]="column.sortFn" [nzSortDirections]="column.sortDirections" [nzFilters]="column.listOfFilter" [nzFilterFn]="column.filterFn">
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let activity of dynamicTable.data" [ngClass]="{'flagged': activity.flagged === true}">
          <td>{{ activity.id }}</td>
          <td>{{ activity.userId }}</td>
          <td class="table-title" nz-tooltip nzTooltipTitle="{{activity.title}}">{{ activity.title }}</td>
          <td>{{ activity.providerName }}</td>
          <td nz-tooltip nzTooltipTitle="{{activity.link}}">
            {{
            activity.link.length > 30
            ? (activity.link | slice: 0:21) + "..." + (activity.link | slice: activity.link.length - 9:activity.link.length)
            : activity.link
            }}
          </td>
          <td>{{ activity.specialisation }}</td>
          <td>{{ activity.authors }}</td>
          <td>{{ activity.status }}</td>
          <td>{{ activity.dateCompleted }}</td>
          <td>{{ activity.dateStart }}</td>
          <td>{{ activity.dateAdded }}</td>
          <td>{{ activity.datePlannedStart }}</td>
          <td class="table-comments" nz-tooltip nzTooltipTitle="{{activity.comments}}">{{ activity.comments }}</td>
          <td>{{ activity.hidden }}</td>
          <td>{{ getSkillsString(activity) }}</td>
          <td>{{ activity.flagged }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
