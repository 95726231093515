import { AfterViewInit, Component } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { NzCardModule } from 'ng-zorro-antd/card';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { IPricingPlan } from 'src/app/models/pricing-plan.model';


@Component({
  selector: 'sl-pricing',
  standalone: true,
  imports: [CommonModule, NzModalModule, NzButtonModule, NzCardModule],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.less'
})
export class PricingComponent implements AfterViewInit {

  public pricingPlans: IPricingPlan[] = [];

  constructor(private router: Router) {
    this.initPricingPlans();
  }

  public initPricingPlans(): void {
    this.pricingPlans = [{
      title: "Student",
      price: {
        original: 5,
        discounted: 1,
      },
      description: "Try us out free of charge, no credit card required!",
      features: [
        "1 month free trial",
        "Discount 80% for 3 months",
        "Unlimited goals",
        "Personalised learning",
        "Cohort onboarding",
        "Activity tracking",
        "Centralise accounts",
        "Access to Guilds"
      ]
    },
    {
      title: "Pro",
      price: {
        original: 10,
        discounted: 4,
      },
      description: "Try us out free of charge, no credit card required!",
      features: [
        "1 month free trial",
        "Discount 60% for 3 months",
        "Unlimited goals",
        "Personalised learning",
        "Cohort onboarding",
        "Activity tracking",
        "Centralise accounts",
        "Access to Guilds",
        "Mentorship opportunities",
        "Guild leadership opportunities",
      ]
    }];
  }

  ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".pricing-info", {
      transform: "translateX(0)",
      duration: 1.5,
      scrollTrigger: {
        trigger: ".pricing-info",
      }
    });
  }

  onJoinNow() {
    const currentDate = new Date();
    const googleFormExpiryDate = new Date(currentDate.getFullYear(), 7, 31);

    if (currentDate <= googleFormExpiryDate) {
      window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdE1Magd2x8oHdqsMoBmJ8mV1SF2sb4DmvTXCrUXWvRAMYv-w/viewform";
    } else {
      this.router.navigate(['register']);
    }
  }
}
