<nz-card nzTitle="Create Action">
  <form class="grid-container" id="action-form" nz-form [formGroup]="actionGroup">
    <div class="grid-action-form">
      <nz-steps id="action-steps" class="step" [nzCurrent]="stepIndex" nzDirection="vertical" (nzIndexChange)="onStepIndexChange($event)">
        <nz-step id="action-name-step" nzTitle="Enter the name of the action" [nzStatus]="checkIfActionNameExists() ? 'finish' : 'process'" [nzDescription]="actionNameInput">
          <ng-template #actionNameInput>
            <div class="form-item-name-container">
              <nz-form-item id="form-item-name">
                <nz-form-control id="form-name-control" nzHasFeedback nzErrorTip="Enter the Name of the Action">
                  <nz-input-group id="action-name" nzPrefixIcon="bars">
                    <input id="form-name" formControlName="title" nz-input placeholder="Action Name" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <button id="action-edit" nz-button [nzType]="'primary'" (click)="showModal()">
                <span><i nz-icon nzType="edit" nzTheme="outline"></i> Edit Actions</span>
              </button>
            </div>
          </ng-template>
        </nz-step>
        <nz-step
          id="action-industry-step"
          class="step"
          nzTitle="Select an Industry"
          [nzStatus]="actionTitleEntered ? (industrySelected ? 'finish' : 'process') : 'wait'"
          [nzSubtitle]="requiredIcon"
          [nzDescription]="actionTitleEntered ? industryOptions : null"
          [nzDisabled]="actionTitleEntered"
        >
          <ng-template #industryOptions>
            <sl-industry-selector id="select-industry" (selectIndustryEvent)="initSelectedIndustry($event)" [currentMode]="mode" [previouslySelectedIndustry]="actionCreated.industry"></sl-industry-selector>
          </ng-template>
        </nz-step>
        <nz-step
          id="action-profession-step"
          class="step"
          nzTitle="Select a Profession"
          [nzStatus]="industrySelected ? (professionSelected ? 'finish' : 'process') : 'wait'"
          [nzSubtitle]="requiredIcon"
          [nzDescription]="industrySelected ? professionOptions : null"
          [nzDisabled]="!industrySelected"
        >
          <ng-template #professionOptions>
            <sl-profession-selector
              id="select-profession"
              [selectedIndustry]="actionCreated.industry"
              [previouslySelectedIndustry]="actionCreated.industry"
              [previouslySelectedProfession]="actionCreated.profession"
              [currentMode]="mode"
              [allowAllProfessions]="true"
              (selectProfessionEvent)="initSelectedProfession($event)"
            >
            </sl-profession-selector>
          </ng-template>
        </nz-step>
        <nz-step
          id="action-specialisation-step"
          class="step"
          nzTitle="Select a Specialisation"
          [nzStatus]="professionSelected ? (specialisationSelected ? 'finish' : 'process') : 'wait'"
          [nzSubtitle]="requiredIcon"
          [nzDescription]="professionSelected ? specialisationOptions : null"
          [nzDisabled]="professionSelected !== undefined"
        >
          <ng-template #specialisationOptions>
            <sl-specialisation-selector
              id="select-specialisation"
              [selectedIndustry]="actionCreated.industry"
              [selectedProfession]="actionCreated.profession"
              [previouslySelectedSpecialisation]="actionCreated.specialisation"
              [previouslySelectedIndustry]="actionCreated.industry"
              [previouslySelectedProfession]="actionCreated.profession"
              [currentMode]="mode"
              (selectSpecialisationEvent)="initSelectedSpecialisation($event)"
            >
            </sl-specialisation-selector>
          </ng-template>
        </nz-step>
        <nz-step
          id="action-level-step"
          class="step"
          nzTitle="Select Level Range(s)"
          [nzStatus]="specialisationSelected !== undefined ? (levelFromSelected && levelToSelected ? 'finish' : 'process') : 'wait'"
          [nzDescription]="specialisationSelected !== undefined ? levelOptions : null"
          [nzDisabled]="specialisationSelected !== undefined"
        >
          <ng-template #levelOptions>
            <sl-level-selector
              id="select-level"
              (selectLevelFromEvent)="initSelectedLevelFrom($event)"
              (selectLevelToEvent)="initSelectedLevelTo($event)"
              [currentMode]="mode"
              [previouslySelectedLevelFrom]="actionCreated.levelFrom"
              [previouslySelectedLevelTo]="actionCreated.levelTo"
            ></sl-level-selector>
          </ng-template>
        </nz-step>
        <nz-step
          id="action-track-step"
          class="step"
          nzTitle="Select a Track"
          [nzStatus]="levelFromSelected && levelToSelected ? (trackSelected ? 'finish' : 'process') : 'wait'"
          [nzDescription]="levelFromSelected && levelToSelected ? trackOptions : null"
          [nzDisabled]="!levelFromSelected || !levelToSelected"
        >
          <ng-template #trackOptions>
            <sl-track-selector
              id="select-track"
              [selectedIndustry]="actionCreated.industry"
              [selectedProfession]="actionCreated.profession"
              [selectedSpecialisation]="actionCreated.specialisation"
              [selectedLevelFrom]="actionCreated.levelFrom"
              [selectedLevelTo]="actionCreated.levelTo"
              [previouslySelectedIndustry]="actionCreated.industry"
              [previouslySelectedProfession]="actionCreated.profession"
              [previouslySelectedSpecialisation]="actionCreated.specialisation"
              [previouslySelectedLevelFrom]="actionCreated.levelFrom"
              [previouslySelectedLevelTo]="actionCreated.levelTo"
              [previouslySelectedTrackName]="actionCreated.relatedTrackName"
              [currentMode]="mode"
              (selectTrackEvent)="initSelectedTrack($event)"
            >
            </sl-track-selector>
          </ng-template>
        </nz-step>
        <nz-step
          id="action-description-step"
          class="step"
          nzTitle="Write a description"
          [nzStatus]="trackSelected ? (checkIfActionDescriptionExists() ? 'finish' : 'process') : 'wait'"
          [nzDescription]="trackSelected ? actionDescription : null"
          [nzDisabled]="!trackSelected"
        >
          <ng-template #actionDescription>
            <div class="form-description-container">
              <nz-form-item>
                <nz-form-control>
                  <!--NOTE: 2022.07.25 This nzHasFeedback has problems with causing a recursive update in the tests, specifically `should upload an image in the image container` -->
                  <textarea id="form-description" nz-input formControlName="description" placeholder="Enter a helpful description to explain what the action is for" [nzAutosize]="{ minRows: 3, maxRows: 5 }"></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-template>
        </nz-step>
        <nz-step id="action-image-step" class="step" nzTitle="Upload an Image" [nzStatus]="trackSelected ? (checkIfActionImageExists() ? 'finish' : 'process') : 'wait'" [nzDescription]="trackSelected ? actionImage : null" [nzDisabled]="!trackSelected">
          <ng-template #actionImage>
            <nz-form-item class="image-container">
              <nz-form-control class="upload-col">
                <div>
                  <nz-upload
                    class="company-image-container avatar-uploader"
                    nzName="avatar"
                    id="form-playlistActivitiesVm"
                    nzListType="picture-card"
                    [nzShowUploadList]="false"
                    [nzBeforeUpload]="beforeUpload"
                    [nzCustomRequest]="uploadActionImage"
                    (click)="selectImageContainer()"
                  >
                    <ng-container *ngIf="!checkForExistingImage()">
                      <i class="upload-icon" nz-icon [nzType]="isLoadingImage ? 'loading' : 'plus'"></i>
                      <div class="ant-upload-text">Upload</div>
                    </ng-container>
                    <div *ngIf="imageContainerSelected === true; then imageContainerIsSelected; else imageContainerIsNotSelected"></div>
                    <ng-template #imageContainerIsSelected>
                      <img id="action-image" style="width: 100%" *ngIf="getImageUrlFromImageContainer()" (error)="invalidImageUrl($event)" [src]="getImageUrlFromImageContainer()" />
                    </ng-template>
                    <ng-template #imageContainerIsNotSelected>
                      <img id="action-image" style="width: 100%" *ngIf="getImageUrlFromInputBar()" (error)="invalidImageUrl($event)" [src]="getImageUrlFromInputBar()" />
                    </ng-template>
                  </nz-upload>
                </div>
                <input class="image-url-input" nz-input formControlName="imageUrl" placeholder="Image URL" tabindex="-1" (click)="$event.target.select()" (ngModelChange)="selectImageInputBar()" />
              </nz-form-control>
            </nz-form-item>
          </ng-template>
        </nz-step>
        <ng-template #requiredIcon
          ><sup><i class="fa-solid fa-asterisk required-icon"></i></sup
        ></ng-template>
      </nz-steps>
    </div>
    <nz-form-item class="grid-submit">
      <nz-form-control>
        <button id="submit-action" type="submit" *ngIf="mode === modeType.new" nz-button nzType="primary" [disabled]="!validateAllStepInputs() || !validateAllControls()" (click)="submitAction()" [nzLoading]="isLoadingRequest">
          <i nz-icon nzType="send" nzTheme="outline"></i>Submit
        </button>
        <button id="submit-action" *ngIf="mode === modeType.edit" nz-button nzType="primary" [disabled]="!validateAllStepInputs() || !validateAllControls()" (click)="submitActionUpdates()" [nzLoading]="isSubmittingAction">
          <i nz-icon nzType="send" nzTheme="outline"></i>Update
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-card>

<nz-modal id="edit-modal" [(nzVisible)]="isVisibleExisting" nzTitle="Select an Action to Edit" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzOkDisabled]="actionExistingSelectedId === undefined">
  <ng-container *nzModalContent>
    <nz-spin *ngIf="loading.existing" nzSimple [nzSize]="'large'"></nz-spin>

    <nz-radio-group *ngIf="!loading.existing" [(ngModel)]="actionExistingSelectedId">
      <label id="edit-action-radio-button" *ngFor="let actionExisting of actionsExisting" nz-radio [nzValue]="actionExisting.id">{{ actionExisting.title }} - {{ actionExisting.dateCreated | date : 'shortDate' }}</label>
    </nz-radio-group>
  </ng-container>
</nz-modal>
