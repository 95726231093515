import { GoalService } from './goal.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, take } from 'rxjs';

@Injectable()
export class AccessGoalPageGuard  {
  private goalsUpdated = new Subject<boolean>();
  private hasGoals = false;

  constructor(private goalService: GoalService, private router: Router) {
    console.log('*** AccessGoalPage Guard is constructing');
    this.goalService.getGoalsUpdatedEvent().subscribe(() => {
      this.updateHasGoals();
    });
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.goalService.isInitialised) {
      return this.goalService.getGoalsUpdatedEvent().pipe(take(1));
    }

    this.updateHasGoals();

    return !this.hasGoals;
  }

  private updateHasGoals() {
    this.hasGoals = this.goalService.getGoals().length > 0;
    console.log(`*** AccessGoalPageGuard: hasGoals update to: ${this.hasGoals}`);
    this.goalsUpdated.next(this.hasGoals);
    if (this.hasGoals) {
      this.router.navigate(['profile/overview'], { replaceUrl: true });
    }
  }
}
