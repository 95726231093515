import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Modules
import { NzMessageService } from 'ng-zorro-antd/message';

// Services
import { UserActivitiesService } from 'src/app/services/user-activities.service';

// Models
import { IUnassignedActivity } from 'src/app/models/unassigned-activity.model';
import { IGoal } from 'src/app/models/user-goal.model';
import { IProvider } from 'src/app/models/provider.model';

@Component({
  selector: 'sl-unassigned-activities',
  templateUrl: './unassigned-activities.component.html',
  styleUrls: ['./unassigned-activities.component.less']
})
export class UnassignedActivitiesComponent implements OnInit {
  @Input() goal!: IGoal;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() closeAssignActivitiesModalEmitter = new EventEmitter<any>();
  isDisabled = true;
  isLoading = false;
  unassignedActivities!: IUnassignedActivity[];

  unknownActivityProvider: IProvider = {
    name: 'Unknown',
    icon: 'fa fa-circle-question',
    logo: '',
    logothumbnail: '',
    type: '',
    connected: false,
    username: '',
    lastRefreshed: new Date(),
  };

  constructor(private userActivitiesService: UserActivitiesService, private message: NzMessageService) { }

  ngOnInit() {
    this.getUnassignedActivities();
    this.disabledButton();
    if (!this.goal.id) {
      throw new Error('Goal id is undefined');
    }
  }

  getUnassignedActivities() {
    const goalId = this.goal.id as number;  // This is checked in the ngOnInit
    this.isLoading = true;
    this.userActivitiesService.getUnassignedActivities(goalId).subscribe({
      next: (activities) => {
        activities.filter(ua => ua.provider === null).forEach(ua => ua.provider = this.unknownActivityProvider);
        this.unassignedActivities = activities;
        this.disabledButton();
        this.isLoading = false;
      },
      error: (error) => {
        console.error(`Could not get unassigned activities: ${JSON.stringify(error)}`);
        this.message.error(`Something went wrong. Please try again.`);
        this.isLoading = false;
      }
    });
  }

  disabledButton() {
    this.isDisabled = !this.unassignedActivities.some(ua => ua.assignToGoal);
  }

  submit() {
    const userActivityIds = this.unassignedActivities.filter(sa => sa.assignToGoal === true).map(sa => sa.id);

    this.userActivitiesService.assignUserActivitiesToGoal(userActivityIds, this.goal.id ?? 0).subscribe({
      next: (userActivities) => {
        this.closeAssignActivitiesModalEmitter.emit();
        if (userActivityIds.length !== userActivities.length) {
          console.error(`*** UnassignedActivitiesComponent: submit, the number of activities assigned to the goal (${userActivities.length}) does not match the number of activities that were requested to be assigned (${userActivityIds.length})`);
          this.message.error(`${userActivities.length} activities were assigned to your goal, but ${userActivityIds.length} activities were requested to be assigned. Please try again in a moment`);
        }
        this.message.success(`${userActivities.length} activities were assigned to your goal`);
      },
      error: (error) => {
        console.error(`Could not add activities to goal: ${JSON.stringify(error)}`);
        this.message.error(`Something went wrong when trying to add the activities to the goal. Please try again in a moment`);
      }
    });
  }
}
