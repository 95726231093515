<div class="tabs">
  <nz-tabset nzType="card">
    <nz-spin [nzSpinning]="isLoading">
      <nz-tab *ngFor="let emailType of emailTypes" nzClosable [nzTitle]="emailType.name">
        <ng-template nz-tab>
          <sl-email-message-templates [emailType]="emailType"></sl-email-message-templates>
        </ng-template>
      </nz-tab>
    </nz-spin>
  </nz-tabset>
</div>
