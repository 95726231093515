import { NzMessageService } from 'ng-zorro-antd/message';
import { IActivityType } from 'src/app/models/activity-type.model';
import { IGuild } from './../../models/guild.model';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// services
import { GuildService } from 'src/app/services/guild.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';

// models
import { IGuildTopActivities } from '../../models/guild-top-activities.model';
import { IUserActivity } from 'src/app/models/user-activity.model';
import { IGuildTopActivityTypeCompleted } from '../../models/guild-top-activity-type.model';
import { GoalService } from 'src/app/services/goal.service';
import { IGoal } from 'src/app/models/user-goal.model';
import { GuildMemberStatus } from '../../types/guild-member-status.type';
import { EGoalSourceType } from 'src/app/types/goal-source-type.type';
import { ActivatedRoute, Router } from '@angular/router';
import { IRecommendation } from 'src/app/models/recommendation.model';
import { map } from 'rxjs';
import { IGuildMember } from '../../models/guild-member.model';

@Component({
  selector: 'sl-guild-top-activities',
  templateUrl: './guild-top-activities.component.html',
  styleUrls: ['./guild-top-activities.component.less'],
})
export class GuildTopActivitiesComponent implements OnChanges {
  @Input() guild: IGuild = {} as IGuild;
  @Input() selectedGuildMembers: IGuildMember[] = [];
  @Input() selectMode!: boolean;
  @Input() guildUrlName!: string;

  isEditable = true;
  isPercentage = false;
  topActivities: IGuildTopActivities = { activities: [] as IGuildTopActivityTypeCompleted[] } as IGuildTopActivities;
  userActivities: IUserActivity[] = [];
  selectedActivity!: IGuildTopActivityTypeCompleted;
  returnedActivityGoals: IGoal[] = [];
  selectedGoal!: IGoal | null;
  goalSelectorVisible = false;
  numberOfActivitiesCompleted = 0;
  percentageOfActivitiesCompleted = 0;
  readonly placeholderImage = 'https://i.stack.imgur.com/y9DpT.jpg';
  guildMemberStatus = GuildMemberStatus;
  goalSelectorComponentType = EGoalSourceType;
  topActivitiesRecommendations: IRecommendation[] = [];
  topActivityRecommendation: IRecommendation = {} as IRecommendation;
  topActivityRecommendationShorterUrl = '';
  topActivityRecommendationFile!: string;
  showRecommendationsDrawer = false;
  isLoadingGuildTopActivities = false

  constructor(private userActivitiesService: UserActivitiesService, private guildService: GuildService, private goalService: GoalService, private message: NzMessageService, private route: ActivatedRoute, private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.guild && !this.selectMode) {
      if (this.guild.id) {
        this.getGuildTopActivities();
      } else {
        this.topActivities.activities = [];
        this.topActivitiesRecommendations = [];
      }
    }

    if (changes.selectMode) {
      if (!this.selectMode) {
        this.getGuildTopActivities();
        this.setNumberAndPercentageOfActivitiesCompleted();
      }
    }
  }

  showDrawer(activity: IGuildTopActivityTypeCompleted) {
    const topActivityRecommendationIndex = this.topActivitiesRecommendations.findIndex(tar => activity.activityTypeId === tar.activityId);
    this.selectedActivity = activity;
    this.topActivityRecommendation = this.topActivitiesRecommendations[topActivityRecommendationIndex];

    this.setTopActivityRecommendationFileUrl();
    this.getShorterTopActivityRecommendationUrl();
    this.showRecommendationsDrawer = true;
  }

  closeDrawer() {
    this.showRecommendationsDrawer = false;
  }

  getShorterTopActivityRecommendationUrl() {
    const indexOfFirst = this.topActivityRecommendation.link.indexOf('://') + 3;
    const indexOfSecond = this.topActivityRecommendation.link.indexOf('/', indexOfFirst + 1);
    this.topActivityRecommendationShorterUrl = this.topActivityRecommendation.link.substring(indexOfFirst, indexOfSecond);
  }

  setTopActivityRecommendationFileUrl() {
    // priority; 1: normal url, 2: stored normal url, 3: larger url, 4: default "type" image
    // Check each condition in order of priority and assign the first valid image source
    if (this.topActivityRecommendation.imageThumbnailUrl != null) {
      this.topActivityRecommendationFile = this.topActivityRecommendation.imageThumbnailUrl;
    }
    else if (this.topActivityRecommendation.imageThumbnail != null) {
      this.topActivityRecommendationFile = 'data:image/jpeg;base64,' + this.topActivityRecommendation.imageThumbnail;
    }
    else if (this.topActivityRecommendation.imageFullSizeUrl != null) {
      this.topActivityRecommendationFile = this.topActivityRecommendation.imageFullSizeUrl;
    }
    else {
      // Default to a placeholder image or base64 encoded string
      this.topActivityRecommendationFile = this.placeholderImage;
    }
  }

  toggleSwitch() {
    this.isPercentage = !this.isPercentage;
  }

  getTopActivityImage(topActivity: IActivityType) {
    if (topActivity.imageThumbnailUrl) {
      return topActivity.imageThumbnailUrl;
    }
    return this.placeholderImage;
  }

  selectGoal(goal: IGoal) {
    this.selectedGoal = goal;
  }

  addTopActivity(activity: IGuildTopActivityTypeCompleted, event: Event) {
    event.stopPropagation();
    this.addTopActivityToUserActivities(activity);
  }

  addTopActivityRecommendation(recommendedTopActivity: IRecommendation) {
    if (this.selectedActivity.activityTypeId !== recommendedTopActivity.activityId) {
      const topActivityIndex = this.topActivities.activities.findIndex(ta => recommendedTopActivity.activityId === ta.activityTypeId);
      this.selectedActivity = this.topActivities.activities[topActivityIndex];
    }

    this.addTopActivityToUserActivities(this.selectedActivity);
  }

  addTopActivityToUserActivities(activity: IGuildTopActivityTypeCompleted) {
    this.selectedActivity = activity;
    const queryParams = this.route.snapshot.queryParams;
    this.goalService.getGoalsForActivity(this.selectedActivity.activityType.id).subscribe({
      next: (goals: IGoal[]) => {
        this.returnedActivityGoals = goals;
        if (queryParams.goalId) {
          const goalId = Number(queryParams.goalId);
          const goalIndex = goals.findIndex((g) => g.id === goalId);
          this.selectedGoal = goals[goalIndex];
        } else {
          this.selectedGoal = null;
        }

        if (this.returnedActivityGoals.length !== 1) {
          if (queryParams.goalId) {
            this.showDrawer(this.selectedActivity);
          }
          this.openGoalSelectionModal();
        } else {
          this.selectedGoal = this.returnedActivityGoals[0];
          const topActivityRecommendationIndex = this.topActivitiesRecommendations.findIndex(tar => activity.activityTypeId === tar.activityId);
          this.selectedActivity = activity;
          this.topActivityRecommendation = this.topActivitiesRecommendations[topActivityRecommendationIndex];
          this.addUserActivityFromTopActivities(); //If there is only one goal, automatically add it
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error(`Oh No! There was an error getting the goals for this activity. ${error.message}`);
      },
    });
  }

  selectGoalFromGoalSelectionModal() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.addUserActivityFromTopActivities();
    this.closeGoalSelectionModal();
  }

  openGoalSelectionModal() {
    this.goalSelectorVisible = true;
  }

  closeGoalSelectionModal() {
    this.goalSelectorVisible = false;
    this.router.navigate([`guilds/home/${this.guildUrlName}/activities`]);
  }

  getTopActivitiesCompleted() {
    const selectedGuildMemberIds = this.selectedGuildMembers.map(member => member.userId);
    this.guildService.getTopActivitiesCompletedByGuildUsers(this.guild.id, selectedGuildMemberIds).subscribe({
      next: (res) => {
        this.topActivities = res;
        this.topActivities.activities = this.sortGuildTopActivities(res.activities);
        this.setNumberAndPercentageOfActivitiesCompleted();
      },
      error: (error) => {
        console.error(`Failed to get the top activities by by users. Error: ${error.message}`);
      }
    });
  }

  private addUserActivityFromTopActivities() {
    this.userActivitiesService.addBacklogItemToGoal(this.selectedActivity.activityTypeId, this.selectedGoal!.id!).subscribe({
      next: (res) => {
        this.userActivitiesService.updateActivitiesCache();
        this.topActivityRecommendation.added = true;
        this.selectedActivity.hasBeenAdded = true;
        this.message.success(`Activity added: ${res.title}`);
        this.closeDrawer();
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 409) {
          this.message.info('Looks like you already have already added this activity to your profile before.');
        } else {
          this.message.error('Damm! We hit an iceberg when trying to add this activity');
        }
      },
    });
  }

  private sortGuildTopActivities(topActivities: IGuildTopActivityTypeCompleted[]) {
    return topActivities.sort((a, b) => {
      // Check if both activities are not added and not completed.
      if (!a.hasBeenAdded && !a.hasBeenCompleted && (b.hasBeenAdded || b.hasBeenCompleted)) {
        return -1; // a comes before b
      } else if (!b.hasBeenAdded && !b.hasBeenCompleted && (a.hasBeenAdded || a.hasBeenCompleted)) {
        return 1; // b comes before a
      }

      // Check if an activity has been added but not completed.
      if (a.hasBeenAdded && !a.hasBeenCompleted && (!b.hasBeenAdded || b.hasBeenCompleted)) {
        return -1; // a comes before b
      } else if (b.hasBeenAdded && !b.hasBeenCompleted && (!a.hasBeenAdded || a.hasBeenCompleted)) {
        return 1; // b comes before a
      }

      // Check if an activity has been completed.
      if (a.hasBeenCompleted && !b.hasBeenCompleted) {
        return 1; // b comes before a because a is completed and b is not
      } else if (b.hasBeenCompleted && !a.hasBeenCompleted) {
        return -1; // a comes before b because b is completed and a is not
      }

      // If all else is equal, maintain the original order of activities
      return 0;
    });
  }

  private getNumberOfActivitiesCompleted() {
    return this.topActivities.activities.filter(a => a.hasBeenCompleted === true).length;
  }

  private getPercentageOfActivitiesCompleted() {
    if (this.topActivities.activities.length !== 0) {
      const rawPercentage = (this.numberOfActivitiesCompleted / this.topActivities.activities.length) * 100;
      return Math.round(rawPercentage);
    } else {
      return 0;
    }
  }

  private setNumberAndPercentageOfActivitiesCompleted() {
    this.numberOfActivitiesCompleted = this.getNumberOfActivitiesCompleted();
    this.percentageOfActivitiesCompleted = this.getPercentageOfActivitiesCompleted();
  }

  private getGuildTopActivities() {
    this.isLoadingGuildTopActivities = true;
    this.guildService.getTopActivities(this.guild.id)
      .pipe(
        map(res => ({
          original: res,
          transformed: res.activities.map(activity => this.mapTopActivityToRecommendation(activity))
        }))
      )
      .subscribe({
        next: ({ original, transformed }) => {
          this.sortGuildTopActivities(original.activities);
          this.topActivities = original;
          this.topActivitiesRecommendations = transformed;
          this.setNumberAndPercentageOfActivitiesCompleted();
          this.userActivities = this.userActivitiesService.getUserActivities();

          this.isLoadingGuildTopActivities = false;
          if (this.topActivities.activities.length > 0) {
            this.getLastSelectedTopActivity();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.message.error(`Oh No! There was an error. ${error.message}`);
          this.isLoadingGuildTopActivities = false;
        },
      });
  }

  private getLastSelectedTopActivity() {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.activityId) {
      const activityId = Number(queryParams.activityId);
      const activityIndex = this.topActivities.activities.findIndex(a => a.activityTypeId === activityId);
      this.addTopActivityToUserActivities(this.topActivities.activities[activityIndex]);
    }
  }

  private mapTopActivityToRecommendation(activity: IGuildTopActivityTypeCompleted): IRecommendation {
    const activityRecommendation: IRecommendation = {
      ...activity.activityType,
      activityId: activity.activityTypeId,
      skills: activity.skills,
      added: activity.hasBeenAdded,
      imageBytes: activity.activityType.imageFullSizeUrl
    } as IRecommendation;
    return activityRecommendation;
  }
}
