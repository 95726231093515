/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Input, OnInit } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';

// libraries
import { NzMessageService } from 'ng-zorro-antd/message';

// services
import { EmailMessageService } from 'src/app/services/email-message.service';

// models
import { IEmailMessageRecipient } from 'src/app/models/email-message-recipient.model';
import { IEmailType } from 'src/app/models/email-type.model';
import { IEmailSingleUser, IEmailTemplate } from 'src/app/models/email-template.model';
import { IEmailMessage } from 'src/app/models/email-message.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'sl-email-message-templates',
  templateUrl: './email-message-templates.component.html',
  styleUrls: ['./email-message-templates.component.less'],
})
export class EmailMessageTemplatesComponent implements OnInit {
  @Input() emailType!: IEmailType;
  recipients: IEmailMessageRecipient[] = [];
  isLoading = false;
  statsShown = false;
  isVisible = false;
  messageSent = false;
  editMode = false;
  previewMode = true;
  isSavingTemplate = false;
  address: string | undefined;
  singleUserMode = false;

  // See: https://netbasal.com/typed-reactive-forms-in-angular-no-longer-a-type-dream-bf6982b0af28 Bonus section
  public emailTemplateGroup = this.fb.group<IEmailTemplate>({
    id: 0,
    title: '',
    message: '',
    emailTypeId: 0,
  });

  public emailSingleUserGroup = this.fb.group<IEmailSingleUser>({
    id: 0,
    title: '',
    message: '',
    emailTypeId: 0,
    address: '',
  });

  constructor(private fb: NonNullableFormBuilder, private emailMessageService: EmailMessageService, private message: NzMessageService) { }

  ngOnInit(): void {
    if (this.emailType.name === 'Single User') {
      this.singleUserMode = true;
      this.editMode = true; // start the form in edit mode automatically
    } else {
      this.singleUserMode = false;
    }

    this.loadMessageTemplate();
  }

  showStats(): void {
    this.statsShown = !this.statsShown;
  }

  sendEmailsFromForm(): void {
    if (this.singleUserMode) {
      this.sendEmailSingleUser();
    } else {
      this.sendEmailTemplate();
    }
  }

  loadMessageTemplate(): void {
    this.emailMessageService.getEmailTemplate(this.emailType.id).subscribe({
      next: (res: IEmailMessage) => {
        if (!this.singleUserMode) {
          this.emailTemplateGroup.patchValue(res);
          this.loadMessageRecipients();
        }
        else {
          this.emailSingleUserGroup.patchValue(res);
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log('Failed to receive template; ERROR ' + error.message);
      },
    });
  }

  loadMessageRecipients(): void {
    this.emailMessageService.getEmailMessageRecipients(this.emailTemplateGroup.getRawValue().id).subscribe({
      next: (res) => {
        this.recipients = res;
      },
      error: (error) => {
        console.log('Failed to load recipients; Error ' + error);
      },
    });
  }

  showRecipientList(): void {
    this.isVisible = true;
  }

  closeRecipientList(): void {
    this.isVisible = false;
  }

  getTemplateText(): string {
    if (!this.singleUserMode && this.emailTemplateGroup) {
      return this.emailTemplateGroup.getRawValue().message;
    } else if (this.singleUserMode && this.emailSingleUserGroup) {
      return this.emailSingleUserGroup.getRawValue().message;
    }

    return '';
  }

  getCanSendEmailCount(): number {
    return this.recipients.filter(r => r.canSendEmail === true).length;
  }

  clickCancelEdit(): void {
    this.message.info(`Preview only mode`);
    this.editMode = false;
  }

  clickSwitch(): void {
    if (this.editMode) {  // going from edit mode to not edit mode means they have saved.
      // if it is not in edit mode, then we want to save the template
      this.isSavingTemplate = true;

      const emailGroup = this.singleUserMode ? this.emailSingleUserGroup.getRawValue() : this.emailTemplateGroup.getRawValue();
      this.emailMessageService.updateEmailTemplate(emailGroup).subscribe({
        next: (res) => {
          console.log(`Saving template...`);
          this.editMode = false;
          if (this.singleUserMode && res) {
            this.previewMode = true;
            this.emailSingleUserGroup.patchValue(res);
          } else if (!this.singleUserMode && res) {
            this.emailTemplateGroup.patchValue(res);
          }
          this.isSavingTemplate = false;
          this.message.success('Template saved!');
        },
        error: (error) => {
          console.log('Failed to load recipients; Error ' + error);
          this.isSavingTemplate = false;
          this.editMode = false;
          this.message.error('Oh no! Something went wrong!');
        },
      });
    } else {
      this.editMode = true;
    }
  }

  private sendEmailTemplate() {
    if (this.singleUserMode) {
      throw new Error('The template mode should be the mode we are in if we get here.');
    }

    this.isLoading = true;
    this.emailMessageService.sendEmailsBasedOnTemplate(this.emailTemplateGroup.getRawValue().id).subscribe({
      next: (batch) => {
        this.loadMessageRecipients();
        this.messageSent = true;
        this.isLoading = false;
        if (batch.numberFailed > 0) {
          this.message.error(`This batch partially failed. Sent to ${batch.numberSent} users, but ${batch.numberFailed} failed to send.`);
        } else {
          this.message.success(`This batch of emails have been sent to ${batch.numberSent} users!`);
        }
      },
      error: (error) => {
        this.message.error(`This batch of emails have been sent but it failed to send to any`);
        console.error(`Failed to send message to all users.  Error: ${error}`);
        this.isLoading = false;
      }
    });
  }

  private sendEmailSingleUser() {
    if (!this.singleUserMode) {
      throw new Error('The SingleUserMode should be enabled if we get here.');
    }

    this.isLoading = true;

    this.emailMessageService.sendEmailsToSingleUser(this.emailSingleUserGroup.getRawValue()).subscribe({
      next: (res) => {
        this.messageSent = true;
        this.isLoading = false;
        this.message.create('success', `This email was sent to ${res.address}`);
      },
      error: (error) => {
        if (error.status === 403) {
          this.message.error('The email was not authorised from this account. Either the domain or the email needs to be registered. Error status: ' + error.status);
        } else {
          this.message.error(`Oh no! Something unexpected happened and we either didn't send the email or didn't save it`);
        }
        console.error(`Failed to send message to a single user: ${this.emailSingleUserGroup.getRawValue().address}.  Error: ` + error);
        this.isLoading = false;
      }
    });
  }
}
