import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { IEmployment } from '../models/employment.model';
import { IGoal } from '../models/user-goal.model';
import { IUserProfilePublic } from '../models/user-profile.model';
import { ITopSkill } from '../models/top-skill.model';
import { PublicUrlResponse } from '../modules-guilds/components/guild-home/guild-home.component';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PublicProfileService {
  private endpoint = '/api/PublicProfile/';

  constructor(private http: HttpClient) { }

  getPrivateStatus(userIdentifer: string): Observable<boolean> {
    const endpoint = this.endpoint + `GetPrivateStatus/${userIdentifer}`;
    return this.http.get<boolean>(endpoint, httpOptions);
  }

  checkUrlAvailability(userIdentifer: string): Observable<boolean> {
    const endpoint = this.endpoint + `CheckUrlAvailability/${userIdentifer}`;
    return this.http.get<boolean>(endpoint, httpOptions);
  }

  getPublicUserDetails(userIdentifier: string): Observable<IUserProfilePublic> {
    const endpoint = this.endpoint + `GetPublicUserDetails/${userIdentifier}`;
    return this.http.get<IUserProfilePublic>(endpoint, httpOptions);
  }

  getPublicGoals(userIdentifier: string): Observable<IGoal[]> {
    const endpoint = this.endpoint + `GetPublicGoals/${userIdentifier}`;
    return this.http.get<IGoal[]>(endpoint, httpOptions);
  }

  getPublicTopSkills(userIdentifier: string): Observable<ITopSkill[]> {
    const endpoint = this.endpoint + `GetPublicTopSkills/${userIdentifier}`;
    return this.http.get<ITopSkill[]>(endpoint, httpOptions);
  }

  getPublicEmployment(userIdentifier: string): Observable<IEmployment[]> {
    const endpoint = this.endpoint + `GetPublicEmployment/${userIdentifier}`;
    return this.http.get<IEmployment[]>(endpoint, httpOptions);
  }

  getSelectedUserPublicUrl(userId: string): Observable<PublicUrlResponse> {
    const endpoint = this.endpoint + `GetSelectedUserPublicUrl/${userId}`;
    return this.http.get<PublicUrlResponse>(endpoint, httpOptions);
  }
}
