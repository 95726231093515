/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit } from '@angular/core';

import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ITrack } from 'src/app/models/track.model';
import { TrackService } from 'src/app/services/track.service';

interface ColumnItem {
  name: string;
  sortOrder?: NzTableSortOrder;
  sortFn?: NzTableSortFn<ITrack>;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn;
  filterMultiple?: boolean;
  sortDirections?: NzTableSortOrder[];
}

@Component({
  selector: 'sl-approve-tracks',
  templateUrl: './approve-tracks.component.html',
  styleUrls: ['./approve-tracks.component.less'],
})
export class ApproveTracksComponent implements OnInit {
  listOfColumns: ColumnItem[] = [
    {
      name: 'Industry',
      sortOrder: 'descend',
      sortFn: (a: ITrack, b: ITrack) => a.industry.localeCompare(b.industry),
      sortDirections: ['descend', 'ascend'],
    },
    {
      name: 'Profession',
      sortOrder: 'descend',
      sortFn: (a: ITrack, b: ITrack) => a.profession.localeCompare(b.profession),
      sortDirections: ['descend', 'ascend'],
    },
    {
      name: 'Specialisation',
      sortOrder: 'descend',
      sortFn: (a: ITrack, b: ITrack) => a.specialisation.localeCompare(b.specialisation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      name: 'Level',
      sortOrder: 'descend',
      sortFn: (a: ITrack, b: ITrack) => a.level.localeCompare(b.level),
      sortDirections: ['descend', 'ascend'],
    },
    {
      name: 'Track Name',
      sortOrder: 'descend',
      sortFn: (a: ITrack, b: ITrack) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      name: 'Skills (Category:Skill)',
    },
    {
      name: 'Date Created',
      sortOrder: 'descend',
      sortFn: (a: ITrack, b: ITrack) => (Number(a.dateCreated?.valueOf) > Number(b.dateCreated?.valueOf) ? 1 : -1),
      sortDirections: ['descend', 'ascend'],
    },
    {
      name: 'Created By',
    },
    {
      name: 'Actions',
      sortOrder: 'descend',
      sortFn: (a: ITrack) => (a.approved ? 1 : -1),
      sortDirections: ['descend', 'ascend'],
    },
  ];
  listOfTrackData!: ITrack[];
  loadingTracks = false;
  editId: number | null = null;

  constructor(private trackService: TrackService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.getTracks();
  }

  getTracks() {
    this.loadingTracks = true;
    this.trackService.getTracksToBeApproved().subscribe(
      (tracks) => {
        this.listOfTrackData = tracks;
        this.setTrackApprovedStateAsUndefined();
        this.loadingTracks = false;
      },
      (error) => {
        this.setTrackApprovedStateAsUndefined();
        this.loadingTracks = false;
        console.error(`*** Admin component: getTracks() could not retrieve tracks. ` + error);
      }
    );
  }

  setTrackApprovedStateAsUndefined() {
    this.listOfTrackData.forEach(track => {
      track.approved = undefined;
    });
  }

  updateTrack(track: ITrack, newApproved: boolean) {
    // console.log(`***Update track called with ${newApproved}`);

    const originalValue = track.approved;
    track.approved = newApproved;
    this.loadingTracks = true;

    const approveOrReject = newApproved ? this.trackService.approveTrack(track) : this.trackService.rejectTrack(track);
    approveOrReject.subscribe(
      (updatedTrack) => {
        track = updatedTrack;
        this.loadingTracks = false;
      },
      (error) => {
        track.approved = originalValue;
        this.loadingTracks = false;
        console.error(`*** Admin component: updateTrack() for ` + track.name + ` failed. ` + error);
      }
    );
  }

  startTrackDetailsEdit(id: number): void {
    this.editId = id;
  }

  onTrackDetailsChange(track: ITrack) {
    this.trackService.updateTrackDetails(track).subscribe({
      next: (resultTrack) => {
        track = resultTrack;
        this.editId = null;
        this.message.success('Successfully updated track details.');
      },
      error: (error) => {
        console.log(`Failed to update track details: ${JSON.stringify(error)} `);
        this.message.error('Could not update track details. Please try again in a moment.');
      }
    });
  }
}
