/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

//services
import { NzMessageService } from 'ng-zorro-antd/message';
import { SignatureService } from 'src/app/services/signature.service';
import { EditMode } from '../../types/edit-mode.type';

@Component({
  selector: 'sl-profession-selector',
  templateUrl: './profession-selector.component.html',
  styleUrls: ['./profession-selector.component.less'],
})
export class ProfessionSelectorComponent implements OnChanges {
  @Output() selectProfessionEvent = new EventEmitter<string>();
  @Input() currentMode!: EditMode;
  @Input() previouslySelectedIndustry!: string;
  @Input() previouslySelectedProfession!: string;
  @Input() selectedIndustry!: string;
  @Input() allowAllProfessions!: boolean;

  selectedProfession!: string;
  public professionOptions: string[] = [];

  constructor(private signatureService: SignatureService, private message: NzMessageService) { }

  ngOnChanges(): void {
    this.initProfessions();
  }

  selectProfession(profession: string): void {
    if (this.selectedProfession === profession) {
      this.selectedProfession = '';
    } else {
      this.selectedProfession = profession;
    }
    this.selectProfessionEvent.emit(this.selectedProfession);
  }

  private initProfessions() {
    if (this.selectedProfession === this.previouslySelectedProfession) {
      this.selectedProfession = '';
    }

    if (this.previouslySelectedProfession && this.selectedProfession && (this.selectedProfession === this.previouslySelectedProfession)) {
      return;
    }

    this.signatureService.getProfessions(this.selectedIndustry).subscribe({
      next: (ret) => {
        this.professionOptions = ret;
        if (this.allowAllProfessions && !this.professionOptions.includes('All')) {   // This can only happen in the the tests where the all is already there.
          this.professionOptions.unshift('All');
        }

        if (this.currentMode === EditMode.edit) {
          if (!this.previouslySelectedProfession) {
            throw new Error('This should set correctly when in edit mode');
          }
          this.selectProfession(this.previouslySelectedProfession);
        }
      },
      error: (err) => {
        console.error(err.message);
        this.message.error(`Oh dear, this is embarassing. We weren't able to get your previously selected profession. Check your network connection and try again in a few moments`, { nzDuration: 5000 });
        this.professionOptions = [];
      },
    });
  }
}
