import { EmailMessageService } from 'src/app/services/email-message.service';
import { Component, OnInit } from '@angular/core';
import { IEmailBatch } from 'src/app/models/email-batch.model';
@Component({
  selector: 'sl-email-metrics',
  templateUrl: './email-metrics.component.html',
  styleUrls: ['./email-metrics.component.less'],
})
export class EmailMetricsComponent implements OnInit {
  emailBatches!: IEmailBatch[];
  isLoading = false;

  constructor(private emailMessageService: EmailMessageService) {}

  ngOnInit(): void {
    this.getEmailBatches();
  }

  refreshBatchTable() {
    this.getEmailBatches();
  }

  getEmailBatches(): void {
    this.isLoading = true;
    this.emailMessageService.getEmailBatches().subscribe({
      next: (res) => {
        this.isLoading = false;
        this.emailBatches = res;
      },
      error: (error) => {
        console.log('Failed to load email batches; Error ' + error);
      },
    });
  }
}
