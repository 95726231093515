/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

//services
import { NzMessageService } from 'ng-zorro-antd/message';
import { SignatureService } from 'src/app/services/signature.service';
import { EditMode } from '../../types/edit-mode.type';

@Component({
  selector: 'sl-specialisation-selector',
  templateUrl: './specialisation-selector.component.html',
  styleUrls: ['./specialisation-selector.component.less'],
})
export class SpecialisationSelectorComponent implements OnChanges {
  @Output() selectSpecialisationEvent = new EventEmitter<string>();
  @Input() currentMode!: EditMode;
  @Input() previouslySelectedSpecialisation!: string;
  @Input() previouslySelectedIndustry!: string;
  @Input() previouslySelectedProfession!: string;
  @Input() selectedIndustry!: string;
  @Input() selectedProfession!: string;

  selectedSpecialisation!: string;
  specialisationOptions: string[] = [];

  constructor(private signatureService: SignatureService, private message: NzMessageService) { }

  ngOnChanges(): void {
    this.initSpecialisations();
  }

  selectSpecialisation(specialisation: string): void {
    if (this.selectedSpecialisation === specialisation) {
      this.selectedSpecialisation = '';
    } else {
      this.selectedSpecialisation = specialisation;
    }
    this.selectSpecialisationEvent.emit(this.selectedSpecialisation);
  }

  private initSpecialisations() {
    this.signatureService.getSpecialisations(this.selectedIndustry, this.selectedProfession).subscribe({
      next: (ret) => {
        this.selectedSpecialisation = '';
        this.specialisationOptions = ret;
        if (!this.specialisationOptions.includes('All')) {   // This can only happen in the the tests where the all is already there.
          this.specialisationOptions.unshift('All');
        }

        if (this.specialisationOptions.find((s) => s === this.previouslySelectedSpecialisation)) {
          this.selectSpecialisation(this.previouslySelectedSpecialisation);
        }
        else {
          this.selectSpecialisation(this.selectedSpecialisation);
        }
      },
      error: (err) => {
        console.error(err.message);
        this.message.error(`Oh dear, this is embarassing. We weren't able to get your previously selected specialisation. Check your network connection and try again in a few moments`, { nzDuration: 5000 });
        this.specialisationOptions = [];
      },
    });
  }
}
