import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { ISkill } from '../models/skill.model';

// services

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class SkillService {
  private endpoint = '/api/Skills';

  constructor(private http: HttpClient) { }

  public getAllSkills(exceptSkillIds: number[] = []): Observable<ISkill[]> {
    const exceptList = exceptSkillIds.join(',');
    const url = this.endpoint + `/GetAllSkills/?exceptIds=${exceptList}`;
    return this.http.get<ISkill[]>(url, httpOptions);
  }

  public addSkill(skill: ISkill): Observable<ISkill> {
    const url = this.endpoint + `/AddSkill`;
    return this.http.post<ISkill>(url, skill, httpOptions);
  }

  public updateSkill(skill: ISkill): Observable<ISkill> {
    const url = this.endpoint + '/UpdateSkill';
    return this.http.post<ISkill>(url, skill, httpOptions);
  }

  public retireSkill(skill: ISkill): Observable<ISkill> {
    const url = this.endpoint + '/RetireSkill';
    return this.http.post<ISkill>(url, skill, httpOptions);
  }

  public cleanUntrimmedSkills(): Observable<ISkill[]> {
    const url = this.endpoint + '/CleanUntrimmedSkills';
    return this.http.post<ISkill[]>(url, '', httpOptions);
  }
}
