import { GuildService } from 'src/app/services/guild.service';
import { Component, Input, OnInit } from '@angular/core';
import { IGuild } from '../../models/guild.model';
import { EGuildVisibilityType } from 'src/app/modules-guilds/types/guild-visibility.type';

@Component({
  selector: 'sl-guild-card',
  templateUrl: './guild-card.component.html',
  styleUrls: ['./guild-card.component.less'],
})
export class GuildCardComponent implements OnInit {
  @Input() guild: IGuild = {} as IGuild;
  placeholderImage = '/assets/img/placeholder_image.png';
  guildVisibilityType = EGuildVisibilityType;
  constructor(private guildService: GuildService) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    if (!this.guild) {
      console.error(`The guild has not been set correctly`);
    }
  }

  getGuildImage() {
    return this.guild.imageUrl;
  }

  getGuildPlaceholderImage() {
    return this.placeholderImage;
  }

  hasLocation() {
    if (!this.guild.location || this.guild.location.country === '' || this.guild.location.city === '') {
      return false;
    }

    return true;
  }

  guildLocation() {
    if (!this.guild.location) {
      return '';
    }
    if (this.guild.location.country !== '' && this.guild.location.city !== '') {
      return this.guild.location.city + ' ' + this.guild.location.country;
    }

    if (!this.guild.location.city) {
      return this.guild.location.country;
    }

    return this.guild.location.city;
  }

  isGuildPublic() {
    if (this.guild.visibility === this.guildVisibilityType.Public) {
      return true;
    }

    return false;
  }

  getGuildMembers() {
    this.guildService.getGuildMembers(this.guild.id).subscribe((members) => {
      this.guild.guildMemberCount = members.length;
    });
  }
}
