<h2 class="header">Approve Tracks</h2>
<nz-spin nzTip="Loading..." [(nzSpinning)]="loadingTracks">
  <div *ngIf="listOfTrackData" class="approve-tracks">
    <nz-table #filterTable [nzData]="listOfTrackData" [nzShowPagination]=true nzBordered="true" [nzShowQuickJumper]=true [nzPageSizeOptions]="[10,20,30,50]" [nzShowSizeChanger]="true" nzTableLayout="auto" nzPaginationPosition="both" nzSize="middle" [nzLoading]="loadingTracks"
      nzShowQuickJumper="true">
      <thead>
        <tr>
          <th *ngFor="let column of listOfColumns" [nzSortOrder]="column.sortOrder" [nzSortFn]="column.sortFn" [nzSortDirections]="column.sortDirections" [nzFilterMultiple]="column.filterMultiple" [nzFilters]="column.listOfFilter" [nzFilterFn]="column.filterFn">
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filterTable.data">
          <td>{{ data.industry }}</td>
          <td>{{ data.profession }}</td>
          <td>{{ data.specialisation }}</td>
          <td>{{ data.level }}</td>
          <td>
            <div class="editable-cell" [hidden]="editId === data.id" (click)="startTrackDetailsEdit(data.id)">
              {{ data.name }}
            </div>
            <input id="track-name-input" [hidden]="editId !== data.id" type="text" nz-input [(ngModel)]="data.name" (blur)="onTrackDetailsChange(data)">
          </td>
          <td>
            <li *ngFor="let skill of data.skills">
              {{ skill.category }} : {{ skill.name }}
            </li>
          </td>
          <td>{{ data.dateCreated | date:'long' }}</td>
          <td>{{ data.createdByUserName }}</td>
          <td>
            <button class="approve-track-button" nz-button (click)="updateTrack(data, true)" [ngStyle]="{'background-color': data.approved === true ? 'rgb(92, 218, 88)' : 'lightgray'}">
              <i nz-icon nzType="check" nzTheme="outline" [ngStyle]="{'color': data.approved === true ? 'white' : 'black'}"></i>
            </button>
            <button class="approve-track-button" nz-button (click)="updateTrack(data, false)" [ngStyle]="{'background-color': data.approved === false ? 'rgb(218, 88, 88)' : 'lightgray'}">
              <i nz-icon nzType="close" nzTheme="outline" [ngStyle]="{'color': data.approved === false ? 'white' : 'black'}"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div *ngIf="!listOfTrackData && !loadingTracks">
    <nz-alert nzType="info" nzShowIcon nzMessage="No tracks to approve" [nzDescription]="descriptionTpl">
      <ng-template #descriptionTpl>
        <h2>☕...</h2>
      </ng-template>
    </nz-alert>
  </div>
</nz-spin>