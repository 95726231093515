/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { ILevelSignature } from '../models/level-signature.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class CareerDetailsService {
  endpoint = '/api/CareerDetails/';
  isLoggedIn = false;
  initialising = false;

  constructor(private http: HttpClient) {}

  public getExperienceLevels(industry: string, profession: string, specialisation: string): Observable<ILevelSignature[]> {
    const url = this.endpoint + `GetExperienceLevels/${industry}/${profession}/${specialisation}`;
    return this.http.get<ILevelSignature[]>(url, httpOptions);
  }

  public SeedLevelDetailsExperienceLevels(seedIndustryName: string): Observable<ILevelSignature[]> {
    const url = this.endpoint + `SeedLevelDetailsExperienceLevels?seedIndustryName=${seedIndustryName}`;
    return this.http.post<ILevelSignature[]>(url, httpOptions);
  }
}
