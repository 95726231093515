import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserAction } from '../models/user-action.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UserActionService {
  private endpoint = '/api/UserAction';

  constructor(private http: HttpClient) {}

  addUserActionToGoal(actionId: number, goalId: number): Observable<IUserAction> {
    if (!actionId) {
      throw new Error('Action Id can\'t be undefined at this point');
    }

    if (!goalId) {
      throw new Error('Goal Id can\'t be undefined at this point');
    }

    const body = { goalId: goalId.toString(), actionId: actionId.toString() };
    const url = this.endpoint + `/AddUserAction/`;
    return this.http.post<IUserAction>(url, body, httpOptions);
  }

  getUserActions(): Observable<IUserAction[]> {
    const url = this.endpoint + `/GetUserActions/`;
    return this.http.get<IUserAction[]>(url, httpOptions);
  }

  deleteUserAction(actionId: number): Observable<number> {
    if (!actionId) {
      throw new Error('Action ID cannot be undefined at this point.');
    }

    const params = new HttpParams().set('userActionId', String(actionId));
    const options = { headers: httpOptions.headers, params };
    const url = this.endpoint + `/DeleteUserAction/`;

    return this.http.delete<number>(url, options);
  }

  completeUserAction(action: IUserAction, complete: boolean): Observable<IUserAction> {
    const url = this.endpoint + `/CompleteUserAction/`;
    const params = new HttpParams() // add all the params sequentially. See user-activities.service.ts for how to add them as query parameters
      .set('id', String(action.id))
      .set('complete', String(complete));

    const options = { headers: httpOptions.headers, params };
    return this.http.put<IUserAction>(url, action, options);
  }

  updateUserAction(action: IUserAction): Observable<IUserAction> {
    if (!action) {
      throw new Error('Action cannot be undefined at this point.');
    }

    const url = this.endpoint + `/UpdateUserAction/`;
    return this.http.post<IUserAction>(url, action, httpOptions);
  }

  updateActionStateTransition(actionId: number): Observable<IUserAction> {
    const url = this.endpoint + `/UpdateUserActionTransitionState/`;

    const params = new HttpParams().set('id', String(actionId));

    const options = { headers: httpOptions.headers, params };
    return this.http.put<IUserAction>(url, '', options);
  }
}
