import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { IDeleteUser, IUserProfile, IUserProfilePublic, IUserProfileSubscription } from '../models/user-profile.model';
import { map } from 'rxjs/operators';
import { UserEmailAddress } from '../modules-account/components/user-details/account-details/account-details.component';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class AccountDetailsService {
  endpoint = '/api/Profile/';
  private user: IUserProfile | undefined;

  constructor(private http: HttpClient) { }

  getIsAdmin(): Observable<boolean> {
    const url = this.endpoint + 'UserIsAdmin';
    return this.http.get<boolean>(url);
  }

  getIsTest(): Observable<boolean> {
    const url = this.endpoint + 'UserIsTest';
    return this.http.get<boolean>(url);
  }

  getBasicUsers(includeDeleted: boolean): Observable<IUserProfileSubscription[]> {
    const url = `${this.endpoint}GetUsers?includeDeleted=${includeDeleted}`;
    return this.http.get<IUserProfileSubscription[]>(url);
  }

  getUserProfileSubscription(): Observable<IUserProfileSubscription> {
    const url = this.endpoint + 'GetUserProfileSubscription';
    return this.http.get<IUserProfileSubscription>(url);
  }

  // Note: the email is not used in the method, just for logging
  loadCurrentUser(): Observable<IUserProfile> {
    const url = this.endpoint + 'GetCurrentUserProfile';
    return this.http.get<IUserProfile>(url).pipe(
      map((user: IUserProfile) => {
        this.user = user;
        return user;
      })
    );
  }

  update(profile: IUserProfile): Observable<IUserProfile> {
    return this.http.put<IUserProfile>(this.endpoint, profile, httpOptions);
  }

  updateTrialStatus(basicUser: IUserProfileSubscription): Observable<IUserProfileSubscription> {
    const url = this.endpoint + 'UpdateTrialStatus';
    const jsonBody = JSON.stringify(basicUser);
    console.log(`*** The profile is being updated with ${jsonBody}`);

    return this.http.post<IUserProfileSubscription>(url, jsonBody, httpOptions);
  }

  deleteUser(basicUser: IUserProfileSubscription, toBeDeleted: boolean, reason: string): Observable<IDeleteUser> {
    const url = this.endpoint + 'DeleteUser';
    const body = {
      userId: basicUser.id,
      deleted: toBeDeleted,
      reason: reason,
    };

    return this.http.post<IDeleteUser>(url, body, httpOptions);
  }

  updateBasicUserRole(basicUser: IUserProfileSubscription): Observable<IUserProfileSubscription> {
    const url = this.endpoint + 'UpdateBasicUserRole';
    const jsonBody = JSON.stringify(basicUser);
    console.log(`*** The profile is being updated with ${jsonBody}`);

    return this.http.post<IUserProfileSubscription>(url, jsonBody, httpOptions);
  }

  seedDatabase(): Observable<boolean> {
    const url = this.endpoint + 'SeedDatabase';

    return this.http.post<boolean>(url, '', httpOptions);
  }

  uploadProfilePicture(imageFile: File, imagePurpose: string): Observable<boolean> {
    const url = this.endpoint + 'UploadImage' + `?imagePurpose=${imagePurpose}`;

    const formData: FormData = new FormData();
    formData.append('fileKey', imageFile, imageFile.name);

    return this.http.post<boolean>(url, formData);
  }

  getImage(imagePurpose: string): Observable<string> {
    const url = this.endpoint + `GetImage?imgPurpose=${imagePurpose}`;
    console.log(`getting image with ${url}`);
    return this.http.get(url, { responseType: 'text' });
  }

  updateUsernames(): Observable<boolean> {
    const url = this.endpoint + 'UpdateUsernameDatabase';
    return this.http.get<boolean>(url);
  }

  privateStatusUpdate(statusUpdate: boolean): Observable<boolean> {
    const url = this.endpoint + 'UpdatePrivateStatus';
    return this.http.post<boolean>(url, statusUpdate, httpOptions);
  }

  searchForUsers(searchQuery: string): Observable<IUserProfilePublic[]> {
    const endpoint = this.endpoint + `SearchForUsers`;
    return this.http.get<IUserProfilePublic[]>(endpoint, { params: { searchQuery } });
  }

  updateEmailAddresses(emailAddresses: UserEmailAddress[]): Observable<UserEmailAddress[]> {
    const endpoint = this.endpoint + `UpdateEmailAddresses`;
    return this.http.put<UserEmailAddress[]>(endpoint, emailAddresses, httpOptions);
  }

  verifyEmailAddress(emailAddressId: number): Observable<UserEmailAddress> {
    const endpoint = this.endpoint + `VerifyEmailAddress`;
    return this.http.get<UserEmailAddress>(endpoint, { params: { emailAddressId: emailAddressId.toString() } });
  }

  isEmailAddressTaken(emailAddress: string): Observable<boolean> {
    const endpoint = this.endpoint + `IsEmailAddressAssociatedWithAnotherAccount`;
    return this.http.get<boolean>(endpoint, { params: { emailAddress } });
  }

  getPublicUrl(): string | undefined {
    if (!this.user) {
      throw new Error('User cannot be undefined at this point');
    }
    return this.user.publicUrl;
  }

  isUserValid(): boolean {
    return !(!this.user);
  }
}
