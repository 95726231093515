<nz-skeleton *ngIf="isLoading"></nz-skeleton>
<div *ngIf="unassignedActivities && !isLoading">
  <div *ngIf="unassignedActivities?.length > 0">
    <nz-table nzTemplateMode>
      <thead>
        <tr>
          <th></th>
          <th>Provider</th>
          <th>Activity</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let activity of unassignedActivities; let i = index">
          <td id="title-{{activity.id}}"><label nz-checkbox (change)="disabledButton()" [(ngModel)]="activity.assignToGoal"></label></td>
          <td class="center-text"><i title="{{activity.provider.name}}" class="{{activity.provider.icon}}"></i></td>
          <td><a target="_blank" href="{{activity.link}}">{{ activity.title }}</a></td>
        </tr>
      </tbody>
    </nz-table>
    <div class="btn-submit">
      <button id="assignButton" (click)="submit()" [disabled]="isDisabled" nz-button nzType="primary">Assign</button>
    </div>
  </div>

  <div *ngIf="unassignedActivities?.length === 0" id="all-assigned">
    <h2 id="noActivities">Well done, you have assigned all of your activities!</h2>
    <img class="no-unassigned-activities-img" alt="Yearly" src="assets/img/goals/clean-work-area.png" />
  </div>
</div>