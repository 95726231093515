import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ITopSkill } from '../models/top-skill.model';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class TopSkillService {
  endpoint = '/api/TopSkills';

  constructor(private http: HttpClient) {}

  getTopSkills(): Observable<ITopSkill[]> {
    const url = this.endpoint + '/GetTopSkills';
    return this.http.get<ITopSkill[]>(url, httpOptions);
  }

  getTopSkillsForUser(id: string): Observable<ITopSkill[]> {
    const url = this.endpoint + '/GetTopSkillsForUserId/';
    return this.http.get<ITopSkill[]>(url, { params: { id: id.toString() } });
  }
}
