import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// services
import { GoalService } from '../../../../services/goal.service';

// models
import { ITopSkill } from '../../../../models/top-skill.model';
import { IUserProfilePublic } from 'src/app/models/user-profile.model';
import { IGoal } from 'src/app/models/user-goal.model';
import { ISkill } from 'src/app/models/skill.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sl-skill-card2',
  templateUrl: './skill-card2.component.html',
  styleUrls: ['./skill-card2.component.less'],
})
export class SkillCard2Component implements OnInit, OnDestroy {
  @Input() userProfilePublic?: IUserProfilePublic;
  @Input() topSkill!: ITopSkill;
  @Input() category!: string;
  @Input() userGoals!: IGoal[]
  topSkillGoal!: IGoal | undefined;
  icon: string | null = null;
  firstName = '';
  onChangeGoal!: Subscription;

  constructor(private goalService: GoalService, private router: Router) { }

  ngOnInit(): void {
    if (this.userProfilePublic !== undefined) {
      this.firstName = `${this.userProfilePublic.name} has`;
    } else {
      this.firstName = 'You have';
    }

    if (!this.topSkill.iconFileName && !this.topSkill.imageFileName && this.topSkill.category) {
      this.goalService.getCategoryIcon(this.category).subscribe({
        next: (res) => {
          this.icon = !res ? 'far fa-circle' : res;
        },
        error: (err) => {
          console.error(`Failed to get category icon: ${err.message}`);
          this.icon = 'far fa-circle';
        }
      });
    } else {
      this.icon = 'far fa-circle';
    }

    this.onChangeGoal = this.goalService.getGoalsUpdatedEvent().subscribe(() => {
      this.getTopSkillGoal();
    });

    this.getTopSkillGoal();
  }

  ngOnDestroy(): void {
    if (this.onChangeGoal) {
      this.onChangeGoal.unsubscribe();
    }
  }

  navigateToRecommendations(topSkill: ITopSkill): void {
    this.router.navigate(['/recommendations', { goalId: topSkill.goalId, skillName: topSkill.name }]);
  }

  navigateToProfileActivities(topSkill: ITopSkill): void {
    this.router.navigate(['/profile/activities', { skillName: topSkill.name }]);
  }

  labelText = (): string => `${this.topSkill.rankPercentile}%`;

  private getTopSkillGoal() {
    const goal = this.goalService.getGoal(this.topSkill.goalId);
    if (goal && goal.skills) {
      this.topSkillGoal = goal;
      if (this.topSkillGoal && this.topSkillGoal.skills) {
        const skillMatch = this.topSkillGoal?.skills.find(skill => skill.name === this.topSkill.name);
        if (skillMatch && this.isTopSkillACoreSkill(skillMatch)) {
          this.topSkill.coreSkill = true;
        } else {
          this.topSkill.coreSkill = false;
        }
      } else {
        this.topSkill.coreSkill = false;
      }
    }
  }

  private isTopSkillACoreSkill(skill: ISkill): boolean {
    if (skill.coreSkill) {
      return true;
    }
    return false;
  }
}