import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ITrack } from 'src/app/models/track.model';
import { TrackService } from 'src/app/services/track.service';

@Component({
  selector: 'sl-goal-signature-selector',
  templateUrl: './goal-signature-selector.component.html',
  styleUrls: ['./goal-signature-selector.component.less'],
})
export class GoalSignatureSelectorComponent implements OnInit {
  @Output() selectedTrackChanged = new EventEmitter<ITrack>();

  trackOptions!: ITrack[];
  selectedTrackOption: ITrack | undefined = undefined;
  isLoading = true;

  constructor(private trackService: TrackService) { }

  ngOnInit(): void {
    this.getTracksOptions();
  }

  getTracksOptions() {
    this.trackService.getAllTracksSummary().subscribe(
      (tracks) => {
        this.trackOptions = this.sortTracks(tracks);
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.trackOptions = [{}] as ITrack[];
      }
    );
  }

  sortTracks(tracks: ITrack[]): ITrack[] {
    const sortedTrackArray = tracks.sort((a, b) =>
      (a.industry === b.industry) ?
        (a.profession === b.profession) ?
          (a.specialisation === b.specialisation) ?
            (a.level === b.level) ?
              (a.name > b.name ? 1 : -1) :
              (a.level > b.level ? 1 : -1) :
            (a.specialisation > b.specialisation ? 1 : -1) :
          (a.profession > b.profession ? 1 : -1) :
        (a.industry > b.industry ? 1 : -1)
    );
    return sortedTrackArray;
  }

  selectTrack(track: ITrack): void {
    this.trackService.getTrack(track.id).subscribe(
      (trackResult) => {
        this.selectedTrackChanged.emit(trackResult);
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
