<div class="v2-background"></div>
<div class="profile-wrapper">
  <!-- HEADER -->
  <div class="header">
    <sl-nameplate [refreshUserActivityMetric]="refreshUserActivityMetric"></sl-nameplate>
    <div class="section-tabs" style="text-align:left">
      <nz-tabset nzLinkRouter [nzTabPosition]="'top'" [nzType]="'line'">
        <nz-tab *ngFor="let tab of tabs" [nzDisabled]="tab.disabled" id="{{tab.label}}">
          <a *nzTabLink nz-tab-link [routerLink]="['/profile', tab.link]">{{tab.label}}</a>
        </nz-tab>
      </nz-tabset>
    </div>
    <div class="section-router" *ngIf="router.url === '/profile/activities'">
      <sl-profile-activities [activityStatus]='activityStatus'></sl-profile-activities>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>