import { UserEmailAddress } from '../modules-account/components/user-details/account-details/account-details.component';

export enum ESubscriptionStatus {
  Free = 0,
  Trial = 1,
  Subscribed = 2,
  Cancelled = 3,
  Unsubscribed = 4,
  TrialExpired = 5,
}

export interface IUserProfilePublic {
  userId?: string;
  name: string;
  surname: string;
  email: string;
  additionalEmails: UserEmailAddress[];
  publicUrl: string;
  tagline: string;
  image: string;
  address: string;
  addressSuburb: string;
  addressState: string;
  addressPostcode: string;
  addressCountry: string;
}

export interface IUserProfileSubscription extends IUserProfilePublic {
  id: string;
  isAdmin: boolean;
  isTest: boolean;
  isTrial: boolean;
  isDeleted: boolean;
  subscriptionStatus?: string;
  subscriptionType?: string;
  numGoals: number;
  numActivities: number;
  numActivitiesCompleted: number;
}

export interface IDeleteUser {
  userId: string;
  deleted: boolean;
  reason: string;
  subscriptionStatus: string;
}

export interface IUserProfile extends IUserProfilePublic {
  id: number;
  username: string;
  salary: number;
  isAdmin: boolean;
  gender: string;
  privateProfile: boolean;
  subscriptionStatus: ESubscriptionStatus;
}
