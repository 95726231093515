/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, EventEmitter, Output } from '@angular/core';

// libraries
import { NzModalService } from 'ng-zorro-antd/modal';

// services
import { AuthService } from 'src/app/services/auth.service';

// models
import { IGuildExternalLink } from 'src/app/modules-guilds/models/guild-external-link.model';
import { IGuild } from '../../../modules-guilds/models/guild.model';
import { ITrack } from 'src/app/models/track.model';

// types
import { ELinkType } from '../../../modules-guilds/types/guild-link-type.type';
import { EditMode } from '../../types/edit-mode.type';
import { UploadType } from 'src/app/modules-guilds/types/upload-type.type';
import { StringValidationPipe } from 'src/app/generic-pipes/string-validation.pipe';

export interface linkProperties {
  type: ELinkType;
  thumbnail: string;
  link: string;
}

@Component({
  selector: 'sl-guild-editor',
  templateUrl: './guild-editor.component.html',
  styleUrls: ['./guild-editor.component.less'],
  providers: [StringValidationPipe]
})
export class GuildEditorComponent implements OnInit, OnChanges {
  @ViewChild('guildnamefocus') vc!: ElementRef;
  @ViewChild('uploadGuide') uploadGuide!: ElementRef;
  @Output() inputValidation = new EventEmitter<boolean>();
  @Output() newlyCreatedGuild = new EventEmitter<IGuild>();
  @Output() editedGuild = new EventEmitter<IGuild>();
  @Output() creationStatus = new EventEmitter<boolean>();
  @Output() editingStatus = new EventEmitter<boolean>();
  @Input() mode: EditMode = EditMode.new;
  @Input() incomingGuild!: IGuild;
  public guildCurrent!: IGuild;

  //used to control what step are shown and not shown
  isStepValid = new Map<string, boolean | undefined>([
    ['nameEntered', false],
    ['nameValid', false],
    ['industrySelected', false],
    ['professionSelected', false],
    ['specialisationSelected', undefined],
    ['levelFromSelected', false],
    ['levelToSelected', false],
    ['trackSelected', false],
  ]);

  public stepIndex = 0;
  public isCreatingGuild = false;

  public modeType = EditMode; // hack to get around the limitations of JavaScript when it comes to enums; https://marco.dev/enums-angular
  public uploadOptions = UploadType.image;
  public uploadType = UploadType;
  public guildDescriptionValue = '';

  public nameInputBarSelected = true;
  public showCondensedUploadGuide = false;

  public guildNameError = '';

  constructor(private authService: AuthService, private modal: NzModalService, private stringValidationPipe: StringValidationPipe) { }

  get isGuildNameValid(): boolean {
    return !this.guildNameError && !this.nameShowError;
  }

  get remainingCharacters(): number {
    return Math.max(64 - this.guildCurrent.name.length, 0);
  }

  get nameShowError(): boolean {
    return (!this.guildCurrent?.name.trim()) && !this.nameInputBarSelected;
  }

  ngOnInit(): void {
    this.initGuildCurrentFromIncoming();

    setTimeout(() => {
      this.vc.nativeElement.focus();
    });
  }

  ngOnChanges(): void {
    if (this.mode === EditMode.new && !this.incomingGuild?.industry) {
      this.initGuildCurrentFromIncoming();
      return;
    }
    this.updateFieldsWithValuesFromEditedGuild();
  }

  nameFocus() {
    this.nameInputBarSelected = true;
    this.isStepValid.set('nameEntered', true);
  }

  nameBlur() {
    this.nameInputBarSelected = false;
    this.validateName();
  }

  validateAllStepInputs(): boolean | undefined {
    if (this.isGuildNameValid &&
      !this.nameShowError &&
      this.isStepValid.get('industrySelected') &&
      this.isStepValid.get('professionSelected') &&
      this.isStepValid.get('specialisationSelected') &&
      this.isStepValid.get('trackSelected') &&
      this.isStepValid.get('levelFromSelected') &&
      this.isStepValid.get('levelToSelected')
    ) {
      this.inputValidation.emit(true);
      return true;
    } else {
      this.inputValidation.emit(false);
      return false;
    }
  }

  onStepIndexChange(index: number): void {
    this.stepIndex = index;
  }

  onGuildNameChange() {
    this.guildNameError = this.stringValidationPipe.transform(this.guildCurrent.name);
    this.validateName();
  }

  validateName(): boolean {
    if (this.guildNameError) {
      this.isStepValid.set('nameValid', false);
      return false;
    }
    this.isStepValid.set('nameValid', true);
    return true;
  }

  public initSelectedIndustry(industry: string) {
    if (!industry) {
      this.isStepValid.set('industrySelected', false);
      this.isStepValid.set('professionSelected', false);
      this.resetMainSteps();
      throw new Error('Not cool to pass an undefined industry into the initialisation of the industry');
    }
    this.isStepValid.set('industrySelected', true);
    this.guildCurrent.industry = industry;
  }

  public initSelectedProfession(profession: string) {
    if (!profession) {
      this.isStepValid.set('professionSelected', false);
      this.resetMainSteps();
      throw new Error('Not cool to pass an undefined profession into the initialisation of the profession');
    }
    this.isStepValid.set('professionSelected', true);
    this.guildCurrent.profession = profession;
  }

  public initSelectedSpecialisation(specialisation: string) {
    if (!specialisation) {
      this.guildCurrent.specialisation = specialisation;
      return;
    }
    this.isStepValid.set('specialisationSelected', !(!specialisation));
    this.guildCurrent.specialisation = specialisation;
  }

  public initSelectedLevelFrom(levelFrom: number) {
    if (levelFrom === -1) {
      this.isStepValid.set('levelFromSelected', false);
      this.isStepValid.set('levelToSelected', false);
      this.isStepValid.set('trackSelected', false);
      return;
    }
    this.isStepValid.set('levelFromSelected', true);
    this.guildCurrent.levelMin = levelFrom;
  }

  public initSelectedLevelTo(levelTo: number) {
    if (levelTo === -1) {
      this.isStepValid.set('levelFromSelected', false);
      this.isStepValid.set('levelToSelected', false);
      this.isStepValid.set('trackSelected', false);
      return;
    }
    this.isStepValid.set('levelToSelected', true);
    this.guildCurrent.levelMax = levelTo;
  }

  public selectedTrackChanged(track: ITrack) {
    this.isStepValid.set('trackSelected', !(!track));
    if (track) {
      this.guildCurrent.relatedTrackName = track.name;
      this.guildCurrent.relatedTrackId = track.id;
    }
  }

  public initSelectedTrackName(trackName: string) {
    this.isStepValid.set('trackSelected', !(!trackName));
    this.guildCurrent.relatedTrackName = trackName;
    if (trackName === '' || trackName === 'All') {
      this.guildCurrent.relatedTrackId = 0;
    }
  }

  initEnteredLinks(links: IGuildExternalLink[]) {
    this.guildCurrent.externalLinks = links;
  }

  initUploadedImageUrl(url: string) {
    this.guildCurrent.imageUrl = url;
  }

  initUploadedGlyphClassname(classname: string) {
    this.guildCurrent.imageIcon = classname;
  }

  cancel(): void {
    if (this.mode === EditMode.new) {
      this.creationStatus.emit(false);
    } else {
      this.editingStatus.emit(false);
    }
  }

  updateFieldsWithValuesFromEditedGuild() {
    if (this.mode === EditMode.edit && !this.incomingGuild) {
      return;
    }

    if (!this.guildCurrent) {
      this.initGuildCurrentFromIncoming();
    }

    this.initSelectedIndustry(this.incomingGuild.industry);
    this.initSelectedProfession(this.incomingGuild.profession ? this.incomingGuild.profession : 'All');
    this.initSelectedSpecialisation(this.incomingGuild.specialisation ? this.incomingGuild.specialisation : 'All');
    this.initSelectedLevelFrom(this.incomingGuild.levelMin);
    this.initSelectedLevelTo(this.incomingGuild.levelMax);
    this.initSelectedTrackName(this.incomingGuild.relatedTrackName ? this.incomingGuild.relatedTrackName : 'All');
    this.initUploadedImageUrl(this.incomingGuild.imageUrl);
    this.initUploadedGlyphClassname(this.incomingGuild.imageIcon);
  }

  openCondensedUploadGuide() {
    this.showCondensedUploadGuide = true;
  }

  closeCondensedUploadGuide() {
    this.showCondensedUploadGuide = false;
  }

  public submitGuild(): void {
    if (!this.validateAllStepInputs()) {
      console.error('Guild controls are not valid and somehow we got to the point where they are submitting  the guild', { nzDuration: 5000 });
      throw new Error('All fields must be valid before submitting');
    }

    if (!this.guildCurrent.description) {
      this.modal.info({
        nzTitle: this.mode === EditMode.new ? '<i>Are you sure you want to create a guild without a description?</i>' : '<i>Are you sure you want to edit this without a description?</i>',
        nzContent: 'The description is your opportunity to convey to people who this guild is for, what it covers and why people might want to join.',
        nzOnOk: () => this.guildSubmitted(),
        nzOkText: 'Yes',
        nzCancelText: 'No',
      });
    } else {
      if (this.guildCurrent.profession === 'All') {
        this.guildCurrent.profession = '';
      }

      if (this.guildCurrent.specialisation === 'All') {
        this.guildCurrent.specialisation = '';
      }

      if (this.guildCurrent.relatedTrackName === 'All') {
        this.guildCurrent.relatedTrackName = '';
      }

      this.guildSubmitted();

      return;
    }
  }

  private initNewGuild() {
    this.incomingGuild = {
      name: '',
      description: '',
      location: { country: 'Australia', city: 'Sydney' },
      externalLinks: [] as IGuildExternalLink[]
    } as IGuild;

    this.guildCurrent = JSON.parse(JSON.stringify(this.incomingGuild));
  }

  private initGuildCurrentFromIncoming() {
    if (this.mode === EditMode.edit) {
      if (!this.guildCurrent) {

        if (!this.incomingGuild.profession || this.incomingGuild.profession.toLocaleLowerCase().includes('all')) {
          this.incomingGuild.profession = 'All';
        }
        if (!this.incomingGuild.specialisation || this.incomingGuild.specialisation.toLocaleLowerCase().includes('all')) {
          this.incomingGuild.specialisation = 'All';
        }
        if (!this.incomingGuild.relatedTrackName || this.incomingGuild.relatedTrackName.toLocaleLowerCase().includes('all')) {
          this.incomingGuild.relatedTrackName = 'All';
        }
        this.guildCurrent = JSON.parse(JSON.stringify(this.incomingGuild));
        this.nameFocus();
      }
    }
    else {
      this.initNewGuild();
    }
  }

  private resetMainSteps() {
    this.isStepValid.set('specialisationSelected', false);
    this.isStepValid.set('levelFromSelected', false);
    this.isStepValid.set('levelToSelected', false);
    this.isStepValid.set('trackSelected', false);
  }

  private guildSubmitted() {
    // TODO: no, this should be coming from the backend. Ie, we don't need to set it if we're editing a guild because it should already be set, and if it's a new guild, we don't need to set it because the backend will do it for us.
    this.guildCurrent.createdById = this.authService.userId;

    // TODO: Sam Send this off to the backend, subscribe to the results, and if successful, emit the newly created guild.
    this.isCreatingGuild = true;
    if (this.mode === EditMode.new) {
      this.newlyCreatedGuild.emit(this.guildCurrent);
    } else {
      this.editedGuild.emit(this.guildCurrent);
    }
  }
}
