<div nz-row nzFlex="auto" nzJustify="center">
  <div nz-col nzSm="14">
    <nz-card id="add-activity-card" nzTitle="{{ recommendationTitle }}">
      <div *ngIf="loaded">
        <div id="recommendation-content">
          <div *ngIf="activityId" id="edit-recommendation-activity" class="center edit-recommendation">
            <sl-manual-user-activity id="manual-activity" [noMatchingGoals]="showNoMatchingGoalWarning" [isActivityRecommendation]="isActivityRecommendation" [isActivityRecommendationAdded]="isActivityRecommendationAdded" [matchingActivityGoals]="matchingActivityGoals"
              [recommendation]="activity" [activityGoalId]="selectedGoalId"></sl-manual-user-activity>
          </div>
          <div *ngIf="playlist" id="edit-recommendation-playlist" class="center edit-recommendation">
            <div id="playlist-information">
              <div class="grid-container">
                <div class="grid-image">
                  <img id="playlist-image" src="{{ playlist.trackImageUrl }}" onerror="this.onerror=null;this.src='assets/img/sealadder/SL_logo_Cropped_692x422.png';" />
                </div>
                <div class="grid-fields">
                  <div *ngIf="playlist.industry" nz-typography>
                    <strong>Industry:</strong>
                  </div>
                  <div *ngIf="playlist.profession" nz-typography>
                    <strong>Profession:</strong>
                  </div>
                  <div *ngIf="playlist.specialisation" nz-typography>
                    <strong>Specialisation:</strong>
                  </div>
                  <div *ngIf="playlist.relatedTrackName" nz-typography>
                    <strong>Track:</strong>
                  </div>
                  <div *ngIf="playlist.levelFrom && playlist.levelTo" nz-typography>
                    <strong>Level Range:</strong>
                  </div>
                  <div nz-typography>
                    <strong>Author:</strong>
                  </div>
                  <div *ngIf="playlist.dateCreated" nz-typography>
                    <strong>Date Created:</strong>
                  </div>
                  <div *ngIf="playlist.playlistActivitiesVm.length > 0" nz-typography>
                    <strong>Activities:</strong>
                  </div>
                </div>
                <div class="grid-info">
                  <div *ngIf="playlist.industry" nz-typography nzEllipsis>{{ playlist.industry }}</div>
                  <div *ngIf="playlist.profession" nz-typography nzEllipsis>{{ playlist.profession }}</div>
                  <div *ngIf="playlist.specialisation" nz-typography nzEllipsis>{{ playlist.specialisation }}</div>
                  <div *ngIf="playlist.relatedTrackName" nz-typography nzEllipsis>{{ playlist.relatedTrackName }}</div>
                  <div *ngIf="playlist.levelFrom && playlist.levelTo" nz-typography>{{ playlist.levelFrom }} - {{ playlist.levelTo }}</div>
                  <div nz-typography nzEllipsis>{{ playlist.createdByFirstName }} {{ playlist.createdByLastName }}</div>
                  <div *ngIf="playlist.dateCreated" nz-typography>{{ playlist.dateCreated | date: 'mediumDate' }}</div>
                  <div *ngIf="playlist.playlistActivitiesVm.length > 0" nz-typography>{{ playlist.playlistActivitiesVm.length }}</div>
                </div>
              </div>
            </div>
            <nz-affix [nzOffsetTop]="offsetTop" (nzChange)="recordNzAffixChange()">
              <div id="playlist-section">
                <div *ngIf="isLoggedIn; else notLoggedIn">
                  <div class="playlist-section-content">
                    <nz-select *ngIf="!isUserPlaylistCreated" id="select-user-goal" [(ngModel)]="selectedGoal" nzAllowClear nzPlaceHolder="Select the goal for this playlist">
                      <nz-option class="user-goals" *ngFor="let userGoal of userGoals" nzLabel="{{ userGoal.profession }} {{ userGoal.specialisation }} {{ userGoal.level }}" [nzValue]="userGoal"></nz-option>
                    </nz-select>
                    <div class="no-matching-goal-alert" *ngIf="showNoMatchingGoalWarning">
                      <nz-alert nzType="warning" nzCloseable nzMessage="Warning: You haven't created any goals that are a good match for this playlist." (nzOnClose)="afterClose()">
                      </nz-alert>
                      <span class="goal-creator-link">
                        <a id="goal-creator" nzType="link" [routerLink]="['/profile/overview']"
                          [queryParams]="{playlistId: getPlaylistIdFromRoute(), industry: playlist.industry, profession: playlist.profession, specialisation: playlist.specialisation, level: playlist.levelFrom}">
                          Can't find a suitable goal? Why not create one of your own.</a>
                      </span>
                    </div>
                    <button *ngIf="!isUserPlaylistCreated" id="add-playlist" nz-button nzType="primary" [nzLoading]="isLoadingAddPlaylist" (click)="addPlaylist(playlist, selectedGoal)">Add Playlist</button>
                    <div *ngIf="isUserPlaylistCreated" id="add-playlist-complete">
                      <span nz-typography nzType="success"><i nz-icon [nzType]="'check-circle'"></i></span> Playlist added to your profile
                    </div>
                  </div>
                </div>
                <ng-template #notLoggedIn>
                  <div class="playlist-section-content">
                    <p class="playlist-section-description">Create an account to start this playlist</p>
                    <button id="button-create-account" nz-button nzType="primary" (click)="goToInvitationPageWithPlaylistInviteCode()">
                      Create Account
                      <sl-help-info onclick="event.stopPropagation()" title="Start your SeaLadder journey" description="Create an account with us so you can view/complete the activities listed in this playlist and learn the skills associated with them.">
                      </sl-help-info>
                    </button>
                  </div>
                  <nz-alert id="user-benefits" nzType="info" nzMessage="As part of our early access offer, create your SeaLadder account NOW to: " [nzDescription]="userBenefits" nzShowIcon>
                    <ng-template #userBenefits>
                      <ul style="margin-left: -25px">
                        <li>Free early access to SeaLadder, and then,</li>
                        <li>50% off the first 3 months of membership, or</li>
                        <li>15% off anual membership</li>
                        <li>Fast-tracked for your next job or promotion</li>
                        <li>Unlimited activities</li>
                        <li>Unlimited active goals</li>
                      </ul>
                      The activities in this playlist are tailored to improve specific skills for this career path. Level up your career by investing in these skills, and get recognition for completing these and many more activities that are tailored for your
                      goals.
                    </ng-template>
                  </nz-alert>
                </ng-template>
              </div>
            </nz-affix>
            <div class="playlist-activities-loading-spinner" *ngIf="!loadedPlaylistActivities">
              <nz-spin class="loading-message" nzTip="Loading Activities..." [nzSize]="'large'"></nz-spin>
            </div>
            <div id="playlist-activities" *ngIf="loadedPlaylistActivities">
              <div *ngFor="let activity of playlist.playlistActivitiesVm; index as i" id="this-one-{{ activity.id }}">
                <div class="activity-container">
                  <img *ngIf="!isShowingLearningTypeIcon(activity)" alt="Activity Image" class="card-img" src="{{ setPlaylistActivityImageUrl(activity) }}" loading="lazy" (error)="handleMissingImage($event, activity)"
                    onerror="this.onerror=null;this.src='assets/img/placeholder_image.png';" />
                  <i *ngIf="isShowingLearningTypeIcon(activity)" class="learning-type-icon {{activity.learningTypeIcon}}"></i>
                  <div class="vertial-align">
                    <div class="playlist-activity-title">
                      <a *ngIf="i < 5; else regularTitle" href="{{ activity.link }}" target="_blank">{{ getTitle(activity) }}</a>
                      <ng-template #regularTitle>
                        <a (click)="goToInvitationPageWithPlaylistInviteCode()">{{ getTitle(activity) }}</a>
                      </ng-template>
                    </div>
                    <div class="playlist-skill-list">
                      <span id="playlist-skill">{{ getPlaylistSkills(activity.skills) }}</span>
                    </div>
                    <div class="activity-icon-group">
                      <i class="{{ getActivityTypeIcon(activity.typeName) }} card-provider-icon" nz-tooltip [nzTooltipTitle]="activity.typeName"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nz-result *ngIf="!activity && !playlist" nzStatus="500" nzTitle="500" nzSubTitle="Hmm, something went wrong while trying to process your request.">
          <div nz-result-extra>
            <button nz-button nzType="primary" [routerLink]="['/profile/overview']">Back Home</button>
          </div>
        </nz-result>
      </div>
    </nz-card>
  </div>
</div>