/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

//services
import { TrackService } from 'src/app/services/track.service';
// models
import { ITrack } from 'src/app/models/track.model';
import { EditMode } from '../../types/edit-mode.type';

@Component({
  selector: 'sl-track-selector',
  templateUrl: './track-selector.component.html',
  styleUrls: ['./track-selector.component.less'],
})
export class TrackSelectorComponent implements OnChanges {
  @Output() selectTrackEvent = new EventEmitter<ITrack>();
  @Input() currentMode!: EditMode;
  @Input() previouslySelectedIndustry!: string;
  @Input() previouslySelectedProfession!: string;
  @Input() previouslySelectedSpecialisation!: string;
  @Input() previouslySelectedLevelFrom!: number;
  @Input() previouslySelectedLevelTo!: number;
  @Input() previouslySelectedTrackName!: string;
  @Input() selectedIndustry!: string;
  @Input() selectedProfession!: string;
  @Input() selectedSpecialisation!: string;
  @Input() selectedLevelFrom!: number;
  @Input() selectedLevelTo!: number;

  selectedTrackName!: string;
  trackOptions: string[] = [];
  tracks: ITrack[] = [];
  isLoading = false;

  // undefined = hasn't been set
  // empty string = has been set to all
  // All = has been set to all
  private previousValues = new Map<string, string | number | undefined>([
    ['nameEntered', undefined],
    ['nameValid', undefined],
    ['industrySelected', undefined],
    ['professionSelected', undefined],
    ['specialisationSelected', undefined],
    ['levelFromSelected', undefined],
    ['levelToSelected', undefined],
  ]);

  constructor(private trackService: TrackService, private message: NzMessageService) { }

  ngOnChanges(): void {
    this.initTracks();
  }

  selectTrack(trackName: string): void {
    this.selectedTrackName = trackName;
    if (trackName === 'All') {
      this.selectTrackEvent.emit({ name: 'All' } as ITrack);
    }
    else {
      if (trackName) {
        const trackFound = this.tracks.find((t) => t.name === trackName);
        this.selectTrackEvent.emit(trackFound);
      }
    }
  }

  private initTracks() {
    // this is an optimisation so we're not getting the tracks again if we don't need to
    if (this.previouslySetTrackValues()) {
      return;
    }

    this.isLoading = true;
    this.trackService.getTracksApprovedBasedOnSignature(this.selectedIndustry, this.selectedProfession, this.selectedSpecialisation, this.selectedLevelFrom, this.selectedLevelTo).subscribe({
      next: (ret) => {
        this.isLoading = false;
        this.setPreviousTrackValues();
        this.selectedTrackName = '';
        this.tracks = ret;
        this.trackOptions = Array.from(new Set(['All', ...ret.map((track) => track.name)]));
        if (this.trackOptions.length === 1 && (this.trackOptions[0] === 'All' || this.trackOptions[0] === '')) {
          this.selectTrack('All');
        } else {
          this.selectTrack(this.previouslySelectedTrackName);
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
        this.message.error(`Oh dear, this is embarassing. We weren't able to get the tracks. Check your network connection and try again in a few moments`, { nzDuration: 5000 });
        this.trackOptions = ['All'];
      },
    });
  }

  private previouslySetTrackValues() {
    const allDefined = this.selectedIndustry && this.selectedProfession && this.selectedSpecialisation && this.selectedLevelFrom && this.selectedLevelTo;
    return allDefined &&
      this.selectedIndustry === this.previousValues.get('industry') &&
      this.selectedProfession === this.previousValues.get('profession') &&
      this.selectedSpecialisation === this.previousValues.get('specialisation') &&
      this.selectedLevelFrom === this.previousValues.get('levelFrom') &&
      this.selectedLevelTo === this.previousValues.get('levelTo');
  }

  private setPreviousTrackValues() {
    this.previousValues.set('industry', this.selectedIndustry);
    this.previousValues.set('profession', this.selectedProfession);
    this.previousValues.set('specialisation', this.selectedSpecialisation);
    this.previousValues.set('levelFrom', this.selectedLevelFrom);
    this.previousValues.set('levelTo', this.selectedLevelTo);
  }
}
