import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

// models
import { IPlaylist } from 'src/app/models/playlist.model';
import { IPlaylistActivity } from 'src/app/models/playlist-activity.model';
import { ISkill } from 'src/app/models/skill.model';

// services
import { PlaylistService } from 'src/app/services/playlist.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { UserPlaylistService } from 'src/app/services/user-playlist.service';

// pipes
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- this is used in the template
import { SingularPluralPipe } from 'src/app/generic-pipes/singular-plural.pipe';

@Component({
  selector: 'sl-playlist-card',
  templateUrl: './playlist-card.component.html',
  styleUrls: ['./playlist-card.component.less'],
})
export class PlaylistCardComponent {
  @Input() playlist!: IPlaylist;
  @Input() goalId!: number;
  @Input() goalSkills!: ISkill[];

  @Output() playlistAdded = new EventEmitter<number>();
  @Output() activityAdded = new EventEmitter<number>();

  isHovered = false;
  childrenVisible = false;
  isDrawerVisible = false;
  isAdding = false;
  MAX_ACTIVITIES = 8;
  activitiesShown = this.MAX_ACTIVITIES;
  readonly defaultCardImage = 'assets/img/playlists/default-playlist.jpg';
  // 'https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80';

  constructor(private userPlaylistService: UserPlaylistService, private playlistService: PlaylistService, private userActivitiesService: UserActivitiesService, private message: NzMessageService, private notification: NzNotificationService) {}

  public get addedToBacklog(): boolean {
    return this.playlist.added;
  }

  public set addedToBacklog(added: boolean) {
    this.playlist.added = added;
  }

  showDrawer(show: boolean) {
    if (!this.playlist.playlistActivitiesVm) {
      this.playlistService.getPlaylistActivities(this.playlist.id).subscribe({
        next: (res: IPlaylistActivity[]) => {
          this.playlist.playlistActivitiesVm = res;
          this.isDrawerVisible = show;
        },
        error: () => {
          this.message.error(`We've lost connection to SeaLadder HQ and can't get the activities for this playlist. Check network connection and try again?`);
        },
      });
    } else {
      this.isDrawerVisible = show;
    }
  }

  activityDrawerVisible(visible: boolean) {
    this.childrenVisible = visible;
  }

  increaseActivitiesShown() {
    this.activitiesShown += this.MAX_ACTIVITIES;
  }

  addToBacklog() {
    this.isAdding = true;
    this.userPlaylistService.addUserPlaylistToGoal(this.playlist.id, this.goalId).subscribe({
      next: (upl) => {
        this.addedToBacklog = true;
        this.userActivitiesService.updateActivitiesCache();
        this.handlePlaylistAdded(upl.playlistId as number);
        this.isAdding = false;
        this.message.success('Nice, you\'ve added the playlist to your profile.');
        if (upl.allCompleted) {
          this.notification.info('Playlist Added', `Playlist successfully added, but the activities in this playlist were already completed 🎉.\nWe'll keep you informed when there are changes to it in the future.`, { nzDuration: 0 });
        }
      },
      error: (error: HttpErrorResponse) => {
        // console.error(`addTobacklog() returned with error: ${error.message}`);
        if (error.status === 409) {
          this.addedToBacklog = true; // since we know they already have this activity, then let's mark it as accepted, but we have to wonder why it was here in the first place.
          this.handlePlaylistAdded(this.playlist.id);
          this.message.warning('We have it on good authority that you have added this playlist before.');
        } else {
          this.message.error('Oh dear, this is embarassing, something went wrong 😳. Try again? ');
          this.addedToBacklog = false;
        }
        this.isAdding = false;
      },
    });
  }

  createNotification(type: string): void {
    this.notification.create(type, 'Notification Title', 'This is the content of the notification. This is the content of the notification. This is the content of the notification.');
  }

  imageEnter() {
    this.isHovered = true;
  }

  imageLeave() {
    this.isHovered = false;
  }

  getActivityCount(): number {
    if (!this.playlist) {
      return 0;
    }

    if (!this.playlist.playlistActivitiesVm) {
      return this.playlist.activityCount;
    }

    // if (this.playlist.activityCount != this.playlist.playlistActivitiesVm.length) {
    //   console.debug(`Activity count is not equal to the number of activities in the playlist. Playlist: ${this.playlist.id} has ${this.playlist.activityCount} activity count, but ${this.playlist.playlistActivitiesVm.length} activities in the playlist.`);
    // }

    return this.playlist.playlistActivitiesVm.length;
  }

  getAuthor(): string {
    let author = '';
    if (this.playlist) {
      author = this.playlist.createdByFirstName + ' ' + this.playlist.createdByLastName;
      return author;
    }
    return author;
  }

  getPlayListDetails(): string {
    if (!this.playlist) {
      console.error('playlist is not defined when we\'re showing a playlist. That just doesn\'t make sense');
      return 'INVALID';
    }

    const levelFrom = this.playlist.levelFrom ? this.playlist.levelFrom.toLocaleString() : '*';
    const levelTo = this.playlist.levelTo ? ' - ' + this.playlist.levelTo.toLocaleString() : '';
    const specialisation = this.playlist.specialisation ? this.playlist.specialisation : '';

    return `${specialisation} [${levelFrom}${levelTo}], by ${this.getAuthor()}`;
  }

  getCardImage(): string {
    if (this.playlist.playlistImageUrl) {
      return this.playlist.playlistImageUrl;
    }

    if (this.playlist.trackImageUrl) {
      return this.playlist.trackImageUrl;
    }

    if (this.playlist.playlistActivitiesVm) {
      let image = '';
      // gets the first activity that has a valid thumbnail URL
      this.playlist.playlistActivitiesVm.every((pav) => {
        if (pav.imageThumbnailUrl) {
          image = pav.imageThumbnailUrl;
          return false;
        }
        return true;
      });
      if (image) {
        return image;
      }
    }
    return this.defaultCardImage;
  }

  public handleActivityAdded(activityId: number) {
    // console.log(`Recommendation for id: '${activityId}' has been added successfully. Notifying listeners...`);
    this.activityAdded.emit(activityId);
  }

  // called when the playlist is added to let the parent component know.
  private handlePlaylistAdded(playlistId: number) {
    // console.log(`Playlist id: '${playlistId}' has been added successfully. Notifying listeners...`);
    this.playlistAdded.emit(playlistId);
  }
}
