import { NgModule } from '@angular/core';

// services
import { AccountDetailsService } from '../services/account-details.service';
import { AuthGuard } from '../services/auth.guard';
import { InviteService } from '../services/invite.service';
import { PublicProfileService } from '../services/public-profile.service';
import { RoutingStateService } from '../services/routing-state.service';
import { SuggestionsService } from '../services/suggestions.service';

// components
import { AccountDetailsComponent } from './components/user-details/account-details/account-details.component';
import { BillingDetailsComponent } from './components/user-details/billing-details/billing-details.component';
import { IndividualInviteCodeComponent } from './components/user-details/account-details/individual-invite-code/individual-invite-code.component';
import { InvoicesComponent } from './components/user-details/billing-details/invoices/invoices.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';

// modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';


const routes: Routes = [
  {
    path: 'account',
    component: UserDetailsComponent,
    children: [
      { path: '', redirectTo: 'account-details', pathMatch: 'full' },
      { path: 'account-details', component: AccountDetailsComponent },
      { path: 'billing-details', component: BillingDetailsComponent }
    ],
  }
];

@NgModule({
  declarations: [AccountDetailsComponent, BillingDetailsComponent, IndividualInviteCodeComponent, InvoicesComponent, UserDetailsComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    NzButtonModule,
    NzCardModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzIconModule,
    NzSelectModule,
    NzSkeletonModule,
    NzSpaceModule,
    NzSpinModule,
    NzTableModule,
    NzToolTipModule,
    NzTabsModule,
    NzUploadModule,
    NzUploadModule,
    NzPopconfirmModule,
    NzPopoverModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
  ],
  providers: [AccountDetailsService, AuthGuard, InviteService, PublicProfileService, RoutingStateService, SuggestionsService],
  exports: [UserDetailsComponent, AccountDetailsComponent, BillingDetailsComponent, RouterModule],
})
export class AccountModule { }
