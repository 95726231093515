<div *ngIf="!industries?.length" class="playlist-invite-loading">
  <nz-spin class="loading-message" nzTip="Attempting a mind meld to establish your goals..." [nzSize]="'large'"></nz-spin>
</div>
<div *ngIf="industries?.length" class="sl__main" style="position: relative">
  <div *ngIf="!noGoals" class="sl--description">Personalise your experience by telling us what you're interested in as a career</div>
  <h1 *ngIf="noGoals" class="sl--description">Everyone needs a goal, so let's create your first one!</h1>
  <nz-alert *ngIf="noGoals" nzType="info" nzShowIcon nzMessage="Creating your first goal" [nzDescription]="descriptionTpl">
    <ng-template #descriptionTpl>
      <div>
        <br />
        Since this is your first goal, we recommend you take your time.<br />
        <br />
        <strong>More information</strong><br />
        In each section, we've got additional information that might make it clearer. Just click on the information icon <i class="fas fa-info-circle help-info-icon"></i>.<br />
        <br />
        <strong>Selecting Skills</strong><br />
        When selecting the skills for your goal, be sure to review each category carefully and consider what <strong>else</strong> you should include, and of course, which skills you can remove. We provide skills based on what other people have done with the
        same goal. Don't worry too much, you can always change it later, but it's still a good idea to get off to a good start.
        <br />
      </div>
    </ng-template>
  </nz-alert>
  <br />
  <br />

  <!-- Step 1: Industry -->
  <div id="step-1" class="step">
    <div class="step__status" [ngClass]="{
        'status--waiting': !selectedIndustry,
        'status--done': visited(goalStep.INDUSTRY)
      }">
      <div *ngIf="!selectedIndustry; else elseBlock">1</div>
      <ng-template #elseBlock>
        <i nz-icon nzType="check" nzTheme="outline"></i>
      </ng-template>
    </div>

    <div style="display: inline-block" *ngIf="selectedIndustry; else optionIndustry">
      <div id="step-1-title" (click)="visit(goalStep.INDUSTRY)" class="step__title" [ngClass]="{
          'title--current': currentStep >= goalStep.INDUSTRY,
          'title--current': editMode
        }">
        I want to work in the field of {{ goal.industry }}
      </div>
    </div>
    <ng-template style="display: inline-block" #optionIndustry>
      <div (click)="visit(goalStep.INDUSTRY)" class="step__title" [ngClass]="{ 'title--current': currentStep >= goalStep.INDUSTRY }">I want to work in the field of...</div>
    </ng-template>
    <sl-help-info title="Select your Industry" contentIconNgZorroDuo="funnel-plot" description="
      The industry is the main area of skills that you work in, as opposed to any domain specific area for the specific company.<br><br>
      If you're not sure, just choose something and you can always come back and change it.<br><br>
      As you learn, you'll discover what you like and don't like, so there's no point trying too hard to get the decision right the first time.<br><br>
      <em>PICK SOMETHING AND START YOUR JOURNEY.</em>">
    </sl-help-info>
  </div>

  <div class="h-radio-group">
    <div *ngIf="!editMode" class="sl__h-block" [@openClose]="currentStep === goalStep.INDUSTRY || !selectedIndustry ? 'open' : 'closed'">
      <div class="h-radio-group__tile" *ngFor="let industry of industries; let i = index" id="step-1-button-{{ i }}" [ngClass]="{ 'tile--selected': industry === selectedIndustry }" (click)="selectIndustry(industry); visit(goalStep.INDUSTRY); visit(goalStep.PROFESSION)">
        {{ industry }}
      </div>
    </div>
  </div>

  <!-- Step 2: Profession -->
  <div id="step-2" class="step">
    <div class="step__status" [ngClass]="{
        'status--waiting': !selectedProfession,
        'status--done': visited(goalStep.PROFESSION)
      }">
      <div *ngIf="!selectedProfession; else elseBlock">2</div>
      <ng-template #elseBlock>
        <i nz-icon nzType="check" nzTheme="outline"></i>
      </ng-template>
    </div>

    <div style="display: inline-block" *ngIf="selectedProfession; else professionNotSet">
      <div id="step-2-title" (click)="visit(goalStep.PROFESSION)" class="step__title" [ngClass]="{
          'title--current': currentStep >= goalStep.PROFESSION,
          'title--current': editMode
        }">
        I want to be a {{ goal.profession }}
      </div>
    </div>
    <ng-template style="display: inline-block" #professionNotSet>
      <div (click)="visit(goalStep.PROFESSION)" class="step__title" [ngClass]="{ 'title--current': currentStep >= goalStep.PROFESSION }">I want to be a...</div>
    </ng-template>
    <sl-help-info title="Select your Profession" contentIconNgZorroDuo="funnel-plot" description="
      The profession is very closely correlated with the job title. Many companies have different flavours of the same job title, so don't get hung up on semantics.<br><br>
      If you're not sure, just choose the closest thing.<br><br>
      We'll be adding more over time, but if you can't wait, get in touch and we'll see what we can do<br><br>
      <em>FOLLOW THE YELLOW BRICK ROAD.</em>">
    </sl-help-info>
  </div>

  <div class="h-radio-group">
    <div *ngIf="!editMode" class="sl__h-block" [@openClose]="currentStep === goalStep.PROFESSION || !selectedProfession ? 'open' : 'closed'">
      <div class="h-radio-group__tile" *ngFor="let profession of professions; let i = index" id="step-2-button-{{ i }}" [ngClass]="{ 'tile--selected': profession === selectedProfession }"
        (click)="selectProfession(profession); visit(goalStep.PROFESSION); visit(goalStep.SPECIALISATION)">
        {{ profession }}
      </div>
    </div>
  </div>

  <!-- Step 3: Specialisations -->
  <div id="step-3" class="step">
    <div class="step__status" [ngClass]="{
        'status--waiting': !selectedSpecialisation,
        'status--done': visited(goalStep.SPECIALISATION)
      }">
      <div *ngIf="!selectedSpecialisation || !visited(goalStep.SPECIALISATION); else elseBlock">3</div>
      <ng-template #elseBlock>
        <i nz-icon nzType="check" nzTheme="outline"></i>
      </ng-template>
    </div>

    <div style="display: inline-block" *ngIf="selectedSpecialisation; else specialisationNotSet">
      <div id="step-3-title" class="step__title" (click)="visit(goalStep.SPECIALISATION)" [ngClass]="{
          'title--current': currentStep >= goalStep.SPECIALISATION,
          'title--current': editMode
        }">
        I want to specialise in {{ goal.specialisation }}
      </div>
    </div>

    <ng-template style="display: inline-block" #specialisationNotSet>
      <div (click)="visit(goalStep.SPECIALISATION)" class="step__title" [ngClass]="{ 'title--current': currentStep >= goalStep.SPECIALISATION }">I want to specialise in...</div>
    </ng-template>
    <sl-help-info title="Selecting Your Specialisation" image="/assets/img/goals/smart-goal-1.png" description="
      Your specialisation is the specific area of skills your profession is typically divided into or looks for. It's often something that might appear in the job description.<br><br>
      It's common that specialisations often complement eachother, but you'll want to choose a primary one to start with and get good at that.<br><br>
      You can add other goals if you want to have multiple specialisations.<br><br>
      <em>CREATING SPECIFIC GOALS IS THE FIRST STEP TO ACHIEVING THEM.</em>">
    </sl-help-info>
  </div>

  <div class="h-radio-group">
    <div *ngIf="!editMode" id="step-3-specialisations" class="sl__h-block" [@openClose]="currentStep === goalStep.SPECIALISATION && specialisations?.length > 0 ? 'open' : 'closed'">
      <div class="h-radio-group__tile" *ngFor="let specialisation of specialisations; let i = index" id="step-3-button-{{ i }}" (click)="selectSpecialisation(specialisation); visit(goalStep.SPECIALISATION); visit(goalStep.LEVEL)" [ngClass]="{
          'tile--selected': specialisation === selectedSpecialisation
        }">
        {{ specialisation }}
      </div>
    </div>
  </div>

  <!-- Step 4: Experience Level -->
  <div id="step-4" class="step">
    <div class="step__status" [ngClass]="{
        'status--waiting': !selectedLevel,
        'status--done': visited(goalStep.LEVEL)
      }">
      <div *ngIf="!selectedLevel; else elseBlock">4</div>
      <ng-template #elseBlock>
        <i nz-icon nzType="check" nzTheme="outline"></i>
      </ng-template>
    </div>

    <div style="display: inline-block" *ngIf="selectedExperience && selectedLevel; else levelDefault">
      <div class="step-4-title step__title" (click)="visit(goalStep.LEVEL)" [ngClass]="{
          'title--current': currentStep >= goalStep.LEVEL,
          'title--current': editMode
        }">
        The next level I want to progress to is
        {{ getPositionLevel(selectedExperience) }}, level {{ selectedLevel }}
      </div>
    </div>
    <ng-template style="display: inline-block" #levelDefault>
      <div class="step-4-title step__title" (click)="visit(goalStep.LEVEL)" [ngClass]="{ 'title--current': currentStep >= goalStep.LEVEL }">The next level I want to progress to is...</div>
      <sl-help-info title="Selecting Your Next Level" contentIconNgZorroDuo="build" description="We recommend you choose a conservative level to start with and consolidate where you think you are.<br><br>
        You should be aiming to build evidence of credibility which supports your immediate career goals.
        You can add more goals at higher levels and work on them at the same time.<br><br>
        <em>ONWARD AND UPWARD!</em>">
      </sl-help-info>
    </ng-template>
  </div>

  <div class="h-radio-group">
    <div *ngIf="!editMode" class="sl__h-block" [@openClose]="currentStep === goalStep.LEVEL ? 'open' : 'closed'" [ngClass]="{ open: currentStep === goalStep.LEVEL }">
      <div id="step-4-levels" *ngIf="selectedExperience">
        <div class="h-radio-group__tile" *ngFor="let experienceLevel of experienceLevels; let i = index" id="step-4-level-{{ i }}" (click)="selectPositionLevel(experienceLevel.positionLevel)" [ngClass]="{
            'tile--selected': experienceLevel.positionLevel === selectedPositionLevel
          }">
          {{ experienceLevel.positionLevel }}
          <span *ngIf="experienceLevel.positionLevel === selectedPositionLevel" style="float: right" class="tile__arrow glyphicon glyphicon-triangle-right"></span>
        </div>
      </div>

      <div class="xp__container" *ngIf="selectedExperience">
        <nz-table class="xp__table" #experienceTable [nzData]="selectedExperience?.levelDetails" [nzShowPagination]="false" [nzScroll]="{ x: '1000px' }">
          <thead>
            <tr>
              <th nzWidth="8%" class="level">LEVEL</th>
              <th nzWidth="20%" class="role">TITLE</th>
              <th nzWidth="47%" class="description">
                DESCRIPTION
                <sl-help-info title="Level Description" contentIconNgZorroDuo="build"
                  description="The description should help you decide roughly what level you're progressing to by giving you an indication of the types of expertise, capabilities and confidence you should have at that level"></sl-help-info>
              </th>
              <th *ngIf="salaryVisibility" nzWidth="150px" class="salary">
                TYPICAL SALARY ($AUD)
                <sl-help-info title="Typical Salary Help" contentIconNgZorroDuo="dollar-circle" description="
                Use this as a rough guide to help you benchmark where you're trying to go if you can't decide after looking at the description."></sl-help-info>
              </th>
              <th nzWidth="10%" class="select" nzRight="0px"></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let levels of experienceTable.data" id="tableRow-{{ levels.levelNum }}" (click)="selectedLevel = levels.levelNum" class="tableRow {{ levels.levelNum }}">
              <td class="level">
                <label for="{{ levels.levelNum }}">{{ levels.levelNum }}</label>
              </td>
              <td class="role">{{ levels.roles }}</td>
              <td class="description">
                <div *ngIf="levels.description.length > 132; else shortDescription">
                  <p nz-typography nzEllipsis [nzEllipsisRows]="2" style="margin: 0px" nzTooltipTitle="{{ levels.description }}" nzTooltipPlacement="topLeft" nz-tooltip>
                    {{ levels.description }}
                  </p>
                </div>
                <ng-template #shortDescription>
                  {{ levels.description }}
                </ng-template>
              </td>
              <td *ngIf="salaryVisibility" class="salary">
                {{ levels.salary | currency : 'AUD' : 'symbol-narrow' : '1.0' }}
              </td>
              <td class="select" nzRight="0px">
                <button nz-button nzType="primary" id="level-btn-{{ levels.levelNum }}" [ngClass]="{ invisible: selectedLevel !== levels.levelNum, selectable: selectedLevel === levels.levelNum }"
                  (click)="selectLevel(levels.levelNum); visit(goalStep.LEVEL); visit(goalStep.SKILLS)">
                  Select
                </button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>

  <!-- Step 5: Skills -->
  <div id="step-5" class="step">
    <div class="step__status" [ngClass]="{
        'status--waiting': !selectedTrackCard,
        'status--done': visited(goalStep.SKILLS)
      }">
      <div *ngIf="!selectedTrackCard; else elseBlock">5</div>
      <ng-template #elseBlock>
        <i nz-icon nzType="check" nzTheme="outline"></i>
      </ng-template>
    </div>

    <div style="display: inline-block" *ngIf="!selectedTrackCard; else trackSelected">
      <div class="step-5-title" (click)="visit(goalStep.SKILLS)" class="step__title" [ngClass]="{ 'title--current': currentStep >= goalStep.SKILLS }">Select or establish a track.</div>
      <sl-help-info title="Selecting Skills" image="/assets/img/goals/smart-goal-s.png" description="Tracks are convenient bundles of skills that will help you quickly configure your goal. The skills you define
      here are core to your goal as they determine what suggested materials will be provided.<br><br>
      These recommended skills are based on what other people have selected, but maybe that's not exactly what you want.<br><br>
      You should review each of the skills recommendations, and feel free to add or remove skills from &quot;your&quot; goal.<br><br>
      If it feels daunting, that's because it always is at first. Just think how much you have achieved already.<br><br>
      But don't strees. Keep in mind that you can always come back and add or remove skills later.<br><br>
      <em>HARD IS GOOD!</em>">
      </sl-help-info>
    </div>
    <ng-template style="display: inline-block" #trackSelected>
      <div class="step-5-title" class="step__title" (click)="deselectTrackCard()" [ngClass]="{ 'title--current': currentStep >= goalStep.SKILLS }">I will follow the "{{ selectedTrackCard }}" track to achieve this goal.</div>
      <sl-help-info title="{{selectedTrackCard}}" image="{{selectedTrackCardImage}}" description="{{selectedTrackCardDescription}}" nz-tooltip="What is the '{{selectedTrackCard}}' Track?">
      </sl-help-info>
    </ng-template>
  </div>

  <div class="h-radio-group">
    <div class="sl__h-block skill-selector" [@openClose]="currentStep === goalStep.SKILLS || editMode ? 'open' : 'closed'">
      <div *ngIf="tracksLoaded" class="tracks-wrapper">
        <div *ngFor="let track of tracks">
          <div (click)="selectTrackCard(track)">
            <sl-goal-track [goalSkills]="goal.skills" [track]="track" [selectedTrack]="selectedTrackCard" [allGoalSkills]="skills" [goalLevel]="selectedLevel" [goalIndustry]="defaultIndustry" [goalProfession]="goal.profession"> </sl-goal-track>
          </div>
        </div>
        <div *ngIf="!selectedTrackCard">
          <nz-card id="new-track-card" nzType="inner" nzTitle="Add New Track" nzHoverable>
            <input nz-input placeholder="Enter Track Name" [(ngModel)]="customTrackName" (keydown.enter)="addNewTrack()" /><br />
            <nz-alert *ngIf="invalidTrackName" nzType="warning" nzMessage="Please enter a track name"></nz-alert>
            <i class="fas fa-plus-circle fa-4x add-track-button" (click)="addNewTrack()"></i>
            <p class="track-card-description">Create your own learning track, complete with skills, if what you were expecting doesn't exist yet</p>
          </nz-card>
        </div>
      </div>
    </div>
    <div class="sl__h-block" *ngIf="currentStep === goalStep.SKILLS && !editMode">
      <div class="btn--dual" *ngIf="selectedTrackCard">
        <!-- TODO: Fix the following line by introducing a function instead of doing inline code -->
        <button nz-button nzType="default" class="button" (click)="selectedTrackCard = null; goal.selectedTrackName = null; goal.skills = []">Back</button>
        <button #selectTrackButton class="select-track-button" nz-button nzType="primary" (click)="setGoalSelectTrack(); visit(goalStep.SKILLS); visit(goalStep.DATE)">Continue</button>
      </div>
    </div>
  </div>

  <!-- Step 6: Set Target Date -->
  <div id="step-6" class="step">
    <div class="step__status" [ngClass]="{
        'status--waiting': !targetDate && !editMode,
        'status--done': visited(goalStep.DATE)
      }">
      <div *ngIf="!targetDate && !editMode; else elseBlock">6</div>
      <ng-template #elseBlock>
        <i nz-icon nzType="check" nzTheme="outline"></i>
      </ng-template>
    </div>

    <div style="display: inline-block">
      <div (click)="visit(goalStep.DATE)" class="step__title" [ngClass]="{ 'title--current': currentStep >= goalStep.DATE }">
        <div *ngIf="goalDuration; else goalOverdue">Goal Duration</div>
        <ng-template #goalOverdue>
          <div>Goal Duration: <span class="goal-overdue">Overdue</span></div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="h-radio-group no-border">
    <div class="sl__h-block target-date" [@openClose]="visited(goalStep.DATE) || editMode ? 'open' : 'closed'">
      <div *ngIf="selectedTrackCard">
        <nz-slider [nzMarks]="durations" [ngModel]="editMode ? existingGoalDuration : 75" [(ngModel)]="goalDuration" [nzStep]="null" (ngModelChange)="updateGoalDuration(); visit(goalStep.EMPLOYMENT)" [nzTooltipVisible]="'never'"> </nz-slider>
      </div>
    </div>
  </div>

  <!-- Step 7: Associate Employment -->
  <div *ngIf="selectedEmploymentExperiencesCurrent.length || selectedEmploymentExperiencesPast.length">
    <div id="step-7" class="step">
      <div class="step__status" [ngClass]="{
          'status--waiting': !visited(goalStep.DATE),
          'status--done': visited(goalStep.DONE)
        }">
        <div *ngIf="!editMode && !visited(goalStep.DONE); else elseBlock">7</div>
        <ng-template #elseBlock>
          <i nz-icon nzType="check" nzTheme="outline"></i>
        </ng-template>
      </div>

      <div style="display: inline-block">
        <div (click)="visit(goalStep.EMPLOYMENT)" class="step__title" [ngClass]="{ 'title--current': currentStep >= goalStep.EMPLOYMENT }">
          <div>Associate Employment (optional)</div>
        </div>
      </div>
    </div>

    <div class="h-radio-group no-border">
      <div class="sl__h-block target-date" [@openClose]="currentStep >= goalStep.DATE || editMode ? 'open' : 'closed'">
        <nz-collapse>
          <nz-collapse-panel *ngIf="selectedEmploymentExperiencesCurrent.length" nzHeader="Current Employment" [nzActive]="selectedEmploymentExperiencesCurrent.length > 0">
            <span *ngFor="let se of selectedEmploymentExperiencesCurrent">
              <nz-card id="employment-card-current" class="employment-experience-card" *ngIf="!se.experience.dateEnd">
                <img class="employment-experience-image" [src]="se.experience.company.imageUrl" alt="Company Logo" />
                <p>
                  <b>{{ se.experience.jobTitle }}</b>
                </p>
                <p>{{ se.experience.company.name }}</p>
                <div nz-checkbox class="employment-selector" [(ngModel)]="se.selected" (ngModelChange)="visit(goalStep.DONE)"></div>
              </nz-card>
            </span>
          </nz-collapse-panel>
          <nz-collapse-panel *ngIf="selectedEmploymentExperiencesPast.length" nzHeader="Past Employment" [nzActive]="selectedEmploymentExperiencesPast.length > 0">
            <span *ngFor="let se of selectedEmploymentExperiencesPast">
              <nz-card id="employment-card-past" class="employment-experience-card" *ngIf="se.experience.dateEnd">
                <img class="employment-experience-image" [src]="se.experience.company.imageUrl" alt="Company Logo" />
                <p>
                  <b>{{ se.experience.jobTitle }}</b>
                </p>
                <p>{{ se.experience.company.name }}</p>
                <div nz-checkbox class="employment-selector" [(ngModel)]="se.selected" (ngModelChange)="visit(goalStep.DONE)"></div>
              </nz-card>
            </span>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>
  </div>

  <!-- Submission -->
  <div class="sl__h-block">
    <div class="btn--dual">
      <button id="goalButton" nz-button nzType="primary" *ngIf="visited(goalStep.DATE) && !editMode" (click)="submit()">Finish</button>
      <button id="goalButton" nz-button nzType="primary" *ngIf="editMode" (click)="submit()">Update Goal</button>
    </div>
  </div>
</div>