/* eslint-disable @typescript-eslint/quotes */
import { ISubscription } from './../../../../models/subscription.model';
import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AppDetailsService } from 'src/app/services/app-details.service';
import { ESubscriptionStatus } from 'src/app/models/user-profile.model';

@Component({
  selector: 'sl-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.less'],
})
export class BillingDetailsComponent implements OnInit {
  userSubscription?: ISubscription;
  isSubscribed = false;
  isLoading = false;
  showCancelModal = false;
  isProduction = false;
  userSubscriptionDate!: Date;
  linkToStripeMonthly = "https://buy.stripe.com/test_4gwdSja1Xfeh8cE3ce"; // non-production link
  linkToStripeYearly = "https://buy.stripe.com/test_8wM7tV5LH9TX2Sk3cf"; // non-production link

  constructor(private paymentService: PaymentService, private appDetailsService: AppDetailsService, private modal: NzModalService, private notification: NzNotificationService) { }

  ngOnInit(): void {
    this.getSubscriptionStatus();
    this.getAppDetails();
  }

  getAppDetails() {
    this.appDetailsService.getAppDetailsData().subscribe({
      next: (res) => {
        if (res.version.includes('P:')) {
          this.isProduction = true;
          this.linkToStripeMonthly = "https://buy.stripe.com/9AQ6qX7JUdsA27628a";
          this.linkToStripeYearly = "https://buy.stripe.com/5kAcPlfcm2NWbHG6or";
        }
      }
    });
  }

  getSubscribedUser() {
    this.paymentService.getUserSubscription().subscribe((userSubscription) => {
      if (userSubscription !== null) {
        this.userSubscription = userSubscription;
      }
    });
  }

  getSubscriptionStatus() {
    this.isLoading = true;
    this.paymentService.getSubscriptionStatus().subscribe((userSubscriptionStatus) => {
      if (userSubscriptionStatus === ESubscriptionStatus.Subscribed || userSubscriptionStatus === ESubscriptionStatus.Cancelled) {
        this.isSubscribed = true;
        this.getSubscribedUser();
      }
      else {
        this.isSubscribed = false;
      }
      this.isLoading = false;
    });
  }
}
