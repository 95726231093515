<div class="actions-container profile-v2-scheme">
  <div class="filters">
    <input class="text-filter" placeholder="Search" nz-input [(ngModel)]="query" (input)="applyFilters()" />
    <div class="dropdown-container" id="filter-dropdown">
      <a nz-dropdown [nzDropdownMenu]="menu" id="filter-dropdown-icon">
        {{ selectedTitleString }}
        <i nz-icon nzType="down-circle" nzTheme="twotone"></i>
      </a>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul id="filterItems" nz-menu nzSelectable *ngFor="let checkedItem of statusFilterItems; let i = index">
          <li nz-menu-item id="filterItem-{{ checkedItem.value }}">
            <label nz-checkbox [(ngModel)]="checkedItem.checked" (ngModelChange)="onChecked()" title="{{ checkedItem.name }}" aria-label="Status Filter Checked" id="{{ checkedItem.name }}" class="{{ checkedItem.name }}">
              <span style="font-weight: normal">{{ checkedItem.name }}</span>
            </label>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
  <button id="add-activity-button" [disabled]="!hasActiveGoals" (click)="showModal()" nz-button nzType="primary" [ngClass]="{ 'faded-btn': !hasActiveGoals }">
    <span>Add Activity</span>
  </button>
</div>

<div>
  <nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" [nzFooter]="modalFooter" [nzContent]="modalContent" (nzOnCancel)="handleCancel()">
    <ng-template #modalContent>
      <sl-manual-user-activity *ngIf="isVisible" (closeModalEventListener)="changeActivityModalState()" (updateActivityType)="changeActivityType($event)" (updateTable)="applyFilters()" [activities]="filteredRowItems" [activityKind]="ActivityKind.UserActivity">
      </sl-manual-user-activity>
    </ng-template>
    <ng-template #modalFooter></ng-template>
    <ng-template #modalTitle>
      <span *ngIf="isAPlaylist">Add Playlist</span>
      <span *ngIf="!isAPlaylist">Add Activity</span>
    </ng-template>
  </nz-modal>
</div>

<div infinite-scroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollThrottle]="throttle" (scrolled)="onScrollDown()">
  <nz-table id="activitiesTable" #editRowTable [nzData]="getScrolledActivityList()" [nzShowPagination]="false" [nzFrontPagination]="false" (updateTable)="applyFilters()" [nzLoadingDelay]="100" [nzScroll]="{ x: '1250px' }">
    <thead>
      <tr>
        <th class="th-1" nzWidth="60px"></th>
        <th class="th-3">TITLE</th>
        <th class="th-4" nzWidth="115px">FORMAT</th>
        <th class="th-5" nzWidth="155px">STARTED</th>
        <th class="th-6" nzWidth="250px">GOAL</th>
        <th class="th-7" nzWidth="230px" nzRight>ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      <!-- Problem I see here is that we've disassociated the table data we define with nzData and the actual data we use for each row. -->
      <!-- I think the only way to handle this is with an aggregate type to enclose either a user Activity OR a playlist -->
      <ng-container *ngFor="let activityRow of editRowTable.data; index as i">
        <!-- If the activityRow is a userActivity -->
        <tr *ngIf="activityRow.activityRowType === ActivityKind.UserActivity" class="single-activities" [ngClass]="{
            scheduled: getUserActivityStatus(activityRow.userActivity) === statusFilterItems[0].value,
            'in-progress': getUserActivityStatus(activityRow.userActivity) === statusFilterItems[1].value,
            completed: getUserActivityStatus(activityRow.userActivity) === statusFilterItems[2].value,
            archived: getUserActivityStatus(activityRow.userActivity) === statusFilterItems[3].value
          }">
          <td class="td-1">
            <span>
              <sl-provider-icon [isACoreSkill]="doesUserActivityContainACoreSkill(activityRow.userActivity)"
                [className]="activityRow.userActivity.provider === null || activityRow.userActivity.provider === 'unverified' || activityRow.userActivity.provider === undefined ? 'fa fa-user-shield' : activityRow.userActivity.provider.icon"
                [providerIconTooltip]="getProviderTooltip(activityRow.userActivity.providerName)" [tooltipVisible]="true" [coreSkillIconTooltip]="'This activity contains core skills for your goal'"></sl-provider-icon>
            </span>
          </td>
          <td class="td-3" nz-typography nzEllipsis>
            <span nz-tooltip [nzTooltipTitle]="getTitle(activityRow.userActivity)">
              <a href="{{ activityRow.userActivity.link }}" target="_blank">{{ getTitle(activityRow.userActivity) }}</a>
            </span>
          </td>
          <td class="td-4">
            {{ activityRow.userActivity.typeName !== null ? activityRow.userActivity.typeName : '-' }}
          </td>
          <td class="td-5" (click)="startEdit(activityRow.userActivity.id)">
            <span class="editable-cell" [hidden]="editId === activityRow.userActivity.id">
              {{ !activityRow.userActivity.dateStart ? '-' : (activityRow.userActivity.dateStart | date : this.dateFormat) }}
            </span>
            <i class="fa fa-pencil"></i>
            <nz-date-picker [hidden]="editId !== activityRow.userActivity.id" [(ngModel)]="activityRow.userActivity.dateStart" (ngModelChange)="onChangeActivity($event, activityRow.userActivity)"></nz-date-picker>
          </td>
          <td class="td-6">{{ getGoalSignature(activityRow.userActivity.goalId) }}</td>
          <td class="td-7" nzRight>
            <sl-profile-activity-actions [userActivity]="activityRow.userActivity" (refreshActivitiesTable)="applyFilters()"> </sl-profile-activity-actions>
          </td>
        </tr>

        <!-- If the activityRow is an action -->
        <tr *ngIf="activityRow.activityRowType === ActivityKind.UserAction" class="single-actions" [ngClass]="{
            scheduled: getUserActionStatus(activityRow.userAction) === statusFilterItems[0].value,
            'in-progress': getUserActionStatus(activityRow.userAction) === statusFilterItems[1].value,
            completed: getUserActionStatus(activityRow.userAction) === statusFilterItems[2].value,
            archived: getUserActionStatus(activityRow.userAction) === statusFilterItems[3].value
          }">
          <td class="td-1">
            <i class="fa fa-user-ninja"></i>
          </td>
          <td class="td-3" nz-typography nzEllipsis>
            <span nz-tooltip [nzTooltipTitle]="activityRow.userAction.title">
              <a>{{ getTitleForAction(activityRow.userAction) }}</a>
            </span>
          </td>
          <td class="td-4">Action</td>
          <td class="td-5" (click)="startEdit(activityRow.userAction.id)">
            <span class="editable-cell" [hidden]="editId === activityRow.userAction.id">
              {{ !activityRow.userAction.dateStarted ? '-' : (activityRow.userAction.dateStarted | date : this.dateFormat) }}
            </span>
            <i class="fa fa-pencil"></i>
            <nz-date-picker [hidden]="editId !== activityRow.userAction.id" [(ngModel)]="activityRow.userAction.dateStarted" (ngModelChange)="onChangeAction($event, activityRow.userAction)"></nz-date-picker>
          </td>
          <td class="td-6">{{ getGoalSignature(activityRow.userAction.goalId) }}</td>
          <td class="td-7" nzRight>
            <sl-profile-activity-actions [userAction]="activityRow.userAction" (refreshUserAction)="deleteUserAction(activityRow.userAction.id)"> </sl-profile-activity-actions>
          </td>
        </tr>

        <!-- If the activityRow is a playlist -->
        <tr *ngIf="activityRow.activityRowType === ActivityKind.UserPlaylist" class="playlist-border playlist" (click)="expandPlaylist(activityRow)">
          <td class="td-1" [nzShowExpand]="true" [nzExpand]="activityRow.userPlaylist.expanded"></td>
          <td class="td-3" nz-typography nzEllipsis>
            <div class="playlist-properties">
              <span class="playlist-title" nz-tooltip [nzTooltipTitle]="getTitleForPlaylist(activityRow.userPlaylist)">
                {{ activityRow.userPlaylist.playlistTitle }}
              </span>
              <div class="activity-status-indicators" (click)="togglePlaylistProgressIndicator(activityRow, $event)">
                <div class="activity-status-badges" *ngIf="activityRow.userPlaylist.completedActivitiesBadgeView">
                  <span class="active-activity">
                    <nz-badge id="active-activity-badge" [nzStyle]="{ backgroundColor: '#f5a623', width: '2.7rem' }" [nzCount]="getNumberOfActiveActivities(activityRow.playlistActivities)" nzShowZero nz-tooltip nzTooltipTitle="Active"></nz-badge>
                  </span>
                  <span class="completed-activity">
                    <nz-badge id="completed-activity-badge" [nzStyle]="{ backgroundColor: '#24b56c', width: '2.7rem' }" [nzCount]="getNumberOfCompletedActivities(activityRow.playlistActivities)" nzShowZero nz-tooltip nzTooltipTitle="Completed"></nz-badge>
                  </span>
                </div>
                <div class="activity-status-percentages" *ngIf="!activityRow.userPlaylist.completedActivitiesBadgeView">
                  <nz-progress id="percentage-indicator" nz-tooltip="{{getPercentageOfCompletedActivities(activityRow.playlistActivities)}}% of activities completed" [nzPercent]="getPercentageOfCompletedActivities(activityRow.playlistActivities)" nzSize="small"></nz-progress>
                </div>
              </div>
            </div>
          </td>
          <td class="td-4">Playlist</td>
          <td class="td-5" (click)="startEdit(activityRow.userPlaylist.id)">
            <span class="editable-cell" [hidden]="editId === activityRow.userPlaylist.id">
              {{ !activityRow.userPlaylist.dateStart ? '-' : (activityRow.userPlaylist.dateStart | date : this.dateFormat) }}
            </span>
          </td>
          <td class="td-6">{{ getGoalSignature(activityRow.userPlaylist.goalId) }}</td>
          <td class="td-7" nzRight>
            <sl-profile-activity-actions [userPlaylist]="activityRow.userPlaylist" (removeUserPlaylist)="deleteUserPlaylist($event)" (click)="$event.stopPropagation()" (updateUserPlaylistVersion)="updateUserPlaylistActivities($event)">
            </sl-profile-activity-actions>
          </td>
        </tr>
        <!-- If the activityRow is a Playlist, show its list of activities -->
        <ng-container *ngIf="activityRow.activityRowType === ActivityKind.UserPlaylist && activityRow.userPlaylist.expanded">
          <tr *ngFor="let playlistActivity of activityRow.playlistActivities" class="playlist-activities">
            <td class="td-1"></td>
            <td class="td-3 playlist-activity-name" [nzEllipsis]="true" [ngClass]="{
                scheduled: getUserActivityStatus(playlistActivity) === statusFilterItems[0].value,
                'in-progress': getUserActivityStatus(playlistActivity) === statusFilterItems[1].value,
                completed: getUserActivityStatus(playlistActivity) === statusFilterItems[2].value,
                archived: getUserActivityStatus(playlistActivity) === statusFilterItems[3].value
              }">
              <span>
                <sl-provider-icon [isACoreSkill]="doesUserActivityContainACoreSkill(playlistActivity)" [tooltipVisible]="true" [coreSkillIconTooltip]="'This activity contains core skills for your goal'" [providerIconTooltip]="getProviderTooltip(playlistActivity.providerName)"
                  [className]="playlistActivity.provider === null || playlistActivity.provider === 'unverified' || playlistActivity.provider === undefined ? 'fa fa-user-shield' : playlistActivity.provider.icon"></sl-provider-icon>
              </span>
              <span style="padding-left: 30px" nz-tooltip [nzTooltipTitle]="getTitle(playlistActivity)">
                <a href="{{ playlistActivity.link }}" target="_blank">{{ getTitle(playlistActivity) }}</a>
              </span>
            </td>
            <td class="td-4">
              {{ playlistActivity.typeName !== null ? playlistActivity.typeName : '-' }}
            </td>
            <td class="td-5" (click)="startEdit(playlistActivity.id)">
              <span class="editable-cell" [hidden]="editId === playlistActivity.id">
                {{ !playlistActivity.dateStart ? '-' : (playlistActivity.dateStart | date : this.dateFormat) }}
              </span>
              <i class="fa fa-pencil"></i>
              <nz-date-picker [hidden]="editId !== playlistActivity.id" [(ngModel)]="playlistActivity.dateStart" (ngModelChange)="onChangeActivity($event, playlistActivity)"></nz-date-picker>
            </td>
            <td class="td-6">{{ getGoalSignature(playlistActivity.goalId) }}</td>
            <td class="td-7" nzRight>
              <sl-profile-activity-actions class="playlist-activity-item" [userActivity]="playlistActivity" (refreshActivitiesTable)="applyFilters()"> </sl-profile-activity-actions>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </nz-table>

  <!-- TODO: dont worry about activity cards yet -->
  <!-- OLD LINE IS BELOW, AS PER TEST, "searchActivities: query;" breaks the component in mobile view-->
  <!-- <div id="activity-cards" *ngFor="let activity of getScrolledActivityList() | searchActivities: query; index as i"> -->
  <div id="activity-cards" *ngFor="let activityRow of getScrolledActivityList(); index as i">
    <div *ngIf="activityRow.activityRowType === ActivityKind.UserActivity">
      <nz-card class="activity-card" nzHoverable [ngClass]="{
          scheduled: activityRow.status === statusFilterItems[0].value,
          'in-progress': activityRow.status === statusFilterItems[1].value,
          completed: activityRow.status === statusFilterItems[2].value,
          archived: activityRow.status === statusFilterItems[3].value
        }">
        <nz-card-meta [nzTitle]="title" [nzDescription]="description" [nzAvatar]="image"></nz-card-meta>
        <div class="card-actions" nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="actionTemplate" nzPopoverTrigger="click">
          <i class="fas fa-ellipsis-v"></i>
        </div>
      </nz-card>
      <ng-template #image>
        <img alt="Activity Image" class="card-img" src="{{ getActivityImageUrl(activityRow.userActivity.imageThumbnailUrl) }}" loading="lazy" />
      </ng-template>
      <ng-template #title>
        <div class="playlist-activity-title" [nz-tooltip]="getTitle(activityRow.userActivity)">
          <a href="{{ activityRow.userActivity.link }}" target="_blank">{{ getTitle(activityRow.userActivity) }}</a>
        </div>
      </ng-template>
      <ng-template #description>
        <div class="activity-card-description">
          <div class="activity-card-goal">
            {{ getGoalSignature(activityRow.userActivity.goalId) }}
          </div>
          <div class="activity-card-skill">
            <span *ngFor="let skill of activityRow.userActivity.skills; let isLast = last; let isFirst = first">{{isFirst ? 'Skills: ' : ''}} <sl-core-skill-icon *ngIf="skill.coreSkill" [coreSkillIconSize]="coreSkillIconSize.Small" [tooltipVisible]="true"
                [tooltip]="'This is a core skill for your goal'"></sl-core-skill-icon> {{ skill.name }}{{ isLast ? '' : ', ' }}</span>
          </div>
          <div class="activity-card-badges">
            <i class="{{ getActivityTypeIcon(activityRow.userActivity.typeName) }} card-provider-icon" nz-tooltip [nzTooltipTitle]="activityRow.userActivity.typeName"></i>
            <sl-provider-icon [isACoreSkill]="false" [className]="activityRow.userActivity.provider === null || activityRow.userActivity.provider === 'unverified' || activityRow.userActivity.provider === undefined
                ? 'fa fa-user-shield' + ' card-provider-icon'
                : activityRow.userActivity.provider.icon + ' card-provider-icon'" [providerIconTooltip]="getProviderTooltip(activityRow.userActivity.providerName)"></sl-provider-icon>
          </div>
        </div>
      </ng-template>
      <ng-template #actionTemplate>
        <sl-profile-activity-actions [userActivity]="activityRow.userActivity" (refreshActivitiesTable)="applyFilters()"> </sl-profile-activity-actions>
      </ng-template>
    </div>
    <!-- PlaylistBlock -->
    <div *ngIf="activityRow.activityRowType === ActivityKind.UserPlaylist">
      <nz-card class="user-playlist-card" nzHoverable>
        <nz-card-meta [nzTitle]="title" [nzDescription]="description" [nzAvatar]="image"></nz-card-meta>
        <div class="card-actions" nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="actionTemplate" nzPopoverTrigger="click">
          <i class="fas fa-ellipsis-v"></i>
        </div>
      </nz-card>
      <ng-template #image>
        <img alt="Playlist Thumbnail" class="mobile-playlist-img" (click)="expandPlaylist(activityRow)" src="{{ getActivityImageUrl(activityRow.userPlaylist.trackImageUrl) }}" loading="lazy" />
      </ng-template>
      <ng-template #title>
        <a href="{{ activityRow.userPlaylist.playlistTitle }}" target="_blank">{{ activityRow.userPlaylist.playlistTitle }}</a>
      </ng-template>
      <ng-template #description>
        <div class="activity-card-goal">
          {{ getGoalSignature(activityRow.userPlaylist.goalId) }}
        </div>
        <div class="activity-card-skill">
          <span> Number of activities: {{ activityRow.playlistActivities.length }} </span>
        </div>
        <div class="activity-card-items">
          <i *ngIf="!activityRow.userPlaylist.expanded" class="fas fa-angle-right toggle-playlist" nz-tooltip nzTooltipTitle="Playlist" (click)="expandPlaylist(activityRow)"></i>
          <i *ngIf="activityRow.userPlaylist.expanded" class="fas fa-angle-down toggle-playlist" nz-tooltip nzTooltipTitle="Playlist" (click)="expandPlaylist(activityRow)"></i>
          <div class="playlist-expanded" [hidden]="!activityRow.userPlaylist.expanded">
            <div *ngFor="let playlistActivity of activityRow.playlistActivities" id="this-one-{{ playlistActivity.id }}">
              <div class="activity-container" [ngClass]="{
                  scheduled: getUserActivityStatus(playlistActivity) === statusFilterItems[0].value,
                  'in-progress': getUserActivityStatus(playlistActivity) === statusFilterItems[1].value,
                  completed: getUserActivityStatus(playlistActivity) === statusFilterItems[2].value,
                  archived: getUserActivityStatus(playlistActivity) === statusFilterItems[3].value
                }">
                <img alt="Activity Image" class="card-img" src="{{ getActivityImageUrl(playlistActivity.imageThumbnailUrl) }}" loading="lazy" />
                <div class="vertical-align">
                  <div class="playlist-activity-title" [nz-tooltip]="getTitle(playlistActivity)">
                    <a href="{{ playlistActivity.link }}" target="_blank">{{ getTitle(playlistActivity) }}</a>
                  </div>
                  <div class="playlist-skill-list">
                    <span id="playlist-skill" *ngFor="let skill of playlistActivity.skills; let isLast = last; let isFirst = first">{{isFirst ? 'Skills: ' : ''}} <sl-core-skill-icon *ngIf="skill.coreSkill" [coreSkillIconSize]="coreSkillIconSize.Small" [tooltipVisible]="true"
                        [tooltip]="'This is a core skill for your goal'"></sl-core-skill-icon> {{
                      skill.name }}{{ isLast ? '' : ', ' }}</span>
                  </div>
                  <div class="activity-icon-group">
                    <i class="{{ getActivityTypeIcon(playlistActivity.typeName) }} card-provider-icon" nz-tooltip [nzTooltipTitle]="playlistActivity.typeName"></i>
                    <sl-provider-icon [isACoreSkill]="false"
                      [className]="playlistActivity.provider === null || playlistActivity.provider === 'unverified' || playlistActivity.provider === undefined ? 'fa fa-user-shield' + ' card-provider-icon' : playlistActivity.provider.icon + ' card-provider-icon'"
                      [providerIconTooltip]="getProviderTooltip(playlistActivity.providerName)"></sl-provider-icon>
                  </div>
                </div>
                <div class="playlist-activity-action" nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="actionTemplate" nzPopoverTrigger="click">
                  <i class="fas fa-ellipsis-v"></i>
                </div>
              </div>
              <ng-template #actionTemplate>
                <sl-profile-activity-actions [userActivity]="playlistActivity" (refreshActivitiesTable)="applyFilters()"></sl-profile-activity-actions>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #actionTemplate>
        <sl-profile-activity-actions [userPlaylist]="activityRow.userPlaylist" (refreshUserPlaylist)="deleteUserPlaylist(activityRow.userPlaylist.id)"></sl-profile-activity-actions>
      </ng-template>
    </div>

    <!-- Action Block -->
    <div *ngIf="activityRow.activityRowType === ActivityKind.UserAction">
      <nz-card class="user-action-card" nzHoverable>
        <nz-card-meta [nzTitle]="title" [nzDescription]="description" [nzAvatar]="image"></nz-card-meta>
        <div class="card-actions" nz-popover nzPopoverPlacement="leftTop" [nzPopoverContent]="actionTemplate" nzPopoverTrigger="click">
          <i class="fas fa-ellipsis-v"></i>
        </div>
      </nz-card>
      <ng-template #image>
        <img alt="Action Image" class="card-img" src="{{ getActivityImageUrl(activityRow.userAction.imageUrl) }}" loading="lazy" />
      </ng-template>
      <ng-template #title>
        <div class="playlist-activity-title" [nz-tooltip]="getTitleForAction(activityRow.userAction)">
          <a [nz-tooltip]="getTitleForAction(activityRow.userAction)">{{ getTitleForAction(activityRow.userAction) }}</a>
        </div>
      </ng-template>
      <ng-template #description>
        <div class="activity-card-goal">
          {{ getGoalSignature(activityRow.userAction.goalId) }}
        </div>
        <div class="activity-card-badges">
          <i class="fas fa-list" nz-tooltip nzTooltipTitle="Action"></i>
        </div>
      </ng-template>
      <ng-template #actionTemplate>
        <sl-profile-activity-actions [userAction]="activityRow.userAction" (refreshUserAction)="deleteUserAction(activityRow.userAction.id)"></sl-profile-activity-actions>
      </ng-template>
    </div>
  </div>
</div>

<nz-spin *ngIf="isLoading()" class="table-spinner" nzSimple></nz-spin>