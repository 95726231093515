<div class="skill-card" *ngIf="!selectedTrack && track.name !== 'Custom' && skillsLoaded && track.approved">
  <nz-card class="track-overview" nzType="inner" nzHoverable [nzTitle]="track.name" [nzCover]="coverTemplate" [nzExtra]="actionEdit">
    <div nz-typography nzEllipsis [nzEllipsisRows]="6" class="track-overview-description">
      <div *ngIf="track.description; else noDescription">
        <p>{{ track.description }}</p>
      </div>
      <ng-template #noDescription>
        <sl-keyword-info class="wiki-description" *ngIf="!track.description" [keyword]="track.name + ' ' + 'computers'" displayType="paragraph"></sl-keyword-info>
      </ng-template>
    </div>
    <ng-template #coverTemplate>
      <div class="track-overview-image-container">
        <img class="track-overview-image" [src]="getTrackImage(track.imageUrl)" />
      </div>
    </ng-template>
    <ng-template #actionEdit>
      <sl-update-data (click)="$event.stopPropagation()" [dataInput]="track" (updateTrackData)="handleUpdatingTrackData()"></sl-update-data>
    </ng-template>
  </nz-card>
</div>

<div class="edit-track-container" *ngIf="selectedTrack === track.name && skillsLoaded">
  <nz-card class="track-editable">
    <h4>
      {{ track.name }} skills I want to learn includes:
    </h4>
    <div *ngFor="let category of categories" class="category-area" #categoryUI>
      <div class="step__subtitle">
        {{ category }}
        <sl-help-info class="help-info" title="Category Overview" [template]="categoryOverviewDescription" (click)="generateCategoryDescription(category)"></sl-help-info>

        <ng-template #categoryOverviewDescription>
          <div *ngIf="!isGeneratingCategoryDescription && !isGeneratingCategorySkillDescription" class="category-overview-description-popover-content">
            <p>{{categoryDescription}}</p>
            <div *ngFor="let skill of categorySkills.skills[category]">
              <span class="category-skill-description">&bull; <b>{{skill.name}}</b> - {{skill.description}}</span>
            </div>
          </div>
          <div *ngIf="isGeneratingCategoryDescription || isGeneratingCategorySkillDescription" class="category-overview-description-popover-spinner-container">
            <nz-spin class="category-overview-description-popover-spinner" nzTip="Generating description..."></nz-spin>
          </div>
        </ng-template>
      </div>
      <sl-goal-skills id="category-skill-selector" [goalSkills]="goalSkills" [skillOptions]="categorySkills.skills[category]" [trackName]="track.name" [allCategoryOptions]="allGoalSkills[category]" [skillCategoryName]="category" [isApprovedTrack]="track.approved"
        [selectableCoreSkills]="selectableCoreSkills" (removeCoreSkill)="clearUnavailableCoreSkillValue($event)" [track]="track" [category]="category">
      </sl-goal-skills>
    </div>
    <button class="add-category-button" *ngIf="addCategoryButtonVisible" nz-button nzSize="small" nzType="primary" (click)="addCategory()" nz-tooltip nzTooltipTitle="Add new category">
      <i class="category-button-icon fas fa-plus"></i>
    </button>
    <nz-input-group class="add-new-category" *ngIf="addingNewCategory" nzSearch [nzAddOnAfter]="suffixIconButton">
      <input class="adding-new-category-field" type="text" nz-input placeholder="Enter Category of Skills" (keydown.enter)="onBlur()" [(ngModel)]="newCategoryName" (ngModelChange)="onChange($event)" [nzAutocomplete]="auto" />
      <nz-spin [(nzSpinning)]="isLoading" class="add-category-spinner"></nz-spin>
    </nz-input-group>
    <nz-autocomplete [nzDataSource]="filteredCategorySuggestions" #auto></nz-autocomplete>
    <ng-template #suffixIconButton>
      <button class="adding-new-category-submit" nz-button nzType="primary" nzSearch (click)="onBlur()">
        <i nz-icon nzType="check"></i>
      </button>
    </ng-template>
  </nz-card>

  <!-- Need to check if selectable core skills is empty. If empty don't show -->
  <div class="core-skills-card-container">
    <nz-card class="core-skills-card">
      <h4 class="core-skills-title">Select the core skills for your goal:</h4>
      <sl-goal-core-skills *ngFor="let coreSkill of [].constructor(coreSkillsCount); let i = index" [selectableCoreSkills]="selectableCoreSkills" (selectionChange)="clearSelectedCoreSkillValue($event)" [preSelectedValue]="initializePreSelectedCoreSkills(i)"></sl-goal-core-skills>
      <button *ngIf="coreSkillsCount < 7" class="add-core-skill-btn" nz-button nzType="primary" nz-tooltip="Add a core skill" nzShape="circle" (click)="addCoreSkillSelector()"><span nz-icon nzType="plus" nzTheme="outline"></span></button>
    </nz-card>
  </div>
</div>