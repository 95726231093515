import { GoalService } from '../../services/goal.service';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';

// services
import { AccountDetailsService } from './../../services/account-details.service';
import { AuthService } from './../../services/auth.service';
import { Subscription, filter } from 'rxjs';
import { AppDetailsService } from 'src/app/services/app-details.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'sl-navbar-side',
  templateUrl: './navbar-side.component.html',
  styleUrls: ['./navbar-side.component.less'],
})
export class NavbarSideComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  isReverseArrow = false;
  width = 80;
  onChangeGoal!: Subscription;

  isAdmin = false;
  isLoggedIn = false;
  hasGoals = false;

  version = '';

  currentRouteSegment!: string | undefined;
  lastVisitedRouteSegment!: string | undefined;

  selectedItem: string = "";
  navbarItems: string[] = ['account', 'goal', 'profile', 'recommendations', 'guilds', 'admin'];

  public get isLandingPage(): boolean {
    return location.pathname === "/";
  }

  constructor(private accountDetailsService: AccountDetailsService, private authService: AuthService, private goalService: GoalService, private appDetailsService: AppDetailsService, private router: Router, private route: ActivatedRoute,
    private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {
    // console.log(`******* NavbarSideComponent check for authenticated = ${this.isLoggedIn}`);
    this.isLoggedIn = this.authService.isLoggedIn();
    this.refreshState();

    this.authService.isLoggedInChange().subscribe(
      (ret) => this.loggedInChangedEventFired(ret),
      (err) => console.error(`*** NavbarSideComponent: ngOnInit isLoggedInChange Error. Err = ${err}`)
    );

    this.onChangeGoal = this.goalService.getGoalsUpdatedEvent().subscribe(
      () => this.updateHasGoals(),
      (err) => console.error(`*** navbar-side: Failed to get goals updating event. Error: ${err}`)
    );

    this.appDetailsService.getAppDetailsData().subscribe((res) => { // This code is to hide the Guilds features on the production site. To be removed when Guilds is complete.
      this.version = res.version;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        const root = this.route.root;
        if (root.firstChild) {
          this.currentRouteSegment = root.firstChild?.snapshot.routeConfig?.path;
          if (this.currentRouteSegment) {
            if (this.lastVisitedRouteSegment) {
              this.removeNavbarItemSelectedClass(this.lastVisitedRouteSegment);
            }
            this.addNavbarItemSelectedClass(this.currentRouteSegment);
            this.lastVisitedRouteSegment = this.currentRouteSegment;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.onChangeGoal) {
      this.onChangeGoal.unsubscribe();
    }
  }

  addNavbarItemSelectedClass(navbarItemId: string) {
    const navbarItem = this.el.nativeElement.querySelector(`#${navbarItemId} > li`);
    if (navbarItem) {
      this.renderer.addClass(navbarItem, 'ant-menu-item-selected');
    }
  }

  removeNavbarItemSelectedClass(navbarItemId: string) {
    const navbarItem = this.el.nativeElement.querySelector(`#${navbarItemId} > li`);
    if (navbarItem) {
      this.renderer.removeClass(navbarItem, 'ant-menu-item-selected');
    }
  }

  public login() {
    // console.log(`*** NavBarSide: we are in the navbar-side.component.ts login function`);
    this.authService.login();
  }

  public logout() {
    // console.log(`*** NavBarSide: we are in the navbar-side.component.ts logout function`);
    this.authService.logout();
  }

  public isProduction(): boolean {
    if (this.version.includes('P:')) {
      return true;
    }
    return false;
  }

  private loggedInChangedEventFired(loggedIn: boolean) {
    // console.log(`*** NavBarSide: isLoggedIn changed value to: ${loggedIn}`);
    this.isLoggedIn = loggedIn;
    this.refreshState();
  }

  private refreshState() {
    if (this.updateisAdmin()) {
      return;
    }
    this.updateHasGoals();
  }

  private updateisAdmin(): boolean {
    if (!this.isLoggedIn) {
      this.isAdmin = false;
      return false;
    }

    this.accountDetailsService.getIsAdmin().subscribe({
      next: (ret) => {
        this.isAdmin = ret;
        return true;
      },
      error: (err) => console.error(`*** NavbarSideComponent: getIsAdmin Error. Err = ${err}`)
    });

    return false;
  }

  private updateHasGoals() {
    if (!this.isLoggedIn) {
      // console.log(`**** NavBarSide: UpdateHasGoals: Is not logged in.`);
      this.hasGoals = false;
      this.isAdmin = false;
      return;
    }

    if (!this.goalService.isInitialised()) {
      // console.log(`**** NavBarSide: GoalService is not initialised yet.`);
      this.hasGoals = false; // It's likely that we're still setting up, which for us means we have no goals at the moment.
      return;
    }

    // we're logged in and goalService is initialiased
    this.hasGoals = this.goalService.getGoals().length > 0;
    // console.log(`*** NavbarSideComponent: updateHasGoals updated to: ${this.hasGoals}`);
  }
}
