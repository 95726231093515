import { Component, EventEmitter, Input, Output } from '@angular/core';

// models
import { ISuggestedAction } from 'src/app/models/suggested-action.model';

// services
import { UserActionService } from 'src/app/services/user-action.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'sl-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.less'],
})
export class ActionCardComponent {
  @Input() suggestedAction!: ISuggestedAction;
  @Input() goalId!: number;

  @Output() actionAdded = new EventEmitter<number>();

  isHovered = false;
  isDrawerVisible = false;
  isAdding = false;

  constructor(private userActionService: UserActionService, private message: NzMessageService) {}

  public get addedToBacklog(): boolean {
    return this.suggestedAction.added;
  }

  public set addedToBacklog(added: boolean) {
    this.suggestedAction.added = added;
  }

  addToBacklog() {
    this.isAdding = true;
    this.userActionService.addUserActionToGoal(this.suggestedAction.id, this.goalId).subscribe(
      (action) => {
        this.addedToBacklog = true;
        this.isAdding = false;
        this.handleActionAdded(action.suggestedActionId as number);
        this.message.success('Nice, you\'ve added the action to your profile.');
      },
      () => {
        // error: HttpErrorResponse
        this.addedToBacklog = false;
        this.isAdding = false;
        // console.error(`addTobacklog() returned with error: ${error.message}`);
        this.message.error('Oh dear, this is embarassing, something went wrong 😳. Try again? ');
      }
    );
  }

  getActionImage() {
    if (!this.suggestedAction.imageUrl) {
      const placeholderImage = '/assets/img/placeholder_image.png';
      return placeholderImage;
    }

    return this.suggestedAction.imageUrl;
  }

  getTrackName() {
    if (!this.suggestedAction.relatedTrackName) {
      return 'No track given';
    }

    return this.suggestedAction.relatedTrackName;
  }

  getAuthor() {
    let author = '';
    if (this.suggestedAction) {
      author = this.suggestedAction.createdByFirstName + ' ' + this.suggestedAction.createdByLastName;
      return author;
    }
    return author;
  }

  imageEnter() {
    this.isHovered = true;
  }

  imageLeave() {
    this.isHovered = false;
  }

  showDrawer(show: boolean) {
    this.isDrawerVisible = show;
  }

  private handleActionAdded(actionId: number) {
    this.actionAdded.emit(actionId);
  }
}
