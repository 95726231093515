import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatGoalDuration'
})
export class FormatGoalDurationPipe implements PipeTransform {

    transform(goalTargetDate: Date): string {
      const durationInDays = Math.round(this.daysBetween(new Date(goalTargetDate)));

      if (durationInDays < 0) {
        return `<span class="red">${Math.abs(durationInDays)} days overdue</span>`;
      } else if (durationInDays >= 0 && durationInDays < 14) {
        return `${durationInDays} days left`;
      } else if (durationInDays >= 14 && durationInDays < 30) {
        return `${Math.round(durationInDays / 7)} weeks left`;
      } else {
        if (Math.round(durationInDays / 30) === 1) {
          return `1 month left`;
        } else {
          return `${Math.round(durationInDays / 30)} months left`;
        }
      }
    }

    daysBetween(futureDate: Date, todayDate = new Date()) {
      const days = (this.elapsedTimeSince1970(futureDate) - this.elapsedTimeSince1970(todayDate)) / (1000 * 60 * 60 * 24);
      return days;
    }

    elapsedTimeSince1970(date: Date): number {
      return date.getTime();
    }
}
