import { NzModalService } from 'ng-zorro-antd/modal';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input, AfterContentInit, AfterViewInit, AfterViewChecked, AfterContentChecked, OnChanges, SimpleChanges, DoCheck } from '@angular/core';

// modules
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'; // used in html
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm'; // used in html

// services
import { GoalService } from '../../../../services/goal.service';

// models
import { IGoal } from '../../../../models/user-goal.model';

// confetti!!
import * as confetti from 'canvas-confetti';
import { Router, ActivatedRoute } from '@angular/router';
import { UserActivitiesService } from 'src/app/services/user-activities.service';

@Component({
  selector: 'sl-goal-actions',
  templateUrl: './goal-actions.component.html',
  styleUrls: ['./goal-actions.component.less'],
})
export class GoalActionsComponent implements OnInit {
  @Input() goal!: IGoal;

  isLoading = false;
  editGoalModalIsOpen = false;
  assignActivityModalIsOpen = false;
  goalId!: number;
  goalEdited: IGoal | undefined = undefined;

  constructor(private goalService: GoalService, private userActivitiesService: UserActivitiesService, private message: NzMessageService, private modal: NzModalService, public router: Router, public route: ActivatedRoute) { }

  get isCompleted(): boolean {
    return null !== this.goal.dateCompleted;
  }

  ngOnInit() {
    this.getEditedGoal();
  }

  confettiRandomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  confettiFire() {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = {
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      zIndex: 0,
      angle: 90,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const interval: any = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);

      const myCanvas = document.createElement('canvas');
      document.appendChild(myCanvas);

      confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
      })({
        ...defaults,
        particleCount,
        origin: {
          x: this.confettiRandomInRange(0.1, 0.3),
          y: Math.random() - 0.2,
        },
      });

      confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
      })({
        ...defaults,
        particleCount,
        origin: {
          x: this.confettiRandomInRange(0.7, 0.9),
          y: Math.random() - 0.2,
        },
      });
    }, 250);
  }

  cancel() {
    this.message.info('Cancelled');
  }

  showEditGoalModal(goalId: number | undefined) {
    this.editGoalModalIsOpen = true;
    this.router.navigate(['/profile/overview']);
    if (!goalId) {
      throw new Error('We cannot set the goalId to an undefined goal');
    }

    this.goalId = goalId;
  }

  getEditedGoal(): IGoal | undefined {
    if (!this.goalEdited) {
      this.goalEdited = this.goalService.getGoal(this.goal.id);
    }
    return this.goalEdited;
  }

  handleCancel() {
    this.modal.confirm({
      nzTitle: '<i>Abandon Changes?</i>',
      nzContent: '<b>Are you sure you want to abandon any changes you\'ve made to this goal?</b>',
      nzOnOk: () => this.closeEditGoalModal(),
      nzOkText: 'Confirm',
    });
  }

  closeEditGoalModal = (): void => {
    this.editGoalModalIsOpen = false;
    this.router.navigate(['/profile/overview']);
  };

  toggleGoalCompleted() {
    this.isLoading = true;
    this.goalService.toggleGoalCompleted(this.goal).subscribe({
      next: (result) => {
        if (result.dateCompleted) {
          this.confettiFire();
          this.message.success(`Congratulations on completing your goal!`, { nzDuration: 5000 });
        } else {
          this.message.success(`Glad to see you're giving it another shot!`, { nzDuration: 5000 });
        }
        this.goal = result;
        this.isLoading = false;
      },
      error: (error) => {
        console.error(`Could not mark goal as completed: ${JSON.stringify(error)}`);
        this.message.error(`Goal's complete status could not be changed`);
        this.isLoading = false;
      }
    });
  }

  showAssignActivityModal(goalId: number | undefined) {
    this.assignActivityModalIsOpen = true;
    if (!goalId) {
      throw new Error('We cannot set the goalId to an undefined goal');
    }
    this.goalId = goalId;
  }

  closeAssignActivitiesModal() {
    this.assignActivityModalIsOpen = false;
  }

  public deleteGoal() {
    const id = this.goal.id;
    if (this.goal.deleted === true) {
      console.log('Selected goal is already deleted');
      this.message.warning('That\'s strange, that goal is already marked as deleted. Try refreshing the page');
      return;
    }

    this.isLoading = true;
    this.userActivitiesService.archiveUserActivitiesAssociatedWithGoal(id!).subscribe({
      next: () => {
        this.goalService.deleteGoal(this.goal).subscribe({
          next: (result) => {
            this.message.info('Goal successfully deleted');
            this.goal = result;
            this.isLoading = false;
          },
          error: (error) => {
            console.error(`Could not delete goal: ${JSON.stringify(error)}`);
            this.message.error('We could not delete the goal. Try again later.');
            this.isLoading = false;
          }
        });

      },
      error: (error) => {
        console.error(`Could not archive the incomplete activities associated with this goal: ${JSON.stringify(error)}`);
        this.message.error('We could not delete the goal. Try again later.');
        this.isLoading = false;
      }
    });
  }
}
