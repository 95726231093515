<nz-card nzTitle="Send an Email">
  <div class="email-message-template">
    <div class="tabs">
      <sl-email-message-tabs></sl-email-message-tabs>
    </div>
  </div>  
  <div class="email-metrics">
    <sl-email-metrics></sl-email-metrics>
  </div>
</nz-card>
