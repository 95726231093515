import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

// Models
import { ISkill } from 'src/app/models/skill.model';

// Components
import { NzSelectComponent } from 'ng-zorro-antd/select';


@Component({
  selector: 'sl-goal-core-skills',
  templateUrl: './goal-core-skills.component.html',
  styleUrls: ['./goal-core-skills.component.less'],
})
export class GoalCoreSkillsComponent implements OnInit {
  @ViewChild(NzSelectComponent, { static: true }) selectNode!: NzSelectComponent;
  @Input() selectableCoreSkills: { skill: ISkill; hidden: boolean }[] = [];
  @Input() preSelectedValue: string | null = null;
  @Output() selectionChange = new EventEmitter<NzSelectComponent>();

  selectedValue: string | null = null;

  ngOnInit(): void {
    if (this.preSelectedValue !== null) {
      this.selectedValue = this.preSelectedValue;
    }
  }

  clearUnavailableCoreSkillValue(valueToRemove: string[]): void {

    if (this.selectedValue !== null) {
      if (valueToRemove.includes(this.selectedValue)) {
        this.selectNode.writeValue(null);
      }
    }
  }

  clearSelectedCoreSkillValue(latestComponent: NzSelectComponent): void {

    if (this.selectNode === latestComponent) {
      return;
    }

    if (this.selectedValue !== null) {
      if (latestComponent.listOfValue.includes(this.selectedValue)) {
        this.selectNode.writeValue(null);
      }
    }
  }

  onModelChange() {
    this.selectionChange.emit(this.selectNode);
  }
}
