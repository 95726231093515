/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, EventEmitter, Output, ViewChildren, QueryList, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

// Animations library
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { CareerDetailsService } from 'src/app/services/career-details.service';
import { EmploymentExperienceService } from 'src/app/services/employment-experience.service';
import { InviteService } from 'src/app/services/invite.service';
import { GoalService } from '../../services/goal.service';
import { SignatureService } from 'src/app/services/signature.service';
import { TrackService } from 'src/app/services/track.service';

// Models
import { IGoal } from './../../models/user-goal.model';
import { IEmployment, IEmploymentLoading } from 'src/app/models/employment.model';
import { IInvitation } from 'src/app/models/invitation.model';
import { ILevelSignature } from '../../models/level-signature.model';
import { ISkill } from '../../models/skill.model';
import { ITrack } from '../../models/track.model';

// Components
import { GoalTrackComponent } from './goal-track/goal-track.component';


export enum GoalStep {
  INDUSTRY,
  PROFESSION,
  SPECIALISATION,
  LEVEL,
  SKILLS,
  DATE,
  EMPLOYMENT,
  DONE,
}
@Component({
  selector: 'sl-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.less'],

  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0px',
          opacity: 0,
        })
      ),

      transition('closed => open', [
        animate(
          '0.3s ease',
          keyframes([
            style({
              height: '*',
            }),
          ])
        ),
        animate(
          '0.3s ease',
          keyframes([
            style({
              opacity: 1,
            }),
          ])
        ),
      ]),
      transition('open => closed', [
        animate(
          '0.3s ease',
          keyframes([
            style({
              opacity: 0,
            }),
          ])
        ),
        animate(
          '0.3s ease',
          keyframes([
            style({
              height: '0px',
            }),
          ])
        ),
      ]),
    ]),
  ],
})
export class GoalComponent implements OnInit {
  @Input() goal?: IGoal;
  @Input() defaultIndustry?: string;
  @Input() defaultProfession?: string;
  @Input() defaultSpecialisation?: string;
  @Input() level?: number;
  @ViewChildren(GoalTrackComponent) trackElements: QueryList<GoalTrackComponent> | undefined;
  @Output() closeAddGoalModalEmitter = new EventEmitter();
  @Output() closeEditGoalModalEmitter = new EventEmitter();
  @Output() sendGoalIdEmitter = new EventEmitter<number>();

  tracksLoaded = false;
  tracks: ITrack[] = [];
  selectedTrackCard: string | undefined;
  selectedTrackCardImage: string | undefined;
  selectedTrackCardDescription: string | undefined;
  customTrackName: string | undefined;
  invalidTrackName = false;
  noGoals = false;
  creatingGoalAndPlaylistFromPlaylistCode = false;
  salaryVisibility = true;
  invitationCode: string | undefined;

  objectKeys = Object.keys;
  objectValues = Object.values;
  visitedSteps: GoalStep[] = [];

  public goalStep = GoalStep;

  // 1. Industry - if the default industry isn't known, then start there, otherwise, skip to profession
  currentStep = !this.defaultIndustry ? GoalStep.INDUSTRY : GoalStep.PROFESSION;
  industries: string[] | undefined;
  selectedIndustry: string | undefined;

  // 2. Professions
  professions: string[] | undefined;
  selectedProfession: string | undefined;

  // 3. Specialisations
  specialisations: string[] | undefined;
  selectedSpecialisation: string | undefined;

  // 4. Experience Levels
  experienceLevels: ILevelSignature[] = [];
  selectedExperience: ILevelSignature | undefined;
  selectedPositionLevel: string | undefined; // PositionLevels are Junior, Developer, Senior, Architect
  selectedLevel: number | undefined;

  // 5. Skills
  skills: { [category: string]: ISkill[] } = {};
  selectedSkills: ISkill[] = [];
  editMode: boolean | undefined;

  // 6. Goal Duration
  targetDate?: Date;
  goalDuration: Date | number | undefined = 75;
  existingGoalDuration: number | undefined;
  durations = {
    25: '3<br/>months',
    50: '6<br/>months',
    75: '9<br/>months',
    100: '1 year',
  };

  // 7. Associated Experience
  selectedEmploymentExperiencesCurrent: { experience: IEmployment; selected: boolean }[] = [];
  selectedEmploymentExperiencesPast: { experience: IEmployment; selected: boolean }[] = [];

  constructor(
    private goalService: GoalService,
    private careerDetailsService: CareerDetailsService,
    private signatureService: SignatureService,
    private trackService: TrackService,
    private employmentExperienceService: EmploymentExperienceService,
    private inviteService: InviteService,
    private message: NzMessageService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.initialiseGoals();
    this.getIndustries();
    this.getEmploymentExperiences();
    this.verifyNumGoals();
  }

  handleUpdatingTrackData() {
    this.getTracks();
  }

  private initialiseGoals() {
    this.editMode = this.goal ? true : false;
    if (!this.editMode) {
      //If the playlist or guild is in the parameter, it's coming via invite code
      this.route.queryParams.subscribe(params => {
        if (params.playlistId && params.invitation) {
          this.createGoalFromPlaylistInviteCode(params.playlistId, params.invitation);
        } else {
          this.initialiseNewGoal();
        }
      });
    } else {
      this.initialiseEditMode();
    }
  }

  private initialiseEditMode() {
    this.initialiseSelectedGoalSignature();

    this.displayGoalDuration();
    const currentlySelectedIndustry = this.getCurrentlySelectedIndustry();
    const currentlySelectedProfession = this.getCurrentlySelectedProfession();
    const currentlySelectedSpecialisation = this.getCurrentlySelectedSpecialisation();

    if (currentlySelectedIndustry && currentlySelectedProfession && currentlySelectedSpecialisation) {
      if (!this.goal) {
        throw new Error('Goal must be defined at this point.');
      }
      this.getCurrentlySelectedExperienceLevels(currentlySelectedIndustry, currentlySelectedProfession, currentlySelectedSpecialisation);
    }
  }

  private getCurrentlySelectedExperienceLevels(currentlySelectedIndustry: string, currentlySelectedProfession: string, currentlySelectedSpecialisation: string) {
    this.careerDetailsService.getExperienceLevels(currentlySelectedIndustry, currentlySelectedProfession, currentlySelectedSpecialisation).subscribe({
      next: (el: ILevelSignature[]) => {
        this.salaryVisibility = this.setSalaryVisibilityFlag(el);
        this.experienceLevels = el;
        this.selectedLevel = this.goal ? this.levelNumFromLevelString(this.goal.level) : this.level;
        this.selectedExperience = this.experienceLevels.find((i) => i.levelDetails.some((l) => l.levelNum === this.selectedLevel));
        this.visitedSteps.push(GoalStep.INDUSTRY);
        this.visitedSteps.push(GoalStep.PROFESSION);
        this.visitedSteps.push(GoalStep.SPECIALISATION);
        this.visitedSteps.push(GoalStep.LEVEL);
        this.getTracks();
      },
      error: (err: HttpErrorResponse) => console.error(err)
    });
  }

  private initialiseSelectedGoalSignature() {
    this.selectedIndustry = (this.goal && this.goal.industry) ? this.goal.industry : this.defaultIndustry;
    this.selectedProfession = (this.goal && this.goal.profession) ? this.goal.profession : this.defaultProfession;
    this.selectedSpecialisation = (this.goal && this.goal.specialisation) ? this.goal.specialisation : this.defaultSpecialisation;
  }

  private initialiseNewGoal() {
    this.goal = {
      industry: undefined,
      profession: undefined,
      // Right now it only supports a single call for a single specialisation
      specialisation: undefined,
      level: undefined,
      dateTarget: this.defaultTargetDate(),
      dateStarted: new Date(),
      dateAdded: new Date(),
      skills: [],
      deleted: false,
      status: '',
      selectedTrackName: '',
      selectedTrackId: 0,
    };
  }

  updateLevelAndExperience() {
    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }

    this.selectedLevel = this.levelNumFromLevelString(this.goal.level);
    this.selectedExperience = this.experienceLevels.find((i) => i.levelDetails.some((l) => l.levelNum === this.selectedLevel));
  }

  public getPositionLevel(experience: ILevelSignature): string | undefined {
    return experience?.positionLevel;
  }

  public selectLevel(level: number) {
    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }

    // console.log('*** SelectLevel ' + level);
    this.goal.level = this.levelFromLevelValue(level);
    this.selectedLevel = level;
    this.getTracks();
  }

  // Private methods -------------------------------------------------------------------------------------------------------------------

  // Go and get the industries and where possible, set the default selected.
  // If the user has already selected a industry, or their goal already has one set, then select it.
  // as a default if it's one of the industries returned, if not, use the default industry passed in if THAT is one of the industries returned.
  // othewise, just leave it.
  private getIndustries() {
    this.signatureService.getIndustries().subscribe({
      next: (ret: string[]) => {
        this.industries = ret;
        if (this.industries) {
          if (this.industries.length === 1) { // if there is only one industry, then select it
            this.selectIndustry(this.industries[0]);
          } else {
            // if the user has not yet selected an industry, then try and set it based on the goal
            if (!this.selectedIndustry) {
              if (this.goal?.industry || this.defaultIndustry) {
                const useThisDefaultIndustry = this.goal?.industry ? this.goal.industry : this.defaultIndustry;
                this.selectIndustry(useThisDefaultIndustry);
                this.visitedSteps.push(GoalStep.INDUSTRY);
                this.visit(GoalStep.PROFESSION);
                return;
              }
            }
          }
        } else {
          this.message.error(`The SeaLadder system seems to be playing up and has forgotten all of the industries. Try again a little later`);
          this.industries = [];
        }
      },
      error: (err: HttpErrorResponse) => {
        this.message.error('SeaLadder seems to be having recalling all of the industries it supports. It might be best to try again a little later.');
        this.industries = [];
        console.error(err);
      }
    });
  }

  // Go and get the professions and where possible, set the default selected.
  // If the user has already selected a profession, or their goal already has one set, then select it.
  // as a default if it's one of the professions returned, if not, use the default profession passed in
  // if THAT is one of the professions returned, othewise, just leave it.
  private getProfessions() {
    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }

    if (!this.selectedIndustry) {
      throw new Error('Professions can not be retrieved until the industry is selected');
    }

    this.signatureService.getProfessions(this.selectedIndustry).subscribe({
      next: (ret: string[]) => {
        this.professions = ret;
        if (this.professions) {
          if (this.professions.length === 1) {  // if there is only one profession, then select it
            this.selectProfession(this.professions[0]);
          } else {
            // if the user has not yet selected a profession, then try and set it based on the goal
            if (!this.selectedProfession) {
              if (this.goal?.profession || this.defaultProfession) {
                const useThisDefaultProfession = this.goal?.profession ? this.goal.profession : this.defaultProfession;
                if (useThisDefaultProfession) {
                  this.selectProfession(useThisDefaultProfession);
                  this.visitedSteps.push(GoalStep.PROFESSION);
                  this.visit(GoalStep.SPECIALISATION);
                  return;
                }
              }
            }
          }
        } else {
          this.message.error(`The SeaLadder system seems to be playing up and has forgotten all of the professions for the industry: ${this.selectedIndustry}. Try again a little later`);
          this.professions = [];
        }
      },
      error: (err: HttpErrorResponse) => {
        this.message.error('SeaLadder seems to be having recalling all of the professions it supports. It might be best to try again a little later.');
        this.professions = [];
        console.error(err);
      }
    });
  }

  private getSpecialisations() {
    const currentlySelectedIndustry = this.getCurrentlySelectedIndustry();
    const currentlySelectedProfession = this.getCurrentlySelectedProfession();

    if (!currentlySelectedIndustry || !currentlySelectedProfession) {
      throw new Error('Industry and Profession must be defined at this point.');
    }

    this.signatureService.getSpecialisations(currentlySelectedIndustry, currentlySelectedProfession).subscribe({
      next: (specialisations: string[]) => {
        this.specialisations = specialisations;
        if (!this.selectedSpecialisation) {
          if (this.goal?.specialisation || this.defaultSpecialisation) {
            const useThisDefaultSpecialisation = this.goal?.specialisation ? this.goal.specialisation : this.defaultSpecialisation;
            if (useThisDefaultSpecialisation) {
              this.selectSpecialisation(useThisDefaultSpecialisation);
              this.visitedSteps.push(GoalStep.SPECIALISATION);
              this.visit(GoalStep.LEVEL);
              return;
            }
          }
        }
      },
      error: (err: HttpErrorResponse) => console.error(err),
    });
  }

  private getExperienceLevels() {
    const currentlySelectedIndustry = this.getCurrentlySelectedIndustry();
    const currentlySelectedProfession = this.getCurrentlySelectedProfession();
    const currentlySelectedSpecialisation = this.getCurrentlySelectedSpecialisation();

    if (!currentlySelectedIndustry || !currentlySelectedProfession || !currentlySelectedSpecialisation) {
      throw new Error('Industry and Profession must be defined at this point.');
    }

    this.careerDetailsService.getExperienceLevels(currentlySelectedIndustry, currentlySelectedProfession, currentlySelectedSpecialisation).subscribe({
      next: (el: ILevelSignature[]) => {
        this.salaryVisibility = this.setSalaryVisibilityFlag(el);
        this.experienceLevels = el;
        this.selectedLevel = 1;
        const positionLevelFirst = this.experienceLevels.length > 0 ? this.experienceLevels[0].positionLevel : undefined;
        this.selectPositionLevel(positionLevelFirst);

        if (this.level) {
          this.selectedLevel = this.level;
          this.selectLevel(this.level);
          this.visitedSteps.push(GoalStep.LEVEL);
          this.visit(GoalStep.SKILLS);
        }
      },
      error: (err: HttpErrorResponse) => console.error(err),
    });
  }

  private setSalaryVisibilityFlag(experienceLevels: ILevelSignature[]): boolean {
    let isSalaryVisible = true; // Assume visibility is true initially

    const hasMissingSalary = experienceLevels.some(level =>
      level.levelDetails.some(detail => !detail.salary)
    );

    if (hasMissingSalary) {
      isSalaryVisible = false; // Set to false if any salary from the levels is missing
    }

    return isSalaryVisible; // Return the determined visibility flag
  }

  private getTracks() {
    this.updateLevelAndExperience();

    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }

    // console.log(`*** Getting the tracks for goalId: ${this.goal.id}`);
    this.trackService.getTracks(this.goal).subscribe({
      next: (tracks: ITrack[]) => {
        // console.log(`*** Got the tracks` + JSON.stringify(tracks));
        tracks.forEach((ts) => {
          ts.recommendedSkills.forEach((s) => {
            this.addSkill(s.skill.category, s.skill.name, s.id, s.coreSkill);
          });
        });

        if (!this.editMode) {
          // new goal
          this.tracks = tracks;
          this.tracksLoaded = true;
        } else {
          // edit mode
          if (!this.goal) {
            throw new Error('Goal must be defined at this point.');
          }

          // console.log(`*** getTracks Looking for trackName ${this.goal.selectedTrackName} in Tracks= ${JSON.stringify(tracks)}`);
          const selectedTrack = this.getTrackWithName(tracks, this.goal.selectedTrackName);

          if (selectedTrack) {
            this.tracks.push(selectedTrack);
            // console.log(`*** getTracks: SelectedTrack= ` + JSON.stringify(selectedTrack));
            this.selectedTrackCard = selectedTrack.name;
            this.tracksLoaded = true;
          } else {
            // if we can't find the selected track, it might be that it was rejected. This user however still needs it, so we'll have to go get it.
            this.trackService.getTrack(this.goal.selectedTrackId).subscribe({
              next: (track: ITrack) => {
                if (track) {
                  this.tracks.push(track);
                  this.tracksLoaded = true;
                  this.selectedTrackCard = track.name;
                } else {
                  if (!this.goal) {
                    throw new Error('Goal must be defined at this point.');
                  }
                  this.goal.selectedTrackName = '';
                  this.message.create('error', `Selected track ${this.goal.selectedTrackName} for this goal is unrecognised`);
                }
                this.tracksLoaded = true;
              },
              error: () => {
                this.message.create('error', `Error getting a track with ID ${this.goal?.selectedTrackId}.`);
              },
            });
          }
        }
      },
      error: () => this.message.create('error', `Error loading skill tracks.`),
    });
  }

  private getTrackWithName(tracks: ITrack[], name: string | undefined) {
    if (!name) {
      return undefined;
    }
    return tracks.find((track) => track.name === name);
  }

  private getEmploymentExperiences() {
    this.employmentExperienceService.getEmploymentExperience().subscribe({
      next: (ret: IEmploymentLoading[]) => {
        if (ret) {
          // console.log(`*** GoalComponent: getEmploymentExpereinces returned with ${JSON.stringify(ret)}`);
          if (!this.goal) {
            throw new Error('Goal must be defined at this point.');
          }

          ret.forEach((employment) => {
            if (!employment.company.imageUrl || employment.company.imageUrl === '') {
              employment.company.imageUrl = 'assets/img/default_employment_image.png';
            }

            const hasGoal = employment.associatedGoals.findIndex((g) => this.goal && g.id === this.goal.id) !== -1;

            if (!employment.dateEnd) {
              this.selectedEmploymentExperiencesCurrent.push({ experience: employment, selected: hasGoal });
            } else {
              this.selectedEmploymentExperiencesPast.push({ experience: employment, selected: hasGoal });
            }
          });
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error(`*** GoalComponent: getEmploymentExperiences ${JSON.stringify(error)}`);
      },
    });
  }


  private levelNumFromLevelString(levelString: string | undefined): number | undefined {
    return !levelString ? undefined : parseInt(levelString.split(' ')[1], 10);
  }

  private levelFromLevelValue(levelNumber: number): string {
    return 'Level ' + levelNumber;
  }

  public createGoalFromPlaylistInviteCode(playlistId: string, invitationCode: string) {
    if (invitationCode) {
      this.invitationCode = invitationCode;
    }

    if (!this.invitationCode || !playlistId) {
      return;
    }

    const playlistIndustry = this.route.snapshot.queryParamMap.get('industry');
    const playlistProfession = this.route.snapshot.queryParamMap.get('profession');
    const playlistSpecialisation = this.route.snapshot.queryParamMap.get('specialisation');
    const playlistLevel = this.route.snapshot.queryParamMap.get('level');

    if (playlistIndustry !== null) {
      this.defaultIndustry = playlistIndustry;
    }

    if (playlistProfession !== null) {
      this.defaultProfession = playlistProfession;
    }

    if (playlistSpecialisation !== null) {
      this.defaultSpecialisation = playlistSpecialisation;
    }

    if (playlistLevel !== null) {
      this.level = +playlistLevel;
    }

    if (this.defaultIndustry && this.defaultProfession && this.defaultSpecialisation) {
      this.getCurrentlySelectedExperienceLevels(this.defaultIndustry, this.defaultProfession, this.defaultSpecialisation);
    }

    this.creatingGoalAndPlaylistFromPlaylistCode = true;
    this.initialiseNewGoal();
  }

  public visit(step: GoalStep) {
    if (!this.editMode) {
      if (!this.visitedSteps.includes(step)) {
        // console.log(`*** visit: Visiting step: ${GoalStep[step]}`);
        this.visitedSteps.push(step);
      }
      this.currentStep = step;

      if (this.currentStep <= GoalStep.INDUSTRY) {
        this.selectedProfession = undefined;
      }
      if (this.currentStep < GoalStep.PROFESSION) {
        this.selectedSpecialisation = undefined;
      }
      if (this.currentStep < GoalStep.LEVEL) {
        this.selectedLevel = undefined;
      }
      if (this.currentStep < GoalStep.SKILLS) {
        this.selectedTrackCard = undefined;
      }
    }
  }

  public visited(step: GoalStep): boolean {
    // console.log(`*** visited: GoalStep step: ${GoalStep[step]}`);
    return this.visitedSteps.includes(step);
  }

  // not proud of the any here, but just trying to fix up as much in the way of linitng issues as possible.

  public addSkill(categoryIn: string, nameIn: string, idIn?: number, coreSkill?: boolean) {
    if (!this.skills[categoryIn]) {
      this.skills[categoryIn] = [];
    }
    if (this.skills[categoryIn].filter((s) => s.name === nameIn).length === 0) {
      this.skills[categoryIn].push({
        id: idIn,
        name: nameIn,
        category: categoryIn,
        coreSkill: coreSkill,
      } as ISkill);
    }
  }

  addNewTrack() {
    if (!this.customTrackName || this.customTrackName === '') {
      this.invalidTrackName = true;
    } else {
      this.tracks.forEach((t) => {
        if (t.name === this.customTrackName) {
          this.selectedTrackCard = this.customTrackName;
        }
      });
      if (!this.selectedTrackCard) {
        const skillsFromCustomTrack = this.tracks.find((i) => i.name === 'Custom')?.recommendedSkills;
        this.tracks.push({ name: this.customTrackName, industry: this.selectedIndustry, profession: this.selectedProfession, specialisation: this.selectedSpecialisation, level: `Level ${this.selectedLevel}`, recommendedSkills: skillsFromCustomTrack, approved: false } as ITrack);
        this.selectedTrackCard = this.customTrackName;
      }
    }
  }

  selectIndustry(selectIndustry: string | undefined) {
    // if they have already selected an industry, set it to that, otherwise, if they have set one in their  goal, then set it to that, otherwise, set it to the default industry
    this.selectedIndustry = selectIndustry ? selectIndustry : this.goal?.industry ? this.goal.industry : this.defaultIndustry;

    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }
    if (!this.industries) {
      throw new Error('Industries must be defined at this point.');
    }

    this.goal.industry = this.selectedIndustry;
    this.goal.profession = undefined;
    this.goal.specialisation = undefined;

    // If there is only one industry, set up so that the Industry is visited and we're only onto the next step (professions);
    if (this.industries.length === 1) {
      this.visitedSteps.push(GoalStep.INDUSTRY);
      this.visit(GoalStep.PROFESSION);
    }
    this.getProfessions();
  }

  selectProfession(selectProfession: string) {
    // if they have already selected a profession, set it to that, otherwise, if they have set one in their  goal, then set it to that, otherwise, set it to the default profession
    this.selectedProfession = selectProfession ? selectProfession : this.goal?.profession ? this.goal?.profession : this.defaultProfession;

    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }
    if (!this.professions) {
      throw new Error('Professions must be defined at this point.');
    }

    this.goal.profession = this.selectedProfession;
    this.goal.specialisation = undefined;

    // If there is only one profession, set up so that the Profession is visited and we're only onto the next step (specialisations);
    if (this.professions.length === 1) {
      this.visitedSteps.push(GoalStep.PROFESSION);
      this.visit(GoalStep.SPECIALISATION);
    }
    this.getSpecialisations();
  }

  // PositionLevels are Junior, Developer, Senior, Architect
  public selectPositionLevel(positionLevel: string | undefined) {
    if (positionLevel) {
      this.selectedExperience = this.experienceLevels.find((i) => i.positionLevel === positionLevel);
      this.selectedPositionLevel = positionLevel;
    }
  }

  public selectSpecialisation(selectedSpecialisation: string) {
    if (!this.specialisations) {
      throw new Error('Specialisations must be defined at this point.');
    }
    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }

    const index = this.specialisations.indexOf(selectedSpecialisation);
    this.selectedSpecialisation = index >= 0 ? selectedSpecialisation : undefined;
    this.goal.specialisation = index >= 0 ? selectedSpecialisation : undefined;
    this.getExperienceLevels();
  }

  updateGoalDuration() {
    const tempGoalDuration = new Date();
    switch (this.goalDuration) {
      case 25:
        this.targetDate = new Date(tempGoalDuration.setMonth(tempGoalDuration.getMonth() + 3));
        break;
      case 50:
        this.targetDate = new Date(tempGoalDuration.setMonth(tempGoalDuration.getMonth() + 6));
        break;
      case 75:
        this.targetDate = new Date(tempGoalDuration.setMonth(tempGoalDuration.getMonth() + 9));
        break;
      case 100:
        this.targetDate = new Date(tempGoalDuration.setMonth(tempGoalDuration.getMonth() + 12));
        break;
      default:
        this.targetDate = new Date(tempGoalDuration.setMonth(tempGoalDuration.getMonth() + 9));
        break;
    }
    this.targetDate.setHours(12);
    this.targetDate.setMinutes(0);
    this.targetDate.setSeconds(0);
    this.targetDate.setMilliseconds(0);
  }

  public displayGoalDuration() {
    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }

    if (!this.goal.dateTarget) {
      // if there is no dateTarget, don't try and set it
      this.existingGoalDuration = undefined;
      return;
    }

    const targetDateInDays = (new Date(this.goal.dateTarget).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);

    switch (true) {
      case targetDateInDays <= 0:
        this.existingGoalDuration = undefined;
        break;
      case targetDateInDays > 0 && targetDateInDays <= 90:
        this.existingGoalDuration = 25;
        break;
      case targetDateInDays > 90 && targetDateInDays <= 180:
        this.existingGoalDuration = 50;
        break;
      case targetDateInDays > 180 && targetDateInDays <= 270:
        this.existingGoalDuration = 75;
        break;
      case targetDateInDays > 270 && targetDateInDays <= 365:
        this.existingGoalDuration = 100;
        break;
      default:
        this.existingGoalDuration = 0;
        break;
    }
    this.goalDuration = this.existingGoalDuration;
  }

  defaultTargetDate(): Date {
    const targetDate = new Date();
    targetDate.setMonth(targetDate.getMonth() + 9);
    targetDate.setHours(12);
    targetDate.setMinutes(0);
    targetDate.setSeconds(0);
    targetDate.setMilliseconds(0);
    return targetDate;
  }

  selectTrackCard(track: ITrack) {
    this.selectedTrackCard = track.name;
    this.selectedTrackCardImage = track.imageUrl;
    this.selectedTrackCardDescription = track.description;
  }

  deselectTrackCard() {
    this.selectedTrackCard = undefined;
    this.selectedTrackCardImage = undefined;
    this.selectedTrackCardDescription = undefined;
  }

  setGoalSelectTrack() {
    if (!this.goal) {
      throw new Error('Goal cannot be defined at this point.');
    }

    if (this.selectedTrackCard) {
      const track = this.getTrackWithName(this.tracks, this.selectedTrackCard);
      if (!track) {
        throw new Error('The track really should be defined at this point');
      }

      this.goal.skills = this.getTrackSkills();
      this.goal.selectedTrackName = track.name;
      this.goal.selectedTrackId = track.id;
    } else {
      this.message.create('error', 'You must select a track');
    }
  }

  submit() {
    if (!this.goal) {
      throw new Error('Goal should be defined when we are submitting.');
    }

    if (this.targetDate) {
      this.goal.dateTarget = this.targetDate;
    }

    if (this.selectedTrackCard) {
      this.setGoalSelectTrack();

      // if editing a goal
      if (this.editMode) {
        // console.log(`submit: updating the selected with the local one`);
        this.goalService.updateGoalAndServiceData(this.goal).subscribe({
          next: (res: IGoal) => {
            // console.log(`updateGoal Succeeded ${JSON.stringify(res)}`);
            this.closeEditGoalModalEmitter.emit();
            this.message.create('success', 'Goal updated successfully!');

            this.updateEmploymentExperiencesWithGoal(res);
          },
          error: (err: HttpErrorResponse) => {
            console.error('Error updating goal ' + err);
          }
        });
      } else {
        // if adding a new goal
        // console.log(`submit: adding a new goal by adding the local one`);
        if (!this.goalService.isGoalDuplicate(this.goal)) {
          if (this.creatingGoalAndPlaylistFromPlaylistCode) {
            this.addGoalFromInviteCode(this.invitationCode!);
          } else {
            this.addGoal(this.goal);
          }
        } else {
          this.message.error('You\'ve already got that goal. Try editing it instead!');
        }
      }
    } else {
      this.message.create('error', 'You must select a track');
      this.currentStep = this.goalStep.SKILLS;
    }
  }

  addGoalFromInviteCode(invitationCode: string) {
    this.inviteService.getInvitationDetails(invitationCode).subscribe({
      next: (invitation: IInvitation) => {
        // validate invite code
        if (invitation) {
          if (!invitation.userPlaylistId && !invitation.playlistId) {
            this.creatingGoalAndPlaylistFromPlaylistCode = false;
            throw new Error('Invitation is not associated with a user playlist nor playlist');
          }

          // get playlist id (not user playlist id)
          const playlistId = invitation.playlistId;
          if (!playlistId) {
            this.creatingGoalAndPlaylistFromPlaylistCode = false;
            throw new Error(`Wrong invite code`);
          }

          // create the goal
          // eslint-disable-next-line @typescript-eslint/unbound-method
          this.goalService.addGoal(this.goal!).subscribe({
            next: () => {
              this.message.success('Goal created successfully!');
              this.creatingGoalAndPlaylistFromPlaylistCode = false;
              // route to profile page
              this.router.navigate(['profile/overview']);
            },
            error: (err: HttpErrorResponse) => console.error('Error adding new goal' + err)
          });
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error('Error getting Invitation details: ' + err.message);
      },
    });

  }

  addGoal(goal: IGoal) {
    this.goalService.addGoal(goal).subscribe({
      next: (res: IGoal) => {
        this.message.success('Goal added successfully!');
        this.updateEmploymentExperiencesWithGoal(res);
        if (res && res.id) {
          this.sendGoalIdEmitter.emit(res.id);
        }
        this.closeAddGoalModalEmitter.emit();
      },
      error: (err: HttpErrorResponse) => console.error('Error adding new goal' + err)
    });
  }

  updateEmploymentExperiencesWithGoal(goalArg: IGoal) {
    if (!this.goal) {
      throw new Error('Goal must be defined at this point.');
    }

    const selectedEmploymentExperiencesAll = this.selectedEmploymentExperiencesCurrent.concat(this.selectedEmploymentExperiencesPast);

    selectedEmploymentExperiencesAll.forEach((se) => {
      const idx = se.experience.associatedGoals.findIndex((g: IGoal) => g.id === this.goal?.id);
      if (se.selected) {
        if (idx === -1) {
          if (this.goal?.id) {
            se.experience.associatedGoals.push(goalArg);
          }
        }
      } else {
        if (idx !== -1) {
          se.experience.associatedGoals.splice(idx, 1);
        }
      }
      this.employmentExperienceService.updateEmploymentExperience(se.experience).subscribe({
        next: () => {
          if (se.selected) {
            this.message.success(`We've also successfully associated your employment at ${se.experience.company.name} with this goal`);
          }
        },
        error: (error: HttpErrorResponse) => console.error(`*** ERROR! GoalComponent couldn't update experience: ${JSON.stringify(error)}, ${JSON.stringify(se.experience)}`)
      });
    });


  }

  private getTrackSkills(): ISkill[] {
    let skillSelectorElement: GoalTrackComponent | undefined;

    if (this.trackElements) {
      this.trackElements.forEach((selector) => {
        // console.log(`*** getTrackSkills: Track= ` + selector.track);
        if (selector.track.name === this.selectedTrackCard) {
          skillSelectorElement = selector;
        }
      });
    }

    if (undefined !== skillSelectorElement) {
      return skillSelectorElement.getSelectorSkills();
    }

    return [];
  }

  private getCurrentlySelectedIndustry(): string | undefined {
    if (!this.selectedIndustry) {
      if (this.defaultIndustry && this.industries) {
        // if there is a default, it should be one of the industries
        if (this.industries.findIndex(i => i === this.defaultIndustry) >= 0) {
          return this.defaultIndustry;
        }
        else {
          console.warn('There is a default, but it is not one fo the list of supported industries');
        }
      }
      else {
        console.warn('Setting goals and gettng the selected industry is really broken right now. We need a list of industries at the very least');
      }
      return undefined;
    }
    return this.selectedIndustry;
  }

  private getCurrentlySelectedProfession(): string | undefined {
    if (!this.selectedProfession) {
      if (this.defaultProfession && this.professions) {
        // if there is a default, it should be one of the industries
        if (this.professions.findIndex(i => i === this.defaultProfession) >= 0) {
          return this.defaultProfession;
        }
        else {
          console.warn('There is a default, but it is not one fo the list of supported professions');
        }
      }
      else {
        console.warn('Setting goals and gettng the selected Profession is really broken right now. We need a list of professions at the very least');
      }
      return undefined;
    }
    return this.selectedProfession;
  }

  private getCurrentlySelectedSpecialisation(): string | undefined {
    if (!this.selectedSpecialisation) {
      if (this.defaultSpecialisation && this.specialisations) {
        // if there is a default, it should be one of the industries
        if (this.specialisations.findIndex(i => i === this.defaultSpecialisation) >= 0) {
          return this.defaultSpecialisation;
        }
        else {
          console.warn('There is a default, but it is not one for the list of supported specialisations');
        }
      }
      else {
        console.warn('Setting goals and gettng the selected Profession is really broken right now. We need a list of professions at the very least');
      }
      return undefined;
    }
    return this.selectedSpecialisation;
  }


  private verifyNumGoals(): void {
    this.noGoals = this.goalService.getGoals().length > 0 ? false : true;
  }
}
