<div class="recommendations-container">
  <div class="filters" *ngIf="searchedRecommendations?.length > 0">
    <label class="filter-label" nz-checkbox [(ngModel)]="isFilter">Filter</label>
    <div class="filter-section" *ngIf="isFilter" nz-row nzJustify="space-between">
      <nz-card nzHoverable nz-col nzXs="24" nzMd="7">
        <div class="filter-options">
          Category
          <nz-tag *ngFor="let category of categories" nzMode="checkable" [nzChecked]="selectedCategories.indexOf(category) > -1" (nzCheckedChange)="handleChangeCategories($event, category)">
            {{ category }}
          </nz-tag>
        </div>
        <div class="filter-reset">
          <button nz-button nzType="link" (click)="resetSelectedCategories()"><i class="fas fa-redo"></i></button>
        </div>
      </nz-card>

      <nz-card nzHoverable nz-col nzXs="24" nzMd="7">
        <div class="filter-options">
          Skills
          <nz-tag *ngFor="let skill of skillsName" nzMode="checkable" [nzChecked]="selectedSkillsName.indexOf(skill) > -1" (nzCheckedChange)="handleChangeSkillNames($event, skill)">
            {{ skill }}
          </nz-tag>
        </div>
        <div class="filter-reset">
          <button nz-button nzType="link" (click)="resetSelectedSkills()"><i class="fas fa-redo"></i></button>
        </div>
      </nz-card>

      <nz-card nzHoverable nz-col nzXs="24" nzMd="7">
        <div class="filter-options">
          Types
          <nz-tag *ngFor="let type of typeNames" nzMode="checkable" [nzChecked]="selectedTypesName.indexOf(type) > -1" (nzCheckedChange)="handleChangeTypeNames($event, type)">
            {{ type }}
          </nz-tag>
        </div>
        <div class="filter-reset">
          <button nz-button nzType="link" (click)="resetSelectedTypes()"><i class="fas fa-redo"></i></button>
        </div>
      </nz-card>
    </div>
  </div>

  <div class="no-matched-recommendations" *ngIf="filteredRecommendations?.length === 0 && hasRecommendations && playlists?.length === 0 && actions?.length === 0">
    <nz-alert id="alert-no-matched-recommendations" nzType="info" nzShowIcon nzMessage="No matching recommendations" [nzDescription]="descriptionTpl">
      <ng-template #descriptionTpl><br />There are no recommendations that match this filter. Reset the filter to get some more yummy goodness.</ng-template>
    </nz-alert>
  </div>

  <div *ngIf="!isFilter" class="playlist-cards">
    <sl-playlist-card class="playlist-card" *ngFor="let playlist of playlists" [playlist]="playlist" [goalSkills]="goalSkills" [goalId]="goalId" (playlistAdded)="handlePlaylistAdded($event)" (activityAdded)="handleActivityAdded($event)"></sl-playlist-card>
  </div>

  <sl-action-card class="action-card" *ngFor="let suggestedAction of actions" [suggestedAction]="suggestedAction" [goalId]="goalId" (actionAdded)="handleActionAdded($event)"></sl-action-card>

  <sl-activity-card class="activity-card" *ngFor="let recommendation of filteredRecommendations" [recommendation]="recommendation" [goalSkills]="goalSkills" [goalId]="goalId" (activityAdded)="handleActivityAdded($event)"></sl-activity-card>
</div>
