<div class="section-profile" nz-row>
  <div class="profile-image" nz-col nzXs="10" nzSm="6" nzXl="4" nzXXl="3">
    <img *ngIf="this.userProfilePublic?.image" class="profile-img" [src]="this.userProfilePublic.image" />
    <i *ngIf="!this.userProfilePublic?.image" id="avatar-icon" nz-icon nzType="user" nzTheme="outline"></i>
  </div>

  <div class="user-information" nz-col nzXs="24" nzSm="18" nzXl="20" nzXXl="21">
    <div class="user-name-container">
      <h1 class="user-name">{{ this.fullName }}</h1>
      <div class="user-location">
        <h2>{{ userLocation }}</h2>
      </div>
      <div class="user-tagline">
        <h6 nz-typography nzEllipsis nzEllipsisRows="1">{{ this.userProfilePublic?.tagline }}</h6>
      </div>
    </div>
    <div [ngClass]="{'edit-profile-container':!isPublicProfileMode, 'invitation-container':isPublicProfileMode}">
      <button *ngIf="isPublicProfileMode && hasPermissionToInviteUserToGuild && !hasInvitedUserToGuild" id="invite-user-button" nz-button nzType="primary" (click)="inviteUserToGuild()">Invite to Guild</button>
      <button *ngIf="isPublicProfileMode && hasInvitedUserToGuild" id="invitation-sent-button" nz-button nzType="primary" disabled>Guild Invitation sent</button>
      <div *ngIf="!isPublicProfileMode" id="nameplate-container" [class.flipped]="isFlipped" (click)="toggleFlip()">
        <div class="nameplate">
          <div class="nameplate-front">
            <div id="completed-user-activities-metric-container" (click)="toggleMetricViewType($event)">
              <ngx-charts-line-chart id="completed-user-activities-metric-chart" [view]="view" [scheme]="colourScheme" [results]="completedUserActivitiesMetric" [animations]="animations">
                <ng-template #tooltipTemplate let-model="model">
                  <div *ngIf="metricViewType === viewType.Monthly">Monthly- {{ model.name }}: {{model.value}} Completed</div>
                  <div *ngIf="metricViewType === viewType.Weekly">Weekly- {{ model.name }}: {{model.value}} Completed</div>
                  <div *ngIf="metricViewType === viewType.Yearly">Yearly- {{ model.name }}: {{model.value}} Completed</div>
                </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model">
                  <div *ngIf="metricViewType === viewType.Monthly">Monthly - {{ model[0].name }}: {{model[0].value}} Completed</div>
                  <div *ngIf="metricViewType === viewType.Weekly">Weekly- {{ model[0].name }}: {{model[0].value}} Completed</div>
                  <div *ngIf="metricViewType === viewType.Yearly">Yearly- {{ model[0].name }}: {{model[0].value}} Completed</div>
                </ng-template>
              </ngx-charts-line-chart>
            </div>
            <ng-container *ngTemplateOutlet="pausePlayIcon"></ng-container>
          </div>
          <div class="nameplate-back">
            <div id="activity-streaks-container">
              <nz-statistic nzValue="{{getActivityStreakDays() | SingularPlural : ' Day' : 'Days'}}" [nzTitle]="'Activity Streak'" [nzPrefix]="flameIcon" [nzSuffix]="hourglassIcon"></nz-statistic>
              <ng-template #flameIcon>
                <i class="flame-icon fa-solid fa-fire-flame-simple" style="color:#e25822"></i>
              </ng-template>
              <ng-template #hourglassIcon>
                <div class="hourglass-container" [ngClass]="{ 'animate-hourglass': isUserAtRiskOfLosingStreak() }">
                  <span class="hourglass primary-hourglass" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Complete an activity soon to maintain your streak">&#8987;</span>
                  <span class="hourglass secondary-hourglass" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Complete an activity soon to maintain your streak">&#9203;</span>
                </div>
              </ng-template>
            </div>
            <ng-container *ngTemplateOutlet="pausePlayIcon"></ng-container>
          </div>
        </div>
      </div>

      <ng-template #pausePlayIcon>
        <span *ngIf="!isOnPause" class="pause-play-icon" nz-icon nzType="pause-circle" nzTheme="outline" (click)="togglePausePlay($event)" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Pause auto rotation"></span>
        <span *ngIf="isOnPause" class="pause-play-icon" nz-icon nzType="play-circle" nzTheme="outline" (click)="togglePausePlay($event)" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Continue auto rotation"></span>
      </ng-template>

      <span *ngIf="!isPublicProfileMode" id="edit-profile-button-container" nz-tooltip nzTooltipPlacement="top" nzTooltipTitle="Edit Profile">
        <button nz-button nzType="default" [nzShape]="'circle'" (click)="editAccount()">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
        </button>
      </span>
    </div>

    <p id="status-message">
      <i>{{ subscriptionStatusMessage }}</i>
    </p>
  </div>
  <sl-onboarding class="center onboarding-area" *ngIf="!isPublicProfileMode"></sl-onboarding>
</div>