<div class="notifications">
  <h2 class="header">Notifications</h2>

  <nz-space class="notification-controls">
    <div *nzSpaceItem class="notification-controls-item">
      <button nzShape="square" nz-button nzType="primary" (click)="showNewNotificationModal()" nz-tooltip nzTooltipTitle="Add a Notification">
        <i class="fa fa-plus"></i>
      </button>
    </div>

    <div *nzSpaceItem class="notification-controls-item">
      <button nz-button nzType="default" nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete all expired notifications?" (nzOnConfirm)="removeExpiredEmails()" nz-tooltip nzTooltipTitle="Clear all expired notifications">
        <i nz-icon nzType="delete" nzTheme="fill"></i>
      </button>
    </div>
  </nz-space>

  <nz-modal [(nzVisible)]="isVisible" nzTitle="New Notification" (nzOnCancel)="cancelNotification()" (nzOnOk)="addNotification()" [nzOkLoading]="loading" nzWidth="40%">
    <ng-container *nzModalContent>
      <div nz-row class="modal-field">
        <label nz-col [nzSpan]="4" class="modal-label">Title</label>
        <input nz-col [nzSpan]="20" class="modal-input" nz-input placeholder="Enter the title of the notification here" [(ngModel)]="newNotification.messageTitle" ngDefaultControl>
      </div>
      <div nz-row class="modal-field">
        <label nz-col [nzSpan]="4" class="modal-label-message">Message</label>
        <textarea nz-col [nzSpan]="20" class="modal-input" nz-input placeholder="Enter the message here" [nzAutosize]="{ minRows: 3, maxRows: 6 }" ngDefaultControl [(ngModel)]="newNotification.messageBody"></textarea>
      </div>
      <div nz-row class="modal-field">
        <label nz-col [nzSpan]="4" class="modal-label">Link</label>
        <input nz-col [nzSpan]="20" class="modal-input" nz-input placeholder="Enter the link here" [(ngModel)]="newNotification.link" ngDefaultControl>
      </div>
      <div nz-row class="modal-field">
        <label nz-col [nzSpan]="4" class="modal-label">Goal</label>
        <div nz-col [nzSpan]="20">
          <nz-space nzDirection="vertical">
            <input *nzSpaceItem nz-input placeholder="Industry" [(ngModel)]="newNotification.industry">
            <input *nzSpaceItem nz-input placeholder="Profession" [(ngModel)]="newNotification.profession">
            <input *nzSpaceItem nz-input placeholder="Specialisation" [(ngModel)]="newNotification.specialisation">
            <input *nzSpaceItem nz-input placeholder="Level" [(ngModel)]="newNotification.levelFrom">
          </nz-space>
        </div>
      </div>
      <div nz-row class="modal-field">
        <label nz-col [nzSpan]="4" class="modal-label">Role</label>
        <input nz-col [nzSpan]="20" class="modal-input" nz-input placeholder="Enter the role here" [(ngModel)]="newNotification.recipientRole" ngDefaultControl>
      </div>
      <div nz-row class="modal-field">
        <label nz-col [nzSpan]="4" class="modal-label">Dates</label>
        <div nz-col [nzSpan]="20">
          <nz-range-picker [(ngModel)]="this.newNotification.date" (ngModelChange)="onChange($event)"> </nz-range-picker>
        </div>
      </div>
      <div nz-row class="modal-field">
        <label nz-col [nzSpan]="4" class="modal-label">Eligible</label>
        <nz-select nz-col [nzSpan]="20" class="modal-input" nzMode="multiple" nzPlaceHolder="Users eligible to see" ngDefaultControl [(ngModel)]="selectedUsers" nzAllowClear nzShowSearch>
          <nz-option *ngFor="let user of users" [nzLabel]="user.name + ' ' + user.surname + ' ' + '(' + user.email + ')'" [nzValue]="user.id"></nz-option>
        </nz-select>
      </div>
    </ng-container>
  </nz-modal>

  <div *ngIf="loading">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <nz-alert *ngIf="hasError" nzType="error" nzMessage="Error" nzDescription="Notifications could not be loaded" nzShowIcon> </nz-alert>
  <div *ngIf="!loading && !hasError" class="table-div">
    <nz-table #filterTable [nzData]="notifications">
      <thead>
        <tr>
          <th>Title</th>
          <th>Body</th>
          <th>Link</th>
          <th>Created By UserId</th>
          <th>Date Created</th>
          <th>Date Publish</th>
          <th>Date Expiry</th>
          <th *ngFor="let column of listOfColumns" [nzFilterMultiple]="column.filterMultiple" [nzFilters]="column.listOfFilter" [nzFilterFn]="column.filterFn"> {{column.name}} </th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filterTable.data">
          <td>{{ data.messageTitle }}</td>
          <td class="messageBody">{{ data.messageBody }}</td>
          <td [routerLink]="[data.link]" class="table-link">{{ data.link }}</td>
          <td>{{ data.creatorUserId }}</td>
          <td>{{ data.dateCreated | date:'EEEE, MMMM d, y' }}</td>
          <td>{{ data.datePublish | date:'EEEE, MMMM d, y, h:mm:ss a zzzz' }}</td>
          <td>{{ data.dateExpiry | date:'EEEE, MMMM d, y, h:mm:ss a zzzz' }}</td>
          <td>{{ getStatus(data) }}</td>
          <td>
            <a nz-button nzType="link" *ngIf="!isExpired(data.dateExpiry)" (click)="removeNotification(data)"><i class="fas fa-trash-alt icon"></i></a>
            <a class="remove-single-expired" nz-button nzType="link" *ngIf="isExpired(data.dateExpiry)" nz-popconfirm nzPopconfirmTitle="Are you sure you want to permanently delete this notification?" (nzOnConfirm)="removeExpiredNotification(data)"><i
                class="fas fa-trash-alt icon"></i></a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>