import { Component, OnInit } from '@angular/core';

// models
import { IPlaylist } from 'src/app/models/playlist.model';

// services
import { NzMessageService } from 'ng-zorro-antd/message';
import { PlaylistService } from 'src/app/services/playlist.service';
import { ILearningType } from 'src/app/models/learning-type.model';
import { LearningService } from 'src/app/services/learning.service';

@Component({
  selector: 'sl-profile-playlists',
  templateUrl: './profile-playlists.component.html',
  styleUrls: ['./profile-playlists.component.less'],
})
export class ProfilePlaylistsComponent implements OnInit {
  isVisible = false;
  playlists: IPlaylist[] = [];
  learningTypes!: ILearningType[];
  isLoadingPlaylists = false;
  constructor(private playlistService: PlaylistService, private message: NzMessageService, private learningService: LearningService) { }

  ngOnInit(): void {
    this.getCreatedPlaylists();
    this.getLearningTypes();
  }

  public getCreatedPlaylists(): void {
    this.isLoadingPlaylists = true;
    this.playlistService.getPlaylistsCreated().subscribe({
      next: (playlists: IPlaylist[]) => {
        // show the playlists in alphabetical order
        this.playlists = playlists.sort((obj1, obj2) => {
          if (obj1.name > obj2.name) {
            return 1;
          }

          if (obj1.name < obj2.name) {
            return -1;
          }

          return 0;
        });
        this.isLoadingPlaylists = false;
      },
      error: (err) => {
        this.message.error(`Error: Failed to get the playlists you've created because we've lost connection to SeaLadder HQ. Check network connection? ${err}`);
        this.isLoadingPlaylists = false;
      },
    });
  }

  public showModal(): void {
    this.isVisible = true;
  }

  public closeModal(): void {
    this.isVisible = false;
  }

  public playlistCreated(playlist: IPlaylist) {
    this.playlists.unshift(playlist);
    this.isVisible = false;
  }

  public playlistUpdated(playlist: IPlaylist) {
    // take the existing object out and replace it with the new one at the front of the list.
    this.playlists.splice(this.playlists.findIndex((p) => p.id === playlist.id));
    this.playlists.unshift(playlist);
    this.isVisible = false;
  }

  public retirePlaylist(playlist: IPlaylist) {
    this.playlists.splice(this.playlists.findIndex((p) => p.id === playlist.id), 1);
  }

  public getLearningTypes() {
    this.learningService.getLearningTypes().subscribe(lt => {
      this.learningTypes = lt;
    });
  }
}
