import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IRecommendation } from 'src/app/models/recommendation.model';

@Component({
  selector: 'sl-activity-contents-drawer',
  templateUrl: './activity-contents-drawer.component.html',
  styleUrls: ['./activity-contents-drawer.component.less']
})
export class ActivityContentsDrawerComponent {
  @Input() recommendation!: IRecommendation;
  @Input() isActivityListMode!: boolean;
  @Input() fileUrl!: string;
  @Input() addedToBacklog = false;
  @Input() showDrawer = false;
  @Input() shorterUrl = '';
  @Input() showLearningTypeIcon = false;
  @Input() missingImagePlaceholder!: string | undefined;
  @Input() learningTypeIcon!: string | undefined;

  @Output() activityRecommendationAdded = new EventEmitter<IRecommendation>();
  @Output() drawerVisible = new EventEmitter<boolean>();
  @Output() drawerNotVisible = new EventEmitter<boolean>();

  defaultImage = 'https://i.stack.imgur.com/y9DpT.jpg';

  closeDrawer() {
    this.showDrawer = false;
    this.drawerNotVisible.emit(true);
  }

  openDrawer() {
    this.showDrawer = true;
    this.drawerVisible.emit(true);
  }

  addToBacklog() {
    this.activityRecommendationAdded.emit(this.recommendation);
  }

  public handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = this.missingImagePlaceholder ? this.missingImagePlaceholder : this.defaultImage;
  }
}
