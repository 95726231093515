<div class="col-xs-offset-1 col-xs-10">
  <div id="wrapper">
    <h1 id="header">Browse Recommendations</h1>
    <div *ngIf="!noActiveGoals" class="filters">
      <h2 id="minor-header">Your Recommendations</h2>
      <div class="search-container">
        <input placeholder="Search" nz-input [(ngModel)]="query" />
        <div class="advanced-filter">
          <label class="advanced-label" nz-checkbox [(ngModel)]="advancedFilter">Filter All</label>
          <div class="advanced-options" *ngIf="advancedFilter">
            <nz-checkbox-group [(ngModel)]="advancedFilterCheckOptions" (ngModelChange)="updateAdvancedFilter()"></nz-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="noActiveGoals">
      <br>
      <h2 id="minor-header">No Recommendations Today</h2>
    </div>

    <div class="recommendations-container">
      <nz-collapse>
        <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.goalSignature" [(nzActive)]="panel.expanded" (nzActiveChange)="toggleExpanded($event, panel)">
          <div *ngIf="loading(panel)">L O A D I N G</div>
          <nz-spin class="spin-loading" [nzSpinning]="loading(panel)" [nzTip]="'Calculating the best activities for the skills in your goal...'">
            <div *ngIf="!loading(panel)">
              <nz-pagination [(nzPageIndex)]="panel.currentPageIndex" nzShowSizeChanger 
              [nzPageSize]="panel.itemsPerPage" [nzTotal]="panel.recommendedActivitiesAll.length + panel.recommendedActionsAll.length + panel.recommendedPlaylistsAll.length" [nzSize]="'small'" (nzPageIndexChange)="updateAdvancedFilterShowCount(panel)"
                (nzPageSizeChange)="updateItemsPerPage(panel, $event)" nzShowQuickJumper nzHideOnSinglePage=true>
              </nz-pagination>
              <sl-recommendation-goal [hasRecommendations]="panel.hasRecommendations" [goalSkills]="panel.goal.skills" [searchedRecommendations]="panel.recommendedActivitiesFiltered | searchRecommendation: query" [playlists]="panel.recommendedPlaylistsFiltered"
               [actions]="panel.recommendedActionsFiltered" [goalId]="panel.goal.id" (activityAdded)="handleActivityAdded($event)" (playlistAdded)="handlePlaylistAdded($event)" (actionAdded)="handleActionAdded($event)">
              </sl-recommendation-goal>
              <nz-pagination [(nzPageIndex)]="panel.currentPageIndex" nzShowSizeChanger [nzPageSize]="panel.itemsPerPage" [nzTotal]="panel.recommendedActivitiesAll.length" [nzSize]="'small'" (nzPageIndexChange)="updateAdvancedFilterShowCount(panel)"
                (nzPageSizeChange)="updateItemsPerPage(panel, $event)" nzShowQuickJumper nzHideOnSinglePage=true>
              </nz-pagination>

              <div *ngIf="!panel.hasRecommendations">
                <nz-alert id="alert-no-recommendations" nzType="info" nzShowIcon nzMessage="No recommendations... yet!" [nzDescription]="descriptionTpl">
                  <ng-template #descriptionTpl>
                    <br />
                    SeaLadder is growing rapidly, but unfortunately, we haven't collected enough data to make recommendations for this goal at this moment.<br />
                    Try adding some <strong><a nzType="link" [routerLink]="['/profile/activities']">Activities</a></strong> of your own to your profile. This will also help others benefit from your experiences so far.
                  </ng-template>
                </nz-alert>
              </div>
            </div>
          </nz-spin>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <nz-alert *ngIf="noActiveGoals" id="alert-no-goals" nzType="info" nzShowIcon nzMessage="No Active Goals" [nzDescription]="descriptionTpl">
      <ng-template #descriptionTpl>
        <div><br>SeaLadder only works when you have one or more goals.<br>
          You're going to have to create a goal first or we won't know what to recommend for you.<br>
          Time to add some<strong><a nzType="link" [routerLink]="['/profile/overview']"> Goals</a></strong> to your profile!<br></div>
      </ng-template>
    </nz-alert>
  </div>
</div>