import { Component, Input } from '@angular/core';
import { EIconSize } from '../../types/icon-size';

@Component({
  selector: 'sl-provider-icon',
  templateUrl: './provider-icon.component.html',
  styleUrls: ['./provider-icon.component.less']
})
export class ProviderIconComponent {
  @Input() className!: string;
  @Input() isACoreSkill!: boolean;
  @Input() providerIconTooltip!: string;
  @Input() coreSkillIconTooltip!: string;
  @Input() tooltipVisible!: boolean;
  coreSkillIconSize = EIconSize;
}