import { IUserActivityBase } from './user-activity-base.model';
import { StatusKind } from '../types/status-kind.type';

export const getUserActionStatus = (userAction: IUserAction): StatusKind => {
  if (userAction.isArchived) {
    return StatusKind.Archived;
  }

  if (userAction.dateCompleted) {
    return StatusKind.Completed;
  }

  if (!userAction.dateStart) {
    return StatusKind.Scheduled;
  }

  if (userAction.dateStart) {
    return StatusKind.InProgres;
  }
  console.error(`We have a userAction in a state that we don't recognise.`);
  throw new Error('invalid userAction state');
};

export interface IUserAction extends IUserActivityBase {
  suggestedActionId: number;
  // associatedActivities: number[];
  imageUrl: string;
  // TODO: George: Why do we not have a link in a userAction?
}
