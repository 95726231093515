import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

// models
import { IEmailType } from 'src/app/models/email-type.model';
import { IUserProfileSubscription } from 'src/app/models/user-profile.model';

// services
import { EmailMessageService } from 'src/app/services/email-message.service';

@Component({
  selector: 'sl-email-message-tabs',
  templateUrl: './email-message-tabs.component.html',
  styleUrls: ['./email-message-tabs.component.less'],
})
export class EmailMessageTabsComponent implements OnInit {
  users!: IUserProfileSubscription[];
  emailTypes!: IEmailType[];
  isLoading = false;
  constructor(private emailMessageService: EmailMessageService, private message: NzMessageService) {}

  ngOnInit(): void {
    this.loadEmailTypes();
  }

  loadEmailTypes() {
    this.isLoading = true;
    this.emailMessageService.getEmailTypeNames().subscribe({
      next: (res) => {
        this.emailTypes = res;
        this.isLoading = false;
      },
      error: (error) => {
        console.error(`*** Failed to Retrieve email message types. Error: ${error}`);
        this.message.error('Oh no, we failed to get the email Types. Try again later?');
        this.isLoading = false;
      }
    });
  }
}

