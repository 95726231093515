import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MockAuthService {
  authNavStatus$ = of(true);

  get isAuthenticated(): boolean {
    return false;
  }

  get authorizationHeaderValue(): string {
    return '';
  }

  register(userRegistration: string): Observable<string> {
    return of(userRegistration);
  }
}
