import { GoalService } from './goal.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, take } from 'rxjs';

@Injectable()
export class HasGoalGuard  {

  private goalsUpdated = new Subject<boolean>();
  private hasGoals!: boolean;
  private state!: RouterStateSnapshot;

  constructor(private goalService: GoalService, private router: Router) {
    console.log('*** HasGoalGuard: constructing...');
    this.goalService.getGoalsUpdatedEvent().pipe(take(1)).subscribe(
      () => {
        this.updateHasGoals();
      },
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.state = state;

    if (!this.goalService.isInitialised()) {
      console.log('*** HasGoalGuard: canActivate called... returning observable.');
      return (this.goalsUpdated);
    }

    this.updateHasGoals();
    console.log(`*** HasGoalGuard: canActivate called... returning value ${this.hasGoals}`);
    return this.hasGoals;
  }

  private updateHasGoals() {
    this.hasGoals = this.goalService.getGoals().length > 0;
    console.log(`*** HasGoalGuard: hasGoals update to: ${this.hasGoals}`);
    this.goalsUpdated.next(this.hasGoals);
    if (!this.hasGoals) {
      this.router.navigate(['goal'], { queryParams: { redirect: this.state.url }, replaceUrl: true });
    }
  }
}
