<div class="profile-wrapper" *ngIf="privateProfileStatus === false">
  <div class="nameplate" *ngIf="userProfilePublic">
    <sl-nameplate [userProfilePublic]="userProfilePublic" [isPublicProfileMode]="true" [isInvitingUserToGuild]="guildId ? true : false" [hasPermissionToInviteUserToGuild]="hasPermission" (inviteUserToGuildEmitter)="handleRequestToInviteUserToGuild($event)"
      [hasInvitedUserToGuild]="hasInvitedUserToGuild"></sl-nameplate>
  </div>
  <div class="goals" *ngIf="userGoals">
    <sl-goal-table [goalsPublic]="userGoals"></sl-goal-table>
  </div>
  <div class="top-skills">
    <sl-top-skills *ngIf="userTopSkills" [topSkillsPublic]="userTopSkills" [userProfilePublic]="userProfilePublic"></sl-top-skills>
    <nz-alert *ngIf="userTopSkills === undefined" id="alert-message" nzType="info" nzShowIcon nzMessage="No Top Skills (yet)" [nzDescription]="descriptionTpl">
      <ng-template #descriptionTpl>
        <div>There is insufficient evidence to establish the top skills for this account.</div>
      </ng-template>
    </nz-alert>
  </div>
  <div class="employments" *ngIf="userEmployment">
    <sl-employment-experience [employmentsPublic]="userEmployment"></sl-employment-experience>
  </div>
</div>
<div class="error-page" *ngIf="privateProfileStatus === true">
  <nz-result nzStatus="404" nzTitle="404" nzSubTitle="Sorry, this page is currently hidden or unavailable.">
    <div nz-result-extra>
      <button nz-button nzType="primary" [routerLink]="['/']">Back Home</button>
    </div>
  </nz-result>
</div>