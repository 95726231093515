<div *ngIf="suggestedAction" class="action-container">
  <nz-card class="card-style" nzHoverable [nzCover]="coverTemplate">
    <div class="card-info-container">
      <h5 class="card-title" id="action-card-{{ suggestedAction.id }}" nz-typography nzEllipsis nzEllipsisRows="1" (click)="showDrawer(true)" nz-tooltip="{{ suggestedAction.title }}">
        {{ suggestedAction.title }}
      </h5>
      <div class="card-type" nz-typography>Author: {{ getAuthor() }}</div>
      <i class="fas fa-book-reader fa-2x card-learning-style" *ngIf="suggestedAction.learningStyle === 'Exposure'" nz-tooltip nzTooltipTitle="Exposure"></i>
      <i class="fas fa-award fa-2x card-learning-style" *ngIf="suggestedAction.learningStyle === 'Credibility'" nz-tooltip nzTooltipTitle="Credibility"></i>
      <i class="fas fa-tools fa-2x card-learning-style" *ngIf="suggestedAction.learningStyle === 'Experience'" nz-tooltip nzTooltipTitle="Experience"></i>
      <i class="fas fa-globe fa-2x card-learning-style" *ngIf="!suggestedAction.learningStyle" nz-tooltip nzTooltipTitle="Uncategorised"></i>
    </div>
  </nz-card>
  <ng-template #coverTemplate>
    <div class="action-image" (mouseleave)="imageLeave()" (mouseenter)="imageEnter()">
      <div class="picture-area" (click)="showDrawer(true)">
        <img class="imgContainer" [src]="getActionImage()" [ngClass]="{ 'img-added-backlog': addedToBacklog }" alt="Image of the action" />
      </div>
      <div *ngIf="!addedToBacklog">
        <button class="add-backlog-button" [hidden]="!isHovered" nz-button nzType="primary" [nzLoading]="isAdding" (click)="addToBacklog()"><i class="fas fa-plus"></i><span class="add-button-text"> Add Action</span></button>
      </div>
      <div *ngIf="addedToBacklog">
        <i class="add-backlog-button checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'"></i>
      </div>
    </div>
  </ng-template>

  <!-- Drawer below -->
  <nz-drawer class="action-drawer" [nzClosable]="true" [nzWidth]="600" [nzVisible]="isDrawerVisible" nzTitle="{{ suggestedAction.title }}" (nzOnClose)="showDrawer(false)">
    <ng-container *nzDrawerContent>
      <div class="panel-container">
        <div class="grid-container">
          <div class="grid-fields">
            <div *ngIf="suggestedAction.industry" nz-typography><strong>Industry:</strong></div>
            <div *ngIf="suggestedAction.profession" nz-typography><strong>Profession:</strong></div>
            <div *ngIf="suggestedAction.specialisation" nz-typography><strong>Specialisation:</strong></div>
            <div *ngIf="suggestedAction.relatedTrackName" nz-typography><strong>Track:</strong></div>
            <div *ngIf="suggestedAction.levelFrom && suggestedAction.levelTo" nz-typography><strong>Level Range:</strong></div>
            <div nz-typography><strong>Author:</strong></div>
            <div *ngIf="suggestedAction.dateCreated" nz-typography><strong>Date Created:</strong></div>
          </div>
          <div class="grid-info">
            <div *ngIf="suggestedAction.industry" nz-typography nzEllipsis>{{ suggestedAction.industry }}</div>
            <div *ngIf="suggestedAction.profession" nz-typography nzEllipsis>{{ suggestedAction.profession }}</div>
            <div *ngIf="suggestedAction.specialisation" nz-typography nzEllipsis>{{ suggestedAction.specialisation }}</div>
            <div *ngIf="suggestedAction.relatedTrackName" nz-typography nzEllipsis>{{ suggestedAction.relatedTrackName }}</div>
            <div *ngIf="suggestedAction.levelFrom && suggestedAction.levelTo" nz-typography>{{ suggestedAction.levelFrom }} - {{ suggestedAction.levelTo }}</div>
            <div nz-typography nzEllipsis>{{ getAuthor() }}</div>
            <div *ngIf="suggestedAction.dateCreated" nz-typography>{{ suggestedAction.dateCreated | date: 'longDate' }}</div>
          </div>
          <img class="grid-image" [src]="getActionImage()" alt="Image of the action" />
        </div>
        <nz-divider></nz-divider>
        <div *ngIf="suggestedAction.description" class="panel-description">
          <h5><b>Description</b></h5>
          <p [innerHTML]="suggestedAction.description"></p>
        </div>
        <div class="panel-add-backlog">
          <div *ngIf="!addedToBacklog">
            <button class="panel-add-backlog-button" nz-button nzType="primary" (click)="addToBacklog()">
              <i class="fas fa-plus"></i>
              <span class="add-button-text"> Add action </span>
            </button>
          </div>
          <div *ngIf="addedToBacklog">
            <i class="panel-add-backlog-button checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'"></i>
          </div>
        </div>
      </div>
    </ng-container>
  </nz-drawer>
</div>