import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GuildService } from 'src/app/services/guild.service';
import { IGuild } from '../../models/guild.model';
import { IPaginatedGuild } from '../../models/paginated-guild.model';
import { Subject, debounceTime } from 'rxjs';
import { IUserProfileSubscription } from 'src/app/models/user-profile.model';

@Component({
  selector: 'sl-search-guilds',
  templateUrl: './search-guilds.component.html',
  styleUrls: ['./search-guilds.component.less'],
})
export class SearchGuildsComponent implements OnInit {
  @ViewChild('guildSearchBar') searchBar!: ElementRef;
  @Output() handleSelectedGuild = new EventEmitter<IGuild>();
  @Output() searchResultsBeingShown = new EventEmitter<IGuild[]>();
  @Output() userClickedGuildId = new EventEmitter<number>();
  @Output() searchingForGuilds = new EventEmitter<boolean>();
  @Output() toggleCheckbox = new EventEmitter<boolean>();
  @Output() handlePermissionToConductSearch = new EventEmitter<boolean>();
  @Input() showAllGuilds = false;
  @Input() isInitialisingFromHomeRoute = true;
  @Input() isConductingSearchFromHomeRoute = false;
  @Input() currentUser!: IUserProfileSubscription;
  @Input() myGuilds!: IGuild[];
  returnedGuilds: IGuild[] = [];

  query = '';
  indeterminate = true;
  searching = false;

  pageSize = 8;
  pageNumber = 1;
  totalGuilds = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  guildsChangeSubject: Subject<any> = new Subject();

  // infinite scroll
  throttle = 0;
  scrollDistance = 2;
  lastGuildIndex = 0;
  debounceTimeMs = 750;
  lastScrollTop = 0;

  constructor(private guildService: GuildService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.guildsChangeSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe(() => {
      this.searchForGuilds(); // this will only fire off the search if the user has stopped typing for 750ms
    });
  }

  selectGuild(guild: IGuild) {
    this.handleSelectedGuild.emit(guild);
  }

  toggleShowAll() {
    this.showAllGuilds = !this.showAllGuilds;
    this.toggleCheckbox.emit(this.showAllGuilds);
  }

  startSearch(query: string) {
    this.query = query;
    this.guildsChangeSubject.next(query); // go through the debounce check before searching.
  }

  loadMoreGuilds() {
    if (this.returnedGuilds.length < this.totalGuilds) {
      this.lastScrollTop = window.scrollY || document.documentElement.scrollTop;
      this.pageNumber++;
      this.searchForGuilds();
    }
  }

  searchForGuilds() {
    this.searchingForGuilds.emit(true);
    this.guildService.searchGuilds(this.query, this.pageNumber, this.pageSize, this.showAllGuilds).subscribe({
      next: (pa: IPaginatedGuild) => {
        this.totalGuilds = pa.total;
        if (pa.total === 0) {
          if (this.showAllGuilds) {
            this.message.warning(`SeaLadder is still growing and needs your help. Consider creating a guild of your own 🤩`);
          } else {
            this.message.warning(`We didn't find any guilds matching your search. You can enable "All guilds" and see if that helps.`);
          }
        }
        this.returnedGuilds = [...this.returnedGuilds, ...pa.data];
        this.returnedGuilds.sort((a, b) => (a.guildMemberCount > b.guildMemberCount ? -1 : a.guildMemberCount < b.guildMemberCount ? 1 : 0));

        //Keep the cursor on the last position before the refresh so it doesn't get reset back to the top of the page
        setTimeout(() => window.scrollTo(0, this.lastScrollTop), 0);

        this.searchResultsBeingShown.emit(this.returnedGuilds);
        this.searchingForGuilds.emit(false);
      },
      error: () => {
        this.message.warning(`Something went wrong while searching for guilds. Please try again later.`);
        this.searching = false;
        this.searchingForGuilds.emit(false);
      },
    });
  }
}
