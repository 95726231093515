import { ISharedPlaylist } from './../../models/shared-playlist.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EButtonType } from 'src/app/types/button-type.type';

// models
import { IPlaylist } from 'src/app/models/playlist.model';
import { ISkill } from 'src/app/models/skill.model';
import { IUserPlaylist } from 'src/app/models/user-playlist.model';

// services
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { UserPlaylistService } from 'src/app/services/user-playlist.service';
import { IGuildMember } from 'src/app/modules-guilds/models/guild-member.model';
import { ILearningType } from 'src/app/models/learning-type.model';

export interface ButtonProperties {
  type: EButtonType;
  name: string;
  icon: string;
}

@Component({
  selector: 'sl-playlist-viewer',
  templateUrl: './playlist-viewer.component.html',
  styleUrls: ['./playlist-viewer.component.less'],
})
export class PlaylistViewerComponent {
  @Input() playlist!: IPlaylist;
  @Input() userPlaylist?: IUserPlaylist;
  @Input() goalId!: number;
  @Input() goalSkills!: ISkill[];
  @Input() isInteractable = true;
  @Input() sharedPlaylist: ISharedPlaylist = {} as ISharedPlaylist;
  @Input() buttonUse: EButtonType = EButtonType.Add;
  @Input() selectedGuildMembers!: IGuildMember[];
  @Input() learningTypes!: ILearningType[];

  @Output() playlistAdded = new EventEmitter<number>();
  @Output() activityAdded = new EventEmitter<number>();
  @Output() playlistShared = new EventEmitter<IPlaylist>();

  buttonType = EButtonType;
  buttonProperties: Map<EButtonType, ButtonProperties> = new Map([
    [EButtonType.Add, { type: EButtonType.Add, name: 'Add Playlist', icon: 'fas fa-plus' }],
    [EButtonType.Share, { type: EButtonType.Share, name: 'Share Playlist', icon: 'fas fa-share' }],
  ]);

  childrenVisible = false;
  isAdding = false;
  MAX_ACTIVITIES = 8;
  activitiesShown = this.MAX_ACTIVITIES;
  readonly defaultCardImage = 'assets/img/playlists/default-playlist.jpg';
  playlistImage = '';

  constructor(private userPlaylistService: UserPlaylistService, private userActivitiesService: UserActivitiesService, private message: NzMessageService, private notification: NzNotificationService) { }

  public get isActivityListMode(): boolean {
    return undefined !== this.userPlaylist;
  }

  public get addedToBacklog(): boolean {
    return this.playlist.added;
  }

  public set addedToBacklog(added: boolean) {
    this.playlist.added = added;
  }

  activityDrawerVisible(visible: boolean) {
    this.childrenVisible = visible;
  }

  increaseActivitiesShown() {
    this.activitiesShown += this.MAX_ACTIVITIES;
  }

  addToBacklog(event: Event) {
    event.stopPropagation(); // prevent the click from bubbling up to the parent card which will switch the card from showing the playlist details to hiding them
    if (!this.goalId) {
      // TODO: @samuelwibz - implement goal selector, and consider how it is best implemented here vs all the other places we've used this component.
      this.notification.warning('Not implemented (yet)', `Unfortunately, we don't know what goal you want to add this playlist to.\nWe haven't implemented support for the goal selector yet.\nSorry for that, we won't be long.`, { nzDuration: 5000, nzPlacement: 'top' });
      return;
    }

    this.isAdding = true;
    this.userPlaylistService.addUserPlaylistToGoal(this.playlist.id, this.goalId).subscribe({
      next: (upl) => {
        this.addedToBacklog = true;
        this.userActivitiesService.updateActivitiesCache();
        this.handlePlaylistAdded(upl.playlistId as number);
        this.isAdding = false;
        this.message.success('Nice, you\'ve added the playlist to your profile.');
        if (upl.allCompleted) {
          this.notification.info('Playlist Added', 'Playlist successfully added, but the activities in this playlist were already completed 🎉.\nWell keep you informed when there are changes to it in the future.', { nzDuration: 0 });
        }
      },
      error: (err: HttpErrorResponse) => {
        // console.error(`*** addTobacklog() returned with error: ${err}`);
        if (err.status === 409) {
          this.addedToBacklog = true; // since we know they already have this activity, then let's mark it as accepted, but we have to wonder why it was here in the first place.
          this.handlePlaylistAdded(this.playlist.id);
          this.message.warning('We have it on good authority that you have added this playlist before.');
        } else {
          this.message.error('Oh dear, this is embarassing, something went wrong 😳. Try again? ');
          this.addedToBacklog = false;
        }
        this.isAdding = false;
      },
    });
  }

  preventEventPropagation(event: Event) {
    event.stopPropagation();
  }

  sharePlaylist() {
    this.playlistShared.emit(this.playlist);
  }

  createNotification(type: string): void {
    this.notification.create(type, 'Notification Title', 'This is the content of the notification. This is the content of the notification. This is the content of the notification.');
  }

  getCardImage(): string {
    if (this.playlistImage) {
      return this.playlistImage;
    }
    if (this.playlist.playlistImageUrl) {
      this.playlistImage = this.playlist.playlistImageUrl;
      return this.playlistImage;
    }

    if (this.playlist.trackImageUrl) {
      this.playlistImage = this.playlist.trackImageUrl;
      return this.playlistImage;
    }

    if (this.playlist.playlistActivitiesVm) {
      let image = '';
      // gets the first activity that has a valid thumbnail URL
      this.playlist.playlistActivitiesVm.every((pav) => {
        if (pav.imageThumbnailUrl) {
          image = pav.imageThumbnailUrl;
          return false;
        }
        return true;
      });
      if (image) {
        this.playlistImage = image;
        return this.playlistImage;
      }
    }

    this.playlistImage = this.defaultCardImage;
    return this.playlistImage;
  }

  public handleActivityAdded(activityId: number) {
    // console.log(`Recommendation for id: '${activityId}' has been added successfully. Notifying listeners...`);
    this.activityAdded.emit(activityId);
  }

  private getAuthor(): string {
    let author = '';
    if (this.playlist) {
      author = this.playlist.createdByFirstName + ' ' + this.playlist.createdByLastName;
      return author;
    }
    return author;
  }

  // called when the playlist is added to let the parent component know.
  private handlePlaylistAdded(playlistId: number) {
    // console.log(`Playlist id: '${playlistId}' has been added successfully. Notifying listeners...`);
    this.playlistAdded.emit(playlistId);
  }
}
