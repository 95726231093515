export enum ELinkType {
  LinkedIn = 0,
  WhatsApp = 1,
  Signal = 2,
  Slack = 3,
  Discord = 4,
  Website = 5,
  Meetup = 6,
  Facebook = 7,
  Other = 8,
}
