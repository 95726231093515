/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prefer-const */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EditMode } from '../../types/edit-mode.type';

export interface LevelSelection {
  name: string;
  position: number;
  levelFrom: number;
  levelTo: number;
}

export enum LevelSelectionMode {
  single = 1,
  range,
}

@Component({
  selector: 'sl-level-selector',
  templateUrl: './level-selector.component.html',
  styleUrls: ['./level-selector.component.less'],
})
export class LevelSelectorComponent implements OnChanges {
  @Output() selectLevelFromEvent = new EventEmitter<number>();
  @Output() selectLevelToEvent = new EventEmitter<number>();
  @Input() currentMode!: EditMode;
  @Input() previouslySelectedLevelFrom!: number;
  @Input() previouslySelectedLevelTo!: number;
  levelOptions: LevelSelection[] = [
    { name: 'Beginner', position: 1, levelFrom: 1, levelTo: 3 },
    { name: 'Intermediate', position: 2, levelFrom: 4, levelTo: 6 },
    { name: 'Advanced', position: 3, levelFrom: 7, levelTo: 9 },
    { name: 'Expert', position: 4, levelFrom: 10, levelTo: 12 },
  ];
  selectedLevel: LevelSelection = {} as LevelSelection;
  selectedLevelRange: LevelSelection[] = [];
  mode: LevelSelectionMode = LevelSelectionMode.single;
  modeType = LevelSelectionMode;

  constructor() { }

  ngOnChanges(): void {
    this.initLevels();
  }

  selectLevel(level: LevelSelection) {
    const index = this.selectedLevelRange.indexOf(level);
    if (index >= 0) {
      this.deselectLevelsBasedOnUserSelection(level, index);
    } else {
      this.selectedLevelRange.push(level);
      this.selectLevelsAutomatically(this.selectedLevelRange[0].levelFrom, this.selectedLevelRange[this.selectedLevelRange.length - 1].levelTo);
    }
    this.checkLevelSelectionMode();
    this.sortArrayContainingLevelRange();
    this.emitSelectedLevels();
  }

  private emitSelectedLevels(): void {
    //if there are no levels selected, set both levelTo and levelFrom to -1
    if (this.selectedLevel === undefined && this.mode === LevelSelectionMode.single) {
      this.selectLevelFromEvent.emit(-1);
      this.selectLevelToEvent.emit(-1);
    } else if (this.selectedLevelRange === undefined && this.mode === LevelSelectionMode.range) {
      this.selectLevelFromEvent.emit(-1);
      this.selectLevelToEvent.emit(-1);
    } else {
      if (this.mode === LevelSelectionMode.single) {
        this.selectLevelFromEvent.emit(this.selectedLevel.levelFrom);
        this.selectLevelToEvent.emit(this.selectedLevel.levelTo);
      } else {
        const lowestLevel = this.selectedLevelRange[0].levelFrom;
        const highestLevel = this.selectedLevelRange[this.selectedLevelRange.length - 1].levelTo;
        this.selectLevelFromEvent.emit(lowestLevel);
        this.selectLevelToEvent.emit(highestLevel);
      }
    }
  }

  private selectLevelsAutomatically(low: number, high: number): void {
    if (low < high) {
      for (let i = low; i <= high; i++) {
        this.levelOptions.forEach((level) => {
          if (level.levelFrom === i && !this.selectedLevelRange.includes(level)) {
            this.selectedLevelRange.push(level);
          }
        });
      }
    } else {
      this.sortArrayContainingLevelRange();
      for (let i = high; i <= low; i++) {
        this.levelOptions.forEach((level) => {
          if (level.levelFrom === i && !this.selectedLevelRange.includes(level)) {
            this.selectedLevelRange.push(level);
          }
        });
      }
    }
  }

  private sortArrayContainingLevelRange(): void {
    this.selectedLevelRange.sort((a: LevelSelection, b: LevelSelection) => a.position - b.position);
  }

  private deselectLevelsBasedOnUserSelection(level: LevelSelection, index: number): void {
    let lowerEnd = this.selectedLevelRange[0].position;
    let higherEnd = this.selectedLevelRange[this.selectedLevelRange.length - 1].position;
    if (level.position === lowerEnd) {
      this.selectedLevelRange.splice(index, 1);
    } else if (level.position === higherEnd) {
      this.selectedLevelRange.splice(index, 1);
    } else if (level.position < higherEnd && level.position > lowerEnd) {
      this.selectedLevelRange.splice(index, higherEnd - level.position + 1);
    } else {
      this.selectedLevelRange.splice(index, 1);
    }
  }

  private checkLevelSelectionMode(): void {
    if (this.selectedLevelRange.length <= 1) {
      this.mode = LevelSelectionMode.single;
      this.selectedLevel = this.selectedLevelRange[0];
    } else {
      this.mode = LevelSelectionMode.range;
      this.selectedLevel = {} as LevelSelection;
    }
  }

  private initLevels() {
    if (this.currentMode === EditMode.edit) {
      this.selectedLevelRange = [];
      const selectedLevel = this.levelOptions.find((lvl) => lvl.levelFrom === this.previouslySelectedLevelFrom && lvl.levelTo === this.previouslySelectedLevelTo);
      if (selectedLevel) {
        this.selectLevel(selectedLevel!);
      } else {
        this.selectLevelsAutomatically(this.previouslySelectedLevelFrom, this.previouslySelectedLevelTo);
      }
    }
  }
}
