<h1 id="user-details-header">User Details</h1>
<nz-tabset nzLinkRouter nzType="card" id="tabset">
  <nz-tab>
    <a class="tab-anchor" *nzTabLink nz-tab-link [routerLink]="['account-details']">Account Details</a>
    <sl-account-details></sl-account-details>
  </nz-tab>
  <nz-tab *ngIf="!isProduction() || isAdminOrTest">
    <a class="tab-anchor" *nzTabLink nz-tab-link [routerLink]="['billing-details']">Billing Details</a>
    <sl-billing-details></sl-billing-details>
  </nz-tab>
</nz-tabset>