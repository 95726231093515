<nz-card class="private-playlist-card" [nzTitle]="cardHeader" [nzExtra]="extraTemplate">
  <div *ngIf="isAddingPlaylist">
    <nz-skeleton id="skeleton-element" *ngIf="isLoadingUserSharedPlaylists" nzType="avatar" [nzActive]="true" [nzAvatar]="true" [nzParagraph]="{ rows: 4 }"></nz-skeleton>
    <sl-playlist-selector id="playlist-selector" *ngIf="!isLoadingUserSharedPlaylists" [playlistsReceived]="sharedPlaylists" (playlistReturned)="handleReturnedPlaylist($event)"></sl-playlist-selector>
  </div>
  <div id="shared-playlist" #container *ngIf="!isAddingPlaylist && sharedPlaylistsForGuild.length > 0 && !isLoadingGuildSharedPlaylists">
    <div *ngFor="let sharedPlaylist of sharedPlaylistsForGuild">
      <sl-shared-playlist-card [sharedPlaylist]="sharedPlaylist" [isInteractable]="isInteractable" [selectedGuildMembers]="selectedGuildMembers" [learningTypes]="learningTypes" (addSharedPlaylist)="handleRequestToAddSharedPlaylist($event)"
        (handleRetirePlaylist)="retirePlaylist($event)" (handleSharedPlaylistExpandedCardState)="handleSharedPlaylistExpandedCardState($event)" (handleUpdatingSharedPlaylistVersion)="updatedSharedPlaylistVersion($event)"></sl-shared-playlist-card>
    </div>
  </div>

  <div class="goal-selection-container">
    <sl-goal-selector [goalSourceType]="goalSelectorComponentType.Playlist" [guild]="guild" [goalSelectorVisible]="goalSelectorVisible" [goals]="returnedPlaylistGoals" [selectedGoal]="selectedGoal" [selectedSharedPlaylist]="selectedSharedPlaylist"
      (selectGoalFromGoals)="selectGoal($event)" (closeGoalSelector)="closeGoalSelectionModal()" (selectGoalFromGoalSelector)="selectGoalFromGoalSelectionModal()"></sl-goal-selector>
  </div>

  <div class="alert-no-shared-playlists-container" *ngIf="!sharedPlaylistsForGuild.length && !isAddingPlaylist && !selectMode && !isLoadingGuildSharedPlaylists">
    <nz-alert id="alert-no-shared-playlists" nzType="info" nzShowIcon [nzMessage]="heading" [nzDescription]="descriptionTpl">
      <ng-template #descriptionTpl>
        <br />
        <span class="alert-body" *ngIf="isEditable">
          No playlists have been shared with the members of this guild.<br />
          Consider sharing some
          <u><strong><a class="share-playlist-button" nzType="link" (click)="sharePlaylist()">Playlists</a></strong></u>
          of your own with the guild. <br />
          This will help others benefit from your experiences so far!
        </span>

        <span class="alert-body" *ngIf="!isEditable">
          No playlists have been shared with the members of this guild.<br />
          Consider joining a guild or creating your own one to start sharing some of your own. <br />
          This will help others benefit from your experiences so far!
        </span>
      </ng-template>
      <ng-template #heading>
        <span class="alert-heading">No shared playlists</span>
      </ng-template>
    </nz-alert>
  </div>

  <div *ngIf="isLoadingGuildSharedPlaylists" class="loading-guild-shared-playlists-spinner-container">
    <nz-spin class="loading-guild-shared-playlists-spinner" nzTip="Hang in there, we're retreiving the playlists..."></nz-spin>
  </div>

  <div *ngIf="!sharedPlaylistsForGuild.length && selectMode">
    <nz-alert id="alert-no-added-playlists" nzType="info" nzShowIcon [nzMessage]="heading" [nzDescription]="descriptionTpl">
      <ng-template #descriptionTpl>
        <br />
        <span class="alert-body"> When playlists have been completed by the selected user(s), they will be displayed here. </span>
      </ng-template>
      <ng-template #heading>
        <span class="alert-heading">No playlists have been added by the selected user(s)</span>
      </ng-template>
    </nz-alert>
  </div>
</nz-card>

<ng-template #cardHeader>
  <span class="private-playlist-activities-container">
    <span *ngIf="!isAddingPlaylist" class="header-icon"><i class="fa-solid fa-list"></i></span>
    <span *ngIf="isAddingPlaylist" class="header-icon"><i class="fa-solid fa-magnifying-glass"></i></span>
    <span *ngIf="!isAddingPlaylist" class="private-playlist-activities-heading">Shared Playlists</span>
    <span *ngIf="isAddingPlaylist" class="private-playlist-activities-heading">Your Playlists to Share</span>
  </span>
</ng-template>

<ng-template #extraTemplate>
  <div *ngIf="!isLoadingUserSharedPlaylists">
    <i *ngIf="!isAddingPlaylist && !selectMode && isInteractable && userSuggestedPlaylistsAvailable()" class="private-playlist-activities-share-icon fa-solid fa-arrow-up-from-bracket" nzTooltipTitle="Share one of your playlists" nzTooltipPlacement="top" nz-tooltip
      (click)="sharePlaylist()"></i>
    <i *ngIf="isAddingPlaylist" class="private-playlist-activities-delete-icon action-icon" nz-icon nzType="close" nzTheme="outline" nzTooltipTitle="Cancel" nzTooltipPlacement="top" nz-tooltip (click)="cancelAdd()"></i>
  </div>
  <div *ngIf="isLoadingUserSharedPlaylists">
    <nz-spin class="loading-guild-shared-playlists-spinner" [nzSize]="'small'"></nz-spin>
  </div>
</ng-template>