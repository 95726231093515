import { Injectable } from '@angular/core';
import { getDocument } from './helper.service';



@Injectable({
  providedIn: 'root',
})
export class SaveFileService {
  constructor() {}

  // adapted from: https://codeburst.io/export-objects-array-as-csv-using-typescript-643bf4f794d9
  saveString(filename: string, content: string): boolean {
    const document = getDocument();

    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    }
    return false;
  }
}
