/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { RollbarService } from '../rollbar';
import { AppDetailsServiceData } from './app-details.service.data.service';
import { getWindow } from './helper.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private appDetailsServiceData: AppDetailsServiceData, private injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-Side Error\nError: ${error.message}`;
          console.error(errorMessage);
        } else {
          // server-side error
          errorMessage = `Server-Side Error\nError Code: ${error.status}\nMessage: ${error.message}`;
          console.error(errorMessage);
        }

        if (this.appDetailsServiceData.isInitialised()) {
          const version = this.appDetailsServiceData.getVersion();
          if (!version.startsWith('P') && !version.startsWith('S')) {
            const window = getWindow();
            window.alert(errorMessage + ' Version: ' + version);
            console.log(`*** HttpErrorInterceptor: app version: ` + version + ` Error dialogs will be shown.`);
          }
          else {
            const rollbar = this.injector.get(RollbarService);
            rollbar.error(new Error(`version:${version} ${error.message}`).stack);  // this will send off an error to rollbar in all situations. If we want to decrease dev environment ones, consider adding it as an else to the if block below with 'D:'
          }
        }

        return throwError(error);
      })
    );
  }
}
