import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ICompany } from '../models/company.model';
import { IEmployment, IEmploymentLoading } from '../models/employment.model';
import { ISkill } from '../models/skill.model';
import { IUrlMetadata } from '../models/user-activity-metadata.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class EmploymentExperienceService {
  private controller = '/api/Employment/';

  constructor(private http: HttpClient) {}

  getCompaniesFromUrl(url: string): Observable<ICompany> {
    const endpoint = this.controller + `GetCompanyFromUrl/`;
    return this.http.get<ICompany>(endpoint, { params: { url } });
  }

  getEmploymentExperience(): Observable<IEmploymentLoading[]> {
    const endpoint = this.controller + 'GetEmploymentExperience/';
    return this.http.get<IEmploymentLoading[]>(endpoint);
  }

  getEmploymentSkills(goalId: number, employmentId: number, includeAll: boolean): Observable<ISkill[]> {
    const endpoint = this.controller + `GetEmploymentSkills/`;
    return this.http.get<ISkill[]>(endpoint, { params: { goalId: goalId.toString(), employmentId: employmentId.toString(), includeAll: includeAll.toString()} });
  }

  addEmploymentExperience(employmentExperience: IEmployment): Observable<IEmployment> {
    const endpoint = this.controller + 'AddEmploymentExperience/';
    return this.http.post<IEmployment>(endpoint, employmentExperience, httpOptions);
  }

  updateEmploymentExperience(employmentExperience: IEmployment): Observable<IEmployment> {
    const endpoint = this.controller + 'UpdateEmploymentExperience/';
    return this.http.put<IEmployment>(endpoint, employmentExperience, httpOptions);
  }

  getCompanyMetadata(url: string): Observable<IUrlMetadata> {
    const endpoint = `/api/UserActivities/GetMetadata/`;
    return this.http.get<IUrlMetadata>(endpoint, { params: { url } });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  deleteEmploymentExperience(employmentExperience: IEmployment): Observable<{}> {
    const endpoint = this.controller + `${employmentExperience.id}/`;
    return this.http.delete(endpoint, httpOptions);
  }

  getIndustryOptions(): Observable<string[]> {
    const endpoint = this.controller + 'GetAllIndustryOptions/';
    return this.http.get<string[]>(endpoint, httpOptions);
  }
}
