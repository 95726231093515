<div id="providerSection">
  <div class="header-title">
    <h3 class="header">Seed Database</h3>
    <h3 id="brokenGoals" class="header">{{brokenGoalsText}}</h3>
  </div>
  <div class="seed-buttons">
    <h4 class="sub-header">Seed Level Details</h4>
    <div class="button-group">
      <button nz-button nzType="primary" (click)="seedLevelDetails('Entrepreneurship')" [nzLoading]="isLoadingLevelDetails['Entrepreneurship']">Entrepreneurship</button>
      <button nz-button nzType="primary" (click)="seedLevelDetails('ProductManagement')" [nzLoading]="isLoadingLevelDetails['Entrepreneurship']">Product Management</button>
    </div>

    <h4 class="sub-header">Seed Skills</h4>
    <div class="button-group">
      <button nz-button nzType="primary" (click)="seedSkills('Technology')" [nzLoading]="isLoadingGoals['Technology']">Software</button>
      <button nz-button nzType="primary" (click)="seedSkills('Entrepreneurship')" [nzLoading]="isLoadingGoals['Entrepreneurship']">Entrepreneurship</button>
      <button nz-button nzType="primary" (click)="seedDatabaseCategoriesAndIcons()" [nzLoading]="isLoadingCategories">Add Categories</button>
    </div>

    <h4 class="sub-header">Seed Core</h4>
    <div class="button-group">
      <button nz-button nzType="primary" (click)="seedDatabaseProviders()" [nzLoading]="isLoadingProviders">Add Providers</button>
      <button nz-button nzType="primary" (click)="seedDatabaseLearning()" [nzLoading]="isLoadingLearning">Add Learning</button>
      <button nz-button nzType="primary" (click)="seedDatabaseProfiles()" [nzLoading]="isLoadingProfiles">Add Admin</button>
      <button nz-button nzType="primary" (click)="seedActions()" [nzLoading]="isLoadingActions">Add Actions</button>
      <button nz-button nzType="primary" (click)="seedEmailMessages()" [nzLoading]="isLoadingEmailMessages">Add Emails</button>
      <button nz-button nzType="primary" (click)="seedGuilds()" [nzLoading]="isLoadingGuilds">Add Guilds</button>
    </div>

    <h4 class="sub-header">Update and Fix</h4>
    <div>
      <div class="button-group">
        <button nz-button nzType="primary" (click)="updateTracks()" [nzLoading]="isLoadingTracks">Update Tracks</button>
        <button nz-button nzType="primary" (click)="updateSkillsCache()" [nzLoading]="isUpdatingSkillsCache">Refresh TopSkills</button>
        <button nz-button nzType="primary" (click)="providersTransfer()" [nzLoading]="isUpdatingProvidersTransfer">Fix Providers</button>
        <button nz-button nzType="primary" (click)="updateUsernames()" [nzLoading]="isUpdatingUsernames">Fix Username's</button>
        <button nz-button nzType="primary" (click)="fixDuplicateTracks()" [nzLoading]="isFixDuplicateTracks">Fix Duplicate Tracks</button>
        <button nz-button nzType="primary" (click)="detectTrackProblems()" [nzLoading]="isDetectingTrackProblems">Analyse Tracks</button>
        <button nz-button nzType="primary" (click)="markCurrentGuildOwnersAsMembers()" [nzLoading]="isMarkingCurrentGuildOwnersAsMembers">Mark Current Guild Owners as Members</button>
      </div>
      <h4 class="sub-header"></h4>
      <div class="button-group">
        <button nz-button nzType="primary" (click)="cleanEmptySkills()" [nzLoading]="isCleaningEmptySkills">Clean Empty Skills</button>
        <button nz-button nzType="primary" (click)="addMetadataToExisitingActivities()" [nzLoading]="isAddingMetadata">Additional Metadata</button>
        <button nz-button nzType="primary" (click)="cleanEmptyPlaylistActivities()" [nzLoading]="isCleaningEmptyPlaylistActivities">Clean Empty Playlist Activities</button>
        <button nz-button nzType="primary" (click)="cleanUntrimmedSkills()" [nzLoading]="isCleaningUntrimmedSkills">Clean Untrimmed Skills</button>
        <button nz-button nzType="primary" (click)="regenerateTrackDescriptions()" [nzLoading]="isLoadingGenerateTrackDescriptions">Regenerate Track Descriptions</button>
      </div>
      <div class="button-group"></div>
    </div>
    <h4 class="sub-header">Load Recommendations</h4>
    <div class="button-group button-grid">
      <nz-upload class="buttons" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload">
        <button nz-button><i nz-icon nzType="upload"></i>Select File</button>
      </nz-upload>
      <button id="seedrecommendations-soft" nz-button nzType="primary" [nzLoading]="isLoadingRecommendations" (click)="seedRecommendations(professionType.Software)">Software Activities</button>
      <button id="seedrecommendations-entr" nz-button nzType="primary" [nzLoading]="isLoadingRecommendations" (click)="seedRecommendations(professionType.Entrepreneur)">Entrepreneur Activities</button>
      <button id="seedrecommendations-all" nz-button nzType="primary" [nzLoading]="isLoadingRecommendations" (click)="seedRecommendations(professionType.All)">All Known Activities</button>
      <button id="seedrecommendations-file" nz-button nzType="primary" [nzLoading]="isLoadingRecommendations" (click)="seedRecommendations()" [disabled]="fileList.length === 0">{{ isLoadingRecommendations ? "Uploading" : "Add From File" }}</button>
    </div>
  </div>

  <br />
  <div id="provider">
    <h3>SeaLadder has the folowing Providers installed:</h3>
    <div class="providers" *ngFor="let provider of providers">
      <nz-tag [nzColor]="'blue'">{{ provider.name }}</nz-tag>
    </div>
    <br />
  </div>

  <div id="users">
    <h3>SeaLadder registered users:</h3>
    <div class="switch-container">
      <h4>Show deleted users</h4>
      <nz-switch [(ngModel)]="includeDeletedUsers" [nzControl]="true" [nzLoading]="isLoadingIncludeDeleted" nzSize="small" (click)="toggleEnableDeleted()"></nz-switch>
    </div>
    <nz-table nzTemplateMode class="users-table" [nzBordered]="true">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th [attr.colspan]="providers.length">Providers</th>
        </tr>
        <tr>
          <th>#</th>
          <th>User</th>
          <th>Email</th>
          <th>Url</th>
          <th>Status</th>
          <th>Type</th>
          <th>IsTest</th>
          <th>IsAdmin</th>
          <th>IsTrial</th>
          <th>Nuke</th>
          <th>#Goals</th>
          <th>#Activities</th>
          <th *ngFor="let provider of providers">{{ provider.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let userProvider of userProviders">
          <td>{{ getNotTestIndex(userProvider.user) }}</td>
          <td>{{ userProvider.user.name }}</td>
          <td>
            <span nz-tooltip nzTooltipTitle="{{ userProvider.user.id }}">{{ userProvider.user.email }}</span>
          </td>
          <td>
            <a href="/profile/{{ userProvider.user.publicUrl }}">{{ userProvider.user.publicUrl }}</a>
          </td>
          <td>
            <span id="subscription-status-for-{{ userProvider.user.id }}" style="text-transform: capitalize" *ngIf="userProvider.user.stripeUserDetails !== null">
              {{ userProvider.user.subscriptionStatus }}
            </span>
          </td>
          <td>
            <span id="subscription-type-for-{{ userProvider.user.id }}" style="text-transform: capitalize" *ngIf="userProvider.user.stripeUserDetails !== null">
              {{ userProvider.user.subscriptionType }}
            </span>
          </td>
          <td>
            <label id="isTest-label">
              <nz-spin *ngIf="isLoadingRoles.get(userProvider.user.id)" nzSimple [nzSize]="'small'"></nz-spin>
              <input *ngIf="!isLoadingRoles.get(userProvider.user.id)" type="checkbox" [disabled]="userProvider.isTestProtected" [checked]="userProvider.user.isTest" (change)="toggleRole(userProvider.user, 'test')" /> Test
            </label>
          </td>
          <td>
            <label id="isAdmin-label">
              <nz-spin *ngIf="isLoadingRoles.get(userProvider.user.id)" nzSimple [nzSize]="'small'"></nz-spin>
              <input *ngIf="!isLoadingRoles.get(userProvider.user.id)" type="checkbox" [disabled]="userProvider.isAdminProtected" [checked]="userProvider.user.isAdmin" (change)="toggleRole(userProvider.user, 'admin')" /> Admin
            </label>
          </td>
          <td>
            <label id="isTrial-label">
              <nz-spin *ngIf="isLoadingTrial.get(userProvider.user.id)" nzSimple [nzSize]="'small'"></nz-spin>
              <input *ngIf="!isLoadingTrial.get(userProvider.user.id)" type="checkbox" [checked]="userProvider.user.isTrial" (change)="toggleTrial(userProvider.user)" /> Trial
            </label>
          </td>
          <td>
            <div id="isDeleted" *ngIf="!userProvider.user.isAdmin && userProvider.user.id !== '0'">
              <nz-spin *ngIf="isLoadingDeleted.get(userProvider.user.id)" nzSimple [nzSize]="'small'"></nz-spin>
              <div *ngIf="!userProvider.user.isDeleted">
                <div *ngIf="!showDeletedInput.get(userProvider.user.id)">
                  <button (click)="showDeleteInput(userProvider.user)" nz-button nzType="danger" [nzSize]="'small'" nz-tooltip nzTooltipTitle="Delete the user: {{ userProvider.user.email }}" nzShape="circle" class="delete-button">
                    <i nz-icon nzType="delete" nzTheme="outline" style="color: #ff4d4f"></i>
                  </button>
                </div>
                <div *ngIf="showDeletedInput.get(userProvider.user.id)">
                  <nz-input-group [nzSuffix]="suffixTemplate">
                    <input
                      #deleteInput
                      id="deleteInput-{{ userProvider.user.id }}"
                      nz-input
                      type="text"
                      [ngModel]="getDeleteReason(userProvider.user.id)"
                      (ngModelChange)="setDeleteReason(userProvider.user.id, $event)"
                      maxlength="60"
                      style="width: 20em"
                      placeholder="Enter reason (max 60 chars)"
                      (keydown.escape)="this.deleteReasons.set(user.id, ''); showDeletedInput.set(userProvider.user.id, false)"
                    />
                  </nz-input-group>
                  <ng-template #suffixTemplate>
                    <button (click)="confirmDelete(userProvider.user)" nz-button nzType="danger" [nzSize]="'small'" nzShape="circle" [disabled]="!getDeleteReason(userProvider.user.id)">
                      <i nz-icon nzType="check" nzTheme="outline" [style.color]="deleteReasons.get(userProvider.user.id) ? '#ff4d4f' : '#d9d9d9'"></i>
                    </button>
                    <button (click)="showDeletedInput.set(userProvider.user.id, false)" nz-button nzType="danger" [nzSize]="'small'" nzShape="circle">
                      <i nz-icon nzType="close" nzTheme="outline"></i>
                    </button>
                  </ng-template>
                </div>
              </div>
              <div *ngIf="userProvider.user.isDeleted">
                <button (click)="toggleDeleted(userProvider.user)" nz-button nzType="default" [nzSize]="'small'" nzShape="circle" class="undo-button" nz-tooltip nzTooltipTitle="Restore (undelete) the user: {{ userProvider.user.email }}">
                  <i nz-icon nzType="rest" nzTheme="fill" style="color: green"></i>
                </button>
              </div>
            </div>
          </td>
          <td>{{ userProvider.user.numGoals }}</td>
          <td>{{ userProvider.user.numActivities }} ({{ userProvider.user.numActivities - userProvider.user.numActivitiesCompleted }}/{{ userProvider.user.numActivitiesCompleted }})</td>
          <td *ngFor="let provider of providers">
            {{ getProviderLoginName(userProvider.providerSet, provider.name) }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
