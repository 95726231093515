import { IMetricLineChart, IMetricSeriesNameValue } from './../models/metric-name-value.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMetricNameValue } from '../models/metric-name-value.model';
import { EMetricViewType } from '../types/metric-view-type';

@Injectable({
  providedIn: 'root',
})
export class MetricService {
  private endpoint = '/api/Metrics/';

  constructor(private http: HttpClient) { }

  getActiveUserMetric(): Observable<IMetricNameValue[]> {
    const url = this.endpoint + 'GetActiveUserMetric/';
    return this.http.get<IMetricNameValue[]>(url);
  }

  getActiveUserPerMonthMetric(): Observable<IMetricLineChart[]> {
    const url = this.endpoint + 'GetActiveUserPerMonthMetric/';
    return this.http.get<IMetricLineChart[]>(url);
  }

  getUserTypesRatioMetric(): Observable<IMetricNameValue[]> {
    const url = this.endpoint + 'GetUserTypesRatio/';
    return this.http.get<IMetricNameValue[]>(url);
  }

  getUsersGoalLevelMetric(): Observable<IMetricNameValue[]> {
    const url = this.endpoint + 'GetUsersGoalLevelMetric/';
    return this.http.get<IMetricNameValue[]>(url);
  }

  getUserSpecialisationsMetric(): Observable<IMetricNameValue[]> {
    const url = this.endpoint + 'GetUserSpecialisationsMetric/';
    return this.http.get<IMetricNameValue[]>(url);
  }

  getCompletedActivitiesMetric(): Observable<IMetricLineChart> {
    const url = this.endpoint + 'GetCompletedActivitiesMetric/';
    return this.http.get<IMetricLineChart>(url);
  }

  getCompletedUserActivitesMetric(viewType: EMetricViewType, numberOfBins: number): Observable<IMetricLineChart> {
    const url = this.endpoint + 'GetCompletedUserActivitiesMetric/';
    return this.http.get<IMetricLineChart>(url, { params: { viewType: viewType.toString(), numberOfBins: numberOfBins.toString() } });
  }

  getCompletedGoalsMetric(): Observable<IMetricLineChart> {
    const url = this.endpoint + 'GetCompletedGoalsMetric/';
    return this.http.get<IMetricLineChart>(url);
  }

  getUserSubscriptionStatusMetric(monthlyRange: number): Observable<IMetricSeriesNameValue> {
    const url = this.endpoint + 'GetUserSubscriptionStatusMetric/';
    return this.http.get<IMetricSeriesNameValue>(url, { params: { monthlyRange: monthlyRange.toString() } });
  }
}
