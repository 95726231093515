<title>{{title}}</title>
<!-- Disabled for now as this causes a runtime error:
  See: https://stackoverflow.com/questions/52069842/ngif-expression-has-changed-after-it-was-checked-previous-value-ngif-false/52070673

  <ng-template [ngIf]="loadingApplication" [ngIfElse]="loadedApp" class="loader loadingPage vertical-center">
  <div class="vertical-center">
    <h2 id="welcome-title" class="text-primary">Welcome to SeaLadder!</h2><br>
    <h3 id="welcome-umpas" class="text-primary">Our umpa lumpas are just building things for you. <br>
      One moment please.</h3>
  </div>
</ng-template> 

<ng-template #loadedApp class="main-application">
  -->
<nz-layout>
  <div>
    <nz-affix>
      <sl-navbar-side></sl-navbar-side>
    </nz-affix>
  </div>
  <nz-layout class="app-body">
    <nz-content class="app-content">
      <div class="app-content-inner">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer class="app-footer">
      <sl-app-details></sl-app-details>
    </nz-footer>
  </nz-layout>
</nz-layout>
<!-- </ng-template> -->
