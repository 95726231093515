<nz-spin [nzSpinning]="isLoading">
  <div *ngIf="userActivity !== undefined">
    <div class="actions-wrapper">
      <div class="play-pause-complete-wrapper">
        <i class="action-icon fa fa-play" *ngIf="isActivePlay()" nzTooltipTitle="Start" nzTooltipPlacement="top" nz-tooltip (click)="play()"></i>
        <i class="action-icon fa fa-play" *ngIf="isActiveResume()" nzTooltipTitle="Resume" nzTooltipPlacement="top" nz-tooltip (click)="toggleDueDateModalVisibility()" nz-popover [nzPopoverVisible]="confirmDueDateModal.isVisible" nzPopoverTitle="Please confirm planned due date"
          [nzPopoverContent]="confirmDueDateTemplate" [nzPopoverTrigger]="confirmDueDateModal.trigger" (nzPopoverVisibleChange)="confirmDueDateModalChange($event)"></i>
        <i class="action-icon fa fa-pause" *ngIf="isActivePause()" nzTooltipTitle="Pause" nzTooltipPlacement="top" nz-tooltip (click)="toggleResumePaused()"></i>
        <div *ngIf="isActiveHidePublic()">
          <i class="action-icon fa fa-eye" *ngIf="isVisible" nzTooltipTitle="Hide visibility on public profile" nzTooltipPlacement="top" nz-tooltip (click)="toggleVisibility()"></i>
          <i class="action-icon fa fa-eye-slash" *ngIf="!isVisible" nzTooltipTitle="Show visibility on public profile" nzTooltipPlacement="top" nz-tooltip (click)="toggleVisibility()"></i>
        </div>
      </div>
      <div class="complete-wrapper">
        <i id="complete-activity" class="action-icon fa fa-check" *ngIf="isActiveComplete() && !isNoSkills()" nzTooltipTitle="Complete" nzTooltipPlacement="top" nz-tooltip (click)="completeUserActivityBeginFlow()"></i>
        <i class="action-icon fa fa-check danger" *ngIf="isActiveComplete() && isNoSkills()" nzTooltipTitle="Add skills now" nzTooltipPlacement="top" nz-tooltip (click)="showEditModal()"></i>
        <i id="complete-activity-undo" class="action-icon fa fa-undo" *ngIf="isActiveUndoComplete()" nzTooltipTitle="Undo Complete" nzTooltipPlacement="top" nz-tooltip (click)="completeUserElement(false)"></i>
      </div>
      <div class="edit-activity-wrapper">
        <i class="action-icon fa fa-pencil" nzTooltipTitle="Edit Activity" nzTooltipPlacement="top" nz-tooltip (click)="showEditModal()"></i>
      </div>
      <div class="link-wrapper">
        <i id="id-link-icon" class="action-icon fa fa-share" nzTooltipTitle="Share Activity With Others" nzTooltipPlacement="top" nz-tooltip (click)="copyLink()"></i>
      </div>
      <div class="archive-wrapper">
        <i id="id-unarchive-icon" class="action-icon fa fa-trash-restore" *ngIf="isArchive" nzTooltipTitle="Unarchive" nzTooltipPlacement="top" nz-tooltip (click)="toggleArchive()"></i>
        <i id="id-trash-icon" class="action-icon fa fa-archive" *ngIf="!isArchive" nzTooltipTitle="Archive" nzTooltipPlacement="top" nz-tooltip (click)="toggleArchive()"></i>
      </div>
    </div>
  </div>

  <!-- Action buttons -->
  <div *ngIf="userAction !== undefined">
    <div class="userAction-actions-wrapper">
      <div class="userAction-delete-wrapper">
        <i class="userAction-action delete-action fa fa-archive" nzTooltipTitle="Delete" nzTooltipPlacement="top" nz-tooltip (click)="deleteUserElement()"></i>
      </div>
      <div class="userAction-complete-wrapper">
        <i class="action-icon fa fa-check" id="userAction-complete-action" *ngIf="isActiveComplete()" nzTooltipTitle="Complete" nzTooltipPlacement="top" nz-tooltip (click)="completeUserElement(true)"></i>
        <i class="action-icon fa fa-undo" *ngIf="isActiveUndoComplete()" nzTooltipTitle="Undo Complete" nzTooltipPlacement="top" nz-tooltip (click)="completeUserElement(false)"></i>
      </div>
      <div class="edit-action-wrapper">
        <i class="action-icon fa fa-pencil" nzTooltipTitle="Edit Action" nzTooltipPlacement="top" nz-tooltip (click)="showEditActionModal()"></i>
      </div>
      <div class="pause-action-wrapper">
        <i class="action-icon fa fa-play" *ngIf="isActivePlay()" nzTooltipTitle="Start" nzTooltipPlacement="top" nz-tooltip (click)="play()"></i>
        <i class="action-icon fa fa-play" *ngIf="isActiveResume()" nzTooltipTitle="Resume" nzTooltipPlacement="top" nz-tooltip (click)="toggleDueDateModalVisibility()" nz-popover [nzPopoverVisible]="confirmDueDateModal.isVisible" nzPopoverTitle="Please confirm planned due date"
          [nzPopoverContent]="confirmDueDateTemplate" [nzPopoverTrigger]="confirmDueDateModal.trigger" (nzPopoverVisibleChange)="confirmDueDateModalChange($event)"></i>
        <i class="action-icon fa fa-pause" *ngIf="isActivePause()" nzTooltipTitle="Pause" nzTooltipPlacement="top" nz-tooltip (click)="toggleResumePaused()"></i>
        <div *ngIf="isActiveHidePublic()">
          <i class="action-icon fa fa-eye" *ngIf="isVisible" nzTooltipTitle="Hide visibility on public profile" nzTooltipPlacement="top" nz-tooltip (click)="toggleVisibility()"></i>
          <i class="action-icon fa fa-eye-slash" *ngIf="!isVisible" nzTooltipTitle="Show visibility on public profile" nzTooltipPlacement="top" nz-tooltip (click)="toggleVisibility()"></i>
        </div>
      </div>
    </div>
  </div>

  <!-- Playlist Buttons -->
  <div *ngIf="userPlaylist !== undefined">
    <div class="userPlaylist-actions-wrapper">
      <div class="playlist-version-wrapper">
        <nz-tag class="playlist-version-tag" [nzColor]="isPlaylistVersionMostCurrent(userPlaylist)?'default':'processing'" [nz-tooltip]="!isPlaylistVersionMostCurrent(userPlaylist)?'Update playlist to the latest version':'Click to view previous versions of this playlist'"
          (click)="!isPlaylistVersionMostCurrent(userPlaylist)? updatePlaylistToLatestVersion(userPlaylist):null" nz-dropdown nzTrigger="click" [nzDropdownMenu]="isPlaylistVersionMostCurrent(userPlaylist)?menu:null">
          <span *ngIf="userPlaylist.updatingVersion" nz-icon nzType="sync" nzSpin></span>
          <span *ngIf="userPlaylist.playlistVersion===userPlaylist.latestPlaylistVersion">V{{userPlaylist.playlistVersion}}</span>
          <span *ngIf="userPlaylist.playlistVersion!==userPlaylist.latestPlaylistVersion">V{{userPlaylist.playlistVersion}} <span nz-icon nzType="caret-right" nzTheme="outline"></span> V{{userPlaylist.latestPlaylistVersion}}</span>
        </nz-tag>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <div *ngFor="let version of userPlaylist.availablePlaylistVersions">
              <li nz-menu-item [nzSelected]="version === userPlaylist.playlistVersion" (click)="updatePlaylistVersion(userPlaylist,version)">V{{version}}</li>
            </div>
          </ul>
        </nz-dropdown-menu>
      </div>

      <div class="playlist-view-wrapper">
        <i class="playlist-action view-playlist fa fa-pencil" nzTooltipTitle="View/Edit" nzTooltipPlacement="top" nz-tooltip (click)="showPlaylistDetails(userPlaylist.playlistId)"></i>
      </div>
      <div class="playlist-share-wrapper">
        <i class="playlist-action share-playlist fas fa-share" nzTooltipTitle="Share Playlist With Others" nzTooltipPlacement="top" nz-tooltip (click)="copyLink()"></i>
      </div>
      <div class="playlist-delete-wrapper">
        <i *ngIf="!isDeleting" class="playlist-action delete-playlist fa fa-trash-o" nzTooltipTitle="Delete" nzTooltipPlacement="top" nz-tooltip (click)="deleteUserElement()"></i>
        <div *ngIf="isDeleting">
          <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
          <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
        </div>
      </div>
    </div>
  </div>
</nz-spin>

<ng-template #confirmDueDateTemplate>
  <nz-date-picker class="confirm-due-date" [(ngModel)]="confirmDueDateModal.datePlannedDue" [nzDisabledDate]="disabledDates" [nzFormat]="'d MMM y'" [nzAllowClear]="false"></nz-date-picker>
  <button nz-button nzType="primary" (click)="confirmDueDate()">Confirm</button>
</ng-template>

<!-- User Activity Modals and Templates -->
<div *ngIf="userActivity !== undefined">
  <nz-modal [nzVisible]="editActivityMode" [nzTitle]="'Edit Activity'" [nzFooter]="modalFooter" [nzContent]="modalContent" (nzOnCancel)="handleCancel()" nzWidth="60%">
    <ng-template #modalContent>
      <sl-manual-user-activity [selectedActivity]="userActivity" (closeModalEventListener)="handleCancel()" [activityKind]="ActivityKind.UserActivity"></sl-manual-user-activity>
    </ng-template>
    <ng-template #modalFooter></ng-template>
  </nz-modal>

  <nz-modal [(nzVisible)]="newSkillsFound" nzTitle="Relevant Skills Detected" [nzContent]="newSkillsFoundModal" (nzOnCancel)="handleCancel()">
    <ng-template #newSkillsFoundModal>
      <p>We've detected that other people have associated skills with this activity you might be interested in adding too.</p>
      <strong>Skills:</strong>
      <div class="skill-tags" *ngFor="let skill of newSkills">
        <nz-tag id="skill-tag" [nzColor]="'blue'" nzMode="closeable">{{ skill.name }}</nz-tag>
        <br />
      </div>
      <p>Would you like us to add these skills to the activity for you?</p>
    </ng-template>
    <div *nzModalFooter>
      <button id="just-save-button" nz-button nzType="default" (click)="completeUserElement(true)">No, just save</button>
      <button id="add-save-button" nz-button nzType="primary" (click)="acceptNewSkills()">Yes, add and save</button>
    </div>
  </nz-modal>
</div>

<!-- View Playlist Modal -->
<div *ngIf="userPlaylist !== undefined">
  <nz-modal id="view-playlist-modal" [(nzVisible)]="showPlaylistInfo" [nzTitle]="userPlaylist.playlistTitle" [nzFooter]="modalFooter" [nzContent]="modalContent" (nzOnCancel)="handleHidePlaylistDetails()" (nzOnOk)="handleHidePlaylistDetails()" nzWidth="60%">
    <ng-template #modalContent>
      <sl-playlist-viewer *ngIf="showPlaylistInfo" [playlist]="playlistFromUserPlaylist" [userPlaylist]="userPlaylist" [goalSkills]="goalSkills" [goalId]="goalId"></sl-playlist-viewer>
    </ng-template>
  </nz-modal>
</div>

<!-- UserAction Modals and Templates -->
<div *ngIf="userAction !== undefined">
  <nz-modal [nzVisible]="editActionMode" nzTitle="Edit Action" [nzFooter]="modalFooter" [nzContent]="modalContent" (nzOnCancel)="handleEditActionCancel()" nzWidth="60%">
    <ng-template #modalContent>
      <sl-manual-user-activity [selectedActivity]="userAction" (closeModalEventListener)="handleEditActionCancel()" [activityKind]="ActivityKind.UserAction"></sl-manual-user-activity>
    </ng-template>
    <ng-template #modalFooter></ng-template>
  </nz-modal>
</div>

<div id="unsubscribed-modal">
  <nz-modal [nzVisible]="isUnsubscribedModal" nzTitle="Would you like to subscribe?" [nzFooter]="modalFooter" nzWidth="40%">
    <ng-container *nzModalContent>
      <p>We love that you're progressing through activities. <br> But if you would like to continue from here, we kindly ask you to subscribe.
      </p>
      <p>Thanks in advance</p>
    </ng-container>
    <ng-template #modalFooter>
      <button id="unsubscribed-modal-cancel-button" nz-button nzType="default" (click)="handleSubscriptionModalCancel()">Cancel</button>
      <button id="unsubscribed-modal-redirect-button" nz-button nzType="primary" (click)="handleSubscriptionModalOk()">Subscribe</button>
    </ng-template>
  </nz-modal>
</div>