<h1 id="admin-header">Admin Portal</h1>
<nz-tabset nzType="card">
  <nz-tab nzTitle="Seed Database">
    <ng-template nz-tab>
      <sl-seed-database></sl-seed-database>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Metrics">
    <ng-template nz-tab>
      <sl-metrics></sl-metrics>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Invitation Code">
    <ng-template nz-tab>
      <sl-invitation-code></sl-invitation-code>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="User Activity">
    <ng-template nz-tab>
      <sl-user-activity></sl-user-activity>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Edit Skills">
    <ng-template nz-tab>
      <sl-uncategorised-skills></sl-uncategorised-skills>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Tracks">
    <ng-template nz-tab>
      <sl-approve-tracks></sl-approve-tracks>
      <sl-track-history></sl-track-history>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Notifications">
    <ng-template nz-tab>
      <sl-notifications></sl-notifications>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Email Messages">
    <ng-template nz-tab>
      <sl-email-messages></sl-email-messages>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="TopSkills">
    <ng-template nz-tab>
      <sl-all-user-top-skills></sl-all-user-top-skills>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Playlists">
    <ng-template nz-tab>
      <sl-playlists></sl-playlists>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Actions">
    <ng-template nz-tab>
      <sl-actions></sl-actions>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Guilds">
    <ng-template nz-tab>
      <sl-guilds-admin></sl-guilds-admin>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Skill Duplicates">
    <ng-template nz-tab>
      <sl-skillduplicates></sl-skillduplicates>
    </ng-template>
  </nz-tab>
  <nz-tab nzTitle="Stripe Reconciliation">
    <ng-template nz-tab>
      <sl-stripe-reconciliation></sl-stripe-reconciliation>
    </ng-template>
  </nz-tab>
</nz-tabset>