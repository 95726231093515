/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Observable, Observer } from 'rxjs';

// Libraries
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';

// Services
import { AuthService } from 'src/app/services/auth.service';
import { SuggestedActionService } from 'src/app/services/suggested-action.service';

// models
import { EditMode } from 'src/app/generic-components/types/edit-mode.type';
import { ISuggestedAction } from 'src/app/models/suggested-action.model';
import { ITrack } from 'src/app/models/track.model';

@Component({
  selector: 'sl-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.less'],
})
export class ActionsComponent implements OnInit {
  public actionCreated!: ISuggestedAction;
  public actionTitleEntered = false;
  public actionTitleFieldValid = false;
  public industrySelected = false;
  public professionSelected: boolean | undefined = undefined;
  public specialisationSelected: boolean | undefined = undefined;
  public trackSelected = false;
  public levelFromSelected = false;
  public levelToSelected = false;
  public actionDescriptionEntered = false;
  public actionDescriptionFieldValid = false;

  public stepIndex = 0;
  public mode: EditMode = EditMode.new;
  public modeType = EditMode; // hack to get around the limitations of JavaScript when it comes to enums; https://marco.dev/enums-angular
  public actionsExisting: ISuggestedAction[] | undefined = undefined;
  public actionExistingSelectedId: number | undefined;
  public isVisibleExisting = false;
  public loading: { [index: string]: boolean } = {};
  public isSubmittingActions = false;
  public isLoadingRequest = false;
  public isLoadingImage = false;
  public imageUrl?: string;
  public imageContainerSelected = true;

  // See: https://netbasal.com/typed-reactive-forms-in-angular-no-longer-a-type-dream-bf6982b0af28 Bonus section
  public actionGroup = this.fb.group({
    title: '',
    description: '',
    imageUrl: '',
  });

  constructor(private fb: NonNullableFormBuilder, private authService: AuthService, private message: NzMessageService, private suggestedActionService: SuggestedActionService, private modal: NzModalService) { }

  ngOnInit(): void {
    this.actionCreated = {} as ISuggestedAction;

    // eslint-disable-next-line guard-for-in
    for (const control in this.actionGroup.controls) {
      this.actionGroup.controls[control].updateValueAndValidity();
    }
  }

  onStepIndexChange(index: number): void {
    this.stepIndex = index;
  }

  checkIfActionNameExists(): boolean {
    while (this.actionGroup.get('title')?.value === null || this.actionGroup.get('title')?.value === '') {
      this.actionTitleFieldValid = false;
      return false;
    }

    this.actionTitleFieldValid = true;
    this.actionTitleEntered = true;

    return true;
  }

  checkIfActionDescriptionExists(): boolean {
    if (this.actionGroup.get('description')?.value === null || this.actionGroup.get('description')?.value === '') {
      return false;
    }
    this.actionDescriptionFieldValid = true;
    this.actionDescriptionEntered = true;
    return true;
  }

  checkIfActionImageExists(): boolean {
    if (this.actionGroup.get('imageUrl')?.value === null || this.actionGroup.get('imageUrl')?.value === '') {
      return false;
    }
    return true;
  }

  initSelectedIndustry(industry: string) {
    if (!industry) {
      this.resetMainSteps();
      return;
    }
    this.industrySelected = true;
    this.actionCreated.industry = industry;
  }

  initSelectedProfession(profession: string) {
    if (!profession) {
      this.resetMainSteps();
      return;
    } else {
      this.specialisationSelected = false;
      this.trackSelected = false;
      if (this.actionCreated.specialisation !== 'All') {
        this.actionCreated.specialisation = '';
      }
      if (this.actionCreated.relatedTrackName !== 'All') {
        this.actionCreated.relatedTrackName = '';
      }
      // handle the edge case where we previously had lowercase all in the database
      if (profession === 'all') {
        profession = 'All';
      }

      this.professionSelected = true;
      this.actionCreated.profession = profession;
    }
  }

  initSelectedSpecialisation(specialisation: string) {
    if (specialisation) {
      this.specialisationSelected = true;
    }
    this.actionCreated.specialisation = specialisation;
  }

  initSelectedLevelFrom(levelFrom: number) {
    if (levelFrom === -1) {
      this.levelFromSelected = false;
      this.levelToSelected = false;
      this.trackSelected = false;
      return;
    }
    this.levelFromSelected = true;
    this.actionCreated.levelFrom = levelFrom;
  }

  initSelectedLevelTo(levelTo: number) {
    if (levelTo === -1) {
      this.levelToSelected = false;
      this.trackSelected = false;
      return;
    }
    this.levelToSelected = true;
    this.actionCreated.levelTo = levelTo;
  }

  initSelectedTrack(track: ITrack) {
    this.initSelectedTrackName(track.name);
  }

  initSelectedTrackName(trackName: string) {
    if (!trackName) {
      this.trackSelected = false;
      throw new Error('Not cool to pass an undefined track into the initialisation of the track');
    }
    this.trackSelected = true;
    this.actionCreated.relatedTrackName = trackName;
  }

  validateAllStepInputs(): boolean {
    return this.checkIfActionNameExists() && this.industrySelected && this.trackSelected && this.levelFromSelected;
  }

  public showModal(): void {
    if (!this.actionsExisting) {
      this.initExisting();
    }

    this.isVisibleExisting = true;
  }

  public handleOk(): void {
    if (!this.actionExistingSelectedId) {
      throw new Error('Action MUST be selected by now or I honestly have no idea what is going on here.');
    }

    this.updateFormWithValuesFromActionId(this.actionExistingSelectedId);
    this.isVisibleExisting = false;
  }

  public handleCancel(): void {
    this.isVisibleExisting = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) =>
    new Observable((observer: Observer<boolean>) => {
      const isSupportedType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
      if (!isSupportedType) {
        this.message.error('You can only upload JPG, PNG or SVG files.');
        observer.complete();
        return;
      }
      let isSupportedSize;
      if (file.size) {
        isSupportedSize = file.size / 1024 / 1024 < 0.2;
      }
      if (!isSupportedSize) {
        this.message.error('Image must be smaller than 200KB!');
        observer.complete();
        return;
      }
      observer.next(isSupportedType && isSupportedSize);
      observer.complete();
    });

  uploadActionImage = (item: NzUploadXHRArgs) => {
    this.isLoadingImage = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileToUpload = item.file as any;
    const reader = new FileReader();
    reader.readAsDataURL(fileToUpload);
    reader.onload = () => {
      this.actionGroup.patchValue({ imageUrl: reader.result?.toString() });
      this.imageUrl = reader.result?.toString();
      this.isLoadingImage = false;
    };
  };

  public checkForExistingImage(): boolean {
    while (this.actionGroup.get('imageUrl')?.value === null || this.actionGroup.get('imageUrl')?.value === '') {
      return false;
    }
    return true;
  }

  public selectImageInputBar(): void {
    this.imageContainerSelected = false;
    this.imageUrl = this.actionGroup.get('imageUrl')?.value;
  }

  public selectImageContainer(): void {
    this.imageContainerSelected = true;
    if (this.actionGroup.get('imageUrl')?.value === null) {
      this.actionGroup.controls.imageUrl.reset();
    }
  }

  public getImageUrlFromInputBar(): string {
    this.isLoadingImage = false;
    const selectedImageUrl = this.actionGroup.get('imageUrl')?.value as string; // TODO: Sam: This is a hacky way to get the image url
    return selectedImageUrl;
  }

  public getImageUrlFromImageContainer(): string {
    this.isLoadingImage = false;
    const selectedImageUrl = this.imageUrl;
    return selectedImageUrl!;
  }

  public invalidImageUrl(image: Event): void {
    (image.target as HTMLImageElement).style.color = 'red';
    (image.target as HTMLImageElement).alt = 'Invalid Url';
  }

  public submitAction(): void {
    if (!this.validateAllControls() && !this.validateAllStepInputs()) {
      console.error('Action controls are not valid and somehow we got to the point where they are submitting the Action');
      throw new Error('Action must be valid before submitting');
    }

    if (!this.actionGroup.getRawValue().description) {
      this.modal.info({
        nzTitle: '<i>Are you sure you want to create an action without a description?</i>',
        nzContent: 'The description is your opportunity to convey to people who this action is for, what it covers and why people might want to do it.',
        nzOnOk: () => {
          if (this.mode === EditMode.new) {
            this.addSuggestedAction();
          }
        },
        nzOkText: 'Yes',
        nzCancelText: 'No',
      });
    } else {
      if (this.mode === EditMode.new) {
        this.addSuggestedAction();
        return;
      } else {
        throw new Error(`We must be in 'new' mode in order to create an action`);
      }
    }
  }

  public submitActionUpdates(): void {
    if (this.mode !== EditMode.edit) {
      throw new Error('We must be in Edit mode in order to submit changes to actions.');
    }
    if (!this.validateAllControls() && !this.validateAllStepInputs()) {
      throw new Error('The action controls are not valid and somehow we got to the point where they are updating the action');
    }
    if (!this.actionExistingSelectedId) {
      throw new Error('We must have a valid action id when in Edit mode.');
    }

    if (this.actionCreated.relatedTrackName === 'All') {
      this.actionCreated.relatedTrackName = '';
    }

    const actionFromForm = this.updateExistingActionFromForm(this.actionExistingSelectedId);

    if (!this.actionGroup.getRawValue().description) {
      this.modal.info({
        nzTitle: '<i>Are you sure you want to update an action without a description?</i>',
        nzContent: 'The description is your opportunity to convey to people who this action is for, what it covers and why people might want to do it.',
        nzOnOk: () => {
          this.message.info(`One sec, we're just updating the action you just made changes to.`, { nzDuration: 3500 });
          this.submitActionUpdatesMetadata(actionFromForm);
        },
        nzOkText: 'Yes',
        nzCancelText: 'No',
      });
    } else {
      this.message.info(`One sec, we're just updating the action you just made changes to.`, { nzDuration: 3500 });
      this.submitActionUpdatesMetadata(actionFromForm);
    }
  }

  public validateAllControls(): boolean {
    if (this.actionGroup && this.actionGroup.controls) {
      // eslint-disable-next-line guard-for-in
      for (const i in this.actionGroup.controls) {
        this.actionGroup.controls[i].markAsDirty();
        this.actionGroup.controls[i].updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    }

    return this.actionGroup.valid;
  }

  private addSuggestedAction() {
    this.actionCreated.title = this.actionGroup.getRawValue().title;
    this.actionCreated.description = this.actionGroup.getRawValue().description;
    this.actionCreated.imageUrl = this.actionGroup.getRawValue().imageUrl;
    this.actionCreated.createdBy = this.authService.userId;
    this.isLoadingRequest = true;
    if (this.actionCreated.relatedTrackName === 'All') {
      this.actionCreated.relatedTrackName = '';
    }

    this.suggestedActionService.addSuggestedAction(this.actionCreated).subscribe({
      next: (response) => {
        this.message.success(`Nice work, the action '${response.title}' is available`);
        this.resetAllFields();
        this.isLoadingRequest = false;
      },
      error: (error: HttpErrorResponse) => {
        this.message.error(`Error, could not create action`);
        console.error(`Could not create action, Error: ${error.message}`);
        this.isLoadingRequest = false;
      },
    });
  }

  private updateFormWithValuesFromActionId(existingActionId: number) {
    const action = this.actionsExisting?.find((act) => act.id === existingActionId);

    if (!action) {
      throw new Error('Action MUST be selected by now or I honestly have no idea what is going on here.');
    }

    if (action) {
      this.mode = EditMode.edit;

      // Go and set the industry, profession, specialisation, level range, and related track
      this.updateFormWithValuesFromAction(action);
    }
  }

  private updateFormWithValuesFromAction(action: ISuggestedAction) {
    const relatedTrackName = action.relatedTrackName ? action.relatedTrackName : 'All';
    const specialisation = action.specialisation && action.specialisation !== 'all' ? action.specialisation : 'All';
    const profession = action.profession && action.specialisation !== 'all' ? action.profession : 'All';

    this.actionGroup.patchValue({ title: action.title, description: action.description, imageUrl: action.imageUrl });
    this.initSelectedIndustry(action.industry);
    this.initSelectedProfession(profession);
    this.initSelectedSpecialisation(specialisation);
    this.initSelectedLevelFrom(action.levelFrom);
    this.initSelectedLevelTo(action.levelTo);
    this.initSelectedTrackName(relatedTrackName);
    this.selectImageInputBar();
  }

  private initExisting() {
    this.loading.existing = true;
    this.suggestedActionService.getActionsCreated().subscribe(
      (ret) => {
        this.actionsExisting = ret;
        this.actionExistingSelectedId = undefined;

        if (!this.actionsExisting || this.actionsExisting.length === 0) {
          this.message.info('It seems like there are no existing actions');
        }

        this.loading.existing = false;
      },
      (err) => {
        console.error(err);
        this.message.error(`Oh dear, this is embarassing. We weren't able to get the actions. Check your network connection and try again in a few moments`, { nzDuration: 5000 });
        this.loading.existing = false;
      }
    );
  }

  private resetAllFields() {
    this.actionGroup.reset();
    this.actionTitleEntered = false;
    this.industrySelected = false;
    this.professionSelected = undefined;
    this.specialisationSelected = undefined;
    this.levelFromSelected = false;
    this.levelToSelected = false;
    this.trackSelected = false;
  }

  private resetMainSteps() {
    this.professionSelected = undefined;
    this.specialisationSelected = undefined;
    this.levelFromSelected = false;
    this.levelToSelected = false;
    this.trackSelected = false;
  }

  private updateExistingActionFromForm(actionId: number): ISuggestedAction {
    const action = this.actionsExisting?.find((act) => act.id === actionId);

    if (undefined === action) {
      throw new Error('Action MUST be selected by now or I honestly have no idea what is going on here.');
    }

    if (action) {
      action.title = this.actionGroup.getRawValue().title;
      action.industry = this.actionCreated.industry;
      action.profession = this.actionCreated.profession;
      action.specialisation = this.actionCreated.specialisation;
      action.levelFrom = this.actionCreated.levelFrom;
      action.levelTo = this.actionCreated.levelTo;
      action.relatedTrackName = this.actionCreated.relatedTrackName;
      action.description = this.actionGroup.getRawValue().description;
      action.imageUrl = this.actionGroup.getRawValue().imageUrl;
    }

    return action;
  }

  private submitActionUpdatesMetadata(actionFromForm: ISuggestedAction) {
    const action = this.actionsExisting?.find((act) => act.id === this.actionExistingSelectedId);
    if (!action) {
      console.error('Action id doesn\'t exist in the list of known ones somehow.');
      throw new Error('There really has to be a valid action at this point or the world has gone absolutely bonkers.');
    }

    actionFromForm.id = this.actionExistingSelectedId as number;
    this.isSubmittingActions = true;

    this.suggestedActionService.updateActionMetaData(actionFromForm).subscribe(
      (res: ISuggestedAction) => {
        this.message.success(`Nice work, the action '${res.title}' is updated and available!`);
        Object.assign(action, res);

        if (this.actionsExisting) {
          const i = this.actionsExisting?.findIndex((act) => act.id === res.id);
          this.actionsExisting[i] = action;
        }
        this.resetAllFields();
        this.isSubmittingActions = false;
      },
      (err: HttpErrorResponse) => {
        console.error(`Failed to update the action. Error: ${err.message}`);
        this.message.error('The attempt to update the Action failed. Perhaps try again.', { nzDuration: 5000 });
        this.isSubmittingActions = false;
      }
    );
  }
}
