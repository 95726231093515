/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// models
import { ITrack } from '../../../models/track.model';
import { ISkill } from '../../../models/skill.model';

// components
import { GoalSkillsComponent } from './goal-skills/goal-skills.component';
import { GoalCoreSkillsComponent } from './goal-core-skills/goal-core-skills.component';

import { Component, OnInit, Input, ViewChildren, QueryList, Renderer2, Output, EventEmitter } from '@angular/core';

// service
import { GoalService } from 'src/app/services/goal.service';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { TrackService } from 'src/app/services/track.service';
import { NzMessageService } from 'ng-zorro-antd/message';

interface TrackSkills {
  trackId: number;
  trackName: string;
  skills: { [category: string]: ISkill[] };
}

@Component({
  selector: 'sl-goal-track',
  templateUrl: './goal-track.component.html',
  styleUrls: ['./goal-track.component.less'],
})
export class GoalTrackComponent implements OnInit {
  @Output() updateTrackData = new EventEmitter();
  @Input() allGoalSkills: { [category: string]: ISkill[] } = {};
  @Input() track!: ITrack;
  @Input() goalSkills!: ISkill[];
  @Input() selectedTrack: string | null = null;
  @Input() goalLevel!: number;
  @Input() goalIndustry!: string;
  @Input() goalProfession!: string;
  @ViewChildren(GoalSkillsComponent) skillSelectors!: QueryList<GoalSkillsComponent>;
  @ViewChildren(GoalCoreSkillsComponent) coreSkillSelectors!: QueryList<GoalCoreSkillsComponent>;

  isLoading = false;
  objectKeys = Object.keys;
  categorySkills: TrackSkills = { skills: {} } as TrackSkills;
  skillsLoaded = false;
  searchOptions = [];
  selectedMultipleOption = [];
  addingNewCategory = true;
  newCategoryName = '';
  addCategoryButtonVisible = false;
  categories: string[] = [];
  categorySuggestions: string[] = [];
  filteredCategorySuggestions: string[] = [];
  selectableCoreSkills: { skill: ISkill; hidden: boolean }[] = [];
  coreSkillsCount = 5;
  categoryDescription!: string;
  isGeneratingCategoryDescription = false;
  isGeneratingCategorySkillDescription = false;
  readonly defaultTrackImage = 'assets/img/default_track_image.png';

  constructor(private goalService: GoalService, private renderer: Renderer2, private trackService: TrackService, private message: NzMessageService) { }

  ngOnInit() {
    this.initialiseSkills();
    this.sortCategoriesAlphabetically();
  }

  handleUpdatingTrackData() {
    this.updateTrackData.emit();
  }

  initialiseSkills(): void {
    let distinctSkillList = [] as ISkill[];
    const skillsFromTrack = [] as ISkill[];
    for (const element of this.track.recommendedSkills) {
      const skill = element.skill;
      skill.coreSkill = element.coreSkill;
      skillsFromTrack.push(element.skill);
    }

    this.categorySkills.trackId = this.track.id;
    this.categorySkills.trackName = this.track.name;

    if (this.editingGoal()) {
      const selectedGoalSkills = this.goalSkills;
      if (selectedGoalSkills.length !== 0) {
        selectedGoalSkills.filter((goalSkill) => !skillsFromTrack.includes(goalSkill));
      }
      distinctSkillList = selectedGoalSkills;
    } else {
      distinctSkillList.push(...skillsFromTrack);
    }
    if (distinctSkillList.length > 0) {
      this.addCategoryButtonVisible = true;
      this.addingNewCategory = false;
    }
    for (const category of Object.keys(this.categorySkills.skills)) {
      this.categorySkills.skills[category].length = 0;
    }
    for (const skill of distinctSkillList) {
      if (this.categorySkills.skills[skill.category] === undefined) {
        this.categorySkills.skills[skill.category] = [];
      }
      if (this.categorySkills.skills[skill.category].includes(skill) !== true) {
        this.categorySkills.skills[skill.category].push(skill);
      }
    }
    this.getCatagories();
    this.skillsLoaded = true;
  }

  getSelectorSkills(): ISkill[] {
    const allSkills: ISkill[] = [];

    this.skillSelectors.forEach((selector) => {
      allSkills.push(...selector.getSelectedSkills());
    });

    allSkills.forEach((skill) => {
      skill.coreSkill = this.coreSkillSelectors.some((coreSkillSelector) => coreSkillSelector.selectedValue === skill.name);
    });

    return allSkills;
  }

  getSelectedSkillsForCategory(categoryName: string): ISkill[] {
    let skillsForCategory: ISkill[] = [];

    this.skillSelectors.forEach((skillSelector: GoalSkillsComponent) => {
      if (skillSelector.category === categoryName) {
        skillsForCategory = skillSelector.getSelectedSkills();
      }
    });

    return skillsForCategory;
  }

  getTrackImage(imageUrl: string): string {
    if (!imageUrl) {
      return this.defaultTrackImage;
    } else {
      return imageUrl;
    }
  }

  addCategory(): void {
    this.addingNewCategory = true;
    setTimeout(() => {
      this.renderer.selectRootElement('.adding-new-category-field').focus();
    }, 400);
    this.addCategoryButtonVisible = false;
    if (this.categorySuggestions.length === 0 && this.goalIndustry && this.goalProfession && this.goalLevel) {
      this.isLoading = true;
      this.goalService.getSuggestedCategorySkills(this.goalIndustry, this.goalProfession, 'Level ' + this.goalLevel.toString()).subscribe((ret: ISkill[]) => {
        ret.forEach((s) => {
          this.isLoading = false;
          if (this.categorySuggestions.indexOf(s.category) === -1 && this.categories.indexOf(s.category) === -1) {
            this.categorySuggestions.push(s.category);
          }
          if (this.allGoalSkills[s.category] === undefined) {
            this.allGoalSkills[s.category] = [];
            this.allGoalSkills[s.category].push(s);
          } else {
            this.allGoalSkills[s.category].push(s);
          }
        });
        this.categorySuggestions.sort((a: string, b: string) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });
        this.filteredCategorySuggestions = this.categorySuggestions;
      });
    }
  }

  removeCategory(categoryName: string): void {
    const foundCategoryIndex = this.categories.findIndex((c) => c === categoryName);
    if (foundCategoryIndex !== -1) {
      const valuesToRemove = this.selectableCoreSkills.filter((coreSkillChoice) => coreSkillChoice.skill.category === categoryName).map(filteredSkill => filteredSkill.skill.name);
      this.clearUnavailableCoreSkillValue(valuesToRemove);
      this.selectableCoreSkills = this.selectableCoreSkills.filter((coreSkillChoice) => coreSkillChoice.skill.category !== categoryName);
      this.categories.splice(foundCategoryIndex, 1);
    }
  }

  generateCategoryDescription(categoryName: string) {
    this.isGeneratingCategoryDescription = true;
    this.categoryDescription = '';

    if (categoryName) {
      this.categorySkills.skills[categoryName] = this.getSelectedSkillsForCategory(categoryName);

      if (this.track.id) {
        this.generateCategoryDescriptionWithTrackId(this.track.id, categoryName);
      } else {
        this.generateCategoryDescriptionWithTrackName(this.track.name, categoryName);
      }
    } else {
      this.message.error(`Hmm...we couldn't generate the description of the skills for the category: ${categoryName}. Try again later?`);
      this.isGeneratingCategorySkillDescription = false;
      this.isGeneratingCategoryDescription = false;
    }
  }

  generateCategoryDescriptionWithTrackId(trackId: number, categoryName: string) {
    const cachedDescription = this.trackService.getTrackSkillCategoryDescriptionById(trackId, categoryName);

    if (cachedDescription) {
      this.categoryDescription = cachedDescription.trackSkillCategoryDescription;
      this.generateCategorySkillDescriptionById(categoryName);
      this.isGeneratingCategoryDescription = false;
    } else {
      this.trackService.generateTrackSkillCategoryDescriptionById(this.track.id, categoryName).subscribe({
        next: (res) => {
          this.categoryDescription = res;
          this.generateCategorySkillDescriptionById(categoryName);
          this.isGeneratingCategoryDescription = false;
        },
        error: (error) => {
          console.error(`Could not generate description for ${categoryName}.`, error);
          this.message.error(`Hmm...we couldn't generate the description for the category: ${categoryName}. Try again later?`);
          this.isGeneratingCategoryDescription = false;
          this.isGeneratingCategorySkillDescription = false;
        }
      })
    }
  }

  generateCategoryDescriptionWithTrackName(trackName: string, categoryName: string) {
    const cachedDescription = this.trackService.getTrackSkillCategoryDescriptionByName(trackName, categoryName);

    if (cachedDescription) {
      this.categoryDescription = cachedDescription.trackSkillCategoryDescription;
      this.generateCategorySkillDescriptionByName(trackName, categoryName);
      this.isGeneratingCategoryDescription = false;
    } else {
      this.trackService.generateTrackSkillCategoryDescriptionByName(trackName, categoryName, this.track.industry, this.track.profession, this.track.specialisation, this.track.level).subscribe({
        next: (res) => {
          this.categoryDescription = res;
          this.generateCategorySkillDescriptionByName(trackName, categoryName);
          this.isGeneratingCategoryDescription = false;
        },
        error: (error) => {
          console.error(`Could not generate description for ${categoryName}.`, error);
          this.message.error(`Hmm...we couldn't generate the description for the category: ${categoryName}. Try again later?`);
          this.isGeneratingCategoryDescription = false;
          this.isGeneratingCategorySkillDescription = false;
        }
      })
    }
  }

  generateCategorySkillDescriptionById(categoryName: string) {
    this.isGeneratingCategorySkillDescription = true;
    if (this.categorySkills.trackId === this.track.id) {
      if (this.categorySkills.skills[categoryName].length) {
        this.categorySkills.skills[categoryName].forEach(skill => {
          if (skill.name) {
            const cachedDescription = this.trackService.getTrackSkillDescriptionById(this.track.id, skill.name);

            if (cachedDescription) {
              skill.description = cachedDescription.trackSkillDescription;
              this.isGeneratingCategorySkillDescription = false;
            } else {
              this.trackService.generateTrackSkillDescriptionById(this.track.id, skill.name, categoryName).subscribe({
                next: (res) => {
                  skill.description = res;
                  if (this.categorySkills.skills[categoryName].every(skill => skill.description)) {
                    this.isGeneratingCategorySkillDescription = false;
                  }
                },
                error: (error) => {
                  console.error(`Could not generate description for ${skill.name}.`, error);
                  this.message.error(`Hmm...we couldn't generate the description for the skill: ${skill.name}. Try again later?`);
                  this.isGeneratingCategorySkillDescription = false;
                  this.isGeneratingCategoryDescription = false;
                }
              })
            }
          } else {
            this.isGeneratingCategorySkillDescription = false;
          }
        })
      } else {
        this.isGeneratingCategorySkillDescription = false;
      }
    } else {
      console.error(`Tge track with id: ${this.track.id} was not found`);
      this.message.error(`Hmm...we couldn't generate the description of the skills for the category: ${categoryName}. Try again later?`);
      this.isGeneratingCategorySkillDescription = false;
      this.isGeneratingCategoryDescription = false;
    }
  }

  generateCategorySkillDescriptionByName(trackName: string, categoryName: string) {
    this.isGeneratingCategorySkillDescription = true;
    if (this.categorySkills.trackName === trackName) {
      if (this.categorySkills.skills[categoryName].length) {
        this.categorySkills.skills[categoryName].forEach(skill => {
          if (skill.name) {
            const cachedDescription = this.trackService.getTrackSkillDescriptionByName(trackName, skill.name);

            if (cachedDescription) {
              skill.description = cachedDescription.trackSkillDescription;
              this.isGeneratingCategorySkillDescription = false;
            } else {
              this.trackService.generateTrackSkillDescriptionByName(trackName, skill.name, categoryName, this.track.industry, this.track.profession, this.track.specialisation, this.track.level).subscribe({
                next: (res) => {
                  skill.description = res;
                  if (this.categorySkills.skills[categoryName].every(skill => skill.description)) {
                    this.isGeneratingCategorySkillDescription = false;
                  }
                },
                error: (error) => {
                  console.error(`Could not generate description for ${skill.name}.`, error);
                  this.message.error(`Hmm...we couldn't generate the description for the skill: ${skill.name}. Try again later?`);
                  this.isGeneratingCategorySkillDescription = false;
                  this.isGeneratingCategoryDescription = false;
                }
              })
            }
          } else {
            this.isGeneratingCategorySkillDescription = false;
          }
        })
      } else {
        this.isGeneratingCategorySkillDescription = false;
      }
    } else {
      console.error(`The track with name: ${this.track.name} was not found`);
      this.message.error(`Hmm...we couldn't generate the description of the skills for the category: ${categoryName}. Try again later?`);
      this.isGeneratingCategorySkillDescription = false;
      this.isGeneratingCategoryDescription = false;
    }
  }

  clearUnavailableCoreSkillValue(valueToRemove: string[]): void {
    this.coreSkillSelectors.forEach((selector) => {
      selector.clearUnavailableCoreSkillValue(valueToRemove);
    });
  }

  clearSelectedCoreSkillValue(currentComponent: NzSelectComponent): void {
    this.coreSkillSelectors.forEach((selector) => {
      selector.clearSelectedCoreSkillValue(currentComponent);
    });
  }

  addCoreSkillSelector(): void {
    if (this.coreSkillsCount < 7) {
      this.coreSkillsCount++;
    }
  }

  initializePreSelectedCoreSkills(index: number): string {
    let recommendedCoreSkills: ISkill[] = [];

    if (this.editingGoal()) {
      recommendedCoreSkills = this.goalSkills.filter((skill) => skill.coreSkill === true);
    }
    else {
      for (const key in this.categorySkills.skills) {
        if (Object.prototype.hasOwnProperty.call(this.categorySkills.skills, key)) {
          recommendedCoreSkills.push(...this.categorySkills.skills[key]);
        }
      }
      recommendedCoreSkills = recommendedCoreSkills.filter((skill) => skill.coreSkill === true);
    }

    if (index < recommendedCoreSkills.length) {
      return recommendedCoreSkills[index].name;
    }
    else {
      return '';
    }
  }

  onBlur() {
    if (this.newCategoryName.trim() !== '') {
      this.addingNewCategory = false;
      this.addCategoryButtonVisible = true;
      this.categorySkills.skills[this.newCategoryName] = [];
      this.categories.push(this.newCategoryName);
      this.newCategoryName = '';
      this.filteredCategorySuggestions = this.categorySuggestions;
    }
  }

  onChange(value: string): void {
    this.filteredCategorySuggestions = this.categorySuggestions.filter((option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public highlightCategoryInterface(categoryUI: HTMLDivElement) {
    categoryUI.classList.add('red-border');
  }

  // note that this method might not be needed. Also, I generally don't think it's a good idea to have the model deliberately removing classes from a view. Rather, it should set a property and the class should be conditional in the template.
  public removeCategoryHighlight(categoryUI: HTMLDivElement) {
    categoryUI.classList.remove('red-border');
  }

  private getCatagories(): void {
    const categories: string[] = [];
    for (const c of Object.keys(this.categorySkills.skills)) {
      if (c) {
        categories.push(c);
      }
    }
    this.categories = categories;
  }

  private editingGoal(): boolean {
    return this.goalSkills.length !== 0;
  }

  private sortCategoriesAlphabetically() {
    this.categories.sort((a: string, b: string) => (a > b ? 1 : -1));
  }
}
