/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable()
export class RegisterService {
  registered = false;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  city: string | undefined;
  profession: string | undefined;

  constructor(private http: HttpClient) {}

  get isRegistered(): boolean {
    return this.registered;
  }

  register(formValues: UntypedFormGroup): Observable<Object> {
    const body = { email: formValues.value.email, firstName: formValues.value.firstName, lastName: formValues.value.lastName, city: formValues.value.city, profession: formValues.value.profession };
    this.registered = true;
    return this.sendRegistration(body);
  }

  registerQuestion(question: string, answer: string): Observable<any> {
    const body = { question, answer, email: this.email };
    return this.sendQuestion(body);
  }

  private sendRegistration(body: any) {
    this.registered = true;
    return this.http.post('/api/registration/Register', JSON.stringify(body), httpOptions);
  }

  private sendQuestion(body: any): Observable<any> {
    this.registered = true;

    return this.http.post('/api/registration/SubmitAnswer', JSON.stringify(body), httpOptions);
  }
}
