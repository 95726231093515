// framework
import { Component, Input, EventEmitter, Output, ElementRef, ViewChild, Renderer2, OnInit, AfterViewInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

// models
import { IProvider } from 'src/app/models/provider.model';
import { IProviderConfig } from 'src/app/models/provider-config.model';

// services
import { ProvidersService } from 'src/app/services/providers.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { ExternalApiService } from 'src/app/services/external-api.service';

@Component({
  selector: 'sl-provider-card',
  templateUrl: './provider-card.component.html',
  styleUrls: ['./provider-card.component.less'],
})
export class ProviderCardComponent implements AfterViewInit, OnInit {
  @Input() provider!: IProvider;
  @Input() providerConfig!: IProviderConfig;
  @Output() getProvidersCallback = new EventEmitter();
  @ViewChild('userelement') firstNameElement!: ElementRef;

  isVisible = false;
  isRefreshing = false;
  isLoading = false;
  selectedProviderName: string | undefined;
  selectedProviderImageValue: string | undefined;
  selectedProviderImageType: string | undefined;
  connectionError = false;
  providerSaving: boolean | undefined;
  username!: string;
  certificateId = '';
  touched = false;
  helpModalVisible = false;
  providerActivitiyCount = 0;

  constructor(private providersService: ProvidersService, private userActivitiesService: UserActivitiesService, private externalApiService: ExternalApiService, private renderer: Renderer2, private message: NzMessageService) {}

  ngOnInit(): void {
    this.getProviderActivitiesCount(this.provider.name);
  }

  ngAfterViewInit(): void {
    this.checkProviderForRefresh();
  }

  connectProvider(providerName: string): void {
    this.connectionError = false;
    this.providerSaving = true;
    this.isLoading = true;
    this.providersService.connectProvider(providerName, this.username).subscribe({
      next: (res) => {
        // If the provider is OAuth flow the code below will never get executed due to the redirect.
        if (res !== null) {
          // console.log(`*** Connected[${res.connected}] to: ${res.name} with user ${res.username}`);
          this.message.success(`Well done, you've connected ${providerName} to your profile. We'll keep this updated for you.`);
          this.getProviders();
          this.clearSelectedProvider();
          this.userActivitiesService.updateActivitiesCache();
        } else {
          console.warn(`*** Failed to connect: ${providerName} - ${this.username}`);
          this.message.error(`Failed to connect : ${providerName}`);
          this.connectionError = true;
        }
      },
      error: (err) => {
        console.error('*** Error: ' + err.message);
        this.connectionError = true;
        this.providerSaving = false;
        this.isLoading = false;
      },
      complete: () => {
        // console.log('*** Completed Connecting Providers');
        this.providerSaving = false;
        this.isLoading = false;
      }}
    );
  }

  connectToOAuthProvider(oauthProviderName: string): void {
    const href = this.getFullOAuthUri(this.providerConfig, oauthProviderName);
    this.externalApiService.beginOAuth(href);
  }

  selectProvider(providerName: string): void {
    this.isVisible = true;
    this.selectedProviderName = providerName;
    this.selectedProviderImageValue = undefined;
    this.selectedProviderImageType = 'NONE';
    this.connectionError = false;
    this.providerSaving = false;
    this.username = '';
    this.touched = false;

    if (providerName === this.provider.name) {
      if (this.provider.logo) {
        this.selectedProviderImageValue = this.provider.logo;
        this.selectedProviderImageType = 'LOGO';
      } else if (this.provider.icon) {
        this.selectedProviderImageValue = this.provider.icon;
        this.selectedProviderImageType = 'ICON';
      } else {
        this.selectedProviderImageValue = this.provider.logothumbnail;
        this.selectedProviderImageType = 'THUMBNAIL';
      }
    }

    setTimeout(() => {
      this.renderer.selectRootElement('#username-input').focus();
    }, 400);
  }

  selectVerifier(providerName: string): void {
    this.isVisible = true;
    this.selectedProviderName = providerName;
    this.selectedProviderImageValue = undefined;
    this.selectedProviderImageType = 'NONE';
    this.connectionError = false;
    this.providerSaving = false;
    this.certificateId = '';
    this.touched = false;

    if (providerName === this.provider.name) {
      if (this.provider.logo) {
        this.selectedProviderImageValue = this.provider.logo;
        this.selectedProviderImageType = 'LOGO';
      } else if (this.provider.icon) {
        this.selectedProviderImageValue = this.provider.icon;
        this.selectedProviderImageType = 'ICON';
      } else {
        this.selectedProviderImageValue = this.provider.logothumbnail;
        this.selectedProviderImageType = 'THUMBNAIL';
      }
    }
  }

  connectVerifier(providerName: string): void {
    this.connectionError = false;
    this.providerSaving = true;
    this.isLoading = true;
    this.providersService.connectVerifier(providerName, this.certificateId).subscribe(
      (res) => {
        if (res !== null) {
          // console.log(`*** Verified certificate [${this.certificateId}] for: ${providerName}`);
          this.message.success(`Well done, you've connected the certificate ${this.certificateId} to your profile. You can only add certificates once`);
          this.getProviders();
          this.clearSelectedProvider();
          this.userActivitiesService.updateActivitiesCache();

          this.getProviderActivitiesCount(providerName);

        } else {
          console.warn(`*** Failed to verify: ${this.certificateId} - ${this.username}`);
          this.message.error(`Failed to verify certificate : ${this.certificateId}`);
          this.connectionError = true;
        }
      },
      (err) => {
        console.error('*** Error: ' + err.message);
        this.connectionError = true;
        this.providerSaving = false;
        this.isLoading = false;
      },
      () => {
        // console.log('*** Completed Adding Certificate');
        this.providerSaving = false;
        this.isLoading = false;
      }
    );
  }

  refreshProvider(selectedProviderName: string, selectedProviderUsername: string): void {
    this.isRefreshing = true;
    this.providersService.refreshProvider(selectedProviderName, selectedProviderUsername).subscribe(
      () => {
        this.isRefreshing = false;
        this.userActivitiesService.updateActivitiesCache();

        this.getProviderActivitiesCount(selectedProviderName);

        this.message.success(`Successfully refreshed ${selectedProviderName}`);
      },
      (err) => {
        console.error('*** refreshProvider: Error: ' + err.message);
        this.isRefreshing = false;
        this.message.error(`Failed to update ${selectedProviderName}. Try again in a moment.`);
      }
    );
  }

  clearSelectedProvider(): void {
    this.isVisible = false;
    this.selectedProviderName = undefined;
    this.selectedProviderImageValue = undefined;
    this.selectedProviderImageType = 'NONE';
    this.username = '';
    this.providerSaving = false;
    this.touched = false;
    // console.log('*** Provider: CLEARED!');
  }

  getProviderActivitiesCount(provider: string): void
  {
    this.providersService.getProviderUserAcitivitiesCount(provider).subscribe((count) => {
      this.providerActivitiyCount = count;
    });
  }

  providerNameChange(): void {
    // console.log('*** The provider name just changed');
    this.connectionError = false;
    this.touched = this.username.length > 0;
  }

  isProviderRefreshing(): boolean {
    return this.isRefreshing;
  }

  validField(value: string): boolean {
    return !(value === undefined || value === null);
  }

  showHelpModal(): void {
    this.helpModalVisible = true;
  }

  closeHelpModal(): void {
    this.helpModalVisible = false;
  }

  getHelpMessage(): string {
    return !this.provider.helpInfo ? 'No info here yet' : this.provider.helpInfo;
  }

  getHelpImagePath(providerName: string): string | null {
    let result: string | null;
    const nameToLower = providerName.toLowerCase();
    switch (nameToLower) {
      case 'stackoverflow':
        result = '/assets/img/providers/stackoverflow-user-name.png';
        break;
      case 'github':
        result = '/assets/img/providers/github-id.png';
        break;
      case 'coursera course':
        result = '/assets/img/providers/coursera-course-certificate.png';
        break;
      default:
        result = null;
        break;
    }
    return result;
  }

  private getProviders() {
    this.getProvidersCallback.emit();
  }

  private checkProviderForRefresh() {
    if (this.provider.connected && this.provider.lastRefreshed === null) {
      this.refreshProvider(this.provider.name, this.username);
    }
  }

  private getFullOAuthUri(providerConfig: IProviderConfig, oauthProviderName: string): string {
    const appendedRedirect = this.getFullRedirectUri(providerConfig);
    const appendedScope = this.getFullScope(providerConfig);
    const href = `${providerConfig.baseUri}?client_id=${providerConfig.clientId}${appendedScope}&state=${oauthProviderName}&response_type=code${appendedRedirect}`;

    return href;
  }

  private getFullRedirectUri(providerConfig: IProviderConfig): string {
    const redirectUri = providerConfig.redirectUri !== '' ? `&redirect_uri=${providerConfig.redirectUri}` : '';

    return redirectUri;
  }

  private getFullScope(providerConfig: IProviderConfig): string {
    const appendedScope = providerConfig.scope !== '' ? `&scope=${providerConfig.scope}` : '';

    return appendedScope;
  }
}
