import { Injectable } from '@angular/core';
import { IUserActivity } from '../models/user-activity.model';
import { Subject } from 'rxjs';

@Injectable()
export class UserActivitiesServiceData {
  private initialised = false;
  private userActivities: IUserActivity[] = [];
  private activitiesUpdated = new Subject<boolean>();

  constructor() { }

  initialiseActivities(activitiesInput: IUserActivity[]): void {
    this.initialised = true;
    this.userActivities = activitiesInput;
    this.sortActivitiesList();
    this.notifyActivitiesUpdated();
  }

  resetActivities() {
    this.initialised = false;
  }

  isInitialised(): boolean {
    return this.initialised;
  }

  getActivities(): IUserActivity[] {
    return this.userActivities;
  }

  addActivity(userActivity: IUserActivity): void {
    const index = this.userActivities.findIndex((activity) => userActivity.id === activity.id);
    if (-1 !== index) {
      throw new Error(`*** UserActivitiesServiceData: Cannot add an activity that already exists`);
    }

    this.userActivities.unshift(userActivity);
    this.notifyActivitiesUpdated();
  }

  updateActivities(userActivities: IUserActivity[], suppressNotifications: boolean): IUserActivity[] {
    userActivities.forEach((userActivity: IUserActivity) => {
      this.updateActivity(userActivity, suppressNotifications);
    });

    if (!suppressNotifications) {
      this.notifyActivitiesUpdated();
    }

    return userActivities;
  }

  deleteActivity(deleteItem: IUserActivity): void {
    const index = this.userActivities.findIndex((activity) => deleteItem.id === activity.id);
    if (-1 === index) {
      throw new Error(`*** UserActivitiesServiceData: Why are we deleting an activity that does not exist`);
    }
    this.userActivities.splice(index, 1);
    this.notifyActivitiesUpdated();
  }

  delecteUserActivitiesById(userActivityIds: number[]) {
    userActivityIds.forEach((userActivityId: number) => {
      const index = this.userActivities.findIndex((userActivity: IUserActivity) => userActivity.id === userActivityId);
      if (index !== -1) {
        this.userActivities.splice(index, 1);
      }
    });
    this.notifyActivitiesUpdated();
  }

  updateActivity(activityInput: IUserActivity, suppressNotifications: boolean = false) {
    const index = this.userActivities.findIndex((activity) => activityInput.id === activity.id);
    if (-1 === index) {
      throw new Error(`updateActivity: Why are we updating an activity that does not exist`);
    }

    this.userActivities[index] = activityInput;

    if (!suppressNotifications) {
      this.notifyActivitiesUpdated();
    }
  }

  updateActivityCompletedStatus(activityInput: IUserActivity, completed: boolean = true): boolean {
    const index = this.userActivities.findIndex((activity) => activityInput.id === activity.id);
    if (-1 === index) {
      throw new Error(`updateActivityCompletedStatus: Why are we updating an activity that does not exist`);
    }

    if (completed && !activityInput.dateCompleted) {
      throw new Error('We expect that the activity has NO completed date and is being set as completed.');
    } else if (!completed && activityInput.dateCompleted) {
      throw new Error('We expect that the activity has a completed date and is being marked as NOT complete.');
    }

    // we don't update the entire activity as it didn't change anything other thant the completed status.
    this.userActivities[index].dateCompleted = activityInput.dateCompleted;
    this.userActivities[index].status = activityInput.status;

    this.notifyActivitiesUpdated();
    return completed;
  }

  activitiesUpdatedEvent(): Subject<boolean> {
    return this.activitiesUpdated;
  }

  private notifyActivitiesUpdated() {
    this.activitiesUpdated.next(this.userActivities.length > 0);
  }

  private sortActivitiesList() {
    const ordering: { [key: string]: number } = {};
    const sortOrder = ['started', 'notstarted', 'completed', 'archived'];
    for (let i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }
    this.userActivities.sort((a, b) => ordering[a.status] - ordering[b.status]);
  }
}
