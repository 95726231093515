import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// models
import { IGoal } from 'src/app/models/user-goal.model';
import { IPlaylist } from 'src/app/models/playlist.model';
import { IUserActivity } from 'src/app/models/user-activity.model';
import { IUserPlaylist } from 'src/app/models/user-playlist.model';
import { IRecommendation } from 'src/app/models/recommendation.model';
import { IStateTransition } from 'src/app/models/state-transition.model';
import { Subscription } from 'rxjs';
// services
import { GoalService } from 'src/app/services/goal.service';
import { RecommendationService } from 'src/app/services/recommendation.service';
import { UserPlaylistService } from 'src/app/services/user-playlist.service';
import { PlaylistService } from 'src/app/services/playlist.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { RoutingStateService } from 'src/app/services/routing-state.service';
import { LocalStorageService } from 'ngx-localstorage';
import { LearningService } from 'src/app/services/learning.service';
import { ILearningType } from 'src/app/models/learning-type.model';
import { HostListener } from '@angular/core';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { IPlaylistActivity } from 'src/app/models/playlist-activity.model';

@Component({
  selector: 'sl-recommendation-page',
  templateUrl: './recommendation-page.component.html',
  styleUrls: ['./recommendation-page.component.less'],
})
export class RecommendationPageComponent implements OnInit, OnDestroy {
  @Input() selectedGoalId!: number | null;
  @Input() playlist!: IPlaylist;

  fromUser: string | null = null;
  activityId: number | null = null;
  activity!: IUserActivity;
  addedUserPlaylist!: IUserPlaylist;
  userGoals: IGoal[] | undefined = [];
  selectedGoal: IGoal | null = null;

  loaded = false; // set once we've successfully loaded the minimum information for the recommendation to proceed.
  loadedPlaylistActivities = false;
  isLoadingAddPlaylist = false;
  isLoadingAddUserPlaylist = false;
  isUserPlaylistCreated = false;
  showNoMatchingGoalWarning = false;
  isLoggedIn = false;
  isActivityRecommendation = false;
  isActivityRecommendationAdded = false
  matchingActivityGoals!: IGoal[];

  onChangeGoal!: Subscription;
  learningTypes!: ILearningType[];
  recommendationTitle = 'Loading activity...';

  offsetTop!: number;

  defaultThumbnail = 'assets/img/placeholder_image.png';

  constructor(
    private route: ActivatedRoute,
    private routingStateService: RoutingStateService,
    private recommendationService: RecommendationService,
    private goalService: GoalService,
    private playlistService: PlaylistService,
    private userPlaylistService: UserPlaylistService,
    private userActivitiesService: UserActivitiesService,
    private learningService: LearningService,
    private message: NzMessageService,
    private localStorageService: LocalStorageService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    this.updateOffsetTop((event.target as Window).innerWidth);
  }

  ngOnInit(): void {
    this.fromUser = this.getUserUrlFromRoute();
    const activityId = this.getActivityIdFromRoute();
    const goalId = this.getGoalIdFromRoute();
    this.selectedGoalId = goalId ? parseInt(goalId, 10) : null;
    this.isLoggedIn = this.goalService.getIsLoggedIn();
    this.updateOffsetTop(window.innerWidth);

    if (activityId) {
      this.initialiseActivity(activityId);
    }

    if (this.playlist) {
      this.intialisePlaylistModel(this.playlist);
    } else {
      // if it's not an activity, and if a playlist isn't passed in, then check if it's either a playlist from the route or a userPlaylist from the route
      const playlistId = this.getPlaylistIdFromRoute();

      if (playlistId) {
        this.initialiseFromPlaylist(playlistId);
      } else {
        const userPlaylistId = this.getUserPlaylistIdFromRoute();

        if (userPlaylistId) {
          this.initialiseFromUserPlaylist(userPlaylistId);
        }
      }
    }
  }

  updateOffsetTop(innerWidth: number) {
    if (innerWidth < 1025) {
      this.offsetTop = 65;
    } else {
      this.offsetTop = 0;
    }
  }

  recordNzAffixChange() {
    this.updateOffsetTop(window.innerWidth);
  }

  public getUserUrlFromRoute(): string | null {
    return this.route.snapshot.paramMap.get('userUrl');
  }

  public getActivityIdFromRoute(): string | null {
    return this.route.snapshot.paramMap.get('activityId');
  }

  public getGoalIdFromRoute(): string | null {
    return this.route.snapshot.paramMap.get('goalId');
  }

  public getActivityIndustryFromRoute(): string | null {
    return this.route.snapshot.paramMap.get('industry');
  }

  public getActivityProfessionFromRoute(): string | null {
    return this.route.snapshot.paramMap.get('profession');
  }

  public getActivitySpecialisationFromRoute(): string | null {
    return this.route.snapshot.paramMap.get('specialisation');
  }

  public getPlaylistIdFromRoute(): number | null {
    const playlistIdString = this.route.snapshot.paramMap.get('playlistId');
    if (playlistIdString) {
      return parseInt(playlistIdString, 10);
    }
    return null;
  }

  public getUserPlaylistIdFromRoute(): number | null {
    const userPlaylistIdString = this.route.snapshot.paramMap.get('userPlaylistId');
    if (userPlaylistIdString) {
      return parseInt(userPlaylistIdString, 10);
    }
    return null;
  }

  public getInviteCodeFromRoute(): string | null {
    return this.route.snapshot.paramMap.get('inviteCode');
  }

  ngOnDestroy() {
    if (this.onChangeGoal) {
      this.onChangeGoal.unsubscribe();
    }
  }

  public addPlaylist(playlist: IPlaylist, goal: IGoal) {
    if (!goal) {
      this.message.info('Playlists help you achieve a goal. Select the goal this playlist will help you with.');
      return;
    }

    if (!playlist) {
      this.message.error('A playlist must be defined by this point');
      return;
    }

    this.isLoadingAddPlaylist = true;
    this.userPlaylistService.addUserPlaylistToGoal(playlist.id, goal.id as number).subscribe({
      next: (res: IUserPlaylist) => {
        this.addedUserPlaylist = res;
        this.isLoadingAddPlaylist = false;
        this.isUserPlaylistCreated = true;
        this.message.success(`Success, playlist added to your profile.`);
      },
      error: (err: HttpErrorResponse) => {
        this.isLoadingAddPlaylist = false;

        if (err.status === 409) {
          this.message.info(`You already have this playlist in your profile. (Playlist: ${playlist.name}).`);
          console.warn(`You can't add the same playlist twice (Playlist: ${playlist.name}).`);
          this.isUserPlaylistCreated = true;
        } else {
          this.message.error(`Could not add playlist with ID: ${playlist.id}.`);
          console.error(`*** RecommendationPageComponent: Could not add playlist with ID: ${playlist.id}.`);
        }
      },
    });
  }

  public setFileUrl(recommendation: IRecommendation) {
    // priority; 1: normal url, 2: stored normal url, 3: larger url, 4: default "type" image
    return null != recommendation.imageThumbnailUrl
      ? recommendation.imageThumbnailUrl
      : null != recommendation.imageThumbnail
        ? 'data:image/jpeg;base64,' + recommendation.imageThumbnail
        : null != recommendation.imageFullSizeUrl
          ? recommendation.imageFullSizeUrl
          : 'data:image/jpeg;base64,' + recommendation.imageBytes;
  }

  public setPlaylistActivityImageUrl(playlistActivity: IPlaylistActivity) {
    // priority; 1: normal url, 2: stored normal url, 3: larger url, 4: learning type image 5. learning type icon (handled using CSS class)
    return playlistActivity.imageThumbnailUrl
      ? playlistActivity.imageThumbnailUrl
      : playlistActivity.imageThumbnail
        ? `data:image/jpeg;base64,${playlistActivity.imageThumbnail}`
        : playlistActivity.imageFullSizeUrl
          ? playlistActivity.imageFullSizeUrl
          : playlistActivity.learningTypeImage
            ? `data:image/jpeg;base64,${playlistActivity.learningTypeImage}`
            : '';
  }

  public handleMissingImage(event: Event, playlistActivity: IPlaylistActivity) {
    (event.target as HTMLImageElement).src = `data:image/jpeg;base64,${playlistActivity.learningTypeImage}`;
  }

  public isShowingLearningTypeIcon(playlistActivity: IPlaylistActivity): boolean {
    if (!this.setPlaylistActivityImageUrl(playlistActivity) && playlistActivity.learningTypeIcon) {
      return true;
    }
    return false;
  }

  public getActivityFromRecommendation(recommendation: IRecommendation): IUserActivity {
    const activityIndustry = this.getActivityIndustryFromRoute() ? this.getActivityIndustryFromRoute() : '';
    const activityProfession = this.getActivityProfessionFromRoute() ? this.getActivityProfessionFromRoute() : '';
    const activitySpecialisation = this.getActivitySpecialisationFromRoute() ? this.getActivitySpecialisationFromRoute() : '';

    if (null == this.selectedGoalId) {
      throw new Error(`No goal selected for recommendation ID: ${recommendation.id}. When we're processing a recommendation to an activity, we should know the goal it's being added to.`);
    }

    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);

    this.userGoals = this.goalService.getGoals(true);
    this.matchingActivityGoals = this.userGoals.filter((g) =>
      g.industry === activityIndustry &&
      g.profession === activityProfession &&
      (g.specialisation === activitySpecialisation || activitySpecialisation === '' || activitySpecialisation === 'All')
    );

    if (!this.matchingActivityGoals.length) {
      this.showNoMatchingGoalWarning = true;
    }

    const newActivity: IUserActivity = {
      id: this.activityId!,
      goalId: this.matchingActivityGoals[0] ? this.matchingActivityGoals[0].id : this.selectedGoalId,
      typeName: recommendation.typeName,
      link: recommendation.link,
      title: recommendation.title,

      // 1. If there is only one matching goal for the industry / profession /specialisation, then set that.
      // 2. If there is NO matching goals, take the industry / profession /specialisation from the route.
      // 3. If there are MANY matching goals, then don't set the industry / profession / specialisation.
      industry: this.matchingActivityGoals.length === 1 ? this.matchingActivityGoals[0].industry : (this.matchingActivityGoals.length === 0 ? activityIndustry! : undefined),
      profession: this.matchingActivityGoals.length === 1 ? this.matchingActivityGoals[0].profession : (this.matchingActivityGoals.length === 0 ? activityProfession! : undefined),
      specialisation: this.matchingActivityGoals.length === 1 ? this.matchingActivityGoals[0].specialisation : (this.matchingActivityGoals.length === 0 ? activitySpecialisation! : undefined),
      dateCreated: new Date(),
      skills: recommendation.skills,
      description: recommendation.description,
      imageThumbnailUrl: this.setFileUrl(recommendation),
      hidden: false,
      status: '',
      transitionStatus: [] as IStateTransition[],
      flaggedActivityType: recommendation.flaggedActivityType
    };

    return newActivity;
  }

  public goToInvitationPageWithPlaylistInviteCode() {
    const inviteCodeFromRoute = this.getInviteCodeFromRoute();
    const hostName = this.routingStateService.getHostName();
    const detailsForPlaylistGoal = JSON.stringify({ playlistId: this.getPlaylistIdFromRoute(), industry: this.playlist.industry, profession: this.playlist.profession, specialisation: this.playlist.specialisation, level: this.playlist.levelFrom });
    this.localStorageService.set('details-for-playlist-goal', detailsForPlaylistGoal);
    this.localStorageService.set('invite-from-playlist-link', inviteCodeFromRoute);
    window.location.href = `http://${hostName}/Account/Register?inviteCode=${inviteCodeFromRoute}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getPlaylistSkills(playlistSkills: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const skillsArray: any = [];
    if (playlistSkills.length > 0) {
      for (const x in playlistSkills) {
        if (x != null) {
          skillsArray.push(playlistSkills[x].name);
        }
      }
      return 'Skills: ' + skillsArray;
    }
    return '';
  }

  public getActivityTypeIcon(typeName: string): string | undefined {
    let result = null;
    if (this.learningTypes) {
      result = this.learningTypes.find((lt) => lt.type === typeName);
    }
    return result ? result.icon : undefined;
  }

  public getProviderTooltip(providerName: string): string {
    const tooltip = providerName === 'unverified' ? 'Unverified activities that we don\'t have a connection to the provider in order to confirm that you\'ve done it.' : providerName;

    return tooltip;
  }

  public getTitle(activity: IUserActivity): string {
    if (activity.title) {
      return activity.title;
    }
    return activity.link;
  }

  private initialiseActivity(activityId: string) {
    this.activityId = parseInt(activityId, 10);
    this.recommendationService.getRecommendation(this.activityId).subscribe({
      next: (ret: IRecommendation) => {
        this.isActivityRecommendation = true;
        this.activity = this.getActivityFromRecommendation(ret);
        this.recommendationTitle = this.activity.title;
        this.userActivitiesService.hasUserAddedActivity(this.activity.title, this.activity.link).subscribe({
          next: (res) => {
            this.isActivityRecommendationAdded = res;
          },
          error: () => {
            this.isActivityRecommendationAdded = false;
          },
        });
        this.loaded = true;
      },
      error: (err: HttpErrorResponse) => {
        this.message.error(`Could not find an activity ID matching ${this.activityId}.`);
        console.log(`*** RecommendationPageComponent: Could not find an activity ID matching ${this.activityId}.`);
        console.error(err.message);
        this.loaded = true;
      },
    });
  }

  private levelNum(levelString: string | null | undefined): number {
    return !levelString ? -1 : parseInt(levelString.split(' ')[1], 10);
  }

  private initialiseFromPlaylist(playlistId: number) {
    this.isLoadingAddPlaylist = true;
    this.playlistService.getPlaylist(playlistId).subscribe({
      next: (playlist: IPlaylist) => {
        this.isLoadingAddPlaylist = false;
        this.loaded = true;
        this.intialisePlaylistModel(playlist);
      },
      error: (err: HttpErrorResponse) => {
        this.message.error(`Could not find a playlist ID matching ${playlistId}.`);
        console.error(`*** RecommendationPageComponent: Could not find a playlist ID matching ${playlistId}.`);
        console.error(err.message);
        this.isLoadingAddPlaylist = false;
        this.loaded = true;
      },
    });
  }

  private initialiseFromUserPlaylist(userPlaylistId: number) {
    this.isLoadingAddPlaylist = true;
    this.playlistService.getPlaylistForUserPlaylist(userPlaylistId).subscribe({
      next: (playlist: IPlaylist) => {
        this.isLoadingAddPlaylist = false;
        this.intialisePlaylistModel(playlist);
      },
      error: (err: HttpErrorResponse) => {
        this.message.error(`Could not find a userPlaylist ID matching ${userPlaylistId}.`);
        console.error(`*** RecommendationPageComponent: Could not find a playlist ID matching ${userPlaylistId}.`);
        console.error(err.message);
        this.isLoadingAddUserPlaylist = false;
      },
    });
  }

  private intialisePlaylistModel(playlist: IPlaylist) {
    this.playlist = playlist;
    this.getLearningTypes();
    this.recommendationTitle = playlist.name;

    if (this.isLoggedIn) {
      this.initialiseUserGoals(playlist);

      // this next part just confirms if the user has already added this playlist.
      this.userPlaylistService.hasUserCreatedPlaylist(playlist.id).subscribe({
        next: (res) => {
          this.isUserPlaylistCreated = res;
        },
        error: () => {
          this.isUserPlaylistCreated = false;
        },
      });
    }
  }

  private getLearningTypes() {
    this.learningService.getLearningTypes().subscribe({
      next: (ret: ILearningType[]) => {
        this.learningTypes = ret;
        this.initialistPlaylistActivityTypes(this.playlist);
      },
    });
  }

  private initialistPlaylistActivityTypes(playlist: IPlaylist) {
    if (playlist && playlist.playlistActivitiesVm) {
      playlist.playlistActivitiesVm.forEach(pla => {
        this.getImageAndIconFromLearningType(pla);
      })
    }
  }

  private getImageAndIconFromLearningType(playlistActivity: IPlaylistActivity): void {
    if (this.learningTypes) {
      this.learningTypes.find(lt => {
        if (lt.type === playlistActivity.typeName) {
          playlistActivity.learningTypeImage = lt.image;
          playlistActivity.learningTypeIcon = lt.icon;
        }
      });
    }
    this.loadedPlaylistActivities = true;
  }

  private initialiseUserGoals(playlist: IPlaylist) {
    if (!this.goalService || !this.goalService.isInitialised()) {
      this.onChangeGoal = this.goalService.getGoalsUpdatedEvent().subscribe(() => {
        this.initialiseUserGoalsWork(playlist);
      });
    } else {
      this.initialiseUserGoalsWork(playlist);
    }
  }

  // not sure what else to call this. It's just doing the work so I can call it from the callback OR directly
  private initialiseUserGoalsWork(playlist: IPlaylist) {
    this.userGoals = this.goalService.getGoals(true);
    const goals: IGoal[] | undefined = this.goalService.getGoals(true);

    const goalsMatched = goals.filter((g) =>
      g.industry === playlist.industry &&
      g.profession === playlist.profession &&
      (g.specialisation === playlist.specialisation || playlist.specialisation === '' || playlist.specialisation === 'All') &&
      this.levelNum(g.level) >= playlist.levelFrom &&
      this.levelNum(g.level) <= playlist.levelTo);

    // TODO: Adam: Note: WE might want to give them a button to create a goal based on this signature (not required yet).
    if (goalsMatched.length === 0) {
      this.showNoMatchingGoalWarning = true;
      // console.log(`We want to give them an option to see what OTHER goals they could choose, but it should be clear that it's not a good match`);
      this.userGoals = goals;
    } else if (goalsMatched) {
      if (goalsMatched.length === 1) {
        if (goalsMatched[0].id) {
          this.selectedGoal = goalsMatched[0];
        }
      }
      this.userGoals = goalsMatched;
    }
  }
}
