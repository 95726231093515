<div *ngIf="playlist">
  <nz-card class="card-style" nzHoverable [nzCover]="coverTemplate">
    <div class="card-info-container">
      <h4 class="card-title" id="playlist-card-{{playlist.id}}" nz-typography nzEllipsis nzEllipsisRows="1" (click)="showDrawer(true)" nz-tooltip="{{playlist.name}}">
        {{playlist.name}}
      </h4>
      <div class="card-details" nz-typography> {{getPlayListDetails()}}</div>

      <span class="view-playlist-activities-icon-container">
        <i class="fas fa-list fa-2x card-learning-style view-playlist-activities-icon" nz-tooltip nzTooltipTitle="View playlist activities" [ngClass]="{'view-playlist-activities-icon-loading': isLoadingPlaylistActivities}" (click)="showDrawer(true)"></i>
        <nz-spin *ngIf="isLoadingPlaylistActivities" class="spinner" nzSimple></nz-spin>
      </span>

      <div class="card-activity-count">
        <span>
          {{getActivityCount()}} Activities
        </span>
      </div>
    </div>
  </nz-card>
  <ng-template #coverTemplate>
    <div class="playlist-image" (mouseleave)="imageLeave()" (mouseenter)="imageEnter()">
      <div class="picture-area" (click)="showDrawer(true)">
        <img class="imgContainer" [src]="getCardImage()" [ngClass]="{'img-added-backlog': addedToBacklog}" alt="Image of the playlist" />
      </div>
      <div *ngIf="!playlistShared">
        <button class="share-playlist-button" [hidden]="!isHovered" nz-button nzType="primary" [nzLoading]="isAdding" nz-tooltip nzTooltipTitle="Share Playlist to Guild" nzTooltipPlacement="top" (click)="sharePlaylistToGuild(playlist)">
          <i class="fa-regular fa-share-from-square"></i><span class="add-button-text"> Share Playlist</span>
        </button>
      </div>
      <div *ngIf="playlistShared">
        <i class="share-playlist-button checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'"></i>
      </div>
    </div>
  </ng-template>

  <nz-drawer class="playlist-drawer" [nzClosable]="true" [nzWidth]="650" [nzOffsetX]="childrenVisible ? 500 : 0" [nzOffsetX]="childrenVisible ? 500 : 0" [nzVisible]="isActivitiesModalVisible" nzTitle="{{playlist.name}}" (nzOnClose)="closeActivityViewer()">
    <ng-container *nzDrawerContent>
      <sl-playlist-viewer [playlist]="playlist" [buttonUse]="buttonType.Share" [isInteractable]="false" (playlistShared)="sharePlaylistToGuild($event)"></sl-playlist-viewer>
    </ng-container>
  </nz-drawer>
</div>