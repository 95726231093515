import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

// services
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { GoalService } from 'src/app/services/goal.service';
import { InviteService } from 'src/app/services/invite.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PlaylistService } from 'src/app/services/playlist.service';
import { RoutingStateService } from 'src/app/services/routing-state.service';
import { UserActionService } from 'src/app/services/user-action.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { UserPlaylistService } from 'src/app/services/user-playlist.service';

// models
import { IGoal } from 'src/app/models/user-goal.model';
import { IInvitation } from 'src/app/models/invitation.model';
import { ISkill } from 'src/app/models/skill.model';
import { IUserAction, getUserActionStatus } from 'src/app/models/user-action.model';
import { getUserActivityStatus, IUserActivity } from 'src/app/models/user-activity.model';
import { IUserPlaylist, IUserPlaylistState } from 'src/app/models/user-playlist.model';
import { EUserActivityProvider } from 'src/app/types/user-activity-provider.type';
import { IStateTransition } from 'src/app/models/state-transition.model';
import { IPlaylist } from 'src/app/models/playlist.model';
// types
import { ActivityKind } from 'src/app/types/activity-kind.type';
import { StatusKind } from 'src/app/types/status-kind.type';
import { EUserRowState } from 'src/app/types/user-activity-state.type';
import { OnboardingService } from 'src/app/services/onboarding.service';

@Component({
  selector: 'sl-profile-activity-actions',
  templateUrl: './profile-activity-actions.component.html',
  styleUrls: ['./profile-activity-actions.component.less'],
})
export class ProfileActivityActionsComponent implements OnInit {
  @Input() userActivity?: IUserActivity;
  @Input() userPlaylist?: IUserPlaylist;
  @Input() userAction?: IUserAction;
  @Output() completeActivity = new EventEmitter();
  @Output() refreshActivitiesTable = new EventEmitter();
  @Output() refreshUserPlaylist = new EventEmitter();
  @Output() refreshUserAction = new EventEmitter();
  @Output() removeUserPlaylist = new EventEmitter();
  @Output() updateUserPlaylistVersion: EventEmitter<IUserPlaylistState> = new EventEmitter();

  ActivityKind = ActivityKind;
  UserRowState = EUserRowState;
  UserActivityProvider = EUserActivityProvider;

  buttonState = EUserRowState.NOTSTARTED;
  playlistFromUserPlaylist!: IPlaylist;
  actionsDisabled = false;
  actionsLimited = false;
  isVisible = true;
  newSkillsFound = false;
  isLoading = false;
  editActivityMode = false;
  editActionMode = false;
  showPlaylistInfo = false;
  goalEdited: IGoal | undefined;
  newSkills: ISkill[] = [];
  isDeleting = false;
  isUnsubscribedModal = false;

  confirmDueDateModal: { isVisible: boolean; trigger: string; datePlannedDue: Date | undefined } = {
    isVisible: false,
    trigger: 'null',
    datePlannedDue: undefined,
  };

  constructor(
    private accountDetailsService: AccountDetailsService,
    private goalService: GoalService,
    private onboardingService: OnboardingService,
    private routingStateService: RoutingStateService,
    private userActivitiesService: UserActivitiesService,
    private userPlaylistService: UserPlaylistService,
    private userActionService: UserActionService,
    private inviteService: InviteService,
    private clipboard: Clipboard,
    private message: NzMessageService,
    private playlistService: PlaylistService,
    private router: Router
  ) { }

  get isArchive(): boolean {
    if (this.userActivity) {
      return this.userActivity.isArchived === true;
    } else if (this.userAction) {
      return this.userAction.isArchived === true;
    } else if (this.userPlaylist) {
      return this.userPlaylist.isArchived === true;
    }

    throw new Error(`We don't have a valid component`);
  }

  ngOnInit() {
    this.setButtonStates();
    this.limitActivityActions();
    this.initialiseEditedGoal();
  }

  play() {
    const startDate = new Date();
    this.resetTimeOnDate(startDate);

    if (this.userActivity !== undefined) {
      this.userActivity.dateStart = startDate;
      this.userActivity.datePlannedStart = startDate;
      this.userActivity.status = EUserRowState.STARTED;
      this.updateUserElement();
      this.buttonState = EUserRowState.STARTED;
    } else if (this.userAction !== undefined) {
      this.userAction.dateStart = startDate;
      this.userAction.datePlannedStart = startDate;
      this.userAction.status = EUserRowState.STARTED;
      this.updateUserElement();
      this.buttonState = EUserRowState.STARTED;
    }
  }

  toggleDueDateModalVisibility() {
    if (this.userActivity !== undefined) {
      if (this.userActivity.datePlannedDue) {
        this.confirmDueDateModal.trigger = 'click';
        this.confirmDueDateModal.isVisible = true;
        this.confirmDueDateModal.datePlannedDue = this.userActivity.datePlannedDue;
      } else {
        this.toggleResumePaused();
      }
    } else if (this.userAction !== undefined) {
      if (this.userAction.datePlannedDue) {
        this.confirmDueDateModal.trigger = 'click';
        this.confirmDueDateModal.isVisible = true;
        this.confirmDueDateModal.datePlannedDue = this.userAction.datePlannedDue;
      } else {
        this.toggleResumePaused();
      }
    }
  }

  confirmDueDateModalChange(visible: boolean) {
    this.confirmDueDateModal.isVisible = visible;
    if (visible === false) {
      this.toggleResumePaused();
    }
  }

  disabledDates = (currentDate: Date): boolean => (currentDate.toDateString() === new Date().toDateString() ? false : currentDate < new Date());

  confirmDueDate() {
    if (this.userActivity !== undefined) {
      this.userActivity.datePlannedDue = this.confirmDueDateModal.datePlannedDue;
      this.updateUserElement();
      this.toggleResumePaused();
    }
  }

  toggleResumePaused() {
    this.updateTranstionStatus();
    const status = this.getElementStatus();
    const transition = this.getElementTransitionStatus();

    if (status && transition) {
      this.setPausedOrStartedState(status, transition);
    }
  }

  limitActivityActions() {
    if (this.userActivity) {
      if (this.userActivity.providerName === EUserActivityProvider.GITHUB) {
        this.actionsLimited = true; // github projects shouldn't be able to be completed
      } else if (this.userActivity.providerName === EUserActivityProvider.MEETUP) {
        this.actionsDisabled = true; // meetup shouldn't be able to be completed
      } else if (this.userActivity.providerName === EUserActivityProvider.STACKOVERFLOW) {
        this.actionsDisabled = true; // stack overflow answeres hould be able to be completed
      } else if (this.userActivity.providerName === EUserActivityProvider.UNVERIFIED) {
        this.actionsDisabled = false;
        this.actionsLimited = false;
      }
    }
  }

  setPausedOrStartedState(status: StatusKind, transitionStatus: IStateTransition[]) {
    if (transitionStatus === undefined || transitionStatus === null || transitionStatus.length === 0) {
      this.buttonState = status === StatusKind.Scheduled ? EUserRowState.NOTSTARTED : EUserRowState.STARTED;
    } else if (transitionStatus[transitionStatus.length - 1].status === EUserRowState.PAUSED) {
      this.buttonState = EUserRowState.PAUSED;
    } else if (status === StatusKind.Scheduled) {
      this.buttonState = EUserRowState.NOTSTARTED;
    } else {
      this.buttonState = EUserRowState.STARTED;
    }
  }

  toggleVisibility() {
    if (this.userActivity) {
      this.userActivity.hidden = !this.userActivity.hidden;
      this.isVisible = !this.userActivity.hidden;
      this.updateUserElement();
    } else if (this.userAction) {
      this.userAction.hidden = !this.userAction.hidden;
      this.isVisible = !this.userAction.hidden;
      this.updateUserElement();
    } else if (this.userPlaylist) {
      throw new Error(`we don't support toggling visibility for playlists`);
    }
  }

  toggleArchive() {
    if (this.userActivity) {
      this.userActivity.isArchived = !this.userActivity.isArchived;
    } else if (this.userAction) {
      this.userAction.isArchived = !this.userAction.isArchived;
    } else if (this.userPlaylist) {
      this.userPlaylist.isArchived = !this.userPlaylist.isArchived;
    }

    this.updateUserElement();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateActivitySchedule(event: any) {
    if (this.userActivity !== undefined) {
      const newDate = new Date(event.target.value);
      this.userActivity.datePlannedStart = newDate;
      this.updateUserElement();
    }
  }

  setButtonStates() {
    if (this.userActivity !== undefined) {
      // why are we not just setting the state to the state of the userActivity?
      this.buttonState = this.userActivity.dateCompleted ? EUserRowState.COMPLETED : this.userActivity.dateStart ? EUserRowState.STARTED : EUserRowState.NOTSTARTED;
      this.isVisible = !this.userActivity.hidden;

      if (!this.userActivity.dateCompleted && this.userActivity.dateStart !== undefined) {
        const status = getUserActivityStatus(this.userActivity);
        this.setPausedOrStartedState(status, this.userActivity.transitionStatus);
      }
    } else if (this.userAction !== undefined) {
      this.buttonState = this.userAction.dateCompleted ? EUserRowState.COMPLETED : this.userAction.dateStart ? EUserRowState.STARTED : EUserRowState.NOTSTARTED;
      this.isVisible = !this.userAction.hidden;

      if (!this.userAction.dateCompleted && this.userAction.dateStart) {
        const status = getUserActionStatus(this.userAction);
        this.setPausedOrStartedState(status, this.userAction.transitionStatus);
      }
    }
  }

  unverifiedProvider() {
    let isVerified = false;
    if (this.userActivity !== undefined) {
      isVerified = this.userActivity.providerName === EUserActivityProvider.UNVERIFIED;
    }
    return isVerified;
  }

  isActivePlay(): boolean {
    return this.buttonState === EUserRowState.NOTSTARTED && !this.isArchive && (!this.actionsLimited || !this.actionsDisabled);
  }

  isActiveResume(): boolean {
    return this.buttonState === EUserRowState.PAUSED && !this.isArchive && !this.actionsDisabled;
  }

  isActivePause(): boolean {
    return this.buttonState === EUserRowState.STARTED && !this.isArchive && !this.actionsDisabled;
  }

  isActiveUndoComplete(): boolean {
    return this.buttonState === EUserRowState.COMPLETED && !this.isArchive && !this.actionsDisabled;
  }

  isActiveHidePublic(): boolean {
    if (this.userActivity) {
      return this.buttonState === EUserRowState.COMPLETED;
    } else if (this.userAction) {
      return getUserActionStatus(this.userAction) === StatusKind.Completed;
    }

    return false;
  }

  isActiveComplete(): boolean {
    return this.buttonState === EUserRowState.STARTED && !this.isArchive && !this.actionsDisabled;
  }

  isActiveEditNotes(): boolean {
    return this.buttonState !== EUserRowState.NOTSTARTED;
  }

  showEditModal() {
    this.editActivityMode = true;
  }

  handleEditModalCancel = () => {
    this.editActivityMode = false;
  };

  showEditActionModal() {
    this.editActionMode = true;
  }

  showPlaylistDetails(playlistId: number) {
    this.playlistService.getPlaylist(playlistId).subscribe((pl) => {
      this.playlistFromUserPlaylist = pl;
      this.showPlaylistInfo = true;
    });
  }

  handleHidePlaylistDetails() {
    this.showPlaylistInfo = false;
  }

  handleSubscriptionModalCancel() {
    this.isUnsubscribedModal = false;
    this.showPlaylistInfo = false;
  }

  handleSubscriptionModalOk() {
    this.isUnsubscribedModal = false;
    this.router.navigate(['account/billing']);
  }

  public updatePlaylistToLatestVersion(userPlaylist: IUserPlaylistState) {
    if (!this.isPlaylistVersionMostCurrent(userPlaylist)) {
      this.updatePlaylistVersion(userPlaylist, userPlaylist.latestPlaylistVersion);
    }
  }

  public updatePlaylistVersion(userPlaylist: IUserPlaylistState, playlistVersion: number) {
    userPlaylist.updatingVersion = true;
    this.userPlaylistService.updatePlaylist(userPlaylist.id, playlistVersion)
      .pipe(
        map(up => ({
          ...up,
          expanded: userPlaylist.expanded,
          updatingVersion: false,
          completedActivitiesBadgeView: false
        })),
      )
      .subscribe({
        next: (res: IUserPlaylistState) => {
          this.updateUserPlaylistVersion.emit(res);
          userPlaylist.updatingVersion = false;
        },
        error: () => {
          this.message.error(`We've lost connection to SeaLadder HQ and can't get the activities for this playlist. Check network connection?`);
          userPlaylist.updatingVersion = false;
        }
      });
  }

  public isPlaylistVersionMostCurrent(userPlaylist: IUserPlaylistState): boolean {
    return userPlaylist.playlistVersion === userPlaylist.latestPlaylistVersion;
  }

  public isNoSkills(): boolean {
    let noSkills = false;
    if (this.userActivity !== undefined) {
      noSkills = this.userActivity.skills.length === 0;
    }
    return noSkills;
  }

  public initialiseEditedGoal(): IGoal | undefined {
    if (!this.goalEdited && this.userActivity !== undefined) {
      this.goalEdited = this.goalService.getGoal(this.userActivity.goalId);
    }
    return this.goalEdited;
  }

  public handleCancel = () => {
    this.editActivityMode = false;
    this.newSkillsFound = false;
    if (this.newSkills.length > 0) {
      this.newSkills.length = 0;
    }
  };

  public handleEditActionCancel() {
    this.editActionMode = false;
  }

  public acceptNewSkills(): void {
    if (this.userActivity !== undefined) {
      this.userActivity.skills.push(...this.newSkills);
      if (!this.goalEdited) {
        this.goalEdited = this.goalService.getGoal(this.userActivity.goalId);
      }
      if (!this.goalEdited) {
        throw new Error('the goalEdited should really be defined by this point');
      }

      this.goalEdited.skills.push(...this.newSkills);

      this.goalService.updateGoalAndServiceData(this.goalEdited).subscribe({
        next: () => {
          // console.log(`updateGoal Succeeded for goalID ${res.id}`);
          this.message.create('success', 'Goal updated successfully!');
          this.newSkillsFound = false;
          // TODO: Need to add the new skills rather than relying on completeUserActivity... or, we use the previous update function and fix the issue with the skills report in there too.
          this.completeUserElement();
        },
        error: (error) => {
          this.message.create('error', 'There was an error updating goal.');
          console.error('Error updating goal ' + error);
        },
      });
    }
  }

  public copyLink() {
    if (this.userActivity) {
      const publicUrl = this.accountDetailsService.getPublicUrl();
      const hostName = this.routingStateService.getHostName();
      const encodedUserActivityIndustry = encodeURIComponent(this.userActivity.industry!);
      const encodedUserActivityProfession = encodeURIComponent(this.userActivity.profession!);
      const encodedUserActivitySpecialisation = encodeURIComponent(this.userActivity.specialisation!);

      if (!publicUrl) {
        this.clipboard.copy(`${hostName}/recommended/activity/${this.userActivity.id}/${this.userActivity.goalId}/${encodedUserActivityIndustry}/${encodedUserActivityProfession}/${encodedUserActivitySpecialisation}`);
      } else {
        this.clipboard.copy(`${hostName}/recommended/activity/${this.userActivity.id}/${this.userActivity.goalId}/${encodedUserActivityIndustry}/${encodedUserActivityProfession}/${encodedUserActivitySpecialisation}/from/${publicUrl}`);
      }
      this.message.success(`'${this.userActivity.title}' copied to clipboard`);
    } else if (this.userAction) {
      throw Error(`Not Supported - copying a link to a userAction`);
    } else if (this.userPlaylist) {
      const publicUrl = this.accountDetailsService.getPublicUrl();
      const hostName = this.routingStateService.getHostName();
      const userPlaylistId = this.userPlaylist.id;
      this.inviteService.generateUserPlaylistInvitationCode(this.userPlaylist.id).subscribe({
        next: (res: IInvitation) => {
          this.clipboard.copy(`${hostName}/recommended/userplaylist/${userPlaylistId}/from/${publicUrl}/${res.inviteCode}`);
          this.message.success(`A link to your playlist has been copied to clipboard.`);
        },
        error: () => {
          this.message.success(`Well, this is embarrassing. We couldn't create a link to your playlist. Try again later?`);
        },
      });
    }
  }

  public completeUserActivityBeginFlow() {
    if (this.userActivity !== undefined) {
      const url = this.userActivity.link;
      if (url === undefined) {
        throw new Error('URL cannot be undefined at this point');
      }

      this.isLoading = true;
      // before we complete the activity, get the metadata and see if there are new skills for the activity that we didn't know about before.
      this.userActivitiesService.getActivityMetadata(url).subscribe({
        next: (res) => {
          res.activitySkills.forEach((s) => {
            if (this.userActivity !== undefined) {
              if (this.userActivity.skills.filter((sn) => sn.name.toLowerCase() === s.name.toLowerCase()).length === 0) {
                this.newSkills.push(s);
              }
            }
          });
          this.isLoading = false;

          if (this.newSkills.length > 0) {
            this.newSkillsFound = true;
          } else {
            this.completeUserElement(true);
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.message.error(`We're really sorry, but something has gone wrong. Try again in a few minutes`);
          console.error(err);
        }
      });
    }
  }

  public completeUserElement(complete: boolean = true) {
    if (this.userActivity) {
      this.isLoading = true;
      this.userActivitiesService.completeUserActivity(this.userActivity, complete).subscribe({
        next: (userActivity) => {
          this.userActivity = userActivity;
          this.setButtonStates();
          this.isLoading = false;
          if (!this.onboardingService.isComplete()) {
            this.onboardingService.completeAddActivityTask(true);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          if (error.status === 402) {
            this.isUnsubscribedModal = true;
            return;
          }
        },
      });
    } else if (this.userAction) {
      this.isLoading = true;

      this.userActionService.completeUserAction(this.userAction, complete).subscribe(
        (ua) => {
          this.userAction = ua;
          this.message.success(`Nice work there! Great to see you're making progress`);
          this.setButtonStates();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.message.error('We failed to complete this action for you. Not sure what went wrong there... perhaps try again');
          console.error(`Could not change the action complete status to ${!complete}. Error: ` + error);
        }
      );
    }
  }

  public deleteUserElement() {
    this.isDeleting = true;
    if (this.userPlaylist) {
      this.userPlaylistService.deleteUserPlaylist(this.userPlaylist.id).subscribe(
        (deletedUserActivityIds) => {
          this.isDeleting = false;
          this.setButtonStates();
          this.message.success(`Delete worked for '${this.userPlaylist?.playlistTitle}'`);
          this.userActivitiesService.removeUserActivitiesByIdFromDataService(deletedUserActivityIds);
          // console.log(`Delete playlist '${this.userPlaylist?.playlistTitle}' resulted in deletion of the following UserActivity IDs: ${deletedUserActivityIds.toString()}`);
          if (typeof this.userPlaylist?.id === 'number') {
            this.removeUserPlaylist.emit(this.userPlaylist.id);
          }
        },
        (err: HttpErrorResponse) => {
          this.isDeleting = false;
          console.error(`There was an error with trying to delete playlist ${this.userPlaylist?.id}`);
          console.error(err);

          if (err.status === 400) {
            this.message.error(`This playlist doesn't exist. This is not good. Playlist Id:${this.userPlaylist?.id}.`);
          } else {
            this.message.error(`Oops, something went wrong when trying to remove playlist with ID:${this.userPlaylist?.id}.`);
          }
        }
      );
    } else if (this.userAction && this.userAction.id) {
      this.userActionService.deleteUserAction(this.userAction.id).subscribe(
        () => {
          this.isDeleting = false;
          this.setButtonStates();
          this.message.success(`Deleted worked for '${this.userAction?.title}'`);
        },
        (err: HttpErrorResponse) => {
          console.error(`There was an error with trying to delete action ${this.userAction?.id}`);
          console.error(err);

          if (err.status === 400) {
            this.message.error(`This action doesn't exist. This is not good. Action Id:${this.userAction?.id}.`);
          } else {
            this.message.error(`Oops, something went wrong when trying to remove action with ID:${this.userAction?.id}.`);
          }
        }
      );
    } else {
      this.isDeleting = false;
    }
  }

  private updateUserElement() {
    if (this.userActivity) {
      this.isLoading = true;
      this.userActivitiesService.updateUserActivity(this.userActivity).subscribe(
        (ua: IUserActivity) => {
          this.userActivity = ua;
          this.setButtonStates(); // we used to do refreshActivity here but I'm not sure we need to. We've updated the cache.
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          console.error('Could not update activity ' + error);
        }
      );
    } else if (this.userAction !== undefined) {
      this.isLoading = true;
      this.userActionService.updateUserAction(this.userAction).subscribe(
        (res: IUserAction) => {
          this.userAction = res;
          this.setButtonStates();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          console.error('Could not update action ' + error);
        }
      );
    }
  }

  private updateTranstionStatus() {
    this.isLoading = true;
    if (this.userActivity && this.userActivity.id) {
      this.userActivitiesService.updateStateTransition(this.userActivity.id).subscribe(
        (ua) => {
          this.userActivity = ua;
          this.setButtonStates();
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.message.error(`Something went wrong there and we weren't able to update the activity for you. Try again.`);
          return console.error('Could not update StateTransition. Error: ' + error);
        }
      );
    } else if (this.userAction && this.userAction.id) {
      this.userActionService.updateActionStateTransition(this.userAction.id).subscribe(
        (ua: IUserAction) => {
          this.userAction = ua;
          this.setButtonStates();
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.message.error(`Something went wrong there and we weren't able to update the action for you. Try again.`);
          return console.error('Could not update StateTransition. Error: ' + error);
        }
      );
    }
  }

  private resetTimeOnDate(date: Date) {
    date.setHours(12);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  private getElementTransitionStatus() {
    return this.userActivity ? this.userActivity.transitionStatus : this.userAction ? this.userAction.transitionStatus : null;
  }

  private getElementStatus() {
    return this.userActivity ? getUserActivityStatus(this.userActivity) : this.userAction ? getUserActionStatus(this.userAction) : null;
  }
}
