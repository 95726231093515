<div id="search-guilds">
  <div class="search-bar-container">
    <div class="search-bar">
      <nz-input-group id="search-input-group" nzSearch [nzAddOnAfter]="suffixIconButton">
        <input #guildSearchBar id="search-bar-input" type="text" nz-input placeholder="Search for guilds" [(ngModel)]="query" (ngModelChange)="startSearch(query)" (keyup.enter)="startSearch(query)" #guildsSearch (click)="guildsSearch.select()" />
      </nz-input-group>
      <ng-template #suffixIconButton>
        <button id="search-button" nz-button nzType="primary" nzSearch (click)="searchForGuilds()"><span nz-icon nzType="search"></span></button>
      </ng-template>
    </div>
    <div class="show-all-container">
      <label nz-checkbox class="show-all-guilds-checkbox" id="toggle-button" [ngModel]="showAllGuilds" (ngModelChange)="toggleShowAll()">
        <div class="show-all-guilds-header">Show All Guilds</div>
      </label>
    </div>
  </div>
</div>
