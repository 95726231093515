<div class="guild-home">
  <nz-card class="guild-home-card">
    <nz-skeleton id="guild-loading-skeleton" *ngIf="isLoading || !guild" [nzActive]="true" [nzAvatar]="true" [nzParagraph]="{ rows: 10 }"></nz-skeleton>
    <div *ngIf="guild && !isLoading" class="guild-home-navbar">
      <div [ngClass]="{'header-container':!isSearchingForUsers, 'header-container-search-optimised':isSearchingForUsers, 'header-container-edit-mode': editGuildMode === editMode.edit}">
        <div *ngIf="guild.imageUrl" class="guild-image-container">
          <img class="guild-image" [src]="getGuildImage()" />
        </div>
        <div *ngIf="!guild.imageUrl && guild.imageIcon" class="guild-icon-container">
          <div class="{{ guild.imageIcon }} image-icon"></div>
        </div>
        <div *ngIf="!guild.imageUrl && !guild.imageIcon" class="guild-image-container">
          <img class="guild-image" [src]="getGuildPlaceholderImage()" />
        </div>

        <div class="guild-settings">
          <ng-container *ngTemplateOutlet="guildSettings"></ng-container>
        </div>

        <div class="guild-title-container">
          <div class="guild-title">
            <h3 id="guild-name"><b>{{ guild.name }}</b><i *ngIf="guild.visibility === guildVisibilityType.Private" class="private-guild-icon fa-solid fa-lock" nz-tooltip="This is a private guild"></i></h3>
            <span class="guild-settings-collapsed"><ng-container *ngTemplateOutlet="guildSettings"></ng-container></span>
          </div>
          <br />
          <div class="header-options">
            <h4 id="generic-industry" *ngIf="guild.profession === ''"><i class="icon fa-solid fa-briefcase"></i> Generic {{ guild.industry }}</h4>
            <h4 id="profession" *ngIf="guild.profession !== ''"><i class="icon fa-solid fa-briefcase"></i> {{ guild.profession }}</h4>
            <h4 id="specialisation" *ngIf="guild.specialisation !== ''"><i class="icon fa-solid fa-microscope"></i> {{ guild.specialisation }}</h4>
            <h4><i class="icon fa-solid fa-stairs" aria-hidden="true"></i> Levels {{ guild.levelMin }} - {{ guild.levelMax }}</h4>
            <h4 id="guild-location" *ngIf="guild.location"><i class="icon fa-solid fa-location-dot"></i> {{ guild.location.city }}, {{ guild.location.country }}</h4>
            <h4 id="guild-members-number" *ngIf="filteredGuildMembersByStatus.length > 0"><i class="icon fa fa-users"></i> {{ filteredGuildMembersByStatus.length }} members</h4>
            <h4 id="guild-members-number" *ngIf="filteredGuildMembersByStatus.length === 0"><i class="icon fa fa-users"></i> {{ filteredGuildMembersByStatus.length }} member</h4>
            <div class="guild-links-container" *ngIf="!editGuildLinks">
              <div class="guild-links" *ngIf="guild.externalLinks?.length >= 0 && (isAGuildMember || isAGuildOwner) && !isPendingApproval()">
                <div *ngIf="!editGuildLinks">
                  <sl-link-selector (addLinkEvent)="submitNewlyAddedLink($event)" (deleteLinkEvent)="deleteLink($event)" (editLinkEvent)="submitEditedLink($event)" [guildLinks]="guild.externalLinks" [guildId]="guild.id"></sl-link-selector>
                </div>
              </div>
            </div>
            <div class="guild-button-container">
              <button *ngIf="!isAGuildMember && !isAGuildOwner && !isPrivate" id="join-guild-button" nz-button nzType="primary" (click)="joinGuild()">Join Guild</button>
              <button *ngIf="!isAGuildMember && !isAGuildOwner && isPrivate && !isPendingApproval()" id="request-to-join-guild-button" nz-button nzType="primary" (click)="requestToJoinGuild()">Request to join</button>
              <button *ngIf="!isAGuildOwner && isPrivate && isPendingApproval()" id="pending-approval-button" nz-button nzType="primary" disabled>Pending approval...</button>
            </div>
          </div>
        </div>

        <ng-template #guildSettings>
          <nz-select *ngIf="isSearchingForUsers" [(ngModel)]="selectedMatchedUserPublicUrl" #userSearchInput class="search-users-input" type="text" nzPlaceHolder="Enter an email or public url" (keyup.enter)="searchForUsers()" nzAutoFocus nzShowSearch nzServerSearch
            (nzBlur)="onBlur()" [nzShowArrow]="false" (nzOnSearch)="startUserSearch($event)" (ngModelChange)="navigateToMatchedUserPublicProfile($event)" [nzNotFoundContent]="userNotFound">
            <ng-container *ngFor="let result of userSearchResults">
              <nz-option [nzValue]="result.publicUrl" nzCustomContent>
                <div class="matched-user" nzTooltipTitle="View profile" nzTooltipPlacement="top" nz-tooltip>
                  <nz-avatar class="matched-user-avatar" nzSize="small" nzIcon="user" nzSrc="{{result.image}}"></nz-avatar>
                  <span class="matched-user-name">{{ result.name + ' ' + result.surname }}</span>
                </div>
              </nz-option>
            </ng-container>
            <ng-template #userNotFound>
              <nz-empty nzNotFoundContent="No users found matching that email or public URL. Please double-check the spelling."></nz-empty>
            </ng-template>
          </nz-select>
          <div id="share-guild-container" *ngIf="((!isPendingApproval() && isPrivate && (isAGuildMember || isAGuildOwner)) || !isPrivate) && !isSearchingForUsers">
            <span class="action-icon-parent guild-role-tag">
              <nz-tag *ngIf="isAGuildOwner || (isAGuildMember && currentGuildMember && currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Owner)!.type)" [nzColor]="'gold'"><i class="{{ guildRoleProperties.get(guildRoleType.Owner)!.icon }}"></i> {{
                guildRoleProperties.get(guildRoleType.Owner)!.name }}</nz-tag>
              <nz-tag *ngIf="isAGuildMember && currentGuildMember && currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Leader)!.type" [nzColor]="'magenta'"><i class="{{ guildRoleProperties.get(guildRoleType.Leader)!.icon }}"></i> {{
                guildRoleProperties.get(guildRoleType.Leader)!.name }}</nz-tag>
              <nz-tag *ngIf="isAGuildMember && currentGuildMember && currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Member)!.type" [nzColor]="'geekblue'"><i class="{{ guildRoleProperties.get(guildRoleType.Member)!.icon }}"></i> {{
                guildRoleProperties.get(guildRoleType.Member)!.name }}</nz-tag>
              <nz-tag *ngIf="isAGuildMember && currentGuildMember && currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Newbie)!.type" [nzColor]="'green'"><i class="{{ guildRoleProperties.get(guildRoleType.Newbie)!.icon }}"></i> {{
                guildRoleProperties.get(guildRoleType.Newbie)!.name }}</nz-tag>
            </span>
            <span *ngIf="
              editGuildMode === editMode.new &&
              currentGuildMember &&
              (currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Leader)!.type || currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Owner)!.type || isAGuildOwner)
            " class="action-icon-parent"><i class="guild-edit action-icon fa fa-pencil" nzTooltipTitle="View / Edit guild" nzTooltipPlacement="top" nz-tooltip (click)="editGuild()"></i></span>
            <span *ngIf="editGuildMode === editMode.edit" class="action-icon-parent"><i class="guild-edit action-icon fa fa-stop" nzTooltipTitle="Cancel editing guild" nzTooltipPlacement="top" nz-tooltip (click)="cancelEdit()"></i></span>
            <i class="playlist-share action-icon fas fa-share" nzTooltipTitle="Share Guild With Others" nzTooltipPlacement="top" nz-tooltip (click)="shareGuild()"></i>
            <nz-badge id="waitlist-count-badge" *ngIf="isAGuildOwner || currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Leader)!.type" [nzCount]="guildWaitlistMembers.length" nzSize="small" class="action-icon-parent">
              <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
            </nz-badge>
            <span *ngIf="!isAGuildOwner && currentGuildMember.memberType !== guildRoleProperties.get(guildRoleType.Leader)!.type" class="action-icon-parent"><ng-container *ngTemplateOutlet="menuTemplate"></ng-container></span>

            <ng-template #menuTemplate>
              <i id="guild-menu-options" [ngClass]="guildWaitlistMembers.length && (isAGuildOwner || currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Leader)!.type) ? 'fa-solid fa-flag':'fa-solid fa-ellipsis-vertical'" class="action-icon"
                *ngIf="isAGuildMember || isAGuildOwner" nzTooltipTitle="More" nzTooltipPlacement="top" nz-tooltip (click)="toggleGuildMenuOptions()" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click" [nzPlacement]="'bottomRight'">
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item *ngIf="isAGuildOwner || currentGuildMember.memberType === guildRoleType.Leader" nzSelectable="false">
                      <span *ngIf="isPublicGuild()">Public guild</span>
                      <span *ngIf="!isPublicGuild()">Private guild</span>
                      <nz-switch class="visibility-switch" [(ngModel)]="isPrivate" [nzCheckedChildren]="privateGuildIcon" (click)="selectToggle($event)" [nzUnCheckedChildren]="publicGuildIcon" nz-popconfirm
                        [nzPopconfirmTitle]="isPublicGuild()? 'Are you sure you want to make this guild private ?' : 'Are you sure you want to make this guild public ?'" (nzOnCancel)="cancelToggle($event)" (nzOnConfirm)="changeGuildAccess($event)"
                        [nzDisabled]="isGuildAccessPopConfirmVisible">
                      </nz-switch>
                      <ng-template #privateGuildIcon><i class="fa-solid fa-lock"></i></ng-template>
                      <ng-template #publicGuildIcon><i class="fa-solid fa-lock-open"></i></ng-template>
                    </li>
                    <li id="waitlist-field" *ngIf="isAGuildOwner" nz-submenu nzTitle="Waitlist ({{guildWaitlistMembers.length}})" [nzDisabled]="isPublicGuild() ? true: false" [nzTooltipTitle]="isPublicGuild()?'The guild must be private for this feature to work': null"
                      nzTooltipPlacement="top" nz-tooltip>
                      <ul *ngIf="!guildWaitlistMembers.length">
                        <li><nz-empty nzNotFoundContent="No one in the waitlist"></nz-empty></li>
                      </ul>
                      <ul *ngFor="let member of guildWaitlistMembers">
                        <li nz-menu-item (click)="navigateToUserProfile(member.userId)">
                          <div class="guild-waitlist-member">
                            <nz-avatar class="guild-waitlist-member-avatar" nzIcon="user" nzSrc="{{member.imageBase64}}" nzTooltipTitle="View profile" nzTooltipPlacement="top" nz-tooltip></nz-avatar>
                            <span class="guild-waitlist-member-details"> {{member.firstName}} {{member.surname}}</span>
                            <div class="guild-waitlist-member-actions">
                              <span class="approve-guild-waitlist-member" nz-icon nzType="check-circle" nzTheme="outline" nzTooltipTitle="Approve" nzTooltipPlacement="top" nz-tooltip (click)="approveUserFromWaitlist(member.userId, $event)"></span>
                              <span class="reject-guild-waitlist-member" nz-icon nzType="close-circle" nzTheme="outline" nzTooltipTitle="Reject" nzTooltipPlacement="top" nz-tooltip (click)="removeUserFromWaitlist(member.userId, $event)"></span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li id="search-for-users-button" *ngIf="isAGuildOwner || currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Leader)!.type" nz-menu-item (click)="searchForUsers()">
                      Invite
                    </li>
                    <li id="leave-guild-button" *ngIf="isAGuildMember && !isAGuildOwner && !isPendingApproval()" nzDanger nz-menu-item (click)="leaveGuild()">
                      Leave Guild
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </i>
            </ng-template>
          </div>
        </ng-template>

        <div class="section-tabs" *ngIf="(!isPendingApproval() && isPrivate && (isAGuildMember || isAGuildOwner)) || !isPrivate">
          <nz-tabset class="guild-tabset" nzLinkRouter [nzTabPosition]="'top'" [nzType]="'line'" *ngIf="editGuildMode === 1" (nzSelectChange)="onTabChange($event)">
            <nz-tab id="guild-details-tab">
              <a id="guild-details-tab-link" *nzTabLink nz-tab-link [routerLink]="['/guilds/home/', guildUrlName, (tabs | async)[0]]">&nbsp;&nbsp;&nbsp; About &nbsp;&nbsp;&nbsp;</a>
              <div class="guild-about-container">
                <sl-guild-details [guild]="guild"></sl-guild-details>
              </div>
            </nz-tab>
            <nz-tab id="private-playlist-activities-tab">
              <a id="private-playlist-activities-tab-link" *nzTabLink nz-tab-link [routerLink]="['/guilds/home/', guildUrlName,  (tabs | async)[1]]" [queryParams]="{ goalId: goalId, playlistId: playlistId }">Playlists</a>
              <div *ngIf="(selectedTab | async) === 'playlists'" class="private-playlist-activities-container">
                <sl-shared-playlist [guild]="guild" [guildUrlName]="guildUrlName" [selectMode]="selectMode" [selectedGuildMembers]="selectedGuildMembers | async" [isInteractable]="isAGuildMember || isAGuildOwner"></sl-shared-playlist>
              </div>
            </nz-tab>
            <nz-tab id="recommended-activities-tab">
              <a id="recommended-activities-tab-link" *nzTabLink nz-tab-link [routerLink]="['/guilds/home/', guildUrlName, (tabs | async)[2]]" [queryParams]="{ goalId: goalId, activityId: activityId }">Activities</a>
              <div *ngIf="(selectedTab | async) === 'activities'" class="guild-recommended-activities-container">
                <sl-guild-top-activities [guild]="guild" [guildUrlName]="guildUrlName" [selectMode]="selectMode" [selectedGuildMembers]="selectedGuildMembers | async"></sl-guild-top-activities>
              </div>
            </nz-tab>
          </nz-tabset>

          <div class="guild-editor" *ngIf="editGuildMode === modeType.edit">
            <sl-guild-editor [mode]="modeType.edit" [incomingGuild]="guild" (updateFieldsWithValuesFromEditedGuild)="(guild.id)" (editingStatus)="checkGuildEditStatus($event)" (editedGuild)="submitEditedGuild($event)"></sl-guild-editor>
          </div>
        </div>
        <div class="guild-occupants-grid" *ngIf="(!isPendingApproval() && isPrivate && (isAGuildMember || isAGuildOwner)) || !isPrivate" [ngStyle]="{'display' : editGuildMode === editMode.new ? 'grid' : 'none'}">
          <div class="guild-occupants-header" [ngStyle]="{'visibility': selectMode ? 'visible' : 'hidden' }">
            <!-- Sam: Commented out the remove user function until I address it down the line -->
            <!-- <i id="remove-member" class="remove-user-icon" nz-icon nzType="delete" nzTheme="outline" nz-popconfirm nzPopconfirmTitle="Are you sure?" nz-tooltip nzTooltipTitle="Remove user(s)" (nzOnConfirm)="removeGuildMember()"></i> -->
            <i class="exit-select-mode-icon" nz-icon nzType="close" nzTheme="outline" nz-tooltip nzTooltipTitle="Deselect user(s)" (click)="exitSelectMode()"></i>
          </div>

          <div class="guild-occupants-container">
            <div class="guild-owners guild-occupants" id="owners" cdkDropList [cdkDropListData]="{ members: memberRoleOwners, role: guildRoleProperties.get(guildRoleType.Owner)!.type }" [cdkDropListConnectedTo]="['newbies', 'members', 'leaders']"
              (cdkDropListDropped)="drop($event)">
              <h4><i class="{{ guildRoleProperties.get(guildRoleType.Owner)!.icon }}"></i> Owners</h4>
              <nz-avatar-group class="member-icons">
                <div *ngFor="let memberRole of memberRoleOwners" cdkDrag [cdkDragData]="memberRole.guildMember.userId" [cdkDragDisabled]="
                      ((!isAGuildMember && !isAGuildOwner) || (currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Leader).type 
                      || currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Member).type || currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Newbie).type))">
                  <nz-avatar id="member-avatar" class="ant-card-hoverable" (click)="selectGuildMemberAvatar(memberRole)" [ngClass]="{
                    'member-avatar-owner': memberRole.guildMember.memberType === guildRoleProperties.get(guildRoleType.Owner).type && !selectMode,
                    'member-avatar-owner-checked': selectMode && memberRole.checked                                 
                    }" nz-button nzIcon="user" nz-tooltip nzTooltipTitle="{{ memberRole.guildMember.firstName }} {{ memberRole.guildMember.surname }}" nzSrc="{{ memberRole.guildMember.imageBase64 }}"
                    (contextmenu)="selectMode ? viewGuildMemberContextMenu($event, guildMemberMenu, memberRole.guildMember) : null">
                  </nz-avatar>
                </div>
              </nz-avatar-group>
            </div>

            <div class="guild-leaders guild-occupants" id="leaders" cdkDropList [cdkDropListData]="{ members: memberRoleLeaders, role: guildRoleProperties.get(guildRoleType.Leader)!.type }" [cdkDropListConnectedTo]="['newbies', 'members', 'owners']"
              (cdkDropListDropped)="drop($event)">
              <h4>
                <nz-badge id="selected-leader-count-badge" [nzStyle]="{ backgroundColor: '#c41d7f' }" [nzCount]="selectedRoleLeaders.length" [nzOffset]="[10, 0]">
                  <i class="{{ guildRoleProperties.get(guildRoleType.Leader)!.icon }}"></i> Leaders
                </nz-badge>
              </h4>
              <nz-avatar-group class="member-icons">
                <div *ngFor="let memberRole of memberRoleLeaders" cdkDrag [cdkDragData]="memberRole.guildMember.userId"
                  [cdkDragDisabled]="(!isAGuildMember && !isAGuildOwner) || (currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Member).type || currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Newbie).type)">
                  <nz-avatar id="member-avatar" class="ant-card-hoverable" (click)="selectGuildMemberAvatar(memberRole)" [ngClass]="{                                          
                    'member-avatar-leader': memberRole.guildMember.memberType === guildRoleProperties.get(guildRoleType.Leader).type && !selectMode,
                    'member-avatar-leader-checked': selectMode && memberRole.checked                        
                    }" nz-button nzIcon="user" nz-tooltip nzTooltipTitle="{{ memberRole.guildMember.firstName }} {{ memberRole.guildMember.surname }}" nzSrc="{{ memberRole.guildMember.imageBase64 }}"
                    (contextmenu)="selectMode ? viewGuildMemberContextMenu($event, guildMemberMenu, memberRole.guildMember) : null">
                  </nz-avatar>
                </div>
              </nz-avatar-group>
            </div>

            <div class="guild-members guild-occupants" id="members" cdkDropList [cdkDropListData]="{ members: memberRoleMembers, role: guildRoleProperties.get(guildRoleType.Member)!.type }" [cdkDropListConnectedTo]="['newbies', 'leaders', 'owners']"
              (cdkDropListDropped)="drop($event)">
              <h4>
                <nz-badge id="selected-member-count-badge" [nzStyle]="{ backgroundColor: '#1d39c4' }" [nzCount]="selectedRoleMembers.length" [nzOffset]="[10, 0]">
                  <i class="{{ guildRoleProperties.get(guildRoleType.Member)!.icon }}"></i> Members
                </nz-badge>
              </h4>
              <nz-avatar-group class="member-icons">
                <div id="members" *ngFor="let memberRole of memberRoleMembers" cdkDrag [cdkDragData]="memberRole.guildMember.userId" [cdkDragDisabled]="(!isAGuildMember && !isAGuildOwner) || (currentGuildMember.memberType === guildRoleProperties.get(guildRoleType.Newbie).type)">
                  <nz-avatar id="member-avatar" class="ant-card-hoverable" (click)="selectGuildMemberAvatar(memberRole)" [ngClass]="{                             
                      'member-avatar-member': memberRole.guildMember.memberType === guildRoleProperties.get(guildRoleType.Member).type && !selectMode,
                      'member-avatar-member-checked': selectMode && memberRole.checked
                      }" nz-button nzIcon="user" nz-tooltip nzTooltipTitle="{{ memberRole.guildMember.firstName }} {{ memberRole.guildMember.surname }}" nzSrc="{{ memberRole.guildMember.imageBase64 }}"
                    (contextmenu)="selectMode ? viewGuildMemberContextMenu($event, guildMemberMenu, memberRole.guildMember) : null">
                  </nz-avatar>
                </div>
              </nz-avatar-group>
            </div>

            <div class="guild-newbies guild-occupants" id="newbies" cdkDropList [cdkDropListData]="{ members: memberRoleNewbies, role: guildRoleProperties.get(guildRoleType.Newbie)!.type }" [cdkDropListConnectedTo]="['leaders', 'members', 'owners']"
              (cdkDropListDropped)="drop($event)">
              <h4>
                <nz-badge id="selected-newbie-count-badge" [nzStyle]="{ backgroundColor: '#389e0d' }" [nzCount]="selectedRoleNewbies.length" [nzOffset]="[10, 0]">
                  <i class="{{ guildRoleProperties.get(guildRoleType.Newbie)!.icon }}"></i> Newbies
                </nz-badge>
              </h4>
              <nz-avatar-group class="member-icons">
                <div *ngFor="let memberRole of memberRoleNewbies" cdkDrag [cdkDragData]="memberRole.guildMember.userId" [cdkDragDisabled]="!isAGuildMember && !isAGuildOwner">
                  <nz-avatar id="member-avatar" class="ant-card-hoverable" (click)="selectGuildMemberAvatar(memberRole)" [ngClass]="{
                    'member-avatar-newbie': memberRole.guildMember.memberType === guildRoleProperties.get(guildRoleType.Newbie).type && !selectMode,
                    'member-avatar-newbie-checked': selectMode && memberRole.checked
                    }" nz-button nzIcon="user" nz-tooltip nzTooltipTitle="{{ memberRole.guildMember.firstName }} {{ memberRole.guildMember.surname }}" nzSrc="{{ memberRole.guildMember.imageBase64 }}"
                    (contextmenu)="selectMode ? viewGuildMemberContextMenu($event, guildMemberMenu, memberRole.guildMember) : null">
                  </nz-avatar>
                </div>
              </nz-avatar-group>
            </div>
            <nz-dropdown-menu id="guildMemberContextMenu" #guildMemberMenu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="navigateToUserProfile(selectedGuildMember.userId)">View Profile</li>
              </ul>
            </nz-dropdown-menu>
            <nz-dropdown-menu id="guildOwnerContextMenu" #guildOwnerMenu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="navigateToUserProfile(guild.createdById)">View Profile</li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </div>
      </div>
      <div *ngIf="isPendingApproval() || (!isAGuildMember && !isAGuildOwner && isPrivate)" class="not-a-private-guild-member">
        <nz-divider></nz-divider>
        <i class="not-a-private-guild-member-icon fa-solid fa-lock"></i>
        <span class="not-a-private-guild-member-prompt">You must be a member of this guild to view this information</span>
      </div>
    </div>
  </nz-card>
</div>