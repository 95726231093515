<div class="panel-container">
  <div class="grid-container">
    <div class="grid-fields">
      <div *ngIf="playlist.industry" nz-typography>
        <strong>Industry:</strong>
      </div>
      <div *ngIf="playlist.profession" nz-typography>
        <strong>Profession:</strong>
      </div>
      <div *ngIf="playlist.specialisation" nz-typography>
        <strong>Specialisation:</strong>
      </div>
      <div *ngIf="playlist.relatedTrackName" nz-typography>
        <strong>Track:</strong>
      </div>
      <div *ngIf="playlist.levelFrom && playlist.levelTo" nz-typography>
        <strong>Level Range:</strong>
      </div>
      <div nz-typography>
        <strong>Author:</strong>
      </div>
      <div *ngIf="playlist.dateCreated" nz-typography>
        <strong>Date Created:</strong>
      </div>
      <div *ngIf="playlist.version" nz-typography>
        <strong>Version:</strong>
      </div>
    </div>
    <div class="grid-info">
      <div id="playlist-industry" *ngIf="playlist.industry" nz-typography nzEllipsis>{{ playlist.industry }}</div>
      <div id="playlist-profession" *ngIf="playlist.profession" nz-typography nzEllipsis>{{ playlist.profession }}</div>
      <div id="playlist-specialisation" *ngIf="playlist.specialisation" nz-typography nzEllipsis>{{ playlist.specialisation }}</div>
      <div id="playlist-relatedTrackName" *ngIf="playlist.relatedTrackName" nz-typography nzEllipsis>{{ playlist.relatedTrackName }}</div>
      <div id="playlist-levelRange" *ngIf="playlist.levelFrom && playlist.levelTo" nz-typography>{{ playlist.levelFrom }} - {{ playlist.levelTo }}</div>
      <div id="playlist-name" nz-typography nzEllipsis>{{ playlist.createdByFirstName }} {{ playlist.createdByLastName }}</div>
      <div id="playlist-datecreated" *ngIf="playlist.dateCreated" nz-typography>{{ playlist.dateCreated | date: 'mediumDate' }}</div>
      <div id="playlist-version" *ngIf="playlist.version" nz-typography nzEllipsis>{{ playlist.version }}</div>
    </div>
    <img class="grid-image" [src]="getCardImage()" alt="Image of the playlist" />
  </div>
  <div *ngIf="playlist.description" class="panel-description">
    <h5><b>Description</b></h5>
    <p [innerHTML]="playlist.description"></p>
  </div>
  <div class="panel-add-backlog">
    <div *ngIf="!isActivityListMode">
      <i *ngIf="addedToBacklog" class="panel-add-backlog-button checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'"></i>
      <button id="add-playlist-button" *ngIf="buttonUse === buttonType.Add && !sharedPlaylist.userAlreadyAdded" class="panel-add-backlog-button" nz-button nzType="primary" (click)="addToBacklog($event)">
        <i class="{{buttonProperties.get(buttonType.Add).icon}}"></i>
        <span class="add-button-text">
          {{buttonProperties.get(buttonType.Add).name}}
        </span>
      </button>

      <button id="share-playlist-button" *ngIf="buttonUse === buttonType.Share" class="panel-add-backlog-button" nz-button nzType="primary" (click)="sharePlaylist()">
        <i class="{{buttonProperties.get(buttonType.Share).icon}}"></i>
        <span class="add-button-text">
          {{buttonProperties.get(buttonType.Share).name}}
        </span>
      </button>
    </div>
  </div>
  <nz-divider></nz-divider>
  <div>
    <h5><b>Activities</b></h5>
  </div>
  <div class="flex-card-layout">
    <div class="flex-card" *ngFor="let item of playlist.playlistActivitiesVm|slice:0:activitiesShown let i=index">
      <sl-activity-card *ngIf="item" class="activity-card" [isInteractable]="isInteractable" [isActivityListMode]="isActivityListMode" [recommendation]="item" [goalSkills]="goalSkills" [goalId]="goalId" (activityAdded)="handleActivityAdded($event)"
        (drawerVisible)="activityDrawerVisible($event)" (click)="preventEventPropagation($event)" [selectedGuildMembers]="selectedGuildMembers" [learningTypes]="learningTypes">
      </sl-activity-card>
    </div>
  </div>
  <div class="showMore">
    <button aria-label="Load More Activities" nz-button class="show-more-button" nzSize="large" nzType="default" *ngIf="playlist.playlistActivitiesVm && activitiesShown < playlist.playlistActivitiesVm.length" (mouseenter)="increaseActivitiesShown()">
      <i class="fa fa-chevron-down"></i>
    </button>
  </div>
</div>