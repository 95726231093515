import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { UserManager, UserManagerSettings, User, WebStorageStateStore } from 'oidc-client';
import { BaseService } from './base.service';
import { getWindow } from './helper.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private manager = new UserManager(getClientSettings());
  private currentUser: User | null = null;
  private loggedInFlowComplete = new Subject<boolean>();

  constructor(private http: HttpClient) {
    super();
    // console.log('*** AuthService Constructor');
    this.getLoggedInUser();
  }

  get authorizationHeaderValue(): string {
    if (this.currentUser === null) {
      throw new Error('we cannot get the header if the user is not defined');
    }

    return `${this.currentUser.token_type} ${this.currentUser.access_token}`;
  }

  get userId(): string {
    return this.currentUser != null ? this.currentUser.profile.sub : '';
  }

  get name(): string {
    return this.currentUser != null ? (this.currentUser.profile.name ? this.currentUser.profile.name : '') : '';
  }

  get email(): string {
    return this.currentUser != null ? (this.currentUser.profile.email ? this.currentUser.profile.email : '') : '';
  }

  isAuthenticated(): Promise<boolean> {
    return this.manager.getUser().then(
      (user) => {
        this.currentUser = user;
        return this.isLoggedIn();
      },
      () => false
    );
  }

  public login(): void {
    console.log('*** AuthService - login');
    this.manager.signinRedirect();
  }

  public logout(): void {
    this.manager.signoutRedirect();
    this.currentUser = null;
    this.loggedInFlowComplete.next(false);
  }

  public completeAuthentication(): Promise<void> {
    // console.log('*** AuthService.ts - completeAuthentication before processing signinRedirect');
    return this.manager.signinRedirectCallback().then((user) => {
      // console.log(`*** AuthService completeAuthentication: User=${JSON.stringify(user)}`);
      this.currentUser = user;
      this.loggedInFlowComplete.next(this.isLoggedIn());
    });
  }

  public isLoggedInChange(): Observable<boolean> {
    return this.loggedInFlowComplete.asObservable();
  }

  public isLoggedIn(): boolean {
    return this.currentUser != null && !this.currentUser.expired;
  }

  private getLoggedInUser() {
    this.manager
      .getUser()
      .then((user) => {
        if (user) {
          this.currentUser = user;
        }
        this.loggedInFlowComplete.next(this.isLoggedIn());
      })
      .catch((err) => {
        console.error(`*** Failed to login in auth.service. Error: ${err}`);
      });
  }
}


export function getClientSettings(): UserManagerSettings {
  const window = getWindow();

  return {
    authority: window.location.origin,
    client_id: 'sealadderapp',
    redirect_uri: window.location.origin + '/auth-callback',
    post_logout_redirect_uri: window.location.origin + '/landing',
    response_type: 'id_token token',
    scope: 'openid profile email api.read', // offline_access ?  Perhaps we can add this in the future, but it's not a known scope on the server
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    silent_redirect_uri: window.location.origin + '/assets/silent-refresh.html',
    accessTokenExpiringNotificationTime: 4,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    monitorSession: true,
  } as UserManagerSettings;
}
