<div class="content">
  <nz-table style="padding: 10px" id="guilds" #GuildsTable [nzData]="guilds" [nzShowPagination]="true" [nzShowQuickJumper]="true" [nzPageSizeOptions]="[30, 50, 100, 200]" [nzPageSize]="50" [nzShowSizeChanger]="true" nzSize="small" nzPaginationPosition="both">
    <thead>
      <tr>
        <th>Name</th>
        <th>Members</th>
        <th>Profession</th>
        <th>Specialisation</th>
        <th>Level From</th>
        <th>Level To</th>
        <th>Related Track Name</th>
        <th>Date Created</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let guild of GuildsTable.data">
        <td class="guild-name">{{ guild.name }}</td>
        <!-- <td>{{guild.members}}</td> -->
        <td>{{ guild.specialisation }}</td>
        <td>{{ guild.profession }}</td>
        <td>{{ guild.levelFrom }}</td>
        <td>{{ guild.levelTo }}</td>
        <td>{{ guild.relatedTrackName }}</td>
        <td>{{ guild.dateCreated }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>
