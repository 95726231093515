import { IGuildExternalLink } from './../modules-guilds/models/guild-external-link.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGuildMember } from '../modules-guilds/models/guild-member.model';
import { IGuildTopActivities } from '../modules-guilds/models/guild-top-activities.model';
import { IGuild } from '../modules-guilds/models/guild.model';
import { IPaginatedGuild } from '../modules-guilds/models/paginated-guild.model';
import { EGuildVisibilityType } from '../modules-guilds/types/guild-visibility.type';
import { IGuildInvitedUser } from '../modules-guilds/models/guild-invited-user.model';

export interface GuildLinkData {
  faviconUrl: string;
  pageTitle: string;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class GuildService {
  private controller = '/api/Guilds';

  constructor(private http: HttpClient) { }

  getGuilds(): Observable<IGuild[]> {
    const endpoint = this.controller + `/GetGuilds`;
    return this.http.get<IGuild[]>(endpoint, httpOptions);
  }

  getGuildById(guildId: number): Observable<IGuild> {
    const endpoint = this.controller + `/GetGuildById`;
    return this.http.get<IGuild>(endpoint, { params: { guildId } });
  }

  getGuildByName(guildName: string): Observable<IGuild> {
    const endpoint = this.controller + `/GetGuildByName`;
    return this.http.get<IGuild>(endpoint, { params: { guildName } });
  }

  searchGuilds(searchQuery: string, pageNumber: number, pageSize: number, showAllGuilds: boolean): Observable<IPaginatedGuild> {
    const endpoint = this.controller + `/SearchGuilds`;
    return this.http.get<IPaginatedGuild>(endpoint, { params: { searchQuery, page: pageNumber.toString(), pageSize: pageSize.toString(), showAllGuilds: showAllGuilds.toString() } });
  }

  addGuild(guild: IGuild): Observable<IGuild> {
    if (!guild) {
      throw new Error('Guild cannot be undefined at this point');
    }

    const endpoint = this.controller + `/AddGuild`;
    return this.http.post<IGuild>(endpoint, guild, httpOptions);
  }

  editGuild(guild: IGuild): Observable<IGuild> {
    if (!guild) {
      throw new Error('Guild cannot be undefined at this point');
    }

    const endpoint = this.controller + `/UpdateGuild`;
    return this.http.put<IGuild>(endpoint, guild, httpOptions);
  }

  getGuildMembers(guildId: number): Observable<IGuildMember[]> {
    const endpoint = this.controller + `/GetGuildMembers`;
    return this.http.get<IGuildMember[]>(endpoint, { params: { guildId: guildId.toString() } });
  }

  changeGuildAccess(guildId: number, newVisibilityType: EGuildVisibilityType): Observable<IGuild> {
    const endpoint = this.controller + `/ChangeGuildAccess`;
    return this.http.post<IGuild>(endpoint, { options: { observe: 'response', responseType: 'json' } }, { params: { guildId: guildId.toString(), newVisibilityType: newVisibilityType.toString() } });
  }

  requestToJoinGuild(guildId: number): Observable<IGuildMember> {
    const endpoint = this.controller + `/RequestToJoinGuild`;
    return this.http.post<IGuildMember>(endpoint, guildId, httpOptions);
  }

  joinGuild(guildId: number): Observable<IGuild> {
    const endpoint = this.controller + `/JoinGuild`;
    return this.http.post<IGuild>(endpoint, guildId, httpOptions);
  }

  seedGuilds(): Observable<string> {
    const endpoint = this.controller + `/SeedGuilds`;
    return this.http.post<string>(endpoint, '', httpOptions);
  }

  getMyGuilds(): Observable<IGuild[]> {
    const endpoint = this.controller + `/MyGuilds`;
    return this.http.get<IGuild[]>(endpoint);
  }

  getTopActivities(guildId: number): Observable<IGuildTopActivities> {
    const endpoint = this.controller + `/GetTopActivities`;
    return this.http.get<IGuildTopActivities>(endpoint, { params: { guildId: guildId.toString() } });
  }

  getTopActivitiesCompletedByGuildUsers(guildId: number, guildMemberIds: string[]): Observable<IGuildTopActivities> {
    const endpoint = this.controller + `/GetTopActivitiesCompletedByGuildUsers`;
    return this.http.get<IGuildTopActivities>(endpoint, { params: { guildId: guildId.toString(), guildMemberIds } });
  }

  leaveGuild(guild: IGuild) {
    const endpoint = this.controller + `/LeaveGuild`;
    return this.http.post(endpoint, guild.id);
  }

  approveUserFromWaitlist(guildId: number, waitlistUserId: string): Observable<IGuildMember> {
    const endpoint = this.controller + `/ApproveUserFromWaitlist`;
    return this.http.post<IGuildMember>(endpoint, { options: { observe: 'response', responseType: 'json' } }, { params: { guildId: guildId.toString(), waitlistUserId } });
  }

  removeUserFromWaitlist(guildId: number, waitlistUserId: string): Observable<IGuildMember> {
    const endpoint = this.controller + `/RemoveUserFromWaitlist`;
    return this.http.post<IGuildMember>(endpoint, { options: { observe: 'response', responseType: 'json' } }, { params: { guildId: guildId.toString(), waitlistUserId } });
  }

  removeGuildMember(guildId: number, userId: string): Observable<boolean> {
    const endpoint = this.controller + `/RemoveMemberFromGuild`;
    return this.http.post<boolean>(endpoint, { options: { observe: 'response', responseType: 'json' } }, { params: { guildId: guildId.toString(), userId } });
  }

  addGuildLink(link: IGuildExternalLink): Observable<IGuildExternalLink> {
    const endpoint = this.controller + `/AddGuildLink`;
    return this.http.post<IGuildExternalLink>(endpoint, link, httpOptions);
  }

  deleteGuildLink(link: IGuildExternalLink): Observable<boolean> {
    const endpoint = this.controller + `/DeleteGuildLink`;
    return this.http.post<boolean>(endpoint, link, httpOptions);
  }

  editGuildLinks(link: IGuildExternalLink): Observable<IGuildExternalLink> {
    const endpoint = this.controller + `/EditGuildLinks`;
    return this.http.post<IGuildExternalLink>(endpoint, link, httpOptions);
  }

  getGuildLinks(guildId: number): Observable<IGuildExternalLink[]> {
    const endpoint = this.controller + `/GetGuildLinks`;
    return this.http.get<IGuildExternalLink[]>(endpoint, { params: { guildId: guildId.toString() } });
  }

  getGuildLinkFavicon(guildLink: string): Observable<GuildLinkData> {
    const endpoint = this.controller + `/GetGuildLinkFavicon`;
    return this.http.get<GuildLinkData>(endpoint, { params: { guildLink } });
  }

  assignGuildRole(guildId: number, newRoleValue: number, guildMemberUserId: string): Observable<IGuildMember> {
    const endpoint = this.controller + `/ChangeGuildRole`;
    return this.http.post<IGuildMember>(endpoint, { options: { observe: 'response', responseType: 'json' } }, { params: { guildId: guildId.toString(), newRoleValue: newRoleValue.toString(), guildMemberUserId } });
  }

  inviteUserToGuild(guildId: number, invitedUserPublicUrl: string): Observable<IGuildInvitedUser> {
    const endpoint = this.controller + `/InviteUserToGuild`;
    return this.http.post<IGuildInvitedUser>(endpoint, { options: { observe: 'response', responseType: 'json' } }, { params: { guildId: guildId.toString(), invitedUserPublicUrl } });
  }

  hasUserBeenInvitedToGuild(guildId: number, invitedUserPublicUrl: string): Observable<boolean> {
    const endpoint = this.controller + `/HasUserBeenInvitedToGuild`;
    return this.http.get<boolean>(endpoint, { params: { guildId: guildId.toString(), invitedUserPublicUrl } });
  }

  public markCurrentGuildOwnersAsMembers(): Observable<number[]> {
    const url = this.controller + '/MarkCurrentGuildOwnersAsMember';
    return this.http.get<number[]>(url, httpOptions);
  }
}