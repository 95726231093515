import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAppData } from '../models/app-data.model';
import { map } from 'rxjs/operators';
import { AppDetailsServiceData } from './app-details.service.data.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AppDetailsService {
  endpoint = '/api/AppDetails/';

  constructor(private http: HttpClient, private appDetailsServiceData: AppDetailsServiceData) {}

  getAppDetailsData(): Observable<IAppData> {
    const url = this.endpoint + 'AppData';
    return this.http.get<IAppData>(url, httpOptions).pipe(
      map((resultAppData: IAppData) => {
        this.appDetailsServiceData.init(resultAppData);
        return resultAppData;
      })
    );
  }

  isProduction(): boolean {
    if (this.appDetailsServiceData.getVersion().includes('P')) {
      return true;
    }

    return false;
  }
}
