/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

// models
import { IUrlMetadata, IActivityMetadata } from '../models/user-activity-metadata.model';
import { IUserActivity } from '../models/user-activity.model';
import { IUserActivitySkill } from '../models/user-activity-skill.model';
import { IUserBacklog } from '../models/user-backlog.model';

// types
import { IUnassignedActivity } from '../models/unassigned-activity.model';

// services
import { UserActivitiesServiceData } from './user-activities.service.data';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class UserActivitiesService {
  private endpoint = '/api/UserActivities';

  private initialising = false;

  constructor(private http: HttpClient, private userActivitiesServiceData: UserActivitiesServiceData) {
    this.initialiseActivitiesFromServer();
  }

  public getActivitiesUpdatedEvent(): Subject<boolean> {
    return this.userActivitiesServiceData.activitiesUpdatedEvent();
  }

  public updateActivitiesCache(): void {
    this.http.get<IUserActivity[]>(this.endpoint, httpOptions).subscribe(
      (activities) => {
        this.reInitialiseFromScratch();
        this.userActivitiesServiceData.initialiseActivities(activities);
      },
      (error) => {
        console.error(`*** UserActivitiesService: updateActivities, error when getting the activities. Error: ${error}`);
      }
    );
  }

  public getUserActivities(): IUserActivity[] {
    let activities = this.userActivitiesServiceData.getActivities();
    if (activities !== undefined) {
      return activities;
    }

    activities = [] as IUserActivity[];
    return activities;
  }

  public getUnassignedActivities(goalId: number): Observable<IUnassignedActivity[]> {
    const url = this.endpoint + `/GetUnassignedActivitiesForGoal`;

    return this.http.get<IUnassignedActivity[]>(url, { params: { goalId } });
  }

  public assignUserActivitiesToGoal(userActivityIds: number[], goalId: number): Observable<IUserActivity[]> {
    const url = this.endpoint + '/AssignUserActivitiesToGoal';
    const body = { userActivityIds, goalId };

    return this.http.post<IUserActivity[]>(url, body, httpOptions).pipe(
      map((resultActivities: IUserActivity[]) => {
        const activities = this.userActivitiesServiceData.updateActivities(resultActivities, true);
        return activities;
      })
    );
  }

  // this implementation is really just for the admin component which gets ALL userActivities and doesn't put them into the data service.
  public getActivitiesListAll(): Observable<IUserActivity[]> {
    const url = this.endpoint + '/GetAllUserActivities';
    return this.http.get<IUserActivity[]>(url, httpOptions);
  }

  public addBacklogItem(backlog: IUserBacklog): Observable<IUserActivity> {
    const url = this.endpoint + '/AddBacklogManual';
    return this.http.post<IUserActivity>(url, backlog, httpOptions).pipe(
      map((resultActivity: IUserActivity) => {
        this.userActivitiesServiceData.addActivity(resultActivity);
        return resultActivity;
      })
    );
  }

  public addBacklogItemToGoal(activityId: number, goalId: number): Observable<IUserActivity> {
    const url = this.endpoint + '/AddActivityToGoal';
    const body = { goalId: goalId.toString(), activityId: activityId.toString() };

    return this.http.post<IUserActivity>(url, body, httpOptions).pipe(
      map((resultActivity: IUserActivity) => {
        this.userActivitiesServiceData.addActivity(resultActivity);
        return resultActivity;
      })
    );
  }

  public updateUserActivity(activity: IUserActivity): Observable<IUserActivity> {
    const url = this.endpoint;
    return this.http.put<IUserActivity>(url, activity, httpOptions).pipe(
      map((resultActivity: IUserActivity) => {
        this.userActivitiesServiceData.updateActivity(resultActivity, false);
        return resultActivity;
      })
    );
  }

  public removeUserActivitiesByIdFromDataService(userActivityIds: number[]) {
    this.userActivitiesServiceData.delecteUserActivitiesById(userActivityIds);
  }

  public completeUserActivity(activity: IUserActivity, complete: boolean = true): Observable<IUserActivity> {
    const url = this.endpoint + `/CompleteUserActivity?id=${activity.id}&complete=${complete}`;

    return this.http.put<IUserActivity>(url, '', httpOptions).pipe(
      map((retUserActivity: IUserActivity) => {
        this.userActivitiesServiceData.updateActivityCompletedStatus(retUserActivity, complete);
        return retUserActivity;
      })
    );
  }

  public updateMarkdownNotes(activityId: number, notes: string): Observable<IUserActivity> {
    const id = `id=${activityId}`;
    const body = { activityId: activityId.toString(), notes: notes.toString() };

    const url = this.endpoint + `/UpdateMarkdownNotes?${id}`;
    return this.http.put<IUserActivity>(url, body, httpOptions);
  }

  public updateStateTransition(activityId: number): Observable<IUserActivity> {
    const id = `id=${activityId}`;
    const url = this.endpoint + `/UpdateUserTransitionState?${id}`;
    return this.http.put<IUserActivity>(url, activityId, httpOptions).pipe(
      map((resultActivity: IUserActivity) => {
        this.userActivitiesServiceData.updateActivity(resultActivity, false);
        return resultActivity;
      })
    );
  }

  public seedDatabase(): Observable<boolean> {
    const url = this.endpoint;
    return this.http.post<boolean>(url, '', httpOptions);
  }

  public getBacklog(goalId: number): Observable<IUserBacklog[]> {
    const url = this.endpoint + `/GetBacklog?goalId=${goalId}`;
    return this.http.get<IUserBacklog[]>(url);
  }

  public getMetadata(url: string): Observable<IUrlMetadata> {
    const newUrl = url.replace(/&/g, '%26');
    const endpoint = this.endpoint + `/GetMetadata?url=${newUrl}`;
    return this.http.get<IUrlMetadata>(endpoint);
  }

  public getActivityMetadata(url: string): Observable<IActivityMetadata> {
    const newUrl = url.replace(/&/g, '%26');
    const endpoint = this.endpoint + `/GetActivityMetadata?url=${newUrl}`;
    return this.http.get<IActivityMetadata>(endpoint);
  }

  public getUncategorisedUserActivitySkills(): Observable<IUserActivitySkill[]> {
    const url = this.endpoint + '/GetUncategorisedUserActivitySkills';
    return this.http.get<IUserActivitySkill[]>(url, httpOptions);
  }

  public addMetadataToExisitingActivities(): Observable<boolean> {
    const url = this.endpoint + '/AddMetadataToExisitingActivities';
    return this.http.put<boolean>(url, '');
  }

  public updateOldUserActivities(): Observable<boolean> {
    const url = this.endpoint + '/UpdateOldUserActivitiesToNewUserActivitiesAndActivityType';
    return this.http.get<boolean>(url, httpOptions);
  }

  public fixAllProviderOnActivities(): Observable<boolean> {
    const url = this.endpoint + '/FixProviderOnActivities';
    const output = this.http.post<boolean>(url, { options: { observe: 'response', responseType: 'json' } }, { params: { testOnly: 'false' } });
    return output;
  }

  public hasUserAddedActivity(activityTitle: string, activityLink: string): Observable<boolean> {
    const url = this.endpoint + `/HasUserActivityBeenAdded/`;
    return this.http.get<boolean>(url, { params: { activityTitle, activityLink } })
  }

  public archiveUserActivitiesAssociatedWithGoal(goalId: number): Observable<IUserActivity[]> {
    if (!goalId) {
      throw new Error('Goal ID cannot be undefined at this point.');
    }

    const url = this.endpoint + `/ArchiveUserActivitiesAssociatedWithGoal`;

    return this.http.post<IUserActivity[]>(url, { options: { observe: 'response', responseType: 'json' } }, { params: { goalId: goalId.toString() } });
  }

  private initialiseActivitiesFromServer() {
    if (!this.isUserActivitiesDataInitialised()) {
      if (this.initialising) {
        return;
      }
      this.initialising = true;
      this.http.get<IUserActivity[]>(this.endpoint, httpOptions).subscribe({
        next: (activities) => {
          this.userActivitiesServiceData.initialiseActivities(activities);
          this.initialising = false;
        },
        error: (error) => {
          console.error(`*** UserActivitiesService: initialiseActivitiesFromServer, error when getting the activities. Error: ${error}`);
          this.initialising = false;
        },
      });
    }
  }

  private reInitialiseFromScratch() {
    this.userActivitiesServiceData.resetActivities();
    this.initialiseActivitiesFromServer();
  }

  private isUserActivitiesDataInitialised(): boolean {
    return this.userActivitiesServiceData.isInitialised();
  }
}
