import { Component, OnInit } from '@angular/core';
// services
import { UserPlaylistService } from 'src/app/services/user-playlist.service';
// models
import { NzMessageService } from 'ng-zorro-antd/message';
import { IUserPlaylist } from 'src/app/models/user-playlist.model';

@Component({
  selector: 'sl-display-user-playlists',
  templateUrl: './display-user-playlists.component.html',
  styleUrls: ['./display-user-playlists.component.less'],
})
export class DisplayUserPlaylistsComponent implements OnInit {
  public userPlaylists: IUserPlaylist[] = [];
  constructor(private userPlaylistService: UserPlaylistService, private message: NzMessageService) {}

  ngOnInit(): void {
    this.initUserPlaylists();
  }

  private initUserPlaylists(): void {
    this.userPlaylistService.getAdminUserPlaylists().subscribe((data: IUserPlaylist[]) => {
      const playlistWithActivites = data.filter((pl) => pl.userActivityIds.length > 0);
      if (playlistWithActivites.length !== data.length) {
        const emptyPlaylists = data.filter((pl) => pl.userActivityIds.length === 0);
        emptyPlaylists.forEach((pl) => {
          this.message.warning(`We received a user playlist without activities. UserPlaylistId: ${pl.id}`);
        });
        console.table(emptyPlaylists);
      }
      this.userPlaylists = playlistWithActivites;
    });
  }
}
