/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

// models
import { EditMode } from '../../types/edit-mode.type';

// services
import { NzMessageService } from 'ng-zorro-antd/message';
import { SignatureService } from 'src/app/services/signature.service';

@Component({
  selector: 'sl-industry-selector',
  templateUrl: './industry-selector.component.html',
  styleUrls: ['./industry-selector.component.less'],
})
export class IndustrySelectorComponent implements OnInit {
  @Output() selectIndustryEvent = new EventEmitter<string>();
  @Input() previouslySelectedIndustry!: string;
  @Input() currentMode!: EditMode;

  public industryOptions: string[] = [];
  public selectedIndustry!: string;

  constructor(private signatureService: SignatureService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.initIndustries();
  }

  public selectIndustry(industry: string) {
    if (this.selectedIndustry === industry) {
      this.selectedIndustry = '';
    } else {
      this.selectedIndustry = industry;
    }

    this.selectIndustryEvent.emit(this.selectedIndustry);
  }

  private initIndustries() {
    if (this.selectedIndustry === this.previouslySelectedIndustry) {
      this.selectedIndustry = '';
    }

    if (this.previouslySelectedIndustry && this.selectedIndustry && (this.selectedIndustry === this.previouslySelectedIndustry)) {
      return;
    }

    this.signatureService.getIndustries().subscribe({
      next: (ret) => {
        this.industryOptions = ret;

        if (this.currentMode === EditMode.edit) {
          if (!this.previouslySelectedIndustry) {
            throw new Error('This should set correctly when in edit mode');
          }
          this.selectIndustry(this.previouslySelectedIndustry);
        }
      },
      error: (err) => {
        console.error(err.message);
        this.message.error(`Oh dear, this is embarassing. We weren't able to get your previously selected industry. Check your network connection and try again in a few moments`, { nzDuration: 5000 });
      },
    });
  }
}
