<nz-table nzTemplateMode class="users-table">
  <thead>
    <tr>
      <th>User</th>
      <th>Email</th>
      <th>TopSkill 1</th>
      <th>TopSkill 2</th>
      <th>TopSkill 3</th>
      <th>TopSkill 4</th>
      <th>TopSkill 5</th>
      <th>TopSkill 6</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users">
      <td id="user-name-{{user.id}}">{{user.name}}</td>
      <td id="user-email-{{user.id}}">{{user.email}}</td>
      <td *ngFor="let topSkillIndex of maxTopSkills; let i = index">
        <div id="topskill-{{i}}-name-for-{{user.id}}">{{getTopSkillName(user.id, i)}} | {{getTopSkillRank(user.id, i)}}</div>
      </td>
    </tr>
  </tbody>
</nz-table>