import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

// model
import { IEmployment } from 'src/app/models/employment.model';
import { IGoal } from 'src/app/models/user-goal.model';
import { ITopSkill } from 'src/app/models/top-skill.model';

// service
import { PublicProfileService } from 'src/app/services/public-profile.service';
import { IUserProfilePublic } from 'src/app/models/user-profile.model';
import { GuildService } from 'src/app/services/guild.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'sl-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.less'],
})
export class PublicProfileComponent implements OnInit {
  userIdentifier: string | undefined | null;
  privateProfileStatus: boolean | undefined;
  userProfilePublic: IUserProfilePublic | undefined = undefined;
  userGoals: IGoal[] | undefined = undefined;
  userTopSkills: ITopSkill[] | undefined = undefined;
  userEmployment: IEmployment[] | undefined = undefined;

  //For guild invitations
  guildId!: number;
  hasPermission!: boolean;
  hasInvitedUserToGuild = false;

  constructor(private publicProfileService: PublicProfileService, private route: ActivatedRoute, private guildService: GuildService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userIdentifier = params.get('user');

      const guildIdParam = params.get('guildId');
      this.guildId = guildIdParam ? parseInt(guildIdParam, 10) : 0;

      const paramValue = params.get('permission');
      this.hasPermission = paramValue ? paramValue.toLowerCase() === 'true' : false;
    });
    this.getPrivateStatus();
    if (this.userIdentifier && this.guildId && this.hasPermission) {
      this.checkIfUserHasBeenInvitedToGuild();
    }
  }

  handleRequestToInviteUserToGuild(publicUrl: string) {
    this.guildService.inviteUserToGuild(this.guildId, publicUrl).subscribe({
      next: (ret) => {
        this.message.success(`Nice! You've successfully invited ${ret.invitedUserFirstName} ${ret.invitedUserSurname} to the guild.`)
        this.hasInvitedUserToGuild = true;
      },
      error: (error) => {
        this.message.error(`Error: Failed to invite this user to the guild for you. Unable to connect to SeaLadder HQ. Check network connection? ${error} `)
      }
    })
  }

  checkIfUserHasBeenInvitedToGuild() {
    this.guildService.hasUserBeenInvitedToGuild(this.guildId, this.userIdentifier!).subscribe({
      next: (ret) => {
        this.hasInvitedUserToGuild = ret
      },
      error: (error) => {
        this.message.error(`Error: Failed to check if this user has been invited to the guild. Unable to connect to SeaLadder HQ. Check network connection? ${error} `)
        this.hasInvitedUserToGuild = false;
      }
    })
  }

  getPrivateStatus(): void {
    if (!this.userIdentifier) {
      throw new Error('Cannot get the status if there is no userId');
    }

    this.publicProfileService.getPrivateStatus(this.userIdentifier).subscribe({
      next: (ret) => {
        this.privateProfileStatus = ret;
        if (!this.privateProfileStatus) {
          this.getUserIdentifier();
        }
      },
      error: (error) => console.error(`PublicProfileComponent: The url is hidden or does not exist. Error: ${error}`)
    });
  }

  getUserProfile(): void {
    if (!this.userIdentifier) {
      throw new Error('Cannot get the public user details if there is no userId');
    }

    this.publicProfileService.getPublicUserDetails(this.userIdentifier).subscribe({
      next: (ret) => {
        this.userProfilePublic = ret;
      },
      error: (err) => console.error(`*** PublicProfileComponent: Failed to get public user details. Error: ${err}`)
    });
  }

  getUserGoals(): void {
    if (!this.userIdentifier) {
      throw new Error('Cannot get the public goals if there is no userId');
    }

    this.publicProfileService.getPublicGoals(this.userIdentifier).subscribe(
      (ret) => {
        this.userGoals = ret;
      },
      (err) => console.error(`*** PublicProfileComponent: Failed to get user goals. Error: ${err}`)
    );
  }

  getUserTopSkills(): void {
    if (!this.userIdentifier) {
      throw new Error('Cannot get the putblic top skills if there is no userId');
    }

    this.publicProfileService.getPublicTopSkills(this.userIdentifier).subscribe(
      (ret) => {
        this.userTopSkills = ret;
      },
      (err) => console.error(`*** PublicProfileComponent: Failed to get user top skills. Error: ${err}`)
    );
  }

  getUserEmployment(): void {
    if (!this.userIdentifier) {
      throw new Error('Cannot get the public employment details if there is no userId');
    }

    this.publicProfileService.getPublicEmployment(this.userIdentifier).subscribe(
      (ret) => {
        this.userEmployment = ret;
      },
      (err) => console.error(`*** PublicProfileComponent: Failed to get user employments. Error: ${err}`)
    );
  }

  private getUserIdentifier() {
    this.getUserProfile();
    this.getUserGoals();
    this.getUserTopSkills();
    this.getUserEmployment();
  }
}
