<div *ngIf="playlist">
  <nz-card class="card-style" nzHoverable [nzCover]="coverTemplate">
    <div class="card-info-container">
      <h5 class="card-title" id="playlist-card-{{playlist.id}}" nz-typography nzEllipsis nzEllipsisRows="1" (click)="showDrawer(true)" nz-tooltip="{{playlist.name}}">
        {{playlist.name}}
      </h5>
      <div class="card-details" nz-typography> {{getPlayListDetails()}}</div>
      <nz-badge *ngIf="playlist.totalUsed > 0" [nzCount]="playlist.totalUsed" [nzStyle]="{ backgroundColor: '#52c41a' }" nz-tooltip nzTooltipTitle="{{playlist.totalUsed | SingularPlural : 'other has': 'others have'}} added this playlist"></nz-badge>
      <i class="fas fa-list fa-2x card-learning-style" nz-tooltip nzTooltipTitle="Playlist" (click)="showDrawer(true)"></i>
      <div class="card-activity-count" nz-typography nzEllipsis nzEllipsisRows="1">
        <span>
          {{getActivityCount()}} Activities
        </span>
      </div>
    </div>
  </nz-card>
  <ng-template #coverTemplate>
    <div class="playlist-image" (mouseleave)="imageLeave()" (mouseenter)="imageEnter()">
      <div class="picture-area" (click)="showDrawer(true)">
        <img class="imgContainer" [src]="getCardImage()" [ngClass]="{'img-added-backlog': addedToBacklog}" alt="Image of the playlist" />
        <div class="playlist-image-overlay">
          <div class="playlist-image-overlay-text">
            <div>
              {{getActivityCount()}}
            </div>
            <i class="fas fa-list fa-lg"></i>
          </div>
        </div>
      </div>
      <div *ngIf="!addedToBacklog">
        <button class="add-backlog-button" [hidden]="!isHovered" nz-button nzType="primary" [nzLoading]="isAdding" (click)="addToBacklog()">
          <i class="fas fa-plus"></i><span class="add-button-text"> Add Playlist</span>
        </button>
      </div>
      <div *ngIf="addedToBacklog">
        <i class="add-backlog-button checkIcon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#52c41a'"></i>
      </div>
    </div>
  </ng-template>
  <nz-drawer class="playlist-drawer" [nzClosable]="true" [nzWidth]="650" [nzOffsetX]="childrenVisible ? 500 : 0" [nzOffsetX]="childrenVisible ? 500 : 0" [nzVisible]="isDrawerVisible" nzTitle="{{playlist.name}}" (nzOnClose)="showDrawer(false)">
    <ng-container *nzDrawerContent>
      <sl-playlist-viewer [playlist]="playlist" [goalSkills]="goalSkills" [goalId]="goalId"></sl-playlist-viewer>
    </ng-container>
  </nz-drawer>
</div>