import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

// models
import { INotification } from 'src/app/models/notification.model';

// services
import { NotificationsService } from 'src/app/services/notifications.service';

const storageNotifications = 'notifications';
const storageHideAccepted = 'notifications-hide-accepted';
@Component({
  selector: 'sl-notification-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.less'],
})
export class NotificationBadgeComponent implements OnInit {
  notifications!: INotification[];
  notificationsHideAccepted!: INotification[];
  notificationCount = 0;
  notificationModalVisible = false;
  allChecked = true;
  indeterminate = false;
  hideAccepted = true;

  constructor(private notificationService: NotificationsService, private localStorageService: LocalStorageService) {}

  ngOnInit() {
    this.hideAccepted = this.localStorageService.get<boolean>(storageHideAccepted)?? true;
    this.getUserNotifications();
  }

  getUserNotifications() {
    this.notificationService.getUserNotifications().subscribe(
      (res) => {
        this.notifications = res;
        this.notifications.forEach((result) => (result.checked = false));
        this.notificationsHideAccepted = this.hideAccepted ? this.notifications.filter((n) => n.checked === false) : this.notifications;

        const initialNotifications = this.localStorageService.get<INotification[]>(storageNotifications);

        if (initialNotifications) {
          for (const notification of initialNotifications) {
            this.notifications = this.notifications.map((item) => (item.id === notification.id ? Object.assign({}, item, { checked: notification.checked }) : item));
          }
        }

        this.refreshNotificationCountStatus();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  updateSingleChecked(id: number, isChecked: boolean) {
    this.notifications = this.notifications.map((item) => (item.id === id ? Object.assign({}, item, { checked: isChecked }) : item));
    this.refreshNotificationCountStatus();
  }

  allCheckedClicked() {
    this.notifications.forEach((item) => (item.checked = this.allChecked));
    this.refreshNotificationCountStatus();
  }

  refreshNotificationCountStatus() {
    const filteredNotifications = this.notifications.filter((item) => item.checked === false);
    this.notificationsHideAccepted = this.hideAccepted ? filteredNotifications : this.notifications;
    this.indeterminate = !(this.notifications.every((item) => item.checked) || this.notifications.every((item) => !item.checked));
    this.localStorageService.set(storageNotifications, this.notifications);
    this.notificationCount = filteredNotifications.length;
    this.refreshAllChecked();
  }

  hideAcceptedClicked() {
    console.log(`*** setting hideAccepted to ${this.hideAccepted}`);
    const filteredNotifications = this.notifications.filter((item) => item.checked === false);
    this.notificationsHideAccepted = this.hideAccepted ? filteredNotifications : this.notifications;
    this.localStorageService.set(storageHideAccepted, this.hideAccepted);
  }

  private refreshAllChecked() {
    if (!this.indeterminate) {
      this.allChecked = this.notifications.every((item) => item.checked);
    }
  }
}
