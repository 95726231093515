<div class="top-skills">
  <div>
    <div class="component-title">
      <div class="title-logo">
        <i class="fa fa-star"></i>
      </div>
      <div class="title-text">TOP SKILLS</div>
    </div>
  </div>
  <div *ngIf="!loading && topSkills">
    <ul class="skills" [ngClass]="{'skills-greater-than': topSkills.length>3}">
      <li *ngFor="let skill of topSkills">
        <sl-skill-card2 [topSkill]="skill" [category]="skill.category" [userProfilePublic]="userProfilePublic"></sl-skill-card2>
      </li>
    </ul>
  </div>
  <nz-alert *ngIf="!loading && !hasError && !topSkills" id="alert-no-skills" nzType="info" nzShowIcon nzMessage="No Top Skills (yet)" [nzDescription]="descriptionTpl">
    <ng-template #descriptionTpl>
      <div><br>You have to first complete activities in order to get recognition for skills.<br>Time to add some<a nzType="link" [routerLink]="['/profile/activities']"> Activities </a>for your goals!<br></div>
    </ng-template>
  </nz-alert>
  <nz-alert *ngIf="loading" id="alert-no-skills" nzType="warning" nzShowIcon nzMessage="Calculating Achievements..." [nzDescription]="descriptionTpl">
    <ng-template #descriptionTpl><span innerHTML={{templateLoading}}></span></ng-template>
  </nz-alert>
  <nz-alert *ngIf="hasError" id="alert-no-skills" nzType="error" nzShowIcon nzMessage="Failed to Load Your Skills" [nzDescription]="descriptionTpl">
    <ng-template #descriptionTpl><span innerHTML={{templateError}}></span></ng-template>
  </nz-alert>
</div>