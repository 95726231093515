import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AccountDetailsService } from '../../services/account-details.service';

@Injectable()
export class AdminGuardServiceGuard  {

    constructor(private accountDetailsService: AccountDetailsService) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.accountDetailsService.getIsAdmin();
    }
}
