<nz-card nzTitle="Billing Details">
  <div *ngIf="isLoading">
    <nz-skeleton nzSimple [nzSize]="'large'"></nz-skeleton>
  </div>
  <br />
  <div *ngIf="!isLoading">
    <div id="testing-subscription-cards">
      <div class="subscription-cards" *ngIf="!isSubscribed">
        <div id="monthly-subscription-card">
          <a href="{{ linkToStripeMonthly }}">
            <nz-card nzTitle="Monthly Subscription" [nzHoverable]="true" [nzCover]="coverTemplateMonthly">
              <p>$9.99 per month</p>
              <p>Billed monthly</p>
              <p>Access to all of SeaLadder</p>
            </nz-card>
            <ng-template #coverTemplateMonthly>
              <img class="subscription-img" alt="Monthly" src="assets/img/billing/silver.png" />
            </ng-template>
          </a>
        </div>
        <div id="yearly-subscription-card">
          <a href="{{ linkToStripeYearly }}">
            <nz-card nzTitle="Yearly Subscription" [nzHoverable]="true" [nzCover]="coverTemplateYearly">
              <p>$99.99 per year</p>
              <p>Save over 15%</p>
              <p>Access to all of SeaLadder</p>
            </nz-card>
            <ng-template #coverTemplateYearly>
              <img class="subscription-img" alt="Yearly" src="assets/img/billing/gold.png" />
            </ng-template>
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="isSubscribed">
      <br />
      <nz-card nzTitle="Welcome to SeaLadder. We hope you'll love using SeaLadder!" [nzHoverable]="false">
        <div *ngIf="userSubscription" style="text-transform: capitalize">
          <p *ngIf="userSubscription.initialStartDate">Subscribed since: {{ userSubscription.initialStartDate | date : 'longDate' }}</p>
          <p>Subscription type: {{ userSubscription.subscriptionType }}</p>
          <p>Status: {{ userSubscription.subscriptionStatus }}</p>
          <p *ngIf="userSubscription.subscriptionCycleEnd">Subscribed Until : {{ userSubscription.subscriptionCycleEnd | date : 'longDate' }}</p>
        </div>
        <div *ngIf="!userSubscription">
          <p>Subscription details unavailable... still processing.</p>
        </div>
      </nz-card>
      <br />
      <div class="button-container">
        <sl-invoices></sl-invoices>
      </div>
    </div>
  </div>
</nz-card>