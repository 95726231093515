import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserActivity } from '../models/user-activity.model';
import { IUserPlaylist } from '../models/user-playlist.model';
import { ISharedPlaylist } from '../models/shared-playlist.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UserPlaylistService {
  private endpoint = '/api/UserPlaylist';

  constructor(private http: HttpClient) { }

  getAdminUserPlaylists(): Observable<IUserPlaylist[]> {
    const requestPermission = true;
    const url = this.endpoint + `/GetUserPlaylists/`;
    return this.http.get<IUserPlaylist[]>(url, { params: { requestAll: requestPermission.toString() } });
  }

  getUserPlaylist(userPlaylistId: number): Observable<IUserPlaylist> {
    const url = this.endpoint + '/GetUserPlaylist/';
    return this.http.get<IUserPlaylist>(url, { params: { userPlaylistId: userPlaylistId.toString() } });
  }

  hasUserCreatedPlaylist(playlistId: number): Observable<boolean> {
    const url = this.endpoint + '/HasUserCreatedPlaylist/';
    return this.http.get<boolean>(url, { params: { playlistId: playlistId.toString() } });
  }


  hasUserAddedPlaylistVersion(playlistId: number): Observable<boolean> {
    const url = this.endpoint + '/HasUserAddedPlaylistVersion/';
    return this.http.get<boolean>(url, { params: { playlistId: playlistId.toString() } });
  }

  getUserPlaylists(): Observable<IUserPlaylist[]> {
    const url = this.endpoint + '/GetUserPlaylists/';
    return this.http.get<IUserPlaylist[]>(url, httpOptions);
  }

  getUserPlaylistActivities(userPlaylistId: number): Observable<IUserActivity[]> {
    const url = this.endpoint + `/GetUserPlaylistActivities/`;
    return this.http.get<IUserActivity[]>(url, { params: { userPlaylistId: userPlaylistId.toString() } });
  }

  updatePlaylist(userPlaylistId: number, newVersion: number): Observable<IUserPlaylist> {
    const url = this.endpoint + `/UpdateUserPlaylistVersion/`;
    const params = new HttpParams().set('userPlaylistId', String(userPlaylistId)).set('version', String(newVersion));
    const options = { headers: httpOptions.headers, params };

    return this.http.patch<IUserPlaylist>(url, {}, options);
  }

  addUserPlaylistToGoal(playlistId: number, goalId: number): Observable<IUserPlaylist> {
    if (!playlistId) {
      throw new Error('Playlist ID cannot be undefined at this point.');
    }

    if (!goalId) {
      throw new Error('User Goal ID cannot be undefined at this point.');
    }

    const body = { goalId: goalId.toString(), playlistId: playlistId.toString() };
    const url = this.endpoint + `/AddUserPlaylist/`;
    return this.http.post<IUserPlaylist>(url, body, httpOptions);
  }

  addUserPlaylistFromExternalPlaylistUrl(goalId: number | undefined, playlistUrl: string | undefined): Observable<IUserPlaylist> {
    if (!goalId) {
      throw new Error('Goal ID cannot be undefined at this point.');
    }

    if (!playlistUrl) {
      throw new Error('Playlist URL cannot be undefuned at this point.');
    }

    const body = { playlistUrl, goalId: goalId.toString() };
    const url = this.endpoint + `/AddUserPlaylistFromYoutubeUrl/`;
    return this.http.post<IUserPlaylist>(url, body, httpOptions);
  }

  addUserPlaylistFromSharedPlaylist(sharedPlaylistId: number, playlistId: number, guildId: number, goalId: number): Observable<IUserPlaylist> {
    console.log('Service Call');
    if (!sharedPlaylistId) {
      throw new Error('SharedPlaylist ID cannot be undefined at this point.');
    }

    if (!playlistId) {
      throw new Error('Playlist ID cannot be undefined at this point.');
    }

    if (!guildId) {
      throw new Error('Guild ID cannot be undefined at this point.');
    }

    if (!goalId) {
      throw new Error('Goal ID cannot be undefined at this point.');
    }

    const body = { sharedPlaylistId: sharedPlaylistId.toString(), playlistId: playlistId.toString(), guildId: guildId.toString(), goalId: goalId.toString() };
    const url = this.endpoint + `/AddUserPlaylistFromSharedPlaylist/`;
    return this.http.post<IUserPlaylist>(url, body, httpOptions);
  }

  getSharedPlaylistsAddedByGuildUsers(guildId: number, guildMemberIds: string[]): Observable<ISharedPlaylist[]> {
    const endpoint = this.endpoint + `/GetSharedPlaylistsAddedByGuildUsers`;
    return this.http.get<ISharedPlaylist[]>(endpoint, { params: { guildId: guildId.toString(), guildMemberIds } });
  }

  deleteUserPlaylist(userPlaylistId: number): Observable<number[]> {
    if (!userPlaylistId) {
      throw new Error('Playlist ID cannot be undefined at this point.');
    }

    const params = new HttpParams().set('userPlaylistId', String(userPlaylistId));
    const options = { headers: httpOptions.headers, params };
    const url = this.endpoint + `/DeleteUserPlaylist/`;

    return this.http.delete<number[]>(url, options);
  }
}
