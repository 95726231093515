import { Component, Input, OnInit } from '@angular/core';
import { TopSkillService } from '../../../services/top-skill.service';
import { ITopSkill } from '../../../models/top-skill.model';
import { IUserProfilePublic } from 'src/app/models/user-profile.model';
import { GoalService } from 'src/app/services/goal.service';
import { Subscription } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'sl-top-skills',
  templateUrl: './top-skills.component.html',
  styleUrls: ['./top-skills.component.less'],
})
export class TopSkillsComponent implements OnInit {
  @Input() topSkillsPublic?: ITopSkill[];
  @Input() userProfilePublic?: IUserProfilePublic;
  private onChangeGoal!: Subscription;
  topSkills: ITopSkill[] | null = null;
  loading = false;
  hasError = false;

  templateLoading = `<br />
  Our minions are tallying the numbers on how much you've completed.<br />
  Hold tight while we calculate awesomeness.`;

  templateError = `<br />
  Well, this is embarrassing... We ran into a problem while trying to calculate how awesome you are. <br />
  Try again a little later perhaps.`;

  constructor(private topSkillService: TopSkillService, private goalService: GoalService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.loading = true;
    this.hasError = false;
    if (this.topSkillsPublic !== undefined) {
      this.topSkills = this.topSkillsPublic.length ? this.topSkillsPublic : null;
      this.loading = false;
    } else {
      this.updateTopskills();
    }

    // this.changeTopSkillsInit();
  }

  changeTopSkillsInit() {
    // console.log('*** Start loading goals');
    this.onChangeGoal = this.goalService.getGoalsUpdatedEvent().subscribe(
      () => {
        this.updateTopskills();
      },
      (error) => {
        this.message.error(`Error loading activities goals. Error Message: ${error.message}`);
      },
      () => {
        this.loading = false;
      }
    );
  }

  updateTopskills(): void {
    // this.loading = true;
    this.topSkillService.getTopSkills().subscribe({
      next: (skills) => {
        this.topSkills = skills.length ? skills : null;
        this.loading = false;
      },
      error: () => {
        this.topSkills = null;
        this.loading = false;
        this.hasError = true;
      }
    });
  }
}
