/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class SignatureService {
  endpoint = '/api/Signatures/';
  isLoggedIn = false;
  initialising = false;

  constructor(private http: HttpClient) {}

  public getIndustries(): Observable<string[]> {
    const url = this.endpoint + 'GetIndustries';
    return this.http.get<string[]>(url, httpOptions);
  }

  public getProfessions(industry: string): Observable<string[]> {
    const url = this.endpoint + `GetProfessions/${industry}`;
    return this.http.get<string[]>(url, httpOptions);
  }

  public getSpecialisations(industry: string, profession: string): Observable<string[]> {
    const url = this.endpoint + `GetSpecialisations/${industry}/${profession}`;
    return this.http.get<string[]>(url, httpOptions);
  }
}
