import { Injectable } from '@angular/core'

export interface TrackSkillDescription {
  skillName: string,
  category: string,
  trackId?: number,
  trackName: string,
  trackSkillDescription: string
}

export interface TrackSkillCategoryDescription {
  category: string,
  trackId?: number,
  trackName: string,
  trackSkillCategoryDescription: string
}

@Injectable({
  providedIn: 'root'
})

export class TrackServiceData {
  private trackSkillDescriptionsById = new Map();
  private trackSkillCategoryDescriptionsById = new Map();
  private trackSkillDescriptionsByName = new Map();
  private trackSkillCategoryDescriptionsByName = new Map();

  constructor() { }

  public initialiseTrackSkillDescriptionsById(trackId: number, skillName: string, category: string, description: string) {
    if (!trackId) {
      throw new Error('initialiseTrackSkillDescriptionsById trackId cannot be undefined');
    }

    const cacheKey = `${trackId}-${skillName}`;

    const trackDescriptionObject = {
      skillName: skillName,
      category: category,
      trackId: trackId,
      trackSkillDescription: description
    } as TrackSkillDescription;

    if (description) {
      this.trackSkillDescriptionsById.set(cacheKey, trackDescriptionObject);
    }
  }

  public initialiseTrackSkillDescriptionsByName(trackName: string, skillName: string, category: string, description: string) {
    if (!trackName) {
      throw new Error('initialiseTrackSkillDescriptionsByName trackName cannot be undefined')
    }

    const cacheKey = `${trackName}-${skillName}`;

    const trackDescriptionObject = {
      skillName: skillName,
      category: category,
      trackName: trackName,
      trackSkillDescription: description
    } as TrackSkillDescription;

    if (description) {
      this.trackSkillDescriptionsByName.set(cacheKey, trackDescriptionObject);
    }
  }

  public initialiseTrackSkillCategoryDescriptionsById(trackId: number, category: string, description: string) {
    if (!trackId) {
      throw new Error('initialiseTrackSkillCatgeoryDescriptionsById trackId cannot be undefined');
    }

    const cacheKey = `${trackId}-${category}`;

    const trackCategoryDescriptionObject = {
      category: category,
      trackId: trackId,
      trackSkillCategoryDescription: description
    } as TrackSkillCategoryDescription;

    if (description) {
      this.trackSkillCategoryDescriptionsById.set(cacheKey, trackCategoryDescriptionObject);
    }
  }

  public initialiseTrackSkillCategoryDescriptionsByName(trackName: string, category: string, description: string) {
    if (!trackName) {
      throw new Error('initialiseTrackSkillCatgeoryDescriptionsByName trackName cannot be undefined');
    }

    const cacheKey = `${trackName}-${category}`;

    const trackCategoryDescriptionObject = {
      category: category,
      trackName: trackName,
      trackSkillCategoryDescription: description
    } as TrackSkillCategoryDescription;

    if (description) {
      this.trackSkillCategoryDescriptionsByName.set(cacheKey, trackCategoryDescriptionObject);
    }
  }

  public getTrackSkillDescriptionById(trackId: number, skillName: string) {
    if (!trackId) {
      throw new Error('initialiseTrackSkillDescriptionsById trackId cannot be undefined');
    }

    const cacheKey = `${trackId}-${skillName}`

    return this.trackSkillDescriptionsById.get(cacheKey);
  }

  public getTrackSkillDescriptionByName(trackName: string, skillName: string) {
    if (!trackName) {
      throw new Error('initialiseTrackSkillDescriptionsByName trackName cannot be undefined');
    }

    const cacheKey = `${trackName}-${skillName}`

    return this.trackSkillDescriptionsByName.get(cacheKey);
  }

  public getTrackSkillCategoryDescriptionById(trackId: number, category: string) {
    if (!trackId) {
      throw new Error('initialiseTrackSkillCategoryDescriptionsById trackId cannot be undefined');
    }

    const cacheKey = `${trackId}-${category}`;

    return this.trackSkillCategoryDescriptionsById.get(cacheKey);
  }

  public getTrackSkillCategoryDescriptionByName(trackName: string, category: string) {
    if (!trackName) {
      throw new Error('initialiseTrackSkillCategoryDescriptionsByName trackName cannot be undefined');
    }

    const cacheKey = `${trackName}-${category}`;

    return this.trackSkillCategoryDescriptionsByName.get(cacheKey);
  }
}
