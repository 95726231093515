<nz-spin [nzSpinning]="isLoading">
  <nz-card class="provider-card" nzHoverable [nzCover]="coverTemplate">
    <nz-card-meta nzTitle="{{provider.name}}"></nz-card-meta>
    <div *ngIf="!provider.connected">
      <div *ngIf="provider.type==='Public'">
          <button class="card-button-no-badge" [ngClass]="'connect--' + provider.name" nz-button name="connect" nzType="primary" [nzSize]="'large'" value="{{provider.name}}" title="Connect to {{provider.name}}" (click)="selectProvider(provider.name)">Connect</button>
      </div>
      <div *ngIf="provider.type==='OAuth2'">
          <button class="card-button-no-badge" [ngClass]="'connect--' + provider.name" nz-button name="connect" nzType="primary" type="submit" [nzSize]="'large'" name="provider" value="{{provider.name}}" title="Login to {{provider.name}}" [nzLoading]="isLoading"
            (click)="connectToOAuthProvider(provider.name)">Login</button>
      </div>
      <div *ngIf="provider.type==='Verifier'">
          <button class="card-button-no-badge" [ngClass]="'connect--' + provider.name" nz-button name="Verify Certificate" nzType="primary" type="submit" [nzSize]="'large'" name="provider" value="{{provider.name}}" title="Login to {{provider.name}}" [nzLoading]="isLoading"
            (click)="selectVerifier(provider.name)">Verify</button>
      </div>
    </div>
    <div *ngIf="provider.connected">
      <div *ngIf="provider.type !== 'Verifier'; else verifyButton" title="Last Refreshed: {{provider.lastRefreshed | date}}">
        <nz-badge  class="card-button-badge" nzOverflowCount="999" [nzStyle]="{ backgroundColor: '#52c41a' }" [nzCount]="providerActivitiyCount">
          <button class="card-button" nz-button [nzGhost]="true" disabled [nzSize]="'large'" type="button">Connected</button>
        </nz-badge>
      </div>
      <ng-template #verifyButton>
          <button class="card-button-no-badge" nz-button [nzGhost]="true" disabled [nzSize]="'large'" type="button" (click)="selectProvider(provider.name)">Add another Certificate</button>
      </ng-template>
    </div>
  </nz-card>
</nz-spin>
<ng-template #coverTemplate>
  <ng-container *ngIf="provider.name">
    <div *ngIf="provider.connected">
      <i nz-icon class="fa fa-user refresh-btn" [ngClass]="'refresh-btn--' + provider.name" (click)="refreshProvider(provider.name, provider.username)" nz-icon [nzType]="'sync'" [nzSpin]="isProviderRefreshing()" nz-tooltip nzTooltipTitle="Refresh {{provider.name}}">
      </i>
      <i class="fa fa-user user-icon" nz-tooltip nzTooltipTitle="Connected to {{provider.username}}"></i>
    </div>
    <div *ngIf="!provider.connected">
      <i id="show-help-button" (click)="showHelpModal()" class="fas fa-info-circle float-element-right help-icon"></i>
      <!-- Two tone approach <i id="show-help-button" (click)="showHelpModal()" class="help-icon" nz-icon nzType="info-circle" nzTheme="twotone"></i> -->
    </div>
    <div class="provider-logo" *ngIf="validField(provider.logo)">
      <img src="{{provider.logo}}">
    </div>
    <div class="provider-icon" *ngIf="validField(provider.icon) && !validField(provider.logo)">
      <i class='provider-field {{provider.icon}}'></i>
    </div>
  </ng-container>
</ng-template>

<nz-modal [nzVisible]="isVisible" [nzContent]="content" [nzFooter]="null" (nzOnCancel)="clearSelectedProvider()">
  <ng-template #content>
    <form nz-form method="post" class="form" [nzLayout]="'vertical'">
      <div>
        <div *ngIf='selectedProviderImageType === "ICON"'>
          <i class='provider-field {{selectedProviderImageValue}}'></i>
        </div>
        <div *ngIf='selectedProviderImageType === "LOGO"'>
          <img src='{{selectedProviderImageValue}}' class='provider-image'>
        </div>
        <h4>{{selectedProviderName}}</h4>
      </div>
      <!-- TODO: Ther is a neater way of doing this described here: http://restlet.com/company/blog/2016/02/11/implementing-angular2-forms-beyond-basics-part-1/ -->
      <nz-form-item>
        <nz-form-label *ngIf="provider.type !=='Verifier'; else verifierLabel" [nzSpan]="14" nzFor="username">Username</nz-form-label>
        <div>
          <ng-template #verifierLabel>
            <nz-form-label [nzSpan]="14" nzFor="username">Certificate ID</nz-form-label>
            <i id="show-help-button-internal" (click)="showHelpModal()" class="fas fa-info-circle float-element-right help-icon-internal"></i>
          </ng-template>
        </div>
      </nz-form-item>
      <nz-form-item [ngClass]="(connectionError) ?
                  'form-group has-error has-feedback' :
                  (touched) ? 'form-group has-success has-feedback' : 'form-group has-warning has-feedback'">
        <nz-form-control [nzSpan]="14">
          <input *ngIf="provider.type !=='Verifier'; else verifierField" id="username-input" nz-input #userelement [(ngModel)]="username" class="form-control form-control-success" type="text" name="username" placeholder="Username" (ngModelChange)="providerNameChange()">
          <ng-template #verifierField>
            <input id="certificate-input" nz-input #userelement [(ngModel)]="certificateId" class="form-control form-control-success" type="text" name="certificate" placeholder="Certificate ID">
          </ng-template>
          <span [ngClass]="(connectionError) ? 
                  'glyphicon glyphicon-warning-sign form-control-feedback' : 
                  (touched) ? 'glyphicon glyphicon-ok form-control-feedback' : ''"></span>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="14" nzFor="connectionError" *ngIf='connectionError' class="alert alert-danger">
          Error while trying to connect</nz-form-label>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="14" nzFor="providerSaving" *ngIf='providerSaving' class="alert alert-info">Trying
          to connect...</nz-form-label>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzSpan]="14">
          <div *ngIf='!providerSaving' class="form-group">
            <button *ngIf="provider.type !=='Verifier'; else verifierButton" nz-button nzType="primary" nzBlock (click)="connectProvider(selectedProviderName)" type="submit" [disabled]="username.length === 0">Sign In</button>
            <ng-template #verifierButton>
              <button nz-button nzType="primary" nzBlock (click)="connectVerifier(selectedProviderName)" type="submit" [disabled]="certificateId.length === 0">Verify</button>
            </ng-template>
          </div>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>
</nz-modal>

<nz-modal [nzVisible]="helpModalVisible" [nzTitle]="helpTitle" [nzContent]="helpContent" [nzFooter]="null" (nzOnCancel)="closeHelpModal()">
  <ng-template #helpTitle>
    <div>Connecting to a {{ provider.name }} provider</div>
  </ng-template>
  <ng-template #helpContent>
    <p [innerHTML]="getHelpMessage()"></p>
    <img class="help-modal-image center" [src]="getHelpImagePath(provider.name)" />
  </ng-template>
</nz-modal>