<nz-card nzTitle="Metrics">
  <div id="metric-tab">
    <div id="graphs">
      <nz-card nzTitle="Subscribed vs Unsubscribed" id="line-chart-subscriptions">
        <ngx-charts-line-chart [view]="view" [scheme]="colourScheme" [results]="newSubscriptionsAndUnsubscriptionsMetric" [legend]="showLegend" legendPosition="below" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis" [yAxis]="showYAxis"
          xAxisLabel="Date" yAxisLabel="Count">
        </ngx-charts-line-chart>
      </nz-card>
      <nz-card nzTitle="Active vs Inactive Users" id="pie-chart-active-users">
        <ngx-charts-pie-chart [view]="view" [scheme]="colourScheme" [results]="activeUserMetric" [gradient]="gradient" [legend]="showLegend" legendPosition="below" labels="true" doughnut="true">
        </ngx-charts-pie-chart>
      </nz-card>
      <nz-card nzTitle="Types of Users" id="pie-chart-user-types">
        <ngx-charts-pie-chart [view]="view" [scheme]="colourScheme" [results]="userTypesRatioMetric" [activeEntries]="userTypesRatioMetric" [gradient]="gradient" [legend]="showLegend" legendPosition="below" labels="true" doughnut="true">
        </ngx-charts-pie-chart>
      </nz-card>
      <nz-card nzTitle="User Levels" id="bar-chart-goal-level">
        <ngx-charts-bar-vertical [view]="view" [scheme]="colourScheme" [results]="usersGoalLevelMetric" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="false" showXAxisLabel="false" [showYAxisLabel]="showYAxisLabel" yAxisLabel="Count">
        </ngx-charts-bar-vertical>
      </nz-card>
      <nz-card nzTitle="User Specialisations" id="treemap-specialisation">
        <ngx-charts-tree-map [view]="view" [scheme]="colourScheme" [results]="userSpecialisationMetric" [gradient]="gradient" [animations]="animations">
        </ngx-charts-tree-map>
      </nz-card>
      <nz-card nzTitle="Active users per month" id="line-chart-active-users">
        <div class="zero-padding">
          <ngx-charts-line-chart [scheme]="colourSchemeReduced" [results]="activeUserPerMonthMetric" [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis" [yAxis]="showYAxis" xAxisLabel="Date" yAxisLabel="Count">
          </ngx-charts-line-chart>
        </div>
      </nz-card>
      <nz-card nzTitle="Completed Activities" id="line-chart-activities">
        <div class="zero-padding">
          <ngx-charts-line-chart [scheme]="colourSchemeReduced" [results]="completedActivitiesMetric" [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis" [yAxis]="showYAxis" xAxisLabel="Date" yAxisLabel="Count">
          </ngx-charts-line-chart>
        </div>
      </nz-card>
      <nz-card nzTitle="Completed Goals" id="line-chart-goals">
        <div>
          <ngx-charts-line-chart [scheme]="colourSchemeReduced" [results]="completedGoalsMetric" [legend]="false" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis" [yAxis]="showYAxis" xAxisLabel="Date" yAxisLabel="Count">
          </ngx-charts-line-chart>
        </div>
      </nz-card>
    </div>
  </div>
</nz-card>