/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

//Libraries
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
// models

// services
import { GuildService } from 'src/app/services/guild.service';

//Models
import { IGuild } from '../../models/guild.model';
import { ELinkType } from '../../types/guild-link-type.type';
import { EditMode } from 'src/app/generic-components/types/edit-mode.type';

export interface linkProperties {
  type: ELinkType;
  thumbnail: string;
  link: string;
}

@Component({
  selector: 'sl-create-guild',
  templateUrl: './create-guild.component.html',
  styleUrls: ['./create-guild.component.less'],
})
export class CreateGuildComponent implements OnInit {
  @Output() newlyCreatedGuild = new EventEmitter<IGuild>();
  @Output() cancelledGuildCreation = new EventEmitter<boolean>();
  public mode!: EditMode;
  public modeType = EditMode; // hack to get around the limitations of JavaScript when it comes to enums; https://marco.dev/enums-angular

  constructor(private guildService: GuildService, private message: NzMessageService, private modal: NzModalService) {}

  ngOnInit(): void {
    this.mode = EditMode.new;
  }

  checkGuildCreationStatus(status: boolean) {
    if (!status) {
      this.cancelledGuildCreation.emit(false);
    }
  }

  public createNewGuild(guild: IGuild): void {
    this.guildService.addGuild(guild).subscribe({
      next: (res: IGuild) => {
        this.newlyCreatedGuild.emit(res);
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 409) {
          const guildResult = err.error as IGuild;
          this.modal.error({
            nzTitle: `Duplicate Guild Detected`,
            nzContent: `Looks like you have already created a Guild called <strong>'${guildResult.name}'</strong> before.  You can choose to update the previous guild with this one but keep in mind, you will lose the current values.<br/><br/>
             Update guild with values from the server?`,
            nzOkText: 'Update guild',
            nzCancelText: 'Keep Existing Values',
            nzOkDanger: true,
            nzOnCancel: () => console.log('Cancelling updating the form'),
            nzOnOk: () => {
              this.editExistingGuild(guild.name);
            },
          });
          console.warn(err.message);
        } else {
          this.message.error(`Error, your guild could not be created`, { nzDuration: 5000 });
          console.error(`Could not create guild ${err.message}`);
        }
      },
    });
  }

  private editExistingGuild(guildName: string): void {
    this.guildService.getGuildByName(guildName).subscribe({
      next: (g) => {
        this.newlyCreatedGuild.next(g);
        this.mode = EditMode.edit;
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 409) {
          const guilds = error.error as IGuild[];
          console.warn(`Guild with name '${guildName}' exists ${guilds.length} times in the.}`);
          this.message.warning(`There are ${guilds.length} guilds with the name '${guildName}'. This isn't currently supported.`);
        } else {
          console.warn(`Failed to load existing guild. Error: ${error.message}`);
        }
      },
    });
  }
}
