<div class="step">
  <nz-space *ngIf="isLoading" id="loading" nzSize="middle">
    <nz-skeleton-element *nzSpaceItem nzType="button" [nzActive]="true" [nzSize]="large" [nzShape]="circle"></nz-skeleton-element>
    <nz-skeleton-element *nzSpaceItem nzType="button" [nzActive]="true" [nzSize]="large" [nzShape]="circle"></nz-skeleton-element>
    <nz-skeleton-element *nzSpaceItem nzType="button" [nzActive]="true" [nzSize]="large" [nzShape]="circle"></nz-skeleton-element>
  </nz-space>
  <div class="track-selector-container" *ngFor="let track of trackOptions" (click)="selectTrack(track)">
    <button *ngIf="!isLoading" class="track-selector-button" nz-button nzType="primary" [nzSize]="'default'" [ngClass]="selectedTrackName === track ? 'active' : 'none'">{{ track }}</button>
  </div>
</div>
