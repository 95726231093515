<div class="tracks-history">
  <h2 class="header">Track History</h2>
  <sl-goal-signature-selector (selectedTrackChanged)="setSelectedTrack($event)"></sl-goal-signature-selector>
  <div>
    <div *ngIf="selectedTrack" class="wrapper-summary">
      <div id="track-summary-total"><strong>Total:</strong> {{ getTotalHistory() }} tracks</div>
      <div id="track-summary-active"><strong>Active Skills:</strong> {{ getSkillsCountActive() }}</div>
      <div id="track-summary-min"><strong>Min Skills:</strong> {{ getSkillsCountMin() }}</div>

      <div id="track-summary-is-recent" *ngIf="!activeIsRecent()">Active <strong>NOT</strong> latest</div>
      <div id="track-summary-is-recent" *ngIf="activeIsRecent()">Active IS latest</div>
      <div id="track-summary-max"><strong>Max Skills:</strong> {{ getSkillsCountMax() }}</div>
      <div id="track-summary-is-max" *ngIf="!activeHasMax()">Active does NOT have max skills</div>
    </div>
    <nz-divider></nz-divider>
    <div class="tracks-timeline">
      <nz-timeline *ngFor="let track of tracks; index as t">
        <nz-timeline-item [nzDot]="dotTemplate">
          <div class="wrapper-history">
            <div class="track-element track-active">
              <label id="isActive">
                <nz-spin *ngIf="isLoadingActive" nzSimple [nzSize]="'small'"></nz-spin>
                <input id="active-track-status" *ngIf="!isLoadingActive" type="checkbox" [disabled]="isActive(track)" [checked]="isActive(track)" (change)="toggleActive(track)" /> Active
              </label>
            </div>
            <div class="track-element track-date track-timeline-dates"><strong>Date:</strong> {{ track.dateCreated | date: 'yyyy-MM-dd h:mm a' }}</div>
            <div class="track-element track-count"><strong>Skills:</strong> {{ track.recommendedSkills.length }}</div>
            <div class="track-element track-created-by"><strong>Created By:</strong> {{ track.createdByUserName }}</div>
            <div *ngIf="!isActive(track)" class="track-element track-expired"><strong>Expired:</strong> {{ track.dateExpired | date: 'yyyy-MM-dd h:mm a' }}</div>
          </div>
        </nz-timeline-item>
        <nz-timeline-item nzColor="blue">
          <h3>Skills</h3>
          <div *ngFor="let trackSkill of track.recommendedSkills; index as s" class="track-timeline-skills">
            <nz-tag [nzColor]="getColour(t, s)">{{ trackSkill.skill.category + ': ' + trackSkill.skill.name }}</nz-tag>
          </div>
        </nz-timeline-item>
      </nz-timeline>
      <ng-template #dotTemplate>
        <i nz-icon nzType="clock-circle-o" style="font-size: 16px"></i>
      </ng-template>
    </div>
  </div>
</div>
