<nz-table id="email-metrics-table" #emailMetricsTable [nzData]="emailBatches" class="table table-hover table-bordered table-fixed" [nzShowPagination]="false">
  <thead>
    <tr>
      <th>Template</th>
      <th>Sent Date</th>
      <th>Sent To Recipients</th>
      <th class="recipient-table-header">Received By Recipients</th>
      <th *ngIf="isLoading === false; else elseBlock" class="refresh">
        <i class="fa fa-refresh static" aria-hidden="true" (click)="refreshBatchTable()"></i>
        <i class="fa fa-refresh hover" style="font-size: 16px" aria-hidden="true" (click)="refreshBatchTable()"></i>
      </th>
      <ng-template #elseBlock>
        <th class="refresh"><i class="fa fa-refresh fa-spin" style="font-size: 16px" aria-hidden="true"></i></th>
      </ng-template>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let batch of emailMetricsTable.data">
      <td>{{ batch.emailTypeName }}</td>
      <td>{{ batch.dateCreated | date: 'dd/MM/yyyy' }}</td>
      <td>{{ batch.numberSent }}</td>
      <td>{{ batch.numberReceived }}</td>
      <td></td>
    </tr>
  </tbody>
</nz-table>
